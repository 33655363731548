import styled from "styled-components";

export const Container = styled.div.attrs<{
  $checked?: string;
  $rounded?: string;
  $showhover?: string;
}>((props) => ({
  $checked: props.$checked,
  $rounded: props.$rounded,
  $showhover: props.$showhover,
}))`
  cursor: pointer;
  text-align: center;
  border-radius: ${(props) => (props.$rounded ? "1.5rem" : "0.25rem")};
  display: flex;
  width: 2rem;
  height: 2rem;
  padding: 0.25rem;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;

  ${(props) =>
    props.$rounded === "true" &&
    `
    width: 2.5rem;
    height: 2.5rem;
    justify-content: center;
    flex-shrink: 0;
    padding: 0.25rem;
  `}

  ${(props) =>
    props.$checked === "true" &&
    `
    opacity: 0.5;
    pointer-events: none;
  `}
  
  &:hover {
    background: ${(props) => props.theme.iconButtonHover};
  }

  &:active {
    background: ${(props) => props.theme.iconButtonClicked};
  }

  ${(props) =>
    props.$showhover === "true" &&
    `
  &:hover {
    background: none;
  }

  &:active {
    background: none;
  }
  `}
`;

export const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  & > * {
    width: 1.5rem;
    height: 1.5rem;
  }
`;
