import {
  createChart as createLwChart,
  IChartApi,
} from "lightweight-charts";
import { LwChartOptions } from "./options";
import { DefaultTheme } from "styled-components";

export type PickChartParams<
  T extends BaseChartState,
  U extends keyof T
> = Pick<T, U> & BaseChartParams;

export type BaseChartState = {
  theme: DefaultTheme;
  elem: HTMLDivElement;
  frequency: string;
  lwChart: IChartApi;
  isDisposed: boolean;
};

export type BaseChartParams = Pick<
  BaseChartState,
  "theme" | "elem" | "frequency"
>;

type CreateOptionsFn<T extends BaseChartParams> = (
  params: T
) => LwChartOptions;

export const createBaseChartState = <
  T extends BaseChartParams
>(
    params: T,
    createOptions: CreateOptionsFn<T>
  ): BaseChartState => {
  const { elem } = params;
  const chart = createLwChart(elem, createOptions(params));

  chart.timeScale().fitContent();

  return {
    ...params,
    lwChart: chart,
    isDisposed: false,
  };
};
