import React, { useEffect, useMemo, useState } from "react";
import { HiCurrencyPound } from "react-icons/hi";
// eslint-disable-next-line max-len
import SelectionDropdown from "../../../selection_dropdown/SelectionDropdown.component";
import { CustomFilterDialog } from "../custom_dialog/CustomFilterDialog";
// eslint-disable-next-line max-len
import { CustomFilterDialogProps } from "../custom_dialog/CustomFilterDialog.types";
import {
  PRICE_CUSTOM_TITLE,
  PriceCustomFields,
  priceScreeningFilter,
  priceScreeningFilterValues,
} from "./PriceScreeningFilter.helper";
import {
  InstrumentAndPrice,
  PriceFilterProps,
  PriceFilterValue,
} from "./PriceScreeningFilter.types";

const getDefaultItem = (values: PriceFilterValue[]) =>
  values.length > 0 ? values[0].title : "";

export const PriceScreeningFilter = ({
  onFilterChange,
  instrumentList,
  latestCandles,
}: PriceFilterProps) => {
  const [customValue, setCustomValue] = useState<PriceFilterValue | null>(null);
  const values = useMemo(
    () =>
      customValue
        ? [...priceScreeningFilterValues, customValue]
        : priceScreeningFilterValues,
    [customValue]
  );
  const [customFilterOpen, setCustomFilterOpen] = useState(false);
  const filter = priceScreeningFilter;
  const [selectedValue, setSelectedValue] = useState<PriceFilterValue>(
    values[0]
  );

  const instrumentsWithPrice: InstrumentAndPrice[] = useMemo(() => {
    if (!latestCandles || !instrumentList) return [];
    return instrumentList.map((instrument) => {
      const candle = latestCandles.find(
        (candle) => candle.hashcode === instrument.hashcode
      );
      if (!candle || candle.candles.length === 0)
        return { ...instrument, close: "" };
      return {
        ...instrument,
        close: candle?.candles[0].close || "",
      };
    });
  }, [instrumentList, latestCandles]);
  useEffect(() => {
    onFilterChange(filter.apply(instrumentsWithPrice, selectedValue));
  }, [selectedValue]);

  const dialogProps: CustomFilterDialogProps<PriceFilterValue> = useMemo(() => {
    return {
      open: customFilterOpen,
      applyFilter: (newValue) => {
        setCustomValue(newValue);
        setCustomFilterOpen(false);
        setSelectedValue(newValue);
      },
      fields: PriceCustomFields,
      onClose: () => setCustomFilterOpen(false),
    };
  }, [customFilterOpen]);

  return (
    <div>
      <SelectionDropdown
        onSelect={(changedValue) => {
          const value = values.find((value) => value.title === changedValue);
          if (!value || value?.title === PRICE_CUSTOM_TITLE) return;
          setSelectedValue(value || values[0]);
        }}
        Icon={<HiCurrencyPound />}
        items={values.map((value) => {
          return value.title;
        })}
        defaultItem={getDefaultItem(values)}
        dialogTitle={"Price"}
        customHeight={"300px"}
        buttonValue={PRICE_CUSTOM_TITLE}
        onButtonValuePressed={() => setCustomFilterOpen(true)}
        value={selectedValue.title}
      />
      <CustomFilterDialog {...dialogProps} />
    </div>
  );
};
