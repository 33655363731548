import React, { useEffect, useState } from "react";
import { getDefaultFrequency } from "./FrequencySelection.helpers";
import * as helpers from "./FrequencySelection.helpers";
// eslint-disable-next-line max-len
import SelectionDropdown from "@components/selection_dropdown/SelectionDropdown.component";
import { HiMiniClock } from "react-icons/hi2";
import { getFrequency } from "@utils/configStorageManager";
import { useAppContext } from "@client/src/context/AppContext";
type Props = {
  onChange: (value: string) => void;
};

const FrequencySelect = ({ onChange }: Props) => {
  const { frequency: contextFrequency, setFrequency } = useAppContext();
  const frequencyParam = contextFrequency;
  const [defaultItem, setDefaultItem] = useState("");
  const items = helpers.hardcodedItems.map((item) => item.name);
  const handleSelect = (itemName: string) => {
    const selectedItem = helpers.hardcodedItems.find(
      (item) => item.name === itemName
    );
    if (selectedItem) {
      setFrequency(selectedItem.value);
      onChange(selectedItem.value);
    }
  };

  useEffect(() => {
    const frequency = getDefaultFrequency(getFrequency(), frequencyParam);
    setDefaultItem(getDefaultFrequency(frequency, frequencyParam));
    handleSelect(contextFrequency);
  }, [contextFrequency]);

  return (
    <div data-testid="frequency-select">
      <SelectionDropdown
        dialogTitle={"Frequency"}
        data-testid="frequency-select"
        items={items}
        defaultItem={defaultItem}
        onSelect={handleSelect}
        customHeight={"300px"}
        Icon={<HiMiniClock />}
      />
    </div>
  );
};

export default FrequencySelect;
