import { TechnicalConfigItem } from "@common/types";
import { InstrumentClassMap } from "@generated/common/basic-types_pb";
import { getSelectedInstrumentClass } from "@utils/instrumentUtils";
import React from "react";
import { useInstrumentContext } from "../../context/InstrumentContext";
import { ChartContainer } from "../chart/Chart.style";
import { ChartData } from "@hooks/useFetchChart";
import { useChartHandler } from "@hooks/useChartHandler";
import { createCandlestickChart } from "@client/src/charts/candlestick";
import Legend from "../legend/Legend.component";
import { useAppContext } from "@client/src/context/AppContext";

export type ChartProps = {
  chartData: ChartData;
  chartType: string;
};

const getTechnicals = (
  technicals: TechnicalConfigItem[],
  selectedInstrumentClass: InstrumentClassMap[keyof InstrumentClassMap]
) => {
  const result = technicals
    .filter((technical) => !technical.rsi)
    .filter((technical) => !technical.macd);
  if (selectedInstrumentClass === 3) {
    return result.filter((technical) => technical.name !== "Volume");
  }
  return result;
};

const Chart = (props: ChartProps) => {
  const { technicals, hashcode, screeningResults } = useAppContext();
  const { instruments } = useInstrumentContext();
  const selectedInstrumentClass = getSelectedInstrumentClass(
    instruments,
    hashcode
  );
  const markers = screeningResults.find(
    (value) => value.hashcode === hashcode
  )?.matches;
  const [chartElemRef, legendElemRef] = useChartHandler(
    createCandlestickChart,
    (elem, theme) => ({
      elem,
      theme,
      markers: markers || [],
      ...props.chartData,
      chartType: props.chartType,
    }),
    props.chartData,
    markers || []
  );
  return (
    <ChartContainer ref={chartElemRef} data-testid="chart">
      <Legend
        data-testid="legend"
        ref={legendElemRef}
        technicals={getTechnicals(technicals, selectedInstrumentClass)}
      />
    </ChartContainer>
  );
};

export default Chart;
