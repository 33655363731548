import { useAuth0 } from "@auth0/auth0-react";
import { Portfolio } from "@common/types";
import { useCallback, useEffect, useState } from "react";
import { useInstrumentContext } from "../context/InstrumentContext";

export const usePortfolios = () => {
  const { getAccessTokenSilently } = useAuth0();
  const [userPortfolios, setUserPortfolios] = useState<Portfolio[]>([]);
  const [filledUserPortfolios, setFilledUserPortfolios] = useState<Portfolio[]>(
    []
  );
  const { instruments } = useInstrumentContext();

  useEffect(() => {
    const updatedPortfolios = [...userPortfolios];
    updatedPortfolios
      .filter((portfolio) => portfolio.visibleInstrumentNames === undefined)
      .forEach((portfolio) => {
        if (instruments.length === 0) return;
        if (portfolio.name === "All") {
          portfolio.hashcodes = instruments.map(
            (instrument) => instrument.hashcode
          );
          portfolio.visibleInstrumentNames = instruments.map(
            (instrument) => instrument.symbol
          );
        }
        portfolio.visibleInstrumentNames = portfolio.hashcodes.map(
          (hashcode) => {
            const instrument = instruments.find(
              (instrument) => instrument.hashcode === hashcode
            );
            return instrument?.symbol || "Unknown";
          }
        );
      });
    setFilledUserPortfolios(updatedPortfolios);
  }, [JSON.stringify(instruments), userPortfolios]);

  const manageSetPortfolios = useCallback(
    (portfolios: Portfolio[]) => {
      const allPortfolio: Portfolio = {
        name: "All",
        hashcodes: [],
        visibleInstrumentNames: undefined,
        id: "all",
      };
      setUserPortfolios([allPortfolio, ...portfolios]);
    },
    [instruments]
  );

  const getPortfolios = () => {
    getAccessTokenSilently().then((token: string) => {
      fetch("/api/portfolios", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
        .then((res) => res.json())
        .then((data) => {
          manageSetPortfolios(data.portfolios);
        });
    });
  };
  const createPortfolio = (body: { name: string; hashcodes: string[] }) => {
    getAccessTokenSilently().then((token: string) => {
      fetch("/api/portfolios", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(body),
      })
        .then((res) => res.json())
        .then((data) => {
          const updatedPortfolios = [...userPortfolios];
          updatedPortfolios.push(data.portfolio);
          setUserPortfolios(updatedPortfolios);
        });
    });
  };
  const deletePortfolio = (portfolioId: string) => {
    getAccessTokenSilently().then((token: string) => {
      fetch(`/api/portfolios/${portfolioId}`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
        .then((res) => res.json())
        .then((data) => {
          const updatedPortfolios = userPortfolios.filter(
            (portfolio) => portfolio.id !== data.portfolio.id
          );
          setUserPortfolios(updatedPortfolios);
        });
    });
  };

  const updatePortfolio = (portfolio: Portfolio) => {
    getAccessTokenSilently().then((token: string) => {
      fetch(`/api/portfolios/${portfolio.id}`, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          name: portfolio.name,
          hashcodes: portfolio.hashcodes,
        }),
      })
        .then((res) => res.json())
        .then((data) => {
          const updatedPortfolios = userPortfolios.map((portfolio) =>
            portfolio.id === data.portfolio.id ? data.portfolio : portfolio
          );
          setUserPortfolios(updatedPortfolios);
        });
    });
  };
  return {
    userPortfolios: filledUserPortfolios,
    getPortfolios,
    createPortfolio,
    deletePortfolio,
    updatePortfolio,
  };
};
