import { Instrument } from "@generated/common/basic-types_pb";
import React from "react";
import { useUserContext } from "../../context/user/UserContext";
import { Container } from "./InstrumentList.style";
import InstrumentItem from "./item/InstrumentItem.component";
import { useInstrumentContext } from "@client/src/context/InstrumentContext";
type Props = {
  instrumentList: Instrument.AsObject[];
  onSelectInstrument: (instrument: Instrument.AsObject) => void;
};
const InstrumentList = ({ instrumentList, onSelectInstrument }: Props) => {
  const { availableInstruments, unavailableInstruments } =
    useInstrumentContext();

  const { setShowAccessDeniedDialog } = useUserContext();
  const subscriptionInstruments = instrumentList.filter((instrument) => {
    return unavailableInstruments.includes(instrument);
  });
  return (
    <Container>
      {instrumentList.map((instrument) => {
        if (availableInstruments.includes(instrument)) {
          return (
            <InstrumentItem
              key={instrument.symbol}
              instrument={instrument}
              handleOnClick={() => {
                onSelectInstrument(instrument);
              }}
            />
          );
        }
      })}
      {subscriptionInstruments.length > 0 && <p>Subscribe for access</p>}
      {subscriptionInstruments.map((instrument) => {
        return (
          <InstrumentItem
            key={instrument.symbol}
            instrument={instrument}
            handleOnClick={() => {
              setShowAccessDeniedDialog(true);
            }}
          />
        );
      })}
    </Container>
  );
};

export default InstrumentList;
