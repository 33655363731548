import { styled } from "styled-components";

export const LegendButton = styled.div`
  // hidden fist and shows on :hover
  display: none;
  cursor: pointer;
  height: fit-content;
  flex-direction: column;
  align-items: center;
`;

export const LegendItemContainer = styled.div<{
  $color?: string;
}>`
  all: unset;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0.25rem 0.75rem;
  gap: 1rem;
  border-radius: 0.5rem;
  border: 1px solid transparent;
  background-color: #0001;
  &:hover {
    background-color: #0008;
    border: 1px solid ${(props) => props.$color};
  }

  &:hover ${LegendButton}, &:focus ${LegendButton} {
    display: inline-flex;
  }
`;

export const LegendContainer = styled.div`
  position: absolute;
  top: 0;
  margin: 0.75rem 1rem;
  z-index: 9;
  & > * {
    margin-bottom: 8px;
  }
`;

export const TextSection = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  gap: 0.75rem;
`;

export const LegendContentContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
`;

export const LegendLabelText = styled.p<{
  $color?: string;
}>`
  all: unset;
  color: ${(props) => props.$color};
`;
