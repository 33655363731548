import React from "react";
import {
  Body,
  Container,
  ContentContainer,
  Input,
  InputError,
  ModalIconContainer,
  Overlay,
  TextContainer,
  Title,
  TwoButtons,
  ErrorText,
} from "./Modal.styles";
import Button from "../button/Button.component";

export type ModalProps = {
  modalImage?: React.ReactElement;
  modalImageType?: "default" | "red";
  modalTitle: string;
  modalDescription: string;
  placeholderInput?: string;
  onInputChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  inputError?: string;
  Button1: React.ReactElement;
  Button2?: React.ReactElement;
  isVisible: boolean;
  hideCancel?: boolean;
};

export const Modal = ({
  modalImage,
  modalImageType,
  modalTitle,
  modalDescription,
  placeholderInput,
  onInputChange,
  inputError,
  Button1,
  Button2,
  isVisible,
  hideCancel,
}: ModalProps) => {
  const Icon = () => {
    if (!modalImage) return null;
    return (
      <ModalIconContainer type={modalImageType ? modalImageType : "default"}>
        {modalImage}
      </ModalIconContainer>
    );
  };

  const Buttons = () => (
    <TwoButtons>
      {Button1}
      {Button2 ||
        (!hideCancel && (
          <Button text="Cancel" type="secondary" data-testid="cancel-modal" />
        ))}
    </TwoButtons>
  );

  if (!isVisible) return null;

  const renderInput = () => {
    if (!placeholderInput) return null;
    if (inputError) return null;
    return <Input placeholder={placeholderInput} onChange={onInputChange} />;
  };

  const renderErrorInput = () => {
    if (!inputError) return null;
    return (
      <>
        <InputError placeholder={placeholderInput} onChange={onInputChange} />
        <ErrorText>{inputError}</ErrorText>
      </>
    );
  };

  return (
    <Overlay>
      <Container data-testid="modal">
        <ContentContainer>
          <Icon />
          <TextContainer>
            <Title>{modalTitle}</Title>
            <Body>{modalDescription}</Body>
            {renderInput()}
            {renderErrorInput()}
          </TextContainer>
        </ContentContainer>
        <Buttons />
      </Container>
    </Overlay>
  );
};
