// source: common/basic-types.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() {
  if (this) { return this; }
  if (typeof window !== 'undefined') { return window; }
  if (typeof global !== 'undefined') { return global; }
  if (typeof self !== 'undefined') { return self; }
  return Function('return this')();
}.call(null));

goog.exportSymbol('proto.ai.sigmafinancial.common.basic_types.CandleFrequency', null, global);
goog.exportSymbol('proto.ai.sigmafinancial.common.basic_types.Candlestick', null, global);
goog.exportSymbol('proto.ai.sigmafinancial.common.basic_types.DataVendor', null, global);
goog.exportSymbol('proto.ai.sigmafinancial.common.basic_types.DayOfWeek', null, global);
goog.exportSymbol('proto.ai.sigmafinancial.common.basic_types.DerivativeType', null, global);
goog.exportSymbol('proto.ai.sigmafinancial.common.basic_types.Exchange', null, global);
goog.exportSymbol('proto.ai.sigmafinancial.common.basic_types.Instrument', null, global);
goog.exportSymbol('proto.ai.sigmafinancial.common.basic_types.InstrumentClass', null, global);
goog.exportSymbol('proto.ai.sigmafinancial.common.basic_types.InstrumentType', null, global);
goog.exportSymbol('proto.ai.sigmafinancial.common.basic_types.Region', null, global);
goog.exportSymbol('proto.ai.sigmafinancial.common.basic_types.Sector', null, global);
goog.exportSymbol('proto.ai.sigmafinancial.common.basic_types.Tradelike', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ai.sigmafinancial.common.basic_types.Candlestick = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ai.sigmafinancial.common.basic_types.Candlestick, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ai.sigmafinancial.common.basic_types.Candlestick.displayName = 'proto.ai.sigmafinancial.common.basic_types.Candlestick';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ai.sigmafinancial.common.basic_types.Tradelike = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ai.sigmafinancial.common.basic_types.Tradelike, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ai.sigmafinancial.common.basic_types.Tradelike.displayName = 'proto.ai.sigmafinancial.common.basic_types.Tradelike';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ai.sigmafinancial.common.basic_types.Instrument = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ai.sigmafinancial.common.basic_types.Instrument, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ai.sigmafinancial.common.basic_types.Instrument.displayName = 'proto.ai.sigmafinancial.common.basic_types.Instrument';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ai.sigmafinancial.common.basic_types.Exchange = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ai.sigmafinancial.common.basic_types.Exchange, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ai.sigmafinancial.common.basic_types.Exchange.displayName = 'proto.ai.sigmafinancial.common.basic_types.Exchange';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ai.sigmafinancial.common.basic_types.Candlestick.prototype.toObject = function(opt_includeInstance) {
  return proto.ai.sigmafinancial.common.basic_types.Candlestick.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ai.sigmafinancial.common.basic_types.Candlestick} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ai.sigmafinancial.common.basic_types.Candlestick.toObject = function(includeInstance, msg) {
  var f, obj = {
    tsInterval: jspb.Message.getFieldWithDefault(msg, 1, 0),
    open: jspb.Message.getFieldWithDefault(msg, 5, ""),
    high: jspb.Message.getFieldWithDefault(msg, 6, ""),
    low: jspb.Message.getFieldWithDefault(msg, 7, ""),
    close: jspb.Message.getFieldWithDefault(msg, 8, ""),
    volume: jspb.Message.getFieldWithDefault(msg, 9, ""),
    vwap: jspb.Message.getFieldWithDefault(msg, 10, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ai.sigmafinancial.common.basic_types.Candlestick}
 */
proto.ai.sigmafinancial.common.basic_types.Candlestick.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ai.sigmafinancial.common.basic_types.Candlestick;
  return proto.ai.sigmafinancial.common.basic_types.Candlestick.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ai.sigmafinancial.common.basic_types.Candlestick} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ai.sigmafinancial.common.basic_types.Candlestick}
 */
proto.ai.sigmafinancial.common.basic_types.Candlestick.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTsInterval(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setOpen(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setHigh(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setLow(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setClose(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setVolume(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setVwap(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ai.sigmafinancial.common.basic_types.Candlestick.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ai.sigmafinancial.common.basic_types.Candlestick.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ai.sigmafinancial.common.basic_types.Candlestick} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ai.sigmafinancial.common.basic_types.Candlestick.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTsInterval();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getOpen();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getHigh();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getLow();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getClose();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getVolume();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getVwap();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
};


/**
 * optional int64 ts_interval = 1;
 * @return {number}
 */
proto.ai.sigmafinancial.common.basic_types.Candlestick.prototype.getTsInterval = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.ai.sigmafinancial.common.basic_types.Candlestick} returns this
 */
proto.ai.sigmafinancial.common.basic_types.Candlestick.prototype.setTsInterval = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string open = 5;
 * @return {string}
 */
proto.ai.sigmafinancial.common.basic_types.Candlestick.prototype.getOpen = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.ai.sigmafinancial.common.basic_types.Candlestick} returns this
 */
proto.ai.sigmafinancial.common.basic_types.Candlestick.prototype.setOpen = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string high = 6;
 * @return {string}
 */
proto.ai.sigmafinancial.common.basic_types.Candlestick.prototype.getHigh = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.ai.sigmafinancial.common.basic_types.Candlestick} returns this
 */
proto.ai.sigmafinancial.common.basic_types.Candlestick.prototype.setHigh = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string low = 7;
 * @return {string}
 */
proto.ai.sigmafinancial.common.basic_types.Candlestick.prototype.getLow = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.ai.sigmafinancial.common.basic_types.Candlestick} returns this
 */
proto.ai.sigmafinancial.common.basic_types.Candlestick.prototype.setLow = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string close = 8;
 * @return {string}
 */
proto.ai.sigmafinancial.common.basic_types.Candlestick.prototype.getClose = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.ai.sigmafinancial.common.basic_types.Candlestick} returns this
 */
proto.ai.sigmafinancial.common.basic_types.Candlestick.prototype.setClose = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string volume = 9;
 * @return {string}
 */
proto.ai.sigmafinancial.common.basic_types.Candlestick.prototype.getVolume = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.ai.sigmafinancial.common.basic_types.Candlestick} returns this
 */
proto.ai.sigmafinancial.common.basic_types.Candlestick.prototype.setVolume = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string vwap = 10;
 * @return {string}
 */
proto.ai.sigmafinancial.common.basic_types.Candlestick.prototype.getVwap = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.ai.sigmafinancial.common.basic_types.Candlestick} returns this
 */
proto.ai.sigmafinancial.common.basic_types.Candlestick.prototype.setVwap = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ai.sigmafinancial.common.basic_types.Tradelike.prototype.toObject = function(opt_includeInstance) {
  return proto.ai.sigmafinancial.common.basic_types.Tradelike.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ai.sigmafinancial.common.basic_types.Tradelike} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ai.sigmafinancial.common.basic_types.Tradelike.toObject = function(includeInstance, msg) {
  var f, obj = {
    ts: jspb.Message.getFieldWithDefault(msg, 1, 0),
    tradeSeq: jspb.Message.getFieldWithDefault(msg, 5, ""),
    price: jspb.Message.getFieldWithDefault(msg, 10, ""),
    bid: jspb.Message.getFieldWithDefault(msg, 15, ""),
    ask: jspb.Message.getFieldWithDefault(msg, 20, ""),
    vol: jspb.Message.getFieldWithDefault(msg, 25, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ai.sigmafinancial.common.basic_types.Tradelike}
 */
proto.ai.sigmafinancial.common.basic_types.Tradelike.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ai.sigmafinancial.common.basic_types.Tradelike;
  return proto.ai.sigmafinancial.common.basic_types.Tradelike.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ai.sigmafinancial.common.basic_types.Tradelike} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ai.sigmafinancial.common.basic_types.Tradelike}
 */
proto.ai.sigmafinancial.common.basic_types.Tradelike.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTs(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setTradeSeq(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setPrice(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setBid(value);
      break;
    case 20:
      var value = /** @type {string} */ (reader.readString());
      msg.setAsk(value);
      break;
    case 25:
      var value = /** @type {string} */ (reader.readString());
      msg.setVol(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ai.sigmafinancial.common.basic_types.Tradelike.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ai.sigmafinancial.common.basic_types.Tradelike.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ai.sigmafinancial.common.basic_types.Tradelike} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ai.sigmafinancial.common.basic_types.Tradelike.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTs();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getTradeSeq();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getPrice();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getBid();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getAsk();
  if (f.length > 0) {
    writer.writeString(
      20,
      f
    );
  }
  f = message.getVol();
  if (f.length > 0) {
    writer.writeString(
      25,
      f
    );
  }
};


/**
 * optional int64 ts = 1;
 * @return {number}
 */
proto.ai.sigmafinancial.common.basic_types.Tradelike.prototype.getTs = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.ai.sigmafinancial.common.basic_types.Tradelike} returns this
 */
proto.ai.sigmafinancial.common.basic_types.Tradelike.prototype.setTs = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string trade_seq = 5;
 * @return {string}
 */
proto.ai.sigmafinancial.common.basic_types.Tradelike.prototype.getTradeSeq = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.ai.sigmafinancial.common.basic_types.Tradelike} returns this
 */
proto.ai.sigmafinancial.common.basic_types.Tradelike.prototype.setTradeSeq = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string price = 10;
 * @return {string}
 */
proto.ai.sigmafinancial.common.basic_types.Tradelike.prototype.getPrice = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.ai.sigmafinancial.common.basic_types.Tradelike} returns this
 */
proto.ai.sigmafinancial.common.basic_types.Tradelike.prototype.setPrice = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string bid = 15;
 * @return {string}
 */
proto.ai.sigmafinancial.common.basic_types.Tradelike.prototype.getBid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.ai.sigmafinancial.common.basic_types.Tradelike} returns this
 */
proto.ai.sigmafinancial.common.basic_types.Tradelike.prototype.setBid = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional string ask = 20;
 * @return {string}
 */
proto.ai.sigmafinancial.common.basic_types.Tradelike.prototype.getAsk = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 20, ""));
};


/**
 * @param {string} value
 * @return {!proto.ai.sigmafinancial.common.basic_types.Tradelike} returns this
 */
proto.ai.sigmafinancial.common.basic_types.Tradelike.prototype.setAsk = function(value) {
  return jspb.Message.setProto3StringField(this, 20, value);
};


/**
 * optional string vol = 25;
 * @return {string}
 */
proto.ai.sigmafinancial.common.basic_types.Tradelike.prototype.getVol = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 25, ""));
};


/**
 * @param {string} value
 * @return {!proto.ai.sigmafinancial.common.basic_types.Tradelike} returns this
 */
proto.ai.sigmafinancial.common.basic_types.Tradelike.prototype.setVol = function(value) {
  return jspb.Message.setProto3StringField(this, 25, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ai.sigmafinancial.common.basic_types.Instrument.prototype.toObject = function(opt_includeInstance) {
  return proto.ai.sigmafinancial.common.basic_types.Instrument.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ai.sigmafinancial.common.basic_types.Instrument} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ai.sigmafinancial.common.basic_types.Instrument.toObject = function(includeInstance, msg) {
  var f, obj = {
    symbol: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    hashcode: jspb.Message.getFieldWithDefault(msg, 3, ""),
    instrumentType: jspb.Message.getFieldWithDefault(msg, 4, 0),
    instrumentClass: jspb.Message.getFieldWithDefault(msg, 5, 0),
    derivativeType: jspb.Message.getFieldWithDefault(msg, 6, 0),
    exchange: (f = msg.getExchange()) && proto.ai.sigmafinancial.common.basic_types.Exchange.toObject(includeInstance, f),
    sector: jspb.Message.getFieldWithDefault(msg, 8, 0),
    isin: jspb.Message.getFieldWithDefault(msg, 9, ""),
    cficode: jspb.Message.getFieldWithDefault(msg, 10, ""),
    vendor: jspb.Message.getFieldWithDefault(msg, 11, 0),
    logoUrl: jspb.Message.getFieldWithDefault(msg, 20, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ai.sigmafinancial.common.basic_types.Instrument}
 */
proto.ai.sigmafinancial.common.basic_types.Instrument.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ai.sigmafinancial.common.basic_types.Instrument;
  return proto.ai.sigmafinancial.common.basic_types.Instrument.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ai.sigmafinancial.common.basic_types.Instrument} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ai.sigmafinancial.common.basic_types.Instrument}
 */
proto.ai.sigmafinancial.common.basic_types.Instrument.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSymbol(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setHashcode(value);
      break;
    case 4:
      var value = /** @type {!proto.ai.sigmafinancial.common.basic_types.InstrumentType} */ (reader.readEnum());
      msg.setInstrumentType(value);
      break;
    case 5:
      var value = /** @type {!proto.ai.sigmafinancial.common.basic_types.InstrumentClass} */ (reader.readEnum());
      msg.setInstrumentClass(value);
      break;
    case 6:
      var value = /** @type {!proto.ai.sigmafinancial.common.basic_types.DerivativeType} */ (reader.readEnum());
      msg.setDerivativeType(value);
      break;
    case 7:
      var value = new proto.ai.sigmafinancial.common.basic_types.Exchange;
      reader.readMessage(value,proto.ai.sigmafinancial.common.basic_types.Exchange.deserializeBinaryFromReader);
      msg.setExchange(value);
      break;
    case 8:
      var value = /** @type {!proto.ai.sigmafinancial.common.basic_types.Sector} */ (reader.readEnum());
      msg.setSector(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setIsin(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setCficode(value);
      break;
    case 11:
      var value = /** @type {!proto.ai.sigmafinancial.common.basic_types.DataVendor} */ (reader.readEnum());
      msg.setVendor(value);
      break;
    case 20:
      var value = /** @type {string} */ (reader.readString());
      msg.setLogoUrl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ai.sigmafinancial.common.basic_types.Instrument.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ai.sigmafinancial.common.basic_types.Instrument.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ai.sigmafinancial.common.basic_types.Instrument} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ai.sigmafinancial.common.basic_types.Instrument.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSymbol();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getHashcode();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getInstrumentType();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getInstrumentClass();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getDerivativeType();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
  f = message.getExchange();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.ai.sigmafinancial.common.basic_types.Exchange.serializeBinaryToWriter
    );
  }
  f = message.getSector();
  if (f !== 0.0) {
    writer.writeEnum(
      8,
      f
    );
  }
  f = message.getIsin();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getCficode();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getVendor();
  if (f !== 0.0) {
    writer.writeEnum(
      11,
      f
    );
  }
  f = message.getLogoUrl();
  if (f.length > 0) {
    writer.writeString(
      20,
      f
    );
  }
};


/**
 * optional string symbol = 1;
 * @return {string}
 */
proto.ai.sigmafinancial.common.basic_types.Instrument.prototype.getSymbol = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.ai.sigmafinancial.common.basic_types.Instrument} returns this
 */
proto.ai.sigmafinancial.common.basic_types.Instrument.prototype.setSymbol = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.ai.sigmafinancial.common.basic_types.Instrument.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.ai.sigmafinancial.common.basic_types.Instrument} returns this
 */
proto.ai.sigmafinancial.common.basic_types.Instrument.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string hashcode = 3;
 * @return {string}
 */
proto.ai.sigmafinancial.common.basic_types.Instrument.prototype.getHashcode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.ai.sigmafinancial.common.basic_types.Instrument} returns this
 */
proto.ai.sigmafinancial.common.basic_types.Instrument.prototype.setHashcode = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional InstrumentType instrument_type = 4;
 * @return {!proto.ai.sigmafinancial.common.basic_types.InstrumentType}
 */
proto.ai.sigmafinancial.common.basic_types.Instrument.prototype.getInstrumentType = function() {
  return /** @type {!proto.ai.sigmafinancial.common.basic_types.InstrumentType} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.ai.sigmafinancial.common.basic_types.InstrumentType} value
 * @return {!proto.ai.sigmafinancial.common.basic_types.Instrument} returns this
 */
proto.ai.sigmafinancial.common.basic_types.Instrument.prototype.setInstrumentType = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional InstrumentClass instrument_class = 5;
 * @return {!proto.ai.sigmafinancial.common.basic_types.InstrumentClass}
 */
proto.ai.sigmafinancial.common.basic_types.Instrument.prototype.getInstrumentClass = function() {
  return /** @type {!proto.ai.sigmafinancial.common.basic_types.InstrumentClass} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.ai.sigmafinancial.common.basic_types.InstrumentClass} value
 * @return {!proto.ai.sigmafinancial.common.basic_types.Instrument} returns this
 */
proto.ai.sigmafinancial.common.basic_types.Instrument.prototype.setInstrumentClass = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * optional DerivativeType derivative_type = 6;
 * @return {!proto.ai.sigmafinancial.common.basic_types.DerivativeType}
 */
proto.ai.sigmafinancial.common.basic_types.Instrument.prototype.getDerivativeType = function() {
  return /** @type {!proto.ai.sigmafinancial.common.basic_types.DerivativeType} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.ai.sigmafinancial.common.basic_types.DerivativeType} value
 * @return {!proto.ai.sigmafinancial.common.basic_types.Instrument} returns this
 */
proto.ai.sigmafinancial.common.basic_types.Instrument.prototype.setDerivativeType = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};


/**
 * optional Exchange exchange = 7;
 * @return {?proto.ai.sigmafinancial.common.basic_types.Exchange}
 */
proto.ai.sigmafinancial.common.basic_types.Instrument.prototype.getExchange = function() {
  return /** @type{?proto.ai.sigmafinancial.common.basic_types.Exchange} */ (
    jspb.Message.getWrapperField(this, proto.ai.sigmafinancial.common.basic_types.Exchange, 7));
};


/**
 * @param {?proto.ai.sigmafinancial.common.basic_types.Exchange|undefined} value
 * @return {!proto.ai.sigmafinancial.common.basic_types.Instrument} returns this
*/
proto.ai.sigmafinancial.common.basic_types.Instrument.prototype.setExchange = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ai.sigmafinancial.common.basic_types.Instrument} returns this
 */
proto.ai.sigmafinancial.common.basic_types.Instrument.prototype.clearExchange = function() {
  return this.setExchange(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ai.sigmafinancial.common.basic_types.Instrument.prototype.hasExchange = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional Sector sector = 8;
 * @return {!proto.ai.sigmafinancial.common.basic_types.Sector}
 */
proto.ai.sigmafinancial.common.basic_types.Instrument.prototype.getSector = function() {
  return /** @type {!proto.ai.sigmafinancial.common.basic_types.Sector} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {!proto.ai.sigmafinancial.common.basic_types.Sector} value
 * @return {!proto.ai.sigmafinancial.common.basic_types.Instrument} returns this
 */
proto.ai.sigmafinancial.common.basic_types.Instrument.prototype.setSector = function(value) {
  return jspb.Message.setProto3EnumField(this, 8, value);
};


/**
 * optional string ISIN = 9;
 * @return {string}
 */
proto.ai.sigmafinancial.common.basic_types.Instrument.prototype.getIsin = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.ai.sigmafinancial.common.basic_types.Instrument} returns this
 */
proto.ai.sigmafinancial.common.basic_types.Instrument.prototype.setIsin = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string CFICode = 10;
 * @return {string}
 */
proto.ai.sigmafinancial.common.basic_types.Instrument.prototype.getCficode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.ai.sigmafinancial.common.basic_types.Instrument} returns this
 */
proto.ai.sigmafinancial.common.basic_types.Instrument.prototype.setCficode = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional DataVendor vendor = 11;
 * @return {!proto.ai.sigmafinancial.common.basic_types.DataVendor}
 */
proto.ai.sigmafinancial.common.basic_types.Instrument.prototype.getVendor = function() {
  return /** @type {!proto.ai.sigmafinancial.common.basic_types.DataVendor} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {!proto.ai.sigmafinancial.common.basic_types.DataVendor} value
 * @return {!proto.ai.sigmafinancial.common.basic_types.Instrument} returns this
 */
proto.ai.sigmafinancial.common.basic_types.Instrument.prototype.setVendor = function(value) {
  return jspb.Message.setProto3EnumField(this, 11, value);
};


/**
 * optional string logo_url = 20;
 * @return {string}
 */
proto.ai.sigmafinancial.common.basic_types.Instrument.prototype.getLogoUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 20, ""));
};


/**
 * @param {string} value
 * @return {!proto.ai.sigmafinancial.common.basic_types.Instrument} returns this
 */
proto.ai.sigmafinancial.common.basic_types.Instrument.prototype.setLogoUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 20, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ai.sigmafinancial.common.basic_types.Exchange.prototype.toObject = function(opt_includeInstance) {
  return proto.ai.sigmafinancial.common.basic_types.Exchange.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ai.sigmafinancial.common.basic_types.Exchange} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ai.sigmafinancial.common.basic_types.Exchange.toObject = function(includeInstance, msg) {
  var f, obj = {
    hashcode: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    mic: jspb.Message.getFieldWithDefault(msg, 3, ""),
    region: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ai.sigmafinancial.common.basic_types.Exchange}
 */
proto.ai.sigmafinancial.common.basic_types.Exchange.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ai.sigmafinancial.common.basic_types.Exchange;
  return proto.ai.sigmafinancial.common.basic_types.Exchange.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ai.sigmafinancial.common.basic_types.Exchange} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ai.sigmafinancial.common.basic_types.Exchange}
 */
proto.ai.sigmafinancial.common.basic_types.Exchange.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setHashcode(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setMic(value);
      break;
    case 4:
      var value = /** @type {!proto.ai.sigmafinancial.common.basic_types.Region} */ (reader.readEnum());
      msg.setRegion(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ai.sigmafinancial.common.basic_types.Exchange.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ai.sigmafinancial.common.basic_types.Exchange.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ai.sigmafinancial.common.basic_types.Exchange} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ai.sigmafinancial.common.basic_types.Exchange.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHashcode();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMic();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getRegion();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
};


/**
 * optional string hashcode = 1;
 * @return {string}
 */
proto.ai.sigmafinancial.common.basic_types.Exchange.prototype.getHashcode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.ai.sigmafinancial.common.basic_types.Exchange} returns this
 */
proto.ai.sigmafinancial.common.basic_types.Exchange.prototype.setHashcode = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.ai.sigmafinancial.common.basic_types.Exchange.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.ai.sigmafinancial.common.basic_types.Exchange} returns this
 */
proto.ai.sigmafinancial.common.basic_types.Exchange.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string mic = 3;
 * @return {string}
 */
proto.ai.sigmafinancial.common.basic_types.Exchange.prototype.getMic = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.ai.sigmafinancial.common.basic_types.Exchange} returns this
 */
proto.ai.sigmafinancial.common.basic_types.Exchange.prototype.setMic = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional Region region = 4;
 * @return {!proto.ai.sigmafinancial.common.basic_types.Region}
 */
proto.ai.sigmafinancial.common.basic_types.Exchange.prototype.getRegion = function() {
  return /** @type {!proto.ai.sigmafinancial.common.basic_types.Region} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.ai.sigmafinancial.common.basic_types.Region} value
 * @return {!proto.ai.sigmafinancial.common.basic_types.Exchange} returns this
 */
proto.ai.sigmafinancial.common.basic_types.Exchange.prototype.setRegion = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * @enum {number}
 */
proto.ai.sigmafinancial.common.basic_types.CandleFrequency = {
  NOT_SET: 0,
  M1: 4,
  M3: 5,
  M5: 10,
  M10: 15,
  M15: 20,
  M30: 25,
  H1: 30,
  H2: 35,
  H3: 40,
  H4: 45,
  D1: 50
};

/**
 * @enum {number}
 */
proto.ai.sigmafinancial.common.basic_types.DataVendor = {
  KAIKO: 0,
  TRADINGTECHNOLOGIES: 1,
  CBOE: 2,
  POLYGON: 3
};

/**
 * @enum {number}
 */
proto.ai.sigmafinancial.common.basic_types.InstrumentClass = {
  EQUITY: 0,
  INDEX: 1,
  CRYPTO: 2,
  FOREX: 3,
  TREASURIES: 4,
  COMMODITIES: 5,
  EXCHANGE_TRADED_NOTES: 6,
  DEPOSITORY_RECEIPT: 7,
  EXCHANGE_TRADED_COMMODITIES: 8,
  EXCHANGE_TRADED_FUNDS: 9
};

/**
 * @enum {number}
 */
proto.ai.sigmafinancial.common.basic_types.InstrumentType = {
  CASH: 0,
  DERIVATIVE: 1,
  INDICES: 2
};

/**
 * @enum {number}
 */
proto.ai.sigmafinancial.common.basic_types.DerivativeType = {
  DERIVATIVE_NOT_SET: 0,
  FUTURE: 1,
  OPTION: 2,
  PERPETUAL_FUTURE: 3,
  SPOT: 4
};

/**
 * @enum {number}
 */
proto.ai.sigmafinancial.common.basic_types.Region = {
  REGION_NOT_SET: 0,
  GLOBAL: 1,
  APAC: 2,
  EMEA: 3,
  LATAM: 4,
  NA: 5,
  EUROPE: 6
};

/**
 * @enum {number}
 */
proto.ai.sigmafinancial.common.basic_types.Sector = {
  SECTOR_NOT_SET: 0,
  ENERGY: 1,
  MATERIALS: 2,
  INDUSTRIALS: 3,
  CONSUMER_DISCRETIONARY: 4,
  CONSUMER_STAPLES: 5,
  HEALTH_CARE: 6,
  FINANCIALS: 7,
  INFORMATION_TECHNOLOGY: 8,
  TELECOMMUNICATION_SERVICES: 9,
  UTILITIES: 10,
  REAL_ESTATE: 11
};

/**
 * @enum {number}
 */
proto.ai.sigmafinancial.common.basic_types.DayOfWeek = {
  DAY_OF_WEEK_SUNDAY: 0,
  DAY_OF_WEEK_MONDAY: 1,
  DAY_OF_WEEK_TUESDAY: 2,
  DAY_OF_WEEK_WEDNESDAY: 3,
  DAY_OF_WEEK_THURSDAY: 4,
  DAY_OF_WEEK_FRIDAY: 5,
  DAY_OF_WEEK_SATURDAY: 6
};

goog.object.extend(exports, proto.ai.sigmafinancial.common.basic_types);
