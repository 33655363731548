import styled from "styled-components";
import { textStyles } from "@client/src/utils/textStyles";

export const InputContainer = styled.div`
  display: flex;
  padding: 0.5rem;
  border-radius: 0.25rem;
  justify-content: space-between;
  max-width: fit-content;
  align-items: center;
  background-color: ${(props) => props.theme.inputNumericBg};
  border: transparent;
  min-width: 0;
  color: ${(props) => props.theme.inputNumericText};
`;

export const Input = styled.input`
  background-color: ${(props) => props.theme.inputNumericBg};
  display: flex;
  border: transparent;
  justify-content: center;
  align-items: center;
  max-width: fit-content;
  min-width: 0;
  ${textStyles.textSm};
  color: ${(props) => props.theme.inputNumericText};
  &:focus {
    outline: none;
    border: transparent;
  }

  /* Remove spinners */
  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &[type="number"] {
    -moz-appearance: textfield;
  }
`;
