import {
  ColorType,
  createChart,
  CrosshairMode,
  LineStyle,
} from "lightweight-charts";
import { BaseChartParams } from "./state";

export type PartialChartOptions = Partial<Parameters<typeof createChart>[1]>;

export type LwChartOptions = Parameters<typeof createChart>[1];

export const applyDefaultChartOptions = <T extends BaseChartParams>(
  params: T,
  options: PartialChartOptions,
): LwChartOptions => {
  const { elem, theme } = params;
  return {
    autoSize: true,
    layout: {
      background: {
        type: ColorType.Solid,
        color: theme.chart.background,
      },
      textColor: theme.text,
    },
    width: elem.clientWidth,
    localization: {
      dateFormat: "UTC dd MM yyyy",
    },
    grid: {
      vertLines: {
        color: theme.chart.grid,
      },
      horzLines: {
        color: theme.chart.grid,
      },
    },
    timeScale: {
      visible: true,
      timeVisible: true,
      fixLeftEdge: true,
    },
    crosshair: {
      vertLine: {
        labelVisible: true,
        color: theme.chart.line,
        style: LineStyle.LargeDashed,
        labelBackgroundColor: theme.chart.label,
      },
      horzLine: {
        labelVisible: true,
        color: theme.chart.line,
        style: LineStyle.LargeDashed,
        labelBackgroundColor: theme.chart.label,
      },
      mode: CrosshairMode.Normal,
    },
    rightPriceScale: {
      ticksVisible: true,
    },
    ...options,
  };
};
