import { baseStyle } from "@utils/textStyles";
import styled from "styled-components";

const Container = styled.div`
  background-color: ${(props) => props.theme.ManageAlertBackground};
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  ${baseStyle};
  border-radius: 0.5rem;
  width: 100%;
`;

export { Container };
