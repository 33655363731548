import { CandleFrequencyMap } from "@generated/common/basic-types_pb";

const FrequencyLabels = {
  0: "Unset",
  4: "1 min",
  5: "3 min",
  10: "5 min",
  15: "10 min",
  20: "15 min",
  25: "30 min",
  30: "1 hour",
  35: "2 hour",
  40: "3 hour",
  45: "4 hour",
  50: "1 day",
};

export const getFrequencyLabel = (
  frequency: CandleFrequencyMap[keyof CandleFrequencyMap] | number
) => {
  if (Object.keys(FrequencyLabels).indexOf(frequency.toString()) !== -1) {
    return FrequencyLabels[
      frequency as CandleFrequencyMap[keyof CandleFrequencyMap]
    ];
  }
  return "Unknown Frequency";
};
