import { css } from "styled-components";

export const effectStyles = {
  grey: {
    shadowSm: css`
        box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05)
    `,
    shadow: css`
        box-shadow: 0px 1px 2px -1px rgba(0, 0, 0, 0.10),
        0px 4px 4px 0px rgba(0, 0, 0, 0.10)
    `,
    shadowMd: css`
        box-shadow: 0px 4px 6px -1px rgba(0, 0, 0, 0.10),
        0px 2px 4px -2px rgba(0, 0, 0, 0.10)
    `,
    shadowLg: css`
        box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.10),
        0px 4px 6px -4px rgba(0, 0, 0, 0.10)
    `,
    shadowXl: css`
        box-shadow: 0px 8px 10px -6px rgba(0, 0, 0, 0.10),
        0px 20px 25px -5px rgba(0, 0, 0, 0.10)
    `,
    shadow2Xl: css`
        box-shadow: 0px 25px 50px -12px rgba(0, 0, 0, 0.25)
    `,
    shadowInner: css`
        box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.05) inset
    `,
  },
};
