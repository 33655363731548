import { textStyles } from "@client/src/utils/textStyles";
import styled from "styled-components";

type RowProps = {
  disabled?: boolean;
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: fit-content;
  gap: 0.5rem;
  padding: 1rem 0;
  background-color: ${(props) =>
    props.theme.patternConfigBg};
`;

const Row = styled.div<RowProps>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  opacity: ${(props) => (props.disabled ? 0.4 : 1)};
`;

const Title = styled.span`
  ${textStyles.textBaseBold}
  color: ${(props) => props.theme.patternConfigText};
`;

const Text = styled.span`
  ${textStyles.textBase}
  color: ${(props) => props.theme.patternConfigText};
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
`;

export { Container, Row, Title, Text, TitleContainer };
