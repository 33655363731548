import { textStyles } from "@client/src/utils/textStyles";
import styled from "styled-components";
import { Tooltip } from "../tooltip/Tooltip.component";

export const Root = styled.div`
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;
  gap: 1rem;
`;
export const NavbarContainer = styled.div`
  background-color: ${(props) => props.theme.navBg};
  padding: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin: 0;

  @media (max-width: 768px) {
    padding: 0.5rem;
  }
`;

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 20;
`;

export const RightSide = styled.div`
  display: flex;
  padding: 0.125rem 0;
  align-items: center;
  gap: 0.5rem;
`;

export const HelpIcon = styled.div`
  display: flex;
  width: 1.75rem;
  height: 1.75rem;
  padding: 0.25rem;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  color: #818181;
  cursor: pointer;
`;

export const BellAndProfile = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 20;
  flex-direction: row;
  gap: 2rem;
  @media (max-width: 768px) {
    gap: 1rem;
  }
`;

export const StyledLink = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const NavBarLinkContainer = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
  justify-content: center;
  margin-left: 2rem;
`;

export const NavBarLink = styled.div<{ $currentlink: string }>`
  ${textStyles.textBase};
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  cursor: pointer;
  gap: 0.5rem;
  padding: 0.25rem 0.5rem 0.25rem 0.5rem;
  ${(props) =>
    props.$currentlink === "true"
      ? `
      display: none;
    `
      : ""}

  &:hover {
    border-bottom: 1px solid white;
  }
`;

export const TooltipBody = styled(Tooltip.Content)`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  & > p {
    all: unset;
  }
`;
