import { LineData, Time } from "lightweight-charts";
import { transformTsIntervalToTime } from "@components/chart/Chart.helpers";
import type { TAResponseV1 } from "@generated/ta/technical_analysis";
import Long from "long";
import { RsiChartState } from "./state";
import {
  addHueOffsetToColor,
  getChartIndicatorColor,
  hueOffsetFromIndex,
} from "../helpers/colors/utils";
import { BandsIndicator } from "./plugin/band-indicator";

const removeSeries = (state: RsiChartState) => {
  for (const series of state.currentLineSeries) {
    try {
      state.lwChart.removeSeries(series);
    } catch (e) {
      console.warn("Attempted to remove series that doesn't exist");
    }
  }
};

export const updateTechnicals = (
  state: RsiChartState,
  technicals: TAResponseV1 | null
) => {
  if (!technicals) {
    return;
  }

  removeSeries(state);

  const resultsToRender = technicals.results.filter(shouldRenderTechnical);
  state.currentLineSeries = resultsToRender.map((result, index) => {
    const series = state.lwChart.addLineSeries({
      color: addHueOffsetToColor(
        getChartIndicatorColor("RSI", state.theme),
        hueOffsetFromIndex(index)
      ),
      priceLineVisible: false,
      lastValueVisible: false,
      lineWidth: state.theme.chart.patterns.lineWidth,
    });

    series.applyOptions({
      autoscaleInfoProvider: () => ({
        priceRange: {
          minValue: 0,
          maxValue: 0.9,
        },
      }),
    });

    const seriesData = getTechnicalSeriesData(technicals, result);
    series.setData(seriesData);

    const rsiBand = new BandsIndicator();
    series.attachPrimitive(rsiBand);

    return series;
  });
};

const shouldRenderTechnical = (technical: TAResponseV1["results"][number]) => {
  if (technical.rsi) {
    return true;
  }
};

const getTechnicalSeriesData = (
  technicals: TAResponseV1,
  technical: TAResponseV1["results"][number]
): LineData<Time>[] => {
  return technical.values
    .map((val, idx) => ({
      time: createLong(technicals.candles[idx].ts_interval),
      value: val,
    }))
    .sort((a, b) => Number(a.time.sub(b.time)))
    .map((datum) => ({
      time: transformTsIntervalToTime(datum.time.toNumber()),
      value: datum.value,
    }));
};

const createLong = (input: Long) => {
  return new Long(input.low, input.high, input.unsigned);
};
