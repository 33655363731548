import { CanvasRenderingTarget2D } from 'fancy-canvas';
import {
  AutoscaleInfo,
  BarData,
  Coordinate,
  DataChangedScope,
  ISeriesPrimitive,
  ISeriesPrimitivePaneRenderer,
  ISeriesPrimitivePaneView,
  LineData,
  Logical,
  SeriesAttachedParameter,
  SeriesDataItemTypeMap,
  SeriesType,
  Time,
} from 'lightweight-charts';
import { PluginBase } from '../../plugins/plugin-base';
import { ClosestTimeIndexFinder } from '../../plugins/helpers/closest-index';
import { UpperLowerInRange } from '../../plugins/helpers/min-max-in-range';
import { colours } from '@client/src/themes';

interface BandRendererData {
  x: Coordinate | number;
  upper: Coordinate | number;
  middle: Coordinate | number;
  lower: Coordinate | number;
}

class BandsIndicatorPaneRenderer implements ISeriesPrimitivePaneRenderer {
  _viewData: BandViewData;
  constructor(data: BandViewData) {
    this._viewData = data;
  }
  draw() { }
  drawBackground(target: CanvasRenderingTarget2D) {
    const points: BandRendererData[] = this._viewData.data;
    target.useBitmapCoordinateSpace(scope => {
      const ctx = scope.context;
      ctx.scale(scope.horizontalPixelRatio, scope.verticalPixelRatio);

      ctx.lineWidth = this._viewData.options.lineWidth;
      ctx.setLineDash([5, 5]);
      ctx.beginPath();

      ctx.strokeStyle = this._viewData.options.upperLineColor;
      const upperLine = new Path2D();
      upperLine.moveTo(points[0].x, points[0].upper);
      for (const point of points) {
        upperLine.lineTo(point.x, point.upper);
      }
      ctx.stroke(upperLine);

      const end = points.length - 1;

      ctx.strokeStyle = this._viewData.options.midLineColor;
      const midLine = new Path2D();
      midLine.moveTo(points[0].x, points[0].middle);
      for (const point of points) {
        midLine.lineTo(point.x, points[0].middle);
      }
      ctx.stroke(midLine);

      ctx.strokeStyle = this._viewData.options.lowerLineColor;
      const lowerLine = new Path2D();
      lowerLine.moveTo(points[end].x, points[end].lower);
      for (let i = points.length - 2; i >= 0; i--) {
        lowerLine.lineTo(points[i].x, points[i].lower);
      }
      ctx.stroke(lowerLine);
    });
  }
}

interface BandViewData {
  data: BandRendererData[];
  options: Required<BandsIndicatorOptions>;
}

class BandsIndicatorPaneView implements ISeriesPrimitivePaneView {
  _source: BandsIndicator;
  _data: BandViewData;

  constructor(source: BandsIndicator) {
    this._source = source;
    this._data = {
      data: [],
      options: this._source._options,
    };
  }

  update() {
    const series = this._source.series;
    const timeScale = this._source.chart.timeScale();
    this._data.data = this._source._bandsData.map(d => {
      return {
        x: timeScale.timeToCoordinate(d.time) ?? -100,
        upper: series.priceToCoordinate(d.upper) ?? -100,
        middle: series.priceToCoordinate(d.middle) ?? -100,
        lower: series.priceToCoordinate(d.lower) ?? -100,
      };
    });
  }

  renderer() {
    return new BandsIndicatorPaneRenderer(this._data);
  }
}

interface BandData {
  time: Time;
  upper: number;
  lower: number;
  middle: number;
}

function extractPrice(
  dataPoint: SeriesDataItemTypeMap[SeriesType]
): number | undefined {
  if ((dataPoint as BarData).close) return (dataPoint as BarData).close;
  if ((dataPoint as LineData).value) return (dataPoint as LineData).value;
  return undefined;
}

export interface BandsIndicatorOptions {
  upperLineColor?: string;
  lowerLineColor?: string;
  midLineColor?: string;
  fillColor?: string;
  lineWidth?: number;
}

const defaults: Required<BandsIndicatorOptions> = {
  upperLineColor: colours.tertiary.green,
  lowerLineColor: colours.tertiary.red,
  midLineColor: colours['sigma-gray'][400],
  fillColor: 'rgba(25, 200, 100, 0)',
  lineWidth: 1,
};

export class BandsIndicator extends PluginBase implements ISeriesPrimitive<Time> {
  _paneViews: BandsIndicatorPaneView[];
  _seriesData: SeriesDataItemTypeMap[SeriesType][] = [];
  _bandsData: BandData[] = [];
  _options: Required<BandsIndicatorOptions>;
  _timeIndices: ClosestTimeIndexFinder<{ time: number }>;
  _upperLower: UpperLowerInRange<BandData>;

  constructor(options: BandsIndicatorOptions = {}) {
    super();
    this._options = { ...defaults, ...options };
    this._paneViews = [new BandsIndicatorPaneView(this)];
    this._timeIndices = new ClosestTimeIndexFinder([]);
    this._upperLower = new UpperLowerInRange([]);
  }

  updateAllViews() {
    this._paneViews.forEach(pw => pw.update());
  }

  paneViews() {
    return this._paneViews;
  }

  attached(p: SeriesAttachedParameter<Time>): void {
    super.attached(p);
    this.dataUpdated('full');
  }

  dataUpdated(scope: DataChangedScope) {
    // plugin base has fired a data changed event
    this._seriesData = JSON.parse(JSON.stringify(this.series.data()));
    this.calculateBands();
    if (scope === 'full') {
      this._timeIndices = new ClosestTimeIndexFinder(
        this._seriesData as { time: number }[]
      );
    }
  }

  _minValue: number = Number.POSITIVE_INFINITY;
  _maxValue: number = Number.NEGATIVE_INFINITY;
  calculateBands() {
    const bandData: BandData[] = new Array(this._seriesData.length);
    let index = 0;
    this._minValue = Number.POSITIVE_INFINITY;
    this._maxValue = Number.NEGATIVE_INFINITY;
    this._seriesData.forEach(d => {
      const price = extractPrice(d);
      if (price === undefined) return;
      const upper = 0.7;
      const lower = 0.3;
      const middle = 0.5;
      if (upper > this._maxValue) this._maxValue = upper;
      if (lower < this._minValue) this._minValue = lower;
      bandData[index] = {
        upper,
        lower,
        middle,
        time: d.time,
      };
      index += 1;
    });
    bandData.length = index;
    this._bandsData = bandData;
    this._upperLower = new UpperLowerInRange(this._bandsData, 4);
  }

  autoscaleInfo(startTimePoint: Logical, endTimePoint: Logical): AutoscaleInfo {
    const ts = this.chart.timeScale();
    const startTime = (ts.coordinateToTime(
      ts.logicalToCoordinate(startTimePoint) ?? 0
    ) ?? 0) as number;
    const endTime = (ts.coordinateToTime(
      ts.logicalToCoordinate(endTimePoint) ?? 5000000000
    ) ?? 5000000000) as number;
    const startIndex = this._timeIndices.findClosestIndex(startTime, 'left');
    const endIndex = this._timeIndices.findClosestIndex(endTime, 'right');
    const range = this._upperLower.getMinMax(startIndex, endIndex);
    return {
      priceRange: {
        minValue: range.lower,
        maxValue: range.upper,
      },
    };
  }
}
