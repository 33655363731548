/* eslint-disable indent */
import { BasePrimitive } from "./base";

/**
 * Creates styles primitive that can be used to create style themes
 * @param primitive
 * @returns
 */
export const createStylePrimitive = <Style extends BasePrimitive>(
  config: Style
) => config satisfies BasePrimitive;

export const createStyleTheme = <
  StyleTheme,
  Primitive extends BasePrimitive = BasePrimitive
>(
  primitive: Primitive,
  fn: (style: typeof primitive) => StyleTheme
) => fn(primitive);

export const createStyle = <Style extends BasePrimitive>(config: Style) => {
  const primitives = createStylePrimitive(config);
  const themer = <StyleTheme>(fn: (style: typeof primitives) => StyleTheme) =>
    fn(primitives);
  return [themer, primitives] as const;
};
