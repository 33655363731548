import React from "react";
import {
  Container,
  Content,
  IconContainer,
  Title,
  Description,
  TextContainer,
} from "./EmptyState.styles";

export type EmptyStateProps = {
  icon?: React.ComponentType;
  title: string;
  description?: string;
  buttonComponent?: JSX.Element;
};

const renderIcon = (Icon?: React.ComponentType) => {
  if (!Icon) return null;
  return (
    <IconContainer>
      <Icon />
    </IconContainer>
  );
};

const renderDescription = (description?: string) => {
  if (!description) return null;
  return (
    <Description>{description}</Description>
  );
};

export const EmptyState: React.FC<EmptyStateProps> = ({
  icon: Icon,
  title,
  description,
  buttonComponent,
}) => {
  return (
    <Container>
      <Content>
        {renderIcon(Icon)}
        <TextContainer>
          <Title>{title}</Title>
          {renderDescription(description)}
        </TextContainer>
        {buttonComponent}
      </Content>
    </Container>
  );
};
