import {
  CandleFrequencyMap,
  Candlestick,
} from "@generated/common/basic-types_pb";
import { PatternTypeMap } from "@generated/pattern/common_pb";
import { MovingAverageType } from "@generated/ta/moving_averages";

export type PatternConfigItem = {
  threshold: number;
  enabled: boolean;
  type: number;
};
export type ProductItem = {
  productName: string;
  productId: string;
  isAvailable: boolean;
  policies: {
    payment: {
      recurring: boolean;
      price: string;
    };
  };
};
export type RecurringPayload = {
  period: number;
  periodUnit: string;
};

export type PolicyPayload = {
  price: number;
  recurring: boolean | RecurringPayload;
};

export type SubscriptionDetails = {
  orderId: string;
  userId: string;
  createdAt: string;
  currentState: string;
  payload: PolicyPayload;
  productName: string;
  expiredAt?: string;
  renewAt?: string;
};

export type Portfolio = {
  id: string;
  name: string;
  hashcodes: string[];
  visibleInstrumentNames?: string[];
};

export type TechnicalConfigItem = {
  name: string;
  ma?: {
    kind: MovingAverageType;
    window_length: number;
  };
  rsi?: {
    main_kind: number;
    zone: number;
    source: number;
    main_period: number;
  };
  macd?: {
    fast_period: number;
    slow_period: number;
    line_period: number;
    source: number;
    fast_kind: number;
    slow_kind: number;
    line_kind: number;
  };
};

export interface AlertInfo {
  patternType: PatternTypeMap[keyof PatternTypeMap] | number;
  instrument: string;
  frequency: CandleFrequencyMap[keyof CandleFrequencyMap] | number;
  patternConfidence: number;
  condition?: string;
}
export interface AlertRecord {
  userId: string;
  alertInfo: AlertInfo;
  jobId: string;
  alertId: string;
  enabled: boolean;
  createdAt: string;
  deleted: boolean;
  read: boolean;
  firedAt?: string;
  eventId?: string;
}

export const DEFAULT_PATTERN_SCORE_THRESHOLD = 5;

export const GLOBAL_PATTERN_TYPE = -1;

export type CandleResponse = {
  hashcode: string;
  candles: Candlestick.AsObject[];
};

export type ScreeningMatch = {
  time: number;
  tag: string;
};

export type ChangeSinceMidnightSimplified = {
  instrumentHashcode: string;
  priceChange: string;
  percentChange: string;
};
