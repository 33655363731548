import React from "react";
import {
  SectionTitle,
  SectionWrapper,
} from "./Section.style";

type SectionProps = {
  title: string;
  children: React.ReactNode;
};

const Section = ({
  title,
  children,
}: SectionProps) => {
  return (
    <div>
      <SectionTitle>{title}</SectionTitle>
      <SectionWrapper>
        {children}
      </SectionWrapper>
    </div>
  );
};

export default Section;
