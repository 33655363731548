import React from "react";
import {
  ConditionBuilderBlock,
  useConditionBuilderContext,
} from "@client/src/context/ConditionBuilderContext";
import { blockReplacer } from "../condition_builder/ConditionBuilder.helper";
import { Container } from "./ConditionBuilderTechnical.style";
import { Input , InputContainer } from "../input/Input.style";
import SelectionDropdown from
  "../selection_dropdown/SelectionDropdown.component";
import { HiChevronDown } from "react-icons/hi";

const MacdOptions = ["VALUE", "SIGNAL"];

const renderDropdownItem = (item: string) => (
  <div>
    {item.charAt(0) + item.slice(1).toLowerCase()}
  </div>
);
const ConditionBuilderTechnical = ({
  block,
}: {
  block: ConditionBuilderBlock,
}) => {
  const {
    setBlock,
    block: rootBlock,
  } = useConditionBuilderContext();
  return (
    <Container>
      {block.operation}
      {block.parameters?.map((parameter) => {
        if (parameter.type === "dropdown") {
          return (
            <div key={`${block.id}-${parameter.type}-${parameter.name}`}>
              <SelectionDropdown
                items={MacdOptions}
                renderItem={renderDropdownItem}
                Icon={<HiChevronDown />}
                onSelect={(selectedItem) => {
                  const updatedBlock = blockReplacer(
                    block.id,
                    rootBlock,
                    {
                      ...block,
                      parameters: block.parameters?.map((p) => {
                        if (p.name === parameter.name) {
                          return {
                            ...p,
                            value: selectedItem,
                          };
                        }
                        return p;
                      }),
                    }
                  );
                  setBlock({ ...updatedBlock });
                }}
                defaultItem={parameter.value}
              />
            </div>
          );
        }
        return (
          <InputContainer
            key={`${block.id}-${parameter.type}-${parameter.name}`}>
            <Input
              type={parameter.type}
              value={parameter.value}
              onChange={(e) => {
                const updatedBlock = blockReplacer(
                  block.id,
                  rootBlock,
                  {
                    ...block,
                    parameters: block.parameters?.map((p) => {
                      if (p.name === parameter.name) {
                        return {
                          ...p,
                          value: e.target.value,
                        };
                      }
                      return p;
                    }),
                  }
                );
                setBlock({ ...updatedBlock });
              }}
            />
          </InputContainer>
        );
      })}
    </Container>
  );
};

export default ConditionBuilderTechnical;
