import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  background-color: ${(props) => props.theme.secondaryBackground};
  border-radius: 0.5rem;
  padding: 0.5rem 0.5rem 0.5rem 0.5rem;
  z-index: 200000;
  position: absolute;
  right: 5%;
  bottom: 10%;
`;

export const OptionContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem;
  border-radius: 0.5rem;
  border-style: none;
  color: ${(props) => props.theme.text};
  background-color: ${(props) => props.theme.secondaryBackground};

  &:hover {
    background-color: ${(props) => props.theme.instrumentButtonBg};
  }
`;
