import { TagBetaIcon } from "@assets/Icons";
import React from "react";
import { HiCheck } from "react-icons/hi2";
import { useUserContext } from "../../../context/user/UserContext";
import Button from "../../button/Button.component";
import { PlanItemData } from "../Plans.helper";
import { BetaLabel, BetaText } from "../Plans.style";
import {
  AnnualPrice,
  Body,
  BodyItemContainer,
  BodyItemList,
  ComingSoonText,
  Container,
  Divider,
  IncludedText,
  PreviousPrice,
  PreviousPriceContainer,
  Price,
  PriceContainer,
  PriceDescription,
  PriceInfoContainer,
  PriceRecurringUnit,
  Title,
  TitleContainer,
} from "./PlanItem.style";

type Props = {
  data: PlanItemData;
  onClick: () => void;
  hideButton?: boolean;
};
export const IncludedItem = ({ item }: { item: string }) => {
  return (
    <BodyItemContainer>
      <HiCheck color={"#ABABAB"} /> <IncludedText>{item}</IncludedText>
    </BodyItemContainer>
  );
};
export const ComingSoonItem = ({ item }: { item: string }) => {
  return (
    <BodyItemContainer>
      <HiCheck color={"#818181"} /> <ComingSoonText>{item}</ComingSoonText>
    </BodyItemContainer>
  );
};
const getPurchaseButtonText = () => {
  const { freeTrialAvailable, activeSubscription } = useUserContext();
  if (activeSubscription) return "Cancel Subscription";
  if (freeTrialAvailable) return "Start 30-day Free Trial";
  return "Subscribe";
};
const renderPurchaseButton = (hideButton: boolean) => {
  if (hideButton) return null;

  return (
    <Button text={getPurchaseButtonText()} type={"primary"} disabled={true} />
  );
};

const renderTitle = (data: PlanItemData) => (
  <TitleContainer>
    {" "}
    <Title>{data.title}</Title>
    <div>
      {data.saving && (
        <BetaLabel>
          <TagBetaIcon />
          <BetaText>{"Save " + data.saving}</BetaText>
        </BetaLabel>
      )}
    </div>
  </TitleContainer>
);

const renderPreviousPriceContent = (data: PlanItemData) => (
  <>
    {data.previousPrice && <PreviousPrice>{data.previousPrice}</PreviousPrice>}
    {data.annualPrice && <AnnualPrice>{data.annualPrice}</AnnualPrice>}
  </>
);

const PlanItem = ({ data, onClick, hideButton }: Props) => {
  return (
    <Container>
      <Body>
        {renderTitle(data)}
        <PriceContainer>
          <PreviousPriceContainer>
            {renderPreviousPriceContent(data)}
          </PreviousPriceContainer>
          <PriceInfoContainer>
            <Price>{data.price}</Price>
            <PriceRecurringUnit>{data.priceRecurringUnit}</PriceRecurringUnit>
          </PriceInfoContainer>
          <PriceDescription></PriceDescription>
        </PriceContainer>
        {renderPurchaseButton(hideButton || false, onClick)}
        <Divider />
        <BodyItemList>
          {data.includedItems.map((item, index) => (
            <IncludedItem item={item} key={index + item} />
          ))}
        </BodyItemList>
        <BodyItemList>
          {data.comingSoonItems.map((item, index) => (
            <ComingSoonItem item={item} key={index + item} />
          ))}
          <ComingSoonText>*coming soon</ComingSoonText>
        </BodyItemList>
      </Body>
    </Container>
  );
};

export default PlanItem;
