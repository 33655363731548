import {
  GetTokenSilentlyOptions,
  LogoutOptions,
  RedirectLoginOptions,
  useAuth0,
  User,
} from "@auth0/auth0-react";
import { SubscriptionDetails } from "@common/types";
import { useProfile } from "@hooks/useProfile";
import { useUserContextData } from "@hooks/useUserContextData";
import React, {
  createContext,
  PropsWithChildren,
  useContext,
  useEffect,
  useState,
} from "react";
import { AlertPreference } from "../app/preference/context";

type UserContextType = {
  groups: string[];
  user: User | undefined;
  logout: (options?: LogoutOptions | undefined) => Promise<void>;
  isAuthenticated?: boolean;
  loginWithRedirect: (
    options?: RedirectLoginOptions | undefined
  ) => Promise<void>;
  getAccessTokenSilently: (
    options?: GetTokenSilentlyOptions
  ) => Promise<string>;
  isSubscribed: boolean;
  showAccessDeniedDialog: boolean;
  setShowAccessDeniedDialog: (show: boolean) => void;
  credits: number;
  activeSubscription?: SubscriptionDetails | undefined;
  updateContext: () => void;
  alertPreferenceData?: AlertPreferencesData | null;
  updateAlertPreferences: () => void;
  freeTrialAvailable: boolean;
  applyNetworkAlertPreferences: (
    alertType: "email" | "web" | "telegram",
    requestType: "enable" | "disable"
  ) => Promise<void>;
  applyAlertPreferencesData: (data: AlertPreferencesData) => void;
};

const defaultUserContext: UserContextType = {
  groups: [],
  user: undefined,
  logout: async () => {
    return;
  },
  isAuthenticated: false,
  loginWithRedirect: async () => {
    return;
  },
  getAccessTokenSilently: async () => {
    return "";
  },
  isSubscribed: false,
  showAccessDeniedDialog: false,
  setShowAccessDeniedDialog: () => {
    return;
  },
  credits: 0,
  activeSubscription: undefined,
  updateContext: () => {
    return;
  },
  alertPreferenceData: null,
  updateAlertPreferences: () => {
    return;
  },
  freeTrialAvailable: true,
  applyNetworkAlertPreferences: async () => {
    return;
  },
  applyAlertPreferencesData: () => {
    return;
  },
};
const SubscriptionGroups = [
  "free",
  "lite",
  "pro",
  "ultimate",
  "beta_ultimate",
  "secret_icon",
];

const hasSubscriptionGroup = (groups: string[]): boolean => {
  if (!groups) return false;
  return groups.some((group) => SubscriptionGroups.includes(group));
};

export type AlertPreferencesData = {
  enabledChannels: AlertPreference[];
  webEnabled: boolean;
  emailEnabled: boolean;
  telegramEnabled: boolean;
  notificationsEnabled: boolean;
};

export const UserContext = createContext(defaultUserContext);
const UserProvider = (props: PropsWithChildren<object>) => {
  const {
    user,
    loginWithRedirect,
    logout,
    isAuthenticated,
    getAccessTokenSilently,
  } = useAuth0();
  const [showAccessDeniedDialog, setShowAccessDeniedDialog] = useState(false);
  const {
    availableCredits,
    groups,
    activeSubscription,
    updateContext,
    freeTrialAvailable,
  } = useUserContextData();
  const [alertPreferenceData, setAlertPreferenceData] =
    useState<AlertPreferencesData | null>(null);
  const { updateAlertPreferences: applyAlertPreferences } = useProfile();
  const applyAlertPreferencesData = (data: AlertPreferencesData) => {
    setAlertPreferenceData(data);
  };
  const fetchAlertPreferences = () => {
    getAccessTokenSilently().then((token: string) => {
      fetch("/api/alerts/preferences", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
        .then((res) => res.json())
        .then((data) => {
          const enabledChannels = data.alertChannels;
          setAlertPreferenceData({
            enabledChannels: enabledChannels as AlertPreference[],
            webEnabled: enabledChannels.includes("web") as boolean,
            emailEnabled: enabledChannels.includes("email") as boolean,
            telegramEnabled: enabledChannels.includes("telegram") as boolean,
            notificationsEnabled: data.notificationsEnabled as boolean,
          });
        })
        .catch(() => {
          return null;
        });
    });
  };

  useEffect(() => {
    fetchAlertPreferences();
  }, []);

  return (
    <UserContext.Provider
      value={{
        groups,
        user,
        logout,
        isAuthenticated,
        loginWithRedirect,
        isSubscribed: hasSubscriptionGroup(groups),
        showAccessDeniedDialog,
        setShowAccessDeniedDialog,
        getAccessTokenSilently,
        credits: availableCredits,
        activeSubscription,
        updateContext,
        alertPreferenceData: alertPreferenceData,
        updateAlertPreferences: fetchAlertPreferences,
        freeTrialAvailable,
        applyNetworkAlertPreferences: applyAlertPreferences,
        applyAlertPreferencesData,
      }}
    >
      {props.children}
    </UserContext.Provider>
  );
};

const useUserContext = () => useContext(UserContext);

export { UserProvider, useUserContext };
export type { UserContextType };
