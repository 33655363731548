import { setAimaShown } from "@utils/aima_utils";
import React from "react";
import { BetaTag } from "../label_types/beta_tag/BetaTag.component";
import Button from "../button/Button.component";
import {
  Body,
  Buttons,
  Container,
  Content,
  Header,
  Text,
  Title,
} from "./AIMATooltip.styles";

interface AIMATooltipProps {
  onClickDismiss: () => void;
}
const AIMaTooltip = ({ onClickDismiss }: AIMATooltipProps) => {
  return (
    <Container data-testid="aima-container">
      <Content>
        <Header>
          <BetaTag />
        </Header>
        <Body>
          <Title>AIMA: See the market at a glance</Title>
          <Text>
            Leopard AIMA identifies and summarises noteworthy trends from the
            last day's market events from 8 AM UTC for select instruments.
          </Text>
        </Body>
      </Content>
      <Buttons>
        <Button
          text="Learn more"
          onClick={() => {
            window.open(
              "https://support.runleopard.ai/hc/en-gb/articles/20941379577105",
              "_blank"
            );
          }}
          type="secondary"
          size="sm"
          data-testid="aima-learn-more-button"
        />
        <Button
          text="Got it!"
          onClick={() => {
            setAimaShown();
            onClickDismiss();
          }}
          type="primary"
          size="sm"
          data-testid="aima-dismiss-button"
        />
      </Buttons>
    </Container>
  );
};
export default AIMaTooltip;
