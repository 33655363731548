import { TagGoldIcon } from "@assets/Icons";
import { useSubscriptions } from "@hooks/useSubscriptions";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { HiChevronRight } from "react-icons/hi2";
import { NavigateFunction, useNavigate } from "react-router-dom";
import { Slide, ToastContainer } from "react-toastify";
import { useTheme } from "styled-components";
import { useUserContext } from "../../context/user/UserContext";
import { openCancelConfirmation } from "../../pages/profile/Profile.component";
import Button from "../button/Button.component";
import Footer from "../footer/Footer.component";
import { Tabbar } from "../tabbar/Tabbar.component";
import ComparedPlans from "./compared_plans/ComparedPlans.component";
import {
  aimaRowData,
  alertsRowData,
  featuresRowData,
  gridColumns,
  instrumentsRowData,
  technicalsRowData,
} from "./compared_plans/ComparedPlans.helper";
import PlanItem from "./item/PlanItem.component";
import {
  InfoModal,
  PlanItemData,
  planItemsAnnually,
  planItemsMonthly,
  subTabs,
} from "./Plans.helper";
import {
  ComparedPlansSection,
  ComparedPlansTitle,
  Container,
  Content,
  GoldText,
  PlanItemContainer,
  SkipButtonContainer,
  SubLabelInfo,
  Subtitle,
  Title,
  TopBar,
  TopBarContent,
} from "./Plans.style";
// eslint-disable-next-line max-len
import TermsDialogWrapper from "../terms_dialog_wrapper/TermsDialogWrapper.component";

const renderTopBar = () => {
  const navigate = useNavigate();
  const [isSticky, setIsSticky] = useState(false);
  const observerRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsSticky(!entry.isIntersecting);
      },
      { threshold: [1] }
    );

    const dummyDiv = observerRef.current;
    if (dummyDiv) {
      observer.observe(dummyDiv);
    }

    return () => {
      if (dummyDiv) {
        observer.unobserve(dummyDiv);
      }
    };
  }, []);
  return (
    <>
      <div ref={observerRef} style={{ height: "1px" }} />
      <TopBar>
        <TopBarContent>
          <SkipButtonContainer $isSticky={isSticky}>
            <Button
              text={"Skip"}
              onClick={() => navigate("/")}
              type={"secondary"}
              rightIcon={<HiChevronRight />}
            />
          </SkipButtonContainer>
        </TopBarContent>
      </TopBar>
    </>
  );
};

const navigateToPurchase = (item: PlanItemData, navigate: NavigateFunction) => {
  const productNameWithSuffix = `${item.title
    .toLowerCase()
    .replace(/\s/g, "_")}${item.suffix.toLowerCase().replace(/\s/g, "")}`;
  navigate(`/plans/${productNameWithSuffix}`);
};

const PlansBody = () => {
  const [selectedTab, setSelectedTab] = useState(subTabs[1].id);
  const [items, setItems] = useState<PlanItemData[]>(planItemsMonthly);
  const [showCancelSubModal, setShowCancelSubModal] = useState(false);
  const { activeSubscription } = useUserContext();
  const { cancelSubscription } = useSubscriptions();
  const navigate = useNavigate();
  useEffect(() => {
    if (selectedTab === "1") {
      setItems(planItemsMonthly);
    } else {
      setItems(planItemsAnnually);
    }
  }, [selectedTab]);
  const handlePlanClick = (
    item: PlanItemData,
    onCancelSubscription: () => void
  ) => {
    if (activeSubscription) {
      onCancelSubscription();
      return;
    }
    navigateToPurchase(item, navigate);
  };

  return (
    <>
      {showCancelSubModal &&
        openCancelConfirmation(
          () => setShowCancelSubModal(false),
          async () => {
            await cancelSubscription(activeSubscription);
            setShowCancelSubModal(false);
          },
          activeSubscription
        )}
      <Tabbar
        avoidSticky={true}
        autoWidth={true}
        onTabSelectedChange={(tab) => {
          setSelectedTab(tab);
        }}
        variant={"gold"}
        data={subTabs}
        selectedTab={selectedTab}
      />
      <SubLabelInfo>
        <TagGoldIcon />
        <GoldText>Get 2 months free Annually</GoldText>
      </SubLabelInfo>
      <PlanItemContainer>
        {items.map((item, index) => (
          <PlanItem
            data={item}
            onClick={() =>
              handlePlanClick(item, () => setShowCancelSubModal(true))
            }
            key={index + item.price}
          />
        ))}
      </PlanItemContainer>
    </>
  );
};

const renderComparedPlans = () => (
  <ComparedPlansSection>
    <ComparedPlansTitle>Compare Plans</ComparedPlansTitle>
    <ComparedPlans
      title={"Instruments"}
      rows={instrumentsRowData}
      columns={gridColumns}
    />
    <ComparedPlans
      title={"Alerts"}
      rows={alertsRowData}
      columns={gridColumns}
    />
    <ComparedPlans
      title={"Technicals"}
      rows={technicalsRowData}
      columns={gridColumns}
    />
    <ComparedPlans
      title={"AI Market Analysis"}
      rows={aimaRowData}
      columns={gridColumns}
    />
    <ComparedPlans
      title={"Features"}
      rows={featuresRowData}
      columns={gridColumns}
    />
  </ComparedPlansSection>
);

const Plans = () => {
  const theme = useTheme();
  const [isInfoModalVisible, setIsInfoModalVisible] = useState(true);
  const handleInfoModalClick = useCallback(() => {
    setIsInfoModalVisible(false);
  }, []);
  return (
    <Container>
      <InfoModal
        onClick={handleInfoModalClick}
        isVisible={isInfoModalVisible}
      />
      {renderTopBar()}
      <Content>
        <Title>Find the right plan, for you</Title>
        <Subtitle>
          Whether you’re just starting out or a Pro, we’ve got a plan for you
        </Subtitle>
        <PlansBody />
        {renderComparedPlans()}
      </Content>
      <Footer />
      <ToastContainer
        toastStyle={{
          backgroundColor: theme?.toastProperties?.background,
        }}
        style={{
          maxWidth: "30rem",
          width: "100%",
          padding: "0.5rem",
        }}
        position={"top-left"}
        autoClose={5000}
        hideProgressBar={true}
        newestOnTop={true}
        transition={Slide}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
      />
      <TermsDialogWrapper />
    </Container>
  );
};

export default Plans;
