import { AlertRecord } from "@common/types";
import { useAlerts } from "@hooks/useAlerts";
import React, {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
type AlertContextType = {
  alerts: AlertRecord[];
  setAlerts: (alerts: AlertRecord[]) => void;
  userAlerts: AlertRecord[];
  setUserAlerts: (alerts: AlertRecord[]) => void;
  fetchUserAlerts: () => void;
  fetchAlertStatus: () => void;
  alertStatus: boolean;
  setAlertStatus: (status: boolean) => void;
  showInbox: () => void;
  closeInbox: () => void;
  isInboxVisible: boolean;
  selectedTab: string;
  setSelectedTab: (tab: string) => void;
};

export const TAB_OPTIONS = [
  { title: "Inbox", id: "inbox" as const },
  { title: "Manage", id: "manage" as const },
];

const defaultAlertContext: AlertContextType = {
  alerts: [],
  setAlerts: (alerts: AlertRecord[]) => {
    return alerts;
  },
  userAlerts: [],
  setUserAlerts: (alerts: AlertRecord[]) => {
    return alerts;
  },
  fetchUserAlerts: () => {
    return;
  },
  fetchAlertStatus: () => {
    return;
  },
  showInbox: () => {
    return;
  },
  closeInbox: () => {
    return;
  },
  isInboxVisible: false,
  selectedTab: "inbox",
  setSelectedTab: (tab: string) => {
    return tab;
  },
  alertStatus: false, // Default value for alertStatus
  setAlertStatus: (status: boolean) => {
    return status;
  },
};

export const AlertContext = createContext(defaultAlertContext);

const AlertProvider = (props: PropsWithChildren<object>) => {
  const openManageInboxTab = useCallback(() => {
    setSelectedTab("manage");
    setIsInboxVisible(true);
  }, []);
  const {
    alerts: networkAlerts,
    getUserAlerts,
    userAlerts: networkUserAlerts,
    getInbox,
    getAlertStatus,
  } = useAlerts(openManageInboxTab);
  const [isInboxVisible, setIsInboxVisible] = React.useState(false);
  const [alerts, setAlerts] = useState<AlertRecord[]>([]);
  const [selectedTab, setSelectedTab] = React.useState("inbox");
  const [userAlerts, setUserAlerts] = useState<AlertRecord[]>([]);
  const [alertStatus, setAlertStatus] = useState<boolean>(false);

  const setAlertsCallback = useCallback((newAlerts: AlertRecord[]) => {
    if (JSON.stringify(alerts) !== JSON.stringify(newAlerts)) {
      setAlerts(newAlerts);
    }
  }, []);

  const fetchUserAlerts = useCallback(() => {
    getUserAlerts();
  }, []);

  const fetchAlertStatus = useCallback(async () => {
    const status = await getAlertStatus();
    if (status) {
      setAlertStatus(status.notificationsEnabled);
    }
  }, [getAlertStatus]);

  const showInbox = useCallback(() => {
    setIsInboxVisible(true);
  }, []);

  const closeInbox = useCallback(() => setIsInboxVisible(false), []);
  useEffect(() => {
    setAlertsCallback(networkAlerts);
  }, [networkAlerts]);

  useEffect(() => {
    setUserAlerts(networkUserAlerts);
  }, [networkUserAlerts]);

  useEffect(() => {
    getInbox();
    getUserAlerts();
  }, []);

  return (
    <AlertContext.Provider
      value={{
        alerts,
        setAlerts: setAlertsCallback,
        userAlerts,
        setUserAlerts,
        fetchUserAlerts,
        fetchAlertStatus,
        alertStatus,
        setAlertStatus,
        showInbox,
        closeInbox,
        isInboxVisible,
        selectedTab,
        setSelectedTab,
      }}
    >
      {props.children}
    </AlertContext.Provider>
  );
};

const useAlertContext = () => useContext(AlertContext);

export { AlertProvider, useAlertContext };
export type { AlertContextType };
