import { useAuth0 } from "@auth0/auth0-react";
import { CandleResponse, ChangeSinceMidnightSimplified } from "@common/types";
import {
  CandleFrequency,
  CandleFrequencyMap,
} from "@generated/common/basic-types_pb";
import { ChangeSinceMidnight } from "@generated/query/query_pb";
import { useEffect, useMemo, useState } from "react";
import { useInstrumentContext } from "../context/InstrumentContext";
import usePooling from "./usePooling";

const calculateChanges = (midnightData: ChangeSinceMidnight.AsObject[]) => {
  return midnightData.map((instrument) => {
    const { current, midnight } = instrument;
    let priceChange;
    if (current.toFixed(4) === midnight.toFixed(4)) {
      priceChange = (0).toFixed(4);
    } else {
      priceChange = (current - midnight).toFixed(4);
    }
    const percentChange = (((current - midnight) / midnight) * 100).toFixed(2);

    return {
      instrumentHashcode: instrument.instrumenthashcode,
      priceChange,
      percentChange,
    } as ChangeSinceMidnightSimplified;
  });
};

const getPrices = async (
  instrumentList: string[],
  frequency: CandleFrequencyMap | number,
  token: string,
  signal?: AbortSignal | null
) => {
  return await fetch("/api/poll-prices", {
    method: "POST",
    body: JSON.stringify({
      instruments: instrumentList,
      frequency: frequency,
    }),
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    signal,
  });
};

const getMidnightChange = async (
  instrumentList: string[],
  token: string,
  signal?: AbortSignal | null
) => {
  return await fetch("/api/midnight-change", {
    method: "POST",
    body: JSON.stringify({
      instruments: instrumentList,
    }),
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    signal,
  });
};

const parseMidnightChangeData = (data: {
  queryList: ChangeSinceMidnight.AsObject[];
}) => {
  const rawData = calculateChanges(data.queryList);
  return rawData.map((item) => ({
    instrumentHashcode: item.instrumentHashcode,
    priceChange: item.priceChange,
    percentChange: item.percentChange,
  }));
};

export const useScreeningData = () => {
  const { instruments } = useInstrumentContext();
  const [instrumentList, setInstrumentList] = useState<string[]>([]);
  const { getAccessTokenSilently } = useAuth0();
  const [latestCandles, setLatestCandles] = useState<CandleResponse[]>([]);
  const [midnightChange, setMidnightChange] = useState<
    ChangeSinceMidnightSimplified[]
  >([]);
  const [loading, setLoading] = useState(true);
  const [frequency, setFrequency] = useState<typeof CandleFrequency | number>(
    CandleFrequency.H1
  );
  const [midnightFirstLoad, setMidnightFirstLoad] = useState(false);
  const [pricesFirstLoad, setPricesFirstLoad] = useState(false);
  const memoInstruments = useMemo(() => instruments, [instruments]);
  const handleFirstLoad = () => {
    getAccessTokenSilently().then((token) => {
      getMidnightChange(instrumentList, token, null).then(async (response) => {
        if (response.ok) {
          const data = await response.json();
          const simplifiedData = parseMidnightChangeData(data);
          setMidnightChange(simplifiedData);
        }
        setMidnightFirstLoad(true);
      });
      getPrices(
        memoInstruments.map((instrument) => instrument.hashcode),
        frequency,
        token,
        null
      ).then(async (response) => {
        if (response.ok) {
          setLatestCandles(await response.json());
        }
        setPricesFirstLoad(true);
      });
    });
  };

  useEffect(() => {
    if (midnightFirstLoad && pricesFirstLoad) setLoading(false);
  }, [midnightFirstLoad, pricesFirstLoad]);
  useEffect(() => {
    if (memoInstruments.length > 0) {
      handleFirstLoad();
    }
  }, [memoInstruments]);
  const instrumentHashcodes = useMemo(
    () => instruments.map((instrument) => instrument.hashcode),
    [instruments]
  );
  usePooling(
    async ({ signal }) => {
      const token = await getAccessTokenSilently();
      if (instrumentHashcodes.length === 0) return;
      const response = await getPrices(
        instrumentHashcodes,
        frequency,
        token,
        signal
      );
      if (!response.ok) {
        return;
      }
      setLatestCandles(await response.json());
    },
    { pollIntervalInMs: 100000 },
    [frequency, instrumentHashcodes]
  );

  usePooling(
    async ({ signal }) => {
      const token = await getAccessTokenSilently();
      if (instrumentHashcodes.length === 0) return;
      const response = await getMidnightChange(
        instrumentHashcodes,
        token,
        signal
      );
      if (!response.ok) {
        return;
      }
      const data = await response.json();
      const simplifiedData = parseMidnightChangeData(data);
      setMidnightChange(simplifiedData);
    },
    { pollIntervalInMs: 100000 },
    [instrumentHashcodes]
  );
  return {
    latestCandles,
    midnightChange,
    frequency,
    setFrequency,
    instruments: loading ? [] : memoInstruments,
    instrumentList,
    setInstrumentList,
  };
};
