export const termsContent = `
**Sigma Financial Trading Limited** ('**Sigma**' or '**we**') owns and
operates the Leopard AI platform which provides Users with the ability
to research technical trading data, analyse trading momentum, spot
trading patterns, to test trading strategies and to generate market
summaries ('**Services**') with a view to the identification of trading
opportunities based on the wants needs and risk profile of individual
Users.

**Application of Terms:** These Terms ('**Terms**') constitute legally
binding terms and govern use of the Services by all Users to the
Services ('**Users**' or '**you**')). Users are **strongly advised** to
familiarise themselves with these Terms before using the Services.

**1. LICENCE**


(a) Sigma hereby grants to the User a non-exclusive, non-transferable
licence (this **Agreement**) to use the Services on these Terms for
the Term specified in Condition 4 below. Users acquire no rights in
the Services or the material comprised therein whatsoever other than
the limited right to use the Services in accordance with these
Terms.

(b) Sigma will use its reasonable endeavours to provide the Services in
an efficient and timely manner, using the skill and expertise and to
a professional standard.


**2. REGISTRATION AND ACCOUNT CREATION**


(a) As part of the registration and account creation process necessary
to obtain access to certain portions of the Services Users will
select a username and a password. Users will be required to provide
Sigma with certain registration information, all of which must be
accurate, truthful, and updated. Users may not:


> (i) select a username already used by another person;

> (ii) create an account for anyone other than yourself without
> permission;

> (iii) use a username in which another person has rights without such
> person's authorisation; or

> (iv) use a username or password that Sigma, in its sole discretion,
> deems offensive or inappropriate.


(b) Sigma reserves the right to deny creation of a User account if it is
unable to verify the authenticity of registration information. A
User shall be solely responsible for maintaining the confidentiality
of their password. Users shall immediately notify Sigma of any known
or suspected unauthorised use(s) of their account, or any known or
suspected breach of security, including loss, theft, or unauthorised
disclosure of their password or payment card information. Users are
fully and solely responsible for all usage and activity on their
account, including, but not limited to, use of the account by any
third party authorised by them to use their username and password.

(c) All Users agree that they will not sell, transfer or assign access
to their account or any User rights.


**3. AGE RESTRICTION**


(a) The use of the Service by any individual under age thirteen (13) is
strictly prohibited.

(b) If a User is over 13 years of old but under 18 years (a young User)
, then a parent or legal guardian must give permission for the young
User to use the Services and confirm he or she has done so and must
read and discuss these Terms with the young User to ensure
understanding by the young User that the right to use the Services
is on these Terms and will be deemed to have done so.

(c) This Agreement will apply to any parent or guardian allowing their
child to use the Services and such parent or guardian will be
responsible for all activity on the account by such child.


**4. TERM AND TERMINATION**


(a) You may terminate the Agreement at any time by notifying Sigma in
writing whereupon the Services will be terminated one (1) month
after receipt of such notification.

(b) Sigma may terminate the Agreement at any time by notifying the User
in writing whereby the Services will be terminated one (1) month
after receipt of such notification.

(c) You agree that, Sigma may terminate its Agreement with you, or
suspend your access to the Services, with or without cause at any
time and effective immediately by notice in writing if you, in
Sigma's sole discretion acting reasonably, fails to comply with any
provision of these Terms.

(d) Sigma shall not be liable to you or to any third party for the
termination or suspension of the Services, or for any claims related
to the termination or suspension of the Services. Upon termination
of your Agreement for any reason, you agree to destroy promptly all
materials obtained from the Services and any copies thereof.

(e) On termination of this Agreement, any purchased Alerts (as referred
to in clause 5(b) below) not used by you will be forfeited. No
refund will be given for any unused Alerts.


**5. CHARGES AND PAYMENTS**


(a) The Services are provided free of charge.

(b) The User shall be notified of a trading opportunity by 'Alerts'. An
Alert is an electronic notification to a User's mobile device and or
email of the identification of a trading opportunity based on the
User's preferences. Alerts can be purchased in packages i.e. 50,
100, 500, etc. Please see Sigma website for more details.

(c) Alert packages can be purchased via the Sigma website. All debit
card, credit card and crypto payments are conducted via third-party
payment systems.


**6. RESTRICTIONS ON USE**


(a) You may not use the Services for any illegal purpose, for the
violation of any law or regulation, or in any manner inconsistent
with these Terms. You agree to use the Services solely for your own
non-commercial use and benefit, and not for resale or other transfer
or disposition to, or use by or for the benefit of, any other person
or entity.

(b) Except as specified below and as otherwise expressly permitted by
Sigma in writing, you may not copy, reproduce, recompile, decompile,
disassemble, reverse engineer, distribute, publish, display,
perform, modify, upload to, create derivative works from, transmit,
transfer, sell, license, upload, edit post, frame, link, or in any
way exploit any part of the Services, except that you may download
material from the Services and/or make one print copy for your own
personal, non-commercial use, provided that any downloaded material
retains all copyright and other proprietary notices. You may not
recirculate, redistribute or publish the analysis and presentation
included in the Services without Sigma's express prior written
consent. Notwithstanding the foregoing you may take screenshots of
graphs and patterns displayed within the Services and of Alerts and
may redistribute these via social media. Any use of the Services
other than as permitted by these Terms will violate the Terms and
may infringe upon our rights or the rights of the third party that
owns the affected Services. You agree to report to Sigma immediately
any violation of these Terms that you become aware of by others.

(c) You may not offer any part of the Services for sale or distribution
over any other medium including but not limited to over-the-air
television or radio broadcast, a computer network or hyperlink
framing on the internet without the express prior written consent of
Sigma.

(d) The Services and the information contained therein may not be used
(in whole or in part) to construct a database of any kind.

(e) You may not use any of the trademarks, trade names, service marks,
copyrights, or logos of Sigma in any manner which creates the
impression that such items belong to or are associated with you or
are used with Sigma's consent, and you acknowledge that you have no
ownership rights in and to any of such items.

(f) You may neither interfere with nor attempt to interfere with nor
otherwise disrupt the proper working of the Services. You may
neither obtain nor attempt to obtain through any means any materials
or information on the Services that have not been intentionally made
publicly available either by public display on the Services or
through accessibility by a visible link on the Services. You shall
not violate the security of the Service or attempt to gain
unauthorised access to the Services, data, materials, information,
computer systems or networks connected to any server associated with
the Services, through hacking, password timing or any other means.
You may neither take nor attempt any action that, in the sole
discretion of Sigma, imposes or may impose an unreasonable or
disproportionately large load or burden on the Services or the
infrastructure of the Services. You shall not use or attempt to use
any "scraper," "robot," "bot," "spider," "data mining," "computer
code," or any other automate device, program, tool, algorithm,
process or methodology to access, acquire, copy, or monitor any
portion of the Services, any data or content found on or accessed
through the Services without the prior express prior written consent
of Sigma. You may not forge headers or otherwise manipulate
identifiers in order to disguise the origin of any content obtained
from the Services.


**7. INTELLECTUAL PROPERTY**


(a) The User agrees that all intellectual property rights in the
Services and in the trade names, trademarks, service marks, patents,
design rights, brand names and/or logos of Sigma including, without
limitation, all future rights, shall at all times remain the
exclusive property of Sigma and its suppliers.

(b) Nothing in this Agreement shall prevent or restrict Sigma from using
and exploiting, techniques, software tools, ideas, know-how and
other intellectual property rights, created, used or developed
during the provision of the Service for any purpose whatsoever.

(c) Notwithstanding anything in this Agreement to the contrary, subject
to the Proviso below, Sigma agrees to indemnify the User and to keep
it indemnified and hold it harmless and at Sigma's expense defend
the User against all and any losses, costs, damages, awards and
expenses arising from any claim that the programs, or any other
items comprised in the Services infringe any intellectual property
right (including without limitation any copyright, trademark,
patent, design or any other contractual, statutory or common law
right) or any other right of whatsoever nature of any third party or
otherwise breach any licence or agreement to which Sigma is party
**PROVIDED ALWAYS** that:


> (i)  the User promptly notifies Sigma of such claim and does not attempt
> to settle the claim in any way; and

> (ii) Sigma shall have control of the conduct of any action in respect of
> such claim; and

> (iii\) the User co-operates with Sigma in every reasonable way in
> defending such claim at the cost of Sigma.

**8. DATA PROTECTION LEGISLATION**


(a) Sigma undertakes to comply fully with the provisions of the UK
General Data Protection Regulation 2021 and the Data Protection Act
2018 that incorporates the GDPR into English law, and all other
applicable data protection legislation ('**Data Protection
Legislation'**) and Sigma shall procure that its employees, agents
and contractors observe the provisions of all Data Protection
Legislation. If Sigma receives '**personal data'** (as defined in
Data Protection Legislation) pursuant to these Terms (including in
the registration process), Sigma shall only use it as strictly
necessary for the performance of its obligations hereunder. Sigma
shall not transfer personal data or otherwise process it in any way
contrary to any applicable Data Protection Legislation.

(b) Sigma shall implement strict and adequate security and technical and
organisational procedures in respect of the integrity and
confidentiality of any personal data that comes into its possession
to ensure that such personal data will not be recorded, disclosed,
processed, deleted, altered, used or otherwise tampered with in an
unauthorised or accidental manner and that such personal data is
protected in accordance with relevant Data Protection Legislation.

For further information please see Sigma's Privacy policy on our
website.

**9. DISCLAIMERS**


(a) You agree that your use of the Services is at your sole risk and
acknowledge that the Services and anything contained within the
Services are provided "as is" and "as available," and that Sigma
makes no warranty of any kind, express or implied, as to the
Services or their content, including, but not limited to,
merchantability, non-infringement, title, or fitness for a
particular purpose or use.

(b) Sigma gives no warranty and assumes no obligation or liability for
scripts, indicators, ideas and other content of third parties. Your
use of any third-party scripts, indicators, ideas and other content
is at your sole risk.

(c) Hypothetical performance results have many inherent limitations. No
representation is made at any time that any account of any User or
any other person will or is likely to achieve profits or losses
similar to those shown. In reality, there are frequently sharp
differences between hypothetical performance results and actual
results subsequently achieved by any particular trading program. It
should be particularly noted that one of the limitations of
hypothetical performance results is that they are generally prepared
with the benefit of hindsight.

(d) Sigma does not warrant that the availability of or the functions
contained in the Services will be uninterrupted or error-free, that
defects will be corrected, or that the server that makes the
Services available is free of viruses or other harmful components or
that the Services or server do not violate any patent or other
intellectual property rights of any person or entity.

(e) Sigma does not warrant or make any representations regarding the use
or the results of the use of the Services in terms of their
correctness, accuracy, reliability, or otherwise.

(f) Sigma does not warrant that the Services are compatible with your
equipment or that the Services, or any e-mail sent by Sigma or its
representative, are free of errors or viruses, worms or "Trojan
horses," or any other harmful, invasive, or corrupted files, and
Sigma shall have no liability for any damage you may suffer as a
result of such destructive features.

(g) The Services include certain information taken from stock exchanges,
other trading venues, news providers and other information sources
from around the world. You acknowledge that no assurance or
guarantee of the sequence, accuracy, completeness, or timeliness of
the Services or any information, facts, views, opinions, statements
or recommendations contained within the Services.

(h) None of the information contained within the Services constitutes a
solicitation, offer, opinion, endorsement or recommendation by Sigma
to buy or sell any security, or to provide legal, tax, accounting,
or investment advice or services regarding the profitability or
suitability of any security or investment.

(i) Sigma shall not, directly or indirectly, be liable, in any way, to
you or any other person for any inaccuracies or errors in or
omissions from the Services including, but not limited to, quotes
and financial data, delays, errors, or interruptions in the
transmission or delivery of the Services or loss or damage of any
kind arising therefrom or occasioned thereby.


**10. LIMITATION OF LIABILITY**


(a) Sigma makes no warranty, either express or implied, as to results to
be attained by the User or others from the use of the Services and
does not give any express or implied warranty in relation to the
Services of merchantability or fitness for any particular purpose or
use.

(b) The software programs contained in the Services are deemed reliable
but Sigma does not guarantee the correctness or completeness of any
program, data or other information provided in connection with or as
part of the Services.

(c) Sigma shall not be liable for any damage to the User arising from
delays or interruptions in the provision of the Services or
breakdown of the Services.

(d) Sigma shall have no liability or responsibility for the security or
maintenance of any data input into the Services made by the User or
for the loss or corruption of any of the User's data.

(e) Notwithstanding and without prejudice to any other provision of this
Agreement Sigma shall not be liable for loss of profits or
consequential or indirect losses of any kind suffered by the User or
for any punitive or exemplary damages howsoever arising.

(f) No action arising out of or pertaining to provision of the Services
may be brought by the User more than six (6) months after the User
becomes aware of the cause of action.

(g) The User acknowledges that, in entering into this Agreement, it is
not relying on any representation, warranty or other assurance
except those set out in this Agreement and the User unconditionally
and irrevocably waives all claims for in respect of any other
representation (negligent or otherwise) unless made fraudulently.

(h) The User agrees to indemnify Sigma against all losses, claims,
expenses, costs, liability and damages suffered or incurred by Sigma
in connection with any claim or action against Sigma by any client
of the User.

(i) The User acknowledges that Sigma will not be liable for any damage
caused to the User's reputation or goodwill arising from any
inaccuracy, error or incompleteness in the Services.

(j) This Condition 9 shall extend to Sigma's liability in tort (if
applicable) as well as in contract in relation to the Services.

(k) No limit on liability shall apply to claims (if any) for death and
personal injury arising from use of the Services or for losses
arising from fraudulent statements.


**11. AUTHORITY**

You represent, warrant and covenant that:

(a) you have the power and authority to enter into this Agreement; and

(b) if you are under eighteen (18) years old you have the permission of
a parent or guardian to use the Services


**12. NON-UK RESIDENTS**

Sigma provides the Services from servers located in the United
Kingdom. Sigma makes no representation that the Services are
appropriate or available for use in locations other than the United
Kingdom. If Users access the Services from locations outside the
United Kingdom, they do so on their own initiative and at their own
risk, and are solely responsible for compliance with local laws, if
and to the extent local laws are applicable.

**13. GOVERNING LAW**

These Terms and any Agreement entered into with a User into which they
are incorporated shall be subject to and will be construed and
interpreted in accordance with English law. Any dispute or claims not
subject to arbitration shall be subject to the exclusive jurisdiction of
the English courts to which the parties hereby irrevocably submit.

**14. DISPUTES AND COMPLAINTS**


(a) If you are unhappy with the standards or level of Service that are
provided, then please contact us and we will endeavour to resolve
the issue as soon as possible.

(b) If you are not happy will the resolution that Sigma has put in place
or has offered, then you can make a compliant which must be received
in writing, via the Sigma's website or App;

(c) Sigma will review all complaints and the outcome will be notified to
you via a final response, in writing, as soon as practicably
possible and no later than 6 weeks from the date of the complaint.
The final response will detail if the complaint is upheld or whether
redress will be offered or not.

(d) Any complaint received more than 6 months after the issue is first
brought to our attention will not be considered.


**15. NOTICES**

Any notice served pursuant to these Terms shall be properly served if
sent to the address of the other party by first class post, email or
hand delivery. Notices sent by post or hand delivered shall be sent to
the registered office of the other party unless a party notifies the
other in writing of an alternative address for service of notices.
Notices sent by email shall be sent to the email addresses last used
for communication between the parties. Notices shall be deemed
delivered by:

-   First class post -- 48 hours after despatch by guaranteed next day
delivery;

-   by email -- on transmission;

-   by hand delivery -- immediately upon delivery.

**16. MISCELLANEOUS**


(a) **Force Majeure.** Neither party shall have any liability for any
failure to comply with any of these Terms for reasons beyond their
respective reasonable control.

(b) **Severance.** If any provision of these Terms is held to be invalid
or unenforceable, such provision shall be modified as required to
conform with legal requirements or, if not possible, shall be deemed
severed from these Terms and the remainder of these Terms shall
continue to be valid and binding.

(c) **Waiver.** Any waiver of any these Terms must be in writing.
Failure or delay by a party in enforcing its rights under this
Agreement will not be construed as a waiver by such party of its
rights in respect of that or any other provision of this Agreement
on that or any subsequent occasion.

(d) **No Partnership.** No part of these Terms is intended to create or
record any employment, partnership, joint venture, agency or other
such relationship between Sigma and the User (or with any of their
respective personnel). For the avoidance of doubt all personnel of
Sigma shall remain personnel of Sigma, and shall not be regarded as
employees, agents, contractors or representatives of the User.

(e) **Entire Agreement.** These Terms constitute the entire agreement
between you and Sigma, govern your use of the Service, and supersede
all prior or contemporaneous written or oral agreements between the
parties with respect to the subject matter hereof. These Terms may
not be amended, nor any obligation waived, without Sigma's written
authorisation.

(f) **Headings.** The section titles in these Terms are used solely for
the convenience and have no legal or contractual significance.

(g) **Change in Services Content.** Any material changes in the nature
of the content or in the technical specifications will be notified
to you in writing.

(h) **Survival.** Conditions 7, 9, 10, 13 and this Condition 15(h) of
these Terms shall survive termination of any Agreement with a User.

(i) **Third Party Rights.** A person who is not a party to any Agreement
to subscribe to the Services incorporating these Terms has no right
to enforce any term of this Agreement under the Contract (Rights of
Third Parties) Act 1999 as amended or substituted from time to time.
`;
