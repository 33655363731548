import type { PatternSearchResult } from "@generated/query/pattern_pb";
import type { Candlestick } from "@generated/common/basic-types_pb";
import {
  getShapeData,
  transformTsIntervalToTime,
} from "@components/chart/Chart.helpers";
import { CandlestickChartState } from "./state";

const gotPatternsAndState = (
  patterns: PatternSearchResult.AsObject[],
  state: CandlestickChartState
) => patterns && state.patterns.length === patterns.length;

const gotPatterns = (patterns: PatternSearchResult.AsObject[]) =>
  !patterns || !patterns.length;

export const updatePatterns = (
  state: CandlestickChartState,
  patterns: PatternSearchResult.AsObject[]
) => {
  if (gotPatternsAndState(patterns, state)) return;
  state.patterns = patterns;

  state.currentPatternSeries.forEach((patternSeries) => {
    try {
      state.lwChart.removeSeries(patternSeries);
    } catch (e) {
      console.warn("Attempted to remove series that doesn't exist");
    }
  });
  if (gotPatterns(patterns)) return [];
  state.currentPatternSeries = patterns.map((pattern) => {
    const series = state.lwChart.addLineSeries({
      color: state.theme.chart.patterns.colours[pattern.patternType],
      priceLineVisible: false,
      lastValueVisible: false,
      lineWidth: state.theme.chart.patterns.lineWidth,
    });

    series.setData(getPatternSeriesData(state.candles, pattern));

    return series;
  });
};

const getPatternSeriesData = (
  candles: Candlestick.AsObject[],
  pattern: PatternSearchResult.AsObject
) => {
  const shape = getShapeData(candles, pattern);

  const patternSeries = [];

  for (let i = 0; i < shape.x.length; i++) {
    const time = transformTsIntervalToTime(Number(shape.x[i]));
    patternSeries.push({ time, value: shape.y[i] });
  }

  return patternSeries;
};
