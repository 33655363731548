import { useAuth0 } from "@auth0/auth0-react";
import React, {
  useContext,
  PropsWithChildren,
  createContext,
  useState,
  useEffect,
} from "react";
import { v4 } from "uuid";
// eslint-disable-next-line max-len
import { Condition } from "@components/condition_selector/ConditionSelector.component";
import { s } from "@client/sigma-script-ts/sigma_script_v1";

export type BlockType =
  | ""
  | "logic"
  | "comparator"
  | "technical"
  | "pattern"
  | "value"
  | "price"
  | "cross";

export type BlockParameter = {
  type: string | number | boolean;
  value: string;
  name: string;
};

export enum LogicOperation {
  AND = "and",
  OR = "or",
  NOT = "not",
}

export enum ComparatorOperation {
  GT = "gt",
  LT = "lt",
  EQ = "eq",
  GTE = "gte",
  LTE = "lte",
}

export enum TechnicalOperation {
  SMA = "SMA",
  RSI = "RSI",
  EMA = "EMA",
  MACD = "MACD",
}

export enum PatternOperation {
  DoubleBottom = "DoubleBottom",
  DoubleTop = "DoubleTop",
  TripleBottom = "TripleBottom",
  TripleTop = "TripleTop",
  RoundedBottom = "RoundedBottom",
  RoundedTop = "RoundedTop",
  CupAndHandle = "CupAndHandle",
  InvertedCupAndHandle = "InvertedCupAndHandle",
  HeadAndShoulders = "HeadAndShoulders",
  InvertedHeadAndShoulders = "InvertedHeadAndShoulders",
}

export enum PriceOperation {
  Open = "Open",
  Close = "Close",
  High = "High",
  Low = "Low",
}

export enum CrossOperation {
  CrossAbove = "Cross Above",
  CrossUnder = "Cross Under",
}

export interface BaseBlock {
  id: string;
  blockType: BlockType;
}

export interface BlankBlock extends BaseBlock {
  blockType: BlockType;
}

export interface PriceBlock extends BaseBlock {
  blockType: "price";
  operation: PriceOperation;
}

export interface LogicBlock extends BaseBlock {
  blockType: "logic";
  operation: LogicOperation;
  left: ConditionBuilderBlock;
  right: ConditionBuilderBlock;
}

export interface ComparatorBlock extends BaseBlock {
  blockType: "comparator";
  operation: ComparatorOperation;
  left: ConditionBuilderBlock;
  right: ConditionBuilderBlock;
}

export interface TechnicalBlock extends BaseBlock {
  blockType: "technical";
  operation: TechnicalOperation;
  parameters: BlockParameter[];
}

export interface PatternBlock extends BaseBlock {
  blockType: "pattern";
  operation: PatternOperation;
}

export interface ValueBlock extends BaseBlock {
  blockType: "value";
  value: number;
}

export interface CrossBlock extends BaseBlock {
  blockType: "cross";
  operation: CrossOperation;
  left: ConditionBuilderBlock;
  right: ConditionBuilderBlock;
}

export type ConditionBuilderBlock =
  | LogicBlock
  | ComparatorBlock
  | TechnicalBlock
  | BlankBlock
  | ValueBlock
  | PriceBlock
  | CrossBlock;

type ConditionAction = {
  action: "add" | "remove" | "edit" | "create";
  blockType: string;
  previousValue?: string;
  newValue?: string;
  blockId: string;
};

type ConditionBuilderContextType = {
  block: ConditionBuilderBlock;
  selectedTool: string | null;
  conditionActionQueue: ConditionAction[];
  sigmaScript?: string;
  conditionResults: {
    conditions: Condition[];
  };
  conditionName: string;
  setBlock: (block: ConditionBuilderBlock) => void;
  setSelectedTool: (tool: string | null) => void;
  mutateConditionActionQueue: (action: ConditionAction) => void;
  fetchConditions: () => void;
  addToUndoStack: (stack: ConditionBuilderBlock) => void;
  addToRedoStack: (stack: ConditionBuilderBlock) => void;
  undo: () => void;
  redo: () => void;
  clearRedoStack: () => void;
  clearBlock: () => void;
  setConditionName: (name: string) => void;
  deleteBlock: (blockId: string) => void;
  setEditBlockId: (editBlockId: string) => void;
  editBlockId: string;
};

const defaultConditionBuilderContext: ConditionBuilderContextType = {
  block: {
    id: v4(),
    blockType: "",
  },
  selectedTool: null,
  conditionActionQueue: [],
  conditionResults: {
    conditions: [],
  },
  conditionName: "",
  setBlock: () => {
    return;
  },
  setSelectedTool: () => {
    return;
  },
  mutateConditionActionQueue: () => {
    return;
  },
  fetchConditions: () => {
    return;
  },
  addToUndoStack: () => {
    return;
  },
  addToRedoStack: () => {
    return;
  },
  undo: () => {
    return;
  },
  redo: () => {
    return;
  },
  clearRedoStack: () => {
    return;
  },
  clearBlock: () => {
    return;
  },
  setConditionName: () => {
    return;
  },
  deleteBlock: () => {
    return;
  },
  setEditBlockId: () => {
    return;
  },
  editBlockId: "",
};

export const isLogicBlock = (
  block: ConditionBuilderBlock
): block is LogicBlock => {
  return block.blockType === "logic";
};

export const isComparatorBlock = (
  block: ConditionBuilderBlock
): block is ComparatorBlock => {
  return block.blockType === "comparator";
};

export const isTechnicalBlock = (
  block: ConditionBuilderBlock
): block is TechnicalBlock => {
  return block.blockType === "technical";
};

export const isValueBlock = (
  block: ConditionBuilderBlock
): block is ValueBlock => {
  return block.blockType === "value";
};

export const isPatternBlock = (
  block: ConditionBuilderBlock
): block is PatternBlock => {
  return block.blockType === "pattern";
};

export const isPriceBlock = (
  block: ConditionBuilderBlock
): block is PriceBlock => {
  return block.blockType === "price";
};

export const isCrossBlock = (
  block: ConditionBuilderBlock
): block is CrossBlock => {
  return block.blockType === "cross";
};

const logicOpToSymbol = (op: string) => {
  switch (op) {
    case LogicOperation.AND:
      return s.andOp();
    case LogicOperation.OR:
      return s.orOp();
    case LogicOperation.NOT:
      return s.notOp();
    default:
      throw new Error(`Unknown logic operation: ${op}`);
  }
};

// eslint-disable-next-line complexity
const comparatorOpToSymbol = (op: string) => {
  switch (op) {
    case ComparatorOperation.GT:
      return s.gt();
    case ComparatorOperation.LT:
      return s.lt();
    case ComparatorOperation.EQ:
      return s.eq();
    case ComparatorOperation.GTE:
      return s.gte();
    case ComparatorOperation.LTE:
      return s.lte();
    default:
      throw new Error(`Unknown comparator operation: ${op}`);
  }
};

// eslint-disable-next-line complexity
const getTechnicalExpressionForBlock = (block: ConditionBuilderBlock) => {
  if (!isTechnicalBlock(block))
    throw new Error("Block is not a technical block");
  switch (block.operation) {
    case TechnicalOperation.SMA: {
      const period = block.parameters?.find(
        (param) => param.name === "period"
      )?.value;

      if (!period) throw new Error("Missing parameters for SMA operation");

      return s.allMovingAverages(s.sma([parseInt(period)]));
    }
    case TechnicalOperation.EMA: {
      const period = block.parameters?.find(
        (param) => param.name === "period"
      )?.value;

      if (!period) throw new Error("Missing parameters for EMA operation");

      return s.allMovingAverages(s.ema([parseInt(period)]));
    }
    case TechnicalOperation.RSI: {
      const period = block.parameters?.find(
        (param) => param.name === "period"
      )?.value;

      if (!period) throw new Error("Missing parameters for RSI operation");

      return s.allIndicators(s.rsi([s.rsiInner([parseInt(period), 0.3])]));
    }
    case TechnicalOperation.MACD: {
      const period = block.parameters?.find(
        (param) => param.name === "macd"
      )?.value;

      if (!period) throw new Error("Missing parameters for MACD operation");

      return s.allIndicators(s.macd(s.macdInner(s.macdValue(period))));
    }
  }
};

// eslint-disable-next-line complexity
const getPatternExpressionForBlock = (block: ConditionBuilderBlock) => {
  if (!isPatternBlock(block)) throw new Error("Block is not a pattern block");
  switch (block.operation) {
    case "DoubleBottom": {
      return s.doubleBottom();
    }
    case "DoubleTop": {
      return s.doubleTop();
    }
    case "TripleBottom": {
      return s.tripleBottom();
    }
    case "TripleTop": {
      return s.tripleTop();
    }
    case "RoundedBottom": {
      return s.roundedBottom();
    }
    case "RoundedTop": {
      return s.roundedTop();
    }
    case "CupAndHandle": {
      return s.cupHandle();
    }
    case "InvertedCupAndHandle": {
      return s.invertedCupHandle();
    }
    case "HeadAndShoulders": {
      return s.headAndShoulders();
    }
    case "InvertedHeadAndShoulders": {
      return s.invertedHeadAndShoulders();
    }
  }

  throw new Error(`Unknown pattern operation: ${block.operation}`);
};

const handleLogicBlock = (block: ConditionBuilderBlock) => {
  if (!isLogicBlock(block)) throw new Error("Block is not a logic block");
  const left = convertBlockToSigmaScript(block.left);
  const logicOpSymbol = s.logicalOrMathOp(
    s.logicalOp(logicOpToSymbol(block.operation))
  );
  const right = convertBlockToSigmaScript(block.right);

  if (!left || !right) return null;

  return s.recursiveExpressions(s.brackets([left, [[logicOpSymbol, right]]]));
};

const handleComparatorBlock = (block: ConditionBuilderBlock) => {
  if (!isComparatorBlock(block))
    throw new Error("Block is not a comparator block");
  const left = convertBlockToSigmaScript(block.left);
  const comparatorOpSymbol = comparatorOpToSymbol(block.operation);
  const right = convertBlockToSigmaScript(block.right);

  if (!left || !right) return null;

  return s.recursiveExpressions(
    s.comparisonExpression([
      [s.brackets([left, []]), []],
      comparatorOpSymbol,
      [s.brackets([right, []]), []],
    ])
  );
};

const handleTechnicalBlock = (block: ConditionBuilderBlock) => {
  return s.recursiveExpressions(
    s.computableExpr(getTechnicalExpressionForBlock(block))
  );
};

const handlePatternBlock = (block: ConditionBuilderBlock) => {
  return s.recursiveExpressions(
    s.allPatterns(getPatternExpressionForBlock(block))
  );
};

const handleValueBlock = (block: ConditionBuilderBlock) => {
  if (!isValueBlock(block)) throw new Error("Block is not a value block");
  return s.recursiveExpressions(s.float(block.value));
};

// eslint-disable-next-line complexity
const getPriceExpressionForBlock = (block: ConditionBuilderBlock) => {
  if (!isPriceBlock(block)) throw new Error("Block is not a price block");
  switch (block.operation) {
    case PriceOperation.Open: {
      return s.candle(s.open());
    }
    case PriceOperation.Close: {
      return s.candle(s.close());
    }
    case PriceOperation.High: {
      return s.candle(s.high());
    }
    case PriceOperation.Low: {
      return s.candle(s.low());
    }
  }
};

// eslint-disable-next-line complexity
const handlePriceBlock = (block: ConditionBuilderBlock) => {
  if (!isPriceBlock(block))
    throw new Error("Block is not a price block");
  return s.recursiveExpressions(
    getPriceExpressionForBlock(block)
  );
};

const getComputableExprOrFloatFromBlock = (
  block: ConditionBuilderBlock
) => {
  if (isValueBlock(block)) {
    return s.float(block.value);
  }
  if (isTechnicalBlock(block)) {
    return s.computableExpr(getTechnicalExpressionForBlock(block));
  }
  if (isPriceBlock(block)) {
    return s.computableExpr(getPriceExpressionForBlock(block));
  }
  throw new Error("Invalid block type");
};

const isCrossChildrenEmpty = (block: CrossBlock) => {
  return (
    block.left.blockType === "" ||
    block.right.blockType === ""
  );
};

const isCrossChildValid = (block: ConditionBuilderBlock) => {
  const validChildBlockTypes = [
    "value",
    "technical",
    "price",
  ];
  return validChildBlockTypes.includes(block.blockType);
};

const isCrossChildrenValid = (block: CrossBlock) => {
  return (
    isCrossChildValid(block.left) &&
    isCrossChildValid(block.right)
  );
};

// eslint-disable-next-line complexity
const handleCrossBlock = (block: ConditionBuilderBlock) => {
  if (!isCrossBlock(block))
    throw new Error("Block is not a cross block");
  if (isCrossChildrenEmpty(block)) return null;
  if (!isCrossChildrenValid(block))
    throw new Error(
      "Cross block children must be value, technical or price blocks"
    );

  const left =
    getComputableExprOrFloatFromBlock(block.left);
  const right =
    getComputableExprOrFloatFromBlock(block.right);

  const crossFunction = (block.operation === CrossOperation.CrossAbove) ?
    s.crossAbove :
    s.crossUnder;

  return s.recursiveExpressions(
    s.crossAction(
      crossFunction(
        [
          left,
          right,
        ]
      )
    )
  );
};

// eslint-disable-next-line complexity
export const convertBlockToSigmaScript = (
  block: ConditionBuilderBlock
): null | ReturnType<typeof s.recursiveExpressions> => {
  if (isLogicBlock(block)) {
    return handleLogicBlock(block);
  }

  if (isComparatorBlock(block)) {
    return handleComparatorBlock(block);
  }

  if (isTechnicalBlock(block)) {
    return handleTechnicalBlock(block);
  }

  if (isPatternBlock(block)) {
    return handlePatternBlock(block);
  }

  if (isValueBlock(block)) {
    return handleValueBlock(block);
  }

  if (isPriceBlock(block)) {
    return handlePriceBlock(block);
  }

  if (isCrossBlock(block)) {
    return handleCrossBlock(block);
  }

  return null;
};

export const ConditionBuilderContext = createContext(
  defaultConditionBuilderContext
);

const ConditionBuilderProvider = (props: PropsWithChildren<object>) => {
  const { getAccessTokenSilently } = useAuth0();
  const [conditionActionQueue, setConditionActionQueue] = useState<
    ConditionAction[]
  >([]);
  const [block, setBlock] = useState<ConditionBuilderBlock>({
    id: v4(),
    blockType: "",
  });
  const [sigmaScript, setSigmaScript] = useState<string>("");
  const [selectedTool, setSelectedTool] = useState<string | null>(null);
  const [conditionResults, setConditionResults] = useState<{
    conditions: Condition[];
  }>({
    conditions: [],
  });
  const [redoStack, setRedoStack] = useState<ConditionBuilderBlock[]>([]);
  const [undoStack, setUndoStack] = useState<ConditionBuilderBlock[]>([]);
  const [conditionName, setConditionName] = useState("");
  const [editBlockId, setEditBlockId] = useState("");

  const mutateConditionActionQueue = (action: ConditionAction) => {
    setConditionActionQueue((prevQueue) => {
      const newQueue = [...prevQueue];
      newQueue.push(action);
      return newQueue;
    });
  };

  useEffect(() => {
    setSigmaScript(convertBlockToSigmaScript(block)?.toString() || "");
  }, [JSON.stringify(block)]);

  const handleSetUndoStack = (block: ConditionBuilderBlock) => {
    setUndoStack((prevStack) => {
      const newStack = [...prevStack];
      newStack.push(block);
      return newStack.slice(-5);
    });
  };

  const undo = () => {
    if (undoStack.length === 0) return;
    const lastBlock = undoStack.pop();
    if (!lastBlock) return;
    handleSetRedoStack(block);
    setBlock(lastBlock);
  };

  const redo = () => {
    if (redoStack.length === 0) return;
    const lastBlock = redoStack.pop();
    if (!lastBlock) return;
    handleSetUndoStack(block);
    setBlock(lastBlock);
  };

  const handleSetRedoStack = (block: ConditionBuilderBlock) => {
    setRedoStack((prevStack) => {
      const newStack = [...prevStack];
      newStack.push(block);
      return newStack.slice(-5);
    });
  };

  const clearBlock = () => {
    if (editBlockId) {
      setBlock({
        id: block.id,
        blockType: "",
      });
      setEditBlockId(editBlockId);
      return;
    }
    setBlock({
      id: v4(),
      blockType: "",
    });
  };

  // eslint-disable-next-line complexity
  const setBlankBlock = (
    blockId: string,
    block: ConditionBuilderBlock
  ): ConditionBuilderBlock => {
    const blankBlockTemplate = {
      id: blockId,
      blockType: "",
    };

    if (block.id === blockId) {
      return { ...blankBlockTemplate };
    }
    if (
      block.blockType === "logic" ||
      block.blockType === "comparator" ||
      block.blockType === "cross"
    ) {
      const operationBlock = block as LogicBlock | ComparatorBlock;
      const leftUpdated = operationBlock.left
        ? setBlankBlock(blockId, operationBlock.left)
        : operationBlock.left;
      const rightUpdated = operationBlock.right
        ? setBlankBlock(blockId, operationBlock.right)
        : operationBlock.right;

      if (
        leftUpdated !== operationBlock.left ||
        rightUpdated !== operationBlock.right
      ) {
        return { ...block, left: leftUpdated, right: rightUpdated };
      }
    }
    return block;
  };

  const deleteBlock = (blockId: string) => {
    setBlock((block) => setBlankBlock(blockId, block));
  };

  const fetchConditions = () => {
    getAccessTokenSilently().then((token) => {
      fetch("/api/conditions", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then((res) => res.json())
        .then((data) => {
          setConditionResults(data);
        });
    });
  };

  useEffect(() => {
    fetchConditions();
  }, []);

  return (
    <ConditionBuilderContext.Provider
      value={{
        block,
        selectedTool,
        conditionActionQueue,
        sigmaScript,
        conditionResults,
        conditionName,
        editBlockId,
        setBlock,
        setSelectedTool,
        mutateConditionActionQueue,
        fetchConditions,
        addToUndoStack: handleSetUndoStack,
        addToRedoStack: handleSetRedoStack,
        undo,
        redo,
        clearRedoStack: () => setRedoStack([]),
        clearBlock,
        deleteBlock,
        setConditionName,
        setEditBlockId,
      }}
    >
      {props.children}
    </ConditionBuilderContext.Provider>
  );
};

const useConditionBuilderContext = () => useContext(ConditionBuilderContext);

export { ConditionBuilderProvider, useConditionBuilderContext };
export type { ConditionBuilderContextType, ConditionAction };
