import React, { useCallback, useEffect, useState } from "react";
import { Container, FullTabWrapper, FullWrapper } from "./Tabbar.styles";

type TabProps = {
  data: TabData;
  selected: boolean;
  onClick: (tabId: string) => void;
  autoWidth?: boolean;
  variant?: "default" | "gold";
};

export type TabbarProps = {
  data: TabData[];
  selectedTab?: string;
  onTabSelectedChange: (selectedId: string) => void;
  hugTabs?: boolean;
  variant?: "default" | "gold";
  autoWidth?: boolean;
  avoidSticky?: boolean;
};

export type TabData = {
  title: string;
  id: string;
  leftIcon?: React.ReactNode;
  rightIcon?: React.ReactNode;
};

export const Tabbar = (props: TabbarProps) => {
  const [selectedTabId, setSelectedTabId] = useState(props.selectedTab || "1");

  useEffect(() => {
    if (props.selectedTab) setSelectedTabId(props.selectedTab);
  }, [props.selectedTab]);

  const handleSelectionChanges = useCallback(
    (tabId: string) => {
      setSelectedTabId(tabId);
      props.onTabSelectedChange(tabId);
    },
    [props.onTabSelectedChange]
  );

  return (
    <Container
      $autoWidth={props.autoWidth === true}
      $avoidSticky={props.avoidSticky === true}
    >
      <FullWrapper data-testid="tabbar" $hugTabs={props.hugTabs === true}>
        {props.data &&
          props.data.map((tab) => (
            <FullTab
              data-testid={`tab-${tab.id}`}
              data={tab}
              autoWidth={props.autoWidth}
              key={tab.id}
              selected={selectedTabId === tab.id}
              onClick={handleSelectionChanges}
              variant={props.variant}
            />
          ))}
      </FullWrapper>
    </Container>
  );
};

const FullTab = (props: TabProps) => {
  return (
    <FullTabWrapper
      selected={props.selected}
      onClick={() => props.onClick(props.data.id)}
      data-testid="fullTab"
      $autoWidth={props.autoWidth === true}
      $gold={props.variant === "gold"}
    >
      {props.data.leftIcon ? props.data.leftIcon : null}
      <span>{props.data.title}</span>
      {props.data.rightIcon ? props.data.rightIcon : null}
    </FullTabWrapper>
  );
};
