import { useAuth0 } from "@auth0/auth0-react";
// eslint-disable-next-line max-len
import AccessDeniedDialog from "@components/access_denied_dialog/AccessDeniedDialog.component";
// eslint-disable-next-line max-len
import TermsDialogWrapper from "@components/terms_dialog_wrapper/TermsDialogWrapper.component";
import React, { useEffect } from "react";
import "react-toastify/dist/ReactToastify.css";
import { AlertProvider } from "../context/AlertContext";
import { useAppContext } from "../context/AppContext";
import {
  InstrumentProvider,
  useInstrumentContext,
} from "../context/InstrumentContext";
import { useUserContext } from "../context/user/UserContext";
import { hasAuthError } from "./App.helpers";
import { Slide, ToastContainer } from "react-toastify";
import { useTheme } from "styled-components";
import { useNavigate, useSearchParams } from "react-router-dom";
import PaasComponent from "../components/app_paas/Paas.component";
import CookieBanner from "../components/cookie_banner/CookieBanner.component";

const setUrlParams = (
  hashcode: string | null,
  setHashcode: (hashcode: string) => void,
  frequency: string | null,
  setFrequency: (frequency: string) => void
) => {
  if (hashcode) setHashcode(hashcode);
  if (frequency) setFrequency(frequency);
};

/**
 * Handles applying url logic to the application
 */
const UrlContextProvider = () => {
  const { setFrequency, setHashcode } = useAppContext();
  const { setShowAccessDeniedDialog } = useUserContext();
  const [searchParams] = useSearchParams();
  const { unavailableInstruments } = useInstrumentContext();
  useEffect(() => {
    const url = new URLSearchParams(searchParams);

    const hashcode = url.get("hashcode");
    const frequency = url.get("frequency");
    const isUnavailable = unavailableInstruments.find(
      (instrument) => instrument.hashcode === hashcode
    );
    if (isUnavailable) {
      setShowAccessDeniedDialog(true);
      return;
    }
    setUrlParams(hashcode, setHashcode, frequency, setFrequency);
  }, [searchParams, unavailableInstruments]);

  return null;
};

const App = ({ logoutRedirect }: { logoutRedirect: string }) => {
  const { isLoading } = useAuth0();
  const {
    isAuthenticated,
    loginWithRedirect,
    logout,
    showAccessDeniedDialog,
    setShowAccessDeniedDialog,
  } = useUserContext();
  const navigate = useNavigate();
  const theme = useTheme();
  const searchParams = new URLSearchParams(document.location.search);
  if (isLoading) return null;
  if (!isAuthenticated) {
    loginWithRedirect();
    return null;
  }
  if (hasAuthError(searchParams.get("error"))) {
    logout({
      logoutParams: {
        returnTo: logoutRedirect,
      },
    });
    return null;
  }

  return (
    <>
      <InstrumentProvider>
        <AlertProvider>
          <AccessDeniedDialog
            show={showAccessDeniedDialog}
            onClick={() => navigate("/plans")}
            onClickCancel={() => setShowAccessDeniedDialog(false)}
          />
          <PaasComponent />
          <ToastContainer
            toastStyle={{
              backgroundColor: theme?.toastProperties?.background,
            }}
            style={{
              maxWidth: "30rem",
              width: "100%",
              padding: "0.5rem",
              boxShadow: `0px 1px 2px -1px rgba(0, 0, 0, 0.10),
                  0px 4px 4px 0px rgba(0, 0, 0, 0.10)`,
            }}
            position={"top-left"}
            autoClose={5000}
            hideProgressBar={true}
            newestOnTop={true}
            transition={Slide}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
          />
        </AlertProvider>
        <UrlContextProvider />
      </InstrumentProvider>
      <TermsDialogWrapper />
      <CookieBanner />
    </>
  );
};

export default App;
