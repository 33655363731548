import { SigmaUIDarkTheme } from "./dark";
import { createStyle } from "./utils";

export interface BasePrimitive {
  spacing?: {
    [x in TSize]?: number | string;
  };
  colors?: {
    /**
     * Where all the color pallets for sigma themed components are
     */
    [x: string]:
      | string
      | {
          [x: string]:
            | TColorVariant
            | string
            | { [variant: string]: string };
        };
    pallets: {
      [x: string]:
        | TColorVariant
        | string
        | { [variant: string]: string };
    };
  };
}

type TSize =
  | "xs"
  | "sm"
  | "base"
  | "lg"
  | "xl"
  | "2xl"
  | "3xl"
  | "4xl"
  | "5xl";
type TColorVariant = {
  default?: string;
  50: string;
  100: string;
  200: string;
  300: string;
  400: string;
  500: string;
  600: string;
  700: string;
  800: string;
  900: string;
};

export type SigmaUIDefaultTheme = typeof SigmaUIDarkTheme;

const [createTheme] = createStyle({
  spacing: {
    xs: "0.5rem",
  },
  size: {
    xs: "0.5rem",
  },
  colors: {
    white: "#ffffff",
    black: "#000000",
    pallets: {
      "sigma-grey": {
        50: "#676767",
        100: "#F2F2F2",
        150: "#ECECEC",
        200: "#E5E7EB",
        300: "#CACACA",
        400: "#ABABAB",
        500: "#818181",
        600: "#676767",
        700: "#444444",
        800: "#242424",
        850: "#1B1B1B",
        900: "#131313",
      },
    },
  },
});

export { createTheme };
