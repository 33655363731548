export type Item = {
  name: string;
  chartType: string;
};

export const hardcodedItems: Item[] = [
  {
    name: "Candlestick",
    chartType: "candlestick",
  },
  {
    name: "Line",
    chartType: "line",
  },
];
