import styled from "styled-components";
import { textStyles } from "@utils/textStyles";
export const Container = styled.div<{
  $autoWidth: boolean;
  $avoidSticky: boolean;
}>`
  background-color: ${(props) => props.theme.TabbarBackground};
  display: flex;
  flex-direction: row;
  padding: 0.25rem;
  width: ${(props) => (props.$autoWidth ? "auto" : "100%")};
  border-radius: 0.25rem;
  position: ${(props) => (props.$avoidSticky ? "unset" : "sticky")};
  top: 0;
  z-index: 10;
`;
export const FullWrapper = styled.div<{ $hugTabs: boolean }>`
  background-color: ${(props) => props.theme.TabbarBackground};
  display: flex;
  flex-direction: row;
  width: 100%;
  border-radius: 0.25rem;
  ${textStyles.textBase};
  position: sticky;
  top: 0;
  z-index: 10;
  & > * {
    ${(props) => (props.$hugTabs ? "" : "flex: 1;")}
  }
  @media (max-width: 768px) {
    overflow-x: auto;
    height: 100%;
    max-height: 2.5rem;
  }
`;

/* tslint:disable */
/* eslint-disable */
export const FullTabWrapper = styled.div<{
  selected: boolean;
  $gold: boolean;
  $autoWidth: boolean;
}>`
  display: flex;
  gap: 0.5rem;
  padding: ${(props) => (props.$autoWidth ? "0.5rem 3.75rem" : "0.25rem 1rem")};
  text-align: center;
  font-size: 14px;
  justify-content: center;
  align-items: center;

  ${(props) =>
    props.selected
      ? `
        background: ${
          props.$gold
            ? props.theme.TabbarSelectedGold
            : props.theme.TabbarSelected
        };
    color: black;
    border-radius: 0.25rem;
    ${textStyles.textBaseBold};
    `
      : `
    cursor: pointer;
  `}
`;
