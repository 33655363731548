export const gridColumns: string[] = ["", "Free", "Lite", "Pro", "Ultimate"];

export type GridRowItemData = string | boolean;
export type GridRowData = {
  comingSoon: boolean;
  data: GridRowItemData[];
};
export const instrumentsRowData: GridRowData[] = [
  {
    data: ["Crypto", "2", true, true, true],
    comingSoon: false,
  },
  {
    data: ["Forex", "2", true, true, true],
    comingSoon: false,
  },
  {
    data: ["UK Equities", "2", "", true, true],
    comingSoon: true,
  },
  {
    data: ["EU Equities", "2", "", true, true],
    comingSoon: true,
  },
  {
    data: ["Swiss Equities", "2", "", true, true],
    comingSoon: true,
  },
  {
    data: ["US Equities - Tech", "2", "", "", true],
    comingSoon: false,
  },
  {
    data: ["US Equities - Manufacturing", "", "", "", true],
    comingSoon: false,
  },
  {
    data: ["Additional US Equities", "", "", "", true],
    comingSoon: false,
  },
];

export const alertsRowData: GridRowData[] = [
  {
    data: ["In-app Alerts", true, true, true, true],
    comingSoon: false,
  },
  {
    data: ["Email Alerts", true, true, true, true],
    comingSoon: false,
  },
  {
    data: ["Telegram Alerts", "", "", true, true],
    comingSoon: false,
  },
  {
    data: ["Complex Condition Alerts", "", "", true, true],
    comingSoon: true,
  },
];

export const technicalsRowData: GridRowData[] = [
  {
    data: ["SMA", true, true, true, true],
    comingSoon: false,
  },
  {
    data: ["EMA", true, true, true, true],
    comingSoon: false,
  },
  {
    data: ["RSI", true, true, true, true],
    comingSoon: false,
  },
  {
    data: ["MACD", true, true, true, true],
    comingSoon: false,
  },
  {
    data: ["Patterns", true, true, true, true],
    comingSoon: false,
  },
  {
    data: ["Support and Resistance", true, true, true, true],
    comingSoon: true,
  },
  {
    data: ["Pattern Score", "", true, true, true],
    comingSoon: false,
  },
  {
    data: ["Advanced Technicals", "", true, true, true],
    comingSoon: true,
  },
  {
    data: ["Bespoke Patterns", "", "", "", true],
    comingSoon: true,
  },
];

export const aimaRowData: GridRowData[] = [
  {
    data: ["Daily AIMA", "", true, true, true],
    comingSoon: false,
  },
  {
    data: ["Intraday AIMA", "", "", true, true],
    comingSoon: true,
  },
  {
    data: ["AIMA Email Newsletters", "", "", true, true],
    comingSoon: true,
  },
  {
    data: ["Historic AIMA", "", "", "", true],
    comingSoon: true,
  },
  {
    data: ["On-demand AIMA", "", "", "", true],
    comingSoon: true,
  },
];

export const featuresRowData: GridRowData[] = [
  {
    data: ["Screening", true, true, true, true],
    comingSoon: false,
  },
  {
    data: ["Advanced Backtesting", "", "", true, true],
    comingSoon: true,
  },
  {
    data: ["Backtesting", "", "", true, true],
    comingSoon: true,
  },
  {
    data: ["Complex Condition Builder", "", "", true, true],
    comingSoon: true,
  },
  {
    data: ["Sigma Script", "", "", "", true],
    comingSoon: true,
  },
  {
    data: ["AI Portfolio Analysis", "", "", "", true],
    comingSoon: true,
  },
];
