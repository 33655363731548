import React from "react";
import { useProfile } from "@client/src/hooks/useProfile";
import { useAppContext } from "../../AppContext";

export const usePreferenceContext = () => {
  const context = useAppContext();

  if (!context.preferencesContext) {
    throw new Error(
      "usePreferenceContext can only be used" + "inside <AppProvider />"
    );
  }

  return context.preferencesContext;
};

export const usePreferenceDispatch = () => {
  const [, dispatch] = usePreferenceContext();
  return dispatch;
};

export const usePreferences = () => {
  const [state] = usePreferenceContext();

  const isWebPrefered = React.useMemo(() => {
    const out = state.alertPreferences.includes("web");
    return out;
  }, [state]);

  const isEmailPrefered = React.useMemo(() => {
    return state.alertPreferences.includes("email");
  }, [state]);

  const isTelegramPrefered = React.useMemo(() => {
    return state.alertPreferences.includes("telegram");
  }, [state]);
  return {
    value: state,
    isWebPrefered,
    isEmailPrefered,
    isTelegramPrefered,
  };
};

export const InitializeUserPreferences = () => {
  const { fetchUserPreferences } = useProfile();
  const preferenceDispatch = usePreferenceDispatch();

  React.useEffect(() => {
    fetchUserPreferences().then((preferences) => {
      if (preferences) {
        preferenceDispatch({
          type: "set-alert-preferences",
          data: preferences.enabledChannels,
        });
      }
    });
  }, []);

  return null;
};
