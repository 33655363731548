import React, { useState } from "react";
import Button from "../../../button/Button.component";
import { FormContainer } from "./CustomFilterDialog.style";
import { CustomField, CustomFieldFormProps } from "./CustomFilterDialog.types";

const forms = {
  Above: <T,>({ applyFilter, field }: CustomFieldFormProps<T>) => {
    const handleSubmit = (value: number) => {
      applyFilter(field.operationFunction(value));
    };
    return (
      <OneInputForm onSubmit={(value) => handleSubmit(value)} field={field} />
    );
  },
  Below: <T,>({ applyFilter, field }: CustomFieldFormProps<T>) => {
    const handleSubmit = (value: number) => {
      applyFilter(field.operationFunction(value));
    };
    return (
      <OneInputForm onSubmit={(value) => handleSubmit(value)} field={field} />
    );
  },
  Between: <T,>({ applyFilter, field }: CustomFieldFormProps<T>) => {
    const handleSubmit = (value: number, value2: number) => {
      applyFilter(field.operationFunction(value, value2));
    };
    return (
      <TwoInputForm
        onSubmit={(value, value2) => handleSubmit(value, value2)}
        field={field}
      />
    );
  },
};

const OneInputForm = <T,>({
  onSubmit,
  field,
}: {
  onSubmit: (value: number) => void;
  field: CustomField<T>;
}) => {
  const [value, setValue] = useState("");
  return (
    <FormContainer>
      <input
        type={field.mainInput.type}
        placeholder={field.mainInput.label}
        onChange={(event) => setValue(event.target.value)}
      />
      <Button text={"Apply"} onClick={() => onSubmit(+value)} />
    </FormContainer>
  );
};

const TwoInputForm = <T,>({
  onSubmit,
  field,
}: {
  onSubmit: (value: number, value2: number) => void;
  field: CustomField<T>;
}) => {
  const [value, setValue] = useState("");
  const [value2, setValue2] = useState("");
  if (!field.secondaryInput) return null;
  return (
    <FormContainer>
      <input
        type={field.mainInput.type}
        placeholder={field.mainInput.label}
        onChange={(event) => setValue(event.target.value)}
      />
      <span>to</span>
      <input
        type={field.secondaryInput.type}
        placeholder={field.secondaryInput.label}
        onChange={(event) => setValue2(event.target.value)}
      />
      <Button text={"Apply"} onClick={() => onSubmit(+value, +value2)} />
    </FormContainer>
  );
};

export const renderForm = <T extends object>(
  field: CustomField<T> | undefined,
  applyFilter: (filter: T) => void
) => {
  if (!field) return null;
  const FormComponent = forms[field.operation];
  return <FormComponent applyFilter={applyFilter} field={field} />;
};
