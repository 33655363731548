import React from "react";
import { HiCheck } from "react-icons/hi2";
import { GridRowData } from "./ComparedPlans.helper";
import {
  ComingSoonText,
  Container,
  GridColumn,
  GridContainer,
  GridItem,
  Title,
} from "./ComparedPlans.style";

type Props = {
  title: string;
  columns: string[];
  rows: GridRowData[];
};

const getGridItemContent = (item: string | boolean) => {
  const content = item === true ? <HiCheck color={"#ffffff"} /> : item;
  return <> {content}</>;
};

const shouldRenderLabel = (isFirstItem: boolean, comingSoon: boolean) =>
  isFirstItem && comingSoon;

const renderGridItem = (
  index: number,
  item: string | boolean,
  comingSoon: boolean
) => {
  const isFirstItem = index % 5 === 0;
  const style = isFirstItem
    ? { justifySelf: "start" }
    : { justifySelf: "center" };
  return (
    <GridItem style={style} key={index}>
      {getGridItemContent(item)}{" "}
      {shouldRenderLabel(isFirstItem, comingSoon) && <>*</>}
    </GridItem>
  );
};

const ComparedPlans = ({ columns, rows, title }: Props) => {
  return (
    <Container>
      <Title>{title}</Title>
      <GridContainer>
        {columns.map((column, index) => {
          return <GridColumn key={index}>{column}</GridColumn>;
        })}
        {rows.map((row, index) => {
          return (
            <React.Fragment key={index}>
              {row.data.map((item, index) => {
                return renderGridItem(index, item, row.comingSoon);
              })}
            </React.Fragment>
          );
        })}
      </GridContainer>
      <ComingSoonText>*coming soon</ComingSoonText>
    </Container>
  );
};

export default ComparedPlans;
