import { PatternConfigItem, TechnicalConfigItem } from "@common/types";
// eslint-disable-next-line max-len
import { ScreeningResult } from "@components/screening_result_table/ScreeningResultTable.component";
// eslint-disable-next-line max-len
import {
  TechnicalOption,
  transformConfigToTechnical,
} from "@components/technicals_dialog/TechnicalsDialogFormSchema";
import { Instrument } from "@generated/common/basic-types_pb";
import { usePatternConfig } from "@hooks/usePatternConfig";
import { ChartMetadata } from "@utils/condition_builder_utils/dataHandler";
import React, {
  createContext,
  PropsWithChildren,
  useContext,
  useEffect,
  useState,
} from "react";
// eslint-disable-next-line max-len
import { hardcodedItems as frequencies } from "../components/frequency_selector/FrequencySelection.helpers";
import {
  getChartFrequency,
  updateStoragePatternConfigItem,
} from "@utils/configStorageManager";

import {
  PREFERENCE_CONTEXT_INITIAL_STATE,
  PreferenceAction,
  PreferenceState,
  preferenceReducer,
} from "./app/preference/context";

type AppContextType = {
  frequency: string;
  hashcode: string;
  setFrequency: (frequency: string) => void;
  setHashcode: (hashcode: string) => void;
  patternConfigItems: PatternConfigItem[];
  globalConfigItem: PatternConfigItem;
  setGlobalConfigItem: (patternConfigItem: PatternConfigItem) => void;
  updatePatternConfigItem: (
    patternConfigItem: PatternConfigItem,
    type: number
  ) => void;
  getEnabledPatterns: () => PatternConfigItem[];
  updateGlobalThreshold: (threshold: number) => void;
  updateGlobalEnabled: (enabled: boolean) => void;
  supportResistanceEnabled: boolean;
  setSupportResistanceEnabled: (enabled: boolean) => void;
  technicals: TechnicalConfigItem[];
  addTechnical: (technical: TechnicalConfigItem) => void;
  removeTechnical: (technical: TechnicalConfigItem) => void;
  technicalDialogVisible: boolean;
  selectedTechnical: TechnicalOption | undefined;
  setTechnicalOptionSelected: (technical: TechnicalOption | undefined) => void;
  setTechnicalConfigSelected: (technical: TechnicalConfigItem) => void;
  setTechnicalDialogVisible: (visible: boolean) => void;
  technicalToEdit: TechnicalConfigItem | undefined;
  setTechnicalToEdit: (technical: TechnicalConfigItem | undefined) => void;
  preferencesContext:
    | [PreferenceState, React.Dispatch<PreferenceAction>]
    | null;
  airwallexEnv: string;
  authDomain: string;
  authClientId: string;
  authRedirectUri: string;
  ddRumApplicationId: string;
  ddRumClientToken: string;
  theme: string;
  screeningResults: ScreeningResult[];
  setScreeningResults: (results: ScreeningResult[]) => void;
  chartMetadata: ChartMetadata | null;
  setChartMetadata: (metadata: ChartMetadata) => void;
  instruments: Instrument.AsObject[];
  setInstruments: (instruments: Instrument.AsObject[]) => void;
};

const defaultAppContext: AppContextType = {
  frequency: frequencies[0].value,
  hashcode: "",
  setFrequency: (frequency: string) => {
    return frequency;
  },
  setHashcode: (hashcode: string) => {
    return hashcode;
  },
  patternConfigItems: [],
  globalConfigItem: {
    type: -1,
    threshold: 0,
    enabled: true,
  },
  setGlobalConfigItem: () => {
    // placeholder
  },
  updatePatternConfigItem: (
    patternConfigItem: PatternConfigItem,
    type: number
  ) => {
    return { patternConfigItem, type };
  },
  getEnabledPatterns: () => {
    return [];
  },
  updateGlobalThreshold: () => {
    //placeholder
  },
  updateGlobalEnabled: () => {
    //placeholder
  },
  supportResistanceEnabled: false,
  setSupportResistanceEnabled: () => {
    //placeholder
  },
  technicals: [],
  addTechnical: () => {
    //placeholder
  },
  removeTechnical: () => {
    //placeholder
  },
  technicalDialogVisible: false,
  selectedTechnical: undefined,
  setTechnicalOptionSelected: () => {
    //placeholder
  },
  setTechnicalConfigSelected: () => {
    //placeholder
  },
  setTechnicalDialogVisible: () => {
    //placeholder
  },
  technicalToEdit: undefined,
  setTechnicalToEdit: () => {
    //placeholder
  },
  preferencesContext: null,
  airwallexEnv: "prod",
  authDomain: "",
  authClientId: "",
  authRedirectUri: "",
  ddRumApplicationId: "",
  ddRumClientToken: "",
  theme: "",
  screeningResults: [],
  setScreeningResults: () => {
    //placeholder
  },
  chartMetadata: null,
  setChartMetadata: () => {
    //placeholder
  },
  instruments: [],
  setInstruments: () => {
    //placeholder
  },
};

export const AppContext = createContext(defaultAppContext);

const getSafeLocalStorage = (key: string, defaultValue: string) => {
  return localStorage.getItem(key) || defaultValue;
};

const getSafeEnv = (defaultValue: string, env?: string) => {
  return env || defaultValue;
};

const AppProvider = (props: PropsWithChildren<object>) => {
  const [authDomain, setAuthDomain] = useState("");
  const [authClientId, setAuthClientId] = useState("");
  const [authRedirectUri, setAuthRedirectUri] = useState("");
  const [ddRumApplicationId, setDdRumApplicationId] = useState("");
  const [ddRumClientToken, setDdRumClientToken] = useState("");
  const [theme, setTheme] = useState("");
  const [airwallexEnv, setAirwallexEnv] = useState("");
  const [screeningResults, setScreeningResults] = useState<ScreeningResult[]>(
    []
  );
  const [instruments, setInstruments] = useState<Instrument.AsObject[]>([]);

  useEffect(() => {
    const envUrl = `${window.location.origin}/api/env`;
    fetch(envUrl)
      .then((res) => res.json())
      .then((envData) => {
        if (!envData) return;
        setAuthDomain(getSafeEnv("", envData.AUTH_DOMAIN));
        setAuthClientId(getSafeEnv("", envData.AUTH_CLIENT_ID));
        setAuthRedirectUri(getSafeEnv("", envData.AUTH_REDIRECT_URI));
        setDdRumApplicationId(getSafeEnv("", envData.DD_RUM_APPLICATION_ID));
        setDdRumClientToken(getSafeEnv("", envData.DD_RUM_CLIENT_TOKEN));
        setTheme(getSafeEnv("dark", envData.THEME));
        setAirwallexEnv(getSafeEnv("prod", envData.AIRWALLEX_ENVIRONMENT));
      });
  }, []);

  const initialFrequency = getSafeLocalStorage(
    "frequency",
    getChartFrequency()
  );

  const preferencesContext = React.useReducer(
    preferenceReducer,
    PREFERENCE_CONTEXT_INITIAL_STATE
  );
  const [chartMetadata, setChartMetadata] = useState<ChartMetadata | null>(
    null
  );
  const [frequency, setFrequencyContext] = useState(initialFrequency);
  const [technicalDialogVisible, setTechnicalDialogVisible] = useState(false);
  const [selectedTechnical, setSelectedTechnical] = useState<
    TechnicalOption | undefined
  >(undefined);

  const [technicalToEdit, setTechnicalToEdit] = useState<
    TechnicalConfigItem | undefined
  >(undefined);
  const setFrequency = (newFrequency: string) => {
    localStorage.setItem("frequency", newFrequency);
    const urlSearchParams = new URLSearchParams(window.location.search);
    urlSearchParams.set("frequency", newFrequency);
    window.history.pushState(
      null,
      "",
      `${window.location.pathname}?${urlSearchParams.toString()}`
    );
    setFrequencyContext(newFrequency);
  };

  const initialHashcode = getSafeLocalStorage("instrumentHashcode", "");
  const [hashcode, setHashcodeContext] = useState(initialHashcode);

  const setHashcode = (newHashcode: string) => {
    localStorage.setItem("instrumentHashcode", newHashcode);
    const urlSearchParams = new URLSearchParams(window.location.search);

    urlSearchParams.set("hashcode", newHashcode);
    window.history.pushState(
      null,
      "",
      `${window.location.pathname}?${urlSearchParams.toString()}`
    );
    setHashcodeContext(newHashcode);
  };

  const setTechnicalOptionSelected = (
    technical: TechnicalOption | undefined
  ) => {
    setSelectedTechnical(technical);
  };

  const setTechnicalConfigItemSelected = (
    technical: TechnicalConfigItem | undefined
  ) => {
    let option: TechnicalOption | undefined = undefined;
    if (!technical) {
      setSelectedTechnical(option);
      return;
    }
    option = transformConfigToTechnical(technical);
    setSelectedTechnical(option);
  };

  const {
    patternConfigItems,
    update,
    getEnabledPatterns,
    globalConfig,
    updateGlobalConfig,
  } = usePatternConfig();

  const updateGlobalThreshold = (threshold: number) => {
    patternConfigItems.forEach((item, index) => {
      update({ ...item, threshold }, index);
      updateStoragePatternConfigItem({
        ...item,
        threshold,
      });
    });
  };
  const updateGlobalEnabled = (enabled: boolean) => {
    patternConfigItems.forEach((item, index) => {
      update({ ...item, enabled }, index);
      updateStoragePatternConfigItem({ ...item, enabled });
    });
  };
  const [supportResistanceEnabled, setSupportResistanceEnabled] =
    useState(false);

  const initialTechnicals = JSON.parse(getSafeLocalStorage("technicals", "[]"));
  const [technicals, setTechnicals] =
    useState<TechnicalConfigItem[]>(initialTechnicals);
  const handleAddTechnical = (technical: TechnicalConfigItem) => {
    if (
      technical.name === "Volume" &&
      technicals.some((item) => item.name === "Volume")
    )
      return;
    addOrEditTechnical(technical);
  };
  const addOrEditTechnical = (technical: TechnicalConfigItem) => {
    if (technicalToEdit) {
      //edit technical
      editTechnical(technical);
    } else {
      //add technical
      addNewTechnical(technical);
    }
  };
  const addNewTechnical = (technical: TechnicalConfigItem) => {
    const newTechnicals = [...technicals, technical];
    localStorage.setItem("technicals", JSON.stringify(newTechnicals));
    setTechnicals(newTechnicals);
  };
  const editTechnical = (technical: TechnicalConfigItem) => {
    const currentTechnicals = [...technicals];
    const index = currentTechnicals.findIndex(
      (item) => JSON.stringify(item) === JSON.stringify(technicalToEdit)
    );
    if (index >= 0) currentTechnicals[index] = technical;
    localStorage.setItem("technicals", JSON.stringify(currentTechnicals));
    setTechnicals(currentTechnicals);
  };

  const removeTechnical = (technical: TechnicalConfigItem) => {
    const newTechnicals = technicals.filter(
      (item) => JSON.stringify(item) !== JSON.stringify(technical)
    );
    localStorage.setItem("technicals", JSON.stringify(newTechnicals));
    setTechnicals(newTechnicals);
  };

  const getTechnicalsToRender = () => {
    if (chartMetadata && chartMetadata.technicals.length > 0)
      return [...technicals, ...chartMetadata.technicals];
    return technicals;
  };
  return (
    <AppContext.Provider
      value={{
        frequency,
        hashcode,
        setFrequency,
        setHashcode,
        patternConfigItems,
        globalConfigItem: globalConfig,
        setGlobalConfigItem: updateGlobalConfig,
        updatePatternConfigItem: update,
        getEnabledPatterns,
        updateGlobalThreshold,
        updateGlobalEnabled,
        supportResistanceEnabled,
        setSupportResistanceEnabled,
        technicals: getTechnicalsToRender(),
        addTechnical: handleAddTechnical,
        removeTechnical,
        technicalDialogVisible,
        setTechnicalDialogVisible,
        setTechnicalConfigSelected: setTechnicalConfigItemSelected,
        setTechnicalOptionSelected,
        selectedTechnical,
        setTechnicalToEdit,
        technicalToEdit,
        preferencesContext,
        airwallexEnv,
        authDomain,
        authClientId,
        authRedirectUri,
        ddRumApplicationId,
        ddRumClientToken,
        theme,
        screeningResults,
        setScreeningResults,
        setChartMetadata,
        chartMetadata,
        instruments,
        setInstruments,
      }}
    >
      {props.children}
    </AppContext.Provider>
  );
};

const useAppContext = () => useContext(AppContext);

export { AppProvider, useAppContext };
export type { AppContextType };
