import { textStyles } from "@client/src/utils/textStyles";
import styled from "styled-components";
import Container from "@client/src/components/container/Container.component";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem;
  gap: 1.5rem;
`;

export const AvatarContainer = styled.div`
  margin-top: 0.375rem;
`;

export const UserInfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
`;
export const Name = styled.p`
  ${textStyles.textXlBold};
  margin: 0;
`;

export const Email = styled.p`
  ${textStyles.textBaseBold};
  margin: 0;
`;

export const ProfileContainer = styled(Container)`
  margin-left: auto;
  margin-right: auto;

  @media (min-width: 640px) {
    max-width: 640px;
  }

  @media (min-width: 768px) {
    max-width: 768px;
  }

  @media (min-width: 1024px) {
    max-width: 1024px;
  }

  @media (min-width: 1280px) {
    max-width: 1280px;
  }

  @media (min-width: 1536px) {
    max-width: 1536px;
  }
`;

export const GridSection = styled.div`
  display: grid;
  gap: 1.5rem;
  grid-template-columns: repeat(1, minmax(0, 1fr));

  @media (min-width: 768px) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
`;

export const UserID = styled.div`
  margin-left: auto;
`;
