import { MagnifyingGlassChart } from "@assets/Icons";
import { Instrument } from "@generated/common/basic-types_pb";
import { getInstrumentLabel } from "@utils/instrumentUtils";
import React from "react";
import Label from "../../label/Label.component";
import { INSTRUMENTS_TO_ANALYSE } from "./InstrumentItem.mock";
import {
  Container,
  Symbol,
  Name,
  Body,
  Column,
  LabelContainer,
} from "./InstrumentItem.style";

type Props = {
  instrument: Instrument.AsObject;
  handleOnClick: () => void;
};

const renderAimaTag = (symbol: string) => {
  if (INSTRUMENTS_TO_ANALYSE.includes(symbol)) return <MagnifyingGlassChart />;
};

const InstrumentItem = ({ instrument, handleOnClick }: Props) => {
  return (
    <Container
      onClick={() => {
        handleOnClick();
      }}
    >
      <Body data-testid="instrument-item">
        <Column>
          <Symbol>{instrument.symbol}</Symbol>
        </Column>
        <Name>{instrument.name}</Name>
      </Body>
      <LabelContainer>
        {renderAimaTag(instrument.symbol)}
        <Label
          text={getInstrumentLabel(instrument.instrumentClass)}
          variant="tertiary"
        />
      </LabelContainer>
    </Container>
  );
};

export default InstrumentItem;
