import styled from "styled-components";

export const Container = styled.div`
  position: relative;
  display: inline-block;
  width: 44px;
  height: 24px;
`;

export const Slider = styled.span`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${(props) => props.theme.uncheckedInput};
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 34px;

  &:before {
    position: absolute;
    content: "";
    height: 20px;
    width: 20px;
    left: 2px;
    bottom: 2px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border-radius: 50%;
  }
`;

export const Checkbox = styled.input.attrs<{ $checkedcolour: string }>(
  (props) => ({
    $checkedcolour: props.$checkedcolour,
  })
)`
  opacity: 0;
  width: 0;
  height: 0;
  &:checked + ${Slider} {
    background-color: ${(props) => props.$checkedcolour}};
  }
  &:checked + ${Slider}:before {
    -webkit-transform: translateX(15px);
    -ms-transform: translateX(15px);
    transform: translateX(20px);
  }
`;
