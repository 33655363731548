import { textStyles } from "@client/src/utils/textStyles";
import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
`;

export const ContentWrapper = styled.div`
  width: 40rem;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 12.5rem;
  margin-right: 37.5rem;
  
  @media (max-width: 1024px) {
    width: 100%;
    padding: 1rem;
    margin: 5rem 0 12.5rem 0;
    justify-content: center;
    align-items: center;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;
`;

export const Text = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 100%;

  > * {
    padding-left: 0;
  }
  `;

export const Title = styled.div`
  ${textStyles.textXlBold};
`;

export const Message = styled.div`
  ${textStyles.text5xlBold};

  @media (max-width: 768px) {
    ${textStyles.text3xlBold};
  }
`;

export const SubMessage = styled.div`
  ${textStyles.textBase};
  color: ${(props) => props.theme.subtextColor};
`;
