import { AppLogo, MobileAppLogo } from "@assets/Icons";
import { useSubscriptionProducts } from "@hooks/useSubscriptionProducts";
import { isMobile } from "@utils/device";
import React, { useEffect } from "react";
import { HiTag } from "react-icons/hi2";
import { useTheme } from "styled-components";
import Label from "../../label/Label.component";
import PaymentPortal from "../../payment_portal/PaymentPortal.component";
import { ComingSoonItem, IncludedItem } from "../item/PlanItem.component";
import { Container } from "../Plans.style";
import {
  PlanDetails,
  betaMockDetails,
  ultimateMockDetails,
} from "./UltimatePage.helper";
import {
  BetaItemContainer,
  BetaItemContent,
  BetaItemDesc,
  BetaItemTitle,
  BillingDetailsTitle,
  Content,
  DarkContainer,
  Description,
  Header,
  NavbarContainer,
  PlanContent,
  PlanContentItem,
  PlanContentTitle,
  Root,
  SecondaryText,
  StyledLink,
  Title,
} from "./UltimatePage.style";
import TermsDialogWrapper from
  "../../terms_dialog_wrapper/TermsDialogWrapper.component";

const hasLogo = (appLogo: string | undefined) => appLogo && appLogo.length > 0;

export const renderAppLogo = (appLogo: string | undefined) => {
  if (hasLogo(appLogo)) {
    return <img src={appLogo} alt={"app logo"} style={{ height: "24px" }} />;
  } else {
    if (isMobile()) return <MobileAppLogo />;
    return <AppLogo />;
  }
};
const NavbarSimple = () => {
  const theme = useTheme();
  return (
    <Root>
      <NavbarContainer>
        <StyledLink href={`/${window.location.search}`} data-testid="app-logo">
          {renderAppLogo(theme.appLogo)}
        </StyledLink>
      </NavbarContainer>
    </Root>
  );
};

export const BetaItem = ({ details }: { details: PlanDetails }) => {
  return (
    <BetaItemContainer>
      <div>{details.icon}</div>
      <BetaItemContent>
        <BetaItemTitle>{details.title}</BetaItemTitle>
        <BetaItemDesc>{details.desc}</BetaItemDesc>
      </BetaItemContent>
    </BetaItemContainer>
  );
};
const UltimatePage = () => {
  const { subscriptionProducts } = useSubscriptionProducts();
  const [productId, setProductId] = React.useState<string | null>(null);
  useEffect(() => {
    if (subscriptionProducts.length === 0) return;
    const ultimateProduct = subscriptionProducts.find(
      (product) => product.productName.toLowerCase() === "beta ultimate"
    );
    setProductId(ultimateProduct?.productId || null);
  }, [subscriptionProducts]);
  return (
    <Container>
      <NavbarSimple />
      <Content>
        <Header>
          <Label
            variant={"beta"}
            text={"Get 3 months free!"}
            leftIcon={<HiTag />}
          />
          <Title>You’re invited to Leopard AI’s Beta Group!</Title>
          <Description>
            Your participation is so important to us and will play a vital role
            in the success of Leopard AI. Because we’re asking for your help in
            testing our groundbreaking technical analysis tools, we want to
            offer you unlimited access for 3 months. This will give you the
            chance to explore and experiment with all Leopard AI features and
            grant you privileged access as we develop our best-in-class
            technical analysis tools.
          </Description>
        </Header>
        <PlanContent>
          <PlanContentItem>
            <PlanContentTitle>How Beta works</PlanContentTitle>
            <DarkContainer>
              {betaMockDetails.map((item, index) => (
                <BetaItem details={item} key={index + item.title} />
              ))}
            </DarkContainer>
          </PlanContentItem>
          <PlanContentItem>
            <PlanContentTitle>Your plan</PlanContentTitle>
            <DarkContainer>
              <PlanContentTitle>Ultimate</PlanContentTitle>
              {ultimateMockDetails.includedItems.map((item, index) => (
                <IncludedItem item={item} key={index + item} />
              ))}
              {ultimateMockDetails.comingSoonItems.map((item, index) => (
                <ComingSoonItem item={item} key={index + item} />
              ))}
              <SecondaryText>*coming soon</SecondaryText>
            </DarkContainer>
          </PlanContentItem>
          <PlanContentItem>
            <PlanContentTitle>Billing</PlanContentTitle>
            <DarkContainer>
              <BillingDetailsTitle>$0 today</BillingDetailsTitle>
              <SecondaryText>$45/month from 30th April 2024</SecondaryText>
            </DarkContainer>
            <BillingDetailsTitle>Select Payment Method</BillingDetailsTitle>
            {productId && (
              <PaymentPortal productId={productId} setProductId={() => null} />
            )}
          </PlanContentItem>
        </PlanContent>
      </Content>
      <TermsDialogWrapper />
    </Container>
  );
};

export default UltimatePage;
