import React from "react";
import { notAllowedPattern } from "./PatternConfig.helpers";
import {
  Container,
  Title,
  VerticalContainer,
} from "./PatternConfig.style";
import { useAppContext } from "@client/src/context/AppContext";
import PatternConfigItem from
  "../pattern_config_item/PatternConfigItem.component";
import { Divider } from "@client/src/app/App.style";
import { GLOBAL_PATTERN_TYPE } from "@common/types";
import { PatternType } from "@generated/pattern/common_pb";

const PatternConfig = () => {
  const {
    updateGlobalThreshold,
    updateGlobalEnabled,
    globalConfigItem,
    setGlobalConfigItem,
  } = useAppContext();

  const handleGlobalToggleChange = (checked: boolean) => {
    const updatedGlobal = { ...globalConfigItem };
    updatedGlobal.enabled = checked;
    setGlobalConfigItem(updatedGlobal);
    updateGlobalEnabled(checked);
  };

  const handleGlobalInputChange = (value: string) => {
    const updatedGlobal = { ...globalConfigItem };
    updatedGlobal.threshold = Number(value);
    setGlobalConfigItem(updatedGlobal);
    updateGlobalThreshold(Number(value));
  };

  return (
    <Container>
      <Title>Pattern Configuration</Title>
      <VerticalContainer>
        <PatternConfigItem
          patternType={GLOBAL_PATTERN_TYPE}
          threshold={globalConfigItem.threshold}
          handleToggleChange={handleGlobalToggleChange}
          handleInputChange={handleGlobalInputChange}
          title="All Patterns"
          global
        />
        <Divider />
        {Object.keys(PatternType).map((key) => {
          const patternType =
            PatternType[key as keyof typeof PatternType];
          if (notAllowedPattern(patternType)) {
            return null;
          }
          return (
            <PatternConfigItem
              patternType={patternType}
              threshold={globalConfigItem.threshold}
              key={key}
            />
          );
        })}
      </VerticalContainer>
    </Container>
  );
};

export default PatternConfig;
