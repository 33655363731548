import { parseAlertInfo } from "@client/src/utils/alertParser";
import { useAlerts } from "@hooks/useAlerts";
import React, { useEffect } from "react";
import { HiBellAlert, HiCheckCircle } from "react-icons/hi2";
import { useAlertContext } from "../../context/AlertContext";
import { EmptyState } from "../empty_state/EmptyState.component";
import { Container } from "./Inbox.styles";
import { InboxItem } from "./inbox_item/InboxItem.component";

const Inbox = () => {
  const { alerts } = useAlertContext();
  const { markBatchAsRead } = useAlerts();

  useEffect(() => {
    markBatchAsRead(
      alerts.filter((alert) => !alert.read).map((alert) => alert.eventId ?? "")
    );
  }, []);

  if (alerts.length === 0) {
    return (
      <EmptyState
        icon={HiBellAlert}
        title={"No Alerts yet!"}
        description={"You'll find your alerts here when they're triggered"}
      />
    );
  }

  const sortedAlerts = [...alerts].sort((a, b) => {
    const dateA = new Date(parseAlertInfo(a).firedAt);
    const dateB = new Date(parseAlertInfo(b).firedAt);
    return dateB.getTime() - dateA.getTime();
  });

  return (
    <div>
      <Container data-testid="inbox-item">
        {sortedAlerts.map((data, index) => (
          <InboxItem key={index} data={data} />
        ))}
      </Container>
      <EmptyState
        icon={HiCheckCircle}
        title={"You're up to date"}
        description={"Nothing left to see here"}
      />
    </div>
  );
};

export default Inbox;
