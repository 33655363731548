import { useAuth0, User } from "@auth0/auth0-react";
import Footer from "@client/src/components/footer/Footer.component";
// eslint-disable-next-line max-len
import { SubscriptionDetails } from "@common/types";
// eslint-disable-next-line max-len
import Avatar from "@components/avatar/Avatar.component";
import Button from "@components/button/Button.component";
import { Modal } from "@components/modal/Modal.component";
import Navbar from "@components/navbar/Navbar.component";

import Section from "@components/settings/section/Section.component";
// eslint-disable-next-line max-len
import SettingsButton from "@components/settings/settings_button/SettingsButton.component";
import SubscriptionTag from "@components/sub_tag/SubscriptionTag.component";
// eslint-disable-next-line max-len
import SubscriptionProfileDetails from "@components/subscription_profile_details/SubscriptionProfileDetails.component";
import React, { useCallback, useEffect, useState } from "react";
import {
  HiBugAnt,
  HiChevronRight,
  HiQuestionMarkCircle,
} from "react-icons/hi2";
import { useNavigate, useParams } from "react-router-dom";
import { Slide, ToastContainer } from "react-toastify";
import { useTheme } from "styled-components";
import { AlertProvider } from "../../context/AlertContext";
import { InstrumentProvider } from "../../context/InstrumentContext";
import { useProfileContext } from "../../context/ProfileContext";
import { useUserContext } from "../../context/user/UserContext";
import {
  AvatarContainer,
  Email,
  Name,
  ProfileContainer,
  UserID,
  UserInfoContainer,
  Wrapper,
} from "./Profile.style";
// eslint-disable-next-line max-len
import TermsDialogWrapper from "@components/terms_dialog_wrapper/TermsDialogWrapper.component";
import { Tabbar } from "@client/src/components/tabbar/Tabbar.component";
import AlertsSettings from "../alerts_settings/AlertsSettings.component";
import SubCTA from "@client/src/components/sub_cta/SubCTA.component";

export const displayCredits = (credits: number) => {
  if (credits === -1) {
    return "∞";
  }
  return credits;
};

export const userHasFullName = (user: User): boolean =>
  user.given_name !== undefined && user.family_name !== undefined;
export const getUserName = (user: User | undefined) => {
  if (!user) return "Unknown User";
  if (!userHasFullName(user)) return user.nickname;
  return `${user.given_name} ${user.family_name}`;
};

export const openCancelConfirmation = (
  closeModal: () => void,
  handleCancelSubscription: () => void,
  activeSubscription: SubscriptionDetails | undefined
) => {
  return (
    <Modal
      modalTitle={`Are you sure you want to cancel your
       ${activeSubscription?.productName} Subscription?`}
      modalDescription="Your account will be downgraded to a Free Account at
       the end of your billing period. Your access will be limited to 10
        instruments, and you will no longer receive free Alerts monthly."
      Button1={
        <Button
          text="Cancel Subscription"
          type="destructive"
          onClick={handleCancelSubscription}
        />
      }
      Button2={
        <Button text="Cancel" type="secondary" onClick={() => closeModal()} />
      }
      isVisible={true}
    />
  );
};

const getSubscriptionNameSafe = (
  activeSubscription: SubscriptionDetails | undefined
) => activeSubscription?.productName || "";

const Profile = ({ logoutRedirect }: { logoutRedirect: string }) => {
  const { isAuthenticated, user, logout } = useAuth0();
  const { showPurchaseModal } = useProfileContext();
  const navigate = useNavigate();
  const theme = useTheme();
  const params = useParams();
  const { isSubscribed, activeSubscription, updateContext } =
    useUserContext();
  const [activeTab, setActiveTab] = useState("1");
  const navigateToExternal = (url: string) => {
    window.open(url, "_blank");
  };
  useEffect(() => {
    updateContext();
  }, []);
  useEffect(() => {
    // check if user visits /profile/purchase for back compat.
    if (params["*"]?.startsWith("purchase")) {
      showPurchaseModal();
      // set back to /profile
      window.history.replaceState(
        null,
        "",
        `/profile${window.location.search}`
      );
    }
  }, [params]);
  if (!isAuthenticated) return null;
  const subscriptionName = getSubscriptionNameSafe(activeSubscription);

  const setSelectedTab = (tabId: string) => {
    setActiveTab(tabId);
  };

  const AlertsNotificationsContent = () => {
    return <AlertsSettings />;
  };

  const SubscriptionBillingContent = () => {
    return (
      <Section title="">
        <SubscriptionProfileDetails
          handleClickPurchase={() => navigate("/plans")}
        />
      </Section>
    );
  };

  const HelpInfoContent = () => {
    return (
      <Section title="">
        <SettingsButton
          onClick={() => navigateToExternal("https://support.runleopard.ai")}
          leftIcon={<HiQuestionMarkCircle />}
          text="Help & FAQs"
          rightIcon={<HiChevronRight />}
        />
        <SettingsButton
          onClick={() =>
            navigateToExternal(
              "https://support.runleopard.ai/hc/en-gb/requests/new"
            )
          }
          leftIcon={<HiBugAnt />}
          text="Report an issue"
          rightIcon={<HiChevronRight />}
        />
      </Section>
    );
  };

  const TAB_CONTENT_MAP: { [key: string]: () => JSX.Element } = {
    "1": SubscriptionBillingContent,
    "2": AlertsNotificationsContent,
    "3": HelpInfoContent,
  };

  const renderTabContent = useCallback(() => {
    const ActiveContentComponent = TAB_CONTENT_MAP[activeTab];
    return <ActiveContentComponent />;
  }, [activeTab]);

  return (
    <InstrumentProvider>
      <AlertProvider>
        <Navbar />
        <ProfileContainer>
          <Wrapper>
            <UserInfoContainer>
              <AvatarContainer>
                <Avatar
                  src={user?.picture}
                  alt="user avatar"
                  data-testid="avatar"
                />
              </AvatarContainer>
              <div>
                <UserInfoContainer>
                  <Name>{getUserName(user)}</Name>
                  {isSubscribed && (
                    <SubscriptionTag subscriptionTier={subscriptionName} />
                  )}
                </UserInfoContainer>
                <Email>{user?.email}</Email>
              </div>
              <UserID>User ID: {user?.sub}</UserID>
            </UserInfoContainer>
            <SubCTA isSubscribed={isSubscribed} />
            <div id="Setting tabs">
              <Tabbar
                data={[
                  { id: "1", title: "Subscription & Billing" },
                  { id: "2", title: "Alert & Notifications" },
                  { id: "3", title: "Help & Info" },
                ]}
                onTabSelectedChange={(tabId) => {
                  setSelectedTab(tabId);
                }}
              />
              {renderTabContent()}
            </div>
            <Button
              text="Logout"
              onClick={() =>
                logout({
                  logoutParams: {
                    returnTo: logoutRedirect,
                  },
                })
              }
            />
            <ToastContainer
              toastStyle={{
                backgroundColor: theme?.toastProperties?.background,
              }}
              style={{
                maxWidth: "30rem",
                width: "100%",
                padding: "0.5rem",
                boxShadow: `0px 1px 2px -1px rgba(0, 0, 0, 0.10),
                  0px 4px 4px 0px rgba(0, 0, 0, 0.10)`,
              }}
              position={"top-left"}
              autoClose={5000}
              hideProgressBar={true}
              newestOnTop={true}
              transition={Slide}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
            />
          </Wrapper>
        </ProfileContainer>
        <Footer />
      </AlertProvider>
      <TermsDialogWrapper />
    </InstrumentProvider>
  );
};

export default Profile;
