import styled from "styled-components";
import { baseStyle, textStyles } from "@utils/textStyles";

export const ItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
`;

export const ItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem 1rem 0rem 1rem;
  gap: 1rem;
`;

export const ItemIcon = styled.img`
  background-color: white;
  display: flex;
  width: 1.5rem;
  height: 1.5rem;
  justify-content: center;
  align-items: center;
  align-content: center;
  border-radius: 9999%;
`;

export const ItemContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

export const DeleteItem = styled.button`
  ${baseStyle};
  // remove base styling
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  font-family: inherit;
  font-size: inherit;

  // styling
  cursor: pointer;
  width: 1rem;
`;

export const Message = styled.div`
  ${baseStyle};
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: flex-start;
  gap: 0rem 0.25rem;
  align-self: stretch;
  flex-wrap: wrap;
`;

export const TextRegular = styled.span`
  ${textStyles.textBase};
`;

export const TextBold = styled.span`
  ${textStyles.textBaseBold};
`;

export const Subtext = styled.span`
  color: ${(props) => props.theme.subtextColor};
`;

export const Divider = styled.div`
  height: 0.0625rem;
  width: 100%;

  background-color: ${(props) => props.theme.dividerColor};
`;

export const AlertStatusContainer = styled.div`
  width: fit-content;
`;
