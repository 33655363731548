import {
  BellBetaIcon,
  CardBetaIcon,
  MailBetaIcon,
  RocketBetaIcon,
} from "@assets/Icons";
import React from "react";

export const ultimateMockDetails = {
  includedItems: [
    "Crypto",
    "Forex",
    "US Equities",
    "AI Market Analysis",
    "200 monthly Alerts",
    "Telegram Alerts",
  ],
  comingSoonItems: [
    "UK, EU & Swiss Equities*",
    "Screening*",
    "Backtesting*",
    "Complex Condition Alerts*",
    "AI Portfolio Analysis*",
  ],
};
export type PlanDetails = {
  title: string;
  desc: string;
  icon: React.ReactNode;
};
export const betaMockDetails: PlanDetails[] = [
  {
    title: "Today",
    desc: "Unlock full access to all of Leopard AI’s features",
    icon: <RocketBetaIcon />,
  },
  {
    title: "During Beta",
    desc: "We’ll ask you for feedback and ideas to improve the platform!",
    icon: <MailBetaIcon />,
  },
  {
    title: "Before the end of Beta ",
    desc:
      "We’ll remind you that the Beta Program is coming to an end, and you" +
      " can choose the right plan for you.",
    icon: <BellBetaIcon />,
  },
  {
    title: "30th April ",
    desc:
      "Your account will automatically be charged for the Monthly Ultimate" +
      " Subscription Plan.",
    icon: <CardBetaIcon />,
  },
];
