import React from "react";
import {
  ConditionBuilderBlock,
  useConditionBuilderContext,
} from "@client/src/context/ConditionBuilderContext";
import { blockReplacer } from "../condition_builder/ConditionBuilder.helper";
import { ValueInput } from "./ConditionBuilderValue.style";

const ConditionBuilderValue = ({ block }: { block: ConditionBuilderBlock }) => {
  const {
    setBlock,
    block: rootBlock,
  } = useConditionBuilderContext();
  return (
    <ValueInput
      type="number"
      value={block.value}
      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
        const updatedBlock = blockReplacer(
          block.id,
          rootBlock,
          {
            ...block,
            value: parseFloat(e.target.value),
          }
        );
        setBlock({ ...updatedBlock });
      }}
    />
  );
};

export default ConditionBuilderValue;
