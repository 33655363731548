import React from "react";
import {
  ConditionBuilderBlock,
  PriceBlock,
} from "@client/src/context/ConditionBuilderContext";
import {
  Container,
} from "./ConditionBuilderPrice.style";

type ConditionBuilderPriceProps = {
  block: ConditionBuilderBlock;
};

const ConditionBuilderPrice = ({
  block,
}: ConditionBuilderPriceProps) => (
  <Container>
    {(block as PriceBlock).operation}
  </Container>
);

export default ConditionBuilderPrice;
