// eslint-disable-next-line max-len
import { IconButton } from "@client/src/components/icon_button/IconButton.component";
// eslint-disable-next-line max-len
import PaymentPortal from "@client/src/components/payment_portal/PaymentPortal.component";
import {
  ComingSoonItem,
  IncludedItem,
} from "@client/src/components/plan_page/item/PlanItem.component";
import {
  PlanItemData,
  planItemsAnnually,
  planItemsMonthly,
} from "@client/src/components/plan_page/Plans.helper";
// eslint-disable-next-line max-len
import TermsDialogWrapper from "@client/src/components/terms_dialog_wrapper/TermsDialogWrapper.component";
// eslint-disable-next-line max-len
import { useSubscriptionProducts } from "@client/src/hooks/useSubscriptionProducts";
import { ProductItem } from "@common/types";
import UltimatePage, {
  BetaItem,
} from "@components/plan_page/ultimate_page/UltimatePage.component";
import {
  BillingDetailsTitle,
  ComingSoonText,
  Content,
  DarkContainer,
  DarkTextContainer,
  PlanContent,
  PlanContentItem,
  PlanContentTitle,
  SecondaryText,
} from "@components/plan_page/ultimate_page/UltimatePage.style";
import React from "react";
import { HiArrowLeft } from "react-icons/hi2";
import { useNavigate, useParams } from "react-router-dom";
import { Slide, ToastContainer } from "react-toastify";
import { useTheme } from "styled-components";
import { LoadingIndicator } from "../alerts_settings/AlertSettings.style";
import ErrorPage from "../errors/fullPageError.component";
import { planDetailsInfo } from "./PurchaseSubscription.helper";
import {
  Container,
  Header,
  LoadingContainer,
  PlanContainer,
} from "./PurchaseSubscription.styles";
import { format } from "date-fns";

const validateBillingCycle = (billingCycle: string) => {
  if (billingCycle === "monthly" || billingCycle === "annual")
    return billingCycle;
  return null;
};

const findPlanDetails = (title: string, billingCycle: string) => {
  if (!validateBillingCycle(billingCycle)) return null;
  const plans =
    billingCycle === "monthly" ? planItemsMonthly : planItemsAnnually;
  const formattedTitle = title.replace(/[_]+/g, " ").toLowerCase();
  return plans.find((plan) => plan.title.toLowerCase() === formattedTitle);
};

const renderPaymentPortal = (
  matchingProduct: { productId: string } | undefined,
  loading: boolean
) => {
  if (!matchingProduct || loading)
    return (
      <LoadingContainer>
        <LoadingIndicator />
      </LoadingContainer>
    );
  return (
    <PaymentPortal
      productId={matchingProduct.productId}
      setProductId={() => {
        return null;
      }}
    />
  );
};

const getRenewDate = () => {
  const today = new Date();
  const nextMonth = new Date(today);
  nextMonth.setMonth(today.getMonth() + 1);
  return format(nextMonth, "do MMMM yyyy");
};

const renderBillingSection = (
  planDetails: PlanItemData,
  matchingProduct: ProductItem | undefined,
  loading: boolean
) => (
  <>
    <PlanContentTitle>Billing</PlanContentTitle>
    <DarkTextContainer>
      <BillingDetailsTitle>$0 today</BillingDetailsTitle>
      <SecondaryText>
        {planDetails.annualPrice
          ? planDetails.annualPrice + " "
          : planDetails.price + planDetails.priceRecurringUnit + " "}
        from {getRenewDate()}
      </SecondaryText>
      {planDetails.previousPrice && (
        <SecondaryText>
          {planDetails.previousPrice} once the Introductory Offer ends
        </SecondaryText>
      )}
    </DarkTextContainer>
    <BillingDetailsTitle>Select Payment Method</BillingDetailsTitle>
    {renderPaymentPortal(matchingProduct, loading)}
  </>
);

const renderYourPlansSection = (planDetails: PlanItemData) => (
  <>
    <PlanContentTitle>Your plan</PlanContentTitle>
    <DarkContainer>
      <PlanContentTitle>{planDetails.title}</PlanContentTitle>
      {planDetails.includedItems.map((item, index) => (
        <IncludedItem item={item} key={index + item} />
      ))}
      {planDetails.comingSoonItems.map((item, index) => (
        <ComingSoonItem item={item} key={index + item} />
      ))}
      <ComingSoonText>*coming soon</ComingSoonText>
    </DarkContainer>
  </>
);

const renderHowItWorksSection = () => (
  <>
    <PlanContentTitle>How does it work?</PlanContentTitle>
    <DarkContainer style={{ gap: "2rem" }}>
      {planDetailsInfo.map((item, index) => (
        <BetaItem details={item} key={index + item.title} />
      ))}
    </DarkContainer>
  </>
);

const RegularSubscription = ({ planName }: { planName: string }) => {
  const theme = useTheme();
  const [title, billingCycle] = (planName?.split("-") as string[]) || [];
  const navigate = useNavigate();
  const { subscriptionProducts } = useSubscriptionProducts();

  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    if (subscriptionProducts.length > 0) {
      setLoading(false);
    }
  }, [subscriptionProducts]);

  const planDetails = findPlanDetails(title, billingCycle);
  if (!planDetails) {
    return <ErrorPage errorStatus={404} />;
  }

  const productNameWithSuffix =
    `${planDetails.title} ${planDetails.suffix}`.toLowerCase();
  const matchingProduct = subscriptionProducts.find(
    (product) => product.productName.toLowerCase() === productNameWithSuffix
  );

  const goBack = () => {
    navigate("/plans");
  };

  return (
    <Container>
      <Header>
        <IconButton Icon={HiArrowLeft} onClick={goBack} />
      </Header>
      <Content>
        <PlanContent>
          <PlanContainer>
            <PlanContentItem>{renderHowItWorksSection()}</PlanContentItem>
            <PlanContentItem>
              {renderYourPlansSection(planDetails)}
            </PlanContentItem>
            <PlanContentItem>
              {renderBillingSection(planDetails, matchingProduct, loading)}
            </PlanContentItem>
          </PlanContainer>
        </PlanContent>
      </Content>
      <ToastContainer
        toastStyle={{
          backgroundColor: theme?.toastProperties?.background,
        }}
        style={{
          maxWidth: "30rem",
          width: "100%",
          padding: "0.5rem",
        }}
        position={"top-left"}
        autoClose={5000}
        hideProgressBar={true}
        newestOnTop={true}
        transition={Slide}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
      />
      <TermsDialogWrapper />
    </Container>
  );
};

const PurchaseSubscription = () => {
  const { planName } = useParams<{ planName: string }>();
  if (planName === "beta-ultimate") return <UltimatePage />;
  return <RegularSubscription planName={planName ?? ""} />;
};

export default PurchaseSubscription;
