import { textStyles } from "@utils/textStyles";
import styled from "styled-components";

export const Title = styled.p`
  ${textStyles.textSm}
  padding: 0;
  margin: 0;
`;

export const SubscriptionType = styled.p`
  ${textStyles.textLgBold}
  padding: 0;
  margin: 0;
`;

export const DetailsText = styled.p`
  ${textStyles.textBaseBold}
  padding: 0;
  margin: 0;
`;

export const SubscriptionDetailsContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 2rem;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;
export const SubscriptionDatesContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 2rem;
`;

export const DetailsItem = styled.div`
  display: flex;
  gap: 0.25rem;
  flex-direction: column;
`;

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: #444444;
`;
