import React, { useState } from "react";
import ConditionBuilderLogicBlock from
  "../condition_builder_logic_block/ConditionBuilderLogicBlock.component";
import ConditionBuilderComparator from
  "../condition_builder_comparator/ConditionBuilderComparator.component";
import ConditionBuilderAdd from
  "../condition_builder_add/ConditionBuilderAdd.component";
import {
  ComparatorBlock,
  ConditionBuilderBlock,
  LogicBlock,
  useConditionBuilderContext,
} from "@client/src/context/ConditionBuilderContext";
import ConditionBuilderTechnical from
  "../condition_builder_technical/ConditionBuilderTechnical.component";
import ConditionBuilderValue from
  "../condition_builder_value/ConditionBuilderValue.component";
import { HiXMark } from "react-icons/hi2";
import {
  BlockContainer,
  RenderContainer,
  ShowRemoveButton,
} from "./ConditionBuilderBlockRenderer.style";
import { IconButton } from "../icon_button/IconButton.component";
import ConditionBuilderPrice from
  "../condition_builder_price/ConditionBuilderPrice.component";
import ConditionBuilderCross from
  "../condition_builder_cross/ConditionBuilderCross.component";

const ConditionBuilderBlockRenderer = ({
  block,
}: {
  block: ConditionBuilderBlock;
}) => {
  const { deleteBlock } = useConditionBuilderContext();
  const [hoveredBlock, setHoveredBlock] = useState<string>("");

  if (!block) return null;

  const handleMouseEnter = () => setHoveredBlock(block.id);
  const handleMouseLeave = () => setHoveredBlock("");

  const isBlockEmpty = () => {
    return block.blockType === "";
  };

  const renderAddBlock = () => {
    if (block.blockType !== "") return null;
    return <ConditionBuilderAdd blockId={block.id} />;
  };

  const renderLogicBlock = () => {
    if (block.blockType !== "logic") return null;
    return (
      <ConditionBuilderLogicBlock
        leftSide={
          <ConditionBuilderBlockRenderer block={(block as LogicBlock).left} />
        }
        rightSide={
          <ConditionBuilderBlockRenderer block={(block as LogicBlock).right} />
        }
        logicSelector={(block as LogicBlock).operation}
      />
    );
  };

  const renderComparatorBlock = () => {
    if (block.blockType !== "comparator") return null;
    return (
      <ConditionBuilderComparator
        type={(block as ComparatorBlock).operation}
        left={(block as ComparatorBlock).left}
        right={(block as ComparatorBlock).right}
        onClick={() => {
          /*
           * TODO: set selected tool for comparator
           * mutate the action queue so that we know
           * which comparator to change
           */
          return;
        }}
      />
    );
  };

  const renderTechnicalBlock = () => {
    if (block.blockType !== "technical" && block.blockType !== "pattern")
      return null;
    return <ConditionBuilderTechnical block={block} />;
  };

  const renderValueBlock = () => {
    if (block.blockType !== "value") return null;
    return <ConditionBuilderValue block={block} />;
  };

  const renderPriceBlock = () => {
    if (block.blockType !== "price") return null;
    return <ConditionBuilderPrice block={block} />;
  };

  const renderCrossBlock = () => {
    if (block.blockType !== "cross") return null;
    return <ConditionBuilderCross block={block} />;
  };

  return (
    <RenderContainer
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <BlockContainer>
        {renderAddBlock()}
        {renderLogicBlock()}
        {renderComparatorBlock()}
        {renderTechnicalBlock()}
        {renderValueBlock()}
        {renderPriceBlock()}
        {renderCrossBlock()}
        {!isBlockEmpty() && (
          <ShowRemoveButton
            $isvisible={(
              hoveredBlock === block.id && !isBlockEmpty()
            ).toString()}
          >
            <IconButton Icon={HiXMark} onClick={() => deleteBlock(block.id)} />
          </ShowRemoveButton>
        )}
      </BlockContainer>
    </RenderContainer>
  );
};

export default ConditionBuilderBlockRenderer;
