import React from "react";

export type PreferenceState = {
  alertPreferences: Array<AlertPreference>;
};

const createPreferenceState = (
  initialData?: Partial<PreferenceState> | undefined
): PreferenceState => ({
  alertPreferences: [],
  ...initialData,
});

export const PREFERENCE_CONTEXT_INITIAL_STATE = createPreferenceState();

export type AlertPreference = "email" | "web" | "telegram";
export type PreferenceAction =
  | {
      type: "set-alert-preferences";
      data: AlertPreference[];
    }
  | {
      type: "apply-alert-preference";
      data: {
        preference: AlertPreference;
        action: "add" | "remove";
      };
    };

const addPreference = (
  preferences: AlertPreference[],
  preference: AlertPreference
) => {
  const index = preferences.findIndex((s) => preference === s);
  if (index === -1) {
    preferences.push(preference);
  }
  return preferences;
};

const removePreference = (
  preferences: AlertPreference[],
  preference: AlertPreference
) => {
  const index = preferences.findIndex((s) => preference === s);
  if (index !== -1) {
    preferences.splice(index, 1);
  }
  return preferences;
};

const handleApplyAlertPreference = (
  action: {
    type: "apply-alert-preference";
    data: { preference: AlertPreference; action: "add" | "remove" };
  },
  prev: PreferenceState
) => {
  const { preference, action: preferenceAction } = action.data;
  let preferences = prev.alertPreferences;
  if (preferenceAction === "add") {
    preferences = addPreference(preferences, preference);
  } else if (preferenceAction === "remove") {
    preferences = removePreference(preferences, preference);
  }
  return { ...prev, alertPreferences: preferences };
};

const handleSetAlertPreferences = (
  prev: PreferenceState,
  action: {
    type: "set-alert-preferences";
    data: AlertPreference[];
  }
) => ({ ...prev, alertPreferences: action.data });

export const preferenceReducer: React.Reducer<
  PreferenceState,
  PreferenceAction
> = (prev, action) => {
  if (action.type === "set-alert-preferences") {
    {
      return handleSetAlertPreferences(prev, action);
    }
  } else if (action.type === "apply-alert-preference") {
    {
      return handleApplyAlertPreference(action, prev);
    }
  } else {
    return prev;
  }
};
