import { Portfolio } from "@common/types";
import { Instrument } from "@generated/common/basic-types_pb";
import React, { useEffect, useMemo, useState } from "react";
import { HiPencil } from "react-icons/hi";
import { HiTrash, HiXMark } from "react-icons/hi2";
import { IconButton } from "../../sigma-ui/atoms/button/button";
import { usePortfolioContext } from "../../context/PortfolioContext";
import Button from "../button/Button.component";
import {
  CloseButton,
  Dialog,
  DialogContainer,
  DialogHeader,
  Title,
} from "../dialog/Dialog.style";
import { Input, InputContainer } from "../input/Input.style";
import { Modal } from "../modal/Modal.component";
import { PortfolioItem } from "../portfolio_item/PortfolioItem";
import {
  PortfolioButtonContainer,
  AddPortfolioDialogBody,
  AddPortfolioDialogContainer,
  PortfolioListDialogBody,
  PortfolioItemContainer,
  ButtonContainer,
  InstrumentListContainer,
  SubText,
} from "./PortfolioDialogProvider.style";
import {
  renderCreateInstrumentItem,
  renderEditInstrumentItem,
} from "./PortfolioDialog.helper";

export const CreatePortfolioDialog = ({
  instruments,
  onClickCreate,
}: {
  instruments: Instrument.AsObject[];
  onClickCreate: () => void;
}) => {
  const [portfolioName, setPortfolioName] = useState<string>("");
  const { createPortfolio, setShowCreatePortfolioDialog } =
    usePortfolioContext();
  return (
    <DialogContainer>
      <Dialog>
        <DialogHeader>
          <Title>Add Portfolio</Title>
          <CloseButton
            onClick={() => {
              setShowCreatePortfolioDialog(false);
            }}
          >
            <HiXMark />
          </CloseButton>
        </DialogHeader>
        <AddPortfolioDialogContainer>
          <AddPortfolioDialogBody>
            <SubText>Select a name for your new Portfolio</SubText>
            <InputContainer>
              <Input
                type={"text"}
                placeholder={"Awesome Portfolio"}
                onChange={(event) => setPortfolioName(event.target.value)}
              />
            </InputContainer>
            <InstrumentListContainer>
              <SubText>{instruments.length} instruments selected</SubText>
              {instruments.map((instrument, index) =>
                renderCreateInstrumentItem(instrument, index)
              )}
            </InstrumentListContainer>
          </AddPortfolioDialogBody>
          <PortfolioButtonContainer>
            <Button
              text={"Create"}
              type={"primary"}
              onClick={() => {
                createPortfolio({
                  name: portfolioName,
                  hashcodes: instruments.map(
                    (instrument) => instrument.hashcode
                  ),
                });
                setShowCreatePortfolioDialog(false);
                setPortfolioName("");
                onClickCreate();
              }}
              disabled={portfolioName === ""}
            />
          </PortfolioButtonContainer>
        </AddPortfolioDialogContainer>
      </Dialog>
    </DialogContainer>
  );
};

const DeletePortfolioModal = ({
  onClose,
  onClick,
}: {
  onClick: () => void;
  onClose: () => void;
}) => {
  return (
    <Modal
      modalImage={<HiTrash />}
      modalTitle="Delete Portfolio"
      modalDescription="Are you sure you want to delete this Portfolio?"
      Button1={<Button text="Delete" type="destructive" onClick={onClick} />}
      Button2={<Button text="Cancel" type="secondary" onClick={onClose} />}
      isVisible={true}
    />
  );
};

export const PortfolioListDialog = ({
  onSelect,
}: {
  onSelect: (portfolio: Portfolio) => void;
}) => {
  const {
    portfolios,
    setShowPortfolioListDialog,
    deletePortfolio,
    editPortfolio,
  } = usePortfolioContext();
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [portfolioToDelete, setPortfolioToDelete] = useState<string>("");
  return (
    <DialogContainer>
      <Dialog>
        {showDeleteModal && (
          <DeletePortfolioModal
            onClick={() => {
              deletePortfolio(portfolioToDelete);
              setPortfolioToDelete("");
              setShowDeleteModal(false);
            }}
            onClose={() => setShowDeleteModal(false)}
          />
        )}
        <DialogHeader>
          <Title>Your saved Portfolios</Title>
          <CloseButton
            onClick={() => {
              setShowPortfolioListDialog(false);
            }}
          >
            <HiXMark />
          </CloseButton>
        </DialogHeader>
        <PortfolioListDialogBody>
          {portfolios.map((portfolio) => (
            <PortfolioItemContainer>
              <PortfolioItem
                key={portfolio.name}
                portfolio={portfolio}
                onClick={() => {
                  onSelect(portfolio);
                  setShowPortfolioListDialog(false);
                }}
              />
              {portfolio.name.toLowerCase() !== "all" && (
                <ButtonContainer>
                  <IconButton
                    icon={HiPencil}
                    onClick={() => {
                      editPortfolio(portfolio);
                    }}
                  />
                  <IconButton
                    icon={HiTrash}
                    onClick={() => {
                      setPortfolioToDelete(portfolio.id);
                      setShowDeleteModal(true);
                    }}
                  />
                </ButtonContainer>
              )}
            </PortfolioItemContainer>
          ))}
        </PortfolioListDialogBody>
      </Dialog>
    </DialogContainer>
  );
};

export const AddToPortfolioDialog = ({
  hashcodes,
  onSelect,
}: {
  onSelect: () => void;
  hashcodes: string[];
  onClickCreate: () => void;
}) => {
  const {
    portfolios,
    setShowAddToPortfolioDialog,
    manageAddHashcodesToPortfolio,
    setShowCreatePortfolioDialog,
  } = usePortfolioContext();
  const portfoliosToDisplay = useMemo(
    () =>
      portfolios.filter((portfolio) => portfolio.name.toLowerCase() !== "all"),
    [portfolios]
  );
  return (
    <DialogContainer>
      <Dialog>
        <DialogHeader>
          <Title>Add to Portfolio</Title>
          <CloseButton
            onClick={() => {
              setShowAddToPortfolioDialog(false);
            }}
          >
            <HiXMark />
          </CloseButton>
        </DialogHeader>
        <AddPortfolioDialogContainer>
          <PortfolioListDialogBody>
            {portfoliosToDisplay.map((portfolio) => (
              <PortfolioItemContainer>
                <PortfolioItem
                  fullWidth={true}
                  key={portfolio.name}
                  portfolio={portfolio}
                  onClick={() => {
                    manageAddHashcodesToPortfolio(portfolio, hashcodes);
                    onSelect();
                    setShowAddToPortfolioDialog(false);
                  }}
                />
              </PortfolioItemContainer>
            ))}
          </PortfolioListDialogBody>
          <PortfolioButtonContainer>
            <Button
              text={"Create new Portfolio"}
              type={"primary"}
              onClick={() => {
                setShowCreatePortfolioDialog(true);
                setShowAddToPortfolioDialog(false);
              }}
            />
          </PortfolioButtonContainer>
        </AddPortfolioDialogContainer>
      </Dialog>
    </DialogContainer>
  );
};

export const EditPortfolioDialog = ({
  instruments,
}: {
  instruments: Instrument.AsObject[];
}) => {
  const { setShowEditPortfolioDialog, portfolioToEdit, manageUpdatePortfolio } =
    usePortfolioContext();
  const [portfolioName, setPortfolioName] = useState<string>("");
  const [instrumentsToSave, setInstrumentsToSave] = useState<
    Instrument.AsObject[]
  >([]);

  useEffect(() => {
    if (portfolioToEdit) {
      setPortfolioName(portfolioToEdit.name);
      const updatedInstruments: Instrument.AsObject[] = [];
      instruments.reduce((acc, instrument) => {
        if (portfolioToEdit.hashcodes.includes(instrument.hashcode)) {
          acc.push(instrument);
        }
        return acc;
      }, updatedInstruments);
      setInstrumentsToSave(updatedInstruments);
    }
  }, [portfolioToEdit, instruments]);

  const handleOnSave = () => {
    if (!portfolioToEdit) return;
    manageUpdatePortfolio(
      portfolioToEdit,
      portfolioName,
      instrumentsToSave.map((instrument) => instrument.hashcode)
    );
  };

  const handleRemoveInstrument = (index: number) => {
    const updatedInstruments = [...instrumentsToSave];
    updatedInstruments.splice(index, 1);
    setInstrumentsToSave(updatedInstruments);
  };

  return (
    <DialogContainer>
      <Dialog>
        <DialogHeader>
          <Title>Edit Portfolio</Title>
          <CloseButton
            onClick={() => {
              setShowEditPortfolioDialog(false);
            }}
          >
            <HiXMark />
          </CloseButton>
        </DialogHeader>
        <AddPortfolioDialogContainer>
          <AddPortfolioDialogBody>
            <InputContainer>
              <Input
                type={"text"}
                placeholder={"Awesome Portfolio"}
                value={portfolioName}
                onChange={(event) => setPortfolioName(event.target.value)}
              />
            </InputContainer>
            <InstrumentListContainer>
              <SubText>Instruments</SubText>
              {instrumentsToSave.map((instrument, index) =>
                renderEditInstrumentItem(instrument, index, () =>
                  handleRemoveInstrument(index)
                )
              )}
            </InstrumentListContainer>
          </AddPortfolioDialogBody>
          <PortfolioButtonContainer>
            <Button
              text={"Save"}
              type={"primary"}
              onClick={handleOnSave}
              disabled={portfolioName === ""}
            />
          </PortfolioButtonContainer>
        </AddPortfolioDialogContainer>
      </Dialog>
    </DialogContainer>
  );
};
