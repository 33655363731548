import { textStyles } from "@client/src/utils/textStyles";
import styled from "styled-components";

export const Container = styled.div`
  width: 100vw;
  height: 100dvh;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  padding: 1rem;
  font-size: 1.5rem;
  color: ${(props) => props.theme.text};
  background-color: ${(props) => props.theme.background};
`;

export const Body = styled.div`
  width: 100dvw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.theme.background};
`;

export const PlanContainer = styled.div`
  padding: 1rem;
  display: flex;
  flex-direction: row;
  align-items: start;
  justify-content: center;
  gap: 1.5rem;
  height: fit-content;
  border-radius: 1rem;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const PaymentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`;

export const PaymentMethodText = styled.div`
  ${textStyles.textXlBold};
  color: ${(props) => props.theme.text};
`;

export const LoadingContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
`;
