import { CandlestickChartState } from "./state";
import { HistogramData, LineData, Time } from "lightweight-charts";
import { transformTsIntervalToTime } from "@components/chart/Chart.helpers";
import type { TAResponseV1 } from "@generated/ta/technical_analysis";
import Long from "long";
import {
  addHueOffsetToColor,
  getChartIndicatorColor,
  hueOffsetFromIndex,
} from "../helpers/colors/utils";

const isSMA = (data: TAResponseV1["results"][number]) => data.ma?.kind === 1;

const isEMA = (data: TAResponseV1["results"][number]) => data.ma?.kind === 3;

const updateMaTechnicals = (
  state: CandlestickChartState,
  technicals: TAResponseV1
) => {
  for (const series of state.currentTechnicalMaSeries) {
    try {
      state.lwChart.removeSeries(series);
    } catch (e) {
      console.warn("Attempted to remove series that doesn't exist");
    }
  }

  state.currentTechnicalMaSeries = technicals.results
    .filter(shouldRenderMa)
    .map((result, index) => {
      const series = state.lwChart.addLineSeries({
        color: addHueOffsetToColor(
          getChartIndicatorColor(
            isSMA(result) ? "SMA" : isEMA(result) ? "EMA" : undefined,
            state.theme
          ),
          hueOffsetFromIndex(index)
        ),
        priceLineVisible: false,
        lastValueVisible: false,
        lineWidth: state.theme.chart.patterns.lineWidth,
      });

      const seriesData = getLineSeriesData(technicals, result);
      series.setData(seriesData);

      return series;
    });
};

const updateVolumeTechnicals = (
  state: CandlestickChartState,
  technicals: TAResponseV1
) => {
  for (const series of state.currentVolumeSeries) {
    try {
      state.lwChart.removeSeries(series);
    } catch (e) {
      console.warn("Attempted to remove series that doesn't exist");
    }
  }

  state.currentVolumeSeries = technicals.results
    .filter(shouldRenderVolume)
    .map((result) => {
      const series = state.lwChart.addHistogramSeries({
        color: "rgba(132, 219, 72, 0.2)",
        priceScaleId: "left",
        priceLineVisible: false,
        priceFormat: {
          type: "volume",
        },
      });
      series.priceScale().applyOptions({
        scaleMargins: {
          top: 0.7,
          bottom: 0,
        },
      });

      const seriesData = getHistogramSeriesData(technicals, result);
      series.setData(seriesData);

      return series;
    });
};

export const updateTechnicals = (
  state: CandlestickChartState,
  technicals: TAResponseV1 | null
) => {
  if (!technicals) {
    return;
  }

  updateMaTechnicals(state, technicals);
  updateVolumeTechnicals(state, technicals);
};

const shouldRenderMa = (technical: TAResponseV1["results"][number]) => {
  if (technical.ma) {
    return true;
  }
};

const shouldRenderVolume = (technical: TAResponseV1["results"][number]) => {
  if (technical.key === 0) {
    return true;
  }
};

const getLineSeriesData = (
  technicals: TAResponseV1,
  technical: TAResponseV1["results"][number]
): LineData<Time>[] => {
  return technical.values
    .map((val, idx) => ({
      time: createLong(technicals.candles[idx].ts_interval),
      value: val,
    }))
    .sort((a, b) => Number(a.time.sub(b.time)))
    .map((datum) => ({
      time: transformTsIntervalToTime(datum.time.toNumber()),
      value: datum.value,
    }));
};

const getHistogramSeriesData = (
  technicals: TAResponseV1,
  technical: TAResponseV1["results"][number]
): HistogramData<Time>[] => {
  return technical.values
    .map((val, idx) => ({
      time: createLong(technicals.candles[idx].ts_interval),
      value: parseFloat(technicals.candles[idx].volume),
    }))
    .sort((a, b) => Number(a.time.sub(b.time)))
    .map((datum) => ({
      time: transformTsIntervalToTime(datum.time.toNumber()),
      value: datum.value,
    }));
};

const createLong = (input: Long) => {
  return new Long(input.low, input.high, input.unsigned);
};
