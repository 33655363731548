import { textStyles } from "@utils/textStyles";
import styled from "styled-components";

export const Root = styled.div`
  display: flex;
  background-color: ${(props) => props.theme.navBg};
  width: 100%;
  margin: 0;
`;

export const StyledLink = styled.a`
  display: flex;
`;

export const NavbarContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding: 1rem;
  @media (max-width: 768px) {
    padding: 0.5rem;
  }
`;

export const Content = styled.div`
  display: flex;
  margin-left: auto;
  margin-right: auto;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 75%;
  margin-top: 4rem;
  gap: 2.5rem;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const Title = styled.h1`
  ${textStyles.text5xlBold};
  margin: 0;
`;

export const Description = styled.p`
  ${textStyles.textLg};
  margin: 0;
`;

export const PlanContent = styled.div`
  display: flex;
  width: 100%;
  @media (max-width: 768px) {
    flex-direction: column;
  }
  gap: 1rem;
`;

export const PlanContentItem = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 1rem;
`;

export const PlanContentTitle = styled.p`
  ${textStyles.textXlBold};
  margin: 0;
`;

export const DarkContainer = styled.div`
  display: flex;
  flex-direction: column;
  background: ${(props) => props.theme.planConfig.darkBackground};
  padding: 1.5rem;
  gap: 1rem;
  border-radius: 0.5rem;
`;

export const DarkTextContainer = styled(DarkContainer)`
  gap: 0.25rem;
`;

export const SecondaryText = styled.p`
  ${textStyles.textSm};
  color: ${(props) => props.theme.planConfig.secondaryText};
  margin: 0;
`;

export const ComingSoonText = styled.p`
  ${textStyles.textSm};
  color: ${(props) => props.theme.planConfig.comingSoonColor};
  margin: 0;
`;

export const BetaItemContainer = styled.div`
  display: flex;
  gap: 0.5rem;
`;

export const BetaItemContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

export const BetaItemTitle = styled.p`
  ${textStyles.textBaseBold};
  margin: 0;
`;

export const BetaItemDesc = styled.p`
  ${textStyles.textBase};
  margin: 0;
`;

export const BillingDetailsTitle = styled.p`
  ${textStyles.textXlBold};
  margin: 0;
`;
