import { createChartState, MacdChartParams } from "./state";
import {
  makeDisposable,
  throwIfDisposed,
} from "../helpers/dispose";
import { ChartData } from "@hooks/useFetchChart";
import { updateTechnicals } from "./technicals";
import { ChartHandler } from "@client/src/charts/helpers/types";
import { registerChartWithSeries } from "@client/src/charts/macd/register-sync";

export const createMacdChart = (
  params: MacdChartParams
) => {
  const state = createChartState(params);
  registerChartWithSeries(state);
  updateTechnicals(state, params.technicals);

  return {
    chart: state.lwChart,
    elemRef: state.elem,
    update: (chartData: ChartData) => {
      throwIfDisposed(state);
      const { technicals } = chartData;
      updateTechnicals(state, technicals);
    },

    dispose: makeDisposable(state),
  } as ChartHandler;
};
