// source: ta/common.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() {
  if (this) { return this; }
  if (typeof window !== 'undefined') { return window; }
  if (typeof global !== 'undefined') { return global; }
  if (typeof self !== 'undefined') { return self; }
  return Function('return this')();
}.call(null));

goog.exportSymbol('proto.ai.sigmafinancial.ta.common.SourceType', null, global);
goog.exportSymbol('proto.ai.sigmafinancial.ta.common.TAOutputKey', null, global);
/**
 * @enum {number}
 */
proto.ai.sigmafinancial.ta.common.SourceType = {
  CLOSE: 0,
  HIGH: 1,
  LOW: 2,
  OPEN: 3,
  VOLUME: 4
};

/**
 * @enum {number}
 */
proto.ai.sigmafinancial.ta.common.TAOutputKey = {
  UNKNOWN: 0,
  AROON_UP: 1,
  AROON_DOWN: 2,
  ADI_ADX: 3,
  ADI_PLUS: 4,
  ADI_MINUS: 5,
  UPPER_BAND: 6,
  MIDDLE_BAND: 7,
  LOWER_BAND: 8,
  HMA: 9,
  TENKAN_SEN: 10,
  KIJUN_SEN: 11,
  SENKOU_SPAN_A: 12,
  SENKOU_SPAN_B: 13,
  MACD_OUTPUT: 14,
  MACD_SIGNAL: 15,
  MOMENTUM_OUTPUT: 16,
  MOMENTUM_SIGNAL: 17,
  RSI_OUTPUT: 18,
  SO_F1: 19,
  SO_F2: 20,
  TRIX_OUTPUT: 21,
  TRIX_SIGNAL: 22,
  TRSI_OUTPUT: 23,
  TSI_OUTPUT: 24,
  TSI_SIGNAL: 26,
  MA_OUTPUT: 27,
  TA_OP_OUTPUT: 28,
  DONCHIANLOWEST: 29,
  DONCHIANMIDDLE: 30,
  DONCHIANHIGHEST: 31,
  KELTNERSOURCE: 32,
  KELTNERUPPER: 33,
  KELTNERLOWER: 34,
  CHAIKINOSCILLATOR: 35
};

goog.object.extend(exports, proto.ai.sigmafinancial.ta.common);
