import { textStyles } from "@utils/textStyles";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  padding: 1rem;
  flex-direction: column;
  gap: 1rem;
  width: 25rem;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
`;

const Header = styled.div`
  display: flex;
`;

const Body = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.125rem;
`;

const Title = styled.span`
  ${textStyles.textLgBold};
`;

const Text = styled.span`
  ${textStyles.textBase};
  color: ${({ theme }) => theme.subtextColor};
`;

const Buttons = styled.div`
  display: flex;
  justify-content: space-between;
`;

export { Container, Header, Body, Buttons, Title, Text };
