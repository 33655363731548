import { useAlerts } from "@client/src/hooks/useAlerts";
import {
  getPatternConfig,
  updateStoragePatternConfigItem,
} from "@client/src/utils/configStorageManager";
import React, { useEffect, useState } from "react";
import { useTheme } from "styled-components";
import { useAlertContext } from "../../context/AlertContext";
import { useAppContext } from "../../context/AppContext";
import InputNumeric from "../input_numeric/InputNumeric.component";
import Toggle from "../toggle/Toggle.component";
import {
  getPatternColor,
  getPatternIcon,
  getPatternName,
  hasScoreAccess,
} from "./PatternConfigItem.helpers";
import {
  Container,
  Row,
  Text,
  Title,
  TitleContainer,
} from "./PatternConfigItem.style";
import { IconButton } from "../icon_button/IconButton.component";
import { HiBellPlus } from "@client/src/assets/Icons";
import { useUserContext } from "@client/src/context/user/UserContext";

type Props = {
  patternType: number;
  threshold: number;
  handleToggleChange?: (checked: boolean) => void;
  handleInputChange?: (value: string) => void;
  title?: string;
  global?: boolean;
  checked?: boolean;
};

const PatternConfigItem = ({
  patternType,
  threshold: initialThreshold,
  handleToggleChange,
  handleInputChange,
  title,
  global,
}: Props) => {
  const theme = useTheme();
  const {
    updatePatternConfigItem,
    patternConfigItems,
    globalConfigItem,
    frequency,
    hashcode,
  } = useAppContext();
  const { groups, setShowAccessDeniedDialog } = useUserContext();
  const { createAlert, isAlertCreated } = useAlerts();
  const { userAlerts } = useAlertContext();
  const PatternIcon = getPatternIcon(patternType);

  const getPatternOrInitialThreshold = (
    patternType: number,
    initialThreshold: number,
  ) =>
    patternConfigItems[patternType]?.threshold ?? initialThreshold;

  const getDefaultThreshold = () => {
    if (!hasScoreAccess(groups)) {
      globalConfigItem.threshold = 5;
      return 5;
    }
    if (global) {
      return globalConfigItem.threshold;
    }
    return getPatternOrInitialThreshold(patternType, initialThreshold);
  };

  const getDefaultIsEnabled = () => {
    if (global) {
      return globalConfigItem.enabled;
    }
    return patternConfigItems[patternType]?.enabled ?? true;
  };

  const [localThreshold, setLocalThreshold] = useState(
    getDefaultThreshold(),
  );
  const [isEnabled, setIsEnabled] = useState(
    getDefaultIsEnabled(),
  );

  useEffect(() => {
    setLocalThreshold(getDefaultThreshold());
    setIsEnabled(getDefaultIsEnabled());
  }, [JSON.stringify(patternConfigItems), patternType]);

  const updateToggleState = (checked: boolean) => {
    const updatedItem = {
      ...patternConfigItems[patternType],
      enabled: checked,
    };
    const existingConfig = getPatternConfig();
    if (existingConfig) {
      updateStoragePatternConfigItem(updatedItem);
    }
    updatePatternConfigItem(updatedItem, patternType);
  };

  const updateInputState = (value: string) => {
    const updatedItem = {
      ...patternConfigItems[patternType],
      threshold: Number(value),
    };
    const existingConfig = getPatternConfig();
    if (existingConfig) {
      updateStoragePatternConfigItem(updatedItem);
    }
    updatePatternConfigItem(updatedItem, patternType);
  };

  const handleItemToggleChange = (checked: boolean) => {
    handleToggleChange
      ? handleToggleChange(checked)
      : updateToggleState(checked);
  };

  const handleItemInputChange = (value: string) => {
    handleInputChange
      ? handleInputChange(value)
      : updateInputState(value);
  };

  const renderAlertBell = () => {
    if (global) return null;
    return (
      <IconButton
        checked={isAlertCreated(
          userAlerts,
          hashcode,
          frequency,
          localThreshold,
          patternType,
        )}
        onClick={() => {
          createAlert({
            patternType: patternType,
            instrument: hashcode,
            frequency: parseInt(frequency),
            patternConfidence: localThreshold,
          });
        }}
        Icon={HiBellPlus}
        tooltipContent={"Create Alert"}
      />
    );
  };

  const handleDisabledInputClick = () => {
    if (!hasScoreAccess(groups)) {
      setShowAccessDeniedDialog(true);
    }
  };

  const isScoreDisabled = () => {
    return !isEnabled || !hasScoreAccess(groups);
  };

  return (
    <Container key={patternType.toString()}>
      <Row>
        <TitleContainer>
          {PatternIcon && <PatternIcon />}
          <Title>
            {title ?? getPatternName(patternType)}
          </Title>
          {renderAlertBell()}
        </TitleContainer>
        <Toggle
          data-testid="toggle"
          onChange={handleItemToggleChange}
          checked={isEnabled}
          checkedColour={getPatternColor(
            patternType,
            theme.patternConfig.colours,
          )}
        />
      </Row>
      <Row
        disabled={isScoreDisabled()}
        onClick={handleDisabledInputClick}
      >
        <Text>Minimum Score</Text>
        <InputNumeric
          data-testid="input-numeric"
          value={localThreshold}
          unit={"%"}
          inputStyle={{ maxWidth: "2rem" }}
          onChange={handleItemInputChange}
          disabled={isScoreDisabled()}
          onClick={handleDisabledInputClick}
        />
      </Row>
    </Container>
  );
};

export default PatternConfigItem;
