import styled from "styled-components";

const SettingsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem;
  gap: 1.5rem;
  border-radius: 0.5rem;
`;

export default SettingsWrapper;
