import { textStyles } from "@client/src/utils/textStyles";
import styled from "styled-components";

const Title = styled.h1`
  display:none;
  margin: 0;
  ${textStyles.textLgBold}
  width: 100%;
  z-index: 10;
  color: ${(props) => props.theme.patternConfigText};
  position: sticky;
  padding: 10px 0;
  top: 0;
  background-color: ${(props) =>
    props.theme.patternConfigBg};
  @media (max-width: 768px) {
    display: none;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 1rem 1rem 1rem;
  gap: 1.5rem;
  height: 100%;
  overflow-y: auto;
  background-color: ${(props) =>
    props.theme.patternConfigBg};

  @media (max-width: 768px) {
    max-height: none;
    border-radius: 0.5rem;
    overflow: auto;
    margin: 1rem;
  }
`;

const VerticalContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 0;
`;

export { Title, Container, VerticalContainer };
