import { HiTrash } from "react-icons/hi2";
import Button from "../../button/Button.component";
import { Modal } from "../../modal/Modal.component";
import React from "react";
import { AlertRecord } from "@common/types";

export const handleDeleteItem = (
  alertRecord: AlertRecord,
  markAsDelete: (alertId: string) => void,
  closeModal: () => void
) => {
  markAsDelete(alertRecord.alertId);
  closeModal();
};

export const openDeleteConfirmation = (
  closeModal: () => void,
  alertRecord: AlertRecord,
  markAsDelete: (alertId: string) => void
) => {
  return (
    <Modal
      modalImage={<HiTrash />}
      modalTitle="Delete Alert"
      modalDescription="Are you sure you want to delete this alert?"
      Button1={<Button
        text="Delete"
        type="destructive"
        onClick={() => handleDeleteItem(
          alertRecord,
          markAsDelete,
          closeModal
        )} />}
      Button2={<Button
        text="Cancel"
        type="secondary"
        onClick={() => closeModal()}
      />}
      isVisible={true}
    />
  );
};
