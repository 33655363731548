import { ISeriesApi } from "lightweight-charts";
import { getChartOptions } from "./options";
import type { TAResponseV1 } from "@generated/ta/technical_analysis";
import {
  BaseChartState,
  createBaseChartState,
  PickChartParams,
} from "../helpers/state";

export type RsiChartParams = PickChartParams<
  RsiChartState,
  | "technicals"
>

export type RsiChartState = BaseChartState & {
  technicals: TAResponseV1 | null;
  currentLineSeries: ISeriesApi<"Line">[];
}

export const createChartState = (params: RsiChartParams): RsiChartState => {
  return {
    ...createBaseChartState(params, getChartOptions),
    technicals: null,
    currentLineSeries: [],
  };
};
