const stylesMap = {
  success: {
    borderRadius: "0.5rem",
    padding: "1rem",
    boxShadow: `0px 1px 2px -1px rgba(0, 0, 0, 0.10),
    0px 4px 4px 0px rgba(0, 0, 0, 0.10)`,
    background: "#31452d",
    color: "rgba(132, 219, 72, 1)",
  },
  tertiary: {
    borderRadius: "0.5rem",
    padding: "1rem",
    boxShadow: `0px 1px 2px -1px rgba(0, 0, 0, 0.10),
    0px 4px 4px 0px rgba(0, 0, 0, 0.10)`,
    background: "#FFE0D3",
    color: "#823210",
  },
  error: {
    borderRadius: "0.5rem",
    padding: "1rem",
    boxShadow: `0px 1px 2px -1px rgba(0, 0, 0, 0.10),
    0px 4px 4px 0px rgba(0, 0, 0, 0.10)`,
    background: "#492b1d",
    color: "rgba(255, 102, 37, 1)",
  },
  info: {
    borderRadius: "0.5rem",
    padding: "1rem",
    boxShadow: `0px 1px 2px -1px rgba(0, 0, 0, 0.10),
    0px 4px 4px 0px rgba(0, 0, 0, 0.10)`,
    background: "#232945",
    color: "rgba(67, 97, 238, 1)",
  },
  warning: {
    borderRadius: "0.5rem",
    padding: "1rem",
    boxShadow: `0px 1px 2px -1px rgba(0, 0, 0, 0.10),
    0px 4px 4px 0px rgba(0, 0, 0, 0.10)`,
    background: "#484124",
    color: "rgba(249, 216, 73, 1)",
  },
};

export const toastStyles = (
  type: keyof typeof stylesMap
) => {
  return stylesMap[type] || {};
};
