import React from "react";
import Inbox from "../inbox/Inbox.component";
import ManageAlert from "../manage_alerts/ManageAlert.component";

export const TABS = {
  INBOX: "inbox",
  MANAGE: "manage",
};

export const renderSelectedTabComponent = (selectedTab: string) => {
  switch (selectedTab) {
    case TABS.INBOX:
      return <Inbox />;
    case TABS.MANAGE:
      return <ManageAlert />;
    default:
      return null;
  }
};
