import { type TTechnical } from
  "@client/src/components/technicals_dialog/TechnicalsDialogFormSchema";
import { DefaultTheme } from "styled-components";
import Color from "color";

export const getChartIndicatorColor = (
  indicator: string | undefined,
  theme: DefaultTheme,
) => {
  if (indicator) {
    const color = theme.chartColors.indicators[
      indicator as TTechnical
    ] as string;

    if (color) {
      return color;
    }
  }

  return theme.chartColors.indicators["_DEFAULT"];
};

export const addHueOffsetToColor = (
  color: string,
  offset: number,
) => {
  const c = Color(color);
  return c.rotate(offset).hex();
};

export const hueOffsetFromIndex = (index: number) =>
  ((index + 1) * 60) % 360;
