import {
  createStylePrimitive,
  createStyleTheme,
} from "../sigma-ui/theme/utils";

const primitives = createStylePrimitive({});
const LeopardTheme = createStyleTheme(primitives, ({}) => ({
  chartColors: {
    patterns: {
      "all-pattern": "#ABABAB",
      "double-bottom": "#7209B7",
      "double-top": "#6A2EF0",
      "triple-bottom": "#4361EE",
      "triple-top": "#4CC9F0",
      "cup-and-handle": "#0BDB6B",
      "inverse-cup-and-handle": "#84DB48",
      "rounded-bottom": "#F9D849",
      "rounded-top": "#FB9700",
      "head-and-shoulders": "#FF6625",
      "inverse-head-and-shoulders": "#F72585",
    },
    /**
     * Base colors for the indicators (they are affected by hue)
     */
    indicators: {
      // when there are no colors to match
      _DEFAULT: "#ccc",
      RSI: "#0BDB6B",
      MACD: "#0BDB6B",
      EMA: "#F9D849",
      SMA: "#A08B18",
    },
  },
  termsBlock: {
    backgroundColor: "#242424",
  },
  navbar: {
    height: "77px",
  },
  patternSidepanel: {
    width: "24.25rem",
  },
  chartPanel: {
    main: {
      height: "29.4375rem",
    },
    mini: {
      height: "16rem",
    },
  },
}));

export const LeopardDarkTheme = LeopardTheme;
export const LeopardLightTheme = LeopardTheme;
export type LeopardTheme = typeof LeopardTheme;
