import React, { useMemo, useState } from "react";
import { HiChevronLeft } from "react-icons/hi";
import { HiArrowsUpDown } from "react-icons/hi2";
// eslint-disable-next-line max-len
import SelectionDropdown from "../../../selection_dropdown/SelectionDropdown.component";
import { renderForm } from "./CustomFilterDialog.helper";
import { Container, Header } from "./CustomFilterDialog.style";
import { CustomFilterDialogProps } from "./CustomFilterDialog.types";

export const CustomFilterDialog = <T extends object>({
  open,
  applyFilter,
  fields,
  onClose,
}: CustomFilterDialogProps<T>) => {
  if (!open) return null;
  const values = useMemo(() => fields.map((field) => field.operation), []);
  const [selectedOperation, setSelectedOperation] = useState<string>(values[0]);
  const selectedField = useMemo(
    () => fields.find((field) => field.operation === selectedOperation),
    [selectedOperation]
  );

  return (
    <Container>
      <Header onClick={onClose}>
        <HiChevronLeft />
        <span>Manual setup</span>
      </Header>
      <SelectionDropdown
        onSelect={(changedValue) => {
          setSelectedOperation(changedValue);
        }}
        Icon={<HiArrowsUpDown />}
        items={values}
        defaultItem={values.length > 0 ? values[0] : ""}
        dialogTitle={"Change"}
        customHeight={"300px"}
      />
      {renderForm(selectedField, applyFilter)}
    </Container>
  );
};
