import { Portfolio } from "@common/types";
import React from "react";
import {
  PortfolioInstrumentListContainer,
  PortfolioListContainer,
  PortfolioListHeader,
  PortfolioPrimaryText,
  PortfolioSecondaryText,
} from "./PortfolioItem.style";
import Label from "../label/Label.component";

export const PortfolioItem = ({
  portfolio,
  onClick,
  fullWidth,
}: {
  portfolio: Portfolio;
  onClick: () => void;
  fullWidth?: boolean;
}) => {
  return (
    <PortfolioListContainer
      onClick={onClick}
      style={{ width: `${fullWidth ? "100%" : "100%"}` }}
    >
      <PortfolioListHeader>
        <PortfolioPrimaryText>{portfolio.name}</PortfolioPrimaryText>
        <PortfolioSecondaryText>
          {portfolio.hashcodes.length} instruments
        </PortfolioSecondaryText>
      </PortfolioListHeader>
      <PortfolioInstrumentListContainer>
        {portfolio.visibleInstrumentNames?.map((visibleName) => (
          <Label text={visibleName} variant="tertiary"/>
        ))}
      </PortfolioInstrumentListContainer>
    </PortfolioListContainer>
  );
};
