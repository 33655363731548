import React, { useEffect, useMemo, useState } from "react";
import { HiArrowsUpDown } from "react-icons/hi2";
// eslint-disable-next-line max-len
import SelectionDropdown from "../../../selection_dropdown/SelectionDropdown.component";
import { CustomFilterDialog } from "../custom_dialog/CustomFilterDialog";
// eslint-disable-next-line max-len
import { CustomFilterDialogProps } from "../custom_dialog/CustomFilterDialog.types";
import {
  ChangeCustomFields,
  changeScreeningFilter,
  changeScreeningFilterValues,
} from "./ChangeScreeningFilter.helper";
import {
  ChangeFilterProps,
  ChangeFilterValue,
  InstrumentAndChange,
} from "./ChangeScreeningFilter.types";

export const ChangeScreeningFilter = ({
  onFilterChange,
  instrumentList,
  change,
}: ChangeFilterProps) => {
  const [customValue, setCustomValue] = useState<ChangeFilterValue | null>(
    null
  );
  const values = useMemo(
    () =>
      customValue
        ? [...changeScreeningFilterValues, customValue]
        : changeScreeningFilterValues,
    [customValue]
  );
  const [customFilterOpen, setCustomFilterOpen] = useState(false);
  const filter = changeScreeningFilter;
  const [selectedValue, setSelectedValue] = useState<ChangeFilterValue>(
    values[0]
  );

  const instrumentsWithChange: InstrumentAndChange[] = useMemo(() => {
    if (!change || !instrumentList) return [];
    return instrumentList.map((instrument) => {
      const changeSinceMidnight = change.find(
        (item) => item.instrumentHashcode === instrument.hashcode
      );
      return {
        ...instrument,
        change: changeSinceMidnight?.percentChange || "",
      };
    });
  }, [instrumentList, change]);

  useEffect(() => {
    onFilterChange(filter.apply(instrumentsWithChange, selectedValue));
  }, [selectedValue]);

  const dialogProps: CustomFilterDialogProps<ChangeFilterValue> =
    useMemo(() => {
      return {
        open: customFilterOpen,
        applyFilter: (newValue) => {
          setCustomValue(newValue);
          setCustomFilterOpen(false);
        },
        fields: ChangeCustomFields,
        onClose: () => setCustomFilterOpen(false),
      };
    }, [customFilterOpen]);

  return (
    <div>
      <SelectionDropdown
        onSelect={(changedValue) => {
          const value = values.find((value) => value.title === changedValue);
          setSelectedValue(value || values[0]);
        }}
        Icon={<HiArrowsUpDown />}
        items={values.map((value) => {
          return value.title;
        })}
        defaultItem={values.length > 0 ? values[0].title : ""}
        dialogTitle={"Change"}
        customHeight={"300px"}
        buttonValue={"Set Manually"}
        onButtonValuePressed={() => setCustomFilterOpen(true)}
      />
      <CustomFilterDialog {...dialogProps} />
    </div>
  );
};
