import { BaseChartState } from "./state";

export const addEventListeners = (chartInstance: BaseChartState) => {
  const onResize = () => {
    if (chartInstance.isDisposed) return;
    chartInstance.lwChart.resize(
      chartInstance.elem.clientWidth,
      window.innerHeight - 200,
    );
  };
  window.addEventListener("resize", onResize);

  return () => {
    window.removeEventListener("resize", onResize);
  };
};
