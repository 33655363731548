import {
  getGlobalPatternConfig,
  getPatternConfig,
  setGlobalPatternConfig,
  setPatternConfig,
} from "@client/src/utils/configStorageManager";
// eslint-disable-next-line max-len
import { notAllowedPattern } from "@components/pattern_config/PatternConfig.helpers";
import { PatternType } from "@generated/pattern/common_pb";
import { useState } from "react";
import {
  DEFAULT_PATTERN_SCORE_THRESHOLD,
  PatternConfigItem,
} from "@common/types";

export const getInitialState = () => {
  let stateArray: PatternConfigItem[] = [];
  Object.keys(PatternType).forEach((key, index) => {
    if (notAllowedPattern(index)) return;
    stateArray.push({
      threshold: DEFAULT_PATTERN_SCORE_THRESHOLD,
      enabled: true,
      type: index,
    });
  });
  const storedPatternConfig = getPatternConfig();
  if (storedPatternConfig.length > 0) {
    stateArray = storedPatternConfig;
  } else {
    setPatternConfig(stateArray);
  }
  return stateArray;
};

export const usePatternConfig = () => {
  const [patternConfigItems, setPatternConfigItems] = useState<
    PatternConfigItem[]
  >(getInitialState());
  const [globalConfig, setGlobalConfig] = useState<PatternConfigItem>(
    getGlobalPatternConfig()
  );
  const updateGlobalConfig = (value: PatternConfigItem) => {
    setGlobalConfig(value);
    setGlobalPatternConfig(value);
  };

  const update = (value: PatternConfigItem, index: number) => {
    setPatternConfigItems((prevItems) => {
      const newArray = [...prevItems];
      newArray[index] = { ...value };
      return newArray;
    });
  };

  const getEnabledPatterns = () =>
    patternConfigItems.filter((item) => item.enabled);

  return {
    patternConfigItems,
    update,
    getEnabledPatterns,
    updateGlobalConfig,
    globalConfig,
  };
};
