import React from "react";
import { Container, IconContainer } from "./IconButton.style";
import { Tooltip } from "../tooltip/Tooltip.component";

type IconProps = {
  onClick?: (event: React.MouseEvent) => void;
  checked?: boolean;
  Icon: React.ComponentType;
  tooltipContent?: string;
  rounded?: boolean;
  showHover?: boolean;
};

export const IconButton = ({
  onClick,
  checked = false,
  Icon,
  tooltipContent,
  rounded = false,
  showHover = true,
}: IconProps) => {
  return (
    <Tooltip>
      <Tooltip.Item>
        <Container
          onClick={onClick}
          $checked={checked.toString()}
          $rounded={rounded.toString()}
          $showhover={(!showHover).toString()}
          data-testid="icon-button"
        >
          <IconContainer data-testid="icon-container">
            <Icon />
          </IconContainer>
        </Container>
      </Tooltip.Item>
      {Boolean(tooltipContent) && <Tooltip.Content text={tooltipContent} />}
    </Tooltip>
  );
};
