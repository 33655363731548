import React from "react";
import Button from "../button/Button.component";
import { SubCTAContainer, Content, Text, Subtext } from "./SubCTA.styles";

const SubCTA = ({ isSubscribed }: { isSubscribed: boolean }) => {
  return (
    <SubCTAContainer>
      <Content>
        <Text>Hunt like a Leopard</Text>
        <Subtext>Upgrade to unlock the power of Leopard AI</Subtext>
      </Content>
      <Button
        text={isSubscribed ? "Upgrade" : "Choose Plan"}
        size="base"
        onClick={() => {
          window.location.href = "/plans";
        }}
        type="gold"
      />
    </SubCTAContainer>
  );
};

export default SubCTA;
