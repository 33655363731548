import { textStyles } from "@client/src/utils/textStyles";
import styled from "styled-components";

export const PortfolioListContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: 1rem;
  background-color: ${(props) => props.theme.portfolioItem.background};
  border-radius: 0.5rem;
  width: 80%;
  cursor: pointer;
  &:hover{
    background-color: ${(props) => props.theme.portfolioItem.backgroundHover};
  }
`;

export const PortfolioListHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`;

export const PortfolioPrimaryText = styled.span`
  ${textStyles.textLgBold};
  color: ${(props) => props.theme.portfolioItem.primaryText};
`;

export const PortfolioSecondaryText = styled.span`
  ${textStyles.textBase};
  color: ${(props) => props.theme.portfolioItem.secondaryText};
`;

export const PortfolioInstrumentListContainer = styled.div`
  display: flex;
  gap: 0.25rem;
  overflow: auto;
  flex-wrap: wrap;
  max-height: 2.5rem;
`;
