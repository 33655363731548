import React from "react";
import { InboxCounterContainer } from "./InboxAlertBell.styles";
import { HiBell, HiBellAlert } from "react-icons/hi2";
import { IconButton } from "../icon_button/IconButton.component";

type InboxBellProps = {
  count?: number;
  onClick: () => void;
};

const getIconComponent = (count: number) => (count > 0 ? HiBellAlert : HiBell);

const getCountDisplay = (count: number) => (count > 99 ? "99+" : count);

const InboxAlertBell = ({ count = 0, onClick }: InboxBellProps) => {
  const IconComponent = getIconComponent(count);

  return (
    <div style={{ position: "relative" }}>
      <IconButton
        onClick={onClick}
        Icon={IconComponent}
        tooltipContent="Alert Inbox"
        rounded
        showHover={false}
        data-testid="inbox-alert-bell"
      />
      {count > 0 && (
        <InboxCounterContainer>{getCountDisplay(count)}</InboxCounterContainer>
      )}
    </div>
  );
};

export default InboxAlertBell;
