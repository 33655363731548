import { useAuth0 } from "@auth0/auth0-react";
import { useEffect, useState } from "react";
import { Instrument } from "@generated/common/basic-types_pb";

export type InstrumentsResponse = {
  availableInstruments: Instrument.AsObject[];
  unavailableInstruments: Instrument.AsObject[];
};
const getInstrumentsUrl = () => "/api/instruments";

export const useInstruments = () => {
  const [instruments, setInstruments] = useState<Instrument.AsObject[]>([]);
  const [
    availableInstruments,
    setAvailableInstruments,
  ] = useState<Instrument.AsObject[]>([]);
  const [
    unavailableInstruments,
    setUnavailableInstruments,
  ] = useState<Instrument.AsObject[]>([]);
  const { getAccessTokenSilently } = useAuth0();
  useEffect(() => {
    try {
      getAccessTokenSilently()
        .then((token) => {
          fetch(getInstrumentsUrl(), {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          })
            .then((res) => res.json())
            .then((data: InstrumentsResponse) => {
              const availableInstruments = data.availableInstruments
                .filter(instrument => !!instrument);
              const unavailableInstruments = data.unavailableInstruments
                .filter(instrument => !!instrument);
              setInstruments([
                ...availableInstruments,
                ...unavailableInstruments,
              ]);
              setAvailableInstruments(availableInstruments);
              setUnavailableInstruments(unavailableInstruments);
            })
            .catch((err) => console.error(err));
        })
        .catch((err) => {
          console.error(err);
        });
    } catch (e) {
      console.error(e);
    }
  }, []);

  return {
    instruments,
    availableInstruments,
    unavailableInstruments,
  };
};
