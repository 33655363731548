import { Instrument } from "@generated/common/basic-types_pb";
import Fuse from "fuse.js";

type InstrumentListFuseInstance = ReturnType<typeof createFuseInstance>;
export const createFuseInstance = (instruments: Instrument.AsObject[]) =>
  new Fuse(instruments, {
    shouldSort: true,
    threshold: 0.1,
    minMatchCharLength: 1,
    keys: ["symbol", "name"],
  });

const isValidQuery = (query: string) => query.length > 0;

export const getInstrumentsToRender = (
  instruments: Instrument.AsObject[],
  searchQuery: string,
  fuseInstance: InstrumentListFuseInstance
) => {
  if (isValidQuery(searchQuery.trim())) {
    return fuseInstance.search(searchQuery.trim()).map(({ item }) => item);
  }
  return instruments;
};
