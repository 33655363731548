import { useAuth0 } from "@auth0/auth0-react";
import { SubscriptionDetails } from "@common/types";
import { useEffect, useState } from "react";

export const useUserContextData = () => {
  const { getAccessTokenSilently } = useAuth0();
  const [groups, setGroups] = useState<string[]>([]);
  const [activeSubscription, setActiveSubscription] = useState<
    SubscriptionDetails | undefined
  >(undefined);
  const [availableCredits, setAvailableCredits] = useState<number>(0);
  const [freeTrialAvailable, setFreeTrialAvailable] = useState<boolean>(true);

  const fetchUserContextData = () => {
    getAccessTokenSilently().then((token) => {
      fetch("/api/user-context", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then((res) => res.json())
        .then((res) => {
          if (res.error) {
            console.error("Failed to fetch user context");
          } else {
            setGroups(res.groups);
            setAvailableCredits(res.availableUnits.availableUnits);
            const subscription =
              JSON.stringify(res.activeSubscription) === JSON.stringify({})
                ? undefined
                : res.activeSubscription;
            setActiveSubscription(subscription);
            setFreeTrialAvailable(res.freeTrialAvailable);
          }
        })
        .catch(() => {
          console.error("Failed to fetch groups");
        });
    });
  };

  useEffect(() => {
    fetchUserContextData();
  }, []);

  const updateContext = () => fetchUserContextData();
  return {
    groups,
    activeSubscription,
    availableCredits,
    updateContext,
    freeTrialAvailable,
  };
};
