/* eslint-disable */

export const protobufPackage = "ai.sigmafinancial.ta.common";

export enum SourceType {
  CLOSE = 0,
  HIGH = 1,
  LOW = 2,
  OPEN = 3,
  VOLUME = 4,
  UNRECOGNIZED = -1,
}

export function sourceTypeFromJSON(object: any): SourceType {
  switch (object) {
    case 0:
    case "CLOSE":
      return SourceType.CLOSE;
    case 1:
    case "HIGH":
      return SourceType.HIGH;
    case 2:
    case "LOW":
      return SourceType.LOW;
    case 3:
    case "OPEN":
      return SourceType.OPEN;
    case 4:
    case "VOLUME":
      return SourceType.VOLUME;
    case -1:
    case "UNRECOGNIZED":
    default:
      return SourceType.UNRECOGNIZED;
  }
}

export function sourceTypeToJSON(object: SourceType): string {
  switch (object) {
    case SourceType.CLOSE:
      return "CLOSE";
    case SourceType.HIGH:
      return "HIGH";
    case SourceType.LOW:
      return "LOW";
    case SourceType.OPEN:
      return "OPEN";
    case SourceType.VOLUME:
      return "VOLUME";
    case SourceType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum TAOutputKey {
  UNKNOWN = 0,
  /**
   * AROON_UP - Aroon indicator
   *  The Aroon Up and the Aroon Down lines fluctuate between zero and 100, with
   *  values close to 100 indicating a strong trend and values near zero
   *  indicating a weak trend.
   */
  AROON_UP = 1,
  AROON_DOWN = 2,
  /**
   * ADI_ADX - Average Directional Index
   *  The ADX, negative directional indicator (-DI), and positive directional
   *  indicator (+DI) are momentum indicators. The ADX helps investors determine
   *  trend strength, while -DI and +DI help determine trend direction.
   */
  ADI_ADX = 3,
  ADI_PLUS = 4,
  ADI_MINUS = 5,
  /**
   * UPPER_BAND - Bollinger Bands
   *  Bollinger Bands® gives traders an idea of where the market is moving based
   *  on prices. It involves the use of three bands—one for the upper level,
   *  another for the lower level, and the third for the moving average. When
   *  prices move closer to the upper band, it indicates that the market may be
   *  overbought. Conversely, the market may be oversold when prices end up
   *  moving closer to the lower or bottom band.
   */
  UPPER_BAND = 6,
  MIDDLE_BAND = 7,
  LOWER_BAND = 8,
  /** HMA - Hull Moving Average */
  HMA = 9,
  /** TENKAN_SEN - Ichimoku Cloud */
  TENKAN_SEN = 10,
  KIJUN_SEN = 11,
  SENKOU_SPAN_A = 12,
  SENKOU_SPAN_B = 13,
  /** MACD_OUTPUT - MACD */
  MACD_OUTPUT = 14,
  MACD_SIGNAL = 15,
  /** MOMENTUM_OUTPUT - Momentum */
  MOMENTUM_OUTPUT = 16,
  MOMENTUM_SIGNAL = 17,
  /** RSI_OUTPUT - RSI */
  RSI_OUTPUT = 18,
  /** SO_F1 - Stockastic Oscillator */
  SO_F1 = 19,
  SO_F2 = 20,
  /** TRIX_OUTPUT - TRIX */
  TRIX_OUTPUT = 21,
  TRIX_SIGNAL = 22,
  /** TRSI_OUTPUT - Trend Strength Index */
  TRSI_OUTPUT = 23,
  /**
   * TSI_OUTPUT - True Strength Index
   *  - The TSI fluctuates between positive and negative territory. Positive
   *    territory means the bulls are more in control of the asset. Negative
   *    territory means the bears are more in control.
   *  - A signal line can be applied to the TSI indicator. When the TSI crosses
   *    above the signal line it can be used as a buy signal, and when it
   *    crosses below, a sell signal. Such crossovers occur frequently, so use
   *    with caution.
   */
  TSI_OUTPUT = 24,
  TSI_SIGNAL = 26,
  /** MA_OUTPUT - Moving Average */
  MA_OUTPUT = 27,
  /** TA_OP_OUTPUT - Technical Analysis Op Output */
  TA_OP_OUTPUT = 28,
  /** DonchianLowest - Donchain */
  DonchianLowest = 29,
  DonchianMiddle = 30,
  DonchianHighest = 31,
  /** KeltnerSource - KeltnerChannel */
  KeltnerSource = 32,
  KeltnerUpper = 33,
  KeltnerLower = 34,
  /** ChaikinOscillator - ChaikinOscillator */
  ChaikinOscillator = 35,
  UNRECOGNIZED = -1,
}

export function tAOutputKeyFromJSON(object: any): TAOutputKey {
  switch (object) {
    case 0:
    case "UNKNOWN":
      return TAOutputKey.UNKNOWN;
    case 1:
    case "AROON_UP":
      return TAOutputKey.AROON_UP;
    case 2:
    case "AROON_DOWN":
      return TAOutputKey.AROON_DOWN;
    case 3:
    case "ADI_ADX":
      return TAOutputKey.ADI_ADX;
    case 4:
    case "ADI_PLUS":
      return TAOutputKey.ADI_PLUS;
    case 5:
    case "ADI_MINUS":
      return TAOutputKey.ADI_MINUS;
    case 6:
    case "UPPER_BAND":
      return TAOutputKey.UPPER_BAND;
    case 7:
    case "MIDDLE_BAND":
      return TAOutputKey.MIDDLE_BAND;
    case 8:
    case "LOWER_BAND":
      return TAOutputKey.LOWER_BAND;
    case 9:
    case "HMA":
      return TAOutputKey.HMA;
    case 10:
    case "TENKAN_SEN":
      return TAOutputKey.TENKAN_SEN;
    case 11:
    case "KIJUN_SEN":
      return TAOutputKey.KIJUN_SEN;
    case 12:
    case "SENKOU_SPAN_A":
      return TAOutputKey.SENKOU_SPAN_A;
    case 13:
    case "SENKOU_SPAN_B":
      return TAOutputKey.SENKOU_SPAN_B;
    case 14:
    case "MACD_OUTPUT":
      return TAOutputKey.MACD_OUTPUT;
    case 15:
    case "MACD_SIGNAL":
      return TAOutputKey.MACD_SIGNAL;
    case 16:
    case "MOMENTUM_OUTPUT":
      return TAOutputKey.MOMENTUM_OUTPUT;
    case 17:
    case "MOMENTUM_SIGNAL":
      return TAOutputKey.MOMENTUM_SIGNAL;
    case 18:
    case "RSI_OUTPUT":
      return TAOutputKey.RSI_OUTPUT;
    case 19:
    case "SO_F1":
      return TAOutputKey.SO_F1;
    case 20:
    case "SO_F2":
      return TAOutputKey.SO_F2;
    case 21:
    case "TRIX_OUTPUT":
      return TAOutputKey.TRIX_OUTPUT;
    case 22:
    case "TRIX_SIGNAL":
      return TAOutputKey.TRIX_SIGNAL;
    case 23:
    case "TRSI_OUTPUT":
      return TAOutputKey.TRSI_OUTPUT;
    case 24:
    case "TSI_OUTPUT":
      return TAOutputKey.TSI_OUTPUT;
    case 26:
    case "TSI_SIGNAL":
      return TAOutputKey.TSI_SIGNAL;
    case 27:
    case "MA_OUTPUT":
      return TAOutputKey.MA_OUTPUT;
    case 28:
    case "TA_OP_OUTPUT":
      return TAOutputKey.TA_OP_OUTPUT;
    case 29:
    case "DonchianLowest":
      return TAOutputKey.DonchianLowest;
    case 30:
    case "DonchianMiddle":
      return TAOutputKey.DonchianMiddle;
    case 31:
    case "DonchianHighest":
      return TAOutputKey.DonchianHighest;
    case 32:
    case "KeltnerSource":
      return TAOutputKey.KeltnerSource;
    case 33:
    case "KeltnerUpper":
      return TAOutputKey.KeltnerUpper;
    case 34:
    case "KeltnerLower":
      return TAOutputKey.KeltnerLower;
    case 35:
    case "ChaikinOscillator":
      return TAOutputKey.ChaikinOscillator;
    case -1:
    case "UNRECOGNIZED":
    default:
      return TAOutputKey.UNRECOGNIZED;
  }
}

export function tAOutputKeyToJSON(object: TAOutputKey): string {
  switch (object) {
    case TAOutputKey.UNKNOWN:
      return "UNKNOWN";
    case TAOutputKey.AROON_UP:
      return "AROON_UP";
    case TAOutputKey.AROON_DOWN:
      return "AROON_DOWN";
    case TAOutputKey.ADI_ADX:
      return "ADI_ADX";
    case TAOutputKey.ADI_PLUS:
      return "ADI_PLUS";
    case TAOutputKey.ADI_MINUS:
      return "ADI_MINUS";
    case TAOutputKey.UPPER_BAND:
      return "UPPER_BAND";
    case TAOutputKey.MIDDLE_BAND:
      return "MIDDLE_BAND";
    case TAOutputKey.LOWER_BAND:
      return "LOWER_BAND";
    case TAOutputKey.HMA:
      return "HMA";
    case TAOutputKey.TENKAN_SEN:
      return "TENKAN_SEN";
    case TAOutputKey.KIJUN_SEN:
      return "KIJUN_SEN";
    case TAOutputKey.SENKOU_SPAN_A:
      return "SENKOU_SPAN_A";
    case TAOutputKey.SENKOU_SPAN_B:
      return "SENKOU_SPAN_B";
    case TAOutputKey.MACD_OUTPUT:
      return "MACD_OUTPUT";
    case TAOutputKey.MACD_SIGNAL:
      return "MACD_SIGNAL";
    case TAOutputKey.MOMENTUM_OUTPUT:
      return "MOMENTUM_OUTPUT";
    case TAOutputKey.MOMENTUM_SIGNAL:
      return "MOMENTUM_SIGNAL";
    case TAOutputKey.RSI_OUTPUT:
      return "RSI_OUTPUT";
    case TAOutputKey.SO_F1:
      return "SO_F1";
    case TAOutputKey.SO_F2:
      return "SO_F2";
    case TAOutputKey.TRIX_OUTPUT:
      return "TRIX_OUTPUT";
    case TAOutputKey.TRIX_SIGNAL:
      return "TRIX_SIGNAL";
    case TAOutputKey.TRSI_OUTPUT:
      return "TRSI_OUTPUT";
    case TAOutputKey.TSI_OUTPUT:
      return "TSI_OUTPUT";
    case TAOutputKey.TSI_SIGNAL:
      return "TSI_SIGNAL";
    case TAOutputKey.MA_OUTPUT:
      return "MA_OUTPUT";
    case TAOutputKey.TA_OP_OUTPUT:
      return "TA_OP_OUTPUT";
    case TAOutputKey.DonchianLowest:
      return "DonchianLowest";
    case TAOutputKey.DonchianMiddle:
      return "DonchianMiddle";
    case TAOutputKey.DonchianHighest:
      return "DonchianHighest";
    case TAOutputKey.KeltnerSource:
      return "KeltnerSource";
    case TAOutputKey.KeltnerUpper:
      return "KeltnerUpper";
    case TAOutputKey.KeltnerLower:
      return "KeltnerLower";
    case TAOutputKey.ChaikinOscillator:
      return "ChaikinOscillator";
    case TAOutputKey.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}
