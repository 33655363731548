import { useAppContext } from "@client/src/context/AppContext";
import { IconButton } from "@client/src/sigma-ui/atoms/button";
import { Text } from "@client/src/sigma-ui/atoms/typography";
import { Instrument } from "@generated/common/basic-types_pb";
import { getInstrumentsLocalStorage } from "@utils/configStorageManager";
import { getInstrumentLabel } from "@utils/instrumentUtils";
import React, { useEffect, useState } from "react";
import { HiChevronDown } from "react-icons/hi";
import { useInstrumentContext } from "../../context/InstrumentContext";
import { getDefaultInstrument } from "../chart_controls/ChartControls.helpers";
import InstrumentDialog from "../instrument_dialog/InstrumentDialog.component";
import Label from "../label/Label.component";
import { Tooltip } from "../tooltip/Tooltip.component";
import {
  Container,
  InstrumentName,
  NameAndChange,
} from "./InstrumentHeader.style";

type Props = {
  onChangeInstrument: (instrument: string) => void;
};

const InstrumentHeader = ({ onChangeInstrument }: Props) => {
  const { instruments } = useInstrumentContext();
  const { hashcode: contextHashcode, setHashcode } = useAppContext();
  const [instrumentsVisible, setInstrumentsVisible] = useState(false);
  const [selectedInstrument, setSelectedInstrument] =
    useState<Instrument.AsObject | null>(null);
  const hashcode = contextHashcode;

  useEffect(() => {
    if (!instruments?.length) return;
    const localStorageInstrument = getInstrumentsLocalStorage();
    setSelectedInstrument(
      getDefaultInstrument(instruments, localStorageInstrument, hashcode)
    );
  }, [instruments, hashcode]);

  useEffect(() => {
    if (!selectedInstrument) return;
    onChangeInstrument(selectedInstrument?.hashcode);
  }, [selectedInstrument]);
  return (
    <Container>
      <InstrumentName>
        <NameAndChange>
          <InstrumentDialog
            onSelectInstrument={(instrument) => {
              onChangeInstrument(instrument.hashcode);
              setHashcode(instrument.hashcode);
              setSelectedInstrument(instrument);
              setInstrumentsVisible(false);
              window.location.reload();
            }}
            onClickOutside={() => setInstrumentsVisible(false)}
            visible={instrumentsVisible}
            instrumentList={instruments}
          />
          <Text $bold={true} $size={"lg"}>
            {selectedInstrument?.symbol}
          </Text>
          <Tooltip>
            <Tooltip.Item>
              <span>
                <IconButton
                  data-testid={"btn-pressable"}
                  icon={HiChevronDown}
                  onClick={() => setInstrumentsVisible(true)}
                />
              </span>
            </Tooltip.Item>
            <Tooltip.Content side="bottom" text="Change Instrument" />
          </Tooltip>
        </NameAndChange>
        <Text $size={"base"}>{selectedInstrument?.name}</Text>
      </InstrumentName>
      <div>
        <Label
          text={getInstrumentLabel(selectedInstrument?.instrumentClass)}
          variant="tertiary"
        />
      </div>
    </Container>
  );
};

export default InstrumentHeader;
