import { useAppContext } from "@client/src/context/AppContext";
import { useFetchChart } from "@client/src/hooks/useFetchChart";
import { getChartType } from "@client/src/utils/configStorageManager";
import React from "react";
import { Panel, PanelGroup, PanelResizeHandle } from "react-resizable-panels";
import Chart, { ChartProps } from "../chart/Chart.component";
import MacdChart from "../chart/MacdChart.component";
import RsiChart from "../chart/RsiChart.component";
import ChartControls from "../chart_controls/ChartControls.component";
import InstrumentHeader from "../instrument_header/InstrumentHeader.component";
import Navbar from "../navbar/Navbar.component";
import ScreeningResultTable, {
  ScreeningResult,
} from "../screening_result_table/ScreeningResultTable.component";

const TablePanel = ({
  screeningResults,
}: {
  screeningResults: ScreeningResult[];
}) => (
  <Panel defaultSizePercentage={25} order={4} style={{ overflow: "auto" }}>
    <ScreeningResultTable
      results={screeningResults}
      columnsToRender={["Name", "Type", "Price", "Last Match", "Count"]}
    />
  </Panel>
);

const PaasComponent = () => {
  const { setHashcode, setFrequency, screeningResults } = useAppContext();
  const chartData = useFetchChart();
  const [chartType, setChartType] = React.useState<string>(getChartType());

  const renderChart = (chartProps: ChartProps) => (
    <Chart {...chartProps} data-testid="chart" />
  );

  const navbarRef = React.useRef<HTMLDivElement>(null);

  const renderRsi = () => {
    if (!chartData.technicals?.results.find((result) => !!result.rsi))
      return null;
    return (
      <>
        <PanelResizeHandle
          style={{
            height: "3px",
            background: "#2a2a2a",
            cursor: "row-resize",
          }}
        />
        <Panel defaultSizePercentage={30} order={2}>
          <RsiChart chartData={chartData} />
        </Panel>
      </>
    );
  };

  const renderMacd = () => {
    if (!chartData.technicals?.results.find((result) => !!result.macd))
      return null;
    return (
      <>
        <PanelResizeHandle
          style={{
            height: "3px",
            background: "#2a2a2a",
            cursor: "row-resize",
          }}
        />
        <Panel defaultSizePercentage={30} order={3}>
          <MacdChart chartData={chartData} />
        </Panel>
      </>
    );
  };

  return (
    <div
      style={{
        height: "100vh",
      }}
    >
      <PanelGroup direction="vertical">
        <Navbar ref={navbarRef} />
        <PanelGroup direction="horizontal">
          <Panel>
            <PanelGroup direction="vertical">
              <InstrumentHeader onChangeInstrument={setHashcode} />
              <ChartControls
                onChangeFrequency={setFrequency}
                onChangeChartType={(chartType) => {
                  setChartType(chartType);
                  window.location.reload();
                }}
              />
              <Panel
                defaultSizePercentage={40}
                maxSizePercentage={90}
                order={1}
              >
                {renderChart({
                  chartType,
                  chartData,
                })}
              </Panel>
              {renderRsi()}
              {renderMacd()}
              <>
                <PanelResizeHandle
                  style={{
                    height: "10px",
                    background: "#2a2a2a",
                    cursor: "row-resize",
                  }}
                />
                {screeningResults.length > 0 && (
                  <TablePanel screeningResults={screeningResults} />
                )}
              </>
            </PanelGroup>
          </Panel>
        </PanelGroup>
      </PanelGroup>
    </div>
  );
};

export default PaasComponent;
