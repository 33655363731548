import React, { useRef } from "react";
import {
  Dialog,
  DialogContainer,
  DialogHeader,
  CloseButton,
  Title,
} from "../dialog/Dialog.style";
import { HiXMark } from "react-icons/hi2";
import { useOnClickOutside } from "@client/src/hooks/useOnClickOutside";
import { Tooltip } from "../tooltip/Tooltip.component";

type PaymentDialogProps = {
  title: string;
  onClickOutside: () => void;
  visible: boolean;
  children: React.ReactNode;
};

const PaymentDialog = ({
  title,
  onClickOutside,
  visible,
  children,
}: PaymentDialogProps) => {
  const containerRef = useRef(null);
  useOnClickOutside(containerRef, onClickOutside);

  if (!visible) return null;

  return (
    <DialogContainer>
      <Dialog ref={containerRef}>
        <DialogHeader>
          <Title>{title}</Title>
          <Tooltip>
            <Tooltip.Item>
              <CloseButton onClick={onClickOutside}>
                <HiXMark />
              </CloseButton>
            </Tooltip.Item>
            <Tooltip.Content text="Close" />
          </Tooltip>
        </DialogHeader>
        {children}
      </Dialog>
    </DialogContainer>
  );
};

export default PaymentDialog;
