import { capitalizeFirstLetter } from "@utils/instrumentUtils";
import React, { useEffect } from "react";
import { HiTag } from "react-icons/hi2";
// eslint-disable-next-line max-len
import { mapInstrumentClassToName } from "../../../instrument_dialog/instrumentClassMappings";
// eslint-disable-next-line max-len
import SelectionDropdown from "../../../selection_dropdown/SelectionDropdown.component";
import { ScreeningFilterData } from "../../ScreeningFilter";
import { typeScreeningFilter } from "./TypeScreeningFilter.helper";
import { TypeFilterProps } from "./TypeScreeningFilter.types";

export const TypeScreeningFilter = ({
  onFilterChange,
  instrumentList,
}: TypeFilterProps) => {
  const filter = typeScreeningFilter;
  const emptyTitle = "Asset Class";
  const [selectedValue, setSelectedValue] = React.useState("");
  const [values, setValues] = React.useState<ScreeningFilterData<string>[]>([]);

  useEffect(() => {
    const type = values.find((value) => value.value === selectedValue);
    if (
      !type ||
      selectedValue.toLowerCase().includes(emptyTitle.toLowerCase())
    ) {
      onFilterChange(instrumentList);
      return;
    }
    onFilterChange(filter.apply(instrumentList, type.id));
  }, [selectedValue]);

  useEffect(() => {
    const uniqueTypes = Array.from(
      new Set(instrumentList.map((instrument) => instrument.instrumentClass))
    );
    const mappedValues: ScreeningFilterData<string>[] = [...uniqueTypes]
      .filter((instrumentClass) => mapInstrumentClassToName(instrumentClass))
      .map((instrumentClass) => ({
        value: capitalizeFirstLetter(mapInstrumentClassToName(instrumentClass)),
        id: instrumentClass.toString(),
      }));
    mappedValues.unshift({
      value: emptyTitle,
      id: "instrument_type",
    });
    setValues(mappedValues);
    onFilterChange(instrumentList);
  }, [instrumentList]);

  return (
    <SelectionDropdown
      onSelect={(changedValue) => {
        setSelectedValue(changedValue);
      }}
      Icon={<HiTag />}
      items={values.map((value) => {
        return value.value === "Asset Class" ? "All" : value.value;
      })}
      defaultItem={values.length > 0 ? values[0].value : ""}
      dialogTitle={"Type"}
      customHeight={"300px"}
    />
  );
};
