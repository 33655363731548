import {
  getPatternLabel,
  isValidType,
  PatternIcons,
} from "@utils/pattern_utils";

export const getPatternName = (patternType: number) => {
  if (!isValidType(patternType)) return "Undefined pattern";
  return getPatternLabel(patternType);
};
export const getDefaultColour = () => "#ABABAB";
export const getPatternColor = (
  patternType: number,
  patternColours: string[]
) => {
  if (!isValidType(patternType)) return getDefaultColour();
  if (patternType >= patternColours.length) return getDefaultColour();
  return patternColours[patternType];
};

export const getPatternIcon = (patternType: number) => {
  if (!isValidType(patternType)) return null;
  return PatternIcons[patternType];
};

export const hasScoreAccess: (groups: string[]) => boolean = (
  groups: string[]
) => groups && groups.length > 0;
