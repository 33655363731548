import { textStyles } from "@utils/textStyles";
import styled, { DefaultTheme } from "styled-components";

type LabelProps = {
  theme: DefaultTheme;
  $variant: string;
};

const getTertiaryTheme = (theme: DefaultTheme) =>
  `background-color: ${theme.label.background.tertiary};`;

const getSuccessTheme = (theme: DefaultTheme) =>
  `background-color: ${theme.label.background.green};`;

const getWarningTheme = (theme: DefaultTheme) =>
  `background-color: ${theme.label.background.yellow};`;

const getErrorTheme = (theme: DefaultTheme) =>
  `background-color: ${theme.label.background.red};`;

const getBetaTheme = (theme: DefaultTheme) => `
      background: ${theme.label.background.beta};
      border-radius: 1rem;
      padding: 0.125rem 0.75rem;
      gap: 0.25rem;
    `;

const getSubscriptionTheme = (theme: DefaultTheme) => `
        background: ${theme.label.background.subscription};
        border-radius: 1rem;
        padding: 0.125rem 0.75rem;
        gap: 0.25rem;
      `;

const getDefaultTheme = (theme: DefaultTheme) =>
  `background-color: ${theme.label.background.default};`;

const getBackgroundColor = (variant: string, theme: DefaultTheme) => {
  const themes = {
    tertiary: getTertiaryTheme(theme),
    success: getSuccessTheme(theme),
    warning: getWarningTheme(theme),
    error: getErrorTheme(theme),
    beta: getBetaTheme(theme),
    subscription: getSubscriptionTheme(theme),
    default: getDefaultTheme(theme),
  };
  return themes[variant as keyof typeof themes];
};

const getTextColor = (variant: string, theme: DefaultTheme) => {
  const textColors = {
    tertiary: `
      color: ${theme.label.text.tertiary};
      `,
    success: `
      color: ${theme.label.text.green};
      `,
    warning: `
      color: ${theme.label.text.yellow};
      `,
    error: `
      color: ${theme.label.text.red};
      `,
    beta: `
      color: ${theme.label.text.beta};
      ${textStyles.textSmBold};
    `,
    subscription: `
      color: ${theme.label.text.subscription};
      ${textStyles.textSmBold};
    `,
    default: `color: ${theme.label.text.default};`,
  };

  return textColors[variant as keyof typeof textColors];
};

export const LabelContainer = styled.div.attrs<LabelProps>((props) => ({
  $variant: props.$variant,
}))`
  display: flex;
  padding: 0.125rem 0.25rem;
  justify-content: center;
  align-items: center;
  gap: 0.125rem;
  border-radius: 0.25rem;
  height: fit-content;
  width: fit-content;
  text-wrap: nowrap;
  ${(props) => getBackgroundColor(props.$variant, props.theme)};
`;

export const LabelIcon = styled.div<LabelProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1.25rem;
  height: 1.25rem;
  ${(props) => getTextColor(props.$variant, props.theme)}
`;

export const LabelText = styled.div<LabelProps>`
  ${textStyles.textSm};
  ${(props) => getTextColor(props.$variant, props.theme)}
`;
