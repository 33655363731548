import { PatternSearchResult } from "@generated/query/pattern_pb";
import { TAResponseV1 } from "@generated/ta/technical_analysis";
import { BaseChartState } from "../helpers/state";
import { MainChartState } from "./state";
import type { Candlestick } from "@generated/common/basic-types_pb";
import { transformTsIntervalToTime } from "@components/chart/Chart.helpers";
import {
  CandlestickData,
  ISeriesApi,
  LineData,
  PriceFormat,
  PriceFormatBuiltIn,
  PriceFormatCustom,
  Time,
  WhitespaceData,
} from "lightweight-charts";
import { getChartSyncHandler } from "../helpers/sync-handler";

export const createCandleSeriesItem = (candle: Candlestick.AsObject) => ({
  time: transformTsIntervalToTime(candle.tsInterval),
  open: parseFloat(candle.open),
  high: parseFloat(candle.high),
  low: parseFloat(candle.low),
  close: parseFloat(candle.close),
});

export const createLineSeriesItem = (candle: Candlestick.AsObject) => ({
  time: transformTsIntervalToTime(candle.tsInterval),
  value: parseFloat(candle.close),
});

const chartTypeToSeriesMap: Record<
  string,
  (
    candle: Candlestick.AsObject
  ) => CandlestickData<Time> | LineData<Time> | WhitespaceData<Time>
> = {
  candlestick: createCandleSeriesItem,
  line: createLineSeriesItem,
};

export const registerChartWithSeries = (state: MainChartState) => {
  getChartSyncHandler().register(state.lwChart, () => getChartSeries(state));
};

export const updateBaseSeries = (
  state: MainChartState,
  candles: Candlestick.AsObject[]
) => {
  if (!candles || !candles.length) return;

  state.candles = candles;

  const series = getChartSeries(state);
  series?.setData(state.candles.map(chartTypeToSeriesMap[state.chartType]));
};

export const hasDifferentPriceFormat = (
  state: PriceFormat,
  options: PriceFormat
) => JSON.stringify(state) !== JSON.stringify(options);

export const applyPriceFormatIfNeeded = (
  state: BaseChartState & {
    candles: Candlestick.AsObject[];
    baseSeries: ISeriesApi<"Candlestick" | "Line"> | null;
    patterns: PatternSearchResult.AsObject[];
    currentPatternSeries: ISeriesApi<"Line">[];
    chartType: string;
    technicals: TAResponseV1 | null;
    currentTechnicalMaSeries: ISeriesApi<"Line">[];
    currentVolumeSeries: ISeriesApi<"Histogram">[];
  },
  options: {
    wickDownColor: string;
    borderDownColor: string;
    priceFormat: PriceFormatBuiltIn | PriceFormatCustom;
    upColor: string;
    wickUpColor: string;
    borderUpColor: string;
    downColor: string;
  }
) => {
  if (
    state.baseSeries &&
    hasDifferentPriceFormat(
      state.baseSeries.options().priceFormat,
      options.priceFormat
    )
  )
    state.baseSeries.applyOptions(options);
};

export const getChartSeries = (state: MainChartState) => {
  const { candles } = state;
  const precision = getPrecisionForNumber(
    parseFloat(candles[candles.length - 1].close)
  );
  const minMove = Math.pow(10, -precision).toPrecision(precision - 1);
  const priceFormat = {
    type: "price",
    precision,
    minMove: parseFloat(minMove),
  } as PriceFormat;
  const options = {
    ...state.theme.chart.candles,
    priceFormat: priceFormat,
  };

  if (state.baseSeries) {
    applyPriceFormatIfNeeded(state, options);
    return state.baseSeries;
  }
  let series;
  if (state.chartType === "candlestick") {
    series = state.lwChart.addCandlestickSeries(options);
  } else {
    series = state.lwChart.addLineSeries(options);
  }
  state.baseSeries = series;
  return series;
};

export const getPrecisionForNumber = (num: number) => {
  if (num < 0.01) return 8;
  if (num < 0.1) return 5;
  if (num < 10) return 4;
  return 2;
};
