import { Button as SigmaButton } from "@client/src/sigma-ui/atoms/button";
import { baseStyle } from "@client/src/utils/textStyles";
import styled from "styled-components";

const Container = styled.div`
  z-index: 1000;
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  background-color: ${(props) =>
    props.theme.dialogFloatingBg};
  ${baseStyle};
`;
const Dialog = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: ${(props) => props.theme.dialogBg};
  border-radius: 5px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  @media (max-width: 768px) {
    width: 100%;
    height: 100%;
    padding: 0px;
    padding-top: 20px;
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Title = styled.h1`
  font-size: ${(props) => props.theme.titleFontSize};
  color: ${(props) => props.theme.text};
`;
const TermsBlock = styled.div`
  overflow: auto;
  height: 400px;
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  width: 100%;
  background-color: ${(props) => props.theme.termsBlock.backgroundColor};
  color: ${(props) => props.theme.termsTextColour};
  @media (max-width: 768px) {
    margin: auto;
    width: 90%;
    height: 100%;
  }
`;

const ButtonHolder = styled.div`
  align-self: flex-end;
  display: inline-flex;
  align-items: flex-end;
  flex-direction: column;
  gap: 1rem;
  @media (max-width: 768px) {
    display: flex;
    margin: auto;
    width: 90%;
    padding-bottom: 2rem;
  }
`;

const CheckboxHolder = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 1rem;
  align-items: center;
  @media (max-width: 768px) {
    justify-content: space-between;
    flex-direction: row-reverse;
  }
`;

const Button = styled(SigmaButton)`
  cursor: pointer;
  flex-shrink: 1;
  width: fit-content;
  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
  @media (max-width: 768px) {
    width: 100%;
  }
`;

const CheckboxLabel = styled.label`
  color: ${(props) => props.theme.termsTextColour};
`;

export const Checkbox = styled.input`
  height: 24px;
  width: 24px;
  border-radius: 4px;
`;

export {
  Container,
  CheckboxLabel,
  Dialog,
  Header,
  Title,
  TermsBlock,
  ButtonHolder,
  CheckboxHolder,
  Button,
};
