import { AppLogo, MobileAppLogo } from "@assets/Icons";
import { useAuth0 } from "@auth0/auth0-react";
import Avatar from "@components/avatar/Avatar.component";
import { useSubscriptions } from "@hooks/useSubscriptions";
import { isMobile } from "@utils/device";
import { toastStyles } from "@utils/toastStyles";
import React, { useEffect } from "react";
import { HiAcademicCap, HiMiniQuestionMarkCircle } from "react-icons/hi2";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { DefaultTheme, useTheme } from "styled-components";
import { useAlertContext } from "../../context/AlertContext";
import { useAppContext } from "../../context/AppContext";
import { useUserContext } from "../../context/user/UserContext";
import InboxAlertBell from "../inbox_alert_bell/InboxAlertBell.component";
import InboxSheet from "../inbox_sheet/InboxSheet.component";
import InboxSlideover from "../inbox_slideover/InboxSlideover.component";
import Label from "../label/Label.component";
// eslint-disable-next-line max-len
import { PurchaseError } from "../purchase_error_banner/PurchaseError.component";
import { Tooltip } from "../tooltip/Tooltip.component";
import {
  BellAndProfile,
  Container,
  HelpIcon,
  NavBarLink,
  NavBarLinkContainer,
  NavbarContainer,
  RightSide,
  Root,
  StyledLink,
  TooltipBody,
} from "./Navbar.styles";
import { HiSearch } from "react-icons/hi";

const shouldRenderSecretIcon = (groups: string[]) =>
  groups && (groups.includes("secret_icon") || groups.includes("test_role"));

const Navbar = React.forwardRef<HTMLDivElement>((props, ref) => {
  const theme = useTheme();
  const { groups } = useUserContext();
  const { user } = useAuth0();
  const navigate = useNavigate();
  const { shouldShowFailedBanner } = useSubscriptions();
  const { alerts, isInboxVisible, closeInbox, showInbox } = useAlertContext();
  const inboxCount = React.useMemo(
    () => alerts.filter((alert) => !alert.read).length,
    [JSON.stringify(alerts)]
  );

  useEffect(() => {
    shouldShowFailedBanner().then((show) => {
      if (show) {
        toast.error(<PurchaseError />, {
          style: toastStyles("error"),
          autoClose: false,
        });
      }
    });
  }, []);

  const renderAppLogo = (theme: DefaultTheme) => {
    if (theme.appLogo) {
      return (
        <img src={theme.appLogo} alt={"app logo"} style={{ height: "24px" }} />
      );
    } else {
      if (isMobile()) return <MobileAppLogo />;
      return <AppLogo />;
    }
  };

  const renderPageNav = () => {
    const isCurrentLink = window.location.pathname === "/screening";
    const navigate = useNavigate();
    const { screeningResults } = useAppContext();
    return (
      <NavBarLinkContainer>
        <NavBarLink
          $currentlink={isCurrentLink.toString()}
          onClick={() => navigate("/screening")}
        >
          <HiSearch />
          {screeningResults.length > 0
            ? "Back to Screening Results"
            : "Screening"}
        </NavBarLink>
      </NavBarLinkContainer>
    );
  };

  const renderBetaLabel = () => {
    return (
      <NavBarLinkContainer>
        <Label text={"Beta"} variant={"beta"} />
      </NavBarLinkContainer>
    );
  };

  const renderSecretIcon = () =>
    shouldRenderSecretIcon(groups) ? <HiAcademicCap /> : null;

  const renderAlertBell = () => {
    return <InboxAlertBell count={inboxCount} onClick={showInbox} />;
  };

  const renderHelpIcon = () => {
    return (
      <Tooltip>
        <Tooltip.Item>
          <HelpIcon
            onClick={() =>
              window.open("https://support.runleopard.ai/", "_blank")
            }
          >
            <HiMiniQuestionMarkCircle size={"20px"} />
          </HelpIcon>
        </Tooltip.Item>
        <Tooltip.Content align="center" text="Help" />
      </Tooltip>
    );
  };

  const renderInbox = () => {
    if (isMobile()) {
      return <InboxSheet isOpen={isInboxVisible} onClose={closeInbox} />;
    }
    return <InboxSlideover isOpen={isInboxVisible} onClose={closeInbox} />;
  };

  return (
    <Root>
      <NavbarContainer ref={ref}>
        <Container>
          <StyledLink
            href={`/${window.location.search}`}
            data-testid="app-logo"
          >
            {renderAppLogo(theme)}
          </StyledLink>
          {renderBetaLabel()}
          {renderPageNav()}
        </Container>
        <RightSide>
          {renderSecretIcon()}
          {renderHelpIcon()}
          <BellAndProfile>
            {renderAlertBell()}
            <Tooltip>
              <Tooltip.Item>
                <Container
                  data-testid="avatar"
                  onClick={() => navigate(`/profile${window.location.search}`)}
                >
                  <Avatar src={user?.picture} alt={"user avatar"} />
                </Container>
              </Tooltip.Item>
              <TooltipBody sideOffset={5}>
                <p className="subtext">{accountText(user?.sub ?? "")}</p>
                <p className="text">{user?.email}</p>
                <p className="text">{user?.nickname}</p>
              </TooltipBody>
            </Tooltip>
          </BellAndProfile>
        </RightSide>
        {renderInbox()}
      </NavbarContainer>
    </Root>
  );
});

const accountText = (id: string) => {
  if (id.startsWith("google")) return "Google Account";
  if (id.startsWith("auth0")) return "Email Account";

  return "Account";
};

export default Navbar;
