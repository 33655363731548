import { css } from "styled-components";

export const baseStyle = css`
  font-family: Poppins;
  font-style: normal;
  color: ${(props) => props.theme.text};
`;

export const textStyles = {
  text5xlBold: css`
    ${baseStyle};
    font-size: 3rem;
    font-weight: 700;
    line-height: 3.25rem;
  `,
  text5xl: css`
    ${baseStyle};
    font-size: 3rem;
    font-weight: 300;
    line-height: 3.25rem;
  `,
  text4xlBold: css`
    ${baseStyle};
    font-size: 2.25rem;
    font-weight: 600;
    line-height: 2.5rem;
  `,
  text4xl: css`
    ${baseStyle};
    font-size: 2.25rem;
    font-weight: 300;
    line-height: 2.5rem;
  `,
  text3xlBold: css`
    ${baseStyle};
    font-size: 1.875rem;
    font-weight: 600;
    line-height: 2.25rem;
  `,
  text3xl: css`
    ${baseStyle};
    font-size: 1.875rem;
    font-weight: 300;
    line-height: 2.25rem;
  `,
  text2xlBold: css`
    ${baseStyle};
    font-size: 1.5rem;
    font-weight: 600;
    line-height: 2rem;
  `,
  text2xl: css`
    ${baseStyle};
    font-size: 1.5rem;
    font-weight: 300;
    line-height: 2rem;
  `,
  textXlBold: css`
    ${baseStyle};
    font-size: 1.25rem;
    font-weight: 600;
    line-height: 1.8rem;
  `,
  textLgBold: css`
    ${baseStyle};
    font-size: 1.1rem;
    font-weight: 600;
    line-height: 1.8rem;
  `,
  textLg: css`
    ${baseStyle};
    font-size: 1.1rem;
    font-weight: 300;
    line-height: 1.8rem;
  `,
  textBaseBold: css`
    ${baseStyle};
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 1rem;
  `,
  textBase: css`
    ${baseStyle};
    font-size: 0.875rem;
    font-weight: 300;
    line-height: 1rem;
  `,
  textSmBold: css`
    ${baseStyle};
    font-size: 0.75rem;
    font-weight: 500;
    line-height: 1rem;
  `,
  textSm: css`
    ${baseStyle};
    font-size: 0.75rem;
    font-weight: 300;
    line-height: 1rem;
  `,
  textXsBold: css`
    ${baseStyle};
    font-size: 0.625rem;
    font-weight: 500;
    line-height: 0.75rem;
  `,
  textXs: css`
    ${baseStyle};
    font-size: 0.625rem;
    font-weight: 300;
    line-height: 0.75rem;
  `,
  alertElipse: css`
    ${baseStyle};
    font-size: 0.625rem;
    font-weight: 300;
    line-height: 0;
  `,
};
