import React, { useEffect } from "react";
import { useAlertContext } from "../../context/AlertContext";
import { Container } from "./ManageAlert.styles";
import {
  ManageAlertItem,
} from "./manage_alert_items/ManageAlertItem.component";
import { EmptyState } from "../empty_state/EmptyState.component";
import { HiBellSnooze } from "react-icons/hi2";

const ManageAlert = () => {
  const { userAlerts, fetchUserAlerts } = useAlertContext();
  useEffect(() => {
    fetchUserAlerts();
  }, []);

  const ManageAlertEmptyState = (
    <EmptyState
      icon={HiBellSnooze}
      title={"Nothing to manage yet"}
      description={
        "Alerts give you immediate feedback about changes to Instruments"
      }
    />
  );

  if (!userAlerts) return ManageAlertEmptyState;
  if (userAlerts.length === 0) return ManageAlertEmptyState;
  return (
    <Container data-testid="manage-alert-item">
      {userAlerts.map((data, index) => (
        <ManageAlertItem key={index} data={data} />
      ))}
    </Container>
  );
};

export default ManageAlert;
