import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  gap: 0.5rem;
  background-color: ${(props) => props.theme.secondaryBackground};
  position: fixed;
  bottom: 2rem;
  right: 2.5rem;
  border-radius: 0.5rem;
  padding: 0.5rem 1rem 0.5rem 0.5rem;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.1);
  z-index: 100000;
`;

export const ButtonContainer = styled.button`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem;
  border-radius: 0.5rem;
  border-style: none;
  color: ${(props) => props.theme.text};
  background-color: ${(props) => props.theme.secondaryBackground};

  &:hover {
    background-color: ${(props) => props.theme.instrumentButtonBg};
  }
`;
