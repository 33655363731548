import {
  DEFAULT_PATTERN_SCORE_THRESHOLD,
  GLOBAL_PATTERN_TYPE,
  PatternConfigItem,
} from "@common/types";
// eslint-disable-next-line max-len
import { Item } from "@components/chart_type_selector/ChartTypeSelector.helpers";
import { Instrument } from "@generated/common/basic-types_pb";
import {
  hardcodedItems as frequencies,
  Item as frequencyType,
} from "../components/frequency_selector/FrequencySelection.helpers";

const PATTERN_CONFIG_STORAGE_KEY = "patternConfig";
const GLOBAL_PATTERN_CONFIG_STORAGE_KEY = "globalPatternConfig";
const CHART_TYPE = "chartType";
const CHART_FREQUENCY = "chartFrequency";
const INSTRUMENT_KEY = "instrument";
const CANDLESTICK_KEY = "candlestick";

const setPatternConfig = (patternConfig: PatternConfigItem[]) => {
  localStorage.setItem(
    PATTERN_CONFIG_STORAGE_KEY,
    JSON.stringify(patternConfig)
  );
};

const getPatternConfig = () => {
  const patternConfig = localStorage.getItem(PATTERN_CONFIG_STORAGE_KEY);
  if (patternConfig) {
    return JSON.parse(patternConfig);
  }
  return [];
};

const updateStoragePatternConfigItem = (item: PatternConfigItem) => {
  const patternConfig = getPatternConfig();
  const index = patternConfig.findIndex(
    (i: { type: number }) => i.type === item.type
  );
  if (index !== -1) {
    patternConfig[index] = item;
    setPatternConfig(patternConfig);
  }
};

const setGlobalPatternConfig = (patternConfig: PatternConfigItem) => {
  localStorage.setItem(
    GLOBAL_PATTERN_CONFIG_STORAGE_KEY,
    JSON.stringify(patternConfig)
  );
};

const getGlobalPatternConfig = (): PatternConfigItem => {
  const defaultGlobalConfig = {
    threshold: DEFAULT_PATTERN_SCORE_THRESHOLD,
    enabled: true,
    type: GLOBAL_PATTERN_TYPE,
  };
  const patternConfig = localStorage.getItem(GLOBAL_PATTERN_CONFIG_STORAGE_KEY);
  if (patternConfig) {
    return JSON.parse(patternConfig);
  }
  return defaultGlobalConfig;
};
const getChartFrequency = () => {
  const localItem = localStorage.getItem(CHART_FREQUENCY) as string;
  const frequencyExists = JSON.parse(localItem)?.value;
  let frequencyData = frequencies[0].value;
  if (frequencyExists) {
    frequencyData = frequencyExists;
  }
  return frequencyData;
};
const setChartFrequency = (selectedFrequency: frequencyType) => {
  localStorage.setItem(CHART_FREQUENCY, JSON.stringify(selectedFrequency));
};
const getFrequency = () => {
  const localItem = localStorage.getItem(CHART_FREQUENCY) as string;
  return JSON.parse(localItem)?.name;
};
const setChartType = (selectedChartType: Item) => {
  localStorage.setItem(CHART_TYPE, JSON.stringify(selectedChartType));
};
const getChartType = () => {
  const localItem = localStorage.getItem(CHART_TYPE) as string;
  const chartTypeExists = JSON.parse(localItem)?.chartType;
  let chartTypeData = CANDLESTICK_KEY;
  if (chartTypeExists) {
    chartTypeData = chartTypeExists;
  }
  return chartTypeData;
};
const getLocalChartName = () => {
  const localItem = localStorage.getItem(CHART_TYPE) as string;
  return JSON.parse(localItem)?.name;
};
const setInstrumentsLocalStorage = (instrumentData: Instrument.AsObject) => {
  localStorage.setItem(INSTRUMENT_KEY, JSON.stringify(instrumentData));
};
const getInstrumentsLocalStorage = (): Instrument.AsObject | undefined => {
  const item = localStorage.getItem(INSTRUMENT_KEY);
  if (item) {
    return JSON.parse(item as string);
  }
  return undefined;
};

const clearUserConfig = () => {
  localStorage.removeItem(CHART_TYPE);
  localStorage.removeItem(CHART_FREQUENCY);
  localStorage.removeItem(INSTRUMENT_KEY);
  localStorage.removeItem(GLOBAL_PATTERN_CONFIG_STORAGE_KEY);
  localStorage.removeItem(PATTERN_CONFIG_STORAGE_KEY);
  localStorage.removeItem(CANDLESTICK_KEY);
};

export {
  setPatternConfig,
  getPatternConfig,
  updateStoragePatternConfigItem,
  setGlobalPatternConfig,
  getGlobalPatternConfig,
  getChartFrequency,
  getChartType,
  getLocalChartName,
  setChartType,
  setChartFrequency,
  getFrequency,
  setInstrumentsLocalStorage,
  getInstrumentsLocalStorage,
  clearUserConfig,
};
