import { SigmaUIDefaultTheme, createTheme } from "./base";

export const SigmaUILightTheme =
  createTheme<SigmaUIDefaultTheme>(({ colors }) => ({
    background: colors.pallets["sigma-grey"][100],
    text: colors.white,
    subtext: colors.pallets["sigma-grey"][400],
    sigmaGrey: colors.pallets["sigma-grey"],
    primary: {
      foreground: colors.white,
      background: colors.pallets["sigma-grey"][900],
      hoverForeground: colors.black + "99",
      hoverBackground: colors.black + "55",
    },
    error: {
      foreground: "#f00",
    },
    secondary: {
      foreground: "#131313",
      background: "#ECECEC",
      hoverForeground:
        colors.pallets["sigma-grey"][900] + "99",
      hoverBackground:
        colors.pallets["sigma-grey"][900] + "99",
    },
    tertiary: {
      foreground: "#823210",
      background: "#FFE0D3",
    },
    modal: {
      overlay: {
        background: "#13131366",
      },
      background: "#1b1b1b",
      boxShadow: `rgba(14, 18, 22, 0.35) 0px 10px 38px -10px,
        rgba(14, 18, 22, 0.2) 0px 10px 20px -15px`,
    },
  }));
