import {
  HiGreaterThan,
  HiGreaterThanEqual,
  HiLessThan,
  HiLessThanEqual,
  HiEqual,
} from "@client/src/assets/Icons";
import React from "react";
import {
  Container,
  ComparatorContainer,
} from "./ConditionBuilderComparator.style";
import ConditionBuilderBlockRenderer from
  "../condition_builder_block_renderer/ConditionBuilderBlockRenderer.component";
import { ConditionBuilderBlock } from
  "@client/src/context/ConditionBuilderContext";

const ConditionBuilderComparator = ({
  type,
  left,
  right,
  onClick,
}: {
  type: string,
  left: ConditionBuilderBlock,
  right: ConditionBuilderBlock,
  onClick: () => void
}) => {
  const renderGreaterThan = () => {
    if (type === "gt") {
      return <HiGreaterThan />;
    }
  };

  const renderGreaterThanOrEqual = () => {
    if (type === "gte") {
      return <HiGreaterThanEqual />;
    }
  };

  const renderLessThan = () => {
    if (type === "lt") {
      return <HiLessThan />;
    }
  };

  const renderLessThanOrEqual = () => {
    if (type === "lte") {
      return <HiLessThanEqual />;
    }
  };

  const renderEqual = () => {
    if (type === "eq") {
      return <HiEqual />;
    }
  };

  return (
    <Container>
      <ConditionBuilderBlockRenderer block={left} />
      <ComparatorContainer onClick={() => onClick()}>
        {renderGreaterThan()}
        {renderGreaterThanOrEqual()}
        {renderLessThan()}
        {renderLessThanOrEqual()}
        {renderEqual()}
      </ComparatorContainer>
      <ConditionBuilderBlockRenderer block={right} />
    </Container>
  );
};

export default ConditionBuilderComparator;
