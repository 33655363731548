import { useAuth0 } from "@auth0/auth0-react";
import { AlertInfo, AlertRecord } from "@common/types";
import { getSeenAlertIds, putAlertIds } from "@utils/alertStorageManager";
import React, { useState, useEffect } from "react";
import AlertToast from "../components/alert_toast/AlertToast.component";
import { toast } from "react-toastify";
import { useAlertContext } from "../context/AlertContext";
import { poll } from "@utils/poll";
import { toastStyles } from "@utils/toastStyles";
import {
  usePreferenceDispatch,
  usePreferences,
} from "../context/app/preference";

const INBOX_BASE_URL = "/api/inbox";
const ALERTS_BASE_URL = "/api/alerts";

export const useAlerts = (openManageInboxTab?: () => void) => {
  const [alerts, setAlerts] = useState<AlertRecord[]>([]);
  const [userAlerts, setUserAlerts] = useState<AlertRecord[]>([]);
  const { getAccessTokenSilently } = useAuth0();
  const { fetchUserAlerts } = useAlertContext();

  const { isWebPrefered } = usePreferences();
  const preferenceDispatch = usePreferenceDispatch();

  useEffect(() => {
    if (!localStorage.getItem("preferencesChecked")) {
      getAccessTokenSilently().then((token: string) => {
        fetch("/api/alerts/preferences", {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        })
          .then((res) => res.json())
          .then((data) => {
            if (data?.alertChannels) {
              const enabledChannels = data.alertChannels;
              preferenceDispatch({
                type: "set-alert-preferences",
                data: enabledChannels,
              });
            }
            localStorage.setItem("preferencesChecked", "true");
          });
      });
    }
  }, [preferenceDispatch]);

  const getInbox = () => {
    try {
      getAccessTokenSilently()
        .then((token) => {
          poll(
            INBOX_BASE_URL,
            {
              method: "GET",
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
            },
            (data) => {
              if (!data) return [];
              if ((data as { error: { message: string } }).error) return [];
              const alertRecordData: AlertRecord[] = data as AlertRecord[];
              setAlerts(alertRecordData);
            },
            5000
          );
        })
        .catch((err) => console.error(err));
    } catch (e) {
      console.error(e);
      return [];
    }
  };

  React.useEffect(() => {
    if (isWebPrefered) {
      toastIfNeeded(alerts);
    }
  }, [alerts, isWebPrefered]);

  const getUserAlerts = () => {
    try {
      getAccessTokenSilently()
        .then((token) => {
          fetch(`${ALERTS_BASE_URL}/user`, {
            method: "GET",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          })
            .then((res) => res.json())
            .then((data) => {
              if (!data) return [];
              if ((data as { error: { message: string } }).error) return [];
              const alertRecordData: AlertRecord[] = data as AlertRecord[];
              setUserAlerts(alertRecordData);
            })
            .catch((err) => {
              console.error(err);
            });
        })
        .catch((err) => console.error(err));
    } catch (e) {
      console.error(e);
    }
  };

  const isToastFiredNow = (alertRecord: AlertRecord) => {
    if (!alertRecord.firedAt) return false;
    const firedAt = new Date(alertRecord.firedAt);
    const now = new Date();
    const diff = now.getTime() - firedAt.getTime();
    const diffInMinutes = Math.round(diff / 60000);
    return diffInMinutes <= 1;
  };

  const isAlertAlreadyFired = (
    seenAlerts: string[],
    alertRecord: AlertRecord
  ) => {
    const id = alertRecord?.eventId ?? "";
    return seenAlerts.includes(id);
  };

  const shouldToastNow = (
    seenAlerts: string[],
    alertRecord: AlertRecord,
    toastNow: boolean
  ) =>
    !isAlertAlreadyFired(seenAlerts, alertRecord) &&
    !alertRecord.read &&
    toastNow;

  const shouldToast = async (
    seenAlerts: string[],
    alertRecord: AlertRecord
  ) => {
    const toastNow = isToastFiredNow(alertRecord);
    let notify = false;

    if (shouldToastNow(seenAlerts, alertRecord, toastNow)) {
      notify = await gotCredits();
    }
    return notify;
  };

  const gotCredits = async () => {
    const token = await getAccessTokenSilently();
    const response = await fetch("/api/credits", {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const data = await response.json();
    return data.availableUnits > 0 || data.availableUnits === -1;
  };

  const toastIfNeeded = (alertRecordData: AlertRecord[]) => {
    const seenAlerts = getSeenAlertIds();
    alertRecordData.forEach(async (alertRecord) => {
      if (await shouldToast(seenAlerts, alertRecord)) {
        toastAlert(alertRecord);
      }
    });
    putAlertIds(alertRecordData);
  };

  const toastAlert = (alert: AlertRecord) =>
    toast(
      <AlertToast alertData={alert} openManageInboxTab={openManageInboxTab} />
    );

  const markAsRead = (eventId: string, responseCallback?: () => void) => {
    try {
      getAccessTokenSilently()
        .then((token) => {
          fetch(`${INBOX_BASE_URL}/${eventId}/read`, {
            method: "PATCH",
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
            .then(() => {
              responseCallback && responseCallback();
            })
            .catch((err) => console.error(err));
        })
        .catch((err) => console.error(err));
    } catch (e) {
      console.error(e);
    }
  };

  const markBatchAsRead = (
    eventIds: string[],
    responseCallback?: () => void
  ) => {
    try {
      if (eventIds.length === 0) return;
      getAccessTokenSilently()
        .then((token) => {
          fetch(`${INBOX_BASE_URL}/read`, {
            method: "PATCH",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          })
            .then(() => {
              responseCallback && responseCallback();
            })
            .catch((err) => console.error(err));
        })
        .catch((err) => console.error(err));
    } catch (e) {
      console.error(e);
    }
  };

  const markAsUnread = (eventId: string) => {
    try {
      getAccessTokenSilently()
        .then((token) => {
          fetch(`${INBOX_BASE_URL}/${eventId}/unread`, {
            method: "PATCH",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          })
            .then((res) => res.json())
            .then((data) => {
              if (!data) return;
              return data;
            })
            .catch((err) => console.error(err));
        })
        .catch((err) => console.error(err));
    } catch (e) {
      console.error(e);
    }
  };

  const markAsDelete = (alertId: string) => {
    try {
      getAccessTokenSilently()
        .then((token) => {
          fetch(`${INBOX_BASE_URL}/${alertId}`, {
            method: "DELETE",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          })
            .then((res) => {
              if (res.status === 200) {
                toast.success("Alert deleted successfully", {
                  style: toastStyles("success"),
                });
                fetchUserAlerts();
              } else {
                toast.error(
                  "Failed to delete the alert. Please try again later.",
                  {
                    style: toastStyles("error"),
                  }
                );
              }
            })
            .catch((err) => {
              console.error(err);
            });
        })
        .catch((err) => {
          console.error(err);
        });
    } catch (e) {
      console.error(e);
    }
  };

  const createAlert = (alert: AlertInfo) => {
    try {
      getAccessTokenSilently()
        .then((token) => {
          fetch(`${ALERTS_BASE_URL}/create`, {
            method: "POST",
            body: JSON.stringify(alert),
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          })
            .then((response) => {
              if (response.status === 200) {
                fetchUserAlerts();
                toast.success("Alert created successfully", {
                  style: toastStyles("success"),
                });
              } else if (response.status === 409) {
                toast.error("Alert for this configuration already exists", {
                  style: toastStyles("error"),
                });
              } else {
                toast.error(
                  `Oops. it look like something went wrong. 
                   Please try again, or come back later`,
                  {
                    style: toastStyles("error"),
                  }
                );
              }
            })
            .catch((err) => console.error(err));
        })
        .catch((err) => console.error(err));
    } catch (e) {
      console.error(e);
    }
  };

  const getAlertStatus = async () => {
    return getAccessTokenSilently().then((token: string) => {
      return fetch("/api/alerts/preferences", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
        .then((res) => res.json())
        .then((data) => {
          return {
            notificationsEnabled: data.notificationsEnabled as boolean,
          };
        })
        .catch(() => {
          toast.error("Error fetching alert status", {
            style: toastStyles("error"),
          });
          return null;
        });
    });
  };

  const isAlertCreated = (
    currentAlerts: AlertRecord[],
    hashcode: string,
    frequency: string,
    localThreshold: number,
    patternType: number
  ) => {
    const selectedAlertInfo = {
      patternType: patternType,
      instrument: hashcode,
      frequency: parseInt(frequency),
      patternConfidence: localThreshold,
    };
    const exists = currentAlerts
      .map((alert) => alert.alertInfo)
      .find((alertInfo) => {
        return isConfigForAlert(selectedAlertInfo, alertInfo);
      });
    return !!exists;
  };

  const isConfigForAlert = (
    selectedAlertInfo: AlertInfo,
    alertInfo: AlertInfo
  ) => JSON.stringify(selectedAlertInfo) === JSON.stringify(alertInfo);

  return {
    getInbox,
    markAsRead,
    markAsUnread,
    markAsDelete,
    createAlert,
    getUserAlerts,
    alerts,
    userAlerts,
    shouldToast,
    shouldToastNow,
    isToastFiredNow,
    markBatchAsRead,
    isAlertCreated,
    getAlertStatus,
  };
};
