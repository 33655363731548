import React, { useCallback, useEffect } from "react";
import Button from "../button/Button.component";
import { HiMiniPause, HiMiniPlay } from "react-icons/hi2";
import { useProfileContext } from "@client/src/context/ProfileContext";
import { useAlertContext } from "@client/src/context/AlertContext";

const PauseAlertToggle = () => {
  const { pauseOrResumeAlerts } = useProfileContext();
  const { alertStatus, setAlertStatus, fetchAlertStatus } = useAlertContext();

  useEffect(() => {
    fetchAlertStatus();
  }, [fetchAlertStatus]);
  const manageAlertToggleChange = useCallback(async () => {
    await pauseOrResumeAlerts(alertStatus);
    setAlertStatus(!alertStatus);
  }, [alertStatus]);

  const text = alertStatus ? "Pause" : "Run";
  const Icon = alertStatus ? HiMiniPause : HiMiniPlay;

  return (
    <Button
      onClick={manageAlertToggleChange}
      text={text}
      size="xs"
      type="primary"
      leftIcon={<Icon />}
    />
  );
};

export default PauseAlertToggle;
