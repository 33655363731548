import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
`;

export const Parameter = styled.input`
  width: 3rem;
  padding: 0.5rem;
  background: #131313;
  color: #8B8B8B;
  border: none;
`;
