import React from "react";
import styled from "styled-components";
import * as AvatarPrimitive from "@radix-ui/react-avatar";
import { HiUser } from "react-icons/hi2";
import { useUserContext } from "@client/src/context/user/UserContext";

const AvatarImage = styled(AvatarPrimitive.AvatarImage)`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: inherit;
`;

const SubscriptionRing = styled.div<{ $hasActiveSubscription: boolean }>`
  display: hidden;
  ${props => props.$hasActiveSubscription && `
    display: show;
    border-radius: 100%;
    background-image: ${props.theme.label.background.subscription};
    background-clip: padding-box;
    padding: 2px;
  `}
`;

const AvatarRoot = styled(AvatarPrimitive.Root)`
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
  overflow: hidden;
  user-select: none;
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 100%;

  @media (max-width: 768px) {
    width: 2rem;
    height: 2rem;
  }
`;

const AvatarFallback = styled(AvatarPrimitive.AvatarFallback)`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  font-size: 1.5rem;
  line-height: 1;
  font-weight: 500;
  color: #1b1b1b;
`;

interface AvatarProps extends React.ComponentProps<typeof AvatarImage> {
  fallbackToInitials?: string;
}

// export default Avatar;
const Avatar = React.forwardRef<typeof AvatarImage, AvatarProps>(
  ({ fallbackToInitials, ...props }, ref) => {
    const { isSubscribed } = useUserContext();

    return (
      <SubscriptionRing $hasActiveSubscription={isSubscribed}>
        <AvatarRoot>
          {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
          {/* @ts-ignore */}
          <AvatarImage {...props} ref={ref} />
          {fallbackToInitials ? (
            <AvatarFallback>
              {extractInitials(fallbackToInitials)}
            </AvatarFallback>
          ) : (
            <AvatarFallback>
              <HiUser color="#131313" />
            </AvatarFallback>
          )}
        </AvatarRoot>
      </SubscriptionRing>
    );
  }
);

export const extractInitials = (name: string) => {
  const n = name.trim();
  const names = n.split(/\s+/g);

  const nameCount = names.length;

  if (nameCount === 0) return null;
  if (nameCount === 1) return names[0]?.substring(0, 1);

  return String(names[0]?.charAt(0)) + String(names[nameCount - 1]?.charAt(0));
};

export default Avatar;
