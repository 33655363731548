import { AlertRecord } from "@common/types";

const LOCAL_STORAGE_KEY = "seenAlerts";
const putAlertIds = (alerts: AlertRecord[]) => {
  localStorage.setItem(
    LOCAL_STORAGE_KEY,
    JSON.stringify(alerts.map((a) => a.eventId))
  );
};

const getSeenAlertIds = (): string[] => {
  const seenAlerts = localStorage.getItem(LOCAL_STORAGE_KEY);
  if (!seenAlerts) {
    return [];
  }
  return JSON.parse(seenAlerts);
};

export { putAlertIds, getSeenAlertIds };
