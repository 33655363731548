export const poll = async (
  url: string,
  params: RequestInit,
  callback: (data: unknown) => void,
  timeout: number
) => {
  await pollUntil(url, params, callback, timeout, null);
};

export const pollUntil = async (
  url: string,
  params: RequestInit,
  callback: (data: unknown) => void,
  timeout: number,
  condition: ((data: unknown) => boolean) | null
) => {
  const now = new Date();
  const response = await fetch(url, params);
  const data = await response.json();
  if (response.status === 200) {
    const shouldContinue = resolve(condition, data, callback);
    if (shouldContinue)
      await continuePolling(now, timeout, url, params, callback, condition);
  }
};

const continuePolling = async (
  now: Date,
  timeout: number,
  url: string,
  params: RequestInit,
  callback: (data: unknown) => void,
  condition: null | ((data: unknown) => boolean)
) => {
  const successTime = new Date();
  const timeDiff = successTime.getTime() - now.getTime();
  if (timeDiff < timeout) {
    setTimeout(() => {
      pollUntil(url, params, callback, timeout, condition);
    }, timeout - timeDiff);
  } else {
    await pollUntil(url, params, callback, timeout, condition);
  }
};

const resolve = (
  condition: ((data: unknown) => boolean) | null,
  data: unknown,
  callback: (data: unknown) => void
) => {
  if (condition) {
    if (condition(data)) {
      callback(data);
      return false;
    } else {
      return true;
    }
  } else {
    callback(data);
    return true;
  }
};
