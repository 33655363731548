import EventEmitter from "events";
import React, { useId, useRef } from "react";

const useConstant = <T>(dataGenToRef: () => T) => {
  const ref = useRef(dataGenToRef());
  return ref.current;
};

const usePooling = <
  PoolableFetch extends (p: {
    signal: AbortSignal;
  }) => Promise<unknown>,
  TOutput extends Awaited<ReturnType<PoolableFetch>>,
>(
    fetch: PoolableFetch,
    options: {
      pollIntervalInMs: number;
      id?: string;
    },
    deps?: Readonly<Array<unknown>>,
  ) => {
  const dependencies = React.useMemo(
    () => ([options] as unknown[]).concat(deps ?? []),
    deps ?? [],
  );

  // const controller = React.useRef(new AbortController());

  const [data, set] = React.useState<TOutput | undefined>(
    undefined,
  );

  const eventEmitter = useConstant(
    () => new EventEmitter(),
  );

  const uniqueFetcherId = useId();

  const {
    $EVT_STREAM,
    $EVT_NEXT_FETCH_STREAM,
    $EVT_KILL_SIGNAL,
  } = React.useMemo(() => {
    const eventTagId = options.id ?? uniqueFetcherId;
    return {
      $EVT_STREAM: `stream-data-${eventTagId}`,
      $EVT_NEXT_FETCH_STREAM: `next-data-fetch-${eventTagId}`,
      $EVT_KILL_SIGNAL: `kill-fetch-${eventTagId}`,
    };
  }, [uniqueFetcherId]);

  const cancel = React.useCallback(() => {
    eventEmitter.emit($EVT_KILL_SIGNAL);
  }, [dependencies, eventEmitter]);

  React.useLayoutEffect(() => {
    // needed to terminate request on dependency change
    const controller = new AbortController();

    // event handelr to update next
    eventEmitter.addListener(
      $EVT_STREAM,
      ({
        data,
        lastfetch,
        signal,
      }: {
        data: TOutput;
        lastfetch: number;
        signal: AbortSignal;
      }) => {
        set(data);
        const timeDiff = new Date().getTime() - lastfetch;

        if (options.pollIntervalInMs > timeDiff) {
          const timeoutId = setTimeout(() => {
            eventEmitter.emit($EVT_NEXT_FETCH_STREAM, {
              signal,
            });
            clearTimeout(timeoutId);
          }, options.pollIntervalInMs - timeDiff);
        } else {
          eventEmitter.emit($EVT_NEXT_FETCH_STREAM, {
            signal,
          });
        }
      },
    );

    // even handler for next fetch
    eventEmitter.addListener(
      $EVT_NEXT_FETCH_STREAM,
      ({ signal }) => {
        const lastfetch = Date.now();
        fetch({ signal: signal })
          .then((result) =>
            eventEmitter.emit($EVT_STREAM, {
              data: result,
              lastfetch,
              signal,
            }),
          ).catch(err => console.warn(err));
      },
    );

    // once when there are dependency change
    eventEmitter.emit($EVT_NEXT_FETCH_STREAM, {
      signal: controller.signal,
    });

    // when killing the signal
    eventEmitter.once($EVT_KILL_SIGNAL, () => {
      controller.abort();
      eventEmitter.removeAllListeners();
    });

    return () => {
      // sends even to kill signal
      eventEmitter.emit($EVT_KILL_SIGNAL);
      eventEmitter.removeAllListeners($EVT_STREAM);
      eventEmitter.removeAllListeners(
        $EVT_NEXT_FETCH_STREAM,
      );
    };
  }, [dependencies]);

  return { data, cancel };
};

export default usePooling;
