import React, { useCallback } from "react";
import { Container, Input, Label } from "./InputNumeric.style";

type Props = {
  unit?: string;
  onChange?: (value: string) => void;
  defaultValue?: number;
  value?: number;
  inputStyle?: React.CSSProperties;
  disabled?: boolean;
  onClick?: () => void;
};

const InputUnit = (unit: string) => {
  return <Label>{unit}</Label>;
};
const InputNumeric = ({
  unit,
  onChange,
  defaultValue,
  value,
  inputStyle,
  disabled,
  onClick,
}: Props) => {
  const onChangeValue = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (onChange) onChange(e.target.value);
    },
    [onChange]
  );
  return (
    <Container onClick={onClick}>
      <Input
        data-testid={"input-numeric"}
        onChange={onChangeValue}
        style={inputStyle}
        defaultValue={defaultValue}
        value={value}
        type="number"
        min={0}
        max={100}
        disabled={disabled}
      />
      {unit && InputUnit(unit)}
    </Container>
  );
};
export default InputNumeric;
