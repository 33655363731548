import styled, { css } from "styled-components";
import {
  TEXT_SIZE_STYLES,
  TEXT_SIZE_WEIGHT_VALUES,
  TFontSize,
  TFontWeight,
  baseFontStyle,
} from "./typography.styles";

const resolveFontWeightFromSize = ({
  weight,
  size,
  bold,
}: {
  bold: boolean;
  weight?: TFontWeight;
  size: TFontSize;
}) => {
  if (!bold) {
    return css`
      font-weight: ${weight ?? 300};
    `;
  }

  return resolveFontWeightFromSizeOnly({ weight, size });
};

const resolveFontWeightFromSizeOnly = (p: {
  weight?: TFontWeight;
  size: TFontSize;
}) => {
  const selectedWeightStyle = TEXT_SIZE_WEIGHT_VALUES[p.size];
  if (selectedWeightStyle) {
    return css`
      font-weight: ${selectedWeightStyle};
    `;
  } else {
    return css`
      font-weight: bold;
    `;
  }
};

export const Text = styled.p<{
  $size?: TFontSize;
  $weight?: TFontWeight;
  $bold?: boolean;
  $colour?: string;
}>`
  ${baseFontStyle}
  ${(p) => TEXT_SIZE_STYLES[p.$size ?? "base"]}
  ${(p) =>
    resolveFontWeightFromSize({
      size: p.$size ?? "base",
      bold: p.$bold ?? false,
      weight: p.$weight,
    })}
  ${(p) => p.$colour && `color: ${p.$colour};`}
`;

// TODO: change conversion to closely match : text-4xl-bold
export const Heading = styled.p`
  all: unset;
  display: block;
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-size: 2.25rem;
  line-height: 2.5rem;
  font-weight: 600;
`;
