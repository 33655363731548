import { textStyles } from "@utils/textStyles";
import styled from "styled-components";

export const Container = styled.div`
  background: ${(props) => props.theme.planConfig.background};
  display: block;
  min-height: 100vh;
`;
export const Content = styled.div`
  display: flex;
  margin-left: auto;
  text-align: center;
  margin-right: auto;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  gap: 1rem;
`;

export const SkipButtonContainer = styled.div<{ $isSticky: boolean }>`
  display: flex;
  flex-direction: row-reverse;
  padding: 1rem;
  width: 100%;
  top: 0;
  z-index: 100;
  background: ${(props) =>
    props.$isSticky
      ? props.theme.planConfig.darkBackground
      : props.theme.planConfig.background};
`;

export const Title = styled.h1`
  ${textStyles.text5xlBold};
  margin: 0;
`;

export const ComparedPlansSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 80%;
  gap: 2.5rem;
  @media (max-width: 768px) {
    max-width: 100%;
    overflow-x: auto;
  }
  margin-bottom: 4rem;
`;
export const ComparedPlansTitle = styled.h1`
  ${textStyles.text2xlBold};
  align-self: flex-start;
`;
export const Subtitle = styled.h2`
  margin: 0;
  ${textStyles.textLg};
  max-width: 80%;
`;

export const TopBar = styled.div`
  background: ${(props) => props.theme.planConfig.darkBackground};
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  position: sticky;
  top: 0;
  z-index: 100;
`;

export const TopBarContent = styled.div`
  display: flex;
  gap: 0.8rem;
  flex-direction: column;
  width: 100%;
  @media (max-width: 768px) {
    align-items: flex-start;
  }
`;

export const TopBarTextContainer = styled.div`
  display: flex;
  gap: 0.5rem;
  justify-content: center;
  padding: 0.9rem 0;
`;

export const TopBarTitle = styled.p`
  margin: 0;
  flex: 0 0 auto;
  ${textStyles.textBaseBold};
`;
export const TopBarSubTitle = styled.p`
  margin: 0;
  text-wrap: normal;
  ${textStyles.textBase};
`;

export const GoldText = styled.p`
  text-wrap: normal;
  ${textStyles.textBase};
  background: ${(props) => props.theme.label.background.subscription};
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin: 0.5rem 0.5rem 0.5rem 0;
`;

export const BetaText = styled(GoldText)`
  ${textStyles.textBaseBold};
  margin: 0.5rem 0.5rem 0.5rem 0;
  background: ${(props) => props.theme.label.background.beta};
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

export const SubLabelInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border: double 1px transparent;
  border-radius: 1rem;

  background-image: ${(props) => props.theme.planConfig.goldButtonBackground},
    ${(props) => props.theme.label.background.subscription};
  background-origin: border-box;
  background-clip: content-box, border-box;
`;

export const BetaLabel = styled(SubLabelInfo)`
  position: relative;
  border-radius: 1rem;
  display: flex;
  padding: 0 0.5rem;
  gap: 0.5rem;
  overflow: hidden;
  z-index: 1;
  background-image: ${(props) => props.theme.planConfig.betaButtonBackground},
    ${(props) => props.theme.label.background.beta};

  &::before {
    content: "";
    position: absolute;
    top: -2px;
    right: -2px;
    bottom: -2px;
    left: -2px;
    background-image: ${(props) => props.theme.planConfig.goldButtonBackground},
      ${(props) => props.theme.label.background.subscription};
    z-index: -1;
  }
`;

export const PlanItemContainer = styled.div`
  display: flex;
  gap: 1rem;
  width: 70%;
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;
