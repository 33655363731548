import { useProfile } from "@hooks/useProfile";
import React, {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useState,
} from "react";
import PurchaseModal from "../pages/purchase/Purchase.component";

type ProfileContextType = {
  fetchUserPreferences: () => void;
  pauseOrResumeAlerts: (pause: boolean) => Promise<void>;
  showPurchaseModal: () => void;
  dismissPurchaseModal: () => void;
};

const defaultProfileContext: ProfileContextType = {
  fetchUserPreferences: () => {
    return null;
  },
  pauseOrResumeAlerts: (): Promise<void> => {
    return new Promise((resolve) => resolve());
  },
  showPurchaseModal: () => {
    return null;
  },
  dismissPurchaseModal: () => {
    return null;
  },
};

export const ProfileContext = createContext(defaultProfileContext);

const ProfileProvider = (props: PropsWithChildren<object>) => {
  const [showPurchase, setShowPurchase] = useState(false);

  const { fetchUserPreferences, pauseOrResumeAlerts } = useProfile();

  const showPurchaseModal = useCallback(() => {
    setShowPurchase(true);
  }, []);

  const dismissPurchaseModal = useCallback(() => {
    setShowPurchase(false);
  }, []);
  return (
    <ProfileContext.Provider
      value={{
        fetchUserPreferences,
        pauseOrResumeAlerts,
        showPurchaseModal,
        dismissPurchaseModal,
      }}
    >
      <PurchaseModal open={showPurchase} onOpenChange={setShowPurchase} />
      {props.children}
    </ProfileContext.Provider>
  );
};

const useProfileContext = () => useContext(ProfileContext);

export { ProfileProvider, useProfileContext };
export type { ProfileContextType };
