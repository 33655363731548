import styled, { css } from "styled-components";

interface LinkProps {
  $variant?: "underline";
}

const Link = styled.a.attrs<LinkProps>((props) => ({
  $variant: props.$variant,
}))`
  color: ${(props) => props.theme.link};

  ${(props) =>
    props.$variant === "underline" &&
    css`
      text-decoration: none;
      font-size: 12px;
      &:hover {
        text-decoration: underline;
      }
      &:visited {
        color: inherit;
      }
    `}
`;

export default Link;
