// eslint-disable-next-line max-len
import { useAuth0 } from "@auth0/auth0-react";
import { DoubleBottom, MagnifyingGlassChart } from "@client/src/assets/Icons";
import { useAppContext } from "@client/src/context/AppContext";
import { themes } from "@client/src/themes";
// eslint-disable-next-line max-len
import ChartTypeSelector from "@components/chart_type_selector/ChartTypeSelector.component";
// eslint-disable-next-line max-len
import FrequencySelect from "@components/frequency_selector/FrequencySelection.component";
import { Instrument } from "@generated/common/basic-types_pb";
import { hasAimaBeenShown } from "@utils/aima_utils";
import React, { useCallback, useEffect, useState } from "react";
import { HiPresentationChartLine } from "react-icons/hi2";
import { useTheme } from "styled-components";
import { useUserContext } from "../../context/user/UserContext";
import AimaDialog, {
  MarketAnalysisDates,
} from "../aima_dialog/AimaDialog.component";
import AIMaTooltip from "../aima_tooltip/AIMATooltip.component";
import { BetaTag } from "../label_types/beta_tag/BetaTag.component";
import Button from "../button/Button.component";
import PatternConfigDialog from "../pattern_config_dialog/PatternConfigDialog";
import TechnicalsDialog from "../technicals_dialog/TechnicalsDialog.component";
import Toggle from "../toggle/Toggle.component";
import { Tooltip } from "../tooltip/Tooltip.component";
import {
  AIMAButtonContainer,
  ChartControlsContainer,
  SupportResistanceToggle,
} from "./ChartControls.style";

type Props = {
  onChangeFrequency: (frequency: string) => void;
  onChangeChartType: (chartType: string) => void;
};
const ChartControls = ({ onChangeFrequency, onChangeChartType }: Props) => {
  const [patternsVisible, setPatternsVisible] = useState(false);
  const [hasMarketAnalysis, setHasMarketAnalysis] = useState(false);
  const [marketAnalysisVisible, setMarketAnalysisVisible] = useState(false);
  const [marketAnalysis, setMarketAnalysis] = useState<string>("");
  const [marketAnalysisDates, setMarketAnalysisDates] =
    useState<MarketAnalysisDates>({
      start: "",
      end: "",
    });

  const [aimaTooltipEnabled, setAimaTooltipEnabled] = useState<boolean>(
    !hasAimaBeenShown()
  );

  useState<Instrument.AsObject | null>(null);
  const {
    supportResistanceEnabled,
    setSupportResistanceEnabled,
    technicalDialogVisible: technicalsVisible,
    setTechnicalDialogVisible: setTechnicalsVisible,
    hashcode: instrumentHashcode,
  } = useAppContext();
  const theme = useTheme();
  const { setShowAccessDeniedDialog } = useUserContext();
  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    getAccessTokenSilently().then((token) => {
      fetch(`/api/market-reports/${instrumentHashcode}`, {
        method: "HEAD",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }).then((res) =>
        setHasMarketAnalysis(res.status === 204 || res.status === 200)
      );
    });
  }, [instrumentHashcode]);

  const isSrEnabled = () => {
    return (
      window.location.search.includes("sr=true") || supportResistanceEnabled
    );
  };

  const renderSupportResistanceToggle = () => {
    if (theme.name !== "akili") return null;
    return (
      <SupportResistanceToggle>
        Support & Resistance
        <Toggle
          checked={isSrEnabled()}
          onChange={(value) => {
            setSupportResistanceEnabled(value);
          }}
          checkedColour={themes["akili"].chart.candles.upColor}
        />
      </SupportResistanceToggle>
    );
  };

  const renderTechnicalsButton = () => {
    return (
      <Button
        text={"Technicals"}
        leftIcon={<HiPresentationChartLine />}
        onClick={() => {
          setTechnicalsVisible(true);
        }}
        type="filter"
        size="sm"
      />
    );
  };

  const prepareMarketAnalysisData = useCallback(() => {
    getAccessTokenSilently().then(async (token: string) => {
      const response = await fetch(
        `/api/market-reports/${instrumentHashcode}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (!response.ok) {
        if (response.status === 403) {
          setShowAccessDeniedDialog(true);
        }
        return;
      }
      response.json().then((data) => {
        setMarketAnalysisDates(data.region_of_interest);
        setMarketAnalysis(data.analysis.replace(/\\n/g, "\n"));
        setMarketAnalysisVisible(true);
      });
    });
  }, [instrumentHashcode]);

  const renderPatternButton = () => {
    return (
      <Button
        text={"Patterns"}
        leftIcon={<DoubleBottom width={16} height={16} />}
        onClick={() => setPatternsVisible(true)}
        type="filter"
        size="sm"
      />
    );
  };

  const renderMarketAnalysisButton = () => {
    const theme = useTheme();
    const betaStyle = {
      border: "1px solid",
      borderImageSlice: 1,
      borderImageSource: theme.label.background.beta,
    };
    return (
      <Tooltip>
        <Tooltip.Item>
          <AIMAButtonContainer
            style={aimaTooltipEnabled ? betaStyle : undefined}
          >
            <Button
              text="AI Market Analysis"
              onClick={() => {
                prepareMarketAnalysisData();
              }}
              type="filter"
              size="sm"
              leftIcon={<MagnifyingGlassChart />}
              rightIcon={<BetaTag />}
            />
          </AIMAButtonContainer>
        </Tooltip.Item>
        <Tooltip.Content
          style={{ backgroundColor: theme.tooltipBgDark, padding: "0rem" }}
          dark={true}
          enabled={aimaTooltipEnabled}
          forceMount={aimaTooltipEnabled ? true : undefined}
        >
          <AIMaTooltip
            onClickDismiss={() => {
              setAimaTooltipEnabled(false);
            }}
          />
        </Tooltip.Content>
      </Tooltip>
    );
  };

  const getMarketAnalysisButton = () => {
    if (!hasMarketAnalysis) return null;
    return renderMarketAnalysisButton();
  };

  return (
    <ChartControlsContainer data-testid={"chart-controls"}>
      <AimaDialog
        visible={marketAnalysisVisible}
        content={marketAnalysis}
        dates={marketAnalysisDates}
        onClickOutside={() => setMarketAnalysisVisible(false)}
      />
      <TechnicalsDialog
        visible={technicalsVisible}
        onClickOutside={() => setTechnicalsVisible(false)}
      />
      <PatternConfigDialog
        visible={patternsVisible}
        onClickOutside={() => setPatternsVisible(false)}
      />
      <FrequencySelect onChange={onChangeFrequency} />
      <ChartTypeSelector onChange={onChangeChartType} />
      {renderPatternButton()}
      {renderTechnicalsButton()}
      {renderSupportResistanceToggle()}
      {getMarketAnalysisButton()}
    </ChartControlsContainer>
  );
};
export default ChartControls;
