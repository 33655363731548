import { RuleSet, css } from "styled-components";

export type TFontSize =
  | "xs"
  | "sm"
  | "base"
  | "lg"
  | "xl"
  | "2xl"
  | "3xl"
  | "4xl"
  | "5xl";

export type TFontWeight =
  | "bold"
  | 100
  | 200
  | 300
  | 400
  | 500
  | 600
  | 700
  | 800
  | 900;

export const baseFontStyle = css`
  all: unset;
  font-family: Poppins;
  font-style: normal;
  font-weight: 300;
`;

export const TEXT_SIZE_STYLES: {
  [x in TFontSize]: RuleSet<object>;
} = {
  xs: css`
    font-size: 0.625rem;
    font-weight: 300;
    line-height: 1rem;
  `,
  sm: css`
    font-size: 0.75rem;
    font-weight: 300;
    line-height: 1.3rem;
  `,
  base: css`
    font-size: 0.875rem;
    font-weight: 300;
    line-height: 1.5rem;
  `,
  lg: css`
    font-size: 1.1rem;
    font-weight: 300;
    line-height: 1.8rem;
  `,
  xl: css`
    font-size: 1.3rem;
    font-weight: 600;
    line-height: 1.8rem;
  `,
  "2xl": css`
    font-size: 1.5rem;
    font-weight: 300;
    line-height: 2rem;
  `,
  "3xl": css`
    font-size: 1.875rem;
    font-weight: 300;
    line-height: 2.25rem;
  `,
  "4xl": css`
    font-size: 2.25rem;
    font-weight: 300;
    line-height: 2.5rem;
  `,
  "5xl": css`
    font-size: 3rem;
    font-weight: 300;
    line-height: 3.25rem;
  `,
};

export const TEXT_SIZE_WEIGHT_VALUES: {
  [x in TFontSize]?: TFontWeight;
} = {
  xs: 500,
  sm: 500,
  base: 500,
  lg: 600,
  xl: 600,
  "2xl": 600,
  "3xl": 600,
  "4xl": 600,
  "5xl": 700,
};
