/* eslint-disable */
import * as _m0 from "protobufjs/minimal";
import { SourceType, sourceTypeFromJSON, sourceTypeToJSON } from "./common";
import Long = require("long");

export const protobufPackage = "ai.sigmafinancial.ta.moving_averages";

/**
 * https://crates.io/crates/yata provides the following moving averages which
 * requires one parameter value `window_length`
 */
export enum MovingAverageType {
  UNKNOWN = 0,
  /** SMA - Simple Moving Average */
  SMA = 1,
  /** WMA - Weighted Moving Average */
  WMA = 2,
  /** EMA - Exponential Moving Average */
  EMA = 3,
  /** DMA - Displace Moving Average */
  DMA = 4,
  /** TMA - Triangular Moving Average */
  TMA = 5,
  /** DEMA - Double Exponential Moving Average */
  DEMA = 6,
  /** TEMA - Triple Exponential Moving Average */
  TEMA = 7,
  /** SMM - Simple Moving Median */
  SMM = 8,
  /** LSMA - Linear regression moving average */
  LSMA = 9,
  /** SWMA - Symmetric Weighted Moving Average */
  SWMA = 10,
  /** HMA - Hull moving average */
  HMA = 11,
  /** RMA - Running Moving Average */
  RMA = 12,
  /** TRIMA - Triangular Moving Average */
  TRIMA = 13,
  /** WSMA - Wilder’s Smoothing Average */
  WSMA = 14,
  /** VIDYA - Variable Index Dynamic Average */
  VIDYA = 15,
  UNRECOGNIZED = -1,
}

export function movingAverageTypeFromJSON(object: any): MovingAverageType {
  switch (object) {
    case 0:
    case "UNKNOWN":
      return MovingAverageType.UNKNOWN;
    case 1:
    case "SMA":
      return MovingAverageType.SMA;
    case 2:
    case "WMA":
      return MovingAverageType.WMA;
    case 3:
    case "EMA":
      return MovingAverageType.EMA;
    case 4:
    case "DMA":
      return MovingAverageType.DMA;
    case 5:
    case "TMA":
      return MovingAverageType.TMA;
    case 6:
    case "DEMA":
      return MovingAverageType.DEMA;
    case 7:
    case "TEMA":
      return MovingAverageType.TEMA;
    case 8:
    case "SMM":
      return MovingAverageType.SMM;
    case 9:
    case "LSMA":
      return MovingAverageType.LSMA;
    case 10:
    case "SWMA":
      return MovingAverageType.SWMA;
    case 11:
    case "HMA":
      return MovingAverageType.HMA;
    case 12:
    case "RMA":
      return MovingAverageType.RMA;
    case 13:
    case "TRIMA":
      return MovingAverageType.TRIMA;
    case 14:
    case "WSMA":
      return MovingAverageType.WSMA;
    case 15:
    case "VIDYA":
      return MovingAverageType.VIDYA;
    case -1:
    case "UNRECOGNIZED":
    default:
      return MovingAverageType.UNRECOGNIZED;
  }
}

export function movingAverageTypeToJSON(object: MovingAverageType): string {
  switch (object) {
    case MovingAverageType.UNKNOWN:
      return "UNKNOWN";
    case MovingAverageType.SMA:
      return "SMA";
    case MovingAverageType.WMA:
      return "WMA";
    case MovingAverageType.EMA:
      return "EMA";
    case MovingAverageType.DMA:
      return "DMA";
    case MovingAverageType.TMA:
      return "TMA";
    case MovingAverageType.DEMA:
      return "DEMA";
    case MovingAverageType.TEMA:
      return "TEMA";
    case MovingAverageType.SMM:
      return "SMM";
    case MovingAverageType.LSMA:
      return "LSMA";
    case MovingAverageType.SWMA:
      return "SWMA";
    case MovingAverageType.HMA:
      return "HMA";
    case MovingAverageType.RMA:
      return "RMA";
    case MovingAverageType.TRIMA:
      return "TRIMA";
    case MovingAverageType.WSMA:
      return "WSMA";
    case MovingAverageType.VIDYA:
      return "VIDYA";
    case MovingAverageType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface WindowedMovingAverage {
  kind: MovingAverageType;
  window_length: number;
  /** Default is `Close` */
  source?: SourceType | undefined;
}

function createBaseWindowedMovingAverage(): WindowedMovingAverage {
  return { kind: 0, window_length: 0, source: undefined };
}

export const WindowedMovingAverage = {
  encode(message: WindowedMovingAverage, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.kind !== 0) {
      writer.uint32(8).int32(message.kind);
    }
    if (message.window_length !== 0) {
      writer.uint32(16).uint32(message.window_length);
    }
    if (message.source !== undefined) {
      writer.uint32(24).int32(message.source);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): WindowedMovingAverage {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseWindowedMovingAverage();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.kind = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.window_length = reader.uint32();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.source = reader.int32() as any;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): WindowedMovingAverage {
    return {
      kind: isSet(object.kind) ? movingAverageTypeFromJSON(object.kind) : 0,
      window_length: isSet(object.window_length) ? globalThis.Number(object.window_length) : 0,
      source: isSet(object.source) ? sourceTypeFromJSON(object.source) : undefined,
    };
  },

  toJSON(message: WindowedMovingAverage): unknown {
    const obj: any = {};
    if (message.kind !== 0) {
      obj.kind = movingAverageTypeToJSON(message.kind);
    }
    if (message.window_length !== 0) {
      obj.window_length = Math.round(message.window_length);
    }
    if (message.source !== undefined) {
      obj.source = sourceTypeToJSON(message.source);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<WindowedMovingAverage>, I>>(base?: I): WindowedMovingAverage {
    return WindowedMovingAverage.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<WindowedMovingAverage>, I>>(object: I): WindowedMovingAverage {
    const message = createBaseWindowedMovingAverage();
    message.kind = object.kind ?? 0;
    message.window_length = object.window_length ?? 0;
    message.source = object.source ?? undefined;
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Long ? string | number | Long : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
