import styled from "styled-components";

export const ChartContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

export const MiniChartContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;
