import * as React from "react";
import * as Dialog from "@radix-ui/react-dialog";
import { HiXMark } from "react-icons/hi2";

import styled from "styled-components";
import { IconButton } from "../../atoms/button";
import { effectStyles } from "@client/src/utils/effectStyles";

export const Root = Dialog.Root;

export const Trigger = Dialog.Trigger;

const ModalContent = styled(Dialog.Content)``;

const ModalContainer = styled.div`
  padding: 2rem;
  column-gap: 3rem;

  margin-left: auto;
  margin-right: auto;
  background: ${(p) => p.theme.modal.background};
  border-radius: 1rem;
  z-index: 1100;
  ${effectStyles.grey.shadowXl};

  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 450px;
  max-height: 85vh;

  width: 100%;

  @media (min-width: 640px) {
    max-width: 640px;
  }

  @media (min-width: 768px) {
    max-width: 768px;
  }

  @media (min-width: 1024px) {
    max-width: 1024px;
  }

  @media (min-width: 1280px) {
    max-width: 1280px;
  }

  @media (min-width: 1536px) {
    max-width: 1536px;
  }
`;

export const Overlay = styled(Dialog.Overlay)`
  position: fixed;
  box-sizing: border-box;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${(p) => p.theme.modal.overlay.background};
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  padding: 1rem;
`;

export const CloseTrigger = styled(Dialog.Close)`
  position: absolute;
  top: 0;
  right: 0;
  margin: 1rem;
`;

export const Container = ({
  children,
  className,
  close,
}: {
  children: React.ReactNode;
  className?: string;
  close?: boolean;
}) => {
  return (
    <Dialog.Portal container={document.body}>
      <Overlay />
      <ModalContent>
        <ModalContainer className={className}>
          {children}
          {close && (
            <CloseTrigger asChild>
              <IconButton icon={HiXMark} />
            </CloseTrigger>
          )}
        </ModalContainer>
      </ModalContent>
    </Dialog.Portal>
  );
};

const ModalHeader = styled.div`
  display: flex;
  gap: 2rem;
  width: 100%;
  justify-content: space-between;
`;
const ModalHeaderContent = styled.div``;
export const Header = ({
  children,
  className,
}: {
  children?: React.ReactNode;
  className?: string;
}) => {
  return (
    <ModalHeader>
      <ModalHeaderContent className={className}>{children}</ModalHeaderContent>
    </ModalHeader>
  );
};

const ModalBody = styled.div``;
export const Body = React.forwardRef<
  HTMLDivElement,
  React.ComponentProps<typeof ModalBody>
>(({ children, ...props }, ref) => {
  return (
    <ModalBody {...props} ref={ref}>
      {children}
    </ModalBody>
  );
});
