import React from "react";
import { LabelContainer, LabelIcon, LabelText } from "./Label.style";

export type LabelProps = {
  variant?: string;
  text: string;
  leftIcon?: React.ReactNode;
  rightIcon?: React.ReactNode;
};

const Label = ({
  variant = "default",
  text,
  leftIcon,
  rightIcon,
}: LabelProps) => {
  return (
    <LabelContainer $variant={variant} data-testid="label-container">
      {leftIcon && (
        <LabelIcon $variant={variant} data-testid="label-left-icon">
          {leftIcon}
        </LabelIcon>
      )}
      <LabelText $variant={variant} data-testid="label-text">
        {text}
      </LabelText>
      {rightIcon && (
        <LabelIcon $variant={variant} data-testid="label-right-icon">
          {rightIcon}
        </LabelIcon>
      )}
    </LabelContainer>
  );
};

export default Label;
