export type Item = {
  name: string;
  value: string;
};
export const hardcodedItems: Item[] = [
  {
    name: "1m",
    value: "4",
  },
  {
    name: "3m",
    value: "5",
  },
  {
    name: "5m",
    value: "10",
  },
  {
    name: "10m",
    value: "15",
  },
  {
    name: "15m",
    value: "20",
  },
  {
    name: "30m",
    value: "25",
  },
  {
    name: "1h",
    value: "30",
  },
  {
    name: "2h",
    value: "35",
  },
  {
    name: "3h",
    value: "40",
  },
  {
    name: "4h",
    value: "45",
  },
  {
    name: "1d",
    value: "50",
  },
];

export const getDefaultFrequency = (
  frequencyName: string,
  frequencyValueParam: string | undefined
): string => {
  if (frequencyValueParam) {
    return getFrequencyByValue(frequencyValueParam);
  }
  return getFrequencyByName(frequencyName);
};
export const getFrequencyByName = (name: string): string => {
  const item = hardcodedItems.find((item) => item.name === name);
  if (item) {
    return item.name;
  }
  return hardcodedItems[0].name;
};

export const getFrequencyByValue = (value: string): string => {
  const item = hardcodedItems.find((item) => item.value === value);
  if (item) {
    return item.name;
  }
  return hardcodedItems[0].name;
};
