import React from "react";
import { getSafeString } from "@client/src/utils/getSafe";
import { getInstrumentLabel } from "@client/src/utils/instrumentUtils";
import { Column, ScreeningResult } from "./ScreeningResultTable.component";
import Label from "../label/Label.component";
import { HiArrowDown, HiArrowUp, HiMinus } from "react-icons/hi2";
import { AlignRight } from "./ScreeningResultTable.style";

export const formatPrice = (value: string) => {
  const numericValue = parseFloat(value);
  if (numericValue >= 1) {
    if (numericValue >= 1000000) {
      return (numericValue / 1000000).toFixed(3) + "M";
    }
    return numericValue.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  } else if (numericValue < 0.1) {
    return numericValue.toFixed(6);
  }
  return value.toString();
};

export const getProperPrice = (result: ScreeningResult) => {
  return getSafeString(result.price?.toString());
};

export const getLatestVolume = (result: ScreeningResult) => {
  return formatPrice(getSafeString(result.volume));
};

export const splitSymbolAndName = (cellContent: string) => {
  const [symbol, name] = cellContent.split("_");
  return { symbol, name };
};

export const renderPercentageChange = (cellContent: string) => {
  if (cellContent === "") return <></>;
  const numbers = cellContent.split(", ");
  const numericValue = parseFloat(numbers[1]);
  if (numericValue === 0) {
    return (
      <AlignRight>
        <Label
          text={`${cellContent}%`}
          variant="warning"
          leftIcon={<HiMinus />}
        />
      </AlignRight>
    );
  }
  if (numericValue > 0) {
    return (
      <AlignRight>
        <Label
          text={`${cellContent}%`}
          variant="success"
          leftIcon={<HiArrowUp />}
        />
      </AlignRight>
    );
  }
  return (
    <AlignRight>
      <Label
        text={`${cellContent}%`}
        variant="error"
        leftIcon={<HiArrowDown />}
      />
    </AlignRight>
  );
};

// format last match
const timeUnits = [
  { name: "year", duration: 31536000 },
  { name: "month", duration: 2592000 },
  { name: "day", duration: 86400 },
  { name: "hour", duration: 3600 },
  { name: "minute", duration: 60 },
];

export const formatIntervalMessage = (interval: number, unitName: string) => {
  return `${interval} ${unitName}${interval > 1 ? "s" : ""} ago`;
};

export const getTimeUnitAgo = (
  interval: number,
  unitName: string,
  date: {
    toLocaleTimeString: (
      arg0: never[],
      arg1: { hour: string; minute: string }
    ) => string;
  }
) => {
  if (unitName === "day" && interval === 1) {
    return `Yesterday ${date.toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
    })}`;
  }
  return formatIntervalMessage(interval, unitName);
};

export const getAgoText = (secondsAgo: number, date: Date) => {
  for (const unit of timeUnits) {
    const interval = Math.floor(secondsAgo / unit.duration);
    if (interval >= 1) {
      return getTimeUnitAgo(interval, unit.name, date);
    }
  }
  return "Just now";
};

export const formatLastMatch = (lastMatch: string | number | Date) => {
  if (!lastMatch) return "";
  const matchDate = new Date(lastMatch);
  const now = new Date();
  const secondsAgo = Math.floor((now.getTime() - matchDate.getTime()) / 1000);
  return getAgoText(secondsAgo, matchDate);
};

const addRow = (
  columnsToInclude: Column[],
  key: Column,
  rows: string[],
  content: string
) => {
  if (columnsToInclude.includes(key)) {
    rows.push(content);
  }
};

export const parseResultsToCells = (
  results: ScreeningResult[],
  columnsToInclude: Column[]
) => {
  const rows: string[] = [];
  results.forEach((result) => {
    if (result.lastMatch) {
      result.lastMatchTimestamp = new Date(result.lastMatch).getTime();
    }
    addRow(
      columnsToInclude,
      "Name",
      rows,
      [result.symbol, result.name].join("_")
    );
    addRow(
      columnsToInclude,
      "Type",
      rows,
      getInstrumentLabel(Number(result.class)).toString()
    );
    addRow(columnsToInclude, "Price", rows, getProperPrice(result));
    addRow(columnsToInclude, "Volume", rows, getLatestVolume(result));
    addRow(
      columnsToInclude,
      "Change",
      rows,
      getSafeString(result.change?.toString())
    );
    addRow(
      columnsToInclude,
      "Last Match",
      rows,
      getSafeString(result.lastMatch)
    );
    addRow(
      columnsToInclude,
      "Count",
      rows,
      getSafeString(result.count?.toString())
    );
    addRow(columnsToInclude, "Portfolio", rows, "");
    addRow(columnsToInclude, "Alert", rows, "");
  });
  return rows;
};
