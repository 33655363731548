const instrumentClassNames: { [key: number]: string } = {
  0: "EQUITY",
  1: "INDEX",
  2: "CRYPTO",
  3: "FOREX",
  4: "TREASURIES",
  5: "COMMODITIES",
  6: "EXCHANGE_TRADED_NOTES",
  7: "DEPOSITORY_RECEIPT",
  8: "EXCHANGE_TRADED_COMMODITIES",
  9: "EXCHANGE_TRADED_FUNDS",
};

export const mapInstrumentClassToName = (instrumentClass: number): string => {
  return instrumentClassNames[instrumentClass] || "Unknown";
};
