import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem 0 0 1rem;
  background-color: ${(props) => props.theme.secondaryBackground};
  border-top: 1px solid ${(props) => props.theme.dividerColor};

  @media (max-width: 768px) {
    align-items: flex-start;
    justify-content: space-between;
    gap: 0.25rem;
    border-top: 0px;
  }
`;

export const NameAndChange = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.25rem;
`;

export const InstrumentName = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 0.25rem;
  }
`;

export const InstrumentTag = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
`;
