import React from "react";
import Button from "../button/Button.component";
import { Modal } from "../modal/Modal.component";
type Props = {
  onClick: () => void;
  onClickCancel: () => void;
  show: boolean;
};
const AccessDeniedDialog = ({ onClick, onClickCancel, show }: Props) => {
  if (!show) return null;
  return (
    <Modal
      modalTitle="You’ll need to upgrade your account to access that"
      modalDescription=""
      Button1={<Button text="Start Free Trial" type="gold" onClick={onClick} />}
      Button2={
        <Button text="Cancel" type="secondary" onClick={onClickCancel} />
      }
      isVisible={true}
    />
  );
};

export default AccessDeniedDialog;
