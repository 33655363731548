import * as React from 'react';

// This module exists to work around Webpack issue https://github.com/webpack/webpack/issues/14814

// eslint-disable-next-line no-restricted-imports

const {
  createElement,
  createContext,
  createRef,
  forwardRef,
  useCallback,
  useContext,
  useEffect,
  useImperativeHandle,
  useLayoutEffect,
  useMemo,
  useRef,
  useState
} = React;

// `toString()` prevents bundlers from trying to `import { useId } from 'react'`
const useId = React["useId".toString()];

const PanelGroupContext = createContext(null);
PanelGroupContext.displayName = "PanelGroupContext";

const useIsomorphicLayoutEffect = useLayoutEffect ;

const wrappedUseId = typeof useId === "function" ? useId : () => null;
let counter = 0;
function useUniqueId(idFromParams = null) {
  const idFromUseId = wrappedUseId();
  const idRef = useRef(idFromParams || idFromUseId || null);
  if (idRef.current === null) {
    idRef.current = "" + counter++;
  }
  return idFromParams !== null && idFromParams !== void 0 ? idFromParams : idRef.current;
}

function PanelWithForwardedRef({
  children,
  className: classNameFromProps = "",
  collapsedSizePercentage,
  collapsedSizePixels,
  collapsible,
  defaultSizePercentage,
  defaultSizePixels,
  forwardedRef,
  id: idFromProps,
  maxSizePercentage,
  maxSizePixels,
  minSizePercentage,
  minSizePixels,
  onCollapse,
  onExpand,
  onResize,
  order,
  style: styleFromProps,
  tagName: Type = "div"
}) {
  const context = useContext(PanelGroupContext);
  if (context === null) {
    throw Error(`Panel components must be rendered within a PanelGroup container`);
  }
  const {
    collapsePanel,
    expandPanel,
    getPanelSize,
    getPanelStyle,
    isPanelCollapsed,
    registerPanel,
    resizePanel,
    unregisterPanel
  } = context;
  const panelId = useUniqueId(idFromProps);
  const panelDataRef = useRef({
    callbacks: {
      onCollapse,
      onExpand,
      onResize
    },
    constraints: {
      collapsedSizePercentage,
      collapsedSizePixels,
      collapsible,
      defaultSizePercentage,
      defaultSizePixels,
      maxSizePercentage,
      maxSizePixels,
      minSizePercentage,
      minSizePixels
    },
    id: panelId,
    idIsFromProps: idFromProps !== undefined,
    order
  });
  useRef({
    didLogMissingDefaultSizeWarning: false
  });
  useIsomorphicLayoutEffect(() => {
    const {
      callbacks,
      constraints
    } = panelDataRef.current;
    panelDataRef.current.id = panelId;
    panelDataRef.current.idIsFromProps = idFromProps !== undefined;
    panelDataRef.current.order = order;
    callbacks.onCollapse = onCollapse;
    callbacks.onExpand = onExpand;
    callbacks.onResize = onResize;
    constraints.collapsedSizePercentage = collapsedSizePercentage;
    constraints.collapsedSizePixels = collapsedSizePixels;
    constraints.collapsible = collapsible;
    constraints.defaultSizePercentage = defaultSizePercentage;
    constraints.defaultSizePixels = defaultSizePixels;
    constraints.maxSizePercentage = maxSizePercentage;
    constraints.maxSizePixels = maxSizePixels;
    constraints.minSizePercentage = minSizePercentage;
    constraints.minSizePixels = minSizePixels;
  });
  useIsomorphicLayoutEffect(() => {
    const panelData = panelDataRef.current;
    registerPanel(panelData);
    return () => {
      unregisterPanel(panelData);
    };
  }, [order, panelId, registerPanel, unregisterPanel]);
  useImperativeHandle(forwardedRef, () => ({
    collapse: () => {
      collapsePanel(panelDataRef.current);
    },
    expand: () => {
      expandPanel(panelDataRef.current);
    },
    getId() {
      return panelId;
    },
    getSize() {
      return getPanelSize(panelDataRef.current);
    },
    isCollapsed() {
      return isPanelCollapsed(panelDataRef.current);
    },
    isExpanded() {
      return !isPanelCollapsed(panelDataRef.current);
    },
    resize: mixedSizes => {
      resizePanel(panelDataRef.current, mixedSizes);
    }
  }), [collapsePanel, expandPanel, getPanelSize, isPanelCollapsed, panelId, resizePanel]);
  const style = getPanelStyle(panelDataRef.current);
  return createElement(Type, {
    children,
    className: classNameFromProps,
    style: {
      ...style,
      ...styleFromProps
    },
    // CSS selectors
    "data-panel": "",
    "data-panel-id": panelId,
    // e2e test attributes
    "data-panel-collapsible": undefined,
    "data-panel-size": undefined
  });
}
const Panel = forwardRef((props, ref) => createElement(PanelWithForwardedRef, {
  ...props,
  forwardedRef: ref
}));
PanelWithForwardedRef.displayName = "Panel";
Panel.displayName = "forwardRef(Panel)";

const PRECISION = 10;

function convertPixelsToPercentage(pixels, groupSizePixels) {
  return pixels / groupSizePixels * 100;
}

function convertPixelConstraintsToPercentages(panelConstraints, groupSizePixels) {
  let {
    collapsedSizePercentage = 0,
    collapsedSizePixels,
    defaultSizePercentage,
    defaultSizePixels,
    maxSizePercentage = 100,
    maxSizePixels,
    minSizePercentage = 0,
    minSizePixels
  } = panelConstraints;
  const hasPixelConstraints = collapsedSizePixels != null || defaultSizePixels != null || minSizePixels != null || maxSizePixels != null;
  if (hasPixelConstraints && groupSizePixels <= 0) {
    console.warn(`WARNING: Invalid group size: ${groupSizePixels}px`);
    return {
      collapsedSizePercentage: 0,
      defaultSizePercentage,
      maxSizePercentage: 0,
      minSizePercentage: 0
    };
  }
  if (collapsedSizePixels != null) {
    collapsedSizePercentage = convertPixelsToPercentage(collapsedSizePixels, groupSizePixels);
  }
  if (defaultSizePixels != null) {
    defaultSizePercentage = convertPixelsToPercentage(defaultSizePixels, groupSizePixels);
  }
  if (minSizePixels != null) {
    minSizePercentage = convertPixelsToPercentage(minSizePixels, groupSizePixels);
  }
  if (maxSizePixels != null) {
    maxSizePercentage = convertPixelsToPercentage(maxSizePixels, groupSizePixels);
  }
  return {
    collapsedSizePercentage,
    defaultSizePercentage,
    maxSizePercentage,
    minSizePercentage
  };
}

function computePercentagePanelConstraints(panelConstraintsArray, panelIndex, groupSizePixels) {
  // All panel constraints, excluding the current one
  let totalMinConstraints = 0;
  let totalMaxConstraints = 0;
  for (let index = 0; index < panelConstraintsArray.length; index++) {
    if (index !== panelIndex) {
      const {
        collapsible
      } = panelConstraintsArray[index];
      const {
        collapsedSizePercentage,
        maxSizePercentage,
        minSizePercentage
      } = convertPixelConstraintsToPercentages(panelConstraintsArray[index], groupSizePixels);
      totalMaxConstraints += maxSizePercentage;
      totalMinConstraints += collapsible ? collapsedSizePercentage : minSizePercentage;
    }
  }
  const {
    collapsedSizePercentage,
    defaultSizePercentage,
    maxSizePercentage,
    minSizePercentage
  } = convertPixelConstraintsToPercentages(panelConstraintsArray[panelIndex], groupSizePixels);
  return {
    collapsedSizePercentage,
    defaultSizePercentage,
    maxSizePercentage: panelConstraintsArray.length > 1 ? Math.min(maxSizePercentage, 100 - totalMinConstraints) : maxSizePercentage,
    minSizePercentage: panelConstraintsArray.length > 1 ? Math.max(minSizePercentage, 100 - totalMaxConstraints) : minSizePercentage
  };
}

function fuzzyCompareNumbers(actual, expected, fractionDigits = PRECISION) {
  actual = parseFloat(actual.toFixed(fractionDigits));
  expected = parseFloat(expected.toFixed(fractionDigits));
  const delta = actual - expected;
  if (delta === 0) {
    return 0;
  } else {
    return delta > 0 ? 1 : -1;
  }
}

function fuzzyNumbersEqual(actual, expected, fractionDigits) {
  return fuzzyCompareNumbers(actual, expected, fractionDigits) === 0;
}

// Panel size must be in percentages; pixel values should be pre-converted
function resizePanel({
  groupSizePixels,
  panelConstraints,
  panelIndex,
  size
}) {
  const hasPixelConstraints = panelConstraints.some(({
    collapsedSizePixels,
    defaultSizePixels,
    minSizePixels,
    maxSizePixels
  }) => collapsedSizePixels != null || defaultSizePixels != null || minSizePixels != null || maxSizePixels != null);
  if (hasPixelConstraints && groupSizePixels <= 0) {
    console.warn(`WARNING: Invalid group size: ${groupSizePixels}px`);
    return 0;
  }
  let {
    collapsible
  } = panelConstraints[panelIndex];
  const {
    collapsedSizePercentage,
    maxSizePercentage,
    minSizePercentage
  } = computePercentagePanelConstraints(panelConstraints, panelIndex, groupSizePixels);
  if (minSizePercentage != null) {
    if (fuzzyCompareNumbers(size, minSizePercentage) < 0) {
      if (collapsible) {
        size = collapsedSizePercentage;
      } else {
        size = minSizePercentage;
      }
    }
  }
  if (maxSizePercentage != null) {
    size = Math.min(maxSizePercentage, size);
  }
  return size;
}

// All units must be in percentages; pixel values should be pre-converted
function adjustLayoutByDelta({
  delta,
  groupSizePixels,
  layout: prevLayout,
  panelConstraints,
  pivotIndices,
  trigger
}) {
  if (fuzzyNumbersEqual(delta, 0)) {
    return prevLayout;
  }
  const nextLayout = [...prevLayout];
  let deltaApplied = 0;

  // A resizing panel affects the panels before or after it.
  //
  // A negative delta means the panel immediately after the resizer should grow/expand by decreasing its offset.
  // Other panels may also need to shrink/contract (and shift) to make room, depending on the min weights.
  //
  // A positive delta means the panel immediately before the resizer should "expand".
  // This is accomplished by shrinking/contracting (and shifting) one or more of the panels after the resizer.

  // First, check the panel we're pivoting around;
  // We should only expand or contract by as much as its constraints allow
  {
    const pivotIndex = delta < 0 ? pivotIndices[1] : pivotIndices[0];
    const initialSize = nextLayout[pivotIndex];
    const {
      collapsible
    } = panelConstraints[pivotIndex];
    const {
      collapsedSizePercentage,
      minSizePercentage
    } = computePercentagePanelConstraints(panelConstraints, pivotIndex, groupSizePixels);
    const isCollapsed = collapsible && fuzzyNumbersEqual(initialSize, collapsedSizePercentage);
    let unsafeSize = initialSize + Math.abs(delta);
    if (isCollapsed) {
      switch (trigger) {
        case "keyboard":
          if (minSizePercentage > unsafeSize) {
            unsafeSize = minSizePercentage;
          }
      }
    }
    const safeSize = resizePanel({
      groupSizePixels,
      panelConstraints,
      panelIndex: pivotIndex,
      size: unsafeSize
    });
    if (fuzzyNumbersEqual(initialSize, safeSize)) {
      // If there's no room for the pivot panel to grow, we should ignore this change
      return nextLayout;
    } else {
      delta = delta < 0 ? initialSize - safeSize : safeSize - initialSize;
    }
  }

  // Delta added to a panel needs to be subtracted from other panels
  // within the constraints that those panels allow
  {
    const pivotIndex = delta < 0 ? pivotIndices[0] : pivotIndices[1];
    let index = pivotIndex;
    while (index >= 0 && index < panelConstraints.length) {
      const deltaRemaining = Math.abs(delta) - Math.abs(deltaApplied);
      const prevSize = prevLayout[index];
      const unsafeSize = prevSize - deltaRemaining;
      let safeSize = resizePanel({
        groupSizePixels,
        panelConstraints,
        panelIndex: index,
        size: unsafeSize
      });
      if (!fuzzyNumbersEqual(prevSize, safeSize)) {
        deltaApplied += prevSize - safeSize;
        nextLayout[index] = safeSize;
        if (deltaApplied.toPrecision(3).localeCompare(Math.abs(delta).toPrecision(3), undefined, {
          numeric: true
        }) >= 0) {
          break;
        }
      }
      if (delta < 0) {
        index--;
      } else {
        index++;
      }
    }
  }

  // If we were unable to resize any of the panels panels, return the previous state.
  // This will essentially bailout and ignore e.g. drags past a panel's boundaries
  if (fuzzyNumbersEqual(deltaApplied, 0)) {
    return prevLayout;
  }
  {
    const pivotIndex = delta < 0 ? pivotIndices[1] : pivotIndices[0];
    const unsafeSize = prevLayout[pivotIndex] + deltaApplied;
    const safeSize = resizePanel({
      groupSizePixels,
      panelConstraints,
      panelIndex: pivotIndex,
      size: unsafeSize
    });

    // Adjust the pivot panel before, but only by the amount that surrounding panels were able to shrink/contract.
    nextLayout[pivotIndex] = safeSize;

    // Edge case where expanding or contracting one panel caused another one to change collapsed state
    if (!fuzzyNumbersEqual(safeSize, unsafeSize)) {
      let deltaRemaining = unsafeSize - safeSize;
      const pivotIndex = delta < 0 ? pivotIndices[1] : pivotIndices[0];
      let index = pivotIndex;
      while (index >= 0 && index < panelConstraints.length) {
        const prevSize = nextLayout[index];
        const unsafeSize = prevSize + deltaRemaining;
        const safeSize = resizePanel({
          groupSizePixels,
          panelConstraints,
          panelIndex: index,
          size: unsafeSize
        });
        if (!fuzzyNumbersEqual(prevSize, safeSize)) {
          deltaRemaining -= safeSize - prevSize;
          nextLayout[index] = safeSize;
        }
        if (fuzzyNumbersEqual(deltaRemaining, 0)) {
          break;
        }
        if (delta > 0) {
          index--;
        } else {
          index++;
        }
      }

      // If we can't redistribute, this layout is invalid;
      // There may be an incremental layout that is valid though
      if (!fuzzyNumbersEqual(deltaRemaining, 0)) {
        try {
          return adjustLayoutByDelta({
            delta: delta < 0 ? delta + 1 : delta - 1,
            groupSizePixels,
            layout: prevLayout,
            panelConstraints,
            pivotIndices,
            trigger
          });
        } catch (error) {
          if (error instanceof RangeError) {
            console.error(`Could not apply delta ${delta} to layout`);
            return prevLayout;
          }
        } finally {
        }
      }
    }
  }
  return nextLayout;
}

function assert(expectedCondition, message = "Assertion failed!") {
  if (!expectedCondition) {
    console.error(message);
    throw Error(message);
  }
}

function getPercentageSizeFromMixedSizes({
  sizePercentage,
  sizePixels
}, groupSizePixels) {
  if (sizePercentage != null) {
    return sizePercentage;
  } else if (sizePixels != null) {
    return convertPixelsToPercentage(sizePixels, groupSizePixels);
  }
  return undefined;
}

function calculateAriaValues({
  groupSizePixels,
  layout,
  panelsArray,
  pivotIndices
}) {
  let currentMinSize = 0;
  let currentMaxSize = 100;
  let totalMinSize = 0;
  let totalMaxSize = 0;

  // A panel's effective min/max sizes also need to account for other panel's sizes.
  panelsArray.forEach((panelData, index) => {
    var _getPercentageSizeFro, _getPercentageSizeFro2;
    const {
      constraints
    } = panelData;
    const {
      maxSizePercentage,
      maxSizePixels,
      minSizePercentage,
      minSizePixels
    } = constraints;
    const minSize = (_getPercentageSizeFro = getPercentageSizeFromMixedSizes({
      sizePercentage: minSizePercentage,
      sizePixels: minSizePixels
    }, groupSizePixels)) !== null && _getPercentageSizeFro !== void 0 ? _getPercentageSizeFro : 0;
    const maxSize = (_getPercentageSizeFro2 = getPercentageSizeFromMixedSizes({
      sizePercentage: maxSizePercentage,
      sizePixels: maxSizePixels
    }, groupSizePixels)) !== null && _getPercentageSizeFro2 !== void 0 ? _getPercentageSizeFro2 : 100;
    if (index === pivotIndices[0]) {
      currentMinSize = minSize;
      currentMaxSize = maxSize;
    } else {
      totalMinSize += minSize;
      totalMaxSize += maxSize;
    }
  });
  const valueMax = Math.min(currentMaxSize, 100 - totalMinSize);
  const valueMin = Math.max(currentMinSize, 100 - totalMaxSize);
  const valueNow = layout[pivotIndices[0]];
  return {
    valueMax,
    valueMin,
    valueNow
  };
}

function getResizeHandleElementsForGroup(groupId) {
  return Array.from(document.querySelectorAll(`[data-panel-resize-handle-id][data-panel-group-id="${groupId}"]`));
}

function getResizeHandleElementIndex(groupId, id) {
  const handles = getResizeHandleElementsForGroup(groupId);
  const index = handles.findIndex(handle => handle.getAttribute("data-panel-resize-handle-id") === id);
  return index !== null && index !== void 0 ? index : null;
}

function determinePivotIndices(groupId, dragHandleId) {
  const index = getResizeHandleElementIndex(groupId, dragHandleId);
  return index != null ? [index, index + 1] : [-1, -1];
}

function getPanelGroupElement(id) {
  const element = document.querySelector(`[data-panel-group][data-panel-group-id="${id}"]`);
  if (element) {
    return element;
  }
  return null;
}

function calculateAvailablePanelSizeInPixels(groupId) {
  const panelGroupElement = getPanelGroupElement(groupId);
  if (panelGroupElement == null) {
    return NaN;
  }
  const direction = panelGroupElement.getAttribute("data-panel-group-direction");
  const resizeHandles = getResizeHandleElementsForGroup(groupId);
  if (direction === "horizontal") {
    return panelGroupElement.offsetWidth - resizeHandles.reduce((accumulated, handle) => {
      return accumulated + handle.offsetWidth;
    }, 0);
  } else {
    return panelGroupElement.offsetHeight - resizeHandles.reduce((accumulated, handle) => {
      return accumulated + handle.offsetHeight;
    }, 0);
  }
}

function getAvailableGroupSizePixels(groupId) {
  const panelGroupElement = getPanelGroupElement(groupId);
  if (panelGroupElement == null) {
    return NaN;
  }
  const direction = panelGroupElement.getAttribute("data-panel-group-direction");
  const resizeHandles = getResizeHandleElementsForGroup(groupId);
  if (direction === "horizontal") {
    return panelGroupElement.offsetWidth - resizeHandles.reduce((accumulated, handle) => {
      return accumulated + handle.offsetWidth;
    }, 0);
  } else {
    return panelGroupElement.offsetHeight - resizeHandles.reduce((accumulated, handle) => {
      return accumulated + handle.offsetHeight;
    }, 0);
  }
}

function getResizeHandleElement(id) {
  const element = document.querySelector(`[data-panel-resize-handle-id="${id}"]`);
  if (element) {
    return element;
  }
  return null;
}

function getResizeHandlePanelIds(groupId, handleId, panelsArray) {
  var _panelsArray$index$id, _panelsArray$index, _panelsArray$id, _panelsArray;
  const handle = getResizeHandleElement(handleId);
  const handles = getResizeHandleElementsForGroup(groupId);
  const index = handle ? handles.indexOf(handle) : -1;
  const idBefore = (_panelsArray$index$id = (_panelsArray$index = panelsArray[index]) === null || _panelsArray$index === void 0 ? void 0 : _panelsArray$index.id) !== null && _panelsArray$index$id !== void 0 ? _panelsArray$index$id : null;
  const idAfter = (_panelsArray$id = (_panelsArray = panelsArray[index + 1]) === null || _panelsArray === void 0 ? void 0 : _panelsArray.id) !== null && _panelsArray$id !== void 0 ? _panelsArray$id : null;
  return [idBefore, idAfter];
}

// https://www.w3.org/WAI/ARIA/apg/patterns/windowsplitter/

function useWindowSplitterPanelGroupBehavior({
  committedValuesRef,
  groupId,
  layout,
  panelDataArray,
  setLayout
}) {
  useRef({
    didWarnAboutMissingResizeHandle: false
  });
  useIsomorphicLayoutEffect(() => {
    const groupSizePixels = calculateAvailablePanelSizeInPixels(groupId);
    const resizeHandleElements = getResizeHandleElementsForGroup(groupId);
    for (let index = 0; index < panelDataArray.length - 1; index++) {
      const {
        valueMax,
        valueMin,
        valueNow
      } = calculateAriaValues({
        groupSizePixels,
        layout,
        panelsArray: panelDataArray,
        pivotIndices: [index, index + 1]
      });
      const resizeHandleElement = resizeHandleElements[index];
      if (resizeHandleElement == null) ; else {
        resizeHandleElement.setAttribute("aria-controls", panelDataArray[index].id);
        resizeHandleElement.setAttribute("aria-valuemax", "" + Math.round(valueMax));
        resizeHandleElement.setAttribute("aria-valuemin", "" + Math.round(valueMin));
        resizeHandleElement.setAttribute("aria-valuenow", "" + Math.round(valueNow));
      }
    }
    return () => {
      resizeHandleElements.forEach((resizeHandleElement, index) => {
        resizeHandleElement.removeAttribute("aria-controls");
        resizeHandleElement.removeAttribute("aria-valuemax");
        resizeHandleElement.removeAttribute("aria-valuemin");
        resizeHandleElement.removeAttribute("aria-valuenow");
      });
    };
  }, [groupId, layout, panelDataArray]);
  useEffect(() => {
    const {
      direction,
      panelDataArray
    } = committedValuesRef.current;
    const groupElement = getPanelGroupElement(groupId);
    assert(groupElement != null, `No group found for id "${groupId}"`);
    const {
      height,
      width
    } = groupElement.getBoundingClientRect();
    const handles = getResizeHandleElementsForGroup(groupId);
    const cleanupFunctions = handles.map(handle => {
      const handleId = handle.getAttribute("data-panel-resize-handle-id");
      const [idBefore, idAfter] = getResizeHandlePanelIds(groupId, handleId, panelDataArray);
      if (idBefore == null || idAfter == null) {
        return () => {};
      }
      const onKeyDown = event => {
        if (event.defaultPrevented) {
          return;
        }
        switch (event.key) {
          case "Enter":
            {
              event.preventDefault();
              const index = panelDataArray.findIndex(panelData => panelData.id === idBefore);
              if (index >= 0) {
                const panelData = panelDataArray[index];
                const size = layout[index];
                if (size != null) {
                  var _getPercentageSizeFro;
                  const groupSizePixels = getAvailableGroupSizePixels(groupId);
                  const minSize = (_getPercentageSizeFro = getPercentageSizeFromMixedSizes({
                    sizePercentage: panelData.constraints.minSizePercentage,
                    sizePixels: panelData.constraints.minSizePixels
                  }, groupSizePixels)) !== null && _getPercentageSizeFro !== void 0 ? _getPercentageSizeFro : 0;
                  let delta = 0;
                  if (size.toPrecision(PRECISION) <= minSize.toPrecision(PRECISION)) {
                    delta = direction === "horizontal" ? width : height;
                  } else {
                    delta = -(direction === "horizontal" ? width : height);
                  }
                  const nextLayout = adjustLayoutByDelta({
                    delta,
                    groupSizePixels,
                    layout,
                    panelConstraints: panelDataArray.map(panelData => panelData.constraints),
                    pivotIndices: determinePivotIndices(groupId, handleId),
                    trigger: "keyboard"
                  });
                  if (layout !== nextLayout) {
                    setLayout(nextLayout);
                  }
                }
              }
              break;
            }
        }
      };
      handle.addEventListener("keydown", onKeyDown);
      return () => {
        handle.removeEventListener("keydown", onKeyDown);
      };
    });
    return () => {
      cleanupFunctions.forEach(cleanupFunction => cleanupFunction());
    };
  }, [committedValuesRef, groupId, layout, panelDataArray, setLayout]);
}

function areEqual(arrayA, arrayB) {
  if (arrayA.length !== arrayB.length) {
    return false;
  }
  for (let index = 0; index < arrayA.length; index++) {
    if (arrayA[index] !== arrayB[index]) {
      return false;
    }
  }
  return true;
}

function isKeyDown(event) {
  return event.type === "keydown";
}
function isMouseEvent(event) {
  return event.type.startsWith("mouse");
}
function isTouchEvent(event) {
  return event.type.startsWith("touch");
}

function getResizeEventCursorPosition(direction, event) {
  const isHorizontal = direction === "horizontal";
  if (isMouseEvent(event)) {
    return isHorizontal ? event.clientX : event.clientY;
  } else if (isTouchEvent(event)) {
    const firstTouch = event.touches[0];
    return isHorizontal ? firstTouch.screenX : firstTouch.screenY;
  } else {
    throw Error(`Unsupported event type "${event.type}"`);
  }
}

function calculateDragOffsetPercentage(event, dragHandleId, direction, initialDragState) {
  const isHorizontal = direction === "horizontal";
  const handleElement = getResizeHandleElement(dragHandleId);
  const groupId = handleElement.getAttribute("data-panel-group-id");
  let {
    initialCursorPosition
  } = initialDragState;
  const cursorPosition = getResizeEventCursorPosition(direction, event);
  const groupElement = getPanelGroupElement(groupId);
  const groupRect = groupElement.getBoundingClientRect();
  const groupSizeInPixels = isHorizontal ? groupRect.width : groupRect.height;
  const offsetPixels = cursorPosition - initialCursorPosition;
  const offsetPercentage = offsetPixels / groupSizeInPixels * 100;
  return offsetPercentage;
}

// https://developer.mozilla.org/en-US/docs/Web/API/MouseEvent/movementX
function calculateDeltaPercentage(event, groupId, dragHandleId, direction, initialDragState, keyboardResizeByOptions) {
  if (isKeyDown(event)) {
    const isHorizontal = direction === "horizontal";
    const groupElement = getPanelGroupElement(groupId);
    const rect = groupElement.getBoundingClientRect();
    const groupSizeInPixels = isHorizontal ? rect.width : rect.height;
    let delta = 0;
    if (event.shiftKey) {
      delta = 100;
    } else if (keyboardResizeByOptions.percentage != null) {
      delta = keyboardResizeByOptions.percentage;
    } else if (keyboardResizeByOptions.pixels != null) {
      delta = keyboardResizeByOptions.pixels / groupSizeInPixels;
    } else {
      delta = 10;
    }
    let movement = 0;
    switch (event.key) {
      case "ArrowDown":
        movement = isHorizontal ? 0 : delta;
        break;
      case "ArrowLeft":
        movement = isHorizontal ? -delta : 0;
        break;
      case "ArrowRight":
        movement = isHorizontal ? delta : 0;
        break;
      case "ArrowUp":
        movement = isHorizontal ? 0 : -delta;
        break;
      case "End":
        movement = 100;
        break;
      case "Home":
        movement = -100;
        break;
    }
    return movement;
  } else {
    return calculateDragOffsetPercentage(event, dragHandleId, direction, initialDragState);
  }
}

function calculateUnsafeDefaultLayout({
  groupSizePixels,
  panelDataArray
}) {
  const layout = Array(panelDataArray.length);
  const panelDataConstraints = panelDataArray.map(panelData => panelData.constraints);
  let numPanelsWithSizes = 0;
  let remainingSize = 100;

  // Distribute default sizes first
  for (let index = 0; index < panelDataArray.length; index++) {
    const {
      defaultSizePercentage
    } = computePercentagePanelConstraints(panelDataConstraints, index, groupSizePixels);
    if (defaultSizePercentage != null) {
      numPanelsWithSizes++;
      layout[index] = defaultSizePercentage;
      remainingSize -= defaultSizePercentage;
    }
  }

  // Remaining size should be distributed evenly between panels without default sizes
  for (let index = 0; index < panelDataArray.length; index++) {
    const {
      defaultSizePercentage
    } = computePercentagePanelConstraints(panelDataConstraints, index, groupSizePixels);
    if (defaultSizePercentage != null) {
      continue;
    }
    const numRemainingPanels = panelDataArray.length - numPanelsWithSizes;
    const size = remainingSize / numRemainingPanels;
    numPanelsWithSizes++;
    layout[index] = size;
    remainingSize -= size;
  }
  return layout;
}

function convertPercentageToPixels(percentage, groupSizePixels) {
  return percentage / 100 * groupSizePixels;
}

// Layout should be pre-converted into percentages
function callPanelCallbacks(groupId, panelsArray, layout, panelIdToLastNotifiedMixedSizesMap) {
  const groupSizePixels = calculateAvailablePanelSizeInPixels(groupId);
  layout.forEach((sizePercentage, index) => {
    const panelData = panelsArray[index];
    if (!panelData) {
      // Handle initial mount (when panels are registered too late to be in the panels array)
      // The subsequent render+effects will handle the resize notification
      return;
    }
    const {
      callbacks,
      constraints,
      id: panelId
    } = panelData;
    const {
      collapsible
    } = constraints;
    const mixedSizes = {
      sizePercentage,
      sizePixels: convertPercentageToPixels(sizePercentage, groupSizePixels)
    };
    const lastNotifiedMixedSizes = panelIdToLastNotifiedMixedSizesMap[panelId];
    if (lastNotifiedMixedSizes == null || mixedSizes.sizePercentage !== lastNotifiedMixedSizes.sizePercentage || mixedSizes.sizePixels !== lastNotifiedMixedSizes.sizePixels) {
      panelIdToLastNotifiedMixedSizesMap[panelId] = mixedSizes;
      const {
        onCollapse,
        onExpand,
        onResize
      } = callbacks;
      if (onResize) {
        onResize(mixedSizes, lastNotifiedMixedSizes);
      }
      if (collapsible && (onCollapse || onExpand)) {
        var _getPercentageSizeFro;
        const collapsedSize = (_getPercentageSizeFro = getPercentageSizeFromMixedSizes({
          sizePercentage: constraints.collapsedSizePercentage,
          sizePixels: constraints.collapsedSizePixels
        }, groupSizePixels)) !== null && _getPercentageSizeFro !== void 0 ? _getPercentageSizeFro : 0;
        const size = getPercentageSizeFromMixedSizes(mixedSizes, groupSizePixels);
        if (onExpand && (lastNotifiedMixedSizes == null || lastNotifiedMixedSizes.sizePercentage === collapsedSize) && size !== collapsedSize) {
          onExpand();
        }
        if (onCollapse && (lastNotifiedMixedSizes == null || lastNotifiedMixedSizes.sizePercentage !== collapsedSize) && size === collapsedSize) {
          onCollapse();
        }
      }
    }
  });
}

function compareLayouts(a, b) {
  if (a.length !== b.length) {
    return false;
  } else {
    for (let index = 0; index < a.length; index++) {
      if (a[index] != b[index]) {
        return false;
      }
    }
  }
  return true;
}

// This method returns a number between 1 and 100 representing

// the % of the group's overall space this panel should occupy.
function computePanelFlexBoxStyle({
  dragState,
  layout,
  panelData,
  panelIndex,
  precision = 3
}) {
  const size = layout[panelIndex];
  let flexGrow;
  if (panelData.length === 1) {
    flexGrow = "100";
  } else if (size == null) {
    flexGrow = "0";
  } else {
    flexGrow = size.toPrecision(precision);
  }
  return {
    flexBasis: 0,
    flexGrow,
    flexShrink: 1,
    // Without this, Panel sizes may be unintentionally overridden by their content
    overflow: "hidden",
    // Disable pointer events inside of a panel during resize
    // This avoid edge cases like nested iframes
    pointerEvents: dragState !== null ? "none" : undefined
  };
}

let currentState = null;
let element = null;
function getCursorStyle(state) {
  switch (state) {
    case "horizontal":
      return "ew-resize";
    case "horizontal-max":
      return "w-resize";
    case "horizontal-min":
      return "e-resize";
    case "vertical":
      return "ns-resize";
    case "vertical-max":
      return "n-resize";
    case "vertical-min":
      return "s-resize";
  }
}
function resetGlobalCursorStyle() {
  if (element !== null) {
    document.head.removeChild(element);
    currentState = null;
    element = null;
  }
}
function setGlobalCursorStyle(state) {
  if (currentState === state) {
    return;
  }
  currentState = state;
  const style = getCursorStyle(state);
  if (element === null) {
    element = document.createElement("style");
    document.head.appendChild(element);
  }
  element.innerHTML = `*{cursor: ${style}!important;}`;
}

function debounce(callback, durationMs = 10) {
  let timeoutId = null;
  let callable = (...args) => {
    if (timeoutId !== null) {
      clearTimeout(timeoutId);
    }
    timeoutId = setTimeout(() => {
      callback(...args);
    }, durationMs);
  };
  return callable;
}

// PanelGroup might be rendering in a server-side environment where localStorage is not available
// or on a browser with cookies/storage disabled.
// In either case, this function avoids accessing localStorage until needed,
// and avoids throwing user-visible errors.
function initializeDefaultStorage(storageObject) {
  try {
    if (typeof localStorage !== "undefined") {
      // Bypass this check for future calls
      storageObject.getItem = name => {
        return localStorage.getItem(name);
      };
      storageObject.setItem = (name, value) => {
        localStorage.setItem(name, value);
      };
    } else {
      throw new Error("localStorage not supported in this environment");
    }
  } catch (error) {
    console.error(error);
    storageObject.getItem = () => null;
    storageObject.setItem = () => {};
  }
}

// Note that Panel ids might be user-provided (stable) or useId generated (non-deterministic)
// so they should not be used as part of the serialization key.
// Using the min/max size attributes should work well enough as a backup.
// Pre-sorting by minSize allows remembering layouts even if panels are re-ordered/dragged.
function getSerializationKey(panels) {
  return panels.map(panel => {
    const {
      constraints,
      id,
      idIsFromProps,
      order
    } = panel;
    if (idIsFromProps) {
      return id;
    } else {
      return `${order}:${JSON.stringify(constraints)}`;
    }
  }).sort((a, b) => a.localeCompare(b)).join(",");
}
function loadSerializedPanelGroupState(autoSaveId, storage) {
  try {
    const serialized = storage.getItem(`PanelGroup:sizes:${autoSaveId}`);
    if (serialized) {
      const parsed = JSON.parse(serialized);
      if (typeof parsed === "object" && parsed != null) {
        return parsed;
      }
    }
  } catch (error) {}
  return null;
}
function loadPanelLayout(autoSaveId, panels, storage) {
  const state = loadSerializedPanelGroupState(autoSaveId, storage);
  if (state) {
    var _state$key;
    const key = getSerializationKey(panels);
    return (_state$key = state[key]) !== null && _state$key !== void 0 ? _state$key : null;
  }
  return null;
}
function savePanelGroupLayout(autoSaveId, panels, sizes, storage) {
  const key = getSerializationKey(panels);
  const state = loadSerializedPanelGroupState(autoSaveId, storage) || {};
  state[key] = sizes;
  try {
    storage.setItem(`PanelGroup:sizes:${autoSaveId}`, JSON.stringify(state));
  } catch (error) {
    console.error(error);
  }
}

function shouldMonitorPixelBasedConstraints(constraints) {
  return constraints.some(constraints => {
    return constraints.collapsedSizePixels !== undefined || constraints.maxSizePixels !== undefined || constraints.minSizePixels !== undefined;
  });
}

// All units must be in percentages; pixel values should be pre-converted
function validatePanelGroupLayout({
  groupSizePixels,
  layout: prevLayout,
  panelConstraints
}) {
  const nextLayout = [...prevLayout];

  // Validate layout expectations
  if (nextLayout.length !== panelConstraints.length) {
    throw Error(`Invalid ${panelConstraints.length} panel layout: ${nextLayout.map(size => `${size}%`).join(", ")}`);
  } else if (!fuzzyNumbersEqual(nextLayout.reduce((accumulated, current) => accumulated + current, 0), 100)) ;
  let remainingSize = 0;

  // First pass: Validate the proposed layout given each panel's constraints
  for (let index = 0; index < panelConstraints.length; index++) {
    const unsafeSize = nextLayout[index];
    const safeSize = resizePanel({
      groupSizePixels,
      panelConstraints,
      panelIndex: index,
      size: unsafeSize
    });
    if (unsafeSize != safeSize) {
      remainingSize += unsafeSize - safeSize;
      nextLayout[index] = safeSize;
    }
  }

  // If there is additional, left over space, assign it to any panel(s) that permits it
  // (It's not worth taking multiple additional passes to evenly distribute)
  if (!fuzzyNumbersEqual(remainingSize, 0)) {
    for (let index = 0; index < panelConstraints.length; index++) {
      const prevSize = nextLayout[index];
      const unsafeSize = prevSize + remainingSize;
      const safeSize = resizePanel({
        groupSizePixels,
        panelConstraints,
        panelIndex: index,
        size: unsafeSize
      });
      if (prevSize !== safeSize) {
        remainingSize -= safeSize - prevSize;
        nextLayout[index] = safeSize;

        // Once we've used up the remainder, bail
        if (fuzzyNumbersEqual(remainingSize, 0)) {
          break;
        }
      }
    }
  }
  return nextLayout;
}

const LOCAL_STORAGE_DEBOUNCE_INTERVAL = 100;
const defaultStorage = {
  getItem: name => {
    initializeDefaultStorage(defaultStorage);
    return defaultStorage.getItem(name);
  },
  setItem: (name, value) => {
    initializeDefaultStorage(defaultStorage);
    defaultStorage.setItem(name, value);
  }
};
const debounceMap = {};
function PanelGroupWithForwardedRef({
  autoSaveId,
  children,
  className: classNameFromProps = "",
  direction,
  forwardedRef,
  id: idFromProps,
  onLayout = null,
  keyboardResizeByPercentage = null,
  keyboardResizeByPixels = null,
  storage = defaultStorage,
  style: styleFromProps,
  tagName: Type = "div"
}) {
  const groupId = useUniqueId(idFromProps);
  const [dragState, setDragState] = useState(null);
  const [layout, setLayout] = useState([]);
  const [panelDataArray, setPanelDataArray] = useState([]);
  const panelIdToLastNotifiedMixedSizesMapRef = useRef({});
  const panelSizeBeforeCollapseRef = useRef(new Map());
  const prevDeltaRef = useRef(0);
  const committedValuesRef = useRef({
    direction,
    dragState,
    id: groupId,
    keyboardResizeByPercentage,
    keyboardResizeByPixels,
    layout,
    onLayout,
    panelDataArray
  });
  useRef({
    didLogIdAndOrderWarning: false,
    didLogPanelConstraintsWarning: false,
    prevPanelIds: []
  });
  useImperativeHandle(forwardedRef, () => ({
    getId: () => committedValuesRef.current.id,
    getLayout: () => {
      const {
        id: groupId,
        layout
      } = committedValuesRef.current;
      const groupSizePixels = calculateAvailablePanelSizeInPixels(groupId);
      return layout.map(sizePercentage => {
        return {
          sizePercentage,
          sizePixels: convertPercentageToPixels(sizePercentage, groupSizePixels)
        };
      });
    },
    setLayout: mixedSizes => {
      const {
        id: groupId,
        layout: prevLayout,
        onLayout,
        panelDataArray
      } = committedValuesRef.current;
      const groupSizePixels = calculateAvailablePanelSizeInPixels(groupId);
      const unsafeLayout = mixedSizes.map(mixedSize => getPercentageSizeFromMixedSizes(mixedSize, groupSizePixels));
      const safeLayout = validatePanelGroupLayout({
        groupSizePixels,
        layout: unsafeLayout,
        panelConstraints: panelDataArray.map(panelData => panelData.constraints)
      });
      if (!areEqual(prevLayout, safeLayout)) {
        setLayout(safeLayout);
        if (onLayout) {
          onLayout(safeLayout.map(sizePercentage => ({
            sizePercentage,
            sizePixels: convertPercentageToPixels(sizePercentage, groupSizePixels)
          })));
        }
        callPanelCallbacks(groupId, panelDataArray, safeLayout, panelIdToLastNotifiedMixedSizesMapRef.current);
      }
    }
  }), []);
  useIsomorphicLayoutEffect(() => {
    committedValuesRef.current.direction = direction;
    committedValuesRef.current.dragState = dragState;
    committedValuesRef.current.id = groupId;
    committedValuesRef.current.layout = layout;
    committedValuesRef.current.onLayout = onLayout;
    committedValuesRef.current.panelDataArray = panelDataArray;
  });
  useWindowSplitterPanelGroupBehavior({
    committedValuesRef,
    groupId,
    layout,
    panelDataArray,
    setLayout
  });
  useEffect(() => {
    // If this panel has been configured to persist sizing information, save sizes to local storage.
    if (autoSaveId) {
      if (layout.length === 0 || layout.length !== panelDataArray.length) {
        return;
      }

      // Limit the frequency of localStorage updates.
      if (!debounceMap[autoSaveId]) {
        debounceMap[autoSaveId] = debounce(savePanelGroupLayout, LOCAL_STORAGE_DEBOUNCE_INTERVAL);
      }
      debounceMap[autoSaveId](autoSaveId, panelDataArray, layout, storage);
    }
  }, [autoSaveId, layout, panelDataArray, storage]);

  // Once all panels have registered themselves,
  // Compute the initial sizes based on default weights.
  // This assumes that panels register during initial mount (no conditional rendering)!
  useIsomorphicLayoutEffect(() => {
    const {
      id: groupId,
      layout,
      onLayout
    } = committedValuesRef.current;
    if (layout.length === panelDataArray.length) {
      // Only compute (or restore) default layout once per panel configuration.
      return;
    }

    // If this panel has been configured to persist sizing information,
    // default size should be restored from local storage if possible.
    let unsafeLayout = null;
    if (autoSaveId) {
      unsafeLayout = loadPanelLayout(autoSaveId, panelDataArray, storage);
    }
    const groupSizePixels = calculateAvailablePanelSizeInPixels(groupId);
    if (groupSizePixels <= 0) {
      // Wait until the group has rendered a non-zero size before computing layout.
      return;
    }
    if (unsafeLayout == null) {
      unsafeLayout = calculateUnsafeDefaultLayout({
        groupSizePixels,
        panelDataArray
      });
    }

    // Validate even saved layouts in case something has changed since last render
    // e.g. for pixel groups, this could be the size of the window
    const validatedLayout = validatePanelGroupLayout({
      groupSizePixels,
      layout: unsafeLayout,
      panelConstraints: panelDataArray.map(panelData => panelData.constraints)
    });
    if (!areEqual(layout, validatedLayout)) {
      setLayout(validatedLayout);
    }
    if (onLayout) {
      onLayout(validatedLayout.map(sizePercentage => ({
        sizePercentage,
        sizePixels: convertPercentageToPixels(sizePercentage, groupSizePixels)
      })));
    }
    callPanelCallbacks(groupId, panelDataArray, validatedLayout, panelIdToLastNotifiedMixedSizesMapRef.current);
  }, [autoSaveId, layout, panelDataArray, storage]);
  useIsomorphicLayoutEffect(() => {
    const constraints = panelDataArray.map(({
      constraints
    }) => constraints);
    if (!shouldMonitorPixelBasedConstraints(constraints)) {
      // Avoid the overhead of ResizeObserver if no pixel constraints require monitoring
      return;
    }
    if (typeof ResizeObserver === "undefined") {
      console.warn(`WARNING: Pixel based constraints require ResizeObserver but it is not supported by the current browser.`);
    } else {
      const resizeObserver = new ResizeObserver(() => {
        const groupSizePixels = calculateAvailablePanelSizeInPixels(groupId);
        const {
          layout: prevLayout,
          onLayout
        } = committedValuesRef.current;
        const nextLayout = validatePanelGroupLayout({
          groupSizePixels,
          layout: prevLayout,
          panelConstraints: panelDataArray.map(panelData => panelData.constraints)
        });
        if (!areEqual(prevLayout, nextLayout)) {
          setLayout(nextLayout);
          if (onLayout) {
            onLayout(nextLayout.map(sizePercentage => ({
              sizePercentage,
              sizePixels: convertPercentageToPixels(sizePercentage, groupSizePixels)
            })));
          }
          callPanelCallbacks(groupId, panelDataArray, nextLayout, panelIdToLastNotifiedMixedSizesMapRef.current);
        }
      });
      resizeObserver.observe(getPanelGroupElement(groupId));
      return () => {
        resizeObserver.disconnect();
      };
    }
  }, [groupId, panelDataArray]);

  // DEV warnings
  useEffect(() => {
  });

  // External APIs are safe to memoize via committed values ref
  const collapsePanel = useCallback(panelData => {
    const {
      layout: prevLayout,
      onLayout,
      panelDataArray
    } = committedValuesRef.current;
    if (panelData.constraints.collapsible) {
      const panelConstraintsArray = panelDataArray.map(panelData => panelData.constraints);
      const {
        collapsedSizePercentage,
        panelSizePercentage,
        pivotIndices,
        groupSizePixels
      } = panelDataHelper(groupId, panelDataArray, panelData, prevLayout);
      if (panelSizePercentage !== collapsedSizePercentage) {
        // Store size before collapse;
        // This is the size that gets restored if the expand() API is used.
        panelSizeBeforeCollapseRef.current.set(panelData.id, panelSizePercentage);
        const isLastPanel = panelDataArray.indexOf(panelData) === panelDataArray.length - 1;
        const delta = isLastPanel ? panelSizePercentage - collapsedSizePercentage : collapsedSizePercentage - panelSizePercentage;
        const nextLayout = adjustLayoutByDelta({
          delta,
          groupSizePixels,
          layout: prevLayout,
          panelConstraints: panelConstraintsArray,
          pivotIndices,
          trigger: "imperative-api"
        });
        if (!compareLayouts(prevLayout, nextLayout)) {
          setLayout(nextLayout);
          if (onLayout) {
            onLayout(nextLayout.map(sizePercentage => ({
              sizePercentage,
              sizePixels: convertPercentageToPixels(sizePercentage, groupSizePixels)
            })));
          }
          callPanelCallbacks(groupId, panelDataArray, nextLayout, panelIdToLastNotifiedMixedSizesMapRef.current);
        }
      }
    }
  }, [groupId]);

  // External APIs are safe to memoize via committed values ref
  const expandPanel = useCallback(panelData => {
    const {
      layout: prevLayout,
      onLayout,
      panelDataArray
    } = committedValuesRef.current;
    if (panelData.constraints.collapsible) {
      const panelConstraintsArray = panelDataArray.map(panelData => panelData.constraints);
      const {
        collapsedSizePercentage,
        panelSizePercentage,
        minSizePercentage,
        pivotIndices,
        groupSizePixels
      } = panelDataHelper(groupId, panelDataArray, panelData, prevLayout);
      if (panelSizePercentage === collapsedSizePercentage) {
        // Restore this panel to the size it was before it was collapsed, if possible.
        const prevPanelSizePercentage = panelSizeBeforeCollapseRef.current.get(panelData.id);
        const baseSizePercentage = prevPanelSizePercentage != null ? prevPanelSizePercentage : minSizePercentage;
        const isLastPanel = panelDataArray.indexOf(panelData) === panelDataArray.length - 1;
        const delta = isLastPanel ? panelSizePercentage - baseSizePercentage : baseSizePercentage - panelSizePercentage;
        const nextLayout = adjustLayoutByDelta({
          delta,
          groupSizePixels,
          layout: prevLayout,
          panelConstraints: panelConstraintsArray,
          pivotIndices,
          trigger: "imperative-api"
        });
        if (!compareLayouts(prevLayout, nextLayout)) {
          setLayout(nextLayout);
          if (onLayout) {
            onLayout(nextLayout.map(sizePercentage => ({
              sizePercentage,
              sizePixels: convertPercentageToPixels(sizePercentage, groupSizePixels)
            })));
          }
          callPanelCallbacks(groupId, panelDataArray, nextLayout, panelIdToLastNotifiedMixedSizesMapRef.current);
        }
      }
    }
  }, [groupId]);

  // External APIs are safe to memoize via committed values ref
  const getPanelSize = useCallback(panelData => {
    const {
      layout,
      panelDataArray
    } = committedValuesRef.current;
    const {
      panelSizePercentage,
      panelSizePixels
    } = panelDataHelper(groupId, panelDataArray, panelData, layout);
    return {
      sizePercentage: panelSizePercentage,
      sizePixels: panelSizePixels
    };
  }, [groupId]);

  // This API should never read from committedValuesRef
  const getPanelStyle = useCallback(panelData => {
    const panelIndex = panelDataArray.indexOf(panelData);
    return computePanelFlexBoxStyle({
      dragState,
      layout,
      panelData: panelDataArray,
      panelIndex
    });
  }, [dragState, layout, panelDataArray]);

  // External APIs are safe to memoize via committed values ref
  const isPanelCollapsed = useCallback(panelData => {
    const {
      layout,
      panelDataArray
    } = committedValuesRef.current;
    const {
      collapsedSizePercentage,
      collapsible,
      panelSizePercentage
    } = panelDataHelper(groupId, panelDataArray, panelData, layout);
    return collapsible === true && panelSizePercentage === collapsedSizePercentage;
  }, [groupId]);

  // External APIs are safe to memoize via committed values ref
  const isPanelExpanded = useCallback(panelData => {
    const {
      layout,
      panelDataArray
    } = committedValuesRef.current;
    const {
      collapsedSizePercentage,
      collapsible,
      panelSizePercentage
    } = panelDataHelper(groupId, panelDataArray, panelData, layout);
    return !collapsible || panelSizePercentage > collapsedSizePercentage;
  }, [groupId]);
  const registerPanel = useCallback(panelData => {
    setPanelDataArray(prevPanelDataArray => {
      const nextPanelDataArray = [...prevPanelDataArray, panelData];
      return nextPanelDataArray.sort((panelA, panelB) => {
        const orderA = panelA.order;
        const orderB = panelB.order;
        if (orderA == null && orderB == null) {
          return 0;
        } else if (orderA == null) {
          return -1;
        } else if (orderB == null) {
          return 1;
        } else {
          return orderA - orderB;
        }
      });
    });
  }, []);
  const registerResizeHandle = useCallback(dragHandleId => {
    return function resizeHandler(event) {
      event.preventDefault();
      const {
        direction,
        dragState,
        id: groupId,
        keyboardResizeByPercentage,
        keyboardResizeByPixels,
        onLayout,
        panelDataArray,
        layout: prevLayout
      } = committedValuesRef.current;
      const {
        initialLayout
      } = dragState !== null && dragState !== void 0 ? dragState : {};
      const pivotIndices = determinePivotIndices(groupId, dragHandleId);
      let delta = calculateDeltaPercentage(event, groupId, dragHandleId, direction, dragState, {
        percentage: keyboardResizeByPercentage,
        pixels: keyboardResizeByPixels
      });
      if (delta === 0) {
        return;
      }

      // Support RTL layouts
      const isHorizontal = direction === "horizontal";
      if (document.dir === "rtl" && isHorizontal) {
        delta = -delta;
      }
      const groupSizePixels = calculateAvailablePanelSizeInPixels(groupId);
      const panelConstraints = panelDataArray.map(panelData => panelData.constraints);
      const nextLayout = adjustLayoutByDelta({
        delta,
        groupSizePixels,
        layout: initialLayout !== null && initialLayout !== void 0 ? initialLayout : prevLayout,
        panelConstraints,
        pivotIndices,
        trigger: isKeyDown(event) ? "keyboard" : "mouse-or-touch"
      });
      const layoutChanged = !compareLayouts(prevLayout, nextLayout);

      // Only update the cursor for layout changes triggered by touch/mouse events (not keyboard)
      // Update the cursor even if the layout hasn't changed (we may need to show an invalid cursor state)
      if (isMouseEvent(event) || isTouchEvent(event)) {
        // Watch for multiple subsequent deltas; this might occur for tiny cursor movements.
        // In this case, Panel sizes might not change–
        // but updating cursor in this scenario would cause a flicker.
        if (prevDeltaRef.current != delta) {
          prevDeltaRef.current = delta;
          if (!layoutChanged) {
            // If the pointer has moved too far to resize the panel any further,
            // update the cursor style for a visual clue.
            // This mimics VS Code behavior.

            if (isHorizontal) {
              setGlobalCursorStyle(delta < 0 ? "horizontal-min" : "horizontal-max");
            } else {
              setGlobalCursorStyle(delta < 0 ? "vertical-min" : "vertical-max");
            }
          } else {
            // Reset the cursor style to the the normal resize cursor.
            setGlobalCursorStyle(isHorizontal ? "horizontal" : "vertical");
          }
        }
      }
      if (layoutChanged) {
        setLayout(nextLayout);
        if (onLayout) {
          onLayout(nextLayout.map(sizePercentage => ({
            sizePercentage,
            sizePixels: convertPercentageToPixels(sizePercentage, groupSizePixels)
          })));
        }
        callPanelCallbacks(groupId, panelDataArray, nextLayout, panelIdToLastNotifiedMixedSizesMapRef.current);
      }
    };
  }, []);

  // External APIs are safe to memoize via committed values ref
  const resizePanel = useCallback((panelData, mixedSizes) => {
    const {
      layout: prevLayout,
      onLayout,
      panelDataArray
    } = committedValuesRef.current;
    const panelConstraintsArray = panelDataArray.map(panelData => panelData.constraints);
    const {
      groupSizePixels,
      panelSizePercentage,
      pivotIndices
    } = panelDataHelper(groupId, panelDataArray, panelData, prevLayout);
    const sizePercentage = getPercentageSizeFromMixedSizes(mixedSizes, groupSizePixels);
    const isLastPanel = panelDataArray.indexOf(panelData) === panelDataArray.length - 1;
    const delta = isLastPanel ? panelSizePercentage - sizePercentage : sizePercentage - panelSizePercentage;
    const nextLayout = adjustLayoutByDelta({
      delta,
      groupSizePixels,
      layout: prevLayout,
      panelConstraints: panelConstraintsArray,
      pivotIndices,
      trigger: "imperative-api"
    });
    if (!compareLayouts(prevLayout, nextLayout)) {
      setLayout(nextLayout);
      if (onLayout) {
        onLayout(nextLayout.map(sizePercentage => ({
          sizePercentage,
          sizePixels: convertPercentageToPixels(sizePercentage, groupSizePixels)
        })));
      }
      callPanelCallbacks(groupId, panelDataArray, nextLayout, panelIdToLastNotifiedMixedSizesMapRef.current);
    }
  }, [groupId]);
  const startDragging = useCallback((dragHandleId, event) => {
    const {
      direction,
      layout
    } = committedValuesRef.current;
    const handleElement = getResizeHandleElement(dragHandleId);
    const initialCursorPosition = getResizeEventCursorPosition(direction, event);
    setDragState({
      dragHandleId,
      dragHandleRect: handleElement.getBoundingClientRect(),
      initialCursorPosition,
      initialLayout: layout
    });
  }, []);
  const stopDragging = useCallback(() => {
    resetGlobalCursorStyle();
    setDragState(null);
  }, []);
  const unregisterPanel = useCallback(panelData => {
    delete panelIdToLastNotifiedMixedSizesMapRef.current[panelData.id];
    setPanelDataArray(panelDataArray => {
      const index = panelDataArray.indexOf(panelData);
      if (index >= 0) {
        panelDataArray = [...panelDataArray];
        panelDataArray.splice(index, 1);
      }
      return panelDataArray;
    });
  }, []);
  const context = useMemo(() => ({
    collapsePanel,
    direction,
    dragState,
    expandPanel,
    getPanelSize,
    getPanelStyle,
    groupId,
    isPanelCollapsed,
    isPanelExpanded,
    registerPanel,
    registerResizeHandle,
    resizePanel,
    startDragging,
    stopDragging,
    unregisterPanel
  }), [collapsePanel, dragState, direction, expandPanel, getPanelSize, getPanelStyle, groupId, isPanelCollapsed, isPanelExpanded, registerPanel, registerResizeHandle, resizePanel, startDragging, stopDragging, unregisterPanel]);
  const style = {
    display: "flex",
    flexDirection: direction === "horizontal" ? "row" : "column",
    height: "100%",
    overflow: "hidden",
    width: "100%"
  };
  return createElement(PanelGroupContext.Provider, {
    value: context
  }, createElement(Type, {
    children,
    className: classNameFromProps,
    style: {
      ...style,
      ...styleFromProps
    },
    // CSS selectors
    "data-panel-group": "",
    "data-panel-group-direction": direction,
    "data-panel-group-id": groupId
  }));
}
const PanelGroup = forwardRef((props, ref) => createElement(PanelGroupWithForwardedRef, {
  ...props,
  forwardedRef: ref
}));
PanelGroupWithForwardedRef.displayName = "PanelGroup";
PanelGroup.displayName = "forwardRef(PanelGroup)";
function panelDataHelper(groupId, panelDataArray, panelData, layout) {
  const panelConstraintsArray = panelDataArray.map(panelData => panelData.constraints);
  const panelIndex = panelDataArray.indexOf(panelData);
  const panelConstraints = panelConstraintsArray[panelIndex];
  const groupSizePixels = calculateAvailablePanelSizeInPixels(groupId);
  const percentagePanelConstraints = computePercentagePanelConstraints(panelConstraintsArray, panelIndex, groupSizePixels);
  const isLastPanel = panelIndex === panelDataArray.length - 1;
  const pivotIndices = isLastPanel ? [panelIndex - 1, panelIndex] : [panelIndex, panelIndex + 1];
  const panelSizePercentage = layout[panelIndex];
  const panelSizePixels = convertPercentageToPixels(panelSizePercentage, groupSizePixels);
  return {
    ...percentagePanelConstraints,
    collapsible: panelConstraints.collapsible,
    panelSizePercentage,
    panelSizePixels,
    groupSizePixels,
    pivotIndices
  };
}

// https://www.w3.org/WAI/ARIA/apg/patterns/windowsplitter/

function useWindowSplitterResizeHandlerBehavior({
  disabled,
  handleId,
  resizeHandler
}) {
  useEffect(() => {
    if (disabled || resizeHandler == null) {
      return;
    }
    const handleElement = getResizeHandleElement(handleId);
    if (handleElement == null) {
      return;
    }
    const onKeyDown = event => {
      if (event.defaultPrevented) {
        return;
      }
      switch (event.key) {
        case "ArrowDown":
        case "ArrowLeft":
        case "ArrowRight":
        case "ArrowUp":
        case "End":
        case "Home":
          {
            event.preventDefault();
            resizeHandler(event);
            break;
          }
        case "F6":
          {
            event.preventDefault();
            const groupId = handleElement.getAttribute("data-panel-group-id");
            const handles = getResizeHandleElementsForGroup(groupId);
            const index = getResizeHandleElementIndex(groupId, handleId);
            assert(index !== null);
            const nextIndex = event.shiftKey ? index > 0 ? index - 1 : handles.length - 1 : index + 1 < handles.length ? index + 1 : 0;
            const nextHandle = handles[nextIndex];
            nextHandle.focus();
            break;
          }
      }
    };
    handleElement.addEventListener("keydown", onKeyDown);
    return () => {
      handleElement.removeEventListener("keydown", onKeyDown);
    };
  }, [disabled, handleId, resizeHandler]);
}

function PanelResizeHandle({
  children = null,
  className: classNameFromProps = "",
  disabled = false,
  id: idFromProps = null,
  onDragging,
  style: styleFromProps = {},
  tagName: Type = "div"
}) {
  const divElementRef = useRef(null);

  // Use a ref to guard against users passing inline props
  const callbacksRef = useRef({
    onDragging
  });
  useEffect(() => {
    callbacksRef.current.onDragging = onDragging;
  });
  const panelGroupContext = useContext(PanelGroupContext);
  if (panelGroupContext === null) {
    throw Error(`PanelResizeHandle components must be rendered within a PanelGroup container`);
  }
  const {
    direction,
    dragState,
    groupId,
    registerResizeHandle,
    startDragging,
    stopDragging
  } = panelGroupContext;
  const resizeHandleId = useUniqueId(idFromProps);
  const isDragging = (dragState === null || dragState === void 0 ? void 0 : dragState.dragHandleId) === resizeHandleId;
  const [isFocused, setIsFocused] = useState(false);
  const [resizeHandler, setResizeHandler] = useState(null);
  const stopDraggingAndBlur = useCallback(() => {
    // Clicking on the drag handle shouldn't leave it focused;
    // That would cause the PanelGroup to think it was still active.
    const div = divElementRef.current;
    div.blur();
    stopDragging();
    const {
      onDragging
    } = callbacksRef.current;
    if (onDragging) {
      onDragging(false);
    }
  }, [stopDragging]);
  useEffect(() => {
    if (disabled) {
      setResizeHandler(null);
    } else {
      const resizeHandler = registerResizeHandle(resizeHandleId);
      setResizeHandler(() => resizeHandler);
    }
  }, [disabled, resizeHandleId, registerResizeHandle]);
  useEffect(() => {
    if (disabled || resizeHandler == null || !isDragging) {
      return;
    }
    const onMove = event => {
      resizeHandler(event);
    };
    const onMouseLeave = event => {
      resizeHandler(event);
    };
    const divElement = divElementRef.current;
    const targetDocument = divElement.ownerDocument;
    targetDocument.body.addEventListener("contextmenu", stopDraggingAndBlur);
    targetDocument.body.addEventListener("mousemove", onMove);
    targetDocument.body.addEventListener("touchmove", onMove);
    targetDocument.body.addEventListener("mouseleave", onMouseLeave);
    window.addEventListener("mouseup", stopDraggingAndBlur);
    window.addEventListener("touchend", stopDraggingAndBlur);
    return () => {
      targetDocument.body.removeEventListener("contextmenu", stopDraggingAndBlur);
      targetDocument.body.removeEventListener("mousemove", onMove);
      targetDocument.body.removeEventListener("touchmove", onMove);
      targetDocument.body.removeEventListener("mouseleave", onMouseLeave);
      window.removeEventListener("mouseup", stopDraggingAndBlur);
      window.removeEventListener("touchend", stopDraggingAndBlur);
    };
  }, [direction, disabled, isDragging, resizeHandler, stopDraggingAndBlur]);
  useWindowSplitterResizeHandlerBehavior({
    disabled,
    handleId: resizeHandleId,
    resizeHandler
  });
  const style = {
    cursor: getCursorStyle(direction),
    touchAction: "none",
    userSelect: "none"
  };
  return createElement(Type, {
    children,
    className: classNameFromProps,
    onBlur: () => setIsFocused(false),
    onFocus: () => setIsFocused(true),
    onMouseDown: event => {
      startDragging(resizeHandleId, event.nativeEvent);
      const {
        onDragging
      } = callbacksRef.current;
      if (onDragging) {
        onDragging(true);
      }
    },
    onMouseUp: stopDraggingAndBlur,
    onTouchCancel: stopDraggingAndBlur,
    onTouchEnd: stopDraggingAndBlur,
    onTouchStart: event => {
      startDragging(resizeHandleId, event.nativeEvent);
      const {
        onDragging
      } = callbacksRef.current;
      if (onDragging) {
        onDragging(true);
      }
    },
    ref: divElementRef,
    role: "separator",
    style: {
      ...style,
      ...styleFromProps
    },
    tabIndex: 0,
    // CSS selectors
    "data-panel-group-direction": direction,
    "data-panel-group-id": groupId,
    "data-resize-handle": "",
    "data-resize-handle-active": isDragging ? "pointer" : isFocused ? "keyboard" : undefined,
    "data-panel-resize-handle-enabled": !disabled,
    "data-panel-resize-handle-id": resizeHandleId
  });
}
PanelResizeHandle.displayName = "PanelResizeHandle";

export { Panel, PanelGroup, PanelResizeHandle };
