import React from "react";
// eslint-disable-next-line max-len
import SelectionDropdown from "../selection_dropdown/SelectionDropdown.component";
import { HiClock } from "react-icons/hi2";
import { CandleFrequency } from "@generated/common/basic-types_pb";
import { getFrequencyLabel } from "@client/src/utils/frequencyUtils";

type Props = {
  onChange: (frequency: number) => void;
};

const ScreeningFrequencySelector = ({ onChange }: Props) => {
  const items = Object.values(CandleFrequency)
    .filter((item) => item !== 0)
    .map((item) => getFrequencyLabel(item));

  const handleSelect = (itemName: string) => {
    const selectedItem = Object.values(CandleFrequency).find(
      (item) => getFrequencyLabel(Number(item)) === itemName
    );
    if (selectedItem) {
      onChange(Number(selectedItem));
    }
  };

  return (
    <div data-testid="frequency-select">
      <SelectionDropdown
        dialogTitle={"Frequency Selector"}
        items={items}
        onSelect={handleSelect}
        Icon={<HiClock />}
        customHeight={"300px"}
        defaultItem={getFrequencyLabel(CandleFrequency.H1)}
      />
    </div>
  );
};

export default ScreeningFrequencySelector;
