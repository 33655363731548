import React, { useRef } from "react";
import {
  SheetContent,
  Content,
  Handle,
  Header,
  HeaderSection,
  HeaderTitle,
  SheetContainer,
} from "./Sheet.styles";
import { useOnClickOutside } from "@client/src/hooks/useOnClickOutside";

export type SheetProps = {
  title: string;
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactNode;
  topRightComponent?: React.ReactNode;
};

export const Sheet = ({
  title,
  isOpen,
  onClose,
  children,
  topRightComponent,
}: SheetProps) => {
  const containerRef = useRef(null);
  useOnClickOutside(containerRef, onClose);

  if (!isOpen) return null;

  return (
    <SheetContainer>
      <SheetContent data-testid="sheet" ref={containerRef}>
        <Header>
          <Handle />
          <HeaderSection>
            <HeaderTitle data-testid="sheet-title">{title}</HeaderTitle>
            {topRightComponent}
          </HeaderSection>
        </Header>
        <Content data-testid="sheet-content">{children}</Content>
      </SheetContent>
    </SheetContainer>
  );
};
