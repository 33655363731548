export const INSTRUMENTS_TO_ANALYSE = [
  "BTC-USD",
  "ETH-USD",
  "SOL-USD",
  "AVAX-USD",
  "ETH-BTC",
  "XRP-USD",
  "ADA-USD",
  "DOGE-USD",
  "LINK-USD",
  "ATOM-USD",
  "EUR-USD",
  "USD-EUR",
  "USD-JPY",
  "GBP-USD",
  "USD-GBP",
  "AUD-USD",
  "USD-CAD",
  "USD-CNY",
  "USD-CHF",
  "EUR-GBP",
  "EUR-AUD",
  "EUR-JPY",
  "USDT-USD",
  "LTC-USD",
  "AUD-JPY",
  "GBP-JPY",
  "NZD-USD",
  "EUR-USD",
  "GBP-USD",
  "AAPL",
  "TSLA",
  "GOOG",
  "GOOGL",
  "META",
  "AAL",
  "AMD",
  "XOM",
  "CVX",
  "COP",
  "SLB",
  "EOG",
  "MPC",
  "PSX",
  "PXD",
  "OXY",
  "LIN",
  "SHW",
  "FCX",
  "ECL",
  "APD",
  "NUE",
  "CTVA",
  "CAT",
  "UNP",
  "GE",
  "RTX",
  "HON",
  "BA",
  "UPS",
  "DE",
  "ETN",
  "LMT",
  "AMZN",
  "HD",
  "MCD",
  "NKE",
  "BKNG",
  "LOW",
  "TJX",
  "SBUX",
  "ABNB",
  "WMT",
  "PG",
  "COST",
  "KO",
  "PEP",
  "PM",
  "MDLZ",
  "MO",
  "CL",
  "TGT",
  "LLY",
  "UNH",
  "JNJ",
  "MRK",
  "ABBV",
  "TMO",
  "ABT",
  "DHR",
  "AMGN",
  "PFE",
  "BRK.B",
  "JPM",
  "V",
  "MA",
  "BAC",
  "WFC",
  "BX",
  "AXP",
  "MS",
  "GS",
  "MSFT",
  "NVDA",
  "AVGO",
  "ORCL",
  "CRM",
  "ADBE",
  "ACN",
  "CSCO",
  "NFLX",
  "DIS",
  "TMUS",
  "VZ",
  "CMCSA",
  "T",
  "NEE",
  "SO",
  "DUK",
  "SRE",
  "PCG",
  "AEP",
  "CEG",
  "D",
  "EXC",
  "XEL",
  "PLD",
  "AMT",
  "EQIX",
  "SPG",
  "PSA",
  "WELL",
  "CCI",
  "DLR",
  "O",
  "CSGP",
];
