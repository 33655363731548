import React from "react";
import { HiTrash, HiXMark } from "react-icons/hi2";
import { TAB_OPTIONS, useAlertContext } from "../../context/AlertContext";
import { Tabbar } from "../tabbar/Tabbar.component";
import {
  Content,
  InboxSectionContainer,
  TopRightContainer,
} from "./InboxSheet.styles";
import { Sheet } from "../sheet/Sheet.component";
import { renderSelectedTabComponent } from "./InboxSheet.helper";
import PauseAlertToggle from "../pause_alert_toggle/PauseAlertToggle.component";
import { useAuth0 } from "@auth0/auth0-react";
import Button from "../button/Button.component";

type InboxSheetProps = {
  onClose: () => void;
  isOpen: boolean;
};

const InboxSheet = ({ onClose, isOpen }: InboxSheetProps) => {
  const { selectedTab, setSelectedTab } = useAlertContext();
  const { getAccessTokenSilently } = useAuth0();

  const handleClearAll = () => {
    getAccessTokenSilently().then((token: string) => {
      fetch("/api/inbox/clear-all", {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
    });
  };

  const renderClearAllButton = () => {
    if (selectedTab !== "inbox") return null;
    return (
      <Button
        onClick={handleClearAll}
        text="Clear all alerts"
        size="xs"
        type="primary"
        leftIcon={<HiTrash />}
      />
    );
  };

  return (
    <Sheet
      isOpen={isOpen}
      title="Alerts"
      onClose={onClose}
      topRightComponent={
        <TopRightContainer>
          <Content>
            <PauseAlertToggle />
            {renderClearAllButton()}
            <HiXMark onClick={() => onClose()} />
          </Content>
        </TopRightContainer>
      }
    >
      <InboxSectionContainer>
        <Tabbar
          data={TAB_OPTIONS}
          selectedTab={selectedTab}
          onTabSelectedChange={setSelectedTab}
        />
        {renderSelectedTabComponent(selectedTab)}
      </InboxSectionContainer>
    </Sheet>
  );
};

export default InboxSheet;
