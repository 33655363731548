import { textStyles } from "@client/src/utils/textStyles";
import styled from "styled-components";

export const CookieBannerContainer = styled.div`
  position: fixed;
  bottom: 1rem;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  z-index: 1000;
  background-color: ${(props) => props.theme.instrumentDialogBg};
  padding: 0 1rem 1rem 1rem;
  width: 50%;
  border-radius: 1rem;
`;

export const Title = styled.p`
  ${textStyles.textLgBold};
`;

export const Body = styled.p`
  ${textStyles.textBase};
`;

export const PrivacyLink = styled.a`
  ${textStyles.textBase};
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: end;
  gap: 1rem;
`;
