import { CanvasRenderingTarget2D } from 'fancy-canvas';
import {
  BarData,
  Coordinate,
  DataChangedScope,
  ISeriesPrimitive,
  ISeriesPrimitivePaneRenderer,
  ISeriesPrimitivePaneView,
  LineData,
  SeriesAttachedParameter,
  SeriesDataItemTypeMap,
  SeriesType,
  Time,
} from 'lightweight-charts';
import { PluginBase } from '../../plugins/plugin-base';
import { ClosestTimeIndexFinder } from '../../plugins/helpers/closest-index';
import { colours } from '@client/src/themes';

interface BandRendererData {
  x: Coordinate | number;
  middle: Coordinate | number;
}

class BandsIndicatorPaneRenderer implements ISeriesPrimitivePaneRenderer {
  _viewData: BandViewData;
  constructor(data: BandViewData) {
    this._viewData = data;
  }
  draw() { }
  drawBackground(target: CanvasRenderingTarget2D) {
    const points: BandRendererData[] = this._viewData.data;
    target.useBitmapCoordinateSpace(scope => {
      const ctx = scope.context;
      ctx.scale(scope.horizontalPixelRatio, scope.verticalPixelRatio);

      ctx.lineWidth = this._viewData.options.lineWidth;
      ctx.setLineDash([5, 5]);
      ctx.beginPath();

      ctx.strokeStyle = this._viewData.options.midLineColor;
      const midLine = new Path2D();
      midLine.moveTo(points[0].x, points[0].middle);
      for (const point of points) {
        midLine.lineTo(point.x, points[0].middle);
      }
      ctx.stroke(midLine);
    });
  }
}

interface BandViewData {
  data: BandRendererData[];
  options: Required<BandsIndicatorOptions>;
}

class BandsIndicatorPaneView implements ISeriesPrimitivePaneView {
  _source: BandsIndicator;
  _data: BandViewData;

  constructor(source: BandsIndicator) {
    this._source = source;
    this._data = {
      data: [],
      options: this._source._options,
    };
  }

  update() {
    const series = this._source.series;
    const timeScale = this._source.chart.timeScale();
    this._data.data = this._source._bandsData.map(d => {
      return {
        x: timeScale.timeToCoordinate(d.time) ?? -100,
        middle: series.priceToCoordinate(d.middle) ?? -100,
      };
    });
  }

  renderer() {
    return new BandsIndicatorPaneRenderer(this._data);
  }
}

interface BandData {
  time: Time;
  middle: number;
}

function extractPrice(
  dataPoint: SeriesDataItemTypeMap[SeriesType]
): number | undefined {
  if ((dataPoint as BarData).close) return (dataPoint as BarData).close;
  if ((dataPoint as LineData).value) return (dataPoint as LineData).value;
  return undefined;
}

export interface BandsIndicatorOptions {
  midLineColor?: string;
  fillColor?: string;
  lineWidth?: number;
}

const defaults: Required<BandsIndicatorOptions> = {
  midLineColor: colours['sigma-gray'][400],
  fillColor: 'rgba(25, 200, 100, 0)',
  lineWidth: 1,
};

export class BandsIndicator extends PluginBase implements ISeriesPrimitive<Time> {
  _paneViews: BandsIndicatorPaneView[];
  _seriesData: SeriesDataItemTypeMap[SeriesType][] = [];
  _bandsData: BandData[] = [];
  _options: Required<BandsIndicatorOptions>;
  _timeIndices: ClosestTimeIndexFinder<{ time: number }>;

  constructor(options: BandsIndicatorOptions = {}) {
    super();
    this._options = { ...defaults, ...options };
    this._paneViews = [new BandsIndicatorPaneView(this)];
    this._timeIndices = new ClosestTimeIndexFinder([]);
  }

  updateAllViews() {
    this._paneViews.forEach(pw => pw.update());
  }

  paneViews() {
    return this._paneViews;
  }

  attached(p: SeriesAttachedParameter<Time>): void {
    super.attached(p);
    this.dataUpdated('full');
  }

  dataUpdated(scope: DataChangedScope) {
    // plugin base has fired a data changed event
    this._seriesData = JSON.parse(JSON.stringify(this.series.data()));
    this.calculateBands();
    if (scope === 'full') {
      this._timeIndices = new ClosestTimeIndexFinder(
        this._seriesData as { time: number }[]
      );
    }
  }

  _minValue: number = Number.POSITIVE_INFINITY;
  _maxValue: number = Number.NEGATIVE_INFINITY;
  calculateBands() {
    const bandData: BandData[] = new Array(this._seriesData.length);
    let index = 0;
    this._seriesData.forEach(d => {
      const price = extractPrice(d);
      if (price === undefined) return;
      const middle = 0;
      bandData[index] = {
        middle,
        time: d.time,
      };
      index += 1;
    });
    bandData.length = index;
    this._bandsData = bandData;
  }
}
