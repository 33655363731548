import React from "react";
import { MiniChartContainer } from "./Chart.style";
import { ChartData } from "@hooks/useFetchChart";
import { useChartHandler } from "@hooks/useChartHandler";
import { createRsiChart } from "@client/src/charts/rsi";
import Legend from "../legend/Legend.component";
import { useAppContext } from "@client/src/context/AppContext";

export type ChartProps = {
  chartData: ChartData;
};

const RsiChart = (props: ChartProps) => {
  const { technicals } = useAppContext();

  const [chartElemRef, legendElemRef] = useChartHandler(
    createRsiChart,
    (elem, theme) => ({
      elem,
      theme,
      frequency: props.chartData.frequency,
      technicals: props.chartData.technicals,
    }),
    props.chartData,
    []
  );

  return (
    <MiniChartContainer ref={chartElemRef} data-testid="chart">
      <Legend
        ref={legendElemRef}
        technicals={technicals.filter((technical) => technical.rsi)}
      />
    </MiniChartContainer>
  );
};

export default RsiChart;
