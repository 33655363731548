import { ScreeningMatch } from "@common/types";
import { SeriesMarker, UTCTimestamp } from "lightweight-charts";
import { MainChartState } from "./state";

export const updateMarkers = (
  state: MainChartState,
  matches: ScreeningMatch[] | null
) => {
  if (!matches || JSON.stringify(state.markers) === JSON.stringify(matches)) {
    return;
  }
  const parsedMarkers: SeriesMarker<UTCTimestamp>[] = matches.map((match) => {
    return {
      time: match.time as UTCTimestamp,
      position: "aboveBar",
      color: "#f68410",
      shape: "circle",
      text: (Number(match.tag) + 1).toString(),
    };
  });
  state.baseSeries?.setMarkers(parsedMarkers);
};
