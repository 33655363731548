import {
  addHueOffsetToColor,
  getChartIndicatorColor,
  hueOffsetFromIndex,
} from "@client/src/charts/helpers/colors/utils";
import { useAppContext } from "@client/src/context/AppContext";
import { TechnicalConfigItem } from "@common/types";

import Color from "color";
import React, { useCallback, useMemo } from "react";
import { HiPencil } from "react-icons/hi";
import { HiTrash } from "react-icons/hi2";
import { useTheme } from "styled-components";
import { Tooltip } from "../tooltip/Tooltip.component";
import {
  LegendButton,
  LegendContentContainer,
  LegendContainer,
  LegendItemContainer,
  LegendLabelText,
  TextSection,
} from "./Legend.style";

export const renderMovingAverage = (technical: TechnicalConfigItem) => {
  const { ma } = technical;
  if (!ma) return null;
  return (
    <div>
      <div>{ma.window_length}</div>
    </div>
  );
};

export const renderRsi = (technical: TechnicalConfigItem) => {
  const { rsi } = technical;
  if (!rsi) return null;
  return (
    <div>
      <div>{rsi.main_period}</div>
    </div>
  );
};

export const renderMacd = (technical: TechnicalConfigItem) => {
  const { macd } = technical;
  if (!macd) return null;
  return (
    <LegendContentContainer>
      <div>{macd.fast_period}</div>
      <div>{macd.slow_period}</div>
      <div>{macd.line_period}</div>
    </LegendContentContainer>
  );
};

const renderEditButton = (
  editTechnical: (technical: TechnicalConfigItem) => void,
  technical: TechnicalConfigItem
) => {
  if (technical.name === "Volume") return null;
  return (
    <Tooltip>
      <Tooltip.Item>
        <LegendButton
          onClick={() => {
            editTechnical(technical);
          }}
        >
          <HiPencil />
        </LegendButton>
      </Tooltip.Item>
      <Tooltip.Content text="Edit" side="top" sideOffset={5} />
    </Tooltip>
  );
};

const renderRemoveButton = (
  removeTechnical: (technical: TechnicalConfigItem) => void,
  technical: TechnicalConfigItem
) => (
  <Tooltip>
    <Tooltip.Item>
      <LegendButton
        onClick={() => {
          removeTechnical(technical);
        }}
      >
        <HiTrash />
      </LegendButton>
    </Tooltip.Item>
    <Tooltip.Content text="Remove" side="top" sideOffset={5} />
  </Tooltip>
);

const LegendItem = ({
  technical,
  removeTechnical,
  offsetValue: offsetIndex,
  editTechnical,
}: {
  technical: TechnicalConfigItem;
  offsetValue: number;
  removeTechnical: (technical: TechnicalConfigItem) => void;
  editTechnical: (technical: TechnicalConfigItem) => void;
}) => {
  const theme = useTheme();
  const color = useMemo(() => {
    const color = Color(getChartIndicatorColor(technical.name, theme));
    // calculate color offset
    // from range of technical
    return addHueOffsetToColor(color.hex(), offsetIndex);
  }, [theme]);
  return (
    <LegendItemContainer $color={color}>
      <TextSection>
        <LegendLabelText $color={color}>{technical.name}</LegendLabelText>
        {renderMovingAverage(technical)}
        {renderRsi(technical)}
        {renderMacd(technical)}
      </TextSection>
      {renderEditButton(editTechnical, technical)}
      {renderRemoveButton(removeTechnical, technical)}
    </LegendItemContainer>
  );
};

const Legend = React.forwardRef<
  HTMLDivElement,
  {
    technicals: TechnicalConfigItem[];
  }
>(({ technicals }, legendRef) => {
  const {
    removeTechnical,
    setTechnicalConfigSelected,
    setTechnicalDialogVisible,
    setTechnicalToEdit,
  } = useAppContext();
  const handleEditTechnical = useCallback((technical: TechnicalConfigItem) => {
    setTechnicalConfigSelected(technical);
    setTechnicalToEdit(technical);
    setTechnicalDialogVisible(true);
  }, []);
  return (
    <LegendContainer ref={legendRef} data-testid="legend">
      {technicals.map((technical, index) => (
        <LegendItem
          key={index}
          technical={technical}
          offsetValue={hueOffsetFromIndex(index)}
          removeTechnical={removeTechnical}
          editTechnical={handleEditTechnical}
        />
      ))}
    </LegendContainer>
  );
});

export default Legend;
