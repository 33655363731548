import React from "react";
import {
  Container,
  LogicContainer,
  Handle,
  LogicSelector,
} from "./ConditionBuilderLogicBlock.style";

type ConditionBuilderLogicBlockProps = {
  leftSide?: React.ReactNode;
  rightSide?: React.ReactNode;
  logicSelector: "and" | "or" | "not";
};

const ConditionBuilderLogicBlock = ({
  leftSide,
  rightSide,
  logicSelector,
}: ConditionBuilderLogicBlockProps) => {
  const renderLeftSide = () => {
    if (!leftSide) return null;
    return leftSide;
  };

  const renderRightSide = () => {
    if (!rightSide) return null;
    return rightSide;
  };

  const renderLogicSelector = () => {
    return <LogicSelector type={logicSelector}>
      {logicSelector.toUpperCase()}
    </LogicSelector>;
  };

  if (logicSelector === "not") {
    return (
      <Container>
        <Handle type={logicSelector} />
        <LogicContainer>
          {renderLogicSelector()}
          {renderLeftSide()}
        </LogicContainer>
      </Container>
    );
  }

  return (
    <Container>
      <Handle type={logicSelector} />
      <LogicContainer>
        {renderLeftSide()}
        {renderLogicSelector()}
        {renderRightSide()}
      </LogicContainer>
    </Container>
  );
};

export default ConditionBuilderLogicBlock;
