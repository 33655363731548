import { datadogRum } from "@datadog/browser-rum";

export const rumInit = (appId: string, token: string) => {
  // RUM won't be applied while running on dev laptop
  if(process.env.NODE_ENV === "production"){
    datadogRum.init({
      applicationId: appId,
      clientToken: token,
      site: "datadoghq.eu",
      service: "leopard",
      env: "prod",
      version: "1.0.0",
      sessionSampleRate: 100,
      sessionReplaySampleRate: 20,
      trackUserInteractions: true,
      trackFrustrations: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: "mask-user-input",
    });

    datadogRum.startSessionReplayRecording();
  }
};
