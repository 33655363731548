import { textStyles } from "@client/src/utils/textStyles";
import styled from "styled-components";

export const AddPortfolioDialogBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  height: 90%;
`;

export const AddPortfolioDialogContainer = styled.div`
  height: 90%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const PortfolioButtonContainer = styled.div`
  display: flex;
  flex-direction: row-reverse;
`;

export const PortfolioListDialogBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  height: 80%;
  overflow-y: auto;
`;

export const PortfolioItemContainer = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  gap: 1rem;
`;

export const ButtonContainer = styled.div`
  display: flex;
  position: absolute;
  right: 0.25rem;
  top: 0.25rem;
  gap: 1rem;
`;

export const InstrumentListContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 80%;
  overflow-y: auto;
`;

export const InstrumentItemContainer = styled.div`
  display: flex;
  padding: 1rem 0.8rem;
`;

export const SubText = styled.div`
  ${textStyles.textSm};
  color: ${(props) => props.theme.subtextColor};
`;
