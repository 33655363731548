import React from "react";
import { useNavigate } from "react-router-dom";
import Button from "../button/Button.component";
import { ButtonContainer, Container, Text } from "./PurchaseError.style";

export const PurchaseError = () => {
  const navigate = useNavigate();
  return (
    <Container>
      <Text>
        Your Subscription Payment failed, shortly your Subscription will be
        cancelled and you will lose access to the Subscription Features. Try
        paying again, or update your payment information.
      </Text>
      <ButtonContainer>
        <Button
          type={"destructive"}
          text={"Update Payment Method"}
          onClick={() => navigate("/profile")}
        />
      </ButtonContainer>
    </Container>
  );
};
