import { Auth0Provider } from "@auth0/auth0-react";
import Plans from "@components/plan_page/Plans.component";
import React from "react";
import { createRoot } from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { createGlobalStyle, ThemeProvider } from "styled-components";
import App from "./app/App.component";
import { rumInit } from "./app/datadog";
// eslint-disable-next-line max-len
import ConditionBuilder from "./components/condition_builder/ConditionBuilder.component";
import { TooltipProvider } from "./components/tooltip/Tooltip.component";
import { AppProvider, useAppContext } from "./context/AppContext";
import { AuthenticatedProvider } from "./context/auth/AuthContext";
import { ConditionBuilderProvider } from "./context/ConditionBuilderContext";
import { InstrumentProvider } from "./context/InstrumentContext";
import { PortfolioProvider } from "./context/PortfolioContext";
import { ProfileProvider } from "./context/ProfileContext";
import { ScreeningProvider } from "./context/ScreeningContext";

import { UserProvider } from "./context/user/UserContext";
import AlertsSettings from "./pages/alerts_settings/AlertsSettings.component";
import ErrorPage from "./pages/errors/fullPageError.component";

// eslint-disable-next-line max-len
import Profile from "./pages/profile/Profile.component";
// eslint-disable-next-line max-len
import PurchaseSubscription from "./pages/purchase_subscription_plan/PurchaseSubscription.component";
import Screening from "./pages/screening/Screening.component";
import { themes } from "./themes";

const isEnvvarsReady = (
  authDomain: string,
  authClientId: string,
  authRedirectUri: string
) => authDomain && authClientId && authRedirectUri;
const GlobalStyle = createGlobalStyle<Record<string, never>>`
  * {
    box-sizing: border-box;
  }

  body {
    font-family: 'Poppins', sans-serif;
    background-color: ${(props) => props.theme.background};
    color: ${(props) => props.theme.text};
    margin: 0;
  }
  /* For Webkit browsers */
  ::-webkit-scrollbar {
    width: 0.5rem;
  }

  ::-webkit-scrollbar-track {
    background: rgba(0,0,0,0);
  }

  ::-webkit-scrollbar-thumb {
    background: rgba(0,0,0,0);
    border-radius: 0.5rem;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: ${(props) => props.theme.scrollbarBg};
  }

  /* For Firefox */
  * {
    scrollbar-width: thin;  // Width of the scrollbar
    scrollbar-color: ${(props) => props.theme.scrollbarBg} rgba(0, 0, 0, 0);
  }
`;

const AuthApp = () => {
  const {
    authDomain,
    authClientId,
    authRedirectUri,
    ddRumApplicationId,
    ddRumClientToken,
    theme,
  } = useAppContext();
  if (!isEnvvarsReady(authDomain, authClientId, authRedirectUri)) return null;
  rumInit(ddRumApplicationId, ddRumClientToken);

  const router = createBrowserRouter([
    {
      path: "*",
      element: <ErrorPage errorStatus={404} />,
    },
    {
      path: "/",
      element: (
        <ProfileProvider>
          <App logoutRedirect={authRedirectUri} />
        </ProfileProvider>
      ),
      errorElement: <ErrorPage errorStatus={500} />,
    },
    {
      path: "/:path/*",
      loader: async ({ params }) => {
        if (!["redeem", "profile"].includes(params["path"] ?? ""))
          throw new Response("Not Found", { status: 404 });

        return null;
      },
      element: (
        <ProfileProvider>
          <Profile logoutRedirect={authRedirectUri} />
        </ProfileProvider>
      ),
      errorElement: <ErrorPage errorStatus={404} />,
    },
    {
      path: "/profile/alerts",
      element: <AlertsSettings />,
      errorElement: <ErrorPage errorStatus={500} />,
    },
    {
      path: "/plans/",
      element: <Plans />,
      errorElement: <ErrorPage errorStatus={500} />,
    },
    {
      path: "/plans/:planName",
      element: <PurchaseSubscription />,
      errorElement: <ErrorPage errorStatus={500} />,
    },
    {
      path: "/screening",
      element: (
        <InstrumentProvider>
          <ConditionBuilderProvider>
            <ScreeningProvider>
              <PortfolioProvider>
                <Screening />
              </PortfolioProvider>
            </ScreeningProvider>
          </ConditionBuilderProvider>
        </InstrumentProvider>
      ),
      errorElement: <ErrorPage errorStatus={500} />,
    },
    {
      path: "/condition-builder",
      element: (
        <ConditionBuilderProvider>
          <ConditionBuilder />
        </ConditionBuilderProvider>
      ),
      errorElement: <ErrorPage errorStatus={500} />,
    },
  ]);

  return (
    <Auth0Provider
      domain={authDomain}
      clientId={authClientId}
      useRefreshTokensFallback={true}
      useRefreshTokens={true}
      onRedirectCallback={(appState) => {
        const location =
          appState && appState.returnTo
            ? appState.returnTo
            : window.location.pathname;

        window.location.href = location;
        history.pushState(null, "", location);
      }}
      cacheLocation="localstorage"
      authorizationParams={{
        redirect_uri: authRedirectUri,
        audience: "https://app.runleopard.ai/api/leopard",
        scope: "openid profile email offline_access",
      }}
    >
      <ThemeProvider theme={themes[theme]}>
        <UserProvider>
          <AuthenticatedProvider>
            <RouterProvider router={router} />
          </AuthenticatedProvider>
          <GlobalStyle />
        </UserProvider>
      </ThemeProvider>
    </Auth0Provider>
  );
};

const root = createRoot(document.getElementById("root") as HTMLElement);

root.render(
  <AppProvider>
    <TooltipProvider>
      <AuthApp />
    </TooltipProvider>
  </AppProvider>
);
export { addEventListeners } from "@client/src/charts/helpers/events";
export { throwIfDisposed } from "@client/src/charts/helpers/dispose";
