import React, { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useTheme } from "styled-components";
import {
  CURRENT_TERMS_VERSION,
  shouldShowDialog,
} from "./TermsDialogWrapper.helpers";
import TermsDialog from "@components/terms_dialog/TermsDialog.component";

const TermsDialogWrapper = () => {
  const { isLoading, isAuthenticated, getAccessTokenSilently } = useAuth0();
  const [termsAccepted, setTermsAccepted] = useState<boolean | undefined>();
  const theme = useTheme();
  useEffect(() => {
    if (isLoading) return;
    if (!isAuthenticated) return;
    getAccessTokenSilently().then((token) =>
      fetch("/api/terms", {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.error) return setTermsAccepted(true);
          const termsAcceptedRecorded =
            data.termsVersion === CURRENT_TERMS_VERSION;
          setTermsAccepted(termsAcceptedRecorded);
        })
        .catch(() => setTermsAccepted(true))
    );
  }, []);

  if (!shouldShowDialog(isLoading, isAuthenticated, termsAccepted, theme))
    return null;

  return <TermsDialog onAcceptTerms={() => setTermsAccepted(true)} />;
};

export default TermsDialogWrapper;
