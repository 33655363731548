import styled from "styled-components";
import { baseStyle, textStyles } from "@utils/textStyles";

export const ItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
`;

export const ItemWrapper = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  padding: 1rem 1rem 0 1rem;
  gap: 1rem;
`;

export const ItemIcon = styled.div<{ read: boolean }>`
  ${(props) =>
    props.read === true
      ? `
  background-color: white;
  height: 0.25rem;
  width: 0.25rem;
  border-radius: 100%;
`
      : `
  background-color: white;
  height: 0.25rem;
  width: 0.25rem;
  border-radius: 9999%;
`}
`;

export const ItemContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

export const ItemSettings = styled.div`
  // width: 1rem;
`;

export const Message = styled.div`
  ${baseStyle};
  display: flex;
  align-items: flex-start;
  align-content: flex-start;
  gap: 0 0.25rem;
  align-self: stretch;
  flex-wrap: wrap;
`;

export const Timeframe = styled.div`
  ${baseStyle};
`;

export const TextRegular = styled.span`
  ${textStyles.textBase};
`;

export const TextBold = styled.span`
  ${textStyles.textBaseBold};
`;

export const Subtext = styled.span`
  ${textStyles.textSm};
  color: ${(props) => props.theme.subtextColor};
`;

export const Divider = styled.div`
  height: 0.0625rem;
  width: 100%;

  background-color: ${(props) => props.theme.dividerColor};
`;
