import styled from "styled-components";
import { baseStyle, textStyles } from "@utils/textStyles";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

export const Content = styled.div`
  ${baseStyle};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  height: 100%;
  width: 100%;
  max-width: 18.375rem;
  box-sizing: border-box;
  @media (max-width: 768px) {
    max-width: 100%;
  }
`;

export const IconContainer = styled.div`
  color: ${(props) => props.theme.emptyStateIcon};
  width: 5rem;
  height: 5rem;
  display: flex;
  & > * {
    width: 100%;
    height: 100%;
  }
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
  align-self: stretch;
`;

export const Title = styled.div`
  ${textStyles.textLgBold};
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  flex-wrap: wrap;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
`;

export const Description = styled.div`
  ${textStyles.textBase};
  color: ${(props) => props.theme.subtextColor};
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  flex-wrap: wrap;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
`;
