import React, { useEffect } from "react";
import {
  Body,
  ButtonContainer,
  CookieBannerContainer,
  PrivacyLink,
  Title,
} from "./CookieBanner.style";
import Button from "../button/Button.component";

const CookieBanner = () => {
  const [visible, setVisible] = React.useState(true);

  useEffect(() => {
    const hasCookie = localStorage.getItem("cookies_accepted");
    if (hasCookie) {
      setVisible(false);
    }
  }, []);

  if (!visible) return null;

  return (
    <CookieBannerContainer>
      <Title>
        Our website uses cookies
      </Title>
      <Body>
        Our website uses cookies.
        By continuing, we assume your permission
        to deploy cookies as detailed in our
        &nbsp;<PrivacyLink
          href="https://www.runleopard.ai/privacy"
        >
          Privacy Policy
        </PrivacyLink>
      </Body>
      <ButtonContainer>
        <Button
          text={"Reject All"}
          type="secondary"
          onClick={() => {
            localStorage.setItem("cookies_accepted", "false");
            setVisible(false);
          }}
        />
        <Button
          text={"Accept All"}
          type="primary"
          onClick={() => {
            localStorage.setItem("cookies_accepted", "true");
            setVisible(false);
          }}
        />
      </ButtonContainer>
    </CookieBannerContainer>
  );
};

export default CookieBanner;
