const AIMA_SHOWN_KEY = "AIMA_SHOWN_KEY";

const setAimaShown = () => {
  localStorage.setItem(AIMA_SHOWN_KEY, "true");
};

const hasAimaBeenShown = () => {
  const aimaShown = localStorage.getItem(AIMA_SHOWN_KEY);
  if (aimaShown && aimaShown === "true") {
    return true;
  }
  return false;
};

const clear = () => {
  localStorage.removeItem(AIMA_SHOWN_KEY);
};

export { setAimaShown, hasAimaBeenShown, clear };
