import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
`;

export const ComparatorContainer = styled.div`
  border-radius: 100%;
  border-color: ${(props) => props.theme.dashedBorder};
  border-style: solid;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.5rem;
  height: 2.5rem;
`;
