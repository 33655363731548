import { AlertRecord } from "@common/types";
import { parseAlertInfo } from "@utils/alertParser";
import React, { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import Button from "../button/Button.component";
import {
  ButtonContainer,
  Container,
  Message,
  TextBold,
  TextRegular,
} from "./AlertToast.styles";

type AlertToastProps = {
  alertData: AlertRecord;
  openManageInboxTab?: () => void;
};
const AlertToast = ({
  alertData,
  openManageInboxTab,
}: AlertToastProps) => {
  const parsedAlert = parseAlertInfo(alertData);
  const navigate = useNavigate();
  const handleGoToChartClick = useCallback(() => {
    const hashcode = alertData.alertInfo.instrument;
    const frequency = alertData.alertInfo.frequency;
    const searchParams = new URLSearchParams(
      window.location.search
    );
    searchParams.set("hashcode", hashcode);
    searchParams.set("frequency", String(frequency));

    navigate({
      pathname: "/",
      search: `?${searchParams.toString()}`,
    });
  }, []);
  return (
    <Container>
      <Message>
        <TextBold>{parsedAlert.instrument}</TextBold>
        <TextRegular>{" has formed a "}</TextRegular>
        <TextBold>{parsedAlert.pattern}</TextBold>
        <TextRegular>{" on "}</TextRegular>
        <TextBold>{parsedAlert.frequency}</TextBold>
        <TextRegular>{" candle intervals "}</TextRegular>
      </Message>
      <ButtonContainer>
        <Button
          text={"Go to Chart"}
          type={"primary"}
          onClick={handleGoToChartClick}
        />
        <Button
          text={"Manage Alerts"}
          type={"secondary"}
          onClick={openManageInboxTab}
        />
      </ButtonContainer>
    </Container>
  );
};

export default AlertToast;
