/**
 * https://stackoverflow.com/a/11381730
 * @returns {boolean} true if device is mobile, false otherwise
 */
/* eslint-disable max-len */
import { useEffect, useState } from "react";

export const isMobile = () => {
  const [width, setWidth] = useState<number>(window.innerWidth);

  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  };
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  return width <= 768;
};
