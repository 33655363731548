import React from "react";
import {
  TooltipContent,
  TooltipArrow,
  TooltipArrowDark,
} from "./Tooltip.style";

import * as TooltipPrimitive from "@radix-ui/react-tooltip";

interface TooltipProps extends TooltipPrimitive.TooltipProps {
  children: React.ReactNode;
}
const Tooltip = ({ children, ...props }: TooltipProps) => {
  return <TooltipPrimitive.Root {...props}>{children}</TooltipPrimitive.Root>;
};

interface TooltipItemProps extends TooltipPrimitive.TooltipTriggerProps {
  children?: React.ReactNode;
  renderItem?: () => JSX.Element | React.ReactNode;
}

Tooltip.Item = React.forwardRef((props: TooltipItemProps, ref) => {
  return (
    <TooltipPrimitive.Trigger asChild {...props} ref={ref}>
      {props.renderItem ? props.renderItem() : props.children}
    </TooltipPrimitive.Trigger>
  );
});

const TooltipBody = ({
  children,
  text,
}: {
  children?: React.ReactNode;
  text?: string;
}) => {
  if (Boolean(children)) {
    return children;
  } else if (Boolean(text)) {
    return <div>{text}</div>;
  }

  return null;
};

interface TooltipContentProps extends TooltipPrimitive.TooltipContentProps {
  className?: string;
  children?: React.ReactNode;
  text?: string;
  enabled?: boolean;
  dark?: boolean;
}

const renderProperArrow = (dark: boolean | undefined) => {
  if (dark === true) return <TooltipArrowDark />;
  return <TooltipArrow />;
};

Tooltip.Content = React.forwardRef(
  (
    { className, children, text, enabled, dark, ...props }: TooltipContentProps,
    ref
  ) => {
    if (enabled === false) return null;
    return (
      <TooltipPrimitive.Portal forceMount={props.forceMount ? true : undefined}>
        <TooltipContent
          sideOffset={3}
          {...props}
          className={className}
          ref={ref}
        >
          <TooltipBody children={children} text={text} />
          {renderProperArrow(dark)}
        </TooltipContent>
      </TooltipPrimitive.Portal>
    );
  }
);

const TooltipProvider = TooltipPrimitive.Provider;
export { Tooltip, TooltipProvider };
