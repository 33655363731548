import { useAuth0 } from "@auth0/auth0-react";
import React from "react";

export const AuthenticatedProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { isAuthenticated, loginWithRedirect, isLoading } = useAuth0();

  if (isLoading) {
    return <p>Loading...</p>;
  }

  if (!isAuthenticated) {
    loginWithRedirect({
      appState: { returnTo: window.location.pathname },
    });
    return <p>Redirecting...</p>;
  }

  return children;
};
