import React from "react";
import {
  ConditionBuilderBlock,
  CrossBlock,
} from "@client/src/context/ConditionBuilderContext";
import ConditionBuilderBlockRenderer from
  "../condition_builder_block_renderer/ConditionBuilderBlockRenderer.component";
import {
  Container,
} from "./ConditionBuilderCross.style";

const ConditionBuilderCross = ({
  block,
}: {
  block: ConditionBuilderBlock,
}) => {
  return (
    <Container>
      <ConditionBuilderBlockRenderer block={(block as CrossBlock).left} />
      <div>{(block as CrossBlock).operation}</div>
      <ConditionBuilderBlockRenderer block={(block as CrossBlock).right} />
    </Container>
  );
};

export default ConditionBuilderCross;
