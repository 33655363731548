import React from "react";
import {
  Container,
  Text,
  Primary,
  Secondary,
  Tertiary,
  Destructive,
  Gold,
  Transparent,
  Filter,
} from "./Button.style";

export type ButtonSize = "xs" | "sm" | "base" | "lg" | "xl";
export type textColour = "info" | "alert" | "warning" | "success";
export type ButtonType =
  | "default"
  | "primary"
  | "secondary"
  | "tertiary"
  | "destructive"
  | "transparent"
  | "gold"
  | "filter";

type Props = {
  leftIcon?: React.ReactNode;
  rightIcon?: React.ReactNode;
  text: string;
  onClick?: () => void;
  type?: ButtonType;
  size?: ButtonSize;
  textColour?: string;
  disabled?: boolean;
};

const BUTTON_TYPES = {
  primary: Primary,
  secondary: Secondary,
  tertiary: Tertiary,
  destructive: Destructive,
  gold: Gold,
  transparent: Transparent,
  filter: Filter,
  default: Container,
};

const getButtonContainer = (type: ButtonType = "default") => BUTTON_TYPES[type];

const Button = ({
  leftIcon,
  rightIcon,
  text,
  onClick,
  type = "default",
  size = "base",
  disabled = false,
  textColour,
}: Props) => {
  const ButtonContainer = getButtonContainer(type);
  return (
    <ButtonContainer
      data-testid="btn-pressable"
      disabled={disabled}
      onClick={() => {
        if (disabled) return;
        onClick && onClick();
      }}
      size={size}
      textColour={textColour}
    >
      {leftIcon}
      <Text>{text}</Text>
      {rightIcon}
    </ButtonContainer>
  );
};

export default Button;
