import { Modal } from "@client/src/sigma-ui/components/Modal";
import styled from "styled-components";

export const ItemPlan = styled.div`
  width: 17.5rem;
  height: 100%;
  padding: 1.5rem;
  display: flex;
  gap: 2rem;
  flex-direction: column;
  flex-grow: 1;

  border-radius: 1rem;
  background: var(--greyscale-900, #131313);
`;

export const ItemOfferingsSection = styled.div``;

export const PurchaseScrollSnapContainer = styled.div`
  width: 100%;
  height: 20.25rem;
  overflow-x: scroll;
  scroll-snap-type: x mandatory;
  display: flex;
  flex-direction: row;
  gap: 1rem;

  & > ${ItemPlan} {
    scroll-snap-align: center;
  }

  & ${ItemOfferingsSection} {
    flex: 1 1 0%;
  }
`;

export const ItemPrice = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  height: 4rem;
  gap: 0.25rem;
  align-self: stretch;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const ModalHeaderContainer = styled(Modal.Header)`
  display: flex;
  flex-direction: row;
  gap: 1.5rem;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
`;

export const Body = styled(Modal.Body)`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 2rem;
  justify-content: center;
`;

export const ModalContainer = styled(Modal.Container)`
  display: flex;
  flex-direction: column;
  gap: 2rem;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem;
  gap: 1.5rem;
`;

export const MobileContainer = styled.div`
  & ${Header} {
    padding: 2.5rem;
  }

  & ${PurchaseScrollSnapContainer} {
    padding: 0 2.5rem;
  }
`;
