import { ScreeningFilter } from "../../ScreeningFilter";
import { CustomField } from "../custom_dialog/CustomFilterDialog.types";
import {
  ChangeFilterValue,
  InstrumentAndChange,
} from "./ChangeScreeningFilter.types";

export const changeScreeningFilter: ScreeningFilter<
  ChangeFilterValue,
  InstrumentAndChange[]
> = {
  apply: (instrumentList, value) => value.operation(instrumentList),
};

export const changeScreeningFilterValues: ChangeFilterValue[] = [
  {
    title: "Change %",
    operation: (instrumentList) => instrumentList,
  },
  {
    title: "30% and above",
    operation: (instrumentList) => {
      return instrumentList.filter(
        (instrument) => Number(instrument.change) >= 30
      );
    },
  },
  {
    title: "20% and above",
    operation: (instrumentList) =>
      instrumentList.filter((instrument) => Number(instrument.change) >= 20),
  },
  {
    title: "10% and above",
    operation: (instrumentList) =>
      instrumentList.filter((instrument) => Number(instrument.change) >= 10),
  },
  {
    title: "5% and above",
    operation: (instrumentList) =>
      instrumentList.filter((instrument) => Number(instrument.change) >= 5),
  },
  {
    title: "0% and above",
    operation: (instrumentList) =>
      instrumentList.filter((instrument) => Number(instrument.change) >= 0),
  },
  {
    title: "5% and below",
    operation: (instrumentList) =>
      instrumentList.filter(
        (instrument) =>
          instrument.change !== "" && Number(instrument.change) <= 5
      ),
  },
  {
    title: "-5% to 0%",
    operation: (instrumentList) =>
      instrumentList.filter(
        (instrument) =>
          instrument.change !== "" &&
          Number(instrument.change) <= 0 &&
          Number(instrument.change) >= -5
      ),
  },
  {
    title: "-5% and below",
    operation: (instrumentList) =>
      instrumentList.filter(
        (instrument) =>
          instrument.change !== "" && Number(instrument.change) <= -5
      ),
  },
  {
    title: "-10% and below",
    operation: (instrumentList) =>
      instrumentList.filter(
        (instrument) =>
          instrument.change !== "" && Number(instrument.change) <= -10
      ),
  },
  {
    title: "-20% and below",
    operation: (instrumentList) =>
      instrumentList.filter(
        (instrument) =>
          instrument.change !== "" && Number(instrument.change) <= -20
      ),
  },
  {
    title: "-30% and below",
    operation: (instrumentList) =>
      instrumentList.filter(
        (instrument) =>
          instrument.change !== "" && Number(instrument.change) <= -30
      ),
  },
];

export const ChangeCustomFields: CustomField<ChangeFilterValue>[] = [
  {
    title: "Change %",
    operation: "Above",
    mainInput: {
      type: "numeric",
      label: "0",
    },
    operationFunction: (value) => {
      return {
        title: `Above ${value}`,
        operation: (instrumentList) =>
          instrumentList.filter(
            (instrument) => Number(instrument.change) > value
          ),
      };
    },
  },
  {
    title: "Change %",
    operation: "Below",
    mainInput: {
      type: "numeric",
      label: "0",
    },
    operationFunction: (value) => {
      return {
        title: `Below ${value}`,
        operation: (instrumentList) =>
          instrumentList.filter(
            (instrument) =>
              instrument.change !== "" && Number(instrument.change) < value
          ),
      };
    },
  },
  {
    title: "Change %",
    operation: "Between",
    mainInput: {
      type: "numeric",
      label: "from",
    },
    secondaryInput: {
      type: "numeric",
      label: "to",
    },
    operationFunction: (value, value2) => {
      return {
        title: `Between ${value} and ${value2}`,
        operation: (instrumentList) =>
          instrumentList.filter(
            (instrument) =>
              Number(instrument.change) >= value &&
              value2 &&
              Number(instrument.change) <= value2
          ),
      };
    },
  },
];
