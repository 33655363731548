import { createChartState, RsiChartParams } from "./state";
import {
  makeDisposable,
  throwIfDisposed,
} from "../helpers/dispose";
import { ChartData } from "@hooks/useFetchChart";
import { updateTechnicals } from "./technicals";
import { ChartHandler } from "../helpers/types";
import { registerChartSync } from "./register-sync";

export type RsiChart = ReturnType<typeof createRsiChart>;

export const createRsiChart = (params: RsiChartParams) => {
  const state = createChartState(params);
  registerChartSync(state);
  updateTechnicals(state, params.technicals);

  return {
    chart: state.lwChart,
    elemRef: state.elem,
    update: (chartData: ChartData) => {
      throwIfDisposed(state);
      const { technicals } = chartData;
      updateTechnicals(state, technicals);
    },

    dispose: makeDisposable(state),
  } as ChartHandler;
};
