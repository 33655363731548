import { Portfolio } from "@common/types";
import { usePortfolios } from "@hooks/usePortfolios";
import React, {
  createContext,
  PropsWithChildren,
  useContext,
  useEffect,
  useState,
} from "react";

type PortfolioContextType = {
  createPortfolio: (body: { name: string; hashcodes: string[] }) => void;
  showCreatePortfolioDialog: boolean;
  setShowCreatePortfolioDialog: (show: boolean) => void;
  showPortfolioListDialog: boolean;
  setShowPortfolioListDialog: (show: boolean) => void;
  portfolios: Portfolio[];
  refreshPortfolios: () => void;
  deletePortfolio: (portfolioId: string) => void;
  showAddToPortfolioDialog: boolean;
  setShowAddToPortfolioDialog: (show: boolean) => void;
  manageAddHashcodesToPortfolio: (
    portfolio: Portfolio,
    hashcodes: string[]
  ) => void;
  showEditPortfolioDialog: boolean;
  setShowEditPortfolioDialog: (show: boolean) => void;
  editPortfolio: (portfolio: Portfolio) => void;
  portfolioToEdit: Portfolio | null;
  manageUpdatePortfolio: (
    portfolio: Portfolio,
    newName: string,
    updatedHashcodes: string[]
  ) => void;
};

const defaultPortfolioContext: PortfolioContextType = {
  createPortfolio: () => {
    return;
  },
  showCreatePortfolioDialog: false,
  setShowCreatePortfolioDialog: () => {
    return;
  },
  showPortfolioListDialog: false,
  setShowPortfolioListDialog: () => {
    return;
  },
  portfolios: [],
  refreshPortfolios: () => {
    return;
  },
  deletePortfolio: () => {
    return;
  },
  showAddToPortfolioDialog: false,
  setShowAddToPortfolioDialog: () => {
    return;
  },
  manageAddHashcodesToPortfolio: () => {
    return;
  },
  showEditPortfolioDialog: false,
  setShowEditPortfolioDialog: () => {
    return;
  },
  editPortfolio: () => {
    return;
  },
  portfolioToEdit: null,
  manageUpdatePortfolio: () => {
    return;
  },
};

export const PortfolioContext = createContext(defaultPortfolioContext);
const PortfolioProvider = (props: PropsWithChildren<object>) => {
  const [showCreatePortfolioDialog, setShowCreatePortfolioDialog] =
    useState(false);
  const [showAddToPortfolioDialog, setShowAddToPortfolioDialog] =
    useState(false);
  const [showPortfolioListDialog, setShowPortfolioListDialog] = useState(false);
  const [showEditPortfolioDialog, setShowEditPortfolioDialog] = useState(false);
  const [portfolioToEdit, setPortfolioToEdit] = useState<Portfolio | null>(
    null
  );
  const {
    createPortfolio,
    userPortfolios,
    getPortfolios,
    deletePortfolio,
    updatePortfolio,
  } = usePortfolios();
  useEffect(() => {
    getPortfolios();
  }, []);

  const openEditPortfolioDialog = (portfolio: Portfolio) => {
    setPortfolioToEdit(portfolio);
    setShowEditPortfolioDialog(true);
    setShowPortfolioListDialog(false);
  };

  const manageUpdatePortfolio = (
    portfolio: Portfolio,
    newName: string,
    updatedHashcodes: string[]
  ) => {
    const updatedPortfolio = {
      ...portfolio,
      name: newName,
      hashcodes: updatedHashcodes,
    };
    updatePortfolio(updatedPortfolio);
    setShowEditPortfolioDialog(false);
  };

  const manageAddHashcodesToPortfolio = (
    portfolio: Portfolio,
    hashcodes: string[]
  ) => {
    const updatedPortfolio = {
      ...portfolio,
      hashcodes: [
        ...portfolio.hashcodes,
        ...hashcodes.filter((hashcode) => {
          return !portfolio.hashcodes.includes(hashcode);
        }),
      ],
    };
    updatePortfolio(updatedPortfolio);
  };
  return (
    <PortfolioContext.Provider
      value={{
        createPortfolio,
        showCreatePortfolioDialog,
        setShowCreatePortfolioDialog,
        showPortfolioListDialog,
        setShowPortfolioListDialog,
        portfolios: userPortfolios,
        refreshPortfolios: getPortfolios,
        deletePortfolio: deletePortfolio,
        showAddToPortfolioDialog,
        setShowAddToPortfolioDialog,
        manageAddHashcodesToPortfolio,
        showEditPortfolioDialog,
        setShowEditPortfolioDialog,
        editPortfolio: openEditPortfolioDialog,
        portfolioToEdit,
        manageUpdatePortfolio,
      }}
    >
      {props.children}
    </PortfolioContext.Provider>
  );
};

const usePortfolioContext = () => useContext(PortfolioContext);

export { PortfolioProvider, usePortfolioContext };
export type { PortfolioContextType };
