import { textStyles } from "@client/src/utils/textStyles";
import styled from "styled-components";

export const SubCTAContainer = styled.div`
  display: flex;
  align-items: start;
  height: fit-content;
  padding: 1rem;
  border-radius: 0.5rem;
  justify: space-between;
  background-color: ${(props) => props.theme.secondaryBackground};
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
`;

export const Text = styled.div`
  ${textStyles.textLgBold}
`;

export const Subtext = styled.div`
  ${textStyles.textBase}
`;
