import { Instrument } from "@generated/common/basic-types_pb";
export const DEFAULT_SYMBOL = "BTC-USD";

export const getDefaultInstrument = (
  instruments: Instrument.AsObject[],
  localStorageInstrument: Instrument.AsObject | undefined,
  paramInstrument: string | undefined
): Instrument.AsObject => {
  if (paramInstrument) {
    return getInstrumentByHashcode(instruments, paramInstrument);
  }
  return getInstrumentBySymbol(instruments, localStorageInstrument);
};

const getInstrumentBySymbol = (
  instruments: Instrument.AsObject[],
  instrument: Instrument.AsObject | undefined
): Instrument.AsObject => {
  let symbolToFind = DEFAULT_SYMBOL;
  if (instrument) {
    symbolToFind = instrument.symbol;
  }
  return instruments.find((i) => i.symbol === symbolToFind) ?? instruments[0];
};

const getInstrumentByHashcode = (
  instruments: Instrument.AsObject[],
  hashcode: string
): Instrument.AsObject => {
  return instruments.find((i) => i.hashcode === hashcode) ?? instruments[0];
};
