import { useAuth0 } from "@auth0/auth0-react";
import { toastStyles } from "@utils/toastStyles";
import {
  AirwallexEnv,
  createElement,
  DropInElementOptions,
  loadAirwallex,
} from "airwallex-payment-elements";
import React, { useEffect } from "react";
import { HiArrowRight } from "react-icons/hi2";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useAppContext } from "../../context/AppContext";
import {
  ButtonContainer,
  Content,
  Message,
  SubMessage,
  Text,
  Title,
} from "../../pages/errors/fullPageError.style";
import Button from "../button/Button.component";

type PaymentIntentBody = {
  amount: number;
  client_secret: string;
  id: string;
  currency: string;
  customer_id: string;
};
enum OrderErrorCodes {
  ALREADY_GOT_SUBSCRIPTION = "4000",
  PRODUCT_NOT_FOUND = "4001",
  USER_NOT_FOUND = "4002",
  PRODUCT_NOT_AVAILABLE_TO_USER = "4003",
}
export const orderErrorMessages = {
  [OrderErrorCodes.ALREADY_GOT_SUBSCRIPTION]: "You already have a subscription",
  [OrderErrorCodes.PRODUCT_NOT_FOUND]: "Product not found",
  [OrderErrorCodes.USER_NOT_FOUND]: "User not found",
  [OrderErrorCodes.PRODUCT_NOT_AVAILABLE_TO_USER]:
    "Product not available to user",
};

const GENERIC_ERROR = "Something went wrong while loading payment portal";

export const getErrorMessage = (code: string) =>
  orderErrorMessages[code as keyof typeof orderErrorMessages];

export const getErrorCode = (error: string) => error.match(/\d+/g);

const getProperError = (errorBody: string): string => {
  const code = getErrorCode(errorBody);
  if (code && code.length > 0) {
    return getErrorMessage(code[0]);
  }
  return GENERIC_ERROR;
};

export const createPaymentIntent = async (
  productId: string,
  token: string,
  setError: (errorMessage: string) => void
): Promise<{
  paymentIntent: PaymentIntentBody;
  createElementConfig: DropInElementOptions;
}> => {
  const response = await fetch("/api/orders/create", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      requestId: "air" + Date.now(),
      productId: productId,
    }),
  });

  if (!response.ok) {
    let error = GENERIC_ERROR;
    if (response.status === 400) {
      const body = await response.json();
      error = getProperError(JSON.stringify(body));
    }
    setError(error);
    throw new Error(error);
  }

  return (await response.json()).data;
};

const renderError = (
  error: string,
  onRetry: () => void,
  onBack: () => void
) => {
  return (
    <Content data-testid={"error-payment"}>
      <Text>
        <Title>Oops!</Title>
        <Message>Something went wrong</Message>
        <SubMessage>{error}</SubMessage>
      </Text>
      <ButtonContainer>
        <Button text="Retry" onClick={onRetry} type="transparent" size="sm" />
        <Button
          text="Go back home"
          onClick={onBack}
          type="transparent"
          size="sm"
          rightIcon={<HiArrowRight />}
        />
      </ButtonContainer>
    </Content>
  );
};

const PaymentPortal = ({
  productId,
  setProductId,
}: {
  productId: string;
  setProductId: (productId: string | null) => void;
}) => {
  const [error, setError] = React.useState<string | null>(null);
  const { getAccessTokenSilently } = useAuth0();
  const navigate = useNavigate();
  const { airwallexEnv } = useAppContext();

  useEffect(() => {
    const loadDropInElement = async () => {
      try {
        await loadAirwallex({
          env: airwallexEnv as AirwallexEnv,
          origin: window.location.origin,
        });
        const token = await getAccessTokenSilently();
        const paymentIntent = await createPaymentIntent(
          productId,
          token,
          setError
        );
        if (!paymentIntent) {
          toast.error(GENERIC_ERROR, {
            style: toastStyles("error"),
          });
          return;
        }
        const createElementConfig = paymentIntent.createElementConfig;
        const element = createElement("dropIn", {
          style: {
            popupWidth: 400,
            popupHeight: 549,
          },
          ...createElementConfig,
        });
        element?.mount("dropIn");
      } catch (error) {
        toast.error(GENERIC_ERROR, {
          style: toastStyles("error"),
        });
      }
    };
    loadDropInElement();

    const onReady = (): void => {
      toast.info("Payment portal ready", {
        style: toastStyles("info"),
      });
    };

    const onSuccess = (): void => {
      setTimeout(() => {
        setProductId(null);
        navigate(`/profile${window.location.search}`);
      }, 2000);
    };

    const onError = (): void => {
      toast.error("Something went wrong while processing payment", {
        style: toastStyles("error"),
      });
      setError("Something went wrong while processing payment");
    };

    const domElement = document.getElementById("dropIn");
    domElement?.addEventListener("onReady", onReady);
    domElement?.addEventListener("onSuccess", onSuccess);
    domElement?.addEventListener("onError", onError);

    return () => {
      domElement?.removeEventListener("onSuccess", onSuccess);
      domElement?.removeEventListener("onError", onError);
      domElement?.removeEventListener("onReady", onReady);
    };
  }, []);
  if (error)
    return renderError(
      error,
      () => window.location.reload(),
      () => navigate("/")
    );
  return (
    <div>
      <div
        data-testid={"dropIn"}
        id="dropIn"
        style={{
          width: "540px",
          margin: "0 auto",
          background: "rgba(255, 255, 255, 0.75)",
          padding: "1rem",
          borderRadius: "0.5rem",
        }}
      ></div>
    </div>
  );
};

export default PaymentPortal;
