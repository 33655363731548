import { AlertRecord } from "@common/types";
import { parseAlertInfo } from "@utils/alertParser";
import React, { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import {
  Divider,
  ItemContainer,
  ItemContent,
  ItemIcon,
  ItemSettings,
  ItemWrapper,
  Message,
  Subtext,
  TextBold,
  TextRegular,
  Timeframe,
} from "./InboxItem.styles";
import { format } from "date-fns";

type InboxItemProps = {
  data: AlertRecord;
};

export const InboxItem = ({ data }: InboxItemProps) => {
  const navigate = useNavigate();
  const parsedAlert = parseAlertInfo(data);
  const handleClick = useCallback(() => {
    const hashcode = data.alertInfo.instrument;
    const frequency = data.alertInfo.frequency;

    const searchParams = new URLSearchParams(
      window.location.search
    );
    searchParams.set("hashcode", hashcode);
    searchParams.set("frequency", String(frequency));
    navigate({
      pathname: "/",
      search: `?${searchParams.toString()}`,
    });
  }, []);

  const formatDate = (inputDate: string) => {
    return format(new Date(inputDate), "MMM dd, yyyy");
  };

  const renderPatternAlert = () => {
    if (parsedAlert.alertType === "Condition") return null;
    return (
      <Message>
        <TextBold>{parsedAlert.instrument}</TextBold>
        <TextRegular>{" formed a "}</TextRegular>
        <TextBold>{parsedAlert.pattern}</TextBold>
        <TextRegular>{" in "}</TextRegular>
        <TextBold>{parsedAlert.frequency}</TextBold>
        <TextRegular>
          {" candle intervals "}
        </TextRegular>
      </Message>
    );
  };

  const renderConditionAlert = () => {
    if (parsedAlert.alertType === "Pattern") return null;
    return (
      <Message>
        <TextBold>{parsedAlert.instrument}</TextBold>
        <TextRegular>{" met the condition: "}</TextRegular>
        <TextBold>{parsedAlert.condition}</TextBold>
      </Message>
    );
  };

  return (
    <ItemWrapper onClick={handleClick}>
      <ItemContainer>
        {!parsedAlert.read && (
          <ItemIcon read={parsedAlert.read}></ItemIcon>
        )}
        <ItemContent>
          {renderPatternAlert()}
          {renderConditionAlert()}
          <Timeframe>
            <Subtext>
              {formatDate(parsedAlert.firedAt)} •{" "}
              {parsedAlert.firedAtTime} UTC
            </Subtext>
          </Timeframe>
        </ItemContent>
        <ItemSettings></ItemSettings>
      </ItemContainer>
      <Divider />
    </ItemWrapper>
  );
};
