import { useInstrumentContext } from "@client/src/context/InstrumentContext";
import { useScreeningContext } from "@client/src/context/ScreeningContext";
import {
  CandleResponse,
  ChangeSinceMidnightSimplified,
  ScreeningMatch,
} from "@common/types";
import { getSafeString } from "@utils/getSafe";
import React, { useEffect, useMemo, useState } from "react";
import {
  HiBellAlert,
  HiMagnifyingGlassCircle,
  HiSquaresPlus,
} from "react-icons/hi2";
import { useNavigate } from "react-router-dom";
import { EmptyState } from "../empty_state/EmptyState.component";
import { IconButton } from "../icon_button/IconButton.component";
import Label from "../label/Label.component";
import {
  formatLastMatch,
  formatPrice,
  parseResultsToCells,
  renderPercentageChange,
  splitSymbolAndName,
} from "./ScreeningResultTable.helper";
import {
  createSortByMethods,
  initialResultSort,
} from "./ScreeningResultTable.sorting";
import {
  ConditionMetContainer,
  ConditionMetText,
  ConditionStatsText,
  GridButton,
  GridColumn,
  GridContainer,
  GridHeader,
  GridItem,
  GridRow,
  LeftAlignText,
  NameAndSymbol,
  NoResultsContainer,
  NoResultsHeader,
  NoResultsSubtext,
  NoResultsTextContainer,
  SubText,
  TextBold,
} from "./ScreeningResultTable.style";
import { CandleFrequencyMap } from "@generated/common/basic-types_pb";
import { useAuth0 } from "@auth0/auth0-react";
import { toast } from "react-toastify";

export type ScreeningResult = {
  hashcode: string;
  symbol: string;
  name: string;
  class: string;
  price?: string;
  volume?: string;
  change?: string;
  lastMatch?: string;
  lastMatchTimestamp?: number;
  matches?: ScreeningMatch[];
  count?: number;
};

type ScreeningResultTableProps = {
  results: ScreeningResult[];
  onChangeSelected?: (selected: string[]) => void;
  columnsToRender?: Column[];
};

type RenderMethodsType = {
  [key: string]: (
    cellContent: string | number,
    result: ScreeningResult
  ) => JSX.Element;
};
export type Column =
  | "Name"
  | "Type"
  | "Price"
  | "Volume"
  | "Change"
  | "Last Match"
  | "Count"
  | "Portfolio"
  | "Alert";
const columns: Column[] = [
  "Name",
  "Type",
  "Price",
  "Volume",
  "Change",
  "Last Match",
  "Count",
  "Portfolio",
  "Alert",
];

const renderMethods: RenderMethodsType = {
  Name: (cellContent) => (
    <NameAndSymbol>
      <LeftAlignText>
        <TextBold>{splitSymbolAndName(cellContent.toString()).symbol}</TextBold>
      </LeftAlignText>
      <LeftAlignText>
        <SubText>{splitSymbolAndName(cellContent.toString()).name}</SubText>
      </LeftAlignText>
    </NameAndSymbol>
  ),
  Type: (cellContent) => (
    <Label text={cellContent.toString()} variant="tertiary" />
  ),
  Price: (cellContent) => <TextBold>{cellContent}</TextBold>,
  Change: (cellContent) => renderPercentageChange(cellContent.toString()),
  "Last Match": (cellContent) => (
    <TextBold>{formatLastMatch(cellContent.toLocaleString())}</TextBold>
  ),
};

// eslint-disable-next-line complexity
const renderCellContent = (
  cellContent: string,
  column: Column,
  result: ScreeningResult,
  frequency: number | CandleFrequencyMap,
  condition: string,
  onSelect: (hashcode: string) => void,
  getAccessTokenSilently: () => Promise<string>,
) => {
  if (column === "Portfolio") {
    return (
      <GridButton
        onClick={(event: React.MouseEvent) => {
          event.stopPropagation();
          onSelect(result.hashcode);
        }}
      >
        <IconButton
          onClick={(event: React.MouseEvent) => {
            event.stopPropagation();
            onSelect(result.hashcode);
          }}
          Icon={HiSquaresPlus}
        />
      </GridButton>
    );
  }
  if (column === "Alert" && condition !== "") {
    return (
      <GridButton
        onClick={(event: React.MouseEvent) => {
          event.stopPropagation();
          getAccessTokenSilently().then(token => {
            fetch("/api/alerts/conditional", {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
              body: JSON.stringify({
                instruments: [result.hashcode],
                frequency: frequency,
                condition: condition,
              }),
            }).then((res) => res.json())
              .then((data) => {
                toast(data.message);
              });
          });
        }}
      >
        <IconButton Icon={HiBellAlert} />
      </GridButton>
    );
  }
  const renderMethod = renderMethods[column];
  return renderMethod ? renderMethod(cellContent, result) : cellContent;
};

const renderCells = (
  results: ScreeningResult[],
  onClick: (result: ScreeningResult) => void,
  onSelect: (hashcode: string) => void,
  selectedHashcodes: string[],
  columnsToInclude: Column[] = columns,
  frequency: number | CandleFrequencyMap,
  condition: string,
  getAccessTokenSilently: () => Promise<string>,
) => {
  const withLastMatch = results.filter((result) => result.lastMatch);
  const withoutLastMatch = initialResultSort(
    results.filter((result) => !result.lastMatch)
  );

  const rowsWithLastMatch = withLastMatch.map((result, rowIndex) => (
    <GridRow
      $count={columnsToInclude.length}
      key={rowIndex}
      onClick={() => onClick(result)}
      data-testid={`cell-row-${rowIndex}`}
      selected={selectedHashcodes.includes(result.hashcode)}
    >
      {parseResultsToCells([result], columnsToInclude).map((cell, index) => (
        <GridItem key={index} data-testid={`cell-item-${index}`}>
          {renderCellContent(
            cell,
            columnsToInclude[index],
            result,
            frequency,
            condition,
            onSelect,
            getAccessTokenSilently,
          )}
        </GridItem>
      ))}
    </GridRow>
  ));

  const rowsWithoutLastMatch = withoutLastMatch.map((result, rowIndex) => (
    <GridRow
      $count={columnsToInclude.length}
      key={rowIndex}
      onClick={() => onClick(result)}
      data-testid={`cell-row-${rowIndex}`}
      selected={selectedHashcodes.includes(result.hashcode)}
    >
      {parseResultsToCells([result], columnsToInclude).map((cell, index) => (
        <GridItem key={index} data-testid={`cell-item-${index}`}>
          {renderCellContent(
            cell,
            columnsToInclude[index],
            result,
            frequency,
            condition,
            onSelect,
            getAccessTokenSilently,
          )}
        </GridItem>
      ))}
    </GridRow>
  ));

  const calculatePercentageOfMatches = (
    matches: number,
    total: number
  ): string => {
    if (total === 0) {
      return "0.00%";
    }
    const percentage = (matches / total) * 100;
    return percentage.toFixed(2) + "%";
  };

  if (rowsWithLastMatch.length > 0) {
    return (
      <div>
        <ConditionMetContainer>
          <LeftAlignText>
            <ConditionMetText>Condition met </ConditionMetText>
            <ConditionStatsText>
              {rowsWithLastMatch.length} of {results.length}(
              {calculatePercentageOfMatches(
                rowsWithLastMatch.length,
                results.length
              )}
              )
            </ConditionStatsText>
          </LeftAlignText>
        </ConditionMetContainer>
        {rowsWithLastMatch}
        <ConditionMetContainer>
          <LeftAlignText>
            <ConditionMetText>Condition not met </ConditionMetText>
            <ConditionStatsText>
              {rowsWithoutLastMatch.length} of {results.length}(
              {calculatePercentageOfMatches(
                rowsWithoutLastMatch.length,
                results.length
              )}
              )
            </ConditionStatsText>
          </LeftAlignText>
        </ConditionMetContainer>
        {rowsWithoutLastMatch}
      </div>
    );
  } else {
    return <div>{rowsWithoutLastMatch}</div>;
  }
};

export const getChangeDetails = (
  result: ScreeningResult,
  midnightChange: ChangeSinceMidnightSimplified[]
) => {
  const changeDetails = midnightChange.find(
    (change) => change.instrumentHashcode === result.hashcode
  );
  if (changeDetails) {
    return `${changeDetails.priceChange}, ${changeDetails.percentChange}`;
  }
  return "";
};

export const getResultsWithPriceAndMidnight = (
  sortedList: ScreeningResult[],
  latestCandles: CandleResponse[],
  midnightChange: ChangeSinceMidnightSimplified[]
) => {
  return sortedList.map((result) => {
    const candle = latestCandles.find(
      (candle) => candle.hashcode === result.hashcode
    );
    if (candle && candle.candles.length > 0) {
      return {
        ...result,
        price: getSafeString(candle.candles[0].close.toString()),
        volume: getSafeString(candle.candles[0].volume.toString()),
        change: getChangeDetails(result, midnightChange),
      };
    }
    return result;
  });
};

const renderNoResults = (
  screeningResults: ScreeningResult[],
  results: ScreeningResult[],
) => {
  if (screeningResults.length !== 0
    &&
    results.every(result => result.lastMatch === "")) {
    return (
      <NoResultsContainer>
        <NoResultsTextContainer>
          <NoResultsHeader>
              No Results
          </NoResultsHeader>
          <NoResultsSubtext>
              Try adjusting your condtion or filters
          </NoResultsSubtext>
        </NoResultsTextContainer>
      </NoResultsContainer>
    );
  }
  return null;
};

const ScreeningResultTable = ({
  results,
  onChangeSelected,
  columnsToRender,
}: ScreeningResultTableProps) => {
  const [sortedList, setSortedList] = useState(results);
  const navigate = useNavigate();
  const { instruments } = useInstrumentContext();
  const {
    frequency,
    setSelectedHashcodes,
    selectedHashcodes,
    searchQuery,
    screeningResults,
    condition,
  } =
    useScreeningContext();
  const { getAccessTokenSilently } = useAuth0();
  useEffect(() => {
    results.forEach((result) => {
      result.price = formatPrice(getSafeString(result.price));
    });
    setSortedList(initialResultSort(results));
  }, [results]);
  const memoColumns = useMemo(
    () => columnsToRender || columns,
    [columnsToRender]
  );

  const sortByMethods = createSortByMethods(sortedList);

  const getSortByMethod = (index: number) => {
    const sorted = sortByMethods[index as keyof typeof sortByMethods];
    setSortedList(sorted);
  };

  return (
    <GridContainer>
      <GridHeader data-testid="grid-header" $count={memoColumns.length}>
        {memoColumns.map((column, index) => {
          return (
            <GridColumn
              onClick={() => {
                getSortByMethod(index);
              }}
              key={index}
              data-testid={`header-col-${index}`}
            >
              {column}
            </GridColumn>
          );
        })}
      </GridHeader>
      {searchQuery !== "" && sortedList.length === 0 && (
        <EmptyState
          icon={HiMagnifyingGlassCircle}
          title={`Sorry, we couldn't find any
            instruments matching your search query`}
          description={"Try searching for something else"}
        />
      )}
      {renderNoResults(screeningResults, results)}
      {renderCells(
        sortedList,
        (result: ScreeningResult) => {
          const instrument = instruments.find(
            (instrument) => instrument.symbol === result.symbol
          );
          if (!instrument) return;
          navigate(`/?hashcode=${instrument?.hashcode}&frequency=${frequency}`);
        },
        (hashcode) => {
          let newHashcodes: string[] = [];
          if (selectedHashcodes.includes(hashcode)) {
            newHashcodes = [...selectedHashcodes].filter((selectedHashcode) => {
              return selectedHashcode !== hashcode;
            });
          } else {
            newHashcodes = [...selectedHashcodes, hashcode];
          }
          setSelectedHashcodes(newHashcodes);
          onChangeSelected && onChangeSelected(newHashcodes);
        },
        selectedHashcodes,
        memoColumns,
        frequency,
        condition,
        getAccessTokenSilently,
      )}
    </GridContainer>
  );
};

export default ScreeningResultTable;
