import { useAuth0 } from "@auth0/auth0-react";
// eslint-disable-next-line max-len
import { IconButton } from "@client/src/components/icon_button/IconButton.component";
import { Sheet } from "@client/src/components/sheet/Sheet.component";
import { useUserContext } from "@client/src/context/user/UserContext";
import { Modal } from "@client/src/sigma-ui/components/Modal";
import { isMobile } from "@client/src/utils/device";
import { ProductItem } from "@common/types";
import PaymentDialog from "@components/payment_dialog/PaymentDialog.component";
import PaymentPortal from "@components/payment_portal/PaymentPortal.component";
import { useSubscriptions } from "@hooks/useSubscriptions";
import React, { useEffect, useState } from "react";
import { HiXMark } from "react-icons/hi2";
import { Button as MdButton } from "../../sigma-ui/atoms/button";
import { Heading, Text } from "../../sigma-ui/atoms/typography";
import { openCancelConfirmation } from "../profile/Profile.component";
import {
  Body,
  Header,
  ItemOfferingsSection,
  ItemPlan,
  ItemPrice,
  MobileContainer,
  ModalContainer,
  ModalHeaderContainer,
  PurchaseScrollSnapContainer,
} from "./Purchase.style";

const PurchaseModal = (props: {
  open: boolean;
  onOpenChange?: (state: boolean) => void;
}) => {
  const { getAccessTokenSilently } = useAuth0();
  const [products, setProducts] = useState<ProductItem[]>([]);
  const [selectedProduct, setSelectedProduct] = useState<string | null>(null);

  useEffect(() => {
    getAccessTokenSilently().then((token: string) => {
      Promise.all([
        fetch("/api/secret-subscription", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }).then((res) => res.json()),
        fetch("/api/credits-products", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }).then((res) => res.json()),
      ])
        .then(([secretSubscriptionData, creditProductsData]) => {
          const combinedProducts =
        [...secretSubscriptionData, ...creditProductsData];
          setProducts(combinedProducts);
        });

      fetch("/api/credits", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then((res) => res.json())
        .then(() => {
          // Do nothing
        });
    });
  }, []);

  const RenderProducts = React.useCallback(
    (props: { open: boolean; onOpenChange?: (open: boolean) => void }) => {
      if (!isMobile())
        return (
          <PurchaseItemsDesktopModal
            open={props.open}
            products={products}
            onSelectProduct={setSelectedProduct}
            onOpenChange={props.onOpenChange}
          />
        );

      return (
        <PurchaseItemsMobileModal
          open={props.open}
          products={products}
          onSelectProduct={setSelectedProduct}
          onOpenChange={props.onOpenChange}
        />
      );
    },
    [selectedProduct, products, setSelectedProduct]
  );

  const RenderPaymentPortal = React.useCallback(() => {
    if (!selectedProduct) return null;

    return (
      <PaymentDialog
        title="Payment Portal"
        onClickOutside={() => setSelectedProduct(null)}
        visible={!!selectedProduct}
      >
        <PaymentPortal
          productId={selectedProduct}
          setProductId={setSelectedProduct}
        />
      </PaymentDialog>
    );
  }, [selectedProduct]);

  return (
    <>
      <RenderProducts
        open={props.open && !selectedProduct}
        onOpenChange={props.onOpenChange}
      />
      <RenderPaymentPortal />
    </>
  );
};

const getCancelSubscriptionButton = (
  setShowCancelSubModal: (show: boolean) => void
) => {
  return (
    <MdButton onClick={() => setShowCancelSubModal(true)}>
      Cancel Subscription
    </MdButton>
  );
};
const getPurchaseButton = (
  setSelectedProduct: (id: string | null) => void,
  option: ProductItem,
  hasActiveSubscription: boolean,
  setShowCancelSubModal: (show: boolean) => void
) => {
  if (option.policies.payment.recurring && hasActiveSubscription)
    return getCancelSubscriptionButton(setShowCancelSubModal);
  return (
    <MdButton onClick={() => setSelectedProduct(option.productId)}>
      Proceed to Payment
    </MdButton>
  );
};

export const PurchaseItemsDesktopModal = ({
  products,
  onSelectProduct: setSelectedProduct,
  onOpenChange,
  open,
}: {
  products: ProductItem[];
  open: boolean;
  onSelectProduct: (id: string | null) => void;
  onOpenChange?: (state: boolean) => void;
}) => {
  const [showCancelSubModal, setShowCancelSubModal] = useState(false);
  const { isSubscribed, activeSubscription } = useUserContext();
  const { cancelSubscription } = useSubscriptions();
  return (
    <Modal.Root open={open} onOpenChange={onOpenChange}>
      <ModalContainer>
        <ModalHeaderContainer>
          <Header>
            <Heading>Get notified in real-time</Heading>
            <Text>
              Purchase Alert packs to receive real-time Alerts on your trading
              strategies.
            </Text>
          </Header>
          <IconButton
            Icon={HiXMark}
            onClick={() => onOpenChange && onOpenChange(false)}
            tooltipContent="Close"
          />
        </ModalHeaderContainer>
        <Body>
          {showCancelSubModal &&
            openCancelConfirmation(
              () => setShowCancelSubModal(false),
              async () => {
                await cancelSubscription(activeSubscription);
                setShowCancelSubModal(false);
              },
              activeSubscription
            )}
          <PurchaseScrollSnapContainer>
            {products.map((option, index) => {
              if (!option.isAvailable) return null;
              return (
                <ItemPlan key={index}>
                  <Text $weight={400}>{option.productName}</Text>
                  <ItemPrice>
                    <Text $size="4xl" $bold>
                      ${option.policies.payment.price}
                    </Text>
                  </ItemPrice>
                  <ItemOfferingsSection />
                  {getPurchaseButton(
                    setSelectedProduct,
                    option,
                    isSubscribed,
                    setShowCancelSubModal
                  )}
                </ItemPlan>
              );
            })}
          </PurchaseScrollSnapContainer>
        </Body>
      </ModalContainer>
    </Modal.Root>
  );
};

export const PurchaseItemsMobileModal = ({
  products,
  onSelectProduct: setSelectedProduct,
  onOpenChange,
  open,
}: {
  products: ProductItem[];
  open: boolean;
  onSelectProduct: (id: string | null) => void;
  onOpenChange?: (state: boolean) => void;
}) => {
  const onClose = () => onOpenChange && onOpenChange(false);
  const [showCancelSubModal, setShowCancelSubModal] = useState(false);
  const { isSubscribed, activeSubscription } = useUserContext();
  const { cancelSubscription } = useSubscriptions();
  return (
    <>
      <Sheet
        isOpen={open}
        title=""
        onClose={onClose}
        topRightComponent={
          <IconButton Icon={HiXMark} onClick={onClose} tooltipContent="Close" />
        }
      >
        <MobileContainer>
          <Header>
            <Heading>Get notified in real-time</Heading>
            <Text>
              Purchase Alert packs to receive real-time Alerts on your trading
              strategies.
            </Text>
          </Header>
          <Body>
            {showCancelSubModal &&
              openCancelConfirmation(
                () => setShowCancelSubModal(false),
                async () => {
                  await cancelSubscription(activeSubscription);
                  setShowCancelSubModal(false);
                },
                activeSubscription
              )}
            <PurchaseScrollSnapContainer>
              {products.map((option, index) => {
                if (!option.isAvailable) return null;
                return (
                  <ItemPlan key={index}>
                    <Text $weight={400}>{option.productName}</Text>
                    <ItemPrice>
                      <Text $size="4xl" $bold>
                        ${option.policies.payment.price}
                      </Text>
                    </ItemPrice>
                    <ItemOfferingsSection />
                    {getPurchaseButton(
                      setSelectedProduct,
                      option,
                      isSubscribed,
                      setShowCancelSubModal
                    )}
                  </ItemPlan>
                );
              })}
            </PurchaseScrollSnapContainer>
          </Body>
        </MobileContainer>
      </Sheet>
    </>
  );
};

export default PurchaseModal;
