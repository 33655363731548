import { MagnifyingGlassChart } from "@assets/Icons";
import { Instrument } from "@generated/common/basic-types_pb";
import React, { useMemo, useState } from "react";
import { HiMagnifyingGlassCircle, HiXMark } from "react-icons/hi2";
import { BetaTag } from "../label_types/beta_tag/BetaTag.component";
import { EmptyState } from "../empty_state/EmptyState.component";
import InstrumentList from "../instrument_list/InstrumentList.component";
// eslint-disable-next-line max-len
import { INSTRUMENTS_TO_ANALYSE } from "../instrument_list/item/InstrumentItem.mock";
import { Searchbar } from "../searchbar/Searchbar.component";
import { Tabbar, TabData } from "../tabbar/Tabbar.component";
import { Tooltip } from "../tooltip/Tooltip.component";
import { mapInstrumentClassToName } from "./instrumentClassMappings";
import {
  createFuseInstance,
  getInstrumentsToRender,
} from "./InstrumentDialog.helpers";
import {
  CloseButton,
  Container,
  Dialog,
  Header,
  Title,
} from "./InstrumentDialog.style";

type Props = {
  instrumentList: Instrument.AsObject[];
  onSelectInstrument: (instrument: Instrument.AsObject) => void;
  onClickOutside?: () => void;
  visible: boolean;
};

type InstrumentEmptyStateProps = {
  searchQuery: string;
};

const generateTabData = (instrumentList: Instrument.AsObject[]): TabData[] => {
  const uniqueTypes = Array.from(
    new Set(instrumentList.map((instrument) => instrument.instrumentClass))
  );

  return [
    { title: "All", id: "All" },
    ...uniqueTypes
      .filter(
        (instrumentClass) =>
          mapInstrumentClassToName(instrumentClass) !== "EQUITY"
      )
      .map((instrumentClass) => ({
        title: capitalizeFirstLetter(mapInstrumentClassToName(instrumentClass)),
        id: instrumentClass.toString(),
      })),
    {
      title: "Equities",
      id: "equity",
      rightIcon: <BetaTag />,
    },
    {
      title: "AIMA Enabled",
      id: "aima",
      leftIcon: <MagnifyingGlassChart />,
      rightIcon: <BetaTag />,
    },
  ];
};
const capitalizeFirstLetter = (str: string): string => {
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};

const InstrumentEmptyState = (searchQuery: InstrumentEmptyStateProps) => {
  return (
    <EmptyState
      icon={HiMagnifyingGlassCircle}
      title={`Sorry, we couldn't find any instruments matching "
      ${searchQuery.searchQuery}"`}
      description={"Try searching for something else"}
    />
  );
};

const getAllInstruments = (instrumentsToRender: Instrument.AsObject[]) => {
  return instrumentsToRender;
};

const getAimaInstruments = (instrumentsToRender: Instrument.AsObject[]) => {
  return instrumentsToRender.filter((instrument) => {
    return INSTRUMENTS_TO_ANALYSE.includes(instrument.symbol);
  });
};

const getEquityInstruments = (instrumentsToRender: Instrument.AsObject[]) => {
  return instrumentsToRender.filter(({ instrumentClass }) => {
    return mapInstrumentClassToName(instrumentClass) === "EQUITY";
  });
};

const getInstrumentClassInstruments = (
  instrumentsToRender: Instrument.AsObject[],
  instrumentClass: string
) => {
  return instrumentsToRender.filter((instrument) => {
    return instrument.instrumentClass.toString() === instrumentClass;
  });
};

const tabToInstrumentListMap: Record<
  string,
  (instruments: Instrument.AsObject[]) => Instrument.AsObject[]
> = {
  All: getAllInstruments,
  equity: getEquityInstruments,
  aima: getAimaInstruments,
};

const getFilteredInstruments = (
  selectedTab: string,
  instrumentsToRender: Instrument.AsObject[]
) => {
  const instrumentListFunction = tabToInstrumentListMap[selectedTab];
  if (!instrumentListFunction)
    return getInstrumentClassInstruments(instrumentsToRender, selectedTab);
  return instrumentListFunction(instrumentsToRender);
};

const InstrumentDialog = ({
  instrumentList,
  onSelectInstrument,
  onClickOutside,
  visible,
}: Props) => {
  const [searchQuery, setSearchQuery] = React.useState("");
  const [selectedTab, setSelectedTab] = useState<string>("All");
  const fuseInstance = useMemo(() => {
    return createFuseInstance(instrumentList);
  }, [instrumentList]);
  const instrumentsToRender = useMemo(() => {
    return getInstrumentsToRender(instrumentList, searchQuery, fuseInstance);
  }, [instrumentList, searchQuery, fuseInstance]);

  const handleContainerClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    if (event.target === event.currentTarget && onClickOutside) {
      onClickOutside();
    }
  };

  const renderSearchResults = () => {
    if (instrumentsToRender.length === 0 && searchQuery) {
      return <InstrumentEmptyState searchQuery={searchQuery} />;
    }
    return (
      <InstrumentList
        instrumentList={filteredInstruments}
        onSelectInstrument={onSelectInstrument}
      />
    );
  };
  const handleTabSelection = (selectedTabId: string) => {
    setSelectedTab(selectedTabId);
  };

  const filteredInstruments = getFilteredInstruments(
    selectedTab,
    instrumentsToRender
  );

  if (!visible) return null;
  const tabData = generateTabData(instrumentList);
  return (
    <Container
      onClick={handleContainerClick}
      data-testid="instrument-container"
    >
      <Dialog data-testid="instrument-dialog">
        <Header>
          <Title>Select Instrument</Title>
          <Tooltip>
            <Tooltip.Item>
              <CloseButton data-testid="close-button" onClick={onClickOutside}>
                <HiXMark />
              </CloseButton>
            </Tooltip.Item>
            <Tooltip.Content text="Close" />
          </Tooltip>
        </Header>
        <Searchbar
          placeholder={"Search for an instrument"}
          onChange={(query) => setSearchQuery(query)}
          initialValue={searchQuery}
        />
        <Tabbar
          hugTabs={true}
          data={tabData}
          selectedTab={selectedTab}
          onTabSelectedChange={handleTabSelection}
        />
        {renderSearchResults()}
      </Dialog>
    </Container>
  );
};

export default InstrumentDialog;
