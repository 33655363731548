import { BaseChartParams } from "@client/src/charts/helpers/state";
import { ChartFactory, ChartHandler } from "@client/src/charts/helpers/types";
import { ScreeningMatch } from "@common/types";
import { DefaultTheme, useTheme } from "styled-components";
import { ChartData } from "@hooks/useFetchChart";
import { useEffect, useRef } from "react";

export const useChartHandler = <T extends BaseChartParams>(
  chartFactory: ChartFactory<T>,
  initParams: (elem: HTMLDivElement, theme: DefaultTheme) => T,
  chartData: ChartData,
  markers: ScreeningMatch[]
) => {
  const chartElemRef = useRef<HTMLDivElement>(null);
  const legendElemRef = useRef<HTMLDivElement>(null);
  const chartHandlerRef = useRef<ChartHandler>();
  const theme = useTheme();

  useEffect(() => {
    if (!chartElemRef.current) return;
    chartHandlerRef.current = chartFactory(
      initParams(chartElemRef.current, theme)
    );

    return () => {
      chartHandlerRef.current?.dispose();
    };
  }, []);

  useEffect(() => {
    const chart = chartHandlerRef.current?.chart;
    const elemRef = chartHandlerRef.current?.elemRef;
    if (!chart || !elemRef) return;

    const resizeChart = () => {
      chart.applyOptions({
        width: elemRef.clientWidth,
        height: elemRef.clientHeight,
        rightPriceScale: {
          minimumWidth: 81,
        },
      });
    };

    chart.timeScale().fitContent();
    resizeChart();
    window.addEventListener("resize", resizeChart);

    return () => window.removeEventListener("resize", resizeChart);
  }, [chartHandlerRef]);

  useEffect(() => {
    chartHandlerRef.current?.update(chartData, markers);
  }, [chartData, markers]);

  return [chartElemRef, legendElemRef];
};
