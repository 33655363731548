import {
  BellBetaIcon,
  CardBetaIcon,
  RocketBetaIcon,
} from "@client/src/assets/Icons";
// eslint-disable-next-line max-len
import { PlanDetails } from "@components/plan_page/ultimate_page/UltimatePage.helper";
import React from "react";

export const planDetailsInfo: PlanDetails[] = [
  {
    title: "Today",
    desc:
      "Unlock full access included in your Subscription to Leopard AI for" +
      " free!",
    icon: <RocketBetaIcon />,
  },
  {
    title: "Before the 30-day Free Trial Ends",
    desc: "We’ll let you know in advance that your trial is about to end",
    icon: <BellBetaIcon />,
  },
  {
    title: "When the 30-day Free Trial Ends",
    desc:
      "Your account will automatically be renewed at the Introductory" +
      " Offer discounted price",
    icon: <CardBetaIcon />,
  },
  {
    title: "1 month before the Introductory Discount Ends",
    desc:
      "It is at our discretion to end the Introductory Offer." +
      " We’ll let you know a month in advance that the Introductory Offer" +
      " is about to end.",
    icon: <BellBetaIcon />,
  },
  {
    title: "When the Introductory Offer Ends",
    desc:
      "Your account will automatically be renewed at the full price of" +
      " your Subscription",
    icon: <CardBetaIcon />,
  },
];
