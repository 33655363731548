import React from "react";
import { cva, cx, VariantProps } from "class-variance-authority";
import styled, { css } from "styled-components";

const BaseIconButtonContainer = styled.button`
  all: unset;
  padding: 0.5rem;
  display: inline-flex;
  cursor: pointer;
  height: fit-content;
  width: fit-content;
  border-radius: 999rem;

  &:hover {
    background-color: #0004;
    background-blend-mode: soft-light;
  }
`;

interface BaseIconButtonProps
  extends React.ComponentProps<typeof BaseIconButtonContainer> {
  icon: (p: { size: number }) => JSX.Element;
}

export const IconButton = ({
  icon: Icon,
  className,
  ...props
}: BaseIconButtonProps) => {
  return (
    <BaseIconButtonContainer
      type="button"
      {...props}
      className={`${className}`}
    >
      <Icon size={24} />
    </BaseIconButtonContainer>
  );
};

export const buttonVariants = cva("", {
  variants: {
    mode: {
      solid: css`
        background: var(--button-background-color);
        color: var(--button-foreground-color);
      `,
      outline: css`
        border: 1px solid var(--button-background-color);
        color: var(--button-background-color);
      `,
      ghost: css`
        color: var(--button-background-color);
      `,
    },
    size: {
      small: css`
        font-size: 12px;
        padding: 0.5rem 1rem;
      `,
      medium: css`
        font-size: 14px;
        padding: 0.625rem 0.75rem;
      `,
    },
  },
  defaultVariants: {
    mode: "solid",
    size: "medium",
  },
});

const BaseButton = styled.button<VariantProps<typeof buttonVariants>>`
  all: unset;
  font-family: "Poppins", sans-serif;
  display: flex;
  padding: 0.625rem 0.75rem;
  justify-content: center;
  align-items: center;
  border-radius: 0.5rem;
  gap: 0.5rem;
  cursor: pointer;
  white-space: nowrap;
  box-sizing: border-box;
  font-weight: 500;
  ${(p) => cx(buttonVariants({ size: p.size, mode: p.mode }))}
`;

const PrimaryButton = styled(BaseButton)`
  --button-foreground-color: ${(p) => p.theme.primary.foreground};
  --button-background-color: ${(p) => p.theme.primary.background};
`;

const SecondaryButton = styled(BaseButton)`
  --button-foreground-color: ${(p) => p.theme.secondary.foreground};
  --button-background-color: ${(p) => p.theme.secondary.background};
`;

const TextButton = styled(BaseButton)`
  --button-foreground-color: ${(p) => p.theme.text};
  --button-background-color: none;
`;

// type ButtonProps = React.ComponentProps<typeof PrimaryButton>;
export { PrimaryButton as Button, PrimaryButton, SecondaryButton, TextButton };
