import {
  CupAndHandle,
  CupAndHandleRevert,
  DoubleBottom,
  DoubleTop,
  HeadAndShoulders,
  InverseHeadAndShoulders,
  RoundingBottom,
  RoundingTop,
  TripleBottom,
  TripleTop,
} from "@assets/Icons";
import { PatternTypeMap } from "@generated/pattern/common_pb";
export const PatternLabel = {
  0: "Double Bottom",
  1: "Double Top",
  2: "Triple Bottom",
  3: "Triple Top",
  4: "Cup and Handle",
  5: "Inverse Cup and Handle",
  6: "Rounding Bottom",
  7: "Rounding Top",
  8: "Head and Shoulders",
  9: "Inverse Head and Shoulders",
  10: "Double Bottom Ascending",
  11: "Double Bottom Descending",
  12: "Double Top Ascending",
  13: "Double Top Descending",
};

export const getPatternLabel = (
  patternType: PatternTypeMap[keyof PatternTypeMap] | number
) => {
  if (patternType >= 0 && patternType <= 13) {
    return PatternLabel[patternType as PatternTypeMap[keyof PatternTypeMap]];
  }
  return "Unknown Pattern";
};

export const PatternIcons = [
  //get all Icons from @assets/Icons
  DoubleBottom,
  DoubleTop,
  TripleBottom, // placeholder TripleBottom
  TripleTop, // placeholder TripleTop
  CupAndHandle,
  CupAndHandleRevert,
  RoundingBottom,
  RoundingTop,
  HeadAndShoulders,
  InverseHeadAndShoulders,
  DoubleBottom, //placeholder DoubleBottomAscending,
  DoubleBottom, //placeholder DoubleBottomDescending,
  DoubleBottom, //placeholderDoubleTopAscending,
  DoubleBottom, //placeholder DoubleTopDescending,
];

export const isValidType = (type: number) => {
  return type >= 0 && type <= 13;
};
