import { textStyles } from "@utils/textStyles";
import styled from "styled-components";

export const SectionWrapper = styled.div`
  border-radius: 0.625rem;
  background-color: ${(props) => props.theme.secondaryBackground};
  display: flex;
  flex-direction: column;
`;

export const SectionTitle = styled.p`
  ${textStyles.textSmBold};
  color: ${(props) => props.theme.subtext};
  margin-bottom: 0.4rem;
`;
