import { MainChartParams } from "./state";
import {
  applyDefaultChartOptions,
  LwChartOptions,
} from "@client/src/charts/helpers/options";

export const getChartOptions = (
  params: MainChartParams,
): LwChartOptions => {
  return applyDefaultChartOptions(params, {
    height: params.elem.clientHeight,
    width: params.elem.clientWidth,
  });
};
