/**
 * <h1>SigmaScriptV1</h1>
 * <p>Takes one of a Condition, or an Indicator</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>SIGMA_SCRIPT_V1 = {CONDITION | INDICATOR }</code>
 * </p>
*/
type SigmaScriptV1 = {
  _type: "SIGMA_SCRIPT_V1";
  
  toString: () => string;
};

/**
 * <p>Create SigmaScriptV1 serializer object.</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>SIGMA_SCRIPT_V1 = {CONDITION | INDICATOR }</code>
 * </p>
 * 
 * @param value - one of a Condition, or an Indicator
 * @returns The created serializer object.
 */
function sigmaScriptV1(value: Condition | Indicator): SigmaScriptV1 {
  
  return {
    _type: "SIGMA_SCRIPT_V1",
    
    toString: () => (value.toString())
  }
}

/**
 * <h1>Indicator</h1>
 * <p>Takes a tuple comprising: one of a Brackets, or an Expressions, or a CrossAction, and zero or more a tuple comprising: one of a Sum, or a Difference, or a Product, or a Division, or an Exponentiation, and one of a Brackets, or an Expressions, or a CrossAction</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>INDICATOR = {(BRACKETS | EXPRESSIONS | CROSS_ACTION) ~ (MATHEMATICAL_OP ~ (BRACKETS | EXPRESSIONS | CROSS_ACTION))*}</code>
 * </p>
*/
type Indicator = {
  _type: "INDICATOR";
  
  toString: () => string;
};

/**
 * <p>Create Indicator serializer object.</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>INDICATOR = {(BRACKETS | EXPRESSIONS | CROSS_ACTION) ~ (MATHEMATICAL_OP ~ (BRACKETS | EXPRESSIONS | CROSS_ACTION))*}</code>
 * </p>
 * 
 * @param value - a tuple comprising: one of a Brackets, or an Expressions, or a CrossAction, and zero or more a tuple comprising: one of a Sum, or a Difference, or a Product, or a Division, or an Exponentiation, and one of a Brackets, or an Expressions, or a CrossAction
 * @returns The created serializer object.
 */
function indicator(value: [ Brackets | Expressions | CrossAction, ([ Sum | Difference | Product | Division | Exponentiation, Brackets | Expressions | CrossAction ])[] ]): Indicator {
  
  return {
    _type: "INDICATOR",
    
    toString: () => ([value[0].toString(), value[1].map((inner_value) => [inner_value[0].toString(), inner_value[1].toString()].join("")).join(" ")].join(""))
  }
}

/**
 * <h1>IndicatorExpression</h1>
 * <p>Takes a tuple comprising: one of a Brackets, or an Expressions, or a CrossAction, and zero or more a tuple comprising: one of a Sum, or a Difference, or a Product, or a Division, or an Exponentiation, and one of a Brackets, or an Expressions, or a CrossAction</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>INDICATOR_EXPRESSION = {( "SigmaIndicator(" | "eval(" ) ~ INDICATOR ~ ")" }</code>
 * </p>
*/
type IndicatorExpression = {
  _type: "INDICATOR_EXPRESSION";
  
  toString: () => string;
};

/**
 * <p>Create IndicatorExpression serializer object.</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>INDICATOR_EXPRESSION = {( "SigmaIndicator(" | "eval(" ) ~ INDICATOR ~ ")" }</code>
 * </p>
 * 
 * @param value - a tuple comprising: one of a Brackets, or an Expressions, or a CrossAction, and zero or more a tuple comprising: one of a Sum, or a Difference, or a Product, or a Division, or an Exponentiation, and one of a Brackets, or an Expressions, or a CrossAction
 * @returns The created serializer object.
 */
function indicatorExpression(value: [ Brackets | Expressions | CrossAction, ([ Sum | Difference | Product | Division | Exponentiation, Brackets | Expressions | CrossAction ])[] ]): IndicatorExpression {
  
  return {
    _type: "INDICATOR_EXPRESSION",
    
    toString: () => (["SigmaIndicator(", value[0].toString() + value[1].flatMap((inner_value) => [inner_value[0].toString(), inner_value[1].toString()].join("")).join(""), ")"].join(""))
  }
}

/**
 * <h1>ComparisonExpression</h1>
 * <p>Takes a tuple comprising: a tuple comprising: one of a LaggedExprs, or a ComputableExpr, or a Float, or an IndicatorExpression, or a Brackets, or a MathematicalFunctions, and zero or more a tuple comprising: one of a Sum, or a Difference, or a Product, or a Division, or an Exponentiation, and one of a LaggedExprs, or a ComputableExpr, or a Float, or an IndicatorExpression, or a Brackets, or a MathematicalFunctions, and one of a Gte, or a Gt, or a Lte, or a Lt, or an Eq, and a tuple comprising: one of a LaggedExprs, or a ComputableExpr, or a Float, or an IndicatorExpression, or a Brackets, or a MathematicalFunctions, and zero or more a tuple comprising: one of a Sum, or a Difference, or a Product, or a Division, or an Exponentiation, and one of a LaggedExprs, or a ComputableExpr, or a Float, or an IndicatorExpression, or a Brackets, or a MathematicalFunctions</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>COMPARISON_EXPRESSION = {(EXPRESSIONS ~ (MATHEMATICAL_OP ~ EXPRESSIONS)*) ~ CMP ~ (EXPRESSIONS ~ (MATHEMATICAL_OP ~ EXPRESSIONS)*)
 * }</code>
 * </p>
*/
type ComparisonExpression = {
  _type: "COMPARISON_EXPRESSION";
  
  toString: () => string;
};

/**
 * <p>Create ComparisonExpression serializer object.</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>COMPARISON_EXPRESSION = {(EXPRESSIONS ~ (MATHEMATICAL_OP ~ EXPRESSIONS)*) ~ CMP ~ (EXPRESSIONS ~ (MATHEMATICAL_OP ~ EXPRESSIONS)*)
 * }</code>
 * </p>
 * 
 * @param value - a tuple comprising: a tuple comprising: one of a LaggedExprs, or a ComputableExpr, or a Float, or an IndicatorExpression, or a Brackets, or a MathematicalFunctions, and zero or more a tuple comprising: one of a Sum, or a Difference, or a Product, or a Division, or an Exponentiation, and one of a LaggedExprs, or a ComputableExpr, or a Float, or an IndicatorExpression, or a Brackets, or a MathematicalFunctions, and one of a Gte, or a Gt, or a Lte, or a Lt, or an Eq, and a tuple comprising: one of a LaggedExprs, or a ComputableExpr, or a Float, or an IndicatorExpression, or a Brackets, or a MathematicalFunctions, and zero or more a tuple comprising: one of a Sum, or a Difference, or a Product, or a Division, or an Exponentiation, and one of a LaggedExprs, or a ComputableExpr, or a Float, or an IndicatorExpression, or a Brackets, or a MathematicalFunctions
 * @returns The created serializer object.
 */
function comparisonExpression(value: [ [ LaggedExprs | ComputableExpr | Float | IndicatorExpression | Brackets | MathematicalFunctions, ([ Sum | Difference | Product | Division | Exponentiation, LaggedExprs | ComputableExpr | Float | IndicatorExpression | Brackets | MathematicalFunctions ])[] ], Gte | Gt | Lte | Lt | Eq, [ LaggedExprs | ComputableExpr | Float | IndicatorExpression | Brackets | MathematicalFunctions, ([ Sum | Difference | Product | Division | Exponentiation, LaggedExprs | ComputableExpr | Float | IndicatorExpression | Brackets | MathematicalFunctions ])[] ] ]): ComparisonExpression {
  
  return {
    _type: "COMPARISON_EXPRESSION",
    
    toString: () => ([[value[0][0].toString(), value[0][1].map((inner_value) => [inner_value[0].toString(), inner_value[1].toString()].join("")).join(" ")].join(""), value[1].toString(), [value[2][0].toString(), value[2][1].map((inner_value) => [inner_value[0].toString(), inner_value[1].toString()].join("")).join(" ")].join("")].join(""))
  }
}

/**
 * <h1>Expression</h1>
 * <p>Takes one of one of an AllPatterns, or a CrossAction, or a ComparisonExpression, or a Brackets</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>EXPRESSION = _{(ALL_PATTERNS | CROSS_ACTION) |
 * 	// Comparison
 * 	COMPARISON_EXPRESSION |
 * 	// Recursion
 * 	BRACKETS
 * }</code>
 * </p>
*/
type Expression = {
  _type: "EXPRESSION";
  
  toString: () => string;
};

/**
 * <p>Create Expression serializer object.</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>EXPRESSION = _{(ALL_PATTERNS | CROSS_ACTION) |
 * 	// Comparison
 * 	COMPARISON_EXPRESSION |
 * 	// Recursion
 * 	BRACKETS
 * }</code>
 * </p>
 * 
 * @param value - one of one of an AllPatterns, or a CrossAction, or a ComparisonExpression, or a Brackets
 * @returns The created serializer object.
 */
function expression(value: AllPatterns | CrossAction | ComparisonExpression | Brackets): Expression {
  
  return {
    _type: "EXPRESSION",
    
    toString: () => (value.toString())
  }
}

/**
 * <h1>Condition</h1>
 * <p>Takes a tuple comprising: one of one of an AllPatterns, or a CrossAction, or a ComparisonExpression, or a Brackets, and zero or more a tuple comprising: a LogicalOp, and one of one of an AllPatterns, or a CrossAction, or a ComparisonExpression, or a Brackets</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>CONDITION = {EXPRESSION ~ (LOGICAL_OP{1,2} ~ EXPRESSION)*}</code>
 * </p>
*/
type Condition = {
  _type: "CONDITION";
  
  toString: () => string;
};

/**
 * <p>Create Condition serializer object.</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>CONDITION = {EXPRESSION ~ (LOGICAL_OP{1,2} ~ EXPRESSION)*}</code>
 * </p>
 * 
 * @param value - a tuple comprising: one of one of an AllPatterns, or a CrossAction, or a ComparisonExpression, or a Brackets, and zero or more a tuple comprising: a LogicalOp, and one of one of an AllPatterns, or a CrossAction, or a ComparisonExpression, or a Brackets
 * @returns The created serializer object.
 */
function condition(value: [ AllPatterns | CrossAction | ComparisonExpression | Brackets, ([ LogicalOp, AllPatterns | CrossAction | ComparisonExpression | Brackets ])[] ]): Condition {
  
  return {
    _type: "CONDITION",
    
    toString: () => ([value[0].toString(), value[1].map((inner_value) => [inner_value[0] ? inner_value[0].toString() : null, inner_value[1].toString()].join("")).join(" ")].join(""))
  }
}

/**
 * <h1>Whitespace</h1>
 * <p>Takes </p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>WHITESPACE = _{" " | "," }</code>
 * </p>
*/
type Whitespace = {
  _type: "WHITESPACE";
  
  toString: () => string;
};

/**
 * <p>Create Whitespace serializer object.</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>WHITESPACE = _{" " | "," }</code>
 * </p>
 * 
 * @returns The created serializer object.
 */
function whitespace(): Whitespace {
  
  return {
    _type: "WHITESPACE",
    
    toString: () => (" ")
  }
}

/**
 * <h1>Float</h1>
 * <p>Takes a number.</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>FLOAT = { ASCII_DIGIT }</code>
 * </p>
*/
type Float = {
  _type: "FLOAT";
  
  toString: () => string;
};

/**
 * <p>Create Float serializer object.</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>FLOAT = { ASCII_DIGIT }</code>
 * </p>
 * 
 * @param value - a number.
 * @returns The created serializer object.
 */
function float(value: number): Float {
  
  return {
    _type: "FLOAT",
    
    toString: () => (value.toString(10))
  }
}

/**
 * <h1>Window</h1>
 * <p>Takes a number.</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>WINDOW = { ASCII_DIGIT }</code>
 * </p>
*/
type Window = {
  _type: "WINDOW";
  
  toString: () => string;
};

/**
 * <p>Create Window serializer object.</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>WINDOW = { ASCII_DIGIT }</code>
 * </p>
 * 
 * @param value - a number.
 * @returns The created serializer object.
 */
function window(value: number): Window {
  
  return {
    _type: "WINDOW",
    
    toString: () => (value.toString(10))
  }
}

/**
 * <h1>HalfWindow</h1>
 * <p>Takes a number.</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>HALF_WINDOW = { ASCII_DIGIT }</code>
 * </p>
*/
type HalfWindow = {
  _type: "HALF_WINDOW";
  
  toString: () => string;
};

/**
 * <p>Create HalfWindow serializer object.</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>HALF_WINDOW = { ASCII_DIGIT }</code>
 * </p>
 * 
 * @param value - a number.
 * @returns The created serializer object.
 */
function halfWindow(value: number): HalfWindow {
  
  return {
    _type: "HALF_WINDOW",
    
    toString: () => (value.toString(10))
  }
}

/**
 * <h1>LaggedWindow</h1>
 * <p>Takes a number.</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>LAGGED_WINDOW = { ASCII_DIGIT }</code>
 * </p>
*/
type LaggedWindow = {
  _type: "LAGGED_WINDOW";
  
  toString: () => string;
};

/**
 * <p>Create LaggedWindow serializer object.</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>LAGGED_WINDOW = { ASCII_DIGIT }</code>
 * </p>
 * 
 * @param value - a number.
 * @returns The created serializer object.
 */
function laggedWindow(value: number): LaggedWindow {
  
  return {
    _type: "LAGGED_WINDOW",
    
    toString: () => (value.toString(10))
  }
}

/**
 * <h1>ZeroToOne</h1>
 * <p>Takes a number.</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>ZERO_TO_ONE = ASCII_DIGIT</code>
 * </p>
*/
type ZeroToOne = {
  _type: "ZERO_TO_ONE";
  
  toString: () => string;
};

/**
 * <p>Create ZeroToOne serializer object.</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>ZERO_TO_ONE = ASCII_DIGIT</code>
 * </p>
 * 
 * @param value - a number.
 * @returns The created serializer object.
 */
function zeroToOne(value: number): ZeroToOne {
  
  return {
    _type: "ZERO_TO_ONE",
    
    toString: () => (value.toString(10))
  }
}

/**
 * <h1>ZeroToHalf</h1>
 * <p>Takes a number.</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>ZERO_TO_HALF = ASCII_DIGIT</code>
 * </p>
*/
type ZeroToHalf = {
  _type: "ZERO_TO_HALF";
  
  toString: () => string;
};

/**
 * <p>Create ZeroToHalf serializer object.</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>ZERO_TO_HALF = ASCII_DIGIT</code>
 * </p>
 * 
 * @param value - a number.
 * @returns The created serializer object.
 */
function zeroToHalf(value: number): ZeroToHalf {
  
  return {
    _type: "ZERO_TO_HALF",
    
    toString: () => (value.toString(10))
  }
}

/**
 * <h1>Sum</h1>
 * <p>Takes </p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>SUM = {"+" }</code>
 * </p>
*/
type Sum = {
  _type: "SUM";
  
  toString: () => string;
};

/**
 * <p>Create Sum serializer object.</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>SUM = {"+" }</code>
 * </p>
 * 
 * @returns The created serializer object.
 */
function sum(): Sum {
  
  return {
    _type: "SUM",
    
    toString: () => ("+")
  }
}

/**
 * <h1>Difference</h1>
 * <p>Takes </p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>DIFFERENCE = {"-" }</code>
 * </p>
*/
type Difference = {
  _type: "DIFFERENCE";
  
  toString: () => string;
};

/**
 * <p>Create Difference serializer object.</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>DIFFERENCE = {"-" }</code>
 * </p>
 * 
 * @returns The created serializer object.
 */
function difference(): Difference {
  
  return {
    _type: "DIFFERENCE",
    
    toString: () => ("-")
  }
}

/**
 * <h1>Product</h1>
 * <p>Takes </p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>PRODUCT = {"*" }</code>
 * </p>
*/
type Product = {
  _type: "PRODUCT";
  
  toString: () => string;
};

/**
 * <p>Create Product serializer object.</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>PRODUCT = {"*" }</code>
 * </p>
 * 
 * @returns The created serializer object.
 */
function product(): Product {
  
  return {
    _type: "PRODUCT",
    
    toString: () => ("*")
  }
}

/**
 * <h1>Division</h1>
 * <p>Takes </p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>DIVISION = {"/" }</code>
 * </p>
*/
type Division = {
  _type: "DIVISION";
  
  toString: () => string;
};

/**
 * <p>Create Division serializer object.</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>DIVISION = {"/" }</code>
 * </p>
 * 
 * @returns The created serializer object.
 */
function division(): Division {
  
  return {
    _type: "DIVISION",
    
    toString: () => ("/")
  }
}

/**
 * <h1>Exponentiation</h1>
 * <p>Takes </p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>EXPONENTIATION = {"^" }</code>
 * </p>
*/
type Exponentiation = {
  _type: "EXPONENTIATION";
  
  toString: () => string;
};

/**
 * <p>Create Exponentiation serializer object.</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>EXPONENTIATION = {"^" }</code>
 * </p>
 * 
 * @returns The created serializer object.
 */
function exponentiation(): Exponentiation {
  
  return {
    _type: "EXPONENTIATION",
    
    toString: () => ("^")
  }
}

/**
 * <h1>MathematicalOp</h1>
 * <p>Takes one of a Sum, or a Difference, or a Product, or a Division, or an Exponentiation</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>MATHEMATICAL_OP = _{SUM | DIFFERENCE | PRODUCT | DIVISION | EXPONENTIATION }</code>
 * </p>
*/
type MathematicalOp = {
  _type: "MATHEMATICAL_OP";
  
  toString: () => string;
};

/**
 * <p>Create MathematicalOp serializer object.</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>MATHEMATICAL_OP = _{SUM | DIFFERENCE | PRODUCT | DIVISION | EXPONENTIATION }</code>
 * </p>
 * 
 * @param value - one of a Sum, or a Difference, or a Product, or a Division, or an Exponentiation
 * @returns The created serializer object.
 */
function mathematicalOp(value: Sum | Difference | Product | Division | Exponentiation): MathematicalOp {
  
  return {
    _type: "MATHEMATICAL_OP",
    
    toString: () => (value.toString())
  }
}

/**
 * <h1>MathExp</h1>
 * <p>Takes one of a LaggedExprs, or a ComputableExpr, or a Float, or an IndicatorExpression, or a Brackets, or a MathematicalFunctions</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>MATH_EXP = {"exp(" ~ EXPRESSIONS ~ ")" }</code>
 * </p>
*/
type MathExp = {
  _type: "MATH_EXP";
  
  toString: () => string;
};

/**
 * <p>Create MathExp serializer object.</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>MATH_EXP = {"exp(" ~ EXPRESSIONS ~ ")" }</code>
 * </p>
 * 
 * @param value - one of a LaggedExprs, or a ComputableExpr, or a Float, or an IndicatorExpression, or a Brackets, or a MathematicalFunctions
 * @returns The created serializer object.
 */
function mathExp(value: LaggedExprs | ComputableExpr | Float | IndicatorExpression | Brackets | MathematicalFunctions): MathExp {
  
  return {
    _type: "MATH_EXP",
    
    toString: () => (["exp(", value.toString(), ")"].join(""))
  }
}

/**
 * <h1>MathExp2</h1>
 * <p>Takes one of a LaggedExprs, or a ComputableExpr, or a Float, or an IndicatorExpression, or a Brackets, or a MathematicalFunctions</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>MATH_EXP2 = {"exp2(" ~ EXPRESSIONS ~ ")" }</code>
 * </p>
*/
type MathExp2 = {
  _type: "MATH_EXP2";
  
  toString: () => string;
};

/**
 * <p>Create MathExp2 serializer object.</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>MATH_EXP2 = {"exp2(" ~ EXPRESSIONS ~ ")" }</code>
 * </p>
 * 
 * @param value - one of a LaggedExprs, or a ComputableExpr, or a Float, or an IndicatorExpression, or a Brackets, or a MathematicalFunctions
 * @returns The created serializer object.
 */
function mathExp2(value: LaggedExprs | ComputableExpr | Float | IndicatorExpression | Brackets | MathematicalFunctions): MathExp2 {
  
  return {
    _type: "MATH_EXP2",
    
    toString: () => (["exp2(", value.toString(), ")"].join(""))
  }
}

/**
 * <h1>MathLn</h1>
 * <p>Takes one of a LaggedExprs, or a ComputableExpr, or a Float, or an IndicatorExpression, or a Brackets, or a MathematicalFunctions</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>MATH_LN = {"ln(" ~ EXPRESSIONS ~ ")" }</code>
 * </p>
*/
type MathLn = {
  _type: "MATH_LN";
  
  toString: () => string;
};

/**
 * <p>Create MathLn serializer object.</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>MATH_LN = {"ln(" ~ EXPRESSIONS ~ ")" }</code>
 * </p>
 * 
 * @param value - one of a LaggedExprs, or a ComputableExpr, or a Float, or an IndicatorExpression, or a Brackets, or a MathematicalFunctions
 * @returns The created serializer object.
 */
function mathLn(value: LaggedExprs | ComputableExpr | Float | IndicatorExpression | Brackets | MathematicalFunctions): MathLn {
  
  return {
    _type: "MATH_LN",
    
    toString: () => (["ln(", value.toString(), ")"].join(""))
  }
}

/**
 * <h1>MathLog2</h1>
 * <p>Takes one of a LaggedExprs, or a ComputableExpr, or a Float, or an IndicatorExpression, or a Brackets, or a MathematicalFunctions</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>MATH_LOG2 = {"log2(" ~ EXPRESSIONS ~ ")" }</code>
 * </p>
*/
type MathLog2 = {
  _type: "MATH_LOG2";
  
  toString: () => string;
};

/**
 * <p>Create MathLog2 serializer object.</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>MATH_LOG2 = {"log2(" ~ EXPRESSIONS ~ ")" }</code>
 * </p>
 * 
 * @param value - one of a LaggedExprs, or a ComputableExpr, or a Float, or an IndicatorExpression, or a Brackets, or a MathematicalFunctions
 * @returns The created serializer object.
 */
function mathLog2(value: LaggedExprs | ComputableExpr | Float | IndicatorExpression | Brackets | MathematicalFunctions): MathLog2 {
  
  return {
    _type: "MATH_LOG2",
    
    toString: () => (["log2(", value.toString(), ")"].join(""))
  }
}

/**
 * <h1>MathLog10</h1>
 * <p>Takes one of a LaggedExprs, or a ComputableExpr, or a Float, or an IndicatorExpression, or a Brackets, or a MathematicalFunctions</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>MATH_LOG10 = {"log10(" ~ EXPRESSIONS ~ ")" }</code>
 * </p>
*/
type MathLog10 = {
  _type: "MATH_LOG10";
  
  toString: () => string;
};

/**
 * <p>Create MathLog10 serializer object.</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>MATH_LOG10 = {"log10(" ~ EXPRESSIONS ~ ")" }</code>
 * </p>
 * 
 * @param value - one of a LaggedExprs, or a ComputableExpr, or a Float, or an IndicatorExpression, or a Brackets, or a MathematicalFunctions
 * @returns The created serializer object.
 */
function mathLog10(value: LaggedExprs | ComputableExpr | Float | IndicatorExpression | Brackets | MathematicalFunctions): MathLog10 {
  
  return {
    _type: "MATH_LOG10",
    
    toString: () => (["log10(", value.toString(), ")"].join(""))
  }
}

/**
 * <h1>MathSqrt</h1>
 * <p>Takes one of a LaggedExprs, or a ComputableExpr, or a Float, or an IndicatorExpression, or a Brackets, or a MathematicalFunctions</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>MATH_SQRT = {"sqrt(" ~ EXPRESSIONS ~ ")" }</code>
 * </p>
*/
type MathSqrt = {
  _type: "MATH_SQRT";
  
  toString: () => string;
};

/**
 * <p>Create MathSqrt serializer object.</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>MATH_SQRT = {"sqrt(" ~ EXPRESSIONS ~ ")" }</code>
 * </p>
 * 
 * @param value - one of a LaggedExprs, or a ComputableExpr, or a Float, or an IndicatorExpression, or a Brackets, or a MathematicalFunctions
 * @returns The created serializer object.
 */
function mathSqrt(value: LaggedExprs | ComputableExpr | Float | IndicatorExpression | Brackets | MathematicalFunctions): MathSqrt {
  
  return {
    _type: "MATH_SQRT",
    
    toString: () => (["sqrt(", value.toString(), ")"].join(""))
  }
}

/**
 * <h1>MathCbrt</h1>
 * <p>Takes one of a LaggedExprs, or a ComputableExpr, or a Float, or an IndicatorExpression, or a Brackets, or a MathematicalFunctions</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>MATH_CBRT = {"cbrt(" ~ EXPRESSIONS ~ ")" }</code>
 * </p>
*/
type MathCbrt = {
  _type: "MATH_CBRT";
  
  toString: () => string;
};

/**
 * <p>Create MathCbrt serializer object.</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>MATH_CBRT = {"cbrt(" ~ EXPRESSIONS ~ ")" }</code>
 * </p>
 * 
 * @param value - one of a LaggedExprs, or a ComputableExpr, or a Float, or an IndicatorExpression, or a Brackets, or a MathematicalFunctions
 * @returns The created serializer object.
 */
function mathCbrt(value: LaggedExprs | ComputableExpr | Float | IndicatorExpression | Brackets | MathematicalFunctions): MathCbrt {
  
  return {
    _type: "MATH_CBRT",
    
    toString: () => (["cbrt(", value.toString(), ")"].join(""))
  }
}

/**
 * <h1>MathCos</h1>
 * <p>Takes one of a LaggedExprs, or a ComputableExpr, or a Float, or an IndicatorExpression, or a Brackets, or a MathematicalFunctions</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>MATH_COS = {"cos(" ~ EXPRESSIONS ~ ")" }</code>
 * </p>
*/
type MathCos = {
  _type: "MATH_COS";
  
  toString: () => string;
};

/**
 * <p>Create MathCos serializer object.</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>MATH_COS = {"cos(" ~ EXPRESSIONS ~ ")" }</code>
 * </p>
 * 
 * @param value - one of a LaggedExprs, or a ComputableExpr, or a Float, or an IndicatorExpression, or a Brackets, or a MathematicalFunctions
 * @returns The created serializer object.
 */
function mathCos(value: LaggedExprs | ComputableExpr | Float | IndicatorExpression | Brackets | MathematicalFunctions): MathCos {
  
  return {
    _type: "MATH_COS",
    
    toString: () => (["cos(", value.toString(), ")"].join(""))
  }
}

/**
 * <h1>MathAcos</h1>
 * <p>Takes one of a LaggedExprs, or a ComputableExpr, or a Float, or an IndicatorExpression, or a Brackets, or a MathematicalFunctions</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>MATH_ACOS = {"acos(" ~ EXPRESSIONS ~ ")" }</code>
 * </p>
*/
type MathAcos = {
  _type: "MATH_ACOS";
  
  toString: () => string;
};

/**
 * <p>Create MathAcos serializer object.</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>MATH_ACOS = {"acos(" ~ EXPRESSIONS ~ ")" }</code>
 * </p>
 * 
 * @param value - one of a LaggedExprs, or a ComputableExpr, or a Float, or an IndicatorExpression, or a Brackets, or a MathematicalFunctions
 * @returns The created serializer object.
 */
function mathAcos(value: LaggedExprs | ComputableExpr | Float | IndicatorExpression | Brackets | MathematicalFunctions): MathAcos {
  
  return {
    _type: "MATH_ACOS",
    
    toString: () => (["acos(", value.toString(), ")"].join(""))
  }
}

/**
 * <h1>MathCosh</h1>
 * <p>Takes one of a LaggedExprs, or a ComputableExpr, or a Float, or an IndicatorExpression, or a Brackets, or a MathematicalFunctions</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>MATH_COSH = {"cosh(" ~ EXPRESSIONS ~ ")" }</code>
 * </p>
*/
type MathCosh = {
  _type: "MATH_COSH";
  
  toString: () => string;
};

/**
 * <p>Create MathCosh serializer object.</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>MATH_COSH = {"cosh(" ~ EXPRESSIONS ~ ")" }</code>
 * </p>
 * 
 * @param value - one of a LaggedExprs, or a ComputableExpr, or a Float, or an IndicatorExpression, or a Brackets, or a MathematicalFunctions
 * @returns The created serializer object.
 */
function mathCosh(value: LaggedExprs | ComputableExpr | Float | IndicatorExpression | Brackets | MathematicalFunctions): MathCosh {
  
  return {
    _type: "MATH_COSH",
    
    toString: () => (["cosh(", value.toString(), ")"].join(""))
  }
}

/**
 * <h1>MathSin</h1>
 * <p>Takes one of a LaggedExprs, or a ComputableExpr, or a Float, or an IndicatorExpression, or a Brackets, or a MathematicalFunctions</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>MATH_SIN = {"sin(" ~ EXPRESSIONS ~ ")" }</code>
 * </p>
*/
type MathSin = {
  _type: "MATH_SIN";
  
  toString: () => string;
};

/**
 * <p>Create MathSin serializer object.</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>MATH_SIN = {"sin(" ~ EXPRESSIONS ~ ")" }</code>
 * </p>
 * 
 * @param value - one of a LaggedExprs, or a ComputableExpr, or a Float, or an IndicatorExpression, or a Brackets, or a MathematicalFunctions
 * @returns The created serializer object.
 */
function mathSin(value: LaggedExprs | ComputableExpr | Float | IndicatorExpression | Brackets | MathematicalFunctions): MathSin {
  
  return {
    _type: "MATH_SIN",
    
    toString: () => (["sin(", value.toString(), ")"].join(""))
  }
}

/**
 * <h1>MathAsin</h1>
 * <p>Takes one of a LaggedExprs, or a ComputableExpr, or a Float, or an IndicatorExpression, or a Brackets, or a MathematicalFunctions</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>MATH_ASIN = {"asin(" ~ EXPRESSIONS ~ ")" }</code>
 * </p>
*/
type MathAsin = {
  _type: "MATH_ASIN";
  
  toString: () => string;
};

/**
 * <p>Create MathAsin serializer object.</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>MATH_ASIN = {"asin(" ~ EXPRESSIONS ~ ")" }</code>
 * </p>
 * 
 * @param value - one of a LaggedExprs, or a ComputableExpr, or a Float, or an IndicatorExpression, or a Brackets, or a MathematicalFunctions
 * @returns The created serializer object.
 */
function mathAsin(value: LaggedExprs | ComputableExpr | Float | IndicatorExpression | Brackets | MathematicalFunctions): MathAsin {
  
  return {
    _type: "MATH_ASIN",
    
    toString: () => (["asin(", value.toString(), ")"].join(""))
  }
}

/**
 * <h1>MathSinh</h1>
 * <p>Takes one of a LaggedExprs, or a ComputableExpr, or a Float, or an IndicatorExpression, or a Brackets, or a MathematicalFunctions</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>MATH_SINH = {"sinh(" ~ EXPRESSIONS ~ ")" }</code>
 * </p>
*/
type MathSinh = {
  _type: "MATH_SINH";
  
  toString: () => string;
};

/**
 * <p>Create MathSinh serializer object.</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>MATH_SINH = {"sinh(" ~ EXPRESSIONS ~ ")" }</code>
 * </p>
 * 
 * @param value - one of a LaggedExprs, or a ComputableExpr, or a Float, or an IndicatorExpression, or a Brackets, or a MathematicalFunctions
 * @returns The created serializer object.
 */
function mathSinh(value: LaggedExprs | ComputableExpr | Float | IndicatorExpression | Brackets | MathematicalFunctions): MathSinh {
  
  return {
    _type: "MATH_SINH",
    
    toString: () => (["sinh(", value.toString(), ")"].join(""))
  }
}

/**
 * <h1>MathTan</h1>
 * <p>Takes one of a LaggedExprs, or a ComputableExpr, or a Float, or an IndicatorExpression, or a Brackets, or a MathematicalFunctions</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>MATH_TAN = {"tan(" ~ EXPRESSIONS ~ ")" }</code>
 * </p>
*/
type MathTan = {
  _type: "MATH_TAN";
  
  toString: () => string;
};

/**
 * <p>Create MathTan serializer object.</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>MATH_TAN = {"tan(" ~ EXPRESSIONS ~ ")" }</code>
 * </p>
 * 
 * @param value - one of a LaggedExprs, or a ComputableExpr, or a Float, or an IndicatorExpression, or a Brackets, or a MathematicalFunctions
 * @returns The created serializer object.
 */
function mathTan(value: LaggedExprs | ComputableExpr | Float | IndicatorExpression | Brackets | MathematicalFunctions): MathTan {
  
  return {
    _type: "MATH_TAN",
    
    toString: () => (["tan(", value.toString(), ")"].join(""))
  }
}

/**
 * <h1>MathAtan</h1>
 * <p>Takes one of a LaggedExprs, or a ComputableExpr, or a Float, or an IndicatorExpression, or a Brackets, or a MathematicalFunctions</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>MATH_ATAN = {"atan(" ~ EXPRESSIONS ~ ")" }</code>
 * </p>
*/
type MathAtan = {
  _type: "MATH_ATAN";
  
  toString: () => string;
};

/**
 * <p>Create MathAtan serializer object.</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>MATH_ATAN = {"atan(" ~ EXPRESSIONS ~ ")" }</code>
 * </p>
 * 
 * @param value - one of a LaggedExprs, or a ComputableExpr, or a Float, or an IndicatorExpression, or a Brackets, or a MathematicalFunctions
 * @returns The created serializer object.
 */
function mathAtan(value: LaggedExprs | ComputableExpr | Float | IndicatorExpression | Brackets | MathematicalFunctions): MathAtan {
  
  return {
    _type: "MATH_ATAN",
    
    toString: () => (["atan(", value.toString(), ")"].join(""))
  }
}

/**
 * <h1>MathTanh</h1>
 * <p>Takes one of a LaggedExprs, or a ComputableExpr, or a Float, or an IndicatorExpression, or a Brackets, or a MathematicalFunctions</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>MATH_TANH = {"tanh(" ~ EXPRESSIONS ~ ")" }</code>
 * </p>
*/
type MathTanh = {
  _type: "MATH_TANH";
  
  toString: () => string;
};

/**
 * <p>Create MathTanh serializer object.</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>MATH_TANH = {"tanh(" ~ EXPRESSIONS ~ ")" }</code>
 * </p>
 * 
 * @param value - one of a LaggedExprs, or a ComputableExpr, or a Float, or an IndicatorExpression, or a Brackets, or a MathematicalFunctions
 * @returns The created serializer object.
 */
function mathTanh(value: LaggedExprs | ComputableExpr | Float | IndicatorExpression | Brackets | MathematicalFunctions): MathTanh {
  
  return {
    _type: "MATH_TANH",
    
    toString: () => (["tanh(", value.toString(), ")"].join(""))
  }
}

/**
 * <h1>MathAbs</h1>
 * <p>Takes one of a LaggedExprs, or a ComputableExpr, or a Float, or an IndicatorExpression, or a Brackets, or a MathematicalFunctions</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>MATH_ABS = {"abs(" ~ EXPRESSIONS ~ ")" }</code>
 * </p>
*/
type MathAbs = {
  _type: "MATH_ABS";
  
  toString: () => string;
};

/**
 * <p>Create MathAbs serializer object.</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>MATH_ABS = {"abs(" ~ EXPRESSIONS ~ ")" }</code>
 * </p>
 * 
 * @param value - one of a LaggedExprs, or a ComputableExpr, or a Float, or an IndicatorExpression, or a Brackets, or a MathematicalFunctions
 * @returns The created serializer object.
 */
function mathAbs(value: LaggedExprs | ComputableExpr | Float | IndicatorExpression | Brackets | MathematicalFunctions): MathAbs {
  
  return {
    _type: "MATH_ABS",
    
    toString: () => (["abs(", value.toString(), ")"].join(""))
  }
}

/**
 * <h1>MathLog</h1>
 * <p>Takes a tuple comprising: one of a LaggedExprs, or a ComputableExpr, or a Float, or an IndicatorExpression, or a Brackets, or a MathematicalFunctions, and one of a LaggedExprs, or a ComputableExpr, or a Float, or an IndicatorExpression, or a Brackets, or a MathematicalFunctions</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>MATH_LOG = {"log(" ~ EXPRESSIONS ~ EXPRESSIONS ~ ")" }</code>
 * </p>
*/
type MathLog = {
  _type: "MATH_LOG";
  
  toString: () => string;
};

/**
 * <p>Create MathLog serializer object.</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>MATH_LOG = {"log(" ~ EXPRESSIONS ~ EXPRESSIONS ~ ")" }</code>
 * </p>
 * 
 * @param value - a tuple comprising: one of a LaggedExprs, or a ComputableExpr, or a Float, or an IndicatorExpression, or a Brackets, or a MathematicalFunctions, and one of a LaggedExprs, or a ComputableExpr, or a Float, or an IndicatorExpression, or a Brackets, or a MathematicalFunctions
 * @returns The created serializer object.
 */
function mathLog(value: [ LaggedExprs | ComputableExpr | Float | IndicatorExpression | Brackets | MathematicalFunctions, LaggedExprs | ComputableExpr | Float | IndicatorExpression | Brackets | MathematicalFunctions ]): MathLog {
  
  return {
    _type: "MATH_LOG",
    
    toString: () => (["log(", value[0].toString(), value[1].toString(), ")"].join(""))
  }
}

/**
 * <h1>MathMin</h1>
 * <p>Takes a tuple comprising: one of a LaggedExprs, or a ComputableExpr, or a Float, or an IndicatorExpression, or a Brackets, or a MathematicalFunctions, and one of a LaggedExprs, or a ComputableExpr, or a Float, or an IndicatorExpression, or a Brackets, or a MathematicalFunctions</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>MATH_MIN = {"min(" ~ EXPRESSIONS ~ EXPRESSIONS ~ ")" }</code>
 * </p>
*/
type MathMin = {
  _type: "MATH_MIN";
  
  toString: () => string;
};

/**
 * <p>Create MathMin serializer object.</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>MATH_MIN = {"min(" ~ EXPRESSIONS ~ EXPRESSIONS ~ ")" }</code>
 * </p>
 * 
 * @param value - a tuple comprising: one of a LaggedExprs, or a ComputableExpr, or a Float, or an IndicatorExpression, or a Brackets, or a MathematicalFunctions, and one of a LaggedExprs, or a ComputableExpr, or a Float, or an IndicatorExpression, or a Brackets, or a MathematicalFunctions
 * @returns The created serializer object.
 */
function mathMin(value: [ LaggedExprs | ComputableExpr | Float | IndicatorExpression | Brackets | MathematicalFunctions, LaggedExprs | ComputableExpr | Float | IndicatorExpression | Brackets | MathematicalFunctions ]): MathMin {
  
  return {
    _type: "MATH_MIN",
    
    toString: () => (["min(", value[0].toString(), value[1].toString(), ")"].join(""))
  }
}

/**
 * <h1>MathMax</h1>
 * <p>Takes a tuple comprising: one of a LaggedExprs, or a ComputableExpr, or a Float, or an IndicatorExpression, or a Brackets, or a MathematicalFunctions, and one of a LaggedExprs, or a ComputableExpr, or a Float, or an IndicatorExpression, or a Brackets, or a MathematicalFunctions</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>MATH_MAX = {"max(" ~ EXPRESSIONS ~ EXPRESSIONS ~ ")" }</code>
 * </p>
*/
type MathMax = {
  _type: "MATH_MAX";
  
  toString: () => string;
};

/**
 * <p>Create MathMax serializer object.</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>MATH_MAX = {"max(" ~ EXPRESSIONS ~ EXPRESSIONS ~ ")" }</code>
 * </p>
 * 
 * @param value - a tuple comprising: one of a LaggedExprs, or a ComputableExpr, or a Float, or an IndicatorExpression, or a Brackets, or a MathematicalFunctions, and one of a LaggedExprs, or a ComputableExpr, or a Float, or an IndicatorExpression, or a Brackets, or a MathematicalFunctions
 * @returns The created serializer object.
 */
function mathMax(value: [ LaggedExprs | ComputableExpr | Float | IndicatorExpression | Brackets | MathematicalFunctions, LaggedExprs | ComputableExpr | Float | IndicatorExpression | Brackets | MathematicalFunctions ]): MathMax {
  
  return {
    _type: "MATH_MAX",
    
    toString: () => (["max(", value[0].toString(), value[1].toString(), ")"].join(""))
  }
}

/**
 * <h1>MathematicalFunctions</h1>
 * <p>Takes one of a MathExp, or a MathExp2, or a MathLn, or a MathLog, or a MathLog2, or a MathLog10, or a MathSqrt, or a MathCbrt, or a MathCos, or a MathAcos, or a MathCosh, or a MathSin, or a MathAsin, or a MathSinh, or a MathTan, or a MathAtan, or a MathTanh, or a MathAbs, or a MathMin, or a MathMax</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>MATHEMATICAL_FUNCTIONS = _{MATH_EXP | MATH_EXP2 | MATH_LN | MATH_LOG | MATH_LOG2 |
 * 	MATH_LOG10 | MATH_SQRT | MATH_CBRT | MATH_COS | MATH_ACOS |
 * 	MATH_COSH | MATH_SIN | MATH_ASIN | MATH_SINH | MATH_TAN |
 * 	MATH_ATAN | MATH_TANH | MATH_ABS | MATH_MIN | MATH_MAX
 * }</code>
 * </p>
*/
type MathematicalFunctions = {
  _type: "MATHEMATICAL_FUNCTIONS";
  
  toString: () => string;
};

/**
 * <p>Create MathematicalFunctions serializer object.</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>MATHEMATICAL_FUNCTIONS = _{MATH_EXP | MATH_EXP2 | MATH_LN | MATH_LOG | MATH_LOG2 |
 * 	MATH_LOG10 | MATH_SQRT | MATH_CBRT | MATH_COS | MATH_ACOS |
 * 	MATH_COSH | MATH_SIN | MATH_ASIN | MATH_SINH | MATH_TAN |
 * 	MATH_ATAN | MATH_TANH | MATH_ABS | MATH_MIN | MATH_MAX
 * }</code>
 * </p>
 * 
 * @param value - one of a MathExp, or a MathExp2, or a MathLn, or a MathLog, or a MathLog2, or a MathLog10, or a MathSqrt, or a MathCbrt, or a MathCos, or a MathAcos, or a MathCosh, or a MathSin, or a MathAsin, or a MathSinh, or a MathTan, or a MathAtan, or a MathTanh, or a MathAbs, or a MathMin, or a MathMax
 * @returns The created serializer object.
 */
function mathematicalFunctions(value: MathExp | MathExp2 | MathLn | MathLog | MathLog2 | MathLog10 | MathSqrt | MathCbrt | MathCos | MathAcos | MathCosh | MathSin | MathAsin | MathSinh | MathTan | MathAtan | MathTanh | MathAbs | MathMin | MathMax): MathematicalFunctions {
  
  return {
    _type: "MATHEMATICAL_FUNCTIONS",
    
    toString: () => (value.toString())
  }
}

/**
 * <h1>DbId</h1>
 * <p>Takes a string.</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>DB_ID = {ASCII_ALPHANUMERIC{64} }</code>
 * </p>
*/
type DbId = {
  _type: "DB_ID";
  
  toString: () => string;
};

/**
 * <p>Create DbId serializer object.</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>DB_ID = {ASCII_ALPHANUMERIC{64} }</code>
 * </p>
 * 
 * @param value - a string.
 * @returns The created serializer object.
 */
function dbId(value: string): DbId {
  
  return {
    _type: "DB_ID",
    
    toString: () => (value)
  }
}

/**
 * <h1>OpenSquare</h1>
 * <p>Takes </p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>OPEN_SQUARE = @{"[" }</code>
 * </p>
*/
type OpenSquare = {
  _type: "OPEN_SQUARE";
  
  toString: () => string;
};

/**
 * <p>Create OpenSquare serializer object.</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>OPEN_SQUARE = @{"[" }</code>
 * </p>
 * 
 * @returns The created serializer object.
 */
function openSquare(): OpenSquare {
  
  return {
    _type: "OPEN_SQUARE",
    
    toString: () => ("[")
  }
}

/**
 * <h1>CloseSquare</h1>
 * <p>Takes </p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>CLOSE_SQUARE = @{"]" }</code>
 * </p>
*/
type CloseSquare = {
  _type: "CLOSE_SQUARE";
  
  toString: () => string;
};

/**
 * <p>Create CloseSquare serializer object.</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>CLOSE_SQUARE = @{"]" }</code>
 * </p>
 * 
 * @returns The created serializer object.
 */
function closeSquare(): CloseSquare {
  
  return {
    _type: "CLOSE_SQUARE",
    
    toString: () => ("]")
  }
}

/**
 * <h1>ArrayValue</h1>
 * <p>Takes one of a Float, or a Float</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>ARRAY_VALUE = _{FLOAT | (FLOAT) }</code>
 * </p>
*/
type ArrayValue = {
  _type: "ARRAY_VALUE";
  
  toString: () => string;
};

/**
 * <p>Create ArrayValue serializer object.</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>ARRAY_VALUE = _{FLOAT | (FLOAT) }</code>
 * </p>
 * 
 * @param value - one of a Float, or a Float
 * @returns The created serializer object.
 */
function arrayValue(value: Float | Float): ArrayValue {
  
  return {
    _type: "ARRAY_VALUE",
    
    toString: () => (value.toString())
  }
}

/**
 * <h1>PatternArray</h1>
 * <p>Takes one of a Brackets, or an ArrayValue</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>PATTERN_ARRAY = _{OPEN_SQUARE ~ BRACKETS ~ CLOSE_SQUARE |
 * 	OPEN_SQUARE ~ ARRAY_VALUE{1,} ~ CLOSE_SQUARE
 * }</code>
 * </p>
*/
type PatternArray = {
  _type: "PATTERN_ARRAY";
  
  toString: () => string;
};

/**
 * <p>Create PatternArray serializer object.</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>PATTERN_ARRAY = _{OPEN_SQUARE ~ BRACKETS ~ CLOSE_SQUARE |
 * 	OPEN_SQUARE ~ ARRAY_VALUE{1,} ~ CLOSE_SQUARE
 * }</code>
 * </p>
 * 
 * @param value - one of a Brackets, or an ArrayValue
 * @returns The created serializer object.
 */
function patternArray(value: Brackets | ArrayValue): PatternArray {
  
  return {
    _type: "PATTERN_ARRAY",
    
    toString: () => (value.toString())
  }
}

/**
 * <h1>InstrumentHashcode</h1>
 * <p>Takes a string.</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>INSTRUMENT_HASHCODE = @{ASCII_ALPHANUMERIC{64} }</code>
 * </p>
*/
type InstrumentHashcode = {
  _type: "INSTRUMENT_HASHCODE";
  
  toString: () => string;
};

/**
 * <p>Create InstrumentHashcode serializer object.</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>INSTRUMENT_HASHCODE = @{ASCII_ALPHANUMERIC{64} }</code>
 * </p>
 * 
 * @param value - a string.
 * @returns The created serializer object.
 */
function instrumentHashcode(value: string): InstrumentHashcode {
  
  return {
    _type: "INSTRUMENT_HASHCODE",
    
    toString: () => (value)
  }
}

/**
 * <h1>M1</h1>
 * <p>Takes </p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>M1 = {"M1" }</code>
 * </p>
*/
type M1 = {
  _type: "M1";
  
  toString: () => string;
};

/**
 * <p>Create M1 serializer object.</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>M1 = {"M1" }</code>
 * </p>
 * 
 * @returns The created serializer object.
 */
function m1(): M1 {
  
  return {
    _type: "M1",
    
    toString: () => ("M1")
  }
}

/**
 * <h1>M3</h1>
 * <p>Takes </p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>M3 = {"M3" }</code>
 * </p>
*/
type M3 = {
  _type: "M3";
  
  toString: () => string;
};

/**
 * <p>Create M3 serializer object.</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>M3 = {"M3" }</code>
 * </p>
 * 
 * @returns The created serializer object.
 */
function m3(): M3 {
  
  return {
    _type: "M3",
    
    toString: () => ("M3")
  }
}

/**
 * <h1>M5</h1>
 * <p>Takes </p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>M5 = {"M5" }</code>
 * </p>
*/
type M5 = {
  _type: "M5";
  
  toString: () => string;
};

/**
 * <p>Create M5 serializer object.</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>M5 = {"M5" }</code>
 * </p>
 * 
 * @returns The created serializer object.
 */
function m5(): M5 {
  
  return {
    _type: "M5",
    
    toString: () => ("M5")
  }
}

/**
 * <h1>M10</h1>
 * <p>Takes </p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>M10 = {"M10" }</code>
 * </p>
*/
type M10 = {
  _type: "M10";
  
  toString: () => string;
};

/**
 * <p>Create M10 serializer object.</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>M10 = {"M10" }</code>
 * </p>
 * 
 * @returns The created serializer object.
 */
function m10(): M10 {
  
  return {
    _type: "M10",
    
    toString: () => ("M10")
  }
}

/**
 * <h1>M15</h1>
 * <p>Takes </p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>M15 = {"M15" }</code>
 * </p>
*/
type M15 = {
  _type: "M15";
  
  toString: () => string;
};

/**
 * <p>Create M15 serializer object.</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>M15 = {"M15" }</code>
 * </p>
 * 
 * @returns The created serializer object.
 */
function m15(): M15 {
  
  return {
    _type: "M15",
    
    toString: () => ("M15")
  }
}

/**
 * <h1>M30</h1>
 * <p>Takes </p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>M30 = {"M30" }</code>
 * </p>
*/
type M30 = {
  _type: "M30";
  
  toString: () => string;
};

/**
 * <p>Create M30 serializer object.</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>M30 = {"M30" }</code>
 * </p>
 * 
 * @returns The created serializer object.
 */
function m30(): M30 {
  
  return {
    _type: "M30",
    
    toString: () => ("M30")
  }
}

/**
 * <h1>H1</h1>
 * <p>Takes </p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>H1 = {"H1" }</code>
 * </p>
*/
type H1 = {
  _type: "H1";
  
  toString: () => string;
};

/**
 * <p>Create H1 serializer object.</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>H1 = {"H1" }</code>
 * </p>
 * 
 * @returns The created serializer object.
 */
function h1(): H1 {
  
  return {
    _type: "H1",
    
    toString: () => ("H1")
  }
}

/**
 * <h1>H2</h1>
 * <p>Takes </p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>H2 = {"H2" }</code>
 * </p>
*/
type H2 = {
  _type: "H2";
  
  toString: () => string;
};

/**
 * <p>Create H2 serializer object.</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>H2 = {"H2" }</code>
 * </p>
 * 
 * @returns The created serializer object.
 */
function h2(): H2 {
  
  return {
    _type: "H2",
    
    toString: () => ("H2")
  }
}

/**
 * <h1>H3</h1>
 * <p>Takes </p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>H3 = {"H3" }</code>
 * </p>
*/
type H3 = {
  _type: "H3";
  
  toString: () => string;
};

/**
 * <p>Create H3 serializer object.</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>H3 = {"H3" }</code>
 * </p>
 * 
 * @returns The created serializer object.
 */
function h3(): H3 {
  
  return {
    _type: "H3",
    
    toString: () => ("H3")
  }
}

/**
 * <h1>H4</h1>
 * <p>Takes </p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>H4 = {"H4" }</code>
 * </p>
*/
type H4 = {
  _type: "H4";
  
  toString: () => string;
};

/**
 * <p>Create H4 serializer object.</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>H4 = {"H4" }</code>
 * </p>
 * 
 * @returns The created serializer object.
 */
function h4(): H4 {
  
  return {
    _type: "H4",
    
    toString: () => ("H4")
  }
}

/**
 * <h1>D1</h1>
 * <p>Takes </p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>D1 = {"D1" }</code>
 * </p>
*/
type D1 = {
  _type: "D1";
  
  toString: () => string;
};

/**
 * <p>Create D1 serializer object.</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>D1 = {"D1" }</code>
 * </p>
 * 
 * @returns The created serializer object.
 */
function d1(): D1 {
  
  return {
    _type: "D1",
    
    toString: () => ("D1")
  }
}

/**
 * <h1>CandleFrequency</h1>
 * <p>Takes one of a M30, or a M10, or a M15, or a M1, or a M3, or a M5, or a H1, or a H2, or a H3, or a H4, or a D1</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>CANDLE_FREQUENCY = _{M30 | M10 | M15 | M1 | M3 | M5 | H1 | H2 | H3 | H4 | D1
 * }</code>
 * </p>
*/
type CandleFrequency = {
  _type: "CANDLE_FREQUENCY";
  
  toString: () => string;
};

/**
 * <p>Create CandleFrequency serializer object.</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>CANDLE_FREQUENCY = _{M30 | M10 | M15 | M1 | M3 | M5 | H1 | H2 | H3 | H4 | D1
 * }</code>
 * </p>
 * 
 * @param value - one of a M30, or a M10, or a M15, or a M1, or a M3, or a M5, or a H1, or a H2, or a H3, or a H4, or a D1
 * @returns The created serializer object.
 */
function candleFrequency(value: M30 | M10 | M15 | M1 | M3 | M5 | H1 | H2 | H3 | H4 | D1): CandleFrequency {
  
  return {
    _type: "CANDLE_FREQUENCY",
    
    toString: () => (value.toString())
  }
}

/**
 * <h1>OptInstrumentFrequency</h1>
 * <p>Takes one of a tuple comprising: an InstrumentHashcode, and a CandleFrequency, or an InstrumentHashcode, or a CandleFrequency</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>OPT_INSTRUMENT_FREQUENCY = _{( INSTRUMENT_HASHCODE ~ CANDLE_FREQUENCY | INSTRUMENT_HASHCODE | CANDLE_FREQUENCY )
 * }</code>
 * </p>
*/
type OptInstrumentFrequency = {
  _type: "OPT_INSTRUMENT_FREQUENCY";
  
  toString: () => string;
};

/**
 * <p>Create OptInstrumentFrequency serializer object.</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>OPT_INSTRUMENT_FREQUENCY = _{( INSTRUMENT_HASHCODE ~ CANDLE_FREQUENCY | INSTRUMENT_HASHCODE | CANDLE_FREQUENCY )
 * }</code>
 * </p>
 * 
 * @param value - one of a tuple comprising: an InstrumentHashcode, and a CandleFrequency, or an InstrumentHashcode, or a CandleFrequency
 * @returns The created serializer object.
 */
function optInstrumentFrequency(value: [ InstrumentHashcode, CandleFrequency ] | InstrumentHashcode | CandleFrequency): OptInstrumentFrequency {
  
  return {
    _type: "OPT_INSTRUMENT_FREQUENCY",
    
    toString: () => (value.toString())
  }
}

/**
 * <h1>Sma</h1>
 * <p>Takes a tuple comprising: an OptInstrumentFrequency, and a number.</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>SMA = {"SMA(" ~ OPT_INSTRUMENT_FREQUENCY? ~ WINDOW ~ ")" }</code>
 * </p>
*/
type Sma = {
  _type: "SMA";
  
  toString: () => string;
};

/**
 * <p>Create Sma serializer object.</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>SMA = {"SMA(" ~ OPT_INSTRUMENT_FREQUENCY? ~ WINDOW ~ ")" }</code>
 * </p>
 * 
 * @param value - a tuple comprising: an OptInstrumentFrequency, and a number.
 * @returns The created serializer object.
 */
function sma(value: [ OptInstrumentFrequency, number ] | [ number ]): Sma {
  
  return {
    _type: "SMA",
    
    toString: () => {
      if (value.length === 2) return ["SMA(", value[0] ? value[0].toString() : null, value[1] ? `,` + value[1].toString() : null, ")"].join("");
      return ["SMA(", value[0] ? value[0].toString() : null, ")"].join("");
    }
  }
}

/**
 * <h1>Smm</h1>
 * <p>Takes a tuple comprising: an OptInstrumentFrequency, and a number.</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>SMM = {"SMM(" ~ OPT_INSTRUMENT_FREQUENCY? ~ WINDOW ~ ")" }</code>
 * </p>
*/
type Smm = {
  _type: "SMM";
  
  toString: () => string;
};

/**
 * <p>Create Smm serializer object.</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>SMM = {"SMM(" ~ OPT_INSTRUMENT_FREQUENCY? ~ WINDOW ~ ")" }</code>
 * </p>
 * 
 * @param value - a tuple comprising: an OptInstrumentFrequency, and a number.
 * @returns The created serializer object.
 */
function smm(value: [ OptInstrumentFrequency, number ]): Smm {
  
  return {
    _type: "SMM",
    
    toString: () => (["SMM(", value[0] ? value[0].toString() : null, value[1] ? `,` + value[1].toString() : null, ")"].join(""))
  }
}

/**
 * <h1>LinReg</h1>
 * <p>Takes a tuple comprising: an OptInstrumentFrequency, and a number.</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>LinReg = {^"LinReg(" ~ OPT_INSTRUMENT_FREQUENCY? ~ WINDOW ~ ")" }</code>
 * </p>
*/
type LinReg = {
  _type: "LinReg";
  
  toString: () => string;
};

/**
 * <p>Create LinReg serializer object.</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>LinReg = {^"LinReg(" ~ OPT_INSTRUMENT_FREQUENCY? ~ WINDOW ~ ")" }</code>
 * </p>
 * 
 * @param value - a tuple comprising: an OptInstrumentFrequency, and a number.
 * @returns The created serializer object.
 */
function linReg(value: [ OptInstrumentFrequency, number ]): LinReg {
  
  return {
    _type: "LinReg",
    
    toString: () => (["LinReg(", value[0] ? value[0].toString() : null, value[1] ? `,` + value[1].toString() : null, ")"].join(""))
  }
}

/**
 * <h1>Ema</h1>
 * <p>Takes a tuple comprising: an OptInstrumentFrequency, and a number.</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>EMA = {"EMA(" ~ OPT_INSTRUMENT_FREQUENCY? ~ WINDOW ~ ")" }</code>
 * </p>
*/
type Ema = {
  _type: "EMA";
  
  toString: () => string;
};

/**
 * <p>Create Ema serializer object.</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>EMA = {"EMA(" ~ OPT_INSTRUMENT_FREQUENCY? ~ WINDOW ~ ")" }</code>
 * </p>
 * 
 * @param value - a tuple comprising: an OptInstrumentFrequency, and a number.
 * @returns The created serializer object.
 */
function ema(value: [ OptInstrumentFrequency, number ] | [ number ]): Ema {
  
  return {
    _type: "EMA",
    
    toString: () => {
      if (value.length === 2) return ["EMA(", value[0] ? value[0].toString() : null, value[1] ? `,` + value[1].toString() : null, ")"].join("");
      return ["EMA(", value[0] ? value[0].toString() : null, ")"].join("");
    }
  }
}

/**
 * <h1>Dema</h1>
 * <p>Takes a tuple comprising: an OptInstrumentFrequency, and a number.</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>DEMA = {"DEMA(" ~ OPT_INSTRUMENT_FREQUENCY? ~ WINDOW ~ ")" }</code>
 * </p>
*/
type Dema = {
  _type: "DEMA";
  
  toString: () => string;
};

/**
 * <p>Create Dema serializer object.</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>DEMA = {"DEMA(" ~ OPT_INSTRUMENT_FREQUENCY? ~ WINDOW ~ ")" }</code>
 * </p>
 * 
 * @param value - a tuple comprising: an OptInstrumentFrequency, and a number.
 * @returns The created serializer object.
 */
function dema(value: [ OptInstrumentFrequency, number ]): Dema {
  
  return {
    _type: "DEMA",
    
    toString: () => (["DEMA(", value[0] ? value[0].toString() : null, value[1] ? `,` + value[1].toString() : null, ")"].join(""))
  }
}

/**
 * <h1>Tema</h1>
 * <p>Takes a tuple comprising: an OptInstrumentFrequency, and a number.</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>TEMA = {"TEMA(" ~ OPT_INSTRUMENT_FREQUENCY? ~ WINDOW ~ ")" }</code>
 * </p>
*/
type Tema = {
  _type: "TEMA";
  
  toString: () => string;
};

/**
 * <p>Create Tema serializer object.</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>TEMA = {"TEMA(" ~ OPT_INSTRUMENT_FREQUENCY? ~ WINDOW ~ ")" }</code>
 * </p>
 * 
 * @param value - a tuple comprising: an OptInstrumentFrequency, and a number.
 * @returns The created serializer object.
 */
function tema(value: [ OptInstrumentFrequency, number ]): Tema {
  
  return {
    _type: "TEMA",
    
    toString: () => (["TEMA(", value[0] ? value[0].toString() : null, value[1] ? `,` + value[1].toString() : null, ")"].join(""))
  }
}

/**
 * <h1>Hma</h1>
 * <p>Takes a tuple comprising: an OptInstrumentFrequency, and a number.</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>HMA = {"HMA(" ~ OPT_INSTRUMENT_FREQUENCY? ~ WINDOW ~ ")" }</code>
 * </p>
*/
type Hma = {
  _type: "HMA";
  
  toString: () => string;
};

/**
 * <p>Create Hma serializer object.</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>HMA = {"HMA(" ~ OPT_INSTRUMENT_FREQUENCY? ~ WINDOW ~ ")" }</code>
 * </p>
 * 
 * @param value - a tuple comprising: an OptInstrumentFrequency, and a number.
 * @returns The created serializer object.
 */
function hma(value: [ OptInstrumentFrequency, number ]): Hma {
  
  return {
    _type: "HMA",
    
    toString: () => (["HMA(", value[0] ? value[0].toString() : null, value[1] ? `,` + value[1].toString() : null, ")"].join(""))
  }
}

/**
 * <h1>Rma</h1>
 * <p>Takes a tuple comprising: an OptInstrumentFrequency, and a number.</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>RMA = {"RMA(" ~ OPT_INSTRUMENT_FREQUENCY? ~ WINDOW ~ ")" }</code>
 * </p>
*/
type Rma = {
  _type: "RMA";
  
  toString: () => string;
};

/**
 * <p>Create Rma serializer object.</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>RMA = {"RMA(" ~ OPT_INSTRUMENT_FREQUENCY? ~ WINDOW ~ ")" }</code>
 * </p>
 * 
 * @param value - a tuple comprising: an OptInstrumentFrequency, and a number.
 * @returns The created serializer object.
 */
function rma(value: [ OptInstrumentFrequency, number ]): Rma {
  
  return {
    _type: "RMA",
    
    toString: () => (["RMA(", value[0] ? value[0].toString() : null, value[1] ? `,` + value[1].toString() : null, ")"].join(""))
  }
}

/**
 * <h1>Tma</h1>
 * <p>Takes a tuple comprising: an OptInstrumentFrequency, and a number.</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>TMA = {"TMA(" ~ OPT_INSTRUMENT_FREQUENCY? ~ WINDOW ~ ")" }</code>
 * </p>
*/
type Tma = {
  _type: "TMA";
  
  toString: () => string;
};

/**
 * <p>Create Tma serializer object.</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>TMA = {"TMA(" ~ OPT_INSTRUMENT_FREQUENCY? ~ WINDOW ~ ")" }</code>
 * </p>
 * 
 * @param value - a tuple comprising: an OptInstrumentFrequency, and a number.
 * @returns The created serializer object.
 */
function tma(value: [ OptInstrumentFrequency, number ]): Tma {
  
  return {
    _type: "TMA",
    
    toString: () => (["TMA(", value[0] ? value[0].toString() : null, value[1] ? `,` + value[1].toString() : null, ")"].join(""))
  }
}

/**
 * <h1>Wma</h1>
 * <p>Takes a tuple comprising: an OptInstrumentFrequency, and a number.</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>WMA = {"WMA(" ~ OPT_INSTRUMENT_FREQUENCY? ~ WINDOW ~ ")" }</code>
 * </p>
*/
type Wma = {
  _type: "WMA";
  
  toString: () => string;
};

/**
 * <p>Create Wma serializer object.</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>WMA = {"WMA(" ~ OPT_INSTRUMENT_FREQUENCY? ~ WINDOW ~ ")" }</code>
 * </p>
 * 
 * @param value - a tuple comprising: an OptInstrumentFrequency, and a number.
 * @returns The created serializer object.
 */
function wma(value: [ OptInstrumentFrequency, number ]): Wma {
  
  return {
    _type: "WMA",
    
    toString: () => (["WMA(", value[0] ? value[0].toString() : null, value[1] ? `,` + value[1].toString() : null, ")"].join(""))
  }
}

/**
 * <h1>Vwma</h1>
 * <p>Takes a tuple comprising: an OptInstrumentFrequency, and a number.</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>VWMA = {"VWMA(" ~ OPT_INSTRUMENT_FREQUENCY? ~ WINDOW ~ ")" }</code>
 * </p>
*/
type Vwma = {
  _type: "VWMA";
  
  toString: () => string;
};

/**
 * <p>Create Vwma serializer object.</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>VWMA = {"VWMA(" ~ OPT_INSTRUMENT_FREQUENCY? ~ WINDOW ~ ")" }</code>
 * </p>
 * 
 * @param value - a tuple comprising: an OptInstrumentFrequency, and a number.
 * @returns The created serializer object.
 */
function vwma(value: [ OptInstrumentFrequency, number ]): Vwma {
  
  return {
    _type: "VWMA",
    
    toString: () => (["VWMA(", value[0] ? value[0].toString() : null, value[1] ? `,` + value[1].toString() : null, ")"].join(""))
  }
}

/**
 * <h1>Swma</h1>
 * <p>Takes a tuple comprising: an OptInstrumentFrequency, and a number.</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>SWMA = {"SWMA(" ~ OPT_INSTRUMENT_FREQUENCY? ~ WINDOW ~ ")" }</code>
 * </p>
*/
type Swma = {
  _type: "SWMA";
  
  toString: () => string;
};

/**
 * <p>Create Swma serializer object.</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>SWMA = {"SWMA(" ~ OPT_INSTRUMENT_FREQUENCY? ~ WINDOW ~ ")" }</code>
 * </p>
 * 
 * @param value - a tuple comprising: an OptInstrumentFrequency, and a number.
 * @returns The created serializer object.
 */
function swma(value: [ OptInstrumentFrequency, number ]): Swma {
  
  return {
    _type: "SWMA",
    
    toString: () => (["SWMA(", value[0] ? value[0].toString() : null, value[1] ? `,` + value[1].toString() : null, ")"].join(""))
  }
}

/**
 * <h1>Vidya</h1>
 * <p>Takes a tuple comprising: an OptInstrumentFrequency, and a number.</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>Vidya = {"Vidya(" ~ OPT_INSTRUMENT_FREQUENCY? ~ WINDOW ~ ")" }</code>
 * </p>
*/
type Vidya = {
  _type: "Vidya";
  
  toString: () => string;
};

/**
 * <p>Create Vidya serializer object.</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>Vidya = {"Vidya(" ~ OPT_INSTRUMENT_FREQUENCY? ~ WINDOW ~ ")" }</code>
 * </p>
 * 
 * @param value - a tuple comprising: an OptInstrumentFrequency, and a number.
 * @returns The created serializer object.
 */
function vidya(value: [ OptInstrumentFrequency, number ]): Vidya {
  
  return {
    _type: "Vidya",
    
    toString: () => (["Vidya(", value[0] ? value[0].toString() : null, value[1] ? `,` + value[1].toString() : null, ")"].join(""))
  }
}

/**
 * <h1>Wsma</h1>
 * <p>Takes a tuple comprising: an OptInstrumentFrequency, and a number.</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>WSMA = {"WSMA(" ~ OPT_INSTRUMENT_FREQUENCY? ~ HALF_WINDOW ~ ")" }</code>
 * </p>
*/
type Wsma = {
  _type: "WSMA";
  
  toString: () => string;
};

/**
 * <p>Create Wsma serializer object.</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>WSMA = {"WSMA(" ~ OPT_INSTRUMENT_FREQUENCY? ~ HALF_WINDOW ~ ")" }</code>
 * </p>
 * 
 * @param value - a tuple comprising: an OptInstrumentFrequency, and a number.
 * @returns The created serializer object.
 */
function wsma(value: [ OptInstrumentFrequency, number ]): Wsma {
  
  return {
    _type: "WSMA",
    
    toString: () => (["WSMA(", value[0] ? value[0].toString() : null, value[1] ? `,` + value[1].toString() : null, ")"].join(""))
  }
}

/**
 * <h1>Trima</h1>
 * <p>Takes a tuple comprising: an OptInstrumentFrequency, and a number.</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>TRIMA = {"TRIMA(" ~ OPT_INSTRUMENT_FREQUENCY? ~ WINDOW ~ ")" }</code>
 * </p>
*/
type Trima = {
  _type: "TRIMA";
  
  toString: () => string;
};

/**
 * <p>Create Trima serializer object.</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>TRIMA = {"TRIMA(" ~ OPT_INSTRUMENT_FREQUENCY? ~ WINDOW ~ ")" }</code>
 * </p>
 * 
 * @param value - a tuple comprising: an OptInstrumentFrequency, and a number.
 * @returns The created serializer object.
 */
function trima(value: [ OptInstrumentFrequency, number ]): Trima {
  
  return {
    _type: "TRIMA",
    
    toString: () => (["TRIMA(", value[0] ? value[0].toString() : null, value[1] ? `,` + value[1].toString() : null, ")"].join(""))
  }
}

/**
 * <h1>AllMovingAverages</h1>
 * <p>Takes one of a Sma, or an Ema, or a Dema, or a Tema, or a Smm, or a LinReg, or a Hma, or a Rma, or a Tma, or a Wma, or a Vwma, or a Swma, or a Vidya, or a Wsma, or a Trima</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>ALL_MOVING_AVERAGES = _{SMA | EMA | DEMA | TEMA | SMM | LinReg | HMA
 * 	| RMA | TMA | WMA | VWMA | SWMA | Vidya | WSMA
 * 	| TRIMA
 * }</code>
 * </p>
*/
type AllMovingAverages = {
  _type: "ALL_MOVING_AVERAGES";
  
  toString: () => string;
};

/**
 * <p>Create AllMovingAverages serializer object.</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>ALL_MOVING_AVERAGES = _{SMA | EMA | DEMA | TEMA | SMM | LinReg | HMA
 * 	| RMA | TMA | WMA | VWMA | SWMA | Vidya | WSMA
 * 	| TRIMA
 * }</code>
 * </p>
 * 
 * @param value - one of a Sma, or an Ema, or a Dema, or a Tema, or a Smm, or a LinReg, or a Hma, or a Rma, or a Tma, or a Wma, or a Vwma, or a Swma, or a Vidya, or a Wsma, or a Trima
 * @returns The created serializer object.
 */
function allMovingAverages(value: Sma | Ema | Dema | Tema | Smm | LinReg | Hma | Rma | Tma | Wma | Vwma | Swma | Vidya | Wsma | Trima): AllMovingAverages {
  
  return {
    _type: "ALL_MOVING_AVERAGES",
    
    toString: () => (value.toString())
  }
}

/**
 * <h1>Cci</h1>
 * <p>Takes a tuple comprising: an OptInstrumentFrequency, and a number.</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>CCI = {"CCI(" ~ OPT_INSTRUMENT_FREQUENCY? ~ WINDOW ~ ")" }</code>
 * </p>
*/
type Cci = {
  _type: "CCI";
  
  toString: () => string;
};

/**
 * <p>Create Cci serializer object.</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>CCI = {"CCI(" ~ OPT_INSTRUMENT_FREQUENCY? ~ WINDOW ~ ")" }</code>
 * </p>
 * 
 * @param value - a tuple comprising: an OptInstrumentFrequency, and a number.
 * @returns The created serializer object.
 */
function cci(value: [ OptInstrumentFrequency, number ]): Cci {
  
  return {
    _type: "CCI",
    
    toString: () => (["CCI(", value[0] ? value[0].toString() : null, value[1] ? `,` + value[1].toString() : null, ")"].join(""))
  }
}

/**
 * <h1>Derivative</h1>
 * <p>Takes a tuple comprising: an OptInstrumentFrequency, and a number.</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>Derivative = {"Derivative(" ~ OPT_INSTRUMENT_FREQUENCY? ~ WINDOW ~ ")" }</code>
 * </p>
*/
type Derivative = {
  _type: "Derivative";
  
  toString: () => string;
};

/**
 * <p>Create Derivative serializer object.</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>Derivative = {"Derivative(" ~ OPT_INSTRUMENT_FREQUENCY? ~ WINDOW ~ ")" }</code>
 * </p>
 * 
 * @param value - a tuple comprising: an OptInstrumentFrequency, and a number.
 * @returns The created serializer object.
 */
function derivative(value: [ OptInstrumentFrequency, number ]): Derivative {
  
  return {
    _type: "Derivative",
    
    toString: () => (["Derivative(", value[0] ? value[0].toString() : null, value[1] ? `,` + value[1].toString() : null, ")"].join(""))
  }
}

/**
 * <h1>Highest</h1>
 * <p>Takes a tuple comprising: an OptInstrumentFrequency, and a number.</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>Highest = {"Highest(" ~ OPT_INSTRUMENT_FREQUENCY? ~ WINDOW ~ ")" }</code>
 * </p>
*/
type Highest = {
  _type: "Highest";
  
  toString: () => string;
};

/**
 * <p>Create Highest serializer object.</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>Highest = {"Highest(" ~ OPT_INSTRUMENT_FREQUENCY? ~ WINDOW ~ ")" }</code>
 * </p>
 * 
 * @param value - a tuple comprising: an OptInstrumentFrequency, and a number.
 * @returns The created serializer object.
 */
function highest(value: [ OptInstrumentFrequency, number ]): Highest {
  
  return {
    _type: "Highest",
    
    toString: () => (["Highest(", value[0] ? value[0].toString() : null, value[1] ? `,` + value[1].toString() : null, ")"].join(""))
  }
}

/**
 * <h1>Lowest</h1>
 * <p>Takes a tuple comprising: an OptInstrumentFrequency, and a number.</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>Lowest = {"Lowest(" ~ OPT_INSTRUMENT_FREQUENCY? ~ WINDOW ~ ")" }</code>
 * </p>
*/
type Lowest = {
  _type: "Lowest";
  
  toString: () => string;
};

/**
 * <p>Create Lowest serializer object.</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>Lowest = {"Lowest(" ~ OPT_INSTRUMENT_FREQUENCY? ~ WINDOW ~ ")" }</code>
 * </p>
 * 
 * @param value - a tuple comprising: an OptInstrumentFrequency, and a number.
 * @returns The created serializer object.
 */
function lowest(value: [ OptInstrumentFrequency, number ]): Lowest {
  
  return {
    _type: "Lowest",
    
    toString: () => (["Lowest(", value[0] ? value[0].toString() : null, value[1] ? `,` + value[1].toString() : null, ")"].join(""))
  }
}

/**
 * <h1>HighestLowestDelta</h1>
 * <p>Takes a tuple comprising: an OptInstrumentFrequency, and a number.</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>HighestLowestDelta = {"HighestLowestDelta(" ~ OPT_INSTRUMENT_FREQUENCY? ~ WINDOW ~ ")" }</code>
 * </p>
*/
type HighestLowestDelta = {
  _type: "HighestLowestDelta";
  
  toString: () => string;
};

/**
 * <p>Create HighestLowestDelta serializer object.</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>HighestLowestDelta = {"HighestLowestDelta(" ~ OPT_INSTRUMENT_FREQUENCY? ~ WINDOW ~ ")" }</code>
 * </p>
 * 
 * @param value - a tuple comprising: an OptInstrumentFrequency, and a number.
 * @returns The created serializer object.
 */
function highestLowestDelta(value: [ OptInstrumentFrequency, number ]): HighestLowestDelta {
  
  return {
    _type: "HighestLowestDelta",
    
    toString: () => (["HighestLowestDelta(", value[0] ? value[0].toString() : null, value[1] ? `,` + value[1].toString() : null, ")"].join(""))
  }
}

/**
 * <h1>HighestIndex</h1>
 * <p>Takes a tuple comprising: an OptInstrumentFrequency, and a number.</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>HighestIndex = {"HighestIndex(" ~ OPT_INSTRUMENT_FREQUENCY? ~ WINDOW ~ ")" }</code>
 * </p>
*/
type HighestIndex = {
  _type: "HighestIndex";
  
  toString: () => string;
};

/**
 * <p>Create HighestIndex serializer object.</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>HighestIndex = {"HighestIndex(" ~ OPT_INSTRUMENT_FREQUENCY? ~ WINDOW ~ ")" }</code>
 * </p>
 * 
 * @param value - a tuple comprising: an OptInstrumentFrequency, and a number.
 * @returns The created serializer object.
 */
function highestIndex(value: [ OptInstrumentFrequency, number ]): HighestIndex {
  
  return {
    _type: "HighestIndex",
    
    toString: () => (["HighestIndex(", value[0] ? value[0].toString() : null, value[1] ? `,` + value[1].toString() : null, ")"].join(""))
  }
}

/**
 * <h1>LowestIndex</h1>
 * <p>Takes a tuple comprising: an OptInstrumentFrequency, and a number.</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>LowestIndex = {"LowestIndex(" ~ OPT_INSTRUMENT_FREQUENCY? ~ WINDOW ~ ")" }</code>
 * </p>
*/
type LowestIndex = {
  _type: "LowestIndex";
  
  toString: () => string;
};

/**
 * <p>Create LowestIndex serializer object.</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>LowestIndex = {"LowestIndex(" ~ OPT_INSTRUMENT_FREQUENCY? ~ WINDOW ~ ")" }</code>
 * </p>
 * 
 * @param value - a tuple comprising: an OptInstrumentFrequency, and a number.
 * @returns The created serializer object.
 */
function lowestIndex(value: [ OptInstrumentFrequency, number ]): LowestIndex {
  
  return {
    _type: "LowestIndex",
    
    toString: () => (["LowestIndex(", value[0] ? value[0].toString() : null, value[1] ? `,` + value[1].toString() : null, ")"].join(""))
  }
}

/**
 * <h1>Integral</h1>
 * <p>Takes a tuple comprising: an OptInstrumentFrequency, and a number.</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>Integral = {"Integral(" ~ OPT_INSTRUMENT_FREQUENCY? ~ WINDOW ~ ")" }</code>
 * </p>
*/
type Integral = {
  _type: "Integral";
  
  toString: () => string;
};

/**
 * <p>Create Integral serializer object.</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>Integral = {"Integral(" ~ OPT_INSTRUMENT_FREQUENCY? ~ WINDOW ~ ")" }</code>
 * </p>
 * 
 * @param value - a tuple comprising: an OptInstrumentFrequency, and a number.
 * @returns The created serializer object.
 */
function integral(value: [ OptInstrumentFrequency, number ]): Integral {
  
  return {
    _type: "Integral",
    
    toString: () => (["Integral(", value[0] ? value[0].toString() : null, value[1] ? `,` + value[1].toString() : null, ")"].join(""))
  }
}

/**
 * <h1>MeanAbsDev</h1>
 * <p>Takes a tuple comprising: an OptInstrumentFrequency, and a number.</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>MeanAbsDev = {"MeanAbsDev(" ~ OPT_INSTRUMENT_FREQUENCY? ~ WINDOW ~ ")" }</code>
 * </p>
*/
type MeanAbsDev = {
  _type: "MeanAbsDev";
  
  toString: () => string;
};

/**
 * <p>Create MeanAbsDev serializer object.</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>MeanAbsDev = {"MeanAbsDev(" ~ OPT_INSTRUMENT_FREQUENCY? ~ WINDOW ~ ")" }</code>
 * </p>
 * 
 * @param value - a tuple comprising: an OptInstrumentFrequency, and a number.
 * @returns The created serializer object.
 */
function meanAbsDev(value: [ OptInstrumentFrequency, number ]): MeanAbsDev {
  
  return {
    _type: "MeanAbsDev",
    
    toString: () => (["MeanAbsDev(", value[0] ? value[0].toString() : null, value[1] ? `,` + value[1].toString() : null, ")"].join(""))
  }
}

/**
 * <h1>MedianAbsDev</h1>
 * <p>Takes a tuple comprising: an OptInstrumentFrequency, and a number.</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>MedianAbsDev = {"MedianAbsDev(" ~ OPT_INSTRUMENT_FREQUENCY? ~ WINDOW ~ ")" }</code>
 * </p>
*/
type MedianAbsDev = {
  _type: "MedianAbsDev";
  
  toString: () => string;
};

/**
 * <p>Create MedianAbsDev serializer object.</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>MedianAbsDev = {"MedianAbsDev(" ~ OPT_INSTRUMENT_FREQUENCY? ~ WINDOW ~ ")" }</code>
 * </p>
 * 
 * @param value - a tuple comprising: an OptInstrumentFrequency, and a number.
 * @returns The created serializer object.
 */
function medianAbsDev(value: [ OptInstrumentFrequency, number ]): MedianAbsDev {
  
  return {
    _type: "MedianAbsDev",
    
    toString: () => (["MedianAbsDev(", value[0] ? value[0].toString() : null, value[1] ? `,` + value[1].toString() : null, ")"].join(""))
  }
}

/**
 * <h1>Momentum</h1>
 * <p>Takes a tuple comprising: an OptInstrumentFrequency, and a number.</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>Momentum = {"Momentum(" ~ OPT_INSTRUMENT_FREQUENCY? ~ WINDOW ~ ")" }</code>
 * </p>
*/
type Momentum = {
  _type: "Momentum";
  
  toString: () => string;
};

/**
 * <p>Create Momentum serializer object.</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>Momentum = {"Momentum(" ~ OPT_INSTRUMENT_FREQUENCY? ~ WINDOW ~ ")" }</code>
 * </p>
 * 
 * @param value - a tuple comprising: an OptInstrumentFrequency, and a number.
 * @returns The created serializer object.
 */
function momentum(value: [ OptInstrumentFrequency, number ]): Momentum {
  
  return {
    _type: "Momentum",
    
    toString: () => (["Momentum(", value[0] ? value[0].toString() : null, value[1] ? `,` + value[1].toString() : null, ")"].join(""))
  }
}

/**
 * <h1>PastOpen</h1>
 * <p>Takes a tuple comprising: an OptInstrumentFrequency, and one of , or a Window</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>PastOpen = {"PastOpen(" ~ OPT_INSTRUMENT_FREQUENCY? ~ ("1" | WINDOW) ~ ")" }</code>
 * </p>
*/
type PastOpen = {
  _type: "PastOpen";
  
  toString: () => string;
};

/**
 * <p>Create PastOpen serializer object.</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>PastOpen = {"PastOpen(" ~ OPT_INSTRUMENT_FREQUENCY? ~ ("1" | WINDOW) ~ ")" }</code>
 * </p>
 * 
 * @param value - a tuple comprising: an OptInstrumentFrequency, and one of , or a Window
 * @returns The created serializer object.
 */
function pastOpen(value: [ OptInstrumentFrequency, "1" | Window ]): PastOpen {
  
  return {
    _type: "PastOpen",
    
    toString: () => (["PastOpen(", value[0].toString(), ',', value[1].toString(), ")"].join(""))
  }
}

/**
 * <h1>PastHigh</h1>
 * <p>Takes a tuple comprising: an OptInstrumentFrequency, and one of , or a Window</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>PastHigh = {"PastHigh(" ~ OPT_INSTRUMENT_FREQUENCY? ~ ("1" | WINDOW) ~ ")" }</code>
 * </p>
*/
type PastHigh = {
  _type: "PastHigh";
  
  toString: () => string;
};

/**
 * <p>Create PastHigh serializer object.</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>PastHigh = {"PastHigh(" ~ OPT_INSTRUMENT_FREQUENCY? ~ ("1" | WINDOW) ~ ")" }</code>
 * </p>
 * 
 * @param value - a tuple comprising: an OptInstrumentFrequency, and one of , or a Window
 * @returns The created serializer object.
 */
function pastHigh(value: [ OptInstrumentFrequency, "1" | Window ]): PastHigh {
  
  return {
    _type: "PastHigh",
    
    toString: () => (["PastHigh(", value[0].toString(), ',', value[1].toString(), ")"].join(""))
  }
}

/**
 * <h1>PastLow</h1>
 * <p>Takes a tuple comprising: an OptInstrumentFrequency, and one of , or a Window</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>PastLow = {"PastLow(" ~ OPT_INSTRUMENT_FREQUENCY? ~ ("1" | WINDOW) ~ ")" }</code>
 * </p>
*/
type PastLow = {
  _type: "PastLow";
  
  toString: () => string;
};

/**
 * <p>Create PastLow serializer object.</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>PastLow = {"PastLow(" ~ OPT_INSTRUMENT_FREQUENCY? ~ ("1" | WINDOW) ~ ")" }</code>
 * </p>
 * 
 * @param value - a tuple comprising: an OptInstrumentFrequency, and one of , or a Window
 * @returns The created serializer object.
 */
function pastLow(value: [ OptInstrumentFrequency, "1" | Window ]): PastLow {
  
  return {
    _type: "PastLow",
    
    toString: () => (["PastLow(", value[0].toString(), ',', value[1].toString(), ")"].join(""))
  }
}

/**
 * <h1>PastClose</h1>
 * <p>Takes a tuple comprising: an OptInstrumentFrequency, and one of , or a Window</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>PastClose = {"PastClose(" ~ OPT_INSTRUMENT_FREQUENCY? ~ ("1" | WINDOW) ~ ")" }</code>
 * </p>
*/
type PastClose = {
  _type: "PastClose";
  
  toString: () => string;
};

/**
 * <p>Create PastClose serializer object.</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>PastClose = {"PastClose(" ~ OPT_INSTRUMENT_FREQUENCY? ~ ("1" | WINDOW) ~ ")" }</code>
 * </p>
 * 
 * @param value - a tuple comprising: an OptInstrumentFrequency, and one of , or a Window
 * @returns The created serializer object.
 */
function pastClose(value: [ OptInstrumentFrequency, "1" | Window ]): PastClose {
  
  return {
    _type: "PastClose",
    
    toString: () => (["PastClose(", value[0].toString(), ',', value[1].toString(), ")"].join(""))
  }
}

/**
 * <h1>Roc</h1>
 * <p>Takes a tuple comprising: an OptInstrumentFrequency, and a number.</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>ROC = {"ROC(" ~ OPT_INSTRUMENT_FREQUENCY? ~ WINDOW ~ ")" }</code>
 * </p>
*/
type Roc = {
  _type: "ROC";
  
  toString: () => string;
};

/**
 * <p>Create Roc serializer object.</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>ROC = {"ROC(" ~ OPT_INSTRUMENT_FREQUENCY? ~ WINDOW ~ ")" }</code>
 * </p>
 * 
 * @param value - a tuple comprising: an OptInstrumentFrequency, and a number.
 * @returns The created serializer object.
 */
function roc(value: [ OptInstrumentFrequency, number ]): Roc {
  
  return {
    _type: "ROC",
    
    toString: () => (["ROC(", value[0] ? value[0].toString() : null, value[1] ? `,` + value[1].toString() : null, ")"].join(""))
  }
}

/**
 * <h1>StDev</h1>
 * <p>Takes a tuple comprising: an OptInstrumentFrequency, and a number.</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>StDev = {"StDev(" ~ OPT_INSTRUMENT_FREQUENCY? ~ WINDOW ~ ")" }</code>
 * </p>
*/
type StDev = {
  _type: "StDev";
  
  toString: () => string;
};

/**
 * <p>Create StDev serializer object.</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>StDev = {"StDev(" ~ OPT_INSTRUMENT_FREQUENCY? ~ WINDOW ~ ")" }</code>
 * </p>
 * 
 * @param value - a tuple comprising: an OptInstrumentFrequency, and a number.
 * @returns The created serializer object.
 */
function stDev(value: [ OptInstrumentFrequency, number ]): StDev {
  
  return {
    _type: "StDev",
    
    toString: () => (["StDev(", value[0] ? value[0].toString() : null, value[1] ? `,` + value[1].toString() : null, ")"].join(""))
  }
}

/**
 * <h1>LinearVolatility</h1>
 * <p>Takes a tuple comprising: an OptInstrumentFrequency, and a number.</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>LinearVolatility = {"LinearVolatility(" ~ OPT_INSTRUMENT_FREQUENCY? ~ WINDOW ~ ")" }</code>
 * </p>
*/
type LinearVolatility = {
  _type: "LinearVolatility";
  
  toString: () => string;
};

/**
 * <p>Create LinearVolatility serializer object.</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>LinearVolatility = {"LinearVolatility(" ~ OPT_INSTRUMENT_FREQUENCY? ~ WINDOW ~ ")" }</code>
 * </p>
 * 
 * @param value - a tuple comprising: an OptInstrumentFrequency, and a number.
 * @returns The created serializer object.
 */
function linearVolatility(value: [ OptInstrumentFrequency, number ]): LinearVolatility {
  
  return {
    _type: "LinearVolatility",
    
    toString: () => (["LinearVolatility(", value[0] ? value[0].toString() : null, value[1] ? `,` + value[1].toString() : null, ")"].join(""))
  }
}

/**
 * <h1>AllTimeSeriesOps</h1>
 * <p>Takes one of a Cci, or a Derivative, or a Highest, or a Lowest, or a HighestLowestDelta, or a HighestIndex, or a LowestIndex, or an Integral, or a MeanAbsDev, or a MedianAbsDev, or a Momentum, or a Roc, or a StDev, or a LinearVolatility, or a PastOpen, or a PastLow, or a PastHigh, or a PastClose</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>ALL_TIME_SERIES_OPS = _{CCI | Derivative | Highest | Lowest | HighestLowestDelta | HighestIndex
 * 	| LowestIndex | Integral | MeanAbsDev | MedianAbsDev | Momentum
 * 	| ROC | StDev | LinearVolatility
 * 	| PastOpen | PastLow | PastHigh | PastClose
 * }</code>
 * </p>
*/
type AllTimeSeriesOps = {
  _type: "ALL_TIME_SERIES_OPS";
  
  toString: () => string;
};

/**
 * <p>Create AllTimeSeriesOps serializer object.</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>ALL_TIME_SERIES_OPS = _{CCI | Derivative | Highest | Lowest | HighestLowestDelta | HighestIndex
 * 	| LowestIndex | Integral | MeanAbsDev | MedianAbsDev | Momentum
 * 	| ROC | StDev | LinearVolatility
 * 	| PastOpen | PastLow | PastHigh | PastClose
 * }</code>
 * </p>
 * 
 * @param value - one of a Cci, or a Derivative, or a Highest, or a Lowest, or a HighestLowestDelta, or a HighestIndex, or a LowestIndex, or an Integral, or a MeanAbsDev, or a MedianAbsDev, or a Momentum, or a Roc, or a StDev, or a LinearVolatility, or a PastOpen, or a PastLow, or a PastHigh, or a PastClose
 * @returns The created serializer object.
 */
function allTimeSeriesOps(value: Cci | Derivative | Highest | Lowest | HighestLowestDelta | HighestIndex | LowestIndex | Integral | MeanAbsDev | MedianAbsDev | Momentum | Roc | StDev | LinearVolatility | PastOpen | PastLow | PastHigh | PastClose): AllTimeSeriesOps {
  
  return {
    _type: "ALL_TIME_SERIES_OPS",
    
    toString: () => (value.toString())
  }
}

/**
 * <h1>AroonUp</h1>
 * <p>Takes </p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>AROON_UP = {^"AROON_UP" | ^"UP" }</code>
 * </p>
*/
type AroonUp = {
  _type: "AROON_UP";
  
  toString: () => string;
};

/**
 * <p>Create AroonUp serializer object.</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>AROON_UP = {^"AROON_UP" | ^"UP" }</code>
 * </p>
 * 
 * @returns The created serializer object.
 */
function aroonUp(): AroonUp {
  
  return {
    _type: "AROON_UP",
    
    toString: () => ("AROON_UP")
  }
}

/**
 * <h1>AroonDown</h1>
 * <p>Takes </p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>AROON_DOWN = {^"AROON_DOWN" | ^"DOWN" }</code>
 * </p>
*/
type AroonDown = {
  _type: "AROON_DOWN";
  
  toString: () => string;
};

/**
 * <p>Create AroonDown serializer object.</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>AROON_DOWN = {^"AROON_DOWN" | ^"DOWN" }</code>
 * </p>
 * 
 * @returns The created serializer object.
 */
function aroonDown(): AroonDown {
  
  return {
    _type: "AROON_DOWN",
    
    toString: () => ("AROON_DOWN")
  }
}

/**
 * <h1>AroonValue</h1>
 * <p>Takes one of an AroonUp, or an AroonDown</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>AROON_VALUE = _{(AROON_UP | AROON_DOWN) }</code>
 * </p>
*/
type AroonValue = {
  _type: "AROON_VALUE";
  
  toString: () => string;
};

/**
 * <p>Create AroonValue serializer object.</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>AROON_VALUE = _{(AROON_UP | AROON_DOWN) }</code>
 * </p>
 * 
 * @param value - one of an AroonUp, or an AroonDown
 * @returns The created serializer object.
 */
function aroonValue(value: AroonUp | AroonDown): AroonValue {
  
  return {
    _type: "AROON_VALUE",
    
    toString: () => (value.toString())
  }
}

/**
 * <h1>Fast</h1>
 * <p>Takes </p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>FAST = {^"FAST" }</code>
 * </p>
*/
type Fast = {
  _type: "FAST";
  
  toString: () => string;
};

/**
 * <p>Create Fast serializer object.</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>FAST = {^"FAST" }</code>
 * </p>
 * 
 * @returns The created serializer object.
 */
function fast(): Fast {
  
  return {
    _type: "FAST",
    
    toString: () => ("FAST")
  }
}

/**
 * <h1>Slow</h1>
 * <p>Takes </p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>SLOW = {^"SLOW" }</code>
 * </p>
*/
type Slow = {
  _type: "SLOW";
  
  toString: () => string;
};

/**
 * <p>Create Slow serializer object.</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>SLOW = {^"SLOW" }</code>
 * </p>
 * 
 * @returns The created serializer object.
 */
function slow(): Slow {
  
  return {
    _type: "SLOW",
    
    toString: () => ("SLOW")
  }
}

/**
 * <h1>MomentumValue</h1>
 * <p>Takes one of a Fast, or a Slow</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>MOMENTUM_VALUE = _{(FAST | SLOW) }</code>
 * </p>
*/
type MomentumValue = {
  _type: "MOMENTUM_VALUE";
  
  toString: () => string;
};

/**
 * <p>Create MomentumValue serializer object.</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>MOMENTUM_VALUE = _{(FAST | SLOW) }</code>
 * </p>
 * 
 * @param value - one of a Fast, or a Slow
 * @returns The created serializer object.
 */
function momentumValue(value: Fast | Slow): MomentumValue {
  
  return {
    _type: "MOMENTUM_VALUE",
    
    toString: () => (value.toString())
  }
}

/**
 * <h1>AdiAdx</h1>
 * <p>Takes </p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>ADI_ADX = {^"ADX" }</code>
 * </p>
*/
type AdiAdx = {
  _type: "ADI_ADX";
  
  toString: () => string;
};

/**
 * <p>Create AdiAdx serializer object.</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>ADI_ADX = {^"ADX" }</code>
 * </p>
 * 
 * @returns The created serializer object.
 */
function adiAdx(): AdiAdx {
  
  return {
    _type: "ADI_ADX",
    
    toString: () => ("ADX")
  }
}

/**
 * <h1>AdiPlus</h1>
 * <p>Takes </p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>ADI_PLUS = {^"PLUS" }</code>
 * </p>
*/
type AdiPlus = {
  _type: "ADI_PLUS";
  
  toString: () => string;
};

/**
 * <p>Create AdiPlus serializer object.</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>ADI_PLUS = {^"PLUS" }</code>
 * </p>
 * 
 * @returns The created serializer object.
 */
function adiPlus(): AdiPlus {
  
  return {
    _type: "ADI_PLUS",
    
    toString: () => ("PLUS")
  }
}

/**
 * <h1>AdiMinus</h1>
 * <p>Takes </p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>ADI_MINUS = {^"MINUS" }</code>
 * </p>
*/
type AdiMinus = {
  _type: "ADI_MINUS";
  
  toString: () => string;
};

/**
 * <p>Create AdiMinus serializer object.</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>ADI_MINUS = {^"MINUS" }</code>
 * </p>
 * 
 * @returns The created serializer object.
 */
function adiMinus(): AdiMinus {
  
  return {
    _type: "ADI_MINUS",
    
    toString: () => ("MINUS")
  }
}

/**
 * <h1>AdiValue</h1>
 * <p>Takes one of an AdiAdx, or an AdiPlus, or an AdiMinus</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>ADI_VALUE = _{(ADI_ADX | ADI_PLUS | ADI_MINUS) }</code>
 * </p>
*/
type AdiValue = {
  _type: "ADI_VALUE";
  
  toString: () => string;
};

/**
 * <p>Create AdiValue serializer object.</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>ADI_VALUE = _{(ADI_ADX | ADI_PLUS | ADI_MINUS) }</code>
 * </p>
 * 
 * @param value - one of an AdiAdx, or an AdiPlus, or an AdiMinus
 * @returns The created serializer object.
 */
function adiValue(value: AdiAdx | AdiPlus | AdiMinus): AdiValue {
  
  return {
    _type: "ADI_VALUE",
    
    toString: () => (value.toString())
  }
}

/**
 * <h1>UpperBand</h1>
 * <p>Takes </p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>UPPER_BAND = {^"UPPER BAND" | ^"UPPER" }</code>
 * </p>
*/
type UpperBand = {
  _type: "UPPER_BAND";
  
  toString: () => string;
};

/**
 * <p>Create UpperBand serializer object.</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>UPPER_BAND = {^"UPPER BAND" | ^"UPPER" }</code>
 * </p>
 * 
 * @returns The created serializer object.
 */
function upperBand(): UpperBand {
  
  return {
    _type: "UPPER_BAND",
    
    toString: () => ("UPPER BAND")
  }
}

/**
 * <h1>MiddleBand</h1>
 * <p>Takes </p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>MIDDLE_BAND = {^"MIDDLE BAND" | ^"MIDDLE" }</code>
 * </p>
*/
type MiddleBand = {
  _type: "MIDDLE_BAND";
  
  toString: () => string;
};

/**
 * <p>Create MiddleBand serializer object.</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>MIDDLE_BAND = {^"MIDDLE BAND" | ^"MIDDLE" }</code>
 * </p>
 * 
 * @returns The created serializer object.
 */
function middleBand(): MiddleBand {
  
  return {
    _type: "MIDDLE_BAND",
    
    toString: () => ("MIDDLE BAND")
  }
}

/**
 * <h1>LowerBand</h1>
 * <p>Takes </p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>LOWER_BAND = {^"LOWER BAND" | ^"LOWER" }</code>
 * </p>
*/
type LowerBand = {
  _type: "LOWER_BAND";
  
  toString: () => string;
};

/**
 * <p>Create LowerBand serializer object.</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>LOWER_BAND = {^"LOWER BAND" | ^"LOWER" }</code>
 * </p>
 * 
 * @returns The created serializer object.
 */
function lowerBand(): LowerBand {
  
  return {
    _type: "LOWER_BAND",
    
    toString: () => ("LOWER BAND")
  }
}

/**
 * <h1>BbValue</h1>
 * <p>Takes one of an UpperBand, or a MiddleBand, or a LowerBand</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>BB_VALUE = _{(UPPER_BAND | MIDDLE_BAND | LOWER_BAND) }</code>
 * </p>
*/
type BbValue = {
  _type: "BB_VALUE";
  
  toString: () => string;
};

/**
 * <p>Create BbValue serializer object.</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>BB_VALUE = _{(UPPER_BAND | MIDDLE_BAND | LOWER_BAND) }</code>
 * </p>
 * 
 * @param value - one of an UpperBand, or a MiddleBand, or a LowerBand
 * @returns The created serializer object.
 */
function bbValue(value: UpperBand | MiddleBand | LowerBand): BbValue {
  
  return {
    _type: "BB_VALUE",
    
    toString: () => (value.toString())
  }
}

/**
 * <h1>TenkanSen</h1>
 * <p>Takes </p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>TENKAN_SEN = {^"TENKAN_SEN" | ^"TenkanSen" }</code>
 * </p>
*/
type TenkanSen = {
  _type: "TENKAN_SEN";
  
  toString: () => string;
};

/**
 * <p>Create TenkanSen serializer object.</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>TENKAN_SEN = {^"TENKAN_SEN" | ^"TenkanSen" }</code>
 * </p>
 * 
 * @returns The created serializer object.
 */
function tenkanSen(): TenkanSen {
  
  return {
    _type: "TENKAN_SEN",
    
    toString: () => ("TENKAN_SEN")
  }
}

/**
 * <h1>KijunSen</h1>
 * <p>Takes </p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>KIJUN_SEN = {^"KIJUN_SEN" | ^"KijunSen" }</code>
 * </p>
*/
type KijunSen = {
  _type: "KIJUN_SEN";
  
  toString: () => string;
};

/**
 * <p>Create KijunSen serializer object.</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>KIJUN_SEN = {^"KIJUN_SEN" | ^"KijunSen" }</code>
 * </p>
 * 
 * @returns The created serializer object.
 */
function kijunSen(): KijunSen {
  
  return {
    _type: "KIJUN_SEN",
    
    toString: () => ("KIJUN_SEN")
  }
}

/**
 * <h1>SenkouSpanA</h1>
 * <p>Takes </p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>SENKOU_SPAN_A = {^"SENKOU_SPAN_A" | ^"SenkouSpanA" }</code>
 * </p>
*/
type SenkouSpanA = {
  _type: "SENKOU_SPAN_A";
  
  toString: () => string;
};

/**
 * <p>Create SenkouSpanA serializer object.</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>SENKOU_SPAN_A = {^"SENKOU_SPAN_A" | ^"SenkouSpanA" }</code>
 * </p>
 * 
 * @returns The created serializer object.
 */
function senkouSpanA(): SenkouSpanA {
  
  return {
    _type: "SENKOU_SPAN_A",
    
    toString: () => ("SENKOU_SPAN_A")
  }
}

/**
 * <h1>SenkouSpanB</h1>
 * <p>Takes </p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>SENKOU_SPAN_B = {^"SENKOU_SPAN_B" | ^"SenkouSpanB" }</code>
 * </p>
*/
type SenkouSpanB = {
  _type: "SENKOU_SPAN_B";
  
  toString: () => string;
};

/**
 * <p>Create SenkouSpanB serializer object.</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>SENKOU_SPAN_B = {^"SENKOU_SPAN_B" | ^"SenkouSpanB" }</code>
 * </p>
 * 
 * @returns The created serializer object.
 */
function senkouSpanB(): SenkouSpanB {
  
  return {
    _type: "SENKOU_SPAN_B",
    
    toString: () => ("SENKOU_SPAN_B")
  }
}

/**
 * <h1>IcValue</h1>
 * <p>Takes one of a TenkanSen, or a KijunSen, or a SenkouSpanA, or a SenkouSpanB</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>IC_VALUE = _{(TENKAN_SEN | KIJUN_SEN | SENKOU_SPAN_A | SENKOU_SPAN_B) }</code>
 * </p>
*/
type IcValue = {
  _type: "IC_VALUE";
  
  toString: () => string;
};

/**
 * <p>Create IcValue serializer object.</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>IC_VALUE = _{(TENKAN_SEN | KIJUN_SEN | SENKOU_SPAN_A | SENKOU_SPAN_B) }</code>
 * </p>
 * 
 * @param value - one of a TenkanSen, or a KijunSen, or a SenkouSpanA, or a SenkouSpanB
 * @returns The created serializer object.
 */
function icValue(value: TenkanSen | KijunSen | SenkouSpanA | SenkouSpanB): IcValue {
  
  return {
    _type: "IC_VALUE",
    
    toString: () => (value.toString())
  }
}

/**
 * <h1>Value</h1>
 * <p>Takes </p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>VALUE = {^"VALUE" }</code>
 * </p>
*/
type Value = {
  _type: "VALUE";
  
  toString: () => string;
};

/**
 * <p>Create Value serializer object.</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>VALUE = {^"VALUE" }</code>
 * </p>
 * 
 * @returns The created serializer object.
 */
function value(): Value {
  
  return {
    _type: "VALUE",
    
    toString: () => ("VALUE")
  }
}

/**
 * <h1>Signal</h1>
 * <p>Takes </p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>SIGNAL = {^"SIGNAL" }</code>
 * </p>
*/
type Signal = {
  _type: "SIGNAL";
  
  toString: () => string;
};

/**
 * <p>Create Signal serializer object.</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>SIGNAL = {^"SIGNAL" }</code>
 * </p>
 * 
 * @returns The created serializer object.
 */
function signal(): Signal {
  
  return {
    _type: "SIGNAL",
    
    toString: () => ("SIGNAL")
  }
}

/**
 * <h1>Main</h1>
 * <p>Takes </p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>MAIN = {^"MAIN" }</code>
 * </p>
*/
type Main = {
  _type: "MAIN";
  
  toString: () => string;
};

/**
 * <p>Create Main serializer object.</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>MAIN = {^"MAIN" }</code>
 * </p>
 * 
 * @returns The created serializer object.
 */
function main(): Main {
  
  return {
    _type: "MAIN",
    
    toString: () => ("MAIN")
  }
}

/**
 * <h1>MacdValue</h1>
 * <p>Takes one of a Value, or a Signal</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>MACD_VALUE = _{(VALUE | SIGNAL) }</code>
 * </p>
*/
type MacdValue = {
  _type: "MACD_VALUE";
  
  toString: () => string;
};

/**
 * <p>Create MacdValue serializer object.</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>MACD_VALUE = _{(VALUE | SIGNAL) }</code>
 * </p>
 * 
 * @param value - one of a Value, or a Signal
 * @returns The created serializer object.
 */
function macdValue(value: Value | Signal | string ): MacdValue {
  
  return {
    _type: "MACD_VALUE",
    
    toString: () => (value.toString())
  }
}

/**
 * <h1>TrixValue</h1>
 * <p>Takes one of a Value, or a Signal</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>TRIX_VALUE = _{(VALUE | SIGNAL) }</code>
 * </p>
*/
type TrixValue = {
  _type: "TRIX_VALUE";
  
  toString: () => string;
};

/**
 * <p>Create TrixValue serializer object.</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>TRIX_VALUE = _{(VALUE | SIGNAL) }</code>
 * </p>
 * 
 * @param value - one of a Value, or a Signal
 * @returns The created serializer object.
 */
function trixValue(value: Value | Signal): TrixValue {
  
  return {
    _type: "TRIX_VALUE",
    
    toString: () => (value.toString())
  }
}

/**
 * <h1>TsiValue</h1>
 * <p>Takes one of a Value, or a Signal</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>TSI_VALUE = _{(VALUE | SIGNAL) }</code>
 * </p>
*/
type TsiValue = {
  _type: "TSI_VALUE";
  
  toString: () => string;
};

/**
 * <p>Create TsiValue serializer object.</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>TSI_VALUE = _{(VALUE | SIGNAL) }</code>
 * </p>
 * 
 * @param value - one of a Value, or a Signal
 * @returns The created serializer object.
 */
function tsiValue(value: Value | Signal): TsiValue {
  
  return {
    _type: "TSI_VALUE",
    
    toString: () => (value.toString())
  }
}

/**
 * <h1>KvoValue</h1>
 * <p>Takes one of a Main, or a Signal</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>KVO_VALUE = _{MAIN | SIGNAL }</code>
 * </p>
*/
type KvoValue = {
  _type: "KVO_VALUE";
  
  toString: () => string;
};

/**
 * <p>Create KvoValue serializer object.</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>KVO_VALUE = _{MAIN | SIGNAL }</code>
 * </p>
 * 
 * @param value - one of a Main, or a Signal
 * @returns The created serializer object.
 */
function kvoValue(value: Main | Signal): KvoValue {
  
  return {
    _type: "KVO_VALUE",
    
    toString: () => (value.toString())
  }
}

/**
 * <h1>F1</h1>
 * <p>Takes </p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>F1 = {^"F1" }</code>
 * </p>
*/
type F1 = {
  _type: "F1";
  
  toString: () => string;
};

/**
 * <p>Create F1 serializer object.</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>F1 = {^"F1" }</code>
 * </p>
 * 
 * @returns The created serializer object.
 */
function f1(): F1 {
  
  return {
    _type: "F1",
    
    toString: () => ("F1")
  }
}

/**
 * <h1>F2</h1>
 * <p>Takes </p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>F2 = {^"F2" }</code>
 * </p>
*/
type F2 = {
  _type: "F2";
  
  toString: () => string;
};

/**
 * <p>Create F2 serializer object.</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>F2 = {^"F2" }</code>
 * </p>
 * 
 * @returns The created serializer object.
 */
function f2(): F2 {
  
  return {
    _type: "F2",
    
    toString: () => ("F2")
  }
}

/**
 * <h1>StoOscValue</h1>
 * <p>Takes one of a F1, or a F2</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>STO_OSC_VALUE = _{(F1 | F2) }</code>
 * </p>
*/
type StoOscValue = {
  _type: "STO_OSC_VALUE";
  
  toString: () => string;
};

/**
 * <p>Create StoOscValue serializer object.</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>STO_OSC_VALUE = _{(F1 | F2) }</code>
 * </p>
 * 
 * @param value - one of a F1, or a F2
 * @returns The created serializer object.
 */
function stoOscValue(value: F1 | F2): StoOscValue {
  
  return {
    _type: "STO_OSC_VALUE",
    
    toString: () => (value.toString())
  }
}

/**
 * <h1>Sar</h1>
 * <p>Takes </p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>SAR = {^"SAR" }</code>
 * </p>
*/
type Sar = {
  _type: "SAR";
  
  toString: () => string;
};

/**
 * <p>Create Sar serializer object.</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>SAR = {^"SAR" }</code>
 * </p>
 * 
 * @returns The created serializer object.
 */
function sar(): Sar {
  
  return {
    _type: "SAR",
    
    toString: () => ("SAR")
  }
}

/**
 * <h1>Trend</h1>
 * <p>Takes </p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>TREND = {^"TREND" }</code>
 * </p>
*/
type Trend = {
  _type: "TREND";
  
  toString: () => string;
};

/**
 * <p>Create Trend serializer object.</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>TREND = {^"TREND" }</code>
 * </p>
 * 
 * @returns The created serializer object.
 */
function trend(): Trend {
  
  return {
    _type: "TREND",
    
    toString: () => ("TREND")
  }
}

/**
 * <h1>ParaSarValue</h1>
 * <p>Takes one of a Sar, or a Trend</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>PARA_SAR_VALUE = _{(SAR | TREND) }</code>
 * </p>
*/
type ParaSarValue = {
  _type: "PARA_SAR_VALUE";
  
  toString: () => string;
};

/**
 * <p>Create ParaSarValue serializer object.</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>PARA_SAR_VALUE = _{(SAR | TREND) }</code>
 * </p>
 * 
 * @param value - one of a Sar, or a Trend
 * @returns The created serializer object.
 */
function paraSarValue(value: Sar | Trend): ParaSarValue {
  
  return {
    _type: "PARA_SAR_VALUE",
    
    toString: () => (value.toString())
  }
}

/**
 * <h1>AdiInner</h1>
 * <p>Takes one of a tuple comprising: a Window, and a Window, and a ZeroToOne, and (optional), an AdiValue, or an AdiValue</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>ADI_INNER = _{(WINDOW ~ WINDOW ~ ZERO_TO_ONE ~ (ADI_VALUE)?) | ADI_VALUE }</code>
 * </p>
*/
type AdiInner = {
  _type: "ADI_INNER";
  
  toString: () => string;
};

/**
 * <p>Create AdiInner serializer object.</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>ADI_INNER = _{(WINDOW ~ WINDOW ~ ZERO_TO_ONE ~ (ADI_VALUE)?) | ADI_VALUE }</code>
 * </p>
 * 
 * @param value - one of a tuple comprising: a Window, and a Window, and a ZeroToOne, and (optional), an AdiValue, or an AdiValue
 * @returns The created serializer object.
 */
function adiInner(value: [ Window, Window, ZeroToOne, (AdiValue)[] ] | AdiValue): AdiInner {
  
  return {
    _type: "ADI_INNER",
    
    toString: () => (value.toString())
  }
}

/**
 * <h1>AverageDirectionalIndex</h1>
 * <p>Takes a tuple comprising: an OptInstrumentFrequency, and an AdiInner</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>AverageDirectionalIndex = {"AverageDirectionalIndex(" ~ OPT_INSTRUMENT_FREQUENCY? ~ ADI_INNER? ~ ")" }</code>
 * </p>
*/
type AverageDirectionalIndex = {
  _type: "AverageDirectionalIndex";
  
  toString: () => string;
};

/**
 * <p>Create AverageDirectionalIndex serializer object.</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>AverageDirectionalIndex = {"AverageDirectionalIndex(" ~ OPT_INSTRUMENT_FREQUENCY? ~ ADI_INNER? ~ ")" }</code>
 * </p>
 * 
 * @param value - a tuple comprising: an OptInstrumentFrequency, and an AdiInner
 * @returns The created serializer object.
 */
function averageDirectionalIndex(value: [ OptInstrumentFrequency, AdiInner ]): AverageDirectionalIndex {
  
  return {
    _type: "AverageDirectionalIndex",
    
    toString: () => (["AverageDirectionalIndex(", value[0] ? value[0].toString() : null, value[1] ? `,` + value[1].toString() : null, ")"].join(""))
  }
}

/**
 * <h1>AwesomeOscillator</h1>
 * <p>Takes an OptInstrumentFrequency</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>AwesomeOscillator = {"AwesomeOscillator(" ~ OPT_INSTRUMENT_FREQUENCY? ~ ")" }</code>
 * </p>
*/
type AwesomeOscillator = {
  _type: "AwesomeOscillator";
  
  toString: () => string;
};

/**
 * <p>Create AwesomeOscillator serializer object.</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>AwesomeOscillator = {"AwesomeOscillator(" ~ OPT_INSTRUMENT_FREQUENCY? ~ ")" }</code>
 * </p>
 * 
 * @param value - an OptInstrumentFrequency
 * @returns The created serializer object.
 */
function awesomeOscillator(value: OptInstrumentFrequency): AwesomeOscillator {
  
  return {
    _type: "AwesomeOscillator",
    
    toString: () => (["AwesomeOscillator(", value.toString(), ")"].join(""))
  }
}

/**
 * <h1>AroonInner</h1>
 * <p>Takes one of a tuple comprising: a Window, and a ZeroToOne, and a Window, and (optional), an AroonValue, or an AroonValue</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>AROON_INNER = _{(WINDOW ~ ZERO_TO_ONE ~ WINDOW ~ (AROON_VALUE)?) | AROON_VALUE }</code>
 * </p>
*/
type AroonInner = {
  _type: "AROON_INNER";
  
  toString: () => string;
};

/**
 * <p>Create AroonInner serializer object.</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>AROON_INNER = _{(WINDOW ~ ZERO_TO_ONE ~ WINDOW ~ (AROON_VALUE)?) | AROON_VALUE }</code>
 * </p>
 * 
 * @param value - one of a tuple comprising: a Window, and a ZeroToOne, and a Window, and (optional), an AroonValue, or an AroonValue
 * @returns The created serializer object.
 */
function aroonInner(value: [ Window, ZeroToOne, Window, (AroonValue)[] ] | AroonValue): AroonInner {
  
  return {
    _type: "AROON_INNER",
    
    toString: () => (value.toString())
  }
}

/**
 * <h1>Aroon</h1>
 * <p>Takes a tuple comprising: an OptInstrumentFrequency, and an AroonInner</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>Aroon = {"Aroon(" ~ OPT_INSTRUMENT_FREQUENCY? ~ AROON_INNER? ~ ")" }</code>
 * </p>
*/
type Aroon = {
  _type: "Aroon";
  
  toString: () => string;
};

/**
 * <p>Create Aroon serializer object.</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>Aroon = {"Aroon(" ~ OPT_INSTRUMENT_FREQUENCY? ~ AROON_INNER? ~ ")" }</code>
 * </p>
 * 
 * @param value - a tuple comprising: an OptInstrumentFrequency, and an AroonInner
 * @returns The created serializer object.
 */
function aroon(value: [ OptInstrumentFrequency, AroonInner ]): Aroon {
  
  return {
    _type: "Aroon",
    
    toString: () => (["Aroon(", value[0] ? value[0].toString() : null, value[1] ? `,` + value[1].toString() : null, ")"].join(""))
  }
}

/**
 * <h1>BollingerBandsInner</h1>
 * <p>Takes one of a tuple comprising: a Window, and a Float, and (optional), a BbValue, or a BbValue</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>BollingerBandsInner = _{WINDOW ~ FLOAT ~ (BB_VALUE)? | BB_VALUE }</code>
 * </p>
*/
type BollingerBandsInner = {
  _type: "BollingerBandsInner";
  
  toString: () => string;
};

/**
 * <p>Create BollingerBandsInner serializer object.</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>BollingerBandsInner = _{WINDOW ~ FLOAT ~ (BB_VALUE)? | BB_VALUE }</code>
 * </p>
 * 
 * @param value - one of a tuple comprising: a Window, and a Float, and (optional), a BbValue, or a BbValue
 * @returns The created serializer object.
 */
function bollingerBandsInner(value: [ Window, Float, (BbValue)[] ] | BbValue): BollingerBandsInner {
  
  return {
    _type: "BollingerBandsInner",
    
    toString: () => (value.toString())
  }
}

/**
 * <h1>BollingerBands</h1>
 * <p>Takes a tuple comprising: an OptInstrumentFrequency, and a BollingerBandsInner</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>BollingerBands = {"BollingerBands(" ~ OPT_INSTRUMENT_FREQUENCY? ~ BollingerBandsInner? ~ ")" }</code>
 * </p>
*/
type BollingerBands = {
  _type: "BollingerBands";
  
  toString: () => string;
};

/**
 * <p>Create BollingerBands serializer object.</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>BollingerBands = {"BollingerBands(" ~ OPT_INSTRUMENT_FREQUENCY? ~ BollingerBandsInner? ~ ")" }</code>
 * </p>
 * 
 * @param value - a tuple comprising: an OptInstrumentFrequency, and a BollingerBandsInner
 * @returns The created serializer object.
 */
function bollingerBands(value: [ OptInstrumentFrequency, BollingerBandsInner ]): BollingerBands {
  
  return {
    _type: "BollingerBands",
    
    toString: () => (["BollingerBands(", value[0] ? value[0].toString() : null, value[1] ? `,` + value[1].toString() : null, ")"].join(""))
  }
}

/**
 * <h1>ChaikinMoneyFlow</h1>
 * <p>Takes a tuple comprising: an OptInstrumentFrequency, and a Window</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>ChaikinMoneyFlow = {"ChaikinMoneyFlow(" ~ OPT_INSTRUMENT_FREQUENCY? ~ WINDOW? ~ ")" }</code>
 * </p>
*/
type ChaikinMoneyFlow = {
  _type: "ChaikinMoneyFlow";
  
  toString: () => string;
};

/**
 * <p>Create ChaikinMoneyFlow serializer object.</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>ChaikinMoneyFlow = {"ChaikinMoneyFlow(" ~ OPT_INSTRUMENT_FREQUENCY? ~ WINDOW? ~ ")" }</code>
 * </p>
 * 
 * @param value - a tuple comprising: an OptInstrumentFrequency, and a Window
 * @returns The created serializer object.
 */
function chaikinMoneyFlow(value: [ OptInstrumentFrequency, Window ]): ChaikinMoneyFlow {
  
  return {
    _type: "ChaikinMoneyFlow",
    
    toString: () => (["ChaikinMoneyFlow(", value[0] ? value[0].toString() : null, value[1] ? `,` + value[1].toString() : null, ")"].join(""))
  }
}

/**
 * <h1>ChaikinOscillatorInner</h1>
 * <p>Takes a tuple comprising: a number., and a number.</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>ChaikinOscillatorInner = _{WINDOW ~ WINDOW }</code>
 * </p>
*/
type ChaikinOscillatorInner = {
  _type: "ChaikinOscillatorInner";
  
  toString: () => string;
};

/**
 * <p>Create ChaikinOscillatorInner serializer object.</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>ChaikinOscillatorInner = _{WINDOW ~ WINDOW }</code>
 * </p>
 * 
 * @param value - a tuple comprising: a number., and a number.
 * @returns The created serializer object.
 */
function chaikinOscillatorInner(value: [ number, number ]): ChaikinOscillatorInner {
  
  return {
    _type: "ChaikinOscillatorInner",
    
    toString: () => ([value[0] ? value[0].toString() : null, value[1] ? `,` + value[1].toString() : null].join(""))
  }
}

/**
 * <h1>ChaikinOscillator</h1>
 * <p>Takes a tuple comprising: an OptInstrumentFrequency, and a ChaikinOscillatorInner</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>ChaikinOscillator = {"ChaikinOscillator(" ~ OPT_INSTRUMENT_FREQUENCY? ~ ChaikinOscillatorInner? ~ ")" }</code>
 * </p>
*/
type ChaikinOscillator = {
  _type: "ChaikinOscillator";
  
  toString: () => string;
};

/**
 * <p>Create ChaikinOscillator serializer object.</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>ChaikinOscillator = {"ChaikinOscillator(" ~ OPT_INSTRUMENT_FREQUENCY? ~ ChaikinOscillatorInner? ~ ")" }</code>
 * </p>
 * 
 * @param value - a tuple comprising: an OptInstrumentFrequency, and a ChaikinOscillatorInner
 * @returns The created serializer object.
 */
function chaikinOscillator(value: [ OptInstrumentFrequency, ChaikinOscillatorInner ]): ChaikinOscillator {
  
  return {
    _type: "ChaikinOscillator",
    
    toString: () => (["ChaikinOscillator(", value[0] ? value[0].toString() : null, value[1] ? `,` + value[1].toString() : null, ")"].join(""))
  }
}

/**
 * <h1>ChandeMomentumOscillatorInner</h1>
 * <p>Takes a tuple comprising: a number., and a number.</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>ChandeMomentumOscillatorInner = _{WINDOW ~ ZERO_TO_ONE }</code>
 * </p>
*/
type ChandeMomentumOscillatorInner = {
  _type: "ChandeMomentumOscillatorInner";
  
  toString: () => string;
};

/**
 * <p>Create ChandeMomentumOscillatorInner serializer object.</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>ChandeMomentumOscillatorInner = _{WINDOW ~ ZERO_TO_ONE }</code>
 * </p>
 * 
 * @param value - a tuple comprising: a number., and a number.
 * @returns The created serializer object.
 */
function chandeMomentumOscillatorInner(value: [ number, number ]): ChandeMomentumOscillatorInner {
  
  return {
    _type: "ChandeMomentumOscillatorInner",
    
    toString: () => ([value[0] ? value[0].toString() : null, value[1] ? `,` + value[1].toString() : null].join(""))
  }
}

/**
 * <h1>ChandeMomentumOscillator</h1>
 * <p>Takes a tuple comprising: an OptInstrumentFrequency, and a ChandeMomentumOscillatorInner</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>ChandeMomentumOscillator = {"ChandeMomentumOscillator(" ~ OPT_INSTRUMENT_FREQUENCY? ~ ChandeMomentumOscillatorInner? ~ ")" }</code>
 * </p>
*/
type ChandeMomentumOscillator = {
  _type: "ChandeMomentumOscillator";
  
  toString: () => string;
};

/**
 * <p>Create ChandeMomentumOscillator serializer object.</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>ChandeMomentumOscillator = {"ChandeMomentumOscillator(" ~ OPT_INSTRUMENT_FREQUENCY? ~ ChandeMomentumOscillatorInner? ~ ")" }</code>
 * </p>
 * 
 * @param value - a tuple comprising: an OptInstrumentFrequency, and a ChandeMomentumOscillatorInner
 * @returns The created serializer object.
 */
function chandeMomentumOscillator(value: [ OptInstrumentFrequency, ChandeMomentumOscillatorInner ]): ChandeMomentumOscillator {
  
  return {
    _type: "ChandeMomentumOscillator",
    
    toString: () => (["ChandeMomentumOscillator(", value[0] ? value[0].toString() : null, value[1] ? `,` + value[1].toString() : null, ")"].join(""))
  }
}

/**
 * <h1>CommodityChannelIndexInner</h1>
 * <p>Takes a tuple comprising: a number., and a number.</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>CommodityChannelIndexInner = _{WINDOW ~ FLOAT }</code>
 * </p>
*/
type CommodityChannelIndexInner = {
  _type: "CommodityChannelIndexInner";
  
  toString: () => string;
};

/**
 * <p>Create CommodityChannelIndexInner serializer object.</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>CommodityChannelIndexInner = _{WINDOW ~ FLOAT }</code>
 * </p>
 * 
 * @param value - a tuple comprising: a number., and a number.
 * @returns The created serializer object.
 */
function commodityChannelIndexInner(value: [ number, number ]): CommodityChannelIndexInner {
  
  return {
    _type: "CommodityChannelIndexInner",
    
    toString: () => ([value[0] ? value[0].toString() : null, value[1] ? `,` + value[1].toString() : null].join(""))
  }
}

/**
 * <h1>CommodityChannelIndex</h1>
 * <p>Takes a tuple comprising: an OptInstrumentFrequency, and a CommodityChannelIndexInner</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>CommodityChannelIndex = {"CommodityChannelIndex(" ~ OPT_INSTRUMENT_FREQUENCY? ~ CommodityChannelIndexInner? ~ ")" }</code>
 * </p>
*/
type CommodityChannelIndex = {
  _type: "CommodityChannelIndex";
  
  toString: () => string;
};

/**
 * <p>Create CommodityChannelIndex serializer object.</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>CommodityChannelIndex = {"CommodityChannelIndex(" ~ OPT_INSTRUMENT_FREQUENCY? ~ CommodityChannelIndexInner? ~ ")" }</code>
 * </p>
 * 
 * @param value - a tuple comprising: an OptInstrumentFrequency, and a CommodityChannelIndexInner
 * @returns The created serializer object.
 */
function commodityChannelIndex(value: [ OptInstrumentFrequency, CommodityChannelIndexInner ]): CommodityChannelIndex {
  
  return {
    _type: "CommodityChannelIndex",
    
    toString: () => (["CommodityChannelIndex(", value[0] ? value[0].toString() : null, value[1] ? `,` + value[1].toString() : null, ")"].join(""))
  }
}

/**
 * <h1>DetrendedPriceOscillatorInner</h1>
 * <p>Takes a number.</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>DetrendedPriceOscillatorInner = _{WINDOW }</code>
 * </p>
*/
type DetrendedPriceOscillatorInner = {
  _type: "DetrendedPriceOscillatorInner";
  
  toString: () => string;
};

/**
 * <p>Create DetrendedPriceOscillatorInner serializer object.</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>DetrendedPriceOscillatorInner = _{WINDOW }</code>
 * </p>
 * 
 * @param value - a number.
 * @returns The created serializer object.
 */
function detrendedPriceOscillatorInner(value: number): DetrendedPriceOscillatorInner {
  
  return {
    _type: "DetrendedPriceOscillatorInner",
    
    toString: () => (value.toString(10))
  }
}

/**
 * <h1>DetrendedPriceOscillator</h1>
 * <p>Takes a tuple comprising: an OptInstrumentFrequency, and a DetrendedPriceOscillatorInner</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>DetrendedPriceOscillator = {"DetrendedPriceOscillator(" ~ OPT_INSTRUMENT_FREQUENCY? ~ DetrendedPriceOscillatorInner? ~ ")" }</code>
 * </p>
*/
type DetrendedPriceOscillator = {
  _type: "DetrendedPriceOscillator";
  
  toString: () => string;
};

/**
 * <p>Create DetrendedPriceOscillator serializer object.</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>DetrendedPriceOscillator = {"DetrendedPriceOscillator(" ~ OPT_INSTRUMENT_FREQUENCY? ~ DetrendedPriceOscillatorInner? ~ ")" }</code>
 * </p>
 * 
 * @param value - a tuple comprising: an OptInstrumentFrequency, and a DetrendedPriceOscillatorInner
 * @returns The created serializer object.
 */
function detrendedPriceOscillator(value: [ OptInstrumentFrequency, DetrendedPriceOscillatorInner ]): DetrendedPriceOscillator {
  
  return {
    _type: "DetrendedPriceOscillator",
    
    toString: () => (["DetrendedPriceOscillator(", value[0] ? value[0].toString() : null, value[1] ? `,` + value[1].toString() : null, ")"].join(""))
  }
}

/**
 * <h1>EaseOfMovement</h1>
 * <p>Takes an OptInstrumentFrequency</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>EaseOfMovement = {"EaseOfMovement(" ~ OPT_INSTRUMENT_FREQUENCY? ~ ")" }</code>
 * </p>
*/
type EaseOfMovement = {
  _type: "EaseOfMovement";
  
  toString: () => string;
};

/**
 * <p>Create EaseOfMovement serializer object.</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>EaseOfMovement = {"EaseOfMovement(" ~ OPT_INSTRUMENT_FREQUENCY? ~ ")" }</code>
 * </p>
 * 
 * @param value - an OptInstrumentFrequency
 * @returns The created serializer object.
 */
function easeOfMovement(value: OptInstrumentFrequency): EaseOfMovement {
  
  return {
    _type: "EaseOfMovement",
    
    toString: () => (["EaseOfMovement(", value.toString(), ")"].join(""))
  }
}

/**
 * <h1>EldersForceIndex</h1>
 * <p>Takes an OptInstrumentFrequency</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>EldersForceIndex = {"EldersForceIndex(" ~ OPT_INSTRUMENT_FREQUENCY? ~ ")" }</code>
 * </p>
*/
type EldersForceIndex = {
  _type: "EldersForceIndex";
  
  toString: () => string;
};

/**
 * <p>Create EldersForceIndex serializer object.</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>EldersForceIndex = {"EldersForceIndex(" ~ OPT_INSTRUMENT_FREQUENCY? ~ ")" }</code>
 * </p>
 * 
 * @param value - an OptInstrumentFrequency
 * @returns The created serializer object.
 */
function eldersForceIndex(value: OptInstrumentFrequency): EldersForceIndex {
  
  return {
    _type: "EldersForceIndex",
    
    toString: () => (["EldersForceIndex(", value.toString(), ")"].join(""))
  }
}

/**
 * <h1>Envelopes</h1>
 * <p>Takes an OptInstrumentFrequency</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>Envelopes = {"Envelopes(" ~ OPT_INSTRUMENT_FREQUENCY? ~ ")" }</code>
 * </p>
*/
type Envelopes = {
  _type: "Envelopes";
  
  toString: () => string;
};

/**
 * <p>Create Envelopes serializer object.</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>Envelopes = {"Envelopes(" ~ OPT_INSTRUMENT_FREQUENCY? ~ ")" }</code>
 * </p>
 * 
 * @param value - an OptInstrumentFrequency
 * @returns The created serializer object.
 */
function envelopes(value: OptInstrumentFrequency): Envelopes {
  
  return {
    _type: "Envelopes",
    
    toString: () => (["Envelopes(", value.toString(), ")"].join(""))
  }
}

/**
 * <h1>FisherTransform</h1>
 * <p>Takes an OptInstrumentFrequency</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>FisherTransform = {"FisherTransform(" ~ OPT_INSTRUMENT_FREQUENCY? ~ ")" }</code>
 * </p>
*/
type FisherTransform = {
  _type: "FisherTransform";
  
  toString: () => string;
};

/**
 * <p>Create FisherTransform serializer object.</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>FisherTransform = {"FisherTransform(" ~ OPT_INSTRUMENT_FREQUENCY? ~ ")" }</code>
 * </p>
 * 
 * @param value - an OptInstrumentFrequency
 * @returns The created serializer object.
 */
function fisherTransform(value: OptInstrumentFrequency): FisherTransform {
  
  return {
    _type: "FisherTransform",
    
    toString: () => (["FisherTransform(", value.toString(), ")"].join(""))
  }
}

/**
 * <h1>HullMovingAverageInner</h1>
 * <p>Takes a tuple comprising: a number., and a number., and a number.</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>HullMovingAverageInner = _{WINDOW ~ HALF_WINDOW ~ HALF_WINDOW }</code>
 * </p>
*/
type HullMovingAverageInner = {
  _type: "HullMovingAverageInner";
  
  toString: () => string;
};

/**
 * <p>Create HullMovingAverageInner serializer object.</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>HullMovingAverageInner = _{WINDOW ~ HALF_WINDOW ~ HALF_WINDOW }</code>
 * </p>
 * 
 * @param value - a tuple comprising: a number., and a number., and a number.
 * @returns The created serializer object.
 */
function hullMovingAverageInner(value: [ number, number, number ]): HullMovingAverageInner {
  
  return {
    _type: "HullMovingAverageInner",
    
    toString: () => ([value[0] ? value[0].toString() : null, value[1] ? `,` + value[1].toString() : null, value[2] ? `,` + value[2].toString() : null].join(""))
  }
}

/**
 * <h1>HullMovingAverage</h1>
 * <p>Takes a tuple comprising: an OptInstrumentFrequency, and a HullMovingAverageInner</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>HullMovingAverage = {"HullMovingAverage(" ~ OPT_INSTRUMENT_FREQUENCY? ~ HullMovingAverageInner? ~ ")" }</code>
 * </p>
*/
type HullMovingAverage = {
  _type: "HullMovingAverage";
  
  toString: () => string;
};

/**
 * <p>Create HullMovingAverage serializer object.</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>HullMovingAverage = {"HullMovingAverage(" ~ OPT_INSTRUMENT_FREQUENCY? ~ HullMovingAverageInner? ~ ")" }</code>
 * </p>
 * 
 * @param value - a tuple comprising: an OptInstrumentFrequency, and a HullMovingAverageInner
 * @returns The created serializer object.
 */
function hullMovingAverage(value: [ OptInstrumentFrequency, HullMovingAverageInner ]): HullMovingAverage {
  
  return {
    _type: "HullMovingAverage",
    
    toString: () => (["HullMovingAverage(", value[0] ? value[0].toString() : null, value[1] ? `,` + value[1].toString() : null, ")"].join(""))
  }
}

/**
 * <h1>IchimokuCloudInner</h1>
 * <p>Takes one of a tuple comprising: a Window, and a Window, and a Window, and a Window, and (optional), an IcValue, or an IcValue</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>IchimokuCloudInner = _{WINDOW ~ WINDOW ~ WINDOW ~ WINDOW ~ (IC_VALUE)? | IC_VALUE }</code>
 * </p>
*/
type IchimokuCloudInner = {
  _type: "IchimokuCloudInner";
  
  toString: () => string;
};

/**
 * <p>Create IchimokuCloudInner serializer object.</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>IchimokuCloudInner = _{WINDOW ~ WINDOW ~ WINDOW ~ WINDOW ~ (IC_VALUE)? | IC_VALUE }</code>
 * </p>
 * 
 * @param value - one of a tuple comprising: a Window, and a Window, and a Window, and a Window, and (optional), an IcValue, or an IcValue
 * @returns The created serializer object.
 */
function ichimokuCloudInner(value: [ Window, Window, Window, Window, (IcValue)[] ] | IcValue): IchimokuCloudInner {
  
  return {
    _type: "IchimokuCloudInner",
    
    toString: () => (value.toString())
  }
}

/**
 * <h1>IchimokuCloud</h1>
 * <p>Takes a tuple comprising: an OptInstrumentFrequency, and an IchimokuCloudInner</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>IchimokuCloud = {"IchimokuCloud(" ~ OPT_INSTRUMENT_FREQUENCY? ~ IchimokuCloudInner? ~ ")" }</code>
 * </p>
*/
type IchimokuCloud = {
  _type: "IchimokuCloud";
  
  toString: () => string;
};

/**
 * <p>Create IchimokuCloud serializer object.</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>IchimokuCloud = {"IchimokuCloud(" ~ OPT_INSTRUMENT_FREQUENCY? ~ IchimokuCloudInner? ~ ")" }</code>
 * </p>
 * 
 * @param value - a tuple comprising: an OptInstrumentFrequency, and an IchimokuCloudInner
 * @returns The created serializer object.
 */
function ichimokuCloud(value: [ OptInstrumentFrequency, IchimokuCloudInner ]): IchimokuCloud {
  
  return {
    _type: "IchimokuCloud",
    
    toString: () => (["IchimokuCloud(", value[0] ? value[0].toString() : null, value[1] ? `,` + value[1].toString() : null, ")"].join(""))
  }
}

/**
 * <h1>KeltnerChannel</h1>
 * <p>Takes an OptInstrumentFrequency</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>KeltnerChannel = {"KeltnerChannel(" ~ OPT_INSTRUMENT_FREQUENCY? ~ ")" }</code>
 * </p>
*/
type KeltnerChannel = {
  _type: "KeltnerChannel";
  
  toString: () => string;
};

/**
 * <p>Create KeltnerChannel serializer object.</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>KeltnerChannel = {"KeltnerChannel(" ~ OPT_INSTRUMENT_FREQUENCY? ~ ")" }</code>
 * </p>
 * 
 * @param value - an OptInstrumentFrequency
 * @returns The created serializer object.
 */
function keltnerChannel(value: OptInstrumentFrequency): KeltnerChannel {
  
  return {
    _type: "KeltnerChannel",
    
    toString: () => (["KeltnerChannel(", value.toString(), ")"].join(""))
  }
}

/**
 * <h1>KlingerVolumeOscillatorInner</h1>
 * <p>Takes one of a tuple comprising: a Window, and a Window, and a Window, and (optional), a KvoValue, or a KvoValue</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>KlingerVolumeOscillatorInner = {WINDOW ~ WINDOW ~ WINDOW ~ (KVO_VALUE)? | KVO_VALUE }</code>
 * </p>
*/
type KlingerVolumeOscillatorInner = {
  _type: "KlingerVolumeOscillatorInner";
  
  toString: () => string;
};

/**
 * <p>Create KlingerVolumeOscillatorInner serializer object.</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>KlingerVolumeOscillatorInner = {WINDOW ~ WINDOW ~ WINDOW ~ (KVO_VALUE)? | KVO_VALUE }</code>
 * </p>
 * 
 * @param value - one of a tuple comprising: a Window, and a Window, and a Window, and (optional), a KvoValue, or a KvoValue
 * @returns The created serializer object.
 */
function klingerVolumeOscillatorInner(value: [ Window, Window, Window, (KvoValue)[] ] | KvoValue): KlingerVolumeOscillatorInner {
  
  return {
    _type: "KlingerVolumeOscillatorInner",
    
    toString: () => (value.toString())
  }
}

/**
 * <h1>KlingerVolumeOscillator</h1>
 * <p>Takes a tuple comprising: an OptInstrumentFrequency, and a KlingerVolumeOscillatorInner</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>KlingerVolumeOscillator = {"KlingerVolumeOscillator(" ~ OPT_INSTRUMENT_FREQUENCY? ~ KlingerVolumeOscillatorInner? ~ ")" }</code>
 * </p>
*/
type KlingerVolumeOscillator = {
  _type: "KlingerVolumeOscillator";
  
  toString: () => string;
};

/**
 * <p>Create KlingerVolumeOscillator serializer object.</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>KlingerVolumeOscillator = {"KlingerVolumeOscillator(" ~ OPT_INSTRUMENT_FREQUENCY? ~ KlingerVolumeOscillatorInner? ~ ")" }</code>
 * </p>
 * 
 * @param value - a tuple comprising: an OptInstrumentFrequency, and a KlingerVolumeOscillatorInner
 * @returns The created serializer object.
 */
function klingerVolumeOscillator(value: [ OptInstrumentFrequency, KlingerVolumeOscillatorInner ]): KlingerVolumeOscillator {
  
  return {
    _type: "KlingerVolumeOscillator",
    
    toString: () => (["KlingerVolumeOscillator(", value[0] ? value[0].toString() : null, value[1] ? `,` + value[1].toString() : null, ")"].join(""))
  }
}

/**
 * <h1>KnowSureThing</h1>
 * <p>Takes an OptInstrumentFrequency</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>KnowSureThing = {"KnowSureThing(" ~ OPT_INSTRUMENT_FREQUENCY? ~ ")" }</code>
 * </p>
*/
type KnowSureThing = {
  _type: "KnowSureThing";
  
  toString: () => string;
};

/**
 * <p>Create KnowSureThing serializer object.</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>KnowSureThing = {"KnowSureThing(" ~ OPT_INSTRUMENT_FREQUENCY? ~ ")" }</code>
 * </p>
 * 
 * @param value - an OptInstrumentFrequency
 * @returns The created serializer object.
 */
function knowSureThing(value: OptInstrumentFrequency): KnowSureThing {
  
  return {
    _type: "KnowSureThing",
    
    toString: () => (["KnowSureThing(", value.toString(), ")"].join(""))
  }
}

/**
 * <h1>MacdInner</h1>
 * <p>Takes one of a tuple comprising: a Window, and a Window, and a Window, and (optional), a MacdValue, or a MacdValue</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>MACD_INNER = _{WINDOW ~ WINDOW ~ WINDOW ~ (MACD_VALUE)? | MACD_VALUE }</code>
 * </p>
*/
type MacdInner = {
  _type: "MACD_INNER";
  
  toString: () => string;
};

/**
 * <p>Create MacdInner serializer object.</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>MACD_INNER = _{WINDOW ~ WINDOW ~ WINDOW ~ (MACD_VALUE)? | MACD_VALUE }</code>
 * </p>
 * 
 * @param value - one of a tuple comprising: a Window, and a Window, and a Window, and (optional), a MacdValue, or a MacdValue
 * @returns The created serializer object.
 */
function macdInner(value: [ Window, Window, Window, (MacdValue)[] ] | MacdValue): MacdInner {
  
  return {
    _type: "MACD_INNER",
    
    toString: () => (value.toString())
  }
}

/**
 * <h1>Macd</h1>
 * <p>Takes a tuple comprising: an OptInstrumentFrequency, and a MacdInner</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>MACD = {"MACD(" ~ OPT_INSTRUMENT_FREQUENCY? ~ MACD_INNER? ~ ")" }</code>
 * </p>
*/
type Macd = {
  _type: "MACD";
  
  toString: () => string;
};

/**
 * <p>Create Macd serializer object.</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>MACD = {"MACD(" ~ OPT_INSTRUMENT_FREQUENCY? ~ MACD_INNER? ~ ")" }</code>
 * </p>
 * 
 * @param value - a tuple comprising: an OptInstrumentFrequency, and a MacdInner
 * @returns The created serializer object.
 */
function macd(value?: [ OptInstrumentFrequency, MacdInner ] | MacdInner): Macd {
  
  return {
    _type: "MACD",

    toString: () => {
      if (Array.isArray(value) && value.length == 2) {
        return ["MACD(", value[0] ? value[0].toString() : null, value[1] ? `, ` + value[1].toString() : null, ")"].join("");
      }
      
      return ["MACD(", value ? value.toString() : null, ")"].join("");
    }
  }
}

/**
 * <h1>MomentumIndexInner</h1>
 * <p>Takes one of a tuple comprising: a Window, and a Window, and (optional), a MomentumValue, or a MomentumValue</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>MomentumIndexInner = _{WINDOW ~ WINDOW ~ (MOMENTUM_VALUE)? | MOMENTUM_VALUE }</code>
 * </p>
*/
type MomentumIndexInner = {
  _type: "MomentumIndexInner";
  
  toString: () => string;
};

/**
 * <p>Create MomentumIndexInner serializer object.</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>MomentumIndexInner = _{WINDOW ~ WINDOW ~ (MOMENTUM_VALUE)? | MOMENTUM_VALUE }</code>
 * </p>
 * 
 * @param value - one of a tuple comprising: a Window, and a Window, and (optional), a MomentumValue, or a MomentumValue
 * @returns The created serializer object.
 */
function momentumIndexInner(value: [ Window, Window, (MomentumValue)[] ] | MomentumValue): MomentumIndexInner {
  
  return {
    _type: "MomentumIndexInner",
    
    toString: () => (value.toString())
  }
}

/**
 * <h1>MomentumIndex</h1>
 * <p>Takes a tuple comprising: an OptInstrumentFrequency, and a MomentumIndexInner</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>MomentumIndex = {"MomentumIndex(" ~ OPT_INSTRUMENT_FREQUENCY? ~ MomentumIndexInner? ~ ")" }</code>
 * </p>
*/
type MomentumIndex = {
  _type: "MomentumIndex";
  
  toString: () => string;
};

/**
 * <p>Create MomentumIndex serializer object.</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>MomentumIndex = {"MomentumIndex(" ~ OPT_INSTRUMENT_FREQUENCY? ~ MomentumIndexInner? ~ ")" }</code>
 * </p>
 * 
 * @param value - a tuple comprising: an OptInstrumentFrequency, and a MomentumIndexInner
 * @returns The created serializer object.
 */
function momentumIndex(value: [ OptInstrumentFrequency, MomentumIndexInner ]): MomentumIndex {
  
  return {
    _type: "MomentumIndex",
    
    toString: () => (["MomentumIndex(", value[0] ? value[0].toString() : null, value[1] ? `,` + value[1].toString() : null, ")"].join(""))
  }
}

/**
 * <h1>MoneyFlowIndex</h1>
 * <p>Takes an OptInstrumentFrequency</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>MoneyFlowIndex = {"MoneyFlowIndex(" ~ OPT_INSTRUMENT_FREQUENCY? ~ ")" }</code>
 * </p>
*/
type MoneyFlowIndex = {
  _type: "MoneyFlowIndex";
  
  toString: () => string;
};

/**
 * <p>Create MoneyFlowIndex serializer object.</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>MoneyFlowIndex = {"MoneyFlowIndex(" ~ OPT_INSTRUMENT_FREQUENCY? ~ ")" }</code>
 * </p>
 * 
 * @param value - an OptInstrumentFrequency
 * @returns The created serializer object.
 */
function moneyFlowIndex(value: OptInstrumentFrequency): MoneyFlowIndex {
  
  return {
    _type: "MoneyFlowIndex",
    
    toString: () => (["MoneyFlowIndex(", value.toString(), ")"].join(""))
  }
}

/**
 * <h1>ParabolicSAR</h1>
 * <p>Takes a tuple comprising: an OptInstrumentFrequency, and a ParaSarValue</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>ParabolicSAR = {"ParabolicSAR(" ~ OPT_INSTRUMENT_FREQUENCY? ~ PARA_SAR_VALUE? ~ ")" }</code>
 * </p>
*/
type ParabolicSAR = {
  _type: "ParabolicSAR";
  
  toString: () => string;
};

/**
 * <p>Create ParabolicSAR serializer object.</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>ParabolicSAR = {"ParabolicSAR(" ~ OPT_INSTRUMENT_FREQUENCY? ~ PARA_SAR_VALUE? ~ ")" }</code>
 * </p>
 * 
 * @param value - a tuple comprising: an OptInstrumentFrequency, and a ParaSarValue
 * @returns The created serializer object.
 */
function parabolicSAR(value: [ OptInstrumentFrequency, ParaSarValue ]): ParabolicSAR {
  
  return {
    _type: "ParabolicSAR",
    
    toString: () => (["ParabolicSAR(", value[0] ? value[0].toString() : null, value[1] ? `,` + value[1].toString() : null, ")"].join(""))
  }
}

/**
 * <h1>PriceChannelStrategy</h1>
 * <p>Takes an OptInstrumentFrequency</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>PriceChannelStrategy = {"PriceChannelStrategy(" ~ OPT_INSTRUMENT_FREQUENCY? ~ ")" }</code>
 * </p>
*/
type PriceChannelStrategy = {
  _type: "PriceChannelStrategy";
  
  toString: () => string;
};

/**
 * <p>Create PriceChannelStrategy serializer object.</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>PriceChannelStrategy = {"PriceChannelStrategy(" ~ OPT_INSTRUMENT_FREQUENCY? ~ ")" }</code>
 * </p>
 * 
 * @param value - an OptInstrumentFrequency
 * @returns The created serializer object.
 */
function priceChannelStrategy(value: OptInstrumentFrequency): PriceChannelStrategy {
  
  return {
    _type: "PriceChannelStrategy",
    
    toString: () => (["PriceChannelStrategy(", value.toString(), ")"].join(""))
  }
}

/**
 * <h1>RsiInner</h1>
 * <p>Takes a tuple comprising: a number., and a number.</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>RSI_INNER = _{WINDOW ~ ZERO_TO_HALF }</code>
 * </p>
*/
type RsiInner = {
  _type: "RSI_INNER";
  
  toString: () => string;
};

/**
 * <p>Create RsiInner serializer object.</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>RSI_INNER = _{WINDOW ~ ZERO_TO_HALF }</code>
 * </p>
 * 
 * @param value - a tuple comprising: a number., and a number.
 * @returns The created serializer object.
 */
function rsiInner(value: [ number, number ]): RsiInner {
  
  return {
    _type: "RSI_INNER",
    
    toString: () => ([value[0] ? value[0].toString() : null, value[1] ? `,` + value[1].toString() : null].join(""))
  }
}

/**
 * <h1>Rsi</h1>
 * <p>Takes a tuple comprising: an OptInstrumentFrequency, and a RsiInner</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>RSI = {"RSI(" ~ OPT_INSTRUMENT_FREQUENCY? ~ RSI_INNER? ~ ")" }</code>
 * </p>
*/
type Rsi = {
  _type: "RSI";
  
  toString: () => string;
};

/**
 * <p>Create Rsi serializer object.</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>RSI = {"RSI(" ~ OPT_INSTRUMENT_FREQUENCY? ~ RSI_INNER? ~ ")" }</code>
 * </p>
 * 
 * @param value - a tuple comprising: an OptInstrumentFrequency, and a RsiInner
 * @returns The created serializer object.
 */
function rsi(value: [ OptInstrumentFrequency, RsiInner ] | [ RsiInner ]): Rsi {
  
  return {
    _type: "RSI",
    
    toString: () => {
      if (value.length == 2) return ["RSI(", value[0] ? value[0].toString() : null, value[1] ? `,` + value[1].toString() : null, ")"].join("");
      return ["RSI(", value[0] ? value[0].toString() : null, ")"].join("");
    }
  }
}

/**
 * <h1>StochasticOscillatorInner</h1>
 * <p>Takes one of a tuple comprising: a Window, and a Window, and a Window, and a StoOscValue, or a StoOscValue</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>StochasticOscillatorInner = _{WINDOW ~ WINDOW ~ WINDOW ~ STO_OSC_VALUE? | STO_OSC_VALUE }</code>
 * </p>
*/
type StochasticOscillatorInner = {
  _type: "StochasticOscillatorInner";
  
  toString: () => string;
};

/**
 * <p>Create StochasticOscillatorInner serializer object.</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>StochasticOscillatorInner = _{WINDOW ~ WINDOW ~ WINDOW ~ STO_OSC_VALUE? | STO_OSC_VALUE }</code>
 * </p>
 * 
 * @param value - one of a tuple comprising: a Window, and a Window, and a Window, and a StoOscValue, or a StoOscValue
 * @returns The created serializer object.
 */
function stochasticOscillatorInner(value: [ Window, Window, Window, StoOscValue ] | StoOscValue): StochasticOscillatorInner {
  
  return {
    _type: "StochasticOscillatorInner",
    
    toString: () => (value.toString())
  }
}

/**
 * <h1>StochasticOscillator</h1>
 * <p>Takes a tuple comprising: an OptInstrumentFrequency, and a StochasticOscillatorInner</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>StochasticOscillator = {"StochasticOscillator(" ~ OPT_INSTRUMENT_FREQUENCY? ~ StochasticOscillatorInner? ~ ")" }</code>
 * </p>
*/
type StochasticOscillator = {
  _type: "StochasticOscillator";
  
  toString: () => string;
};

/**
 * <p>Create StochasticOscillator serializer object.</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>StochasticOscillator = {"StochasticOscillator(" ~ OPT_INSTRUMENT_FREQUENCY? ~ StochasticOscillatorInner? ~ ")" }</code>
 * </p>
 * 
 * @param value - a tuple comprising: an OptInstrumentFrequency, and a StochasticOscillatorInner
 * @returns The created serializer object.
 */
function stochasticOscillator(value: [ OptInstrumentFrequency, StochasticOscillatorInner ]): StochasticOscillator {
  
  return {
    _type: "StochasticOscillator",
    
    toString: () => (["StochasticOscillator(", value[0] ? value[0].toString() : null, value[1] ? `,` + value[1].toString() : null, ")"].join(""))
  }
}

/**
 * <h1>TrendStrengthIndexInner</h1>
 * <p>Takes a tuple comprising: a number., and a number., and a number.</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>TrendStrengthIndexInner = _{WINDOW ~ ZERO_TO_ONE ~ HALF_WINDOW }</code>
 * </p>
*/
type TrendStrengthIndexInner = {
  _type: "TrendStrengthIndexInner";
  
  toString: () => string;
};

/**
 * <p>Create TrendStrengthIndexInner serializer object.</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>TrendStrengthIndexInner = _{WINDOW ~ ZERO_TO_ONE ~ HALF_WINDOW }</code>
 * </p>
 * 
 * @param value - a tuple comprising: a number., and a number., and a number.
 * @returns The created serializer object.
 */
function trendStrengthIndexInner(value: [ number, number, number ]): TrendStrengthIndexInner {
  
  return {
    _type: "TrendStrengthIndexInner",
    
    toString: () => ([value[0] ? value[0].toString() : null, value[1] ? `,` + value[1].toString() : null, value[2] ? `,` + value[2].toString() : null].join(""))
  }
}

/**
 * <h1>TrendStrengthIndex</h1>
 * <p>Takes a tuple comprising: an OptInstrumentFrequency, and a TrendStrengthIndexInner</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>TrendStrengthIndex = {"TrendStrengthIndex(" ~ OPT_INSTRUMENT_FREQUENCY? ~ TrendStrengthIndexInner? ~ ")" }</code>
 * </p>
*/
type TrendStrengthIndex = {
  _type: "TrendStrengthIndex";
  
  toString: () => string;
};

/**
 * <p>Create TrendStrengthIndex serializer object.</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>TrendStrengthIndex = {"TrendStrengthIndex(" ~ OPT_INSTRUMENT_FREQUENCY? ~ TrendStrengthIndexInner? ~ ")" }</code>
 * </p>
 * 
 * @param value - a tuple comprising: an OptInstrumentFrequency, and a TrendStrengthIndexInner
 * @returns The created serializer object.
 */
function trendStrengthIndex(value: [ OptInstrumentFrequency, TrendStrengthIndexInner ]): TrendStrengthIndex {
  
  return {
    _type: "TrendStrengthIndex",
    
    toString: () => (["TrendStrengthIndex(", value[0] ? value[0].toString() : null, value[1] ? `,` + value[1].toString() : null, ")"].join(""))
  }
}

/**
 * <h1>TrixInner</h1>
 * <p>Takes one of a tuple comprising: a Window, and a TrixValue, or a TrixValue</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>TrixInner = _{WINDOW ~ TRIX_VALUE? | TRIX_VALUE }</code>
 * </p>
*/
type TrixInner = {
  _type: "TrixInner";
  
  toString: () => string;
};

/**
 * <p>Create TrixInner serializer object.</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>TrixInner = _{WINDOW ~ TRIX_VALUE? | TRIX_VALUE }</code>
 * </p>
 * 
 * @param value - one of a tuple comprising: a Window, and a TrixValue, or a TrixValue
 * @returns The created serializer object.
 */
function trixInner(value: [ Window, TrixValue ] | TrixValue): TrixInner {
  
  return {
    _type: "TrixInner",
    
    toString: () => (value.toString())
  }
}

/**
 * <h1>Trix</h1>
 * <p>Takes a tuple comprising: an OptInstrumentFrequency, and a TrixInner</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>Trix = {"Trix(" ~ OPT_INSTRUMENT_FREQUENCY? ~ TrixInner? ~ ")" }</code>
 * </p>
*/
type Trix = {
  _type: "Trix";
  
  toString: () => string;
};

/**
 * <p>Create Trix serializer object.</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>Trix = {"Trix(" ~ OPT_INSTRUMENT_FREQUENCY? ~ TrixInner? ~ ")" }</code>
 * </p>
 * 
 * @param value - a tuple comprising: an OptInstrumentFrequency, and a TrixInner
 * @returns The created serializer object.
 */
function trix(value: [ OptInstrumentFrequency, TrixInner ]): Trix {
  
  return {
    _type: "Trix",
    
    toString: () => (["Trix(", value[0] ? value[0].toString() : null, value[1] ? `,` + value[1].toString() : null, ")"].join(""))
  }
}

/**
 * <h1>TrueStrengthIndexInner</h1>
 * <p>Takes one of a tuple comprising: a Window, and a Window, and a Window, and a ZeroToOne, and (optional), a TsiValue, or a TsiValue</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>TrueStrengthIndexInner = _{WINDOW ~ WINDOW ~ WINDOW ~ ZERO_TO_ONE ~ (TSI_VALUE)? | TSI_VALUE }</code>
 * </p>
*/
type TrueStrengthIndexInner = {
  _type: "TrueStrengthIndexInner";
  
  toString: () => string;
};

/**
 * <p>Create TrueStrengthIndexInner serializer object.</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>TrueStrengthIndexInner = _{WINDOW ~ WINDOW ~ WINDOW ~ ZERO_TO_ONE ~ (TSI_VALUE)? | TSI_VALUE }</code>
 * </p>
 * 
 * @param value - one of a tuple comprising: a Window, and a Window, and a Window, and a ZeroToOne, and (optional), a TsiValue, or a TsiValue
 * @returns The created serializer object.
 */
function trueStrengthIndexInner(value: [ Window, Window, Window, ZeroToOne, (TsiValue)[] ] | TsiValue): TrueStrengthIndexInner {
  
  return {
    _type: "TrueStrengthIndexInner",
    
    toString: () => (value.toString())
  }
}

/**
 * <h1>TrueStrengthIndex</h1>
 * <p>Takes a tuple comprising: an OptInstrumentFrequency, and a TrueStrengthIndexInner</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>TrueStrengthIndex = {"TrueStrengthIndex(" ~ OPT_INSTRUMENT_FREQUENCY? ~ TrueStrengthIndexInner? ~ ")" }</code>
 * </p>
*/
type TrueStrengthIndex = {
  _type: "TrueStrengthIndex";
  
  toString: () => string;
};

/**
 * <p>Create TrueStrengthIndex serializer object.</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>TrueStrengthIndex = {"TrueStrengthIndex(" ~ OPT_INSTRUMENT_FREQUENCY? ~ TrueStrengthIndexInner? ~ ")" }</code>
 * </p>
 * 
 * @param value - a tuple comprising: an OptInstrumentFrequency, and a TrueStrengthIndexInner
 * @returns The created serializer object.
 */
function trueStrengthIndex(value: [ OptInstrumentFrequency, TrueStrengthIndexInner ]): TrueStrengthIndex {
  
  return {
    _type: "TrueStrengthIndex",
    
    toString: () => (["TrueStrengthIndex(", value[0] ? value[0].toString() : null, value[1] ? `,` + value[1].toString() : null, ")"].join(""))
  }
}

/**
 * <h1>WoodiesCCIInner</h1>
 * <p>Takes a tuple comprising: a number., and a number., and a number.</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>WoodiesCCIInner = _{WINDOW ~ WINDOW ~ WINDOW }</code>
 * </p>
*/
type WoodiesCCIInner = {
  _type: "WoodiesCCIInner";
  
  toString: () => string;
};

/**
 * <p>Create WoodiesCCIInner serializer object.</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>WoodiesCCIInner = _{WINDOW ~ WINDOW ~ WINDOW }</code>
 * </p>
 * 
 * @param value - a tuple comprising: a number., and a number., and a number.
 * @returns The created serializer object.
 */
function woodiesCCIInner(value: [ number, number, number ]): WoodiesCCIInner {
  
  return {
    _type: "WoodiesCCIInner",
    
    toString: () => ([value[0] ? value[0].toString() : null, value[1] ? `,` + value[1].toString() : null, value[2] ? `,` + value[2].toString() : null].join(""))
  }
}

/**
 * <h1>WoodiesCCI</h1>
 * <p>Takes a tuple comprising: an OptInstrumentFrequency, and a WoodiesCCIInner</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>WoodiesCCI = {"WoodiesCCI(" ~ OPT_INSTRUMENT_FREQUENCY? ~ WoodiesCCIInner? ~ ")" }</code>
 * </p>
*/
type WoodiesCCI = {
  _type: "WoodiesCCI";
  
  toString: () => string;
};

/**
 * <p>Create WoodiesCCI serializer object.</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>WoodiesCCI = {"WoodiesCCI(" ~ OPT_INSTRUMENT_FREQUENCY? ~ WoodiesCCIInner? ~ ")" }</code>
 * </p>
 * 
 * @param value - a tuple comprising: an OptInstrumentFrequency, and a WoodiesCCIInner
 * @returns The created serializer object.
 */
function woodiesCCI(value: [ OptInstrumentFrequency, WoodiesCCIInner ]): WoodiesCCI {
  
  return {
    _type: "WoodiesCCI",
    
    toString: () => (["WoodiesCCI(", value[0] ? value[0].toString() : null, value[1] ? `,` + value[1].toString() : null, ")"].join(""))
  }
}

/**
 * <h1>Atr</h1>
 * <p>Takes a tuple comprising: an OptInstrumentFrequency, and a number.</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>ATR = {"AverageTrueRange(" ~ OPT_INSTRUMENT_FREQUENCY? ~ WINDOW ~ ")" }</code>
 * </p>
*/
type Atr = {
  _type: "ATR";
  
  toString: () => string;
};

/**
 * <p>Create Atr serializer object.</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>ATR = {"AverageTrueRange(" ~ OPT_INSTRUMENT_FREQUENCY? ~ WINDOW ~ ")" }</code>
 * </p>
 * 
 * @param value - a tuple comprising: an OptInstrumentFrequency, and a number.
 * @returns The created serializer object.
 */
function atr(value: [ OptInstrumentFrequency, number ]): Atr {
  
  return {
    _type: "ATR",
    
    toString: () => (["AverageTrueRange(", value[0] ? value[0].toString() : null, value[1] ? `,` + value[1].toString() : null, ")"].join(""))
  }
}

/**
 * <h1>AllIndicators</h1>
 * <p>Takes one of a BollingerBands, or a Rsi, or a Macd, or an Aroon, or a HullMovingAverage, or an IchimokuCloud, or a StochasticOscillator, or a TrendStrengthIndex, or a Trix, or a TrueStrengthIndex, or an AverageDirectionalIndex, or a MomentumIndex, or a KnowSureThing, or an AwesomeOscillator, or an EaseOfMovement, or an EldersForceIndex, or an Envelopes, or a FisherTransform, or a KeltnerChannel, or a MoneyFlowIndex, or a PriceChannelStrategy, or a KlingerVolumeOscillator, or a CommodityChannelIndex, or a DetrendedPriceOscillator, or a WoodiesCCI, or a ParabolicSAR, or a ChaikinMoneyFlow, or a ChaikinOscillator, or a ChandeMomentumOscillator, or an Atr</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>ALL_INDICATORS = _{BollingerBands | RSI | MACD | Aroon | HullMovingAverage | IchimokuCloud
 * 	| StochasticOscillator | TrendStrengthIndex | Trix | TrueStrengthIndex
 * 	| AverageDirectionalIndex | MomentumIndex | KnowSureThing
 * 	| AwesomeOscillator | EaseOfMovement | EldersForceIndex | Envelopes | FisherTransform
 * 	| KeltnerChannel | MoneyFlowIndex | PriceChannelStrategy | KlingerVolumeOscillator
 * 	| CommodityChannelIndex | DetrendedPriceOscillator | WoodiesCCI
 * 	| ParabolicSAR | ChaikinMoneyFlow | ChaikinOscillator | ChandeMomentumOscillator | ATR
 * }</code>
 * </p>
*/
type AllIndicators = {
  _type: "ALL_INDICATORS";
  
  toString: () => string;
};

/**
 * <p>Create AllIndicators serializer object.</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>ALL_INDICATORS = _{BollingerBands | RSI | MACD | Aroon | HullMovingAverage | IchimokuCloud
 * 	| StochasticOscillator | TrendStrengthIndex | Trix | TrueStrengthIndex
 * 	| AverageDirectionalIndex | MomentumIndex | KnowSureThing
 * 	| AwesomeOscillator | EaseOfMovement | EldersForceIndex | Envelopes | FisherTransform
 * 	| KeltnerChannel | MoneyFlowIndex | PriceChannelStrategy | KlingerVolumeOscillator
 * 	| CommodityChannelIndex | DetrendedPriceOscillator | WoodiesCCI
 * 	| ParabolicSAR | ChaikinMoneyFlow | ChaikinOscillator | ChandeMomentumOscillator | ATR
 * }</code>
 * </p>
 * 
 * @param value - one of a BollingerBands, or a Rsi, or a Macd, or an Aroon, or a HullMovingAverage, or an IchimokuCloud, or a StochasticOscillator, or a TrendStrengthIndex, or a Trix, or a TrueStrengthIndex, or an AverageDirectionalIndex, or a MomentumIndex, or a KnowSureThing, or an AwesomeOscillator, or an EaseOfMovement, or an EldersForceIndex, or an Envelopes, or a FisherTransform, or a KeltnerChannel, or a MoneyFlowIndex, or a PriceChannelStrategy, or a KlingerVolumeOscillator, or a CommodityChannelIndex, or a DetrendedPriceOscillator, or a WoodiesCCI, or a ParabolicSAR, or a ChaikinMoneyFlow, or a ChaikinOscillator, or a ChandeMomentumOscillator, or an Atr
 * @returns The created serializer object.
 */
function allIndicators(value: BollingerBands | Rsi | Macd | Aroon | HullMovingAverage | IchimokuCloud | StochasticOscillator | TrendStrengthIndex | Trix | TrueStrengthIndex | AverageDirectionalIndex | MomentumIndex | KnowSureThing | AwesomeOscillator | EaseOfMovement | EldersForceIndex | Envelopes | FisherTransform | KeltnerChannel | MoneyFlowIndex | PriceChannelStrategy | KlingerVolumeOscillator | CommodityChannelIndex | DetrendedPriceOscillator | WoodiesCCI | ParabolicSAR | ChaikinMoneyFlow | ChaikinOscillator | ChandeMomentumOscillator | Atr): AllIndicators {
  
  return {
    _type: "ALL_INDICATORS",
    
    toString: () => (value.toString())
  }
}

/**
 * <h1>AndOp</h1>
 * <p>Takes </p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>AND_OP = @{"&&" | ^"AND" }</code>
 * </p>
*/
type AndOp = {
  _type: "AND_OP";
  
  toString: () => string;
};

/**
 * <p>Create AndOp serializer object.</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>AND_OP = @{"&&" | ^"AND" }</code>
 * </p>
 * 
 * @returns The created serializer object.
 */
function andOp(): AndOp {
  
  return {
    _type: "AND_OP",
    
    toString: () => ("&&")
  }
}

/**
 * <h1>OrOp</h1>
 * <p>Takes </p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>OR_OP = @{"||" | ^"OR" }</code>
 * </p>
*/
type OrOp = {
  _type: "OR_OP";
  
  toString: () => string;
};

/**
 * <p>Create OrOp serializer object.</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>OR_OP = @{"||" | ^"OR" }</code>
 * </p>
 * 
 * @returns The created serializer object.
 */
function orOp(): OrOp {
  
  return {
    _type: "OR_OP",
    
    toString: () => ("||")
  }
}

/**
 * <h1>NotOp</h1>
 * <p>Takes </p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>NOT_OP = @{"!" | ^"NOT" }</code>
 * </p>
*/
type NotOp = {
  _type: "NOT_OP";
  
  toString: () => string;
};

/**
 * <p>Create NotOp serializer object.</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>NOT_OP = @{"!" | ^"NOT" }</code>
 * </p>
 * 
 * @returns The created serializer object.
 */
function notOp(): NotOp {
  
  return {
    _type: "NOT_OP",
    
    toString: () => ("!")
  }
}

/**
 * <h1>LogicalOp</h1>
 * <p>Takes one of a tuple comprising: , or an AndOp, or an OrOp, or a NotOp</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>LOGICAL_OP = _{(AND_OP ~ NOT_OP | AND_OP | OR_OP | NOT_OP) }</code>
 * </p>
*/
type LogicalOp = {
  _type: "LOGICAL_OP";
  
  toString: () => string;
};

/**
 * <p>Create LogicalOp serializer object.</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>LOGICAL_OP = _{(AND_OP ~ NOT_OP | AND_OP | OR_OP | NOT_OP) }</code>
 * </p>
 * 
 * @param value - one of a tuple comprising: , or an AndOp, or an OrOp, or a NotOp
 * @returns The created serializer object.
 */
function logicalOp(value: [ AndOp, NotOp ] | AndOp | OrOp | NotOp): LogicalOp {
  
  return {
    _type: "LOGICAL_OP",
    
    toString: () => (value.toString())
  }
}

/**
 * <h1>Gt</h1>
 * <p>Takes </p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>GT = @{">" }</code>
 * </p>
*/
type Gt = {
  _type: "GT";
  
  toString: () => string;
};

/**
 * <p>Create Gt serializer object.</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>GT = @{">" }</code>
 * </p>
 * 
 * @returns The created serializer object.
 */
function gt(): Gt {
  
  return {
    _type: "GT",
    
    toString: () => (">")
  }
}

/**
 * <h1>Gte</h1>
 * <p>Takes </p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>GTE = @{">=" }</code>
 * </p>
*/
type Gte = {
  _type: "GTE";
  
  toString: () => string;
};

/**
 * <p>Create Gte serializer object.</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>GTE = @{">=" }</code>
 * </p>
 * 
 * @returns The created serializer object.
 */
function gte(): Gte {
  
  return {
    _type: "GTE",
    
    toString: () => (">=")
  }
}

/**
 * <h1>Lt</h1>
 * <p>Takes </p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>LT = @{"<" }</code>
 * </p>
*/
type Lt = {
  _type: "LT";
  
  toString: () => string;
};

/**
 * <p>Create Lt serializer object.</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>LT = @{"<" }</code>
 * </p>
 * 
 * @returns The created serializer object.
 */
function lt(): Lt {
  
  return {
    _type: "LT",
    
    toString: () => ("<")
  }
}

/**
 * <h1>Lte</h1>
 * <p>Takes </p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>LTE = @{"<=" }</code>
 * </p>
*/
type Lte = {
  _type: "LTE";
  
  toString: () => string;
};

/**
 * <p>Create Lte serializer object.</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>LTE = @{"<=" }</code>
 * </p>
 * 
 * @returns The created serializer object.
 */
function lte(): Lte {
  
  return {
    _type: "LTE",
    
    toString: () => ("<=")
  }
}

/**
 * <h1>Eq</h1>
 * <p>Takes </p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>EQ = @{"==" }</code>
 * </p>
*/
type Eq = {
  _type: "EQ";
  
  toString: () => string;
};

/**
 * <p>Create Eq serializer object.</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>EQ = @{"==" }</code>
 * </p>
 * 
 * @returns The created serializer object.
 */
function eq(): Eq {
  
  return {
    _type: "EQ",
    
    toString: () => ("==")
  }
}

/**
 * <h1>Cmp</h1>
 * <p>Takes one of a Gte, or a Gt, or a Lte, or a Lt, or an Eq</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>CMP = _{GTE | GT | LTE | LT | EQ }</code>
 * </p>
*/
type Cmp = {
  _type: "CMP";
  
  toString: () => string;
};

/**
 * <p>Create Cmp serializer object.</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>CMP = _{GTE | GT | LTE | LT | EQ }</code>
 * </p>
 * 
 * @param value - one of a Gte, or a Gt, or a Lte, or a Lt, or an Eq
 * @returns The created serializer object.
 */
function cmp(value: Gte | Gt | Lte | Lt | Eq): Cmp {
  
  return {
    _type: "CMP",
    
    toString: () => (value.toString())
  }
}

/**
 * <h1>LatchSize</h1>
 * <p>Takes a number.</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>LATCH_SIZE = _{"Latchsize(" ~ WINDOW ~ ")" }</code>
 * </p>
*/
type LatchSize = {
  _type: "LATCH_SIZE";
  
  toString: () => string;
};

/**
 * <p>Create LatchSize serializer object.</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>LATCH_SIZE = _{"Latchsize(" ~ WINDOW ~ ")" }</code>
 * </p>
 * 
 * @param value - a number.
 * @returns The created serializer object.
 */
function latchSize(value: number): LatchSize {
  
  return {
    _type: "LATCH_SIZE",
    
    toString: () => (["Latchsize(", value.toString(), ")"].join(""))
  }
}

/**
 * <h1>DoubleBottom</h1>
 * <p>Takes a tuple comprising: an OptInstrumentFrequency, and a LatchSize</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>DOUBLE_BOTTOM = {(^"DB(" | "DoubleBottom(") ~ OPT_INSTRUMENT_FREQUENCY? ~ LATCH_SIZE? ~ ")" }</code>
 * </p>
*/
type DoubleBottom = {
  _type: "DOUBLE_BOTTOM";
  
  toString: () => string;
};

/**
 * <p>Create DoubleBottom serializer object.</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>DOUBLE_BOTTOM = {(^"DB(" | "DoubleBottom(") ~ OPT_INSTRUMENT_FREQUENCY? ~ LATCH_SIZE? ~ ")" }</code>
 * </p>
 * 
 * @param value - a tuple comprising: an OptInstrumentFrequency, and a LatchSize
 * @returns The created serializer object.
 */
function doubleBottom(value?: [ OptInstrumentFrequency, LatchSize ]): DoubleBottom {
  
  return {
    _type: "DOUBLE_BOTTOM",
    
    toString: () => {
      if (value) { return ["DB(", value[0] ? value[0].toString() : null, value[1] ? `,` + value[1].toString() : null, ")"].join("") }
      return "DoubleBottom()";
    }
  }
}

/**
 * <h1>DoubleTop</h1>
 * <p>Takes a tuple comprising: an OptInstrumentFrequency, and a LatchSize</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>DOUBLE_TOP = {(^"DT(" | "DoubleTop(") ~ OPT_INSTRUMENT_FREQUENCY? ~ LATCH_SIZE? ~ ")" }</code>
 * </p>
*/
type DoubleTop = {
  _type: "DOUBLE_TOP";
  
  toString: () => string;
};

/**
 * <p>Create DoubleTop serializer object.</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>DOUBLE_TOP = {(^"DT(" | "DoubleTop(") ~ OPT_INSTRUMENT_FREQUENCY? ~ LATCH_SIZE? ~ ")" }</code>
 * </p>
 * 
 * @param value - a tuple comprising: an OptInstrumentFrequency, and a LatchSize
 * @returns The created serializer object.
 */
function doubleTop(value?: [ OptInstrumentFrequency, LatchSize ]): DoubleTop {
  
  return {
    _type: "DOUBLE_TOP",
    toString: () => {
      if (value) { return ["DT(", value[0] ? value[0].toString() : null, value[1] ? `,` + value[1].toString() : null, ")"].join("") }
      return "DoubleTop()";
    }
  }
}

/**
 * <h1>RoundedBottom</h1>
 * <p>Takes a tuple comprising: an OptInstrumentFrequency, and a LatchSize</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>ROUNDED_BOTTOM = {(^"RB(" | "RoundedBottom(") ~ OPT_INSTRUMENT_FREQUENCY? ~ LATCH_SIZE? ~ ")" }</code>
 * </p>
*/
type RoundedBottom = {
  _type: "ROUNDED_BOTTOM";
  
  toString: () => string;
};

/**
 * <p>Create RoundedBottom serializer object.</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>ROUNDED_BOTTOM = {(^"RB(" | "RoundedBottom(") ~ OPT_INSTRUMENT_FREQUENCY? ~ LATCH_SIZE? ~ ")" }</code>
 * </p>
 * 
 * @param value - a tuple comprising: an OptInstrumentFrequency, and a LatchSize
 * @returns The created serializer object.
 */
function roundedBottom(value?: [ OptInstrumentFrequency, LatchSize ]): RoundedBottom {
  
  return {
    _type: "ROUNDED_BOTTOM",
    
    toString: () => {
      if (value) { return ["RB(", value[0] ? value[0].toString() : null, value[1] ? `,` + value[1].toString() : null, ")"].join("") }
      return "RoundedBottom()";
    }
  }
}

/**
 * <h1>RoundedTop</h1>
 * <p>Takes a tuple comprising: an OptInstrumentFrequency, and a LatchSize</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>ROUNDED_TOP = {(^"RT(" | "RoundedTop(") ~ OPT_INSTRUMENT_FREQUENCY? ~ LATCH_SIZE? ~ ")" }</code>
 * </p>
*/
type RoundedTop = {
  _type: "ROUNDED_TOP";
  
  toString: () => string;
};

/**
 * <p>Create RoundedTop serializer object.</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>ROUNDED_TOP = {(^"RT(" | "RoundedTop(") ~ OPT_INSTRUMENT_FREQUENCY? ~ LATCH_SIZE? ~ ")" }</code>
 * </p>
 * 
 * @param value - a tuple comprising: an OptInstrumentFrequency, and a LatchSize
 * @returns The created serializer object.
 */
function roundedTop(value?: [ OptInstrumentFrequency, LatchSize ]): RoundedTop {
  
  return {
    _type: "ROUNDED_TOP",
    
    toString: () => {
      if (value) { return ["RT(", value[0] ? value[0].toString() : null, value[1] ? `,` + value[1].toString() : null, ")"].join("") }
      return "RoundedTop()";
    }
  }
}

/**
 * <h1>CupHandle</h1>
 * <p>Takes a tuple comprising: an OptInstrumentFrequency, and a LatchSize</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>CUP_HANDLE = {(^"CH(" | "CupAndHandle(") ~ OPT_INSTRUMENT_FREQUENCY? ~ LATCH_SIZE? ~ ")" }</code>
 * </p>
*/
type CupHandle = {
  _type: "CUP_HANDLE";
  
  toString: () => string;
};

/**
 * <p>Create CupHandle serializer object.</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>CUP_HANDLE = {(^"CH(" | "CupAndHandle(") ~ OPT_INSTRUMENT_FREQUENCY? ~ LATCH_SIZE? ~ ")" }</code>
 * </p>
 * 
 * @param value - a tuple comprising: an OptInstrumentFrequency, and a LatchSize
 * @returns The created serializer object.
 */
function cupHandle(value?: [ OptInstrumentFrequency, LatchSize ]): CupHandle {
  
  return {
    _type: "CUP_HANDLE",
    
    toString: () => {
      if (value) { return ["CH(", value[0] ? value[0].toString() : null, value[1] ? `,` + value[1].toString() : null, ")"].join("") }
      return "CupAndHandle()";
    }
  }
}

/**
 * <h1>InvertedCupHandle</h1>
 * <p>Takes a tuple comprising: an OptInstrumentFrequency, and a LatchSize</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>INVERTED_CUP_HANDLE = {(^"ICH(" | "InvertedCupAndHandle(") ~ OPT_INSTRUMENT_FREQUENCY? ~ LATCH_SIZE? ~ ")" }</code>
 * </p>
*/
type InvertedCupHandle = {
  _type: "INVERTED_CUP_HANDLE";
  
  toString: () => string;
};

/**
 * <p>Create InvertedCupHandle serializer object.</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>INVERTED_CUP_HANDLE = {(^"ICH(" | "InvertedCupAndHandle(") ~ OPT_INSTRUMENT_FREQUENCY? ~ LATCH_SIZE? ~ ")" }</code>
 * </p>
 * 
 * @param value - a tuple comprising: an OptInstrumentFrequency, and a LatchSize
 * @returns The created serializer object.
 */
function invertedCupHandle(value?: [ OptInstrumentFrequency, LatchSize ]): InvertedCupHandle {
  
  return {
    _type: "INVERTED_CUP_HANDLE",
    
    toString: () => {
      if (value) { return ["ICH(", value[0] ? value[0].toString() : null, value[1] ? `,` + value[1].toString() : null, ")"].join("") }
      return "InvertedCupAndHandle()";
    }
  }
}

/**
 * <h1>TripleTop</h1>
 * <p>Takes a tuple comprising: an OptInstrumentFrequency, and a LatchSize</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>TRIPLE_TOP = {(^"TT(" | "TripleTop(") ~ OPT_INSTRUMENT_FREQUENCY? ~ LATCH_SIZE? ~ ")" }</code>
 * </p>
*/
type TripleTop = {
  _type: "TRIPLE_TOP";
  
  toString: () => string;
};

/**
 * <p>Create TripleTop serializer object.</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>TRIPLE_TOP = {(^"TT(" | "TripleTop(") ~ OPT_INSTRUMENT_FREQUENCY? ~ LATCH_SIZE? ~ ")" }</code>
 * </p>
 * 
 * @param value - a tuple comprising: an OptInstrumentFrequency, and a LatchSize
 * @returns The created serializer object.
 */
function tripleTop(value?: [ OptInstrumentFrequency, LatchSize ]): TripleTop {
  
  return {
    _type: "TRIPLE_TOP",
    
    toString: () => {
      if (value) { return ["TT(", value[0] ? value[0].toString() : null, value[1] ? `,` + value[1].toString() : null, ")"].join("") }
      return "TripleTop()";
    }
  }
}

/**
 * <h1>TripleBottom</h1>
 * <p>Takes a tuple comprising: an OptInstrumentFrequency, and a LatchSize</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>TRIPLE_BOTTOM = {(^"TB(" | "TripleBottom(") ~ OPT_INSTRUMENT_FREQUENCY? ~ LATCH_SIZE? ~ ")" }</code>
 * </p>
*/
type TripleBottom = {
  _type: "TRIPLE_BOTTOM";
  
  toString: () => string;
};

/**
 * <p>Create TripleBottom serializer object.</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>TRIPLE_BOTTOM = {(^"TB(" | "TripleBottom(") ~ OPT_INSTRUMENT_FREQUENCY? ~ LATCH_SIZE? ~ ")" }</code>
 * </p>
 * 
 * @param value - a tuple comprising: an OptInstrumentFrequency, and a LatchSize
 * @returns The created serializer object.
 */
function tripleBottom(value?: [ OptInstrumentFrequency, LatchSize ]): TripleBottom {
  
  return {
    _type: "TRIPLE_BOTTOM",
    
    toString: () => {
      if (value) { return ["TB(", value[0] ? value[0].toString() : null, value[1] ? `,` + value[1].toString() : null, ")"].join("") }
      return "TripleBottom()";
    }
  }
}

/**
 * <h1>HeadAndShoulders</h1>
 * <p>Takes a tuple comprising: an OptInstrumentFrequency, and a LatchSize</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>HEAD_AND_SHOULDERS = {(^"HS(" | "HeadAndShoulders(") ~ OPT_INSTRUMENT_FREQUENCY? ~ LATCH_SIZE? ~ ")" }</code>
 * </p>
*/
type HeadAndShoulders = {
  _type: "HEAD_AND_SHOULDERS";
  
  toString: () => string;
};

/**
 * <p>Create HeadAndShoulders serializer object.</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>HEAD_AND_SHOULDERS = {(^"HS(" | "HeadAndShoulders(") ~ OPT_INSTRUMENT_FREQUENCY? ~ LATCH_SIZE? ~ ")" }</code>
 * </p>
 * 
 * @param value - a tuple comprising: an OptInstrumentFrequency, and a LatchSize
 * @returns The created serializer object.
 */
function headAndShoulders(value?: [ OptInstrumentFrequency, LatchSize ]): HeadAndShoulders {
  
  return {
    _type: "HEAD_AND_SHOULDERS",
    
    toString: () => {
      if (value) { return ["HS(", value[0] ? value[0].toString() : null, value[1] ? `,` + value[1].toString() : null, ")"].join("") }
      return "HeadAndShoulders()";
    }
  }
}

/**
 * <h1>InvertedHeadAndShoulders</h1>
 * <p>Takes a tuple comprising: an OptInstrumentFrequency, and a LatchSize</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>INVERTED_HEAD_AND_SHOULDERS = {(^"IHS(" | "InvertedHeadAndShoulders(") ~ OPT_INSTRUMENT_FREQUENCY? ~ LATCH_SIZE? ~ ")" }</code>
 * </p>
*/
type InvertedHeadAndShoulders = {
  _type: "INVERTED_HEAD_AND_SHOULDERS";
  
  toString: () => string;
};

/**
 * <p>Create InvertedHeadAndShoulders serializer object.</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>INVERTED_HEAD_AND_SHOULDERS = {(^"IHS(" | "InvertedHeadAndShoulders(") ~ OPT_INSTRUMENT_FREQUENCY? ~ LATCH_SIZE? ~ ")" }</code>
 * </p>
 * 
 * @param value - a tuple comprising: an OptInstrumentFrequency, and a LatchSize
 * @returns The created serializer object.
 */
function invertedHeadAndShoulders(value?: [ OptInstrumentFrequency, LatchSize ]): InvertedHeadAndShoulders {
  
  return {
    _type: "INVERTED_HEAD_AND_SHOULDERS",
    
    toString: () => {
      if (value) { return ["IHS(", value[0] ? value[0].toString() : null, value[1] ? `,` + value[1].toString() : null, ")"].join("") }
      return "InvertedHeadAndShoulders()";
    }
  }
}

/**
 * <h1>StoredCustomPatternDb</h1>
 * <p>Takes a tuple comprising: a string., and a LatchSize</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>STORED_CUSTOM_PATTERN_DB = {"StoredCustomPattern(" ~ DB_ID ~ LATCH_SIZE? ~ ")" }</code>
 * </p>
*/
type StoredCustomPatternDb = {
  _type: "STORED_CUSTOM_PATTERN_DB";
  
  toString: () => string;
};

/**
 * <p>Create StoredCustomPatternDb serializer object.</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>STORED_CUSTOM_PATTERN_DB = {"StoredCustomPattern(" ~ DB_ID ~ LATCH_SIZE? ~ ")" }</code>
 * </p>
 * 
 * @param value - a tuple comprising: a string., and a LatchSize
 * @returns The created serializer object.
 */
function storedCustomPatternDb(value: [ string, LatchSize ]): StoredCustomPatternDb {
  
  return {
    _type: "STORED_CUSTOM_PATTERN_DB",
    
    toString: () => (["StoredCustomPattern(", value[0] ? value[0].toString() : null, value[1] ? `,` + value[1].toString() : null, ")"].join(""))
  }
}

/**
 * <h1>CustomPatternArray</h1>
 * <p>Takes a tuple comprising: an OptInstrumentFrequency, and one of a Brackets, or an ArrayValue, and a LatchSize</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>CUSTOM_PATTERN_ARRAY = {"CustomPattern(" ~ OPT_INSTRUMENT_FREQUENCY? ~ PATTERN_ARRAY ~ LATCH_SIZE? ~ ")" }</code>
 * </p>
*/
type CustomPatternArray = {
  _type: "CUSTOM_PATTERN_ARRAY";
  
  toString: () => string;
};

/**
 * <p>Create CustomPatternArray serializer object.</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>CUSTOM_PATTERN_ARRAY = {"CustomPattern(" ~ OPT_INSTRUMENT_FREQUENCY? ~ PATTERN_ARRAY ~ LATCH_SIZE? ~ ")" }</code>
 * </p>
 * 
 * @param value - a tuple comprising: an OptInstrumentFrequency, and one of a Brackets, or an ArrayValue, and a LatchSize
 * @returns The created serializer object.
 */
function customPatternArray(value: [ OptInstrumentFrequency, Brackets | ArrayValue, LatchSize ]): CustomPatternArray {
  
  return {
    _type: "CUSTOM_PATTERN_ARRAY",
    
    toString: () => (["CustomPattern(", value[0] ? value[0].toString() : null, value[1].toString(), value[2] ? `,` + value[2].toString() : null, ")"].join(""))
  }
}

/**
 * <h1>DoubleBottomAscending</h1>
 * <p>Takes a tuple comprising: an OptInstrumentFrequency, and a LatchSize</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>DOUBLE_BOTTOM_ASCENDING = {(^"DBAsc(" | "DoubleBottomAsc(") ~ OPT_INSTRUMENT_FREQUENCY? ~ LATCH_SIZE? ~ ")" }</code>
 * </p>
*/
type DoubleBottomAscending = {
  _type: "DOUBLE_BOTTOM_ASCENDING";
  
  toString: () => string;
};

/**
 * <p>Create DoubleBottomAscending serializer object.</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>DOUBLE_BOTTOM_ASCENDING = {(^"DBAsc(" | "DoubleBottomAsc(") ~ OPT_INSTRUMENT_FREQUENCY? ~ LATCH_SIZE? ~ ")" }</code>
 * </p>
 * 
 * @param value - a tuple comprising: an OptInstrumentFrequency, and a LatchSize
 * @returns The created serializer object.
 */
function doubleBottomAscending(value: [ OptInstrumentFrequency, LatchSize ]): DoubleBottomAscending {
  
  return {
    _type: "DOUBLE_BOTTOM_ASCENDING",
    
    toString: () => (["DBAsc(", value[0] ? value[0].toString() : null, value[1] ? `,` + value[1].toString() : null, ")"].join(""))
  }
}

/**
 * <h1>DoubleBottomDescending</h1>
 * <p>Takes a tuple comprising: an OptInstrumentFrequency, and a LatchSize</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>DOUBLE_BOTTOM_DESCENDING = {(^"DBDesc(" | "DoubleBottomDesc(") ~ OPT_INSTRUMENT_FREQUENCY? ~ LATCH_SIZE? ~ ")" }</code>
 * </p>
*/
type DoubleBottomDescending = {
  _type: "DOUBLE_BOTTOM_DESCENDING";
  
  toString: () => string;
};

/**
 * <p>Create DoubleBottomDescending serializer object.</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>DOUBLE_BOTTOM_DESCENDING = {(^"DBDesc(" | "DoubleBottomDesc(") ~ OPT_INSTRUMENT_FREQUENCY? ~ LATCH_SIZE? ~ ")" }</code>
 * </p>
 * 
 * @param value - a tuple comprising: an OptInstrumentFrequency, and a LatchSize
 * @returns The created serializer object.
 */
function doubleBottomDescending(value: [ OptInstrumentFrequency, LatchSize ]): DoubleBottomDescending {
  
  return {
    _type: "DOUBLE_BOTTOM_DESCENDING",
    
    toString: () => (["DBDesc(", value[0] ? value[0].toString() : null, value[1] ? `,` + value[1].toString() : null, ")"].join(""))
  }
}

/**
 * <h1>DoubleTopAscending</h1>
 * <p>Takes a tuple comprising: an OptInstrumentFrequency, and a LatchSize</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>DOUBLE_TOP_ASCENDING = {(^"DTAsc(" | "DoubleTopAsc(") ~ OPT_INSTRUMENT_FREQUENCY? ~ LATCH_SIZE? ~ ")" }</code>
 * </p>
*/
type DoubleTopAscending = {
  _type: "DOUBLE_TOP_ASCENDING";
  
  toString: () => string;
};

/**
 * <p>Create DoubleTopAscending serializer object.</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>DOUBLE_TOP_ASCENDING = {(^"DTAsc(" | "DoubleTopAsc(") ~ OPT_INSTRUMENT_FREQUENCY? ~ LATCH_SIZE? ~ ")" }</code>
 * </p>
 * 
 * @param value - a tuple comprising: an OptInstrumentFrequency, and a LatchSize
 * @returns The created serializer object.
 */
function doubleTopAscending(value: [ OptInstrumentFrequency, LatchSize ]): DoubleTopAscending {
  
  return {
    _type: "DOUBLE_TOP_ASCENDING",
    
    toString: () => (["DTAsc(", value[0] ? value[0].toString() : null, value[1] ? `,` + value[1].toString() : null, ")"].join(""))
  }
}

/**
 * <h1>DoubleTopDescending</h1>
 * <p>Takes a tuple comprising: an OptInstrumentFrequency, and a LatchSize</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>DOUBLE_TOP_DESCENDING = {(^"DTDesc(" | "DoubleTopDesc(") ~ OPT_INSTRUMENT_FREQUENCY? ~ LATCH_SIZE? ~ ")" }</code>
 * </p>
*/
type DoubleTopDescending = {
  _type: "DOUBLE_TOP_DESCENDING";
  
  toString: () => string;
};

/**
 * <p>Create DoubleTopDescending serializer object.</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>DOUBLE_TOP_DESCENDING = {(^"DTDesc(" | "DoubleTopDesc(") ~ OPT_INSTRUMENT_FREQUENCY? ~ LATCH_SIZE? ~ ")" }</code>
 * </p>
 * 
 * @param value - a tuple comprising: an OptInstrumentFrequency, and a LatchSize
 * @returns The created serializer object.
 */
function doubleTopDescending(value: [ OptInstrumentFrequency, LatchSize ]): DoubleTopDescending {
  
  return {
    _type: "DOUBLE_TOP_DESCENDING",
    
    toString: () => (["DTDesc(", value[0] ? value[0].toString() : null, value[1] ? `,` + value[1].toString() : null, ")"].join(""))
  }
}

/**
 * <h1>AllPatterns</h1>
 * <p>Takes one of a DoubleBottom, or a DoubleTop, or a RoundedBottom, or a RoundedTop, or a CupHandle, or an InvertedCupHandle, or a TripleTop, or a TripleBottom, or an InvertedHeadAndShoulders, or a HeadAndShoulders, or a StoredCustomPatternDb, or a CustomPatternArray, or a DoubleBottomAscending, or a DoubleBottomDescending, or a DoubleTopAscending, or a DoubleTopDescending</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>ALL_PATTERNS = _{DOUBLE_BOTTOM | DOUBLE_TOP | ROUNDED_BOTTOM | ROUNDED_TOP |
 * 	CUP_HANDLE | INVERTED_CUP_HANDLE | TRIPLE_TOP | TRIPLE_BOTTOM |
 * 	INVERTED_HEAD_AND_SHOULDERS | HEAD_AND_SHOULDERS |
 * 	STORED_CUSTOM_PATTERN_DB | CUSTOM_PATTERN_ARRAY |
 * 	DOUBLE_BOTTOM_ASCENDING | DOUBLE_BOTTOM_DESCENDING |
 * 	DOUBLE_TOP_ASCENDING | DOUBLE_TOP_DESCENDING
 * }</code>
 * </p>
*/
type AllPatterns = {
  _type: "ALL_PATTERNS";
  
  toString: () => string;
};

/**
 * <p>Create AllPatterns serializer object.</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>ALL_PATTERNS = _{DOUBLE_BOTTOM | DOUBLE_TOP | ROUNDED_BOTTOM | ROUNDED_TOP |
 * 	CUP_HANDLE | INVERTED_CUP_HANDLE | TRIPLE_TOP | TRIPLE_BOTTOM |
 * 	INVERTED_HEAD_AND_SHOULDERS | HEAD_AND_SHOULDERS |
 * 	STORED_CUSTOM_PATTERN_DB | CUSTOM_PATTERN_ARRAY |
 * 	DOUBLE_BOTTOM_ASCENDING | DOUBLE_BOTTOM_DESCENDING |
 * 	DOUBLE_TOP_ASCENDING | DOUBLE_TOP_DESCENDING
 * }</code>
 * </p>
 * 
 * @param value - one of a DoubleBottom, or a DoubleTop, or a RoundedBottom, or a RoundedTop, or a CupHandle, or an InvertedCupHandle, or a TripleTop, or a TripleBottom, or an InvertedHeadAndShoulders, or a HeadAndShoulders, or a StoredCustomPatternDb, or a CustomPatternArray, or a DoubleBottomAscending, or a DoubleBottomDescending, or a DoubleTopAscending, or a DoubleTopDescending
 * @returns The created serializer object.
 */
function allPatterns(value: DoubleBottom | DoubleTop | RoundedBottom | RoundedTop | CupHandle | InvertedCupHandle | TripleTop | TripleBottom | InvertedHeadAndShoulders | HeadAndShoulders | StoredCustomPatternDb | CustomPatternArray | DoubleBottomAscending | DoubleBottomDescending | DoubleTopAscending | DoubleTopDescending): AllPatterns {
  
  return {
    _type: "ALL_PATTERNS",
    
    toString: () => (value.toString())
  }
}

/**
 * <h1>OpenBracket</h1>
 * <p>Takes </p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>OPEN_BRACKET = @{"(" }</code>
 * </p>
*/
type OpenBracket = {
  _type: "OPEN_BRACKET";
  
  toString: () => string;
};

/**
 * <p>Create OpenBracket serializer object.</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>OPEN_BRACKET = @{"(" }</code>
 * </p>
 * 
 * @returns The created serializer object.
 */
function openBracket(): OpenBracket {
  
  return {
    _type: "OPEN_BRACKET",
    
    toString: () => ("(")
  }
}

/**
 * <h1>CloseBracket</h1>
 * <p>Takes </p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>CLOSE_BRACKET = @{")" }</code>
 * </p>
*/
type CloseBracket = {
  _type: "CLOSE_BRACKET";
  
  toString: () => string;
};

/**
 * <p>Create CloseBracket serializer object.</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>CLOSE_BRACKET = @{")" }</code>
 * </p>
 * 
 * @returns The created serializer object.
 */
function closeBracket(): CloseBracket {
  
  return {
    _type: "CLOSE_BRACKET",
    
    toString: () => (")")
  }
}

/**
 * <h1>Open</h1>
 * <p>Takes an OptInstrumentFrequency</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>OPEN = {^"OPEN(" ~ OPT_INSTRUMENT_FREQUENCY? ~ ")" }</code>
 * </p>
*/
type Open = {
  _type: "OPEN";
  
  toString: () => string;
};

/**
 * <p>Create Open serializer object.</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>OPEN = {^"OPEN(" ~ OPT_INSTRUMENT_FREQUENCY? ~ ")" }</code>
 * </p>
 * 
 * @param value - an OptInstrumentFrequency
 * @returns The created serializer object.
 */
function open(value?: OptInstrumentFrequency): Open {
  
  return {
    _type: "OPEN",
    
    toString: () => {
      if (value) { return ["OPEN(", value.toString(), ")"].join("") }
      return "OPEN()"
    }
  }
}

/**
 * <h1>High</h1>
 * <p>Takes an OptInstrumentFrequency</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>HIGH = {^"HIGH(" ~ OPT_INSTRUMENT_FREQUENCY? ~ ")" }</code>
 * </p>
*/
type High = {
  _type: "HIGH";
  
  toString: () => string;
};

/**
 * <p>Create High serializer object.</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>HIGH = {^"HIGH(" ~ OPT_INSTRUMENT_FREQUENCY? ~ ")" }</code>
 * </p>
 * 
 * @param value - an OptInstrumentFrequency
 * @returns The created serializer object.
 */
function high(value?: OptInstrumentFrequency): High {
  
  return {
    _type: "HIGH",
    
    toString: () => {
      if (value) { return ["HIGH(", value.toString(), ")"].join("") }
      return "HIGH()"
    }
  }
}

/**
 * <h1>Low</h1>
 * <p>Takes an OptInstrumentFrequency</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>LOW = {^"LOW(" ~ OPT_INSTRUMENT_FREQUENCY? ~ ")" }</code>
 * </p>
*/
type Low = {
  _type: "LOW";
  
  toString: () => string;
};

/**
 * <p>Create Low serializer object.</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>LOW = {^"LOW(" ~ OPT_INSTRUMENT_FREQUENCY? ~ ")" }</code>
 * </p>
 * 
 * @param value - an OptInstrumentFrequency
 * @returns The created serializer object.
 */
function low(value?: OptInstrumentFrequency): Low {
  
  return {
    _type: "LOW",
    
    toString: () => {
      if (value) { return ["LOW(", value.toString(), ")"].join("") }
      return "LOW()"
    }
  }
}

/**
 * <h1>Close</h1>
 * <p>Takes an OptInstrumentFrequency</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>CLOSE = {^"CLOSE(" ~ OPT_INSTRUMENT_FREQUENCY? ~ ")" }</code>
 * </p>
*/
type Close = {
  _type: "CLOSE";
  
  toString: () => string;
};

/**
 * <p>Create Close serializer object.</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>CLOSE = {^"CLOSE(" ~ OPT_INSTRUMENT_FREQUENCY? ~ ")" }</code>
 * </p>
 * 
 * @param value - an OptInstrumentFrequency
 * @returns The created serializer object.
 */
function close(value?: OptInstrumentFrequency): Close {
  
  return {
    _type: "CLOSE",
    
    toString: () => {
      if (value) { return ["CLOSE(", value.toString(), ")"].join("") }
      return "CLOSE()"
    }
  }
}

/**
 * <h1>Volume</h1>
 * <p>Takes an OptInstrumentFrequency</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>VOLUME = {(^"VOL(" | ^"VOLUME(") ~ OPT_INSTRUMENT_FREQUENCY? ~ ")" }</code>
 * </p>
*/
type Volume = {
  _type: "VOLUME";
  
  toString: () => string;
};

/**
 * <p>Create Volume serializer object.</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>VOLUME = {(^"VOL(" | ^"VOLUME(") ~ OPT_INSTRUMENT_FREQUENCY? ~ ")" }</code>
 * </p>
 * 
 * @param value - an OptInstrumentFrequency
 * @returns The created serializer object.
 */
function volume(value: OptInstrumentFrequency): Volume {
  
  return {
    _type: "VOLUME",
    
    toString: () => (["VOL(", value.toString(), ")"].join(""))
  }
}

/**
 * <h1>Vwap</h1>
 * <p>Takes an OptInstrumentFrequency</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>VWAP = {^"VWAP(" ~ OPT_INSTRUMENT_FREQUENCY? ~ ")" }</code>
 * </p>
*/
type Vwap = {
  _type: "VWAP";
  
  toString: () => string;
};

/**
 * <p>Create Vwap serializer object.</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>VWAP = {^"VWAP(" ~ OPT_INSTRUMENT_FREQUENCY? ~ ")" }</code>
 * </p>
 * 
 * @param value - an OptInstrumentFrequency
 * @returns The created serializer object.
 */
function vwap(value: OptInstrumentFrequency): Vwap {
  
  return {
    _type: "VWAP",
    
    toString: () => (["VWAP(", value.toString(), ")"].join(""))
  }
}

/**
 * <h1>HeikinAshiOpen</h1>
 * <p>Takes an OptInstrumentFrequency</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>HeikinAshiOpen = {"HeikinAshiOpen(" ~ OPT_INSTRUMENT_FREQUENCY? ~ ")" }</code>
 * </p>
*/
type HeikinAshiOpen = {
  _type: "HeikinAshiOpen";
  
  toString: () => string;
};

/**
 * <p>Create HeikinAshiOpen serializer object.</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>HeikinAshiOpen = {"HeikinAshiOpen(" ~ OPT_INSTRUMENT_FREQUENCY? ~ ")" }</code>
 * </p>
 * 
 * @param value - an OptInstrumentFrequency
 * @returns The created serializer object.
 */
function heikinAshiOpen(value: OptInstrumentFrequency): HeikinAshiOpen {
  
  return {
    _type: "HeikinAshiOpen",
    
    toString: () => (["HeikinAshiOpen(", value.toString(), ")"].join(""))
  }
}

/**
 * <h1>HeikinAshiHigh</h1>
 * <p>Takes an OptInstrumentFrequency</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>HeikinAshiHigh = {"HeikinAshiHigh(" ~ OPT_INSTRUMENT_FREQUENCY? ~ ")" }</code>
 * </p>
*/
type HeikinAshiHigh = {
  _type: "HeikinAshiHigh";
  
  toString: () => string;
};

/**
 * <p>Create HeikinAshiHigh serializer object.</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>HeikinAshiHigh = {"HeikinAshiHigh(" ~ OPT_INSTRUMENT_FREQUENCY? ~ ")" }</code>
 * </p>
 * 
 * @param value - an OptInstrumentFrequency
 * @returns The created serializer object.
 */
function heikinAshiHigh(value: OptInstrumentFrequency): HeikinAshiHigh {
  
  return {
    _type: "HeikinAshiHigh",
    
    toString: () => (["HeikinAshiHigh(", value.toString(), ")"].join(""))
  }
}

/**
 * <h1>HeikinAshiLow</h1>
 * <p>Takes an OptInstrumentFrequency</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>HeikinAshiLow = {"HeikinAshiLow(" ~ OPT_INSTRUMENT_FREQUENCY? ~ ")" }</code>
 * </p>
*/
type HeikinAshiLow = {
  _type: "HeikinAshiLow";
  
  toString: () => string;
};

/**
 * <p>Create HeikinAshiLow serializer object.</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>HeikinAshiLow = {"HeikinAshiLow(" ~ OPT_INSTRUMENT_FREQUENCY? ~ ")" }</code>
 * </p>
 * 
 * @param value - an OptInstrumentFrequency
 * @returns The created serializer object.
 */
function heikinAshiLow(value: OptInstrumentFrequency): HeikinAshiLow {
  
  return {
    _type: "HeikinAshiLow",
    
    toString: () => (["HeikinAshiLow(", value.toString(), ")"].join(""))
  }
}

/**
 * <h1>HeikinAshiClose</h1>
 * <p>Takes an OptInstrumentFrequency</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>HeikinAshiClose = {"HeikinAshiClose(" ~ OPT_INSTRUMENT_FREQUENCY? ~ ")" }</code>
 * </p>
*/
type HeikinAshiClose = {
  _type: "HeikinAshiClose";
  
  toString: () => string;
};

/**
 * <p>Create HeikinAshiClose serializer object.</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>HeikinAshiClose = {"HeikinAshiClose(" ~ OPT_INSTRUMENT_FREQUENCY? ~ ")" }</code>
 * </p>
 * 
 * @param value - an OptInstrumentFrequency
 * @returns The created serializer object.
 */
function heikinAshiClose(value: OptInstrumentFrequency): HeikinAshiClose {
  
  return {
    _type: "HeikinAshiClose",
    
    toString: () => (["HeikinAshiClose(", value.toString(), ")"].join(""))
  }
}

/**
 * <h1>Candle</h1>
 * <p>Takes one of an Open, or a High, or a Low, or a Close, or a Volume, or a Vwap, or a HeikinAshiOpen, or a HeikinAshiHigh, or a HeikinAshiLow, or a HeikinAshiClose</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>CANDLE = _{OPEN | HIGH | LOW | CLOSE | VOLUME | VWAP
 * 	| HeikinAshiOpen | HeikinAshiHigh | HeikinAshiLow | HeikinAshiClose
 * }</code>
 * </p>
*/
type Candle = {
  _type: "CANDLE";
  
  toString: () => string;
};

/**
 * <p>Create Candle serializer object.</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>CANDLE = _{OPEN | HIGH | LOW | CLOSE | VOLUME | VWAP
 * 	| HeikinAshiOpen | HeikinAshiHigh | HeikinAshiLow | HeikinAshiClose
 * }</code>
 * </p>
 * 
 * @param value - one of an Open, or a High, or a Low, or a Close, or a Volume, or a Vwap, or a HeikinAshiOpen, or a HeikinAshiHigh, or a HeikinAshiLow, or a HeikinAshiClose
 * @returns The created serializer object.
 */
function candle(value: Open | High | Low | Close | Volume | Vwap | HeikinAshiOpen | HeikinAshiHigh | HeikinAshiLow | HeikinAshiClose): Candle {
  
  return {
    _type: "CANDLE",
    
    toString: () => (value.toString())
  }
}

/**
 * <h1>LaggedExprs</h1>
 * <p>Takes a tuple comprising: one of an AllTimeSeriesOps, or an AllMovingAverages, or an AllIndicators, or a Candle, and a number.</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>LAGGED_EXPRS = {"Lagged(" ~ COMPUTABLE_EXPR ~ LAGGED_WINDOW ~ ")" }</code>
 * </p>
*/
type LaggedExprs = {
  _type: "LAGGED_EXPRS";
  
  toString: () => string;
};

/**
 * <p>Create LaggedExprs serializer object.</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>LAGGED_EXPRS = {"Lagged(" ~ COMPUTABLE_EXPR ~ LAGGED_WINDOW ~ ")" }</code>
 * </p>
 * 
 * @param value - a tuple comprising: one of an AllTimeSeriesOps, or an AllMovingAverages, or an AllIndicators, or a Candle, and a number.
 * @returns The created serializer object.
 */
function laggedExprs(value: [ AllTimeSeriesOps | AllMovingAverages | AllIndicators | Candle, number ]): LaggedExprs {
  
  return {
    _type: "LAGGED_EXPRS",
    
    toString: () => (["Lagged(", value[0].toString(), value[1] ? `,` + value[1].toString() : null, ")"].join(""))
  }
}

/**
 * <h1>CrossExpression</h1>
 * <p>Takes one of a ComputableExpr, or a Float</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>CROSS_EXPRESSION = _{COMPUTABLE_EXPR | FLOAT }</code>
 * </p>
*/
type CrossExpression = {
  _type: "CROSS_EXPRESSION";
  
  toString: () => string;
};

/**
 * <p>Create CrossExpression serializer object.</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>CROSS_EXPRESSION = _{COMPUTABLE_EXPR | FLOAT }</code>
 * </p>
 * 
 * @param value - one of a ComputableExpr, or a Float
 * @returns The created serializer object.
 */
function crossExpression(value: ComputableExpr | Float): CrossExpression {
  
  return {
    _type: "CROSS_EXPRESSION",
    
    toString: () => (value.toString())
  }
}

/**
 * <h1>CrossAbove</h1>
 * <p>Takes a tuple comprising: one of a ComputableExpr, or a Float, and one of a ComputableExpr, or a Float</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>CROSS_ABOVE = {"CrossAbove(" ~ CROSS_EXPRESSION ~ CROSS_EXPRESSION ~ ")" }</code>
 * </p>
*/
type CrossAbove = {
  _type: "CROSS_ABOVE";
  
  toString: () => string;
};

/**
 * <p>Create CrossAbove serializer object.</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>CROSS_ABOVE = {"CrossAbove(" ~ CROSS_EXPRESSION ~ CROSS_EXPRESSION ~ ")" }</code>
 * </p>
 * 
 * @param value - a tuple comprising: one of a ComputableExpr, or a Float, and one of a ComputableExpr, or a Float
 * @returns The created serializer object.
 */
function crossAbove(value: [ ComputableExpr | Float, ComputableExpr | Float ]): CrossAbove {
  
  return {
    _type: "CROSS_ABOVE",
    
    toString: () => (["CrossAbove(", value[0].toString(), ',', value[1].toString(), ")"].join(""))
  }
}

/**
 * <h1>CrossUnder</h1>
 * <p>Takes a tuple comprising: one of a ComputableExpr, or a Float, and one of a ComputableExpr, or a Float</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>CROSS_UNDER = {"CrossUnder(" ~ CROSS_EXPRESSION ~ CROSS_EXPRESSION ~ ")" }</code>
 * </p>
*/
type CrossUnder = {
  _type: "CROSS_UNDER";
  
  toString: () => string;
};

/**
 * <p>Create CrossUnder serializer object.</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>CROSS_UNDER = {"CrossUnder(" ~ CROSS_EXPRESSION ~ CROSS_EXPRESSION ~ ")" }</code>
 * </p>
 * 
 * @param value - a tuple comprising: one of a ComputableExpr, or a Float, and one of a ComputableExpr, or a Float
 * @returns The created serializer object.
 */
function crossUnder(value: [ ComputableExpr | Float, ComputableExpr | Float ]): CrossUnder {
  
  return {
    _type: "CROSS_UNDER",
    
    toString: () => (["CrossUnder(", value[0].toString(), ',', value[1].toString(), ")"].join(""))
  }
}

/**
 * <h1>Cross</h1>
 * <p>Takes a tuple comprising: one of a ComputableExpr, or a Float, and one of a ComputableExpr, or a Float</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>CROSS = {"Cross(" ~ CROSS_EXPRESSION ~ CROSS_EXPRESSION ~ ")" }</code>
 * </p>
*/
type Cross = {
  _type: "CROSS";
  
  toString: () => string;
};

/**
 * <p>Create Cross serializer object.</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>CROSS = {"Cross(" ~ CROSS_EXPRESSION ~ CROSS_EXPRESSION ~ ")" }</code>
 * </p>
 * 
 * @param value - a tuple comprising: one of a ComputableExpr, or a Float, and one of a ComputableExpr, or a Float
 * @returns The created serializer object.
 */
function cross(value: [ ComputableExpr | Float, ComputableExpr | Float ]): Cross {
  
  return {
    _type: "CROSS",
    
    toString: () => (["Cross(", value[0].toString(), ',', value[1].toString(), ")"].join(""))
  }
}

/**
 * <h1>CrossAction</h1>
 * <p>Takes one of a CrossAbove, or a CrossUnder, or a Cross</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>CROSS_ACTION = _{CROSS_ABOVE | CROSS_UNDER | CROSS }</code>
 * </p>
*/
type CrossAction = {
  _type: "CROSS_ACTION";
  
  toString: () => string;
};

/**
 * <p>Create CrossAction serializer object.</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>CROSS_ACTION = _{CROSS_ABOVE | CROSS_UNDER | CROSS }</code>
 * </p>
 * 
 * @param value - one of a CrossAbove, or a CrossUnder, or a Cross
 * @returns The created serializer object.
 */
function crossAction(value: CrossAbove | CrossUnder | Cross): CrossAction {
  
  return {
    _type: "CROSS_ACTION",
    
    toString: () => (value.toString())
  }
}

/**
 * <h1>ComputableExpr</h1>
 * <p>Takes one of an AllTimeSeriesOps, or an AllMovingAverages, or an AllIndicators, or a Candle</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>COMPUTABLE_EXPR = {ALL_TIME_SERIES_OPS | ALL_MOVING_AVERAGES | ALL_INDICATORS | CANDLE }</code>
 * </p>
*/
type ComputableExpr = {
  _type: "COMPUTABLE_EXPR";
  
  toString: () => string;
};

/**
 * <p>Create ComputableExpr serializer object.</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>COMPUTABLE_EXPR = {ALL_TIME_SERIES_OPS | ALL_MOVING_AVERAGES | ALL_INDICATORS | CANDLE }</code>
 * </p>
 * 
 * @param value - one of an AllTimeSeriesOps, or an AllMovingAverages, or an AllIndicators, or a Candle
 * @returns The created serializer object.
 */
function computableExpr(value: AllTimeSeriesOps | AllMovingAverages | AllIndicators | Candle): ComputableExpr {
  
  return {
    _type: "COMPUTABLE_EXPR",
    
    toString: () => (value.toString())
  }
}

/**
 * <h1>Expressions</h1>
 * <p>Takes one of a LaggedExprs, or a ComputableExpr, or a Float, or an IndicatorExpression, or a Brackets, or a MathematicalFunctions</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>EXPRESSIONS = _{LAGGED_EXPRS | COMPUTABLE_EXPR | FLOAT | INDICATOR_EXPRESSION | BRACKETS | MATHEMATICAL_FUNCTIONS }</code>
 * </p>
*/
type Expressions = {
  _type: "EXPRESSIONS";
  
  toString: () => string;
};

/**
 * <p>Create Expressions serializer object.</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>EXPRESSIONS = _{LAGGED_EXPRS | COMPUTABLE_EXPR | FLOAT | INDICATOR_EXPRESSION | BRACKETS | MATHEMATICAL_FUNCTIONS }</code>
 * </p>
 * 
 * @param value - one of a LaggedExprs, or a ComputableExpr, or a Float, or an IndicatorExpression, or a Brackets, or a MathematicalFunctions
 * @returns The created serializer object.
 */
function expressions(value: LaggedExprs | ComputableExpr | Float | IndicatorExpression | Brackets | MathematicalFunctions): Expressions {
  
  return {
    _type: "EXPRESSIONS",
    
    toString: () => (value.toString())
  }
}

/**
 * <h1>AllExpressions</h1>
 * <p>Takes one of a LaggedExprs, or a ComputableExpr, or a Float, or a CrossAction</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>ALL_EXPRESSIONS = _{LAGGED_EXPRS | COMPUTABLE_EXPR | FLOAT | CROSS_ACTION
 * }</code>
 * </p>
*/
type AllExpressions = {
  _type: "ALL_EXPRESSIONS";
  
  toString: () => string;
};

/**
 * <p>Create AllExpressions serializer object.</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>ALL_EXPRESSIONS = _{LAGGED_EXPRS | COMPUTABLE_EXPR | FLOAT | CROSS_ACTION
 * }</code>
 * </p>
 * 
 * @param value - one of a LaggedExprs, or a ComputableExpr, or a Float, or a CrossAction
 * @returns The created serializer object.
 */
function allExpressions(value: LaggedExprs | ComputableExpr | Float | CrossAction): AllExpressions {
  
  return {
    _type: "ALL_EXPRESSIONS",
    
    toString: () => (value.toString())
  }
}

/**
 * <h1>RecursiveExpressions</h1>
 * <p>Takes one of a LogicalOp, or a Brackets, or a ComparisonExpression, or an AllPatterns, or a CrossAction, or a MathematicalOp, or a Float, or a Candle, or a MathematicalFunctions, or a ComputableExpr</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>RECURSIVE_EXPRESSIONS = _{LOGICAL_OP | BRACKETS | COMPARISON_EXPRESSION |
 * 	ALL_PATTERNS | CROSS_ACTION | MATHEMATICAL_OP |
 * 	FLOAT | CANDLE | MATHEMATICAL_FUNCTIONS | COMPUTABLE_EXPR
 * }</code>
 * </p>
*/
type RecursiveExpressions = {
  _type: "RECURSIVE_EXPRESSIONS";
  
  toString: () => string;
};

/**
 * <p>Create RecursiveExpressions serializer object.</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>RECURSIVE_EXPRESSIONS = _{LOGICAL_OP | BRACKETS | COMPARISON_EXPRESSION |
 * 	ALL_PATTERNS | CROSS_ACTION | MATHEMATICAL_OP |
 * 	FLOAT | CANDLE | MATHEMATICAL_FUNCTIONS | COMPUTABLE_EXPR
 * }</code>
 * </p>
 * 
 * @param value - one of a LogicalOp, or a Brackets, or a ComparisonExpression, or an AllPatterns, or a CrossAction, or a MathematicalOp, or a Float, or a Candle, or a MathematicalFunctions, or a ComputableExpr
 * @returns The created serializer object.
 */
function recursiveExpressions(value: LogicalOp | Brackets | ComparisonExpression | AllPatterns | CrossAction | MathematicalOp | Float | Candle | MathematicalFunctions | ComputableExpr): RecursiveExpressions {
  
  return {
    _type: "RECURSIVE_EXPRESSIONS",
    
    toString: () => (value.toString())
  }
}

/**
 * <h1>LogicalOrMathOp</h1>
 * <p>Takes one of a LogicalOp, or a MathematicalOp</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>LOGICAL_OR_MATH_OP = _{LOGICAL_OP | MATHEMATICAL_OP }</code>
 * </p>
*/
type LogicalOrMathOp = {
  _type: "LOGICAL_OR_MATH_OP";
  
  toString: () => string;
};

/**
 * <p>Create LogicalOrMathOp serializer object.</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>LOGICAL_OR_MATH_OP = _{LOGICAL_OP | MATHEMATICAL_OP }</code>
 * </p>
 * 
 * @param value - one of a LogicalOp, or a MathematicalOp
 * @returns The created serializer object.
 */
function logicalOrMathOp(value: LogicalOp | MathematicalOp): LogicalOrMathOp {
  
  return {
    _type: "LOGICAL_OR_MATH_OP",
    
    toString: () => (value.toString())
  }
}

/**
 * <h1>Brackets</h1>
 * <p>Takes one of a tuple comprising: a RecursiveExpressions, and zero or more a tuple comprising: a LogicalOrMathOp, and a RecursiveExpressions, or a Brackets</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>BRACKETS = _{OPEN_BRACKET ~ (RECURSIVE_EXPRESSIONS ~ (LOGICAL_OR_MATH_OP ~ RECURSIVE_EXPRESSIONS)*) ~ CLOSE_BRACKET
 * 	| OPEN_BRACKET ~ BRACKETS ~ CLOSE_BRACKET // Recursion
 * }</code>
 * </p>
*/
type Brackets = {
  _type: "BRACKETS";
  
  toString: () => string;
};

/**
 * <p>Create Brackets serializer object.</p>
 * <p>
 * <strong>Peg Definition:</strong>
 * <code>BRACKETS = _{OPEN_BRACKET ~ (RECURSIVE_EXPRESSIONS ~ (LOGICAL_OR_MATH_OP ~ RECURSIVE_EXPRESSIONS)*) ~ CLOSE_BRACKET
 * 	| OPEN_BRACKET ~ BRACKETS ~ CLOSE_BRACKET // Recursion
 * }</code>
 * </p>
 * 
 * @param value - one of a tuple comprising: a RecursiveExpressions, and zero or more a tuple comprising: a LogicalOrMathOp, and a RecursiveExpressions, or a Brackets
 * @returns The created serializer object.
 */
function brackets(value: [ RecursiveExpressions, ([ LogicalOrMathOp, RecursiveExpressions ])[] ] | Brackets): Brackets {
  
  return {
    _type: "BRACKETS",
    
    toString: () => ("(" + (Array.isArray(value) ? (value[0].toString() + value[1].flatMap((inner_value) => [inner_value[0].toString(), inner_value[1].toString()].join("")).join("")) : value.toString()) + ")")
  }
}

export const s = {
  sigmaScriptV1,
  indicator,
  indicatorExpression,
  comparisonExpression,
  expression,
  condition,
  whitespace,
  float,
  window,
  halfWindow,
  laggedWindow,
  zeroToOne,
  zeroToHalf,
  sum,
  difference,
  product,
  division,
  exponentiation,
  mathematicalOp,
  mathExp,
  mathExp2,
  mathLn,
  mathLog2,
  mathLog10,
  mathSqrt,
  mathCbrt,
  mathCos,
  mathAcos,
  mathCosh,
  mathSin,
  mathAsin,
  mathSinh,
  mathTan,
  mathAtan,
  mathTanh,
  mathAbs,
  mathLog,
  mathMin,
  mathMax,
  mathematicalFunctions,
  dbId,
  openSquare,
  closeSquare,
  arrayValue,
  patternArray,
  instrumentHashcode,
  m1,
  m3,
  m5,
  m10,
  m15,
  m30,
  h1,
  h2,
  h3,
  h4,
  d1,
  candleFrequency,
  optInstrumentFrequency,
  sma,
  smm,
  linReg,
  ema,
  dema,
  tema,
  hma,
  rma,
  tma,
  wma,
  vwma,
  swma,
  vidya,
  wsma,
  trima,
  allMovingAverages,
  cci,
  derivative,
  highest,
  lowest,
  highestLowestDelta,
  highestIndex,
  lowestIndex,
  integral,
  meanAbsDev,
  medianAbsDev,
  momentum,
  pastOpen,
  pastHigh,
  pastLow,
  pastClose,
  roc,
  stDev,
  linearVolatility,
  allTimeSeriesOps,
  aroonUp,
  aroonDown,
  aroonValue,
  fast,
  slow,
  momentumValue,
  adiAdx,
  adiPlus,
  adiMinus,
  adiValue,
  upperBand,
  middleBand,
  lowerBand,
  bbValue,
  tenkanSen,
  kijunSen,
  senkouSpanA,
  senkouSpanB,
  icValue,
  value,
  signal,
  main,
  macdValue,
  trixValue,
  tsiValue,
  kvoValue,
  f1,
  f2,
  stoOscValue,
  sar,
  trend,
  paraSarValue,
  adiInner,
  averageDirectionalIndex,
  awesomeOscillator,
  aroonInner,
  aroon,
  bollingerBandsInner,
  bollingerBands,
  chaikinMoneyFlow,
  chaikinOscillatorInner,
  chaikinOscillator,
  chandeMomentumOscillatorInner,
  chandeMomentumOscillator,
  commodityChannelIndexInner,
  commodityChannelIndex,
  detrendedPriceOscillatorInner,
  detrendedPriceOscillator,
  easeOfMovement,
  eldersForceIndex,
  envelopes,
  fisherTransform,
  hullMovingAverageInner,
  hullMovingAverage,
  ichimokuCloudInner,
  ichimokuCloud,
  keltnerChannel,
  klingerVolumeOscillatorInner,
  klingerVolumeOscillator,
  knowSureThing,
  macdInner,
  macd,
  momentumIndexInner,
  momentumIndex,
  moneyFlowIndex,
  parabolicSAR,
  priceChannelStrategy,
  rsiInner,
  rsi,
  stochasticOscillatorInner,
  stochasticOscillator,
  trendStrengthIndexInner,
  trendStrengthIndex,
  trixInner,
  trix,
  trueStrengthIndexInner,
  trueStrengthIndex,
  woodiesCCIInner,
  woodiesCCI,
  atr,
  allIndicators,
  andOp,
  orOp,
  notOp,
  logicalOp,
  gt,
  gte,
  lt,
  lte,
  eq,
  cmp,
  latchSize,
  doubleBottom,
  doubleTop,
  roundedBottom,
  roundedTop,
  cupHandle,
  invertedCupHandle,
  tripleTop,
  tripleBottom,
  headAndShoulders,
  invertedHeadAndShoulders,
  storedCustomPatternDb,
  customPatternArray,
  doubleBottomAscending,
  doubleBottomDescending,
  doubleTopAscending,
  doubleTopDescending,
  allPatterns,
  openBracket,
  closeBracket,
  open,
  high,
  low,
  close,
  volume,
  vwap,
  heikinAshiOpen,
  heikinAshiHigh,
  heikinAshiLow,
  heikinAshiClose,
  candle,
  laggedExprs,
  crossExpression,
  crossAbove,
  crossUnder,
  cross,
  crossAction,
  computableExpr,
  expressions,
  allExpressions,
  recursiveExpressions,
  logicalOrMathOp,
  brackets,
};