import React from "react";
// eslint-disable-next-line max-len
import ConditionBuilderSelector from "../condition_builder_selector/ConditionBuilderSelector.component";
import {
  convertBlockToSigmaScript,
  useConditionBuilderContext,
} from "@client/src/context/ConditionBuilderContext";
// eslint-disable-next-line max-len
import ConditionBuilderBlockRenderer from "../condition_builder_block_renderer/ConditionBuilderBlockRenderer.component";
// eslint-disable-next-line max-len
import ConditionBuilderToolbar from "../condition_builder_toolbar/ConditionBuilderToolbar.component";
// eslint-disable-next-line max-len
import {
  BlockContainer,
  Container,
  Header,
  HeaderSection,
} from "./ConditionBuilder.style";
import { Input, InputContainer } from "../input/Input.style";
import Button from "@components/button/Button.component";
import { useAuth0 } from "@auth0/auth0-react";
import { HiPlus } from "react-icons/hi2";
import { toast } from "react-toastify";
import {
  getLogicOptions,
  getComparatorOptions,
  getTechnicalsOptions,
  getPatternOptions,
  getPriceOptions,
  getCrossOptions,
} from "./ConditionBuilder.helper";

const ConditionBuilder = ({ onSave }: { onSave?: () => void }) => {
  const {
    block,
    selectedTool,
    conditionActionQueue,
    setBlock,
    setSelectedTool,
    mutateConditionActionQueue,
    fetchConditions,
    addToUndoStack,
    clearBlock,
    conditionName,
    setConditionName,
    editBlockId,
    setEditBlockId,
  } = useConditionBuilderContext();
  const { getAccessTokenSilently } = useAuth0();
  const [saveText, editSaveText] = React.useState("Save");

  const renderSaveText = () => {
    if (conditionName === "") {
      editSaveText("Save");
    } else {
      editSaveText("Update");
    }
  };

  const handleNewCondition = () => {
    setConditionName("");
    clearBlock();
    setEditBlockId(block.id);
  };

  React.useEffect(() => {
    renderSaveText();
  }, [editBlockId]);

  const handleSave = async () => {
    const token = await getAccessTokenSilently();
    const url = "/api/conditions";

    const response = await fetch(url, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        id: editBlockId || block.id,
        name: conditionName,
        block: block,
      }),
    });

    if (response.ok) {
      fetchConditions();
      if (onSave) onSave();
      toast.success(`Successfully saved ${conditionName}`);
      return;
    }
  };

  const renderLogicOptions = () => {
    if (selectedTool !== "logic") return null;
    return (
      <ConditionBuilderSelector
        options={getLogicOptions(
          conditionActionQueue,
          block,
          mutateConditionActionQueue,
          setBlock,
          setSelectedTool,
          addToUndoStack
        )}
      />
    );
  };

  const renderComparatorOptions = () => {
    if (selectedTool !== "comparator") return null;
    return (
      <ConditionBuilderSelector
        options={getComparatorOptions(
          conditionActionQueue,
          block,
          mutateConditionActionQueue,
          setBlock,
          setSelectedTool,
          addToUndoStack
        )}
      />
    );
  };

  const renderTechnicalsOptions = () => {
    if (selectedTool !== "technical") return null;
    return (
      <ConditionBuilderSelector
        options={getTechnicalsOptions(
          conditionActionQueue,
          block,
          mutateConditionActionQueue,
          setBlock,
          setSelectedTool,
          addToUndoStack
        )}
      />
    );
  };

  const renderPatternOptions = () => {
    if (selectedTool !== "pattern") return null;
    return (
      <ConditionBuilderSelector
        options={getPatternOptions(
          conditionActionQueue,
          block,
          mutateConditionActionQueue,
          setBlock,
          setSelectedTool,
          addToUndoStack
        )}
      />
    );
  };

  const renderPriceOptions = () => {
    if (selectedTool !== "price") return null;
    return (
      <ConditionBuilderSelector
        options={getPriceOptions(
          conditionActionQueue,
          block,
          mutateConditionActionQueue,
          setBlock,
          setSelectedTool,
          addToUndoStack
        )}
      />
    );
  };

  const renderCrossOptions = () => {
    if (selectedTool !== "cross") return null;
    return (
      <ConditionBuilderSelector
        options={getCrossOptions(
          conditionActionQueue,
          block,
          mutateConditionActionQueue,
          setBlock,
          setSelectedTool,
          addToUndoStack
        )}
      />
    );
  };

  return (
    <Container>
      <Header>
        <HeaderSection>
          <InputContainer>
            <Input
              type={"text"}
              value={conditionName}
              onChange={(e) => setConditionName(e.target.value)}
              placeholder="Condition Name"
            />
          </InputContainer>
          <Button
            onClick={handleSave}
            size="sm"
            disabled={conditionName === "" || !convertBlockToSigmaScript(block)}
            text={saveText}
            type="primary"
          />
        </HeaderSection>
        <HeaderSection>
          {block.blockType === "" ? (
            <div>
              <span>Click the</span>
              <HiPlus />
              <span>below to start building your condition</span>
            </div>
          ) : (
            <Button
              leftIcon={<HiPlus />}
              text="New Condition"
              size="sm"
              type="secondary"
              onClick={() => handleNewCondition()}
            />
          )}
        </HeaderSection>
      </Header>
      <ConditionBuilderToolbar />
      <BlockContainer>
        <ConditionBuilderBlockRenderer block={block} />
        {renderLogicOptions()}
        {renderComparatorOptions()}
        {renderTechnicalsOptions()}
        {renderPatternOptions()}
        {renderPriceOptions()}
        {renderCrossOptions()}
      </BlockContainer>
    </Container>
  );
};

export default ConditionBuilder;
