import styled from "styled-components";
import { textStyles } from "@utils/textStyles";

const Container = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 0.8rem;
  &:hover {
    background-color: ${(props) => props.theme.instrumentItemHoverBg};
    cursor: pointer;
  }
`;

const Column = styled.div`
  display: flex;
  width: 20%;
  justify-content: flex-start;
  align-items: flex-start;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

const Body = styled.div`
  display: flex;
  gap: 0.12rem;
  width: 100%;
  flex-direction: row;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const LabelContainer = styled.div`
  display: flex;
  gap: 1rem;
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
    height: 100%;
  }
`;

const Symbol = styled.span`
  ${textStyles.textBaseBold}
  color: ${(props) => props.theme.text};
`;

const Name = styled.span`
  ${textStyles.textSm}
  color: ${(props) => props.theme.subtext};
`;

export { Container, Symbol, Name, Body, Column, LabelContainer };
