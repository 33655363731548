import { SubscriptionDetails } from "@common/types";
import { useSubscriptions } from "@hooks/useSubscriptions";
import { format } from "date-fns";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useUserContext } from "../../context/user/UserContext";
import { openCancelConfirmation } from "../../pages/profile/Profile.component";
import Button from "../button/Button.component";
import Section from "../settings/section/Section.component";
// eslint-disable-next-line max-len
import SettingsButton from "../settings/settings_button/SettingsButton.component";
import SettingsWrapper from "../settings_wrapper/SettingsWrapper.component";
import {
  DetailsItem,
  DetailsText,
  Divider,
  SubscriptionDatesContainer,
  SubscriptionDetailsContainer,
  SubscriptionType,
  Title,
} from "./SubscriptionProfileDetails.style";

type Props = {
  handleClickPurchase: () => void;
};
const renderSubscriptionButton = (
  activeSubscription: SubscriptionDetails | undefined,
  handleClickPurchase: () => void
) => {
  const navigate = useNavigate();
  return activeSubscription
    ? renderExploreButton(handleClickPurchase)
    : renderUpgradeButton(() => navigate("/plans"));
};

const renderExploreButton = (onClickExplore: () => void) => {
  return <Button text={"Explore"} type={"primary"} onClick={onClickExplore} />;
};

const renderUpgradeButton = (handleClickPurchase: () => void) => (
  <Button
    text={"Upgrade"}
    type={"primary"}
    onClick={() => handleClickPurchase()}
  />
);

const renderCancelSubscriptionButton = (onClick: () => void) => (
  <Button text={"Cancel Subscription"} type={"destructive"} onClick={onClick} />
);

const renderSubscriptionType = (
  activeSubscription: SubscriptionDetails | undefined
) => {
  return (
    <div>
      <Title>Current Subscription</Title>
      <SubscriptionType>
        {activeSubscription ? activeSubscription.productName : "Free"}
      </SubscriptionType>
    </div>
  );
};

const renderSubscriptionDetails = (
  subscriptionDetails: SubscriptionDetails
) => {
  return (
    <SubscriptionDetailsContainer>
      <SubscriptionDatesContainer>
        <DetailsItem>
          <Title>Start Date</Title>
          <DetailsText>
            {format(
              new Date(
                subscriptionDetails.createdAt
              ).getTime(),
              "MMMM dd, yyyy",
            )}
          </DetailsText>
        </DetailsItem>
        {subscriptionDetails.expiredAt && (
          <DetailsItem>
            <Title>Expiry Date</Title>
            <DetailsText>
              {format(
                new Date(subscriptionDetails.expiredAt).getTime(),
                "MMMM dd, yyyy "
              )}
            </DetailsText>
          </DetailsItem>
        )}
        {subscriptionDetails.renewAt && (
          <DetailsItem>
            <Title>Renewal Date</Title>
            <DetailsText>
              {format(
                new Date(subscriptionDetails.renewAt).getTime(),
                "MMMM dd, yyyy "
              )}
            </DetailsText>
          </DetailsItem>
        )}
      </SubscriptionDatesContainer>
      <DetailsItem>
        <Title>Amount</Title>
        <DetailsText>${subscriptionDetails.payload.price} USD</DetailsText>
      </DetailsItem>
    </SubscriptionDetailsContainer>
  );
};

const SubscriptionProfileDetails = ({ handleClickPurchase }: Props) => {
  const [showCancelSubModal, setShowCancelSubModal] = useState(false);
  const { cancelSubscription } = useSubscriptions();
  const { activeSubscription: subscriptionDetails } = useUserContext();
  return (
    <SettingsWrapper>
      {showCancelSubModal &&
        openCancelConfirmation(
          () => setShowCancelSubModal(false),
          async () => {
            await cancelSubscription(subscriptionDetails);
            setShowCancelSubModal(false);
          },
          subscriptionDetails
        )}
      <Section title={""}>
        <SettingsButton
          onClick={() => null}
          leftIcon={renderSubscriptionType(subscriptionDetails)}
          rightIcon={renderSubscriptionButton(
            subscriptionDetails,
            handleClickPurchase
          )}
          text=""
        />
        {subscriptionDetails && (
          <div>
            <SettingsButton
              onClick={() => null}
              leftIcon={renderSubscriptionDetails(subscriptionDetails)}
              rightIcon={null}
              text=""
            />
            <Divider />
            <SettingsButton
              onClick={() => null}
              leftIcon={null}
              rightIcon={renderCancelSubscriptionButton(() =>
                setShowCancelSubModal(true)
              )}
              text="Cancel Subscription"
              description={
                "If you cancel your Subscription, your account " +
                "will be downgraded at the end of your billing period."
              }
            />
          </div>
        )}
      </Section>
    </SettingsWrapper>
  );
};
export default SubscriptionProfileDetails;
