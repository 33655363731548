import { isMobile } from "@client/src/utils/device";
import React from "react";
import { HiArrowUturnLeft, HiArrowUturnRight } from "react-icons/hi2";
// eslint-disable-next-line max-len
import { useConditionBuilderContext } from "../../context/ConditionBuilderContext";
import { ButtonContainer, Container } from "./ConditionBuilderToolbar.style";
import { HiX } from "react-icons/hi";

type ConditionBuilderButtonProps = {
  icon: React.ReactNode;
  text?: string;
  onClick?: () => void;
};

export const ConditionBuilderButton = ({
  icon,
  text,
  onClick,
}: ConditionBuilderButtonProps) => {
  const mobile = isMobile();

  const renderMobile = () => {
    if (!mobile) return null;
    return (
      <ButtonContainer onClick={() => (onClick ? onClick() : null)}>
        {icon}
      </ButtonContainer>
    );
  };

  const renderDesktop = () => {
    if (mobile) return null;
    return (
      <ButtonContainer onClick={() => (onClick ? onClick() : null)}>
        {icon}
        {text}
      </ButtonContainer>
    );
  };

  return (
    <>
      {renderMobile()}
      {renderDesktop()}
    </>
  );
};

type ConditionBuilderToolbarProps = {
  onLogicClick?: () => void;
};

const ConditionBuilderToolbar = ({}: ConditionBuilderToolbarProps) => {
  const { redo, undo, clearBlock } = useConditionBuilderContext();
  return (
    <Container>
      <ConditionBuilderButton
        icon={<HiX />} onClick={clearBlock} text="Clear"/>
      <ConditionBuilderButton
        icon={<HiArrowUturnLeft />} onClick={undo} text="Undo"/>
      <ConditionBuilderButton
        icon={<HiArrowUturnRight />} onClick={redo} text="Redo"/>
    </Container>
  );
};

export default ConditionBuilderToolbar;
