import React, {
  useCallback,
  useEffect,
  useState,
} from "react";
import { HiMagnifyingGlass, HiMiniXCircle } from "react-icons/hi2";
import {
  SearchContainer,
  IconContainer,
  StyledInput,
  ClearIcon,
} from "./Searchbar.styles";
import { Tooltip } from "../tooltip/Tooltip.component";

type SearchBarProps = {
  placeholder?: string | null;
  onChange: (value: string) => void;
  initialValue?: string;
};

export const Searchbar = (props: SearchBarProps) => {
  const [searchText, setSearchText] = useState(
    props.initialValue || "",
  );

  useEffect(() => {
    setSearchText(props.initialValue || "");
  }, [props.initialValue]);

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setSearchText(event.target.value);
      props.onChange(event.target.value);
    },
    [props.onChange],
  );

  const renderStyledInput = () => {
    return (
      <StyledInput
        data-testid="searchbar-input"
        type="search"
        placeholder={props.placeholder || "Search"}
        onChange={handleChange}
        value={searchText}
      />
    );
  };

  const handleClear = () => {
    setSearchText("");
    props.onChange("");
  };

  return (
    <SearchContainer data-testid="searchbar">
      <Tooltip>
        <Tooltip.Item>
          <span>
            <IconContainer data-testid="searchbar-icon">
              <HiMagnifyingGlass />
            </IconContainer>
          </span>
        </Tooltip.Item>
        <Tooltip.Content side="bottom" text="Search" />
      </Tooltip>
      {renderStyledInput()}
      {searchText && (
        <Tooltip>
          <Tooltip.Item>
            <ClearIcon onClick={handleClear}>
              <HiMiniXCircle />
            </ClearIcon>
          </Tooltip.Item>
          <Tooltip.Content side="top" text="Clear" />
        </Tooltip>
      )}
    </SearchContainer>
  );
};
