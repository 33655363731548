import { createTheme } from "./base";

export const SigmaUIDarkTheme = createTheme(
  ({ colors }) => ({
    background: colors.pallets["sigma-grey"][850],
    text: colors.white,
    subtext: colors.pallets["sigma-grey"][400],
    sigmaGrey: colors.pallets["sigma-grey"],
    primary: {
      foreground: colors.pallets["sigma-grey"][900],
      background: colors.white,
      hoverForeground:
        colors.pallets["sigma-grey"][900] + "11",
      hoverBackground: "#F2F2F2",
    },
    secondary: {
      foreground: colors.white,
      background: colors.pallets["sigma-grey"][700],
      hoverForeground:
        colors.pallets["sigma-grey"][900] + "99",
      hoverBackground:
        colors.pallets["sigma-grey"][900] + "22",
    },
    tertiary: {
      foreground: "#823210",
      background: "#FFE0D3",
    },
    error: {
      foreground: "#ff6625",
    },
    modal: {
      overlay: {
        background: "#13131366",
      },
      background: "#1b1b1b",
      boxShadow: `rgba(14, 18, 22, 0.35) 0px 10px 38px -10px,
      rgba(14, 18, 22, 0.2) 0px 10px 20px -15px`,
    },
  })
);
