import styled from "styled-components";

const Container = styled.div`
  display: flex;
  padding: 0.5rem;
  background-color: ${(props) => props.theme.inputNumericBg};
  border-radius: 0.25rem;
  justify-content: space-between;
  max-width: fit-content;
  align-items: center;
`;

const Input = styled.input`
  font-family: "Poppins", sans-serif;
  pointer-events: ${({ disabled }) => (disabled ? "none" : "auto")};
  background-color: ${(props) => props.theme.inputNumericBg};
  border: transparent;
  min-width: 0;
  color: ${(props) => props.theme.inputNumericText};
  &:focus {
    outline: none;
    border: transparent;
  }

  /* Remove spinners */
  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &[type="number"] {
    -moz-appearance: textfield;
  }
}
`;

const Label = styled.label`
  font-family: "Poppins", sans-serif;
  color: ${(props) => props.theme.inputUnitColor};
`;

export { Container, Input, Label };
