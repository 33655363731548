import styled from "styled-components";

export const Container = styled.div`
  display: block;
  border-radius: 0.5rem;
  min-width: 2rem;
  min-height: 2rem;
  background-color: ${(props) => props.theme.secondaryBackground};
  border-style: dashed;
  justify-content: center;
  align-items: center;
  max-width: 10rem;
  width: 5rem;
  max-height: 2.5rem;
  height: 2.5rem;
  border-color: ${(props) => props.theme.dashedBorder};
`;

export const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

export const AddBlockDialogContainer = styled.div`
  position: relative;
  top: 1rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  min-width: 10rem;
  width: 10rem;
  border-radius: 0.5rem;
  background-color: ${(props) => props.theme.secondaryBackground};
  z-index: 100000;
`;
