import { TechnicalConfigItem } from "@common/types";
import { PatternType } from "@generated/pattern/common_pb";
import { MovingAverageType } from "@generated/ta/moving_averages_pb";
import { generateIndicatorConfig } from "./indicatorConfigFactory";
// DoubleBottom() > DoubleTop() > MACD(12,27)

export type ChartMetadata = {
  patternTypes: number[];
  technicals: TechnicalConfigItem[];
};
const logicalAndCompareBlocks = ["&&", "||", "!", ">", ">=", "<", "<=", "="];
const allIndicators = [
  "BollingerBands",
  "Rsi",
  "Macd",
  "Aroon",
  "HullMovingAverage",
  "IchimokuCloud",
  "StochasticOscillator",
  "TrendStrengthIndex",
  "Trix",
  "TrueStrengthIndex",
  "AverageDirectionalIndex",
  "MomentumIndex",
  "KnowSureThing",
  "AwesomeOscillator",
  "EaseOfMovement",
  "EldersForceIndex",
  "Envelopes",
  "FisherTransform",
  "KeltnerChannel",
  "MoneyFlowIndex",
  "PriceChannelStrategy",
  "KlingerVolumeOscillator",
  "CommodityChannelIndex",
  "DetrendedPriceOscillator",
  "WoodiesCCI",
  "ParabolicSAR",
  "ChaikinMoneyFlow",
  "ChaikinOscillator",
  "ChandeMomentumOscillator",
];
const isLogicalOrCompareBlock = (block: string) =>
  logicalAndCompareBlocks.includes(block);

const processPattern = (acc: ChartMetadata, curr: string) => {
  const isPatternResult = isPattern(curr);
  if (isPatternResult.pattern) {
    acc.patternTypes.push(isPatternResult.patternType);
  }
};

const processMovingAverage = (acc: ChartMetadata, curr: string) => {
  const isMovingAverageResult = isMovingAverage(curr);
  if (
    isMovingAverageResult.isMA &&
    isMovingAverageResult.technicalItemConfig !== null
  ) {
    acc.technicals.push(isMovingAverageResult.technicalItemConfig);
  }
};

const processTechnical = (acc: ChartMetadata, curr: string) => {
  const isTechnicalResult = isIndicator(curr);
  if (
    isTechnicalResult.isIndicator &&
    isTechnicalResult.technicalItemConfig !== null
  ) {
    acc.technicals.push(isTechnicalResult.technicalItemConfig);
  }
};

export const getPatternsAndTechnicals = (
  sigmaScript: string
): ChartMetadata => {
  const initialAcc: ChartMetadata = {
    patternTypes: [],
    technicals: [],
  };
  if (sigmaScript === "") return initialAcc;
  return sigmaScript.toString().split(" ").reduce((acc, curr) => {
    if (!isLogicalOrCompareBlock(curr)) {
      processPattern(acc, curr);
      processMovingAverage(acc, curr);
      processTechnical(acc, curr);
    }
    return acc;
  }, initialAcc);
};

export const isMovingAverage = (scriptBlock: string) => {
  const { operator, params } = splitSigmaScript(scriptBlock);
  for (const movingAverageTypeKey in MovingAverageType) {
    if (operator.toLowerCase() === movingAverageTypeKey.toLowerCase()) {
      return {
        isMA: true,
        technicalItemConfig: {
          name: movingAverageTypeKey,
          ma: {
            kind: MovingAverageType[
              movingAverageTypeKey as keyof typeof MovingAverageType
            ],
            window_length: getParamValues(params)[0],
          },
        },
      };
    }
  }
  return {
    isMA: false,
    technicalItemConfig: null,
  };
};

const handleGetIndicatorConfig = (indicator: string, params: string) => {
  const config = generateIndicatorConfig(indicator, getParamValues(params));
  if (config !== null)
    return {
      isIndicator: true,
      technicalItemConfig: config,
    };
  return {
    isIndicator: false,
    technicalItemConfig: null,
  };
};

export const isIndicator = (scriptBlock: string) => {
  const { operator, params } = splitSigmaScript(scriptBlock);
  for (const indicator in allIndicators) {
    const indicatorKey = allIndicators[indicator];
    if (operator.toLowerCase() === indicatorKey.toLowerCase()) {
      return handleGetIndicatorConfig(indicatorKey, params);
    }
  }
  return {
    isIndicator: false,
    technicalItemConfig: null,
  };
};

export const getParamValues = (params: string) => {
  return params
    .slice(1, -1)
    .split(",")
    .map((param) => Number(param));
};

export const isPattern = (scriptBlock: string) => {
  const { operator } = splitSigmaScript(scriptBlock);
  for (const patternTypeKey in PatternType) {
    if (operator.toLowerCase() === formatPatternKey(patternTypeKey))
      return {
        pattern: true,
        patternType: PatternType[patternTypeKey as keyof typeof PatternType],
      };
  }
  return {
    pattern: false,
    patternType: -1,
  };
};

export const splitSigmaScript = (sigmaScript: string) => {
  if (!sigmaScript.includes("(")) return { operator: sigmaScript, params: "" };
  const startParamsIndex = sigmaScript.indexOf("(");
  const operator = sigmaScript.slice(0, startParamsIndex);
  const params = sigmaScript.slice(startParamsIndex);
  return { operator: operator, params: params };
};
export const formatPatternKey = (patternKey: string) => {
  return patternKey
    .toLowerCase()
    .split("_")
    .map((word) => word.toLowerCase())
    .join("");
};
