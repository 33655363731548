import { textStyles } from "@utils/textStyles";
import styled from "styled-components";

export const GridContainer = styled.div`
  background: ${(props) => props.theme.planConfig.darkBackground};
  display: grid;
  width: 100%;
  border-radius: 0.5rem;
`;

export const GridColumn = styled.div`
  ${textStyles.textBaseBold};
  padding: 1rem 0;
  justify-self: center;
  cursor: pointer;
`;

export const GridItem = styled.div`
  ${textStyles.textBase};
  padding: 1rem 0.8rem;
  color: ${(props) => props.theme.planConfig.tertiaryText};
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const GridRow = styled.div<{ selected?: boolean; $count?: number }>`
  display: grid;
  grid-template-columns: ${(props) => `2fr repeat(${props.$count}, 1fr)`};
  background-color: ${(props) =>
    props.selected ? props.theme.background : "initial"};
  &:hover {
    background-color: ${(props) => props.theme.instrumentItemHoverBg};
    cursor: pointer;
  }
`;

export const GridHeader = styled(GridRow)`
  grid-template-columns: ${(props) => `2fr repeat(${props.$count}, 1fr)`};
  background-color: ${(props) => props.theme.planConfig.darkBackground};
  position: sticky;
  top: 0;
  &:hover {
    background-color: initial;
  }
`;

export const NameAndSymbol = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  flex-direction: column;
  gap: 0.25rem;
`;

export const LeftAlignText = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  text-align: left;
  width: 100%;
  gap: 0.5rem;
`;

export const TextBold = styled.div`
  ${textStyles.textBaseBold};
`;

export const SubText = styled.div`
  ${textStyles.textSm};
  color: ${(props) => props.theme.subtext};
`;

export const AlignRight = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

export const GridButton = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  cusor: pointer;
  align-items: center;
  justify-content: center;
`;
export const ConditionMetText = styled.div`
  ${textStyles.textBaseBold};
`;
export const ConditionStatsText = styled.div`
  ${textStyles.textBase};
  color: ${(props) => props.theme.subtextColor};
`;
export const ConditionMetContainer = styled.div`
  display: flex;
  width: 100%;
  padding: 2rem 1rem 0.5rem 1rem;
  justify-content: space-between;
  align-items: center;
`;

export const NoResultsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 1rem;
`;

export const NoResultsTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
`;

export const NoResultsHeader = styled.div`
  ${textStyles.textLgBold};
`;
export const NoResultsSubtext = styled.div`
  ${textStyles.textBase};
  color: ${(props) => props.theme.subtextColor};
`;
