import React, { useCallback, useEffect } from "react";
import { Checkbox, Container, Slider } from "./Toggle.styles";

type Props = {
  checked: boolean;
  checkedColour: string;
  onChange: (newValue: boolean) => void;
};
const Toggle = ({ checked, checkedColour, onChange }: Props) => {
  const [isChecked, setIsChecked] = React.useState(checked);

  useEffect(() => {
    setIsChecked(checked);
  }, [checked]);

  const onClick = useCallback(() => {
    setIsChecked(!isChecked);
    onChange(!isChecked);
  }, [isChecked, onChange]);

  return (
    <Container>
      <Checkbox
        data-testid={"toggle-checkbox"}
        $checkedcolour={checkedColour}
        type="checkbox"
        checked={isChecked}
        readOnly
      />
      <Slider data-testid={"toggle-slider"} onClick={onClick} />
    </Container>
  );
};

export default Toggle;
