import styled from "styled-components";

export const Container = styled.div`
  position: absolute;
  z-index: 100;
  display: flex;
  flex-direction: column;
  padding: 1rem;
  background: black;
  gap: 1rem;
`;
export const Header = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

export const FormContainer = styled.div`
  padding: 1rem;
  gap: 1rem;
  display: flex;
  flex-direction: column;
`;
