export const getSafeString = (value: string | null | undefined): string => {
  return value || "";
};

export const getSafeNumber = (value: number | null | undefined): number => {
  return value || 0;
};

export const getSafeBoolean = (value: boolean | null | undefined): boolean => {
  return value || false;
};
