import React from "react";
import { themes } from "../themes";

const MobileAppLogo = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 280 280"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M54.48 123.014L162.03 213.884C168.12 220.314 172.52 228.094 174.9
      236.534C175.63 239.144 175.99 241.844 176.02 244.554L176.3 275.924L59.65
      225.424C48.26 220.524 41.07 209.234 41.52 196.964L40.91 146.574C41.08
      140.054 43.74 133.844 48.36 129.194L54.48 123.014Z"
        fill="#fff"
      />
      <path
        d="M215.78 17.1741C215.78 17.1741 192.41 -3.76586 159.01
      7.15414C125.61 18.0841 105.44 23.1841 105.44 23.1841C105.44 23.1841 88.5
      25.4341 74.43 45.9541L4 67.0341C4 67.0341 24.02 110.874 54.33
      108.324L54.42 108.294L152.74 158.374C162.28 163.234 173.73 162.364 182.42
      156.124L265.1 96.8141C271.7 92.2841 275.64 84.8441 275.64 76.9041L275.7
      17.3641L215.78 17.1741ZM158.45 48.5441L178.96 27.1641L206.05
      35.8741L158.45 48.5441Z"
        fill="#fff"
      />
    </svg>
  );
};
const AppLogo = () => {
  return (
    <svg
      width="140"
      height="24"
      viewBox="0 0 560 112"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M149.82 68.0371H170.6V77.4371H140.42V23.4671H149.82V68.0371Z"
        fill="white"
      />
      <path
        d="M221.23 59.3471H186.51C186.79 62.7671 188.13 65.5071 190.55
      67.5771C192.97 69.6471 195.94 70.6871 199.45 70.6871C204.51 70.6871 208.08
      68.6971 210.17 64.7071H220.31C218.93 68.6371 216.45 71.8671 212.85
      74.3771C209.25 76.8871 204.78 78.1471 199.45 78.1471C195.11 78.1471
      191.22 77.2271 187.78 75.3871C184.34 73.5471 181.65 70.9571 179.7
      67.6171C177.75 64.2771 176.77 60.4071 176.77 56.0071C176.77 51.6071
      177.72 47.7371 179.62 44.3971C181.52 41.0571 184.18 38.4871 187.62
      36.6671C191.06 34.8571 195 33.9471 199.45 33.9471C203.74 33.9471 207.56
      34.8271 210.91 36.5871C214.26 38.3471 216.87 40.8171 218.74
      44.0071C220.61 47.1871 221.54 50.8571 221.54 54.9971C221.56 56.5971
      221.45 58.0471 221.23 59.3471ZM211.75 52.1971C211.69 48.9371 210.46
      46.3271 208.04 44.3571C205.62 42.3871 202.62 41.4071 199.05
      41.4071C195.81 41.4071 193.03 42.3771 190.72 44.3171C188.41
      46.2571 187.04 48.8871 186.6 52.1971H211.75Z"
        fill="white"
      />
      <path
        d="M238.88 75.3771C235.42 73.5371 232.69 70.9471 230.72
      67.6071C228.74 64.2671 227.75 60.3971 227.75 55.9971C227.75 51.6471
      228.77 47.7871 230.8 44.4271C232.83 41.0671 235.61 38.4771 239.13
      36.6571C242.65 34.8471 246.58 33.9371 250.92 33.9371C255.26 33.9371
      259.19 34.8471 262.71 36.6571C266.23 38.4671 269 41.0571 271.04
      44.4271C273.07 47.7971 274.09 51.6471 274.09 55.9971C274.09 60.3471
      273.04 64.2071 270.96 67.5671C268.87 70.9371 266.03 73.5371 262.43
      75.3671C258.83 77.2071 254.86 78.1271 250.51 78.1271C246.22 78.1371
      242.34 77.2171 238.88 75.3771ZM257.31 68.8171C259.42 67.7271 261.14
      66.0971 262.46 63.9271C263.78 61.7571 264.44 59.1071 264.44 56.0071C264.44
      52.9071 263.81 50.2771 262.54 48.1271C261.28 45.9771 259.6 44.3571 257.51
      43.2771C255.42 42.1871 253.17 41.6471 250.75 41.6471C248.33 41.6471
      246.09 42.1871 244.03 43.2771C241.97 44.3671 240.33 45.9871 239.12
      48.1271C237.91 50.2771 237.31 52.9071 237.31 56.0071C237.31 60.6171
      238.56 64.1771 241.06 66.6871C243.56 69.1971 246.71 70.4571 250.5
      70.4571C252.92 70.4471 255.19 69.9071 257.31 68.8171Z"
        fill="white"
      />
      <path
        d="M296.18 35.9671C298.93 34.6171 302.03 33.9471 305.5
      33.9471C309.46 33.9471 313.07 34.8671 316.34 36.7071C319.61 38.5471 322.18
      41.1171 324.05 44.4371C325.92 47.7471 326.85 51.5571 326.85
      55.8571C326.85 60.1571 325.91 63.9971 324.05 67.3871C322.18
      70.7771 319.61 73.4171 316.34 75.3071C313.07 77.1971 309.45
      78.1371 305.5 78.1371C302.04 78.1371 298.96 77.4771 296.26
      76.1571C293.57 74.8371 291.37 73.1971 289.66
      71.2271V97.7871H280.26V34.6471H289.66V40.9371C291.26 38.9671 293.43
      37.3171 296.18 35.9671ZM315.36 48.1971C314.07 46.0471 312.36 44.4171
      310.25 43.3071C308.13 42.1971 305.87 41.6371 303.45 41.6371C301.09
      41.6371 298.85 42.2071 296.73 43.3471C294.61 44.4871 292.91 46.1471
      291.62 48.3171C290.33 50.4871 289.68 53.0571 289.68 56.0071C289.68
      58.9571 290.32 61.5371 291.62 63.7371C292.91 65.9371 294.62 67.6071
      296.73 68.7471C298.84 69.8871 301.09 70.4571 303.45 70.4571C305.87
      70.4571 308.14 69.8771 310.25 68.7071C312.37 67.5471 314.07 65.8471
      315.36 63.6171C316.65 61.3871 317.3 58.7971 317.3 55.8471C317.29
      52.8971 316.65 50.3471 315.36 48.1971Z"
        fill="white"
      />
      <path
        d="M335.89 44.4371C337.79 41.1271 340.37 38.5471
      343.64 36.7071C346.91 34.8671 350.52 33.9471 354.48 33.9471C358.05
      33.9471 361.17 34.6071 363.84 35.9271C366.51 37.2471 368.64 38.8871
      370.23 40.8571V34.6471H379.71V77.4371H370.23V71.0671C368.64 73.0871
      366.47 74.7671 363.72 76.1171C360.97 77.4671 357.84 78.1371 354.32
      78.1371C350.42 78.1371 346.84 77.1971 343.6 75.3071C340.35 73.4171
      337.78 70.7771 335.89 67.3871C333.99 63.9971 333.04 60.1571 333.04
      55.8571C333.04 51.5471 333.99 47.7471 335.89 44.4371ZM368.3 48.3171C367.01
      46.1471 365.32 44.4871 363.23 43.3471C361.14 42.2071 358.89 41.6371
      356.47 41.6371C354.05 41.6371 351.8 42.1971 349.71 43.3071C347.62 44.4171
      345.93 46.0471 344.64 48.1971C343.35 50.3471 342.7 52.8971 342.7
      55.8471C342.7 58.7971 343.34 61.3871 344.64 63.6171C345.93 65.8471
      347.64 67.5371 349.75 68.7071C351.87 69.8671 354.11 70.4571 356.47
      70.4571C358.89 70.4571 361.14 69.8871 363.23 68.7471C365.32 67.6071
      367.01 65.9371 368.3 63.7371C369.59 61.5371 370.24 58.9571 370.24
      56.0071C370.23 53.0571 369.59 50.4871 368.3 48.3171Z"
        fill="white"
      />
      <path
        d="M400.78 35.7771C403.06 34.5571 405.77 33.9571 408.9
      33.9571V43.1171H406.51C402.83 43.1171 400.04 43.9971 398.14 45.7571C396.24
      47.5171 395.3 50.5671 395.3
      54.9171V77.4371H385.9V34.6471H395.3V40.8571C396.67 38.6871 398.5 36.9871
      400.78 35.7771Z"
        fill="white"
      />
      <path
        d="M415.93 44.4371C417.83 41.1271 420.41 38.5471 423.68
      36.7071C426.95 34.8671 430.59 33.9471 434.61 33.9471C437.58 33.9471 440.51
      34.5571 443.39 35.7671C446.28 36.9871 448.57 38.5971 450.28
      40.6171V19.9571H459.76V77.4271H450.28V70.9771C448.74 73.0471 446.61
      74.7571 443.89 76.0971C441.17 77.4471 438.05 78.1171 434.53
      78.1171C430.57 78.1171 426.96 77.1771 423.69 75.2871C420.42
      73.3971 417.84 70.7571 415.94 67.3671C414.04 63.9771 413.1 60.1371 413.1
      55.8371C413.09 51.5471 414.04 47.7471 415.93 44.4371ZM448.34
      48.3171C447.05 46.1471 445.36 44.4871 443.27 43.3471C441.18 42.2071
      438.93 41.6371 436.51 41.6371C434.09 41.6371 431.84 42.1971 429.75
      43.3071C427.66 44.4171 425.97 46.0471 424.68 48.1971C423.39 50.3471
      422.74 52.8971 422.74 55.8471C422.74 58.7971 423.38 61.3871 424.68
      63.6171C425.97 65.8471 427.67 67.5371 429.79 68.7071C431.91 69.8671
      434.15 70.4571 436.51 70.4571C438.93 70.4571 441.18 69.8871 443.27
      68.7471C445.36 67.6071 447.05 65.9371 448.34 63.7371C449.63 61.5371
      450.28 58.9571 450.28 56.0071C450.28 53.0571 449.63 50.4871 448.34
      48.3171Z"
        fill="white"
      />
      <path
        d="M525.18 66.4071H501.18L497.06 77.4371H487.25L507.78
      23.3871H518.67L539.2 77.4371H529.31L525.18 66.4071ZM522.54
      59.1871L513.22 34.1071L503.82 59.1871H522.54Z"
        fill="white"
      />
      <path d="M554.94 23.4671V77.4371H545.54V23.4671H554.94Z" fill="white" />
      <path
        d="M25.98 49.1371L68.57 85.1271C70.98 87.6771 72.73 90.7571 73.67
      94.0971C73.96 95.1271 74.1 96.1971 74.11 97.2771L74.22 109.697L28.02
      89.6971C23.51 87.7571 20.66 83.2871 20.84 78.4271L20.6 58.4771C20.67
      55.8971 21.72 53.4371 23.55 51.5971L25.98 49.1371Z"
        fill="white"
      />
      <path
        d="M89.86 7.21712C89.86 7.21712 80.6 -1.07288 67.38 3.24712C54.15
      7.57712 46.17 9.59713 46.17 9.59713C46.17 9.59713 39.46 10.4871 33.89
      18.6171L6 26.9671C6 26.9671 13.93 44.3271 25.93 43.3171L25.97
      43.3071L64.91 63.1371C68.69 65.0571 73.22 64.7171 76.67 62.2471L109.41
      38.7571C112.02 36.9671 113.58 34.0171 113.59 30.8671L113.61 7.28713L89.86
      7.21712ZM67.15 19.6471L75.27 11.1771L86 14.6271L67.15 19.6471Z"
        fill="white"
      />
    </svg>
  );
};

const PoweredBySigmaDark = () => {
  return (
    <svg
      width="224"
      height="48"
      viewBox="0 0 224 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 24C0 10.7452 10.7452 0 24 0H199.768C213.023 0
      223.768 10.7452 223.768 24V48H0V24Z"
        fill="#25FFBC"
      />
      <path
        d="M32.432 22.032C32.432 22.992 32.1173 23.7653 31.488
      24.352C30.8587 24.9387 29.9147 25.232 28.656
      25.232H26.4V30H25.28V18.816H28.656C29.904 18.816 30.8427 19.1093 31.472
      19.696C32.112 20.2827 32.432 21.0613 32.432 22.032ZM28.656 24.272C30.4053
      24.272 31.28 23.5253 31.28 22.032C31.28 21.2853 31.072 20.72 30.656
      20.336C30.24 19.952 29.5733 19.76 28.656 19.76H26.4V24.272H28.656ZM38.1353
       30.128C37.3139 30.128 36.5726 29.9467 35.9113 29.584C35.2606 29.2107
       34.7433 28.688 34.3593 28.016C33.9859 27.3333 33.7993 26.5387 33.7993
       25.632C33.7993 24.7253 33.9913 23.936 34.3753 23.264C34.7593 22.5813
       35.2819 22.0587 35.9433 21.696C36.6046 21.3227 37.3459 21.136 38.1673
       21.136C38.9886 21.136 39.7299 21.3227 40.3913 21.696C41.0633 22.0587
       41.5859 22.5813 41.9593 23.264C42.3433 23.936 42.5353 24.7253 42.5353
       25.632C42.5353 26.528 42.3433 27.3173 41.9593 28C41.5753 28.6827 41.0473
        29.2107 40.3753 29.584C39.7033 29.9467 38.9566 30.128 38.1353
        30.128ZM38.1353 29.152C38.7113 29.152 39.2446 29.024 39.7353
        28.768C40.2259 28.5013 40.6206 28.1067 40.9193 27.584C41.2286
        27.0507 41.3833 26.4 41.3833 25.632C41.3833 24.864 41.2339 24.2187
        40.9353 23.696C40.6366 23.1627 40.2419 22.768 39.7513 22.512C39.2606
        22.2453 38.7273 22.112 38.1513 22.112C37.5753 22.112 37.0419 22.2453
        36.5513 22.512C36.0606 22.768 35.6659 23.1627 35.3673 23.696C35.0793
        24.2187 34.9353 24.864 34.9353 25.632C34.9353 26.4 35.0793 27.0507
        35.3673 27.584C35.6659 28.1067 36.0553 28.5013 36.5353 28.768C37.0259
        29.024 37.5593 29.152 38.1353 29.152ZM56.0969 21.264L53.3609
        30H52.2409L49.8249 22.672L47.4089 30H46.2729L43.5369
        21.264H44.6729L46.8489 28.864L49.2969 21.264H50.4169L52.8329
        28.88L54.9929 21.264H56.0969ZM65.5278 25.184C65.5278 25.568
        65.5171 25.8613 65.4958 26.064H58.2478C58.2798 26.7253 58.4398
        27.2907 58.7278 27.76C59.0158 28.2293 59.3944 28.5867 59.8638
        28.832C60.3331 29.0667 60.8451 29.184 61.3998 29.184C62.1251
        29.184 62.7331 29.008 63.2238 28.656C63.7251 28.304 64.0558
        27.8293 64.2158 27.232H65.3998C65.1864 28.0853 64.7278 28.784
        64.0238 29.328C63.3304 29.8613 62.4558 30.128 61.3998 30.128C60.5784
        30.128 59.8424 29.9467 59.1918 29.584C58.5411 29.2107 58.0291 28.688
        57.6558 28.016C57.2931 27.3333 57.1118 26.5387 57.1118 25.632C57.1118
        24.7253 57.2931 23.9307 57.6558 23.248C58.0184 22.5653 58.5251 22.0427
        59.1758 21.68C59.8264 21.3173 60.5678 21.136 61.3998 21.136C62.2318
        21.136 62.9571 21.3173 63.5758 21.68C64.2051 22.0427 64.6851 22.5333
        65.0158 23.152C65.3571 23.76 65.5278 24.4373 65.5278 25.184ZM64.3918
        25.152C64.4024 24.5013 64.2691 23.9467 63.9918 23.488C63.7251 23.0293
        63.3571 22.6827 62.8878 22.448C62.4184 22.2133 61.9064 22.096 61.3518
        22.096C60.5198 22.096 59.8104 22.3627 59.2238 22.896C58.6371 23.4293
        58.3118 24.1813 58.2478 25.152H64.3918ZM68.6969 22.816C68.9422 22.272
        69.3155 21.8507 69.8169 21.552C70.3289 21.2533 70.9529 21.104 71.6889
        21.104V22.272H71.3849C70.5742 22.272 69.9235 22.4907 69.4329
        22.928C68.9422 23.3653 68.6969 24.096 68.6969
        25.12V30H67.5769V21.264H68.6969V22.816ZM81.2778 25.184C81.2778 25.568
        81.2671 25.8613 81.2458 26.064H73.9978C74.0298 26.7253 74.1898 27.2907
        74.4778 27.76C74.7658 28.2293 75.1444 28.5867 75.6138 28.832C76.0831
        29.0667 76.5951 29.184 77.1498 29.184C77.8751 29.184 78.4831 29.008
        78.9738 28.656C79.4751 28.304 79.8058 27.8293 79.9658
        27.232H81.1498C80.9364 28.0853 80.4778 28.784 79.7738 29.328C79.0804
        29.8613 78.2058 30.128 77.1498 30.128C76.3284 30.128 75.5924 29.9467
        74.9418 29.584C74.2911 29.2107 73.7791 28.688 73.4058 28.016C73.0431
        27.3333 72.8618 26.5387 72.8618 25.632C72.8618 24.7253 73.0431 23.9307
        73.4058 23.248C73.7684 22.5653 74.2751 22.0427 74.9258 21.68C75.5764
        21.3173 76.3178 21.136 77.1498 21.136C77.9818 21.136 78.7071 21.3173
        79.3258 21.68C79.9551 22.0427 80.4351 22.5333 80.7658 23.152C81.1071
        23.76 81.2778 24.4373 81.2778 25.184ZM80.1418 25.152C80.1524 24.5013
        80.0191 23.9467 79.7418 23.488C79.4751 23.0293 79.1071 22.6827 78.6378
        22.448C78.1684 22.2133 77.6564 22.096 77.1018 22.096C76.2698 22.096
        75.5604 22.3627 74.9738 22.896C74.3871 23.4293 74.0618 24.1813 73.9978
        25.152H80.1418ZM82.8149 25.616C82.8149 24.72 82.9962 23.936 83.3589
        23.264C83.7215 22.5813 84.2175 22.0587 84.8469 21.696C85.4869 21.3227
        86.2069 21.136 87.0069 21.136C87.7749 21.136 88.4682 21.3227 89.0869
        21.696C89.7055 22.0693 90.1589 22.5547 90.4469
        23.152V18.16H91.5669V30H90.4469V28.048C90.1802 28.656 89.7429 29.1573
        89.1349 29.552C88.5269 29.936 87.8122 30.128 86.9909 30.128C86.1909
        30.128 85.4709 29.9413 84.8309 29.568C84.2015 29.1947 83.7055 28.6667
        83.3429 27.984C82.9909 27.3013 82.8149 26.512 82.8149 25.616ZM90.4469
        25.632C90.4469 24.928 90.3029 24.3093 90.0149 23.776C89.7269 23.2427
        89.3322 22.832 88.8309 22.544C88.3402 22.256 87.7962 22.112 87.1989
        22.112C86.5802 22.112 86.0255 22.2507 85.5349 22.528C85.0442 22.8053
        84.6549 23.2107 84.3669 23.744C84.0895 24.2667 83.9509 24.8907 83.9509
        25.616C83.9509 26.3307 84.0895 26.96 84.3669 27.504C84.6549 28.0373
        85.0442 28.448 85.5349 28.736C86.0255 29.0133 86.5802 29.152 87.1989
        29.152C87.7962 29.152 88.3402 29.008 88.8309 28.72C89.3322 28.432
        89.7269 28.0213 90.0149 27.488C90.3029 26.9547 90.4469 26.336 90.4469
        25.632ZM99.6184 23.2C99.9064 22.592 100.349 22.096 100.946
        21.712C101.554 21.328 102.269 21.136 103.09 21.136C103.89 21.136
        104.605 21.3227 105.234 21.696C105.864 22.0587 106.354 22.5813
        106.706 23.264C107.069 23.936 107.25 24.72 107.25 25.616C107.25 26.512
        107.069 27.3013 106.706 27.984C106.354 28.6667 105.858 29.1947 105.218
        29.568C104.589 29.9413 103.88 30.128 103.09 30.128C102.258 30.128
        101.538 29.9413 100.93 29.568C100.333 29.184 99.8957 28.688 99.6184
        28.08V30H98.5144V18.16H99.6184V23.2ZM106.114 25.616C106.114 24.8907
        105.97 24.2667 105.682 23.744C105.405 23.2107 105.021 22.8053 104.53
        22.528C104.04 22.2507 103.485 22.112 102.866 22.112C102.269 22.112
        101.72 22.256 101.218 22.544C100.728 22.832 100.338 23.2427 100.05
        23.776C99.7624 24.3093 99.6184 24.928 99.6184 25.632C99.6184 26.336
        99.7624 26.9547 100.05 27.488C100.338 28.0213 100.728 28.432 101.218
        28.72C101.72 29.008 102.269 29.152 102.866 29.152C103.485 29.152 104.04
        29.0133 104.53 28.736C105.021 28.448 105.405 28.0373 105.682
        27.504C105.97 26.96 106.114 26.3307 106.114 25.616ZM116.511
        21.264L111.327 34.112H110.159L111.855 29.952L108.271
        21.264H109.503L112.479 28.72L115.359 21.264H116.511Z"
        fill="#171E3C"
      />
      <g clipPath="url(#clip0_878_41907)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M123 36H199.768V30.1385H197.741V33.973H126.332L131.493
          23.9993L126.332
        14.027H197.741V17.9051H199.768V12H123L129.211 23.9993L123 36ZM134.969
        28.5835C135.343 29.1098 135.857 29.517 136.512 29.8027H136.511C137.165
        30.0896 137.902 30.2324 138.72 30.2324C139.585 30.2324 140.336 30.0658
        140.973 29.7326C141.611 29.3994 142.096 28.9591 142.429 28.409C142.762
        27.8603 142.929 27.2759 142.929 26.6557C142.929 25.8849 142.752 25.2674
        142.395 24.8059C142.038 24.3444 141.607 24.002 141.098 23.7798C140.588
        23.5577 139.931 23.3356 139.125 23.1134C138.518 22.9495 138.038 22.7974
        137.687 22.6573C137.337 22.5171 137.047 22.3267 136.82 22.0874C136.591
        21.8481 136.478 21.5413 136.478 21.1671C136.478 20.617 136.659 20.1966
        137.021 19.9044C137.383 19.6135 137.851 19.4667 138.424 19.4667C139.056
        19.4667 139.564 19.624 139.95 19.9401C140.335 20.2561 140.546 20.6356
        140.581 21.0798H142.79C142.708 20.0524 142.29 19.2419 141.537
        18.6522C140.783
        18.0612 139.816 17.7663 138.636 17.7663C137.806 17.7663 137.075 17.9065
        136.445 18.1868C135.813 18.4671 135.322 18.8704 134.972 19.3966C134.621
        19.9229 134.445 20.5364 134.445 21.2372C134.445 21.9975 134.621 22.6057
        134.972 23.0605C135.322 23.5154 135.745 23.8526 136.242 24.0681C136.738
        24.2849 137.385 24.4978 138.179 24.7081C138.81 24.872 139.303 25.0241
        139.66 25.1642C140.016 25.3044 140.315 25.504 140.554 25.7606C140.793
        26.0184 140.914 26.3516 140.914 26.7602C140.914 27.2864 140.721 27.7162
        140.335 28.0494C139.948 28.3826 139.387 28.5492 138.721 28.5492C138.056
        28.5492 137.538 28.3786 137.17 28.0401C136.802 27.7016 136.594 27.2692
        136.548 26.743H134.408C134.408 27.4438 134.595 28.0573 134.969
        28.5835ZM148.698
        17.925V30.1107H146.699V17.925H148.698ZM160.233 20.0551C160.795 20.3658
        161.233 20.8246 161.549 21.4315H163.948C163.492 20.2627 162.76 19.3596
        161.748 18.7223C160.737 18.085 159.582 17.7663 158.285 17.7663C157.151
        17.7663 156.108 18.0321 155.156 18.5636C154.204 19.0951 153.45 19.8343
        152.895 20.781C152.339 21.7277 152.062 22.7974 152.062 23.9901C152.062
        25.1827 152.339 26.2524 152.895 27.1992C153.45 28.1459 154.201 28.885
        155.148 29.4166C156.096 29.9481 157.142 30.2139 158.287 30.2139C159.351
        30.2139 160.318 29.9772 161.189 29.5038C162.059 29.0305 162.766 28.3958
        163.31 27.6011C163.853 26.8065 164.189 25.9351 164.317
        24.9884V23.4281H157.602
        V25.0241H162.178C162.027 26.0647 161.612 26.8898 160.934 27.4967C160.257
        28.1049 159.374 28.409 158.287 28.409C157.48 28.409 156.761 28.2279
        156.13 27.8656C155.498 27.5046 155.005 26.9863 154.648 26.3146C154.292
        25.6429 154.114 24.8681 154.114 23.9914C154.114 23.1148 154.291 22.3439
        154.648 21.6775C155.005 21.0111 155.499 20.4967 156.13 20.1344C156.761
        19.7721 157.48 19.591 158.287 19.591C159.023 19.591 159.672 19.7457
        160.233 20.0551ZM180.52 17.925V30.1107H178.521V21.7648L174.804
        30.1107H173.418
        L169.684 21.7648V30.1107H167.685V17.925H169.842L174.119 27.4808L178.379
        17.925H180.519H180.52ZM186.795 27.621H191.898L192.775
        30.1107H194.878L190.512
        17.9078H188.198L183.832 30.1107H185.919L186.795 27.621ZM189.357
        20.3275L191.337
        25.9906H187.357L189.357 20.3275Z"
          fill="#171E3C"
        />
      </g>

      <defs>
        <clipPath id="clip0_878_41907">
          <rect
            width="76.768"
            height="24"
            fill="white"
            transform="translate(123 12)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

const CupAndHandle = (
  { width = 24, height = 24 }:
  { width?: number, height?: number }
) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="24" height="24" rx="4" fill="#131313" />
      <path
        d="M1.5 12.3136L3.88636 9.22273C3.88636 9.22273 6.55739 16.95 9.85227
         16.95C13.1472 16.95 15.8182 9.22273 15.8182 9.22273L18.2045
          12.3136L22.5 6.75"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

const CupAndHandleRevert = (
  { width = 24, height = 24 }:
  { width?: number, height?: number }
) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="24" height="24" rx="4" fill="#131313" />
      <path
        d="M1.5 11.9386L3.88636 14.4727C3.88636 14.4727 6.55739 6.825 9.85227
         6.825C13.1472 6.825 15.8182 14.4727 15.8182 14.4727L18.2045
          11.9386L22.5 17.25"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

const DoubleBottom = (
  { width = 24, height = 24 }:
  { width?: number, height?: number }
) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="24" height="24" rx="4" fill="#131313" />
      <path
        d="M1.3125 5.4375L7.55819 18.375L11.8125 9.5625L16.0668 18.375L22.3125
         5.4375"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

const DoubleTop = (
  { width = 24, height = 24 }:
  { width?: number, height?: number }
) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="24" height="24" rx="4" fill="#131313" />
      <path
        d="M22.3125 19.5L16.0668 6.5625L11.8125 15.375L7.55819 6.5625L1.3125
         19.5"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

const HeadAndShoulders = (
  { width = 24, height = 24 }:
  { width?: number, height?: number }
) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="24" height="24" rx="4" fill="#131313" />
      <path
        d="M22.5 16.125L18.75 8.96591L16.25 13.7386L12 5.625L7.75 13.7386L5.25
         8.96591L1.5 16.125"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <mask id="path-3-inside-1_595_7366" fill="white">
        <ellipse
          cx="18.75"
          cy="9"
          rx="1.125"
          ry="1.125"
          transform="rotate(-180 18.75 9)"
        />
      </mask>
      <ellipse
        cx="18.75"
        cy="9"
        rx="1.125"
        ry="1.125"
        transform="rotate(-180 18.75 9)"
        fill="#131313"
      />
      <path
        d="M19.125 9C19.125 9.20711 18.9571 9.375 18.75 9.375L18.75
        6.375C17.3003 6.375 16.125 7.55025 16.125 9L19.125 9ZM18.75
        9.375C18.5429 9.375 18.375 9.20711 18.375 9L21.375 9C21.375 7.55025
        20.1997 6.375 18.75 6.375L18.75 9.375ZM18.375 9C18.375 8.79289
        18.5429 8.625 18.75 8.625L18.75 11.625C20.1997 11.625 21.375
        10.4497 21.375 9L18.375 9ZM18.75 8.625C18.9571 8.625 19.125 8.79289
        19.125 9L16.125 9C16.125 10.4497 17.3003 11.625 18.75 11.625L18.75
        8.625Z"
        fill="white"
        mask="url(#path-3-inside-1_595_7366)"
      />
    </svg>
  );
};

const InverseHeadAndShoulders = (
  { width = 24, height = 24 }:
  { width?: number, height?: number }
) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="24" height="24" rx="4" fill="#131313" />
      <path
        d="M1.5 7.875L5.25 15.0341L7.75 10.2614L12 18.375L16.25 10.2614L18.75
         15.0341L22.5 7.875"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle
        cx="5.25"
        cy="15"
        r="0.75"
        fill="#131313"
        stroke="white"
        strokeWidth="0.75"
      />
    </svg>
  );
};

const Pennant = (
  { width = 24, height = 24 }:
  { width?: number, height?: number }
) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="24" height="24" rx="4" fill="#131313" />
      <path
        d="M1.5 16.875L3.79412 21.75L10.6765 7.125L13.9412 14.0625L16.3235
         9L17.9118 12.375L22.5 2.625"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle
        cx="18"
        cy="12.375"
        r="0.75"
        transform="rotate(-180 18 12.375)"
        fill="#131313"
        stroke="white"
        strokeWidth="0.75"
      />
    </svg>
  );
};

const RoundingBottom = (
  { width = 24, height = 24 }:
  { width?: number, height?: number }
) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="24" height="24" rx="4" fill="#131313" />
      <path
        d="M1.5 5.25L4.29114 9.76899C4.29114 9.76899 5.48734 18.057 12
         18.057C18.5127 18.057 19.7089 9.76899 19.7089 9.76899L22.5 5.25"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

const RoundingTop = (
  { width = 24, height = 24 }:
  { width?: number, height?: number }
) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="24" height="24" rx="4" fill="#131313" />
      <path
        d="M22.5 19.125L19.7089 14.606C19.7089 14.606 18.5127 6.31804 12
        6.31804C5.48734 6.31804 4.29114 14.606 4.29114 14.606L1.5 19.125"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

const TripleTop = (
  { width = 24, height = 24 }:
  { width?: number, height?: number }
) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="24" height="24" rx="4" fill="#131313" />
      <path
        d="M22.5 16.5L18.1193 6.75001L15.0595 13.5595L12 6.75L8.9404
        13.5595L5.8808 6.75L1.5 16.5"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

const TripleBottom = (
  { width = 24, height = 24 }:
  { width?: number, height?: number }
) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="24" height="24" rx="4" fill="#131313" />
      <path
        d="M1 7L5.5894 17L8.79469 10.0159L12 17L15.2052 10.0159L18.4106 17L23 7"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

const HiBellPlus = () => {
  const darkModeQuery = window.matchMedia("(prefers-color-scheme: dark)");
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 2.25C8.27215 2.25 5.25012 5.27197 5.25001 8.9998L5.24981
        9.75C5.24981 11.8731 4.4488 13.8074 3.13126 15.2699C2.96476
        15.4547 2.90073 15.71 2.96033 15.9516C3.01992 16.1931 3.19539
        16.3893 3.42875 16.4755C4.97287 17.0455 6.58934 17.4659 8.2604
        17.7192C8.25351 17.812 8.25001 17.9056 8.25001 18C8.25001
        20.0711 9.92894 21.75 12 21.75C14.0711 21.75 15.75 20.0711
        15.75 18C15.75 17.9056 15.7465 17.812 15.7396 17.7192C17.4105
        17.4659 19.0269 17.0455 20.5709 16.4755C20.8042 16.3893
        20.9797 16.1931 21.0393 15.9516C21.0989 15.71 21.0349 15.4547
        20.8684 15.2699C19.5508 13.8074 18.7498 11.8731 18.7498
        9.75V9.04919L18.75 9C18.75 5.27208 15.7279 2.25 12
        2.25ZM9.75001 18C9.75001 17.9662 9.75075 17.9326 9.75221
        17.8993C10.4927 17.966 11.2424 18 11.9998 18C12.7574 18
        13.5072 17.9659 14.2478 17.8992C14.2493 17.9326 14.25 17.9662
        14.25 18C14.25 19.2426 13.2427 20.25 12 20.25C10.7574 20.25
        9.75001 19.2426 9.75001 18ZM12 7C12.4142 7 12.75 7.33579 12.75
        7.75V10H15C15.4142 10 15.75 10.3358 15.75 10.75C15.75 11.1642
        15.4142 11.5 15 11.5L12.75 11.5V13.75C12.75 14.1642 12.4142
        14.5 12 14.5C11.5858 14.5 11.25 14.1642 11.25
        13.75V11.5H9C8.58579 11.5 8.25 11.1642 8.25 10.75C8.25 10.3358
        8.58579 10 9 10L11.25 10V7.75C11.25 7.33579 11.5858 7 12 7Z"
        fill={
          darkModeQuery.matches ? themes["dark"].text : themes["light"].text
        }
      />
    </svg>
  );
};

const TelegramIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <g clipPath="url(#clip0_1140_6172)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="
          M20 10C20 15.5228 15.5228 20 10 20C4.47715 20 0 15.5228 0 10C0 4.47715
          4.47715 0 10 0C15.5228 0 20 4.47715 20 10ZM10.3584 7.38244C9.38571
          7.787 7.44178 8.62433 4.52658 9.89443C4.05319 10.0827 3.80521 10.2668
          3.78264 10.4469C3.74449 10.7512 4.12559 10.8711 4.64456
          11.0343C4.71515 11.0565 4.78829 11.0795 4.86328 11.1038C5.37386
          11.2698 6.06069 11.464 6.41774 11.4717C6.74162 11.4787 7.10311
          11.3452 7.5022 11.0711C10.226
          9.2325 11.632 8.30317 11.7203 8.28314C11.7825 8.26901 11.8688 8.25123
          11.9273 8.3032C11.9858 8.35518 11.98 8.4536 11.9738 8.48C11.9361
          8.64095
          10.4401 10.0317 9.66593 10.7515C9.42459 10.9759 9.25339 11.135 9.2184
          11.1714C9.14 11.2528 9.06011 11.3298 8.98332 11.4038C8.50897 11.8611
          8.15326 12.204 9.00301 12.764C9.41137 13.0331 9.73814 13.2556 10.0641
          13.4776C10.4201 13.7201 10.7752 13.9619 11.2347 14.2631C11.3517
          14.3398 11.4635 14.4195 11.5724 14.4971C11.9867
          14.7925 12.359 15.0578 12.8188 15.0155C13.086 14.9909 13.3621
          14.7397 13.5022 13.9903C13.8335 12.2193 14.4847 8.38205 14.6352
          6.80081C14.6484 6.66227 14.6318 6.48498 14.6185 6.40715C14.6051
          6.32931 14.5773 6.21842 14.4761 6.13633C14.3563 6.03911 14.1714
          6.01861 14.0886 6.02007C13.7125
          6.0267 13.1355 6.22735 10.3584 7.38244Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_1140_6172">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

const MagnifyingGlassChart = () => {
  const darkModeQuery = window.matchMedia("(prefers-color-scheme: dark)");
  return (
    <svg
      width="1rem"
      height="1rem"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.5 2.25C5.94365 2.25
      2.25 5.94365 2.25 10.5C2.25 15.0563 5.94365 18.75 10.5 18.75C12.5078
      18.75 14.3491 18.032 15.7793 16.8399L20.4697 21.5303C20.7626 21.8232
      21.2374 21.8232 21.5303 21.5303C21.8232 21.2374 21.8232 20.7626 21.5303
      20.4697L16.8399 15.7793C18.032 14.3491 18.75 12.5078 18.75 10.5C18.75
      5.94365 15.0563 2.25 10.5 2.25ZM14.25 7.5C14.25 7.08579 13.9142 6.75
      13.5 6.75C13.0858 6.75 12.75 7.08579 12.75 7.5V13.5C12.75 13.9142 13.0858
      14.25 13.5 14.25C13.9142 14.25 14.25 13.9142 14.25 13.5V7.5ZM11.25
      9.75C11.25 9.33579 10.9142 9 10.5 9C10.0858 9 9.75 9.33579 9.75
      9.75V13.5C9.75 13.9142 10.0858 14.25 10.5 14.25C10.9142 14.25 11.25
      13.9142 11.25 13.5V9.75ZM8.25 12C8.25 11.5858 7.91421 11.25 7.5
      11.25C7.08579 11.25 6.75 11.5858 6.75 12V13.5C6.75 13.9142 7.08579
      14.25 7.5 14.25C7.91421 14.25 8.25 13.9142 8.25 13.5V12Z"
        fill={
          darkModeQuery.matches ? themes["dark"].text : themes["light"].text
        }
      />
    </svg>
  );
};

const TooltipTriangleDown = () => {
  const darkModeQuery = window.matchMedia("(prefers-color-scheme: dark)");
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="9"
      height="4"
      viewBox="0 0 9 4"
      fill="none"
    >
      <path
        d="M4.5 4L8.5 0H0.5L4.5 4Z"
        fill={
          darkModeQuery.matches ? themes["dark"].text : themes["light"].text
        }
      />
    </svg>
  );
};

const TooltipTriangleUp = () => {
  const darkModeQuery = window.matchMedia("(prefers-color-scheme: dark)");
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="9"
      height="4"
      viewBox="0 0 9 4"
      fill="none"
    >
      <path
        d="M4.5 -3.49691e-07L0.5 4L8.5 4L4.5 -3.49691e-07Z"
        fill={
          darkModeQuery.matches ? themes["dark"].text : themes["light"].text
        }
      />
    </svg>
  );
};

const TagGoldIcon = () => {
  return (
    <svg
      width="30"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.5 3C4.11929 3 3 4.11929 3 5.5V8.37868C3 9.04172 3.26339 9.67761
        3.73223 10.1464L10.2322 16.6464C11.2085 17.6228 12.7915 17.6228 13.7678
         16.6464L16.6464 13.7678C17.6228 12.7915 17.6228 11.2085 16.6464
          10.2322L10.1464 3.73223C9.67761 3.26339 9.04172 3 8.37868 3H5.5ZM6
           7C6.55228 7 7 6.55228 7 6C7 5.44772 6.55228 5 6 5C5.44772 5 5
           5.44772 5 6C5 6.55228 5.44772 7 6 7Z"
        fill="url(#paint0_linear_97_15169)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_97_15169"
          x1="3"
          y1="10.1893"
          x2="17.3787"
          y2="10.1893"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF6625" />
          <stop offset="0.509829" stopColor="#FB9700" />
          <stop offset="0.997383" stopColor="#F9D849" />
        </linearGradient>
      </defs>
    </svg>
  );
};

const RocketBetaIcon = () => {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.60597 13.4691C4.85946 13.7967 4.79938 14.2678 4.47179
        14.5213C3.87925 14.9798 3.5 15.6954 3.5 16.5C3.5 16.6569 3.51437
        16.81 3.54177 16.9582C3.68996 16.9856 3.84307 17 4 17C4.80458
        17 5.52023 16.6208 5.97873 16.0282C6.23222 15.7006 6.70328 15.6405
        7.03087 15.894C7.35846 16.1475 7.41854 16.6186 7.16505 16.9462C6.4347
        17.89 5.2884 18.5 4 18.5C3.54003 18.5 3.09667 18.4221 2.6834
         18.278C2.46722 18.2027 2.29728 18.0328 2.22195 17.8166C2.07794
         17.4033 2 16.96 2 16.5C2 15.2116 2.60997 14.0653 3.55383
         13.335C3.88142 13.0815 4.35248 13.1415 4.60597 13.4691Z"
        fill="url(#paint0_linear_2645_939)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.75236 12.5C6.40714 13.3376 7.16237 14.0929 8 14.7476V18.75C8
        19.1642 8.33579 19.5 8.75 19.5C11.5114 19.5 13.75 17.2614 13.75
        14.5C13.75 14.0097 13.6792 13.535 13.5471 13.0861C16.8467 10.7297 19
        6.86651 19 2.5C19 2.41234 18.9991 2.32486 18.9974 2.23759C18.9894
        1.83502 18.665 1.51058 18.2624 1.5026C18.1751 1.50087 18.0877 1.5 18
        1.5C13.6335 1.5 9.77032 3.65331 7.41387 6.95291C6.96496 6.82078 6.49028
         6.75 6 6.75C3.23858 6.75 1 8.98858 1 11.75C1 12.1642 1.33579 12.5 1.75
         12.5H5.75236ZM13 9.5C14.1046 9.5 15 8.60457 15 7.5C15 6.39543 14.1046
         5.5 13 5.5C11.8954 5.5 11 6.39543 11 7.5C11 8.60457 11.8954 9.5 13
         9.5Z"
        fill="url(#paint1_linear_2645_939)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_2645_939"
          x1="1"
          y1="10.5"
          x2="19"
          y2="10.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.181698" stopColor="#6A2EF0" />
          <stop offset="0.488995" stopColor="#4361EE" />
          <stop offset="0.997383" stopColor="#0BDB6B" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_2645_939"
          x1="1"
          y1="10.5"
          x2="19"
          y2="10.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.181698" stopColor="#6A2EF0" />
          <stop offset="0.488995" stopColor="#4361EE" />
          <stop offset="0.997383" stopColor="#0BDB6B" />
        </linearGradient>
      </defs>
    </svg>
  );
};

const MailBetaIcon = () => {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.10557 6.94721C1.428 7.286 1 7.97852 1 8.73607V16.5C1 17.6046
        1.89543 18.5 3 18.5H17C18.1046 18.5 19 17.6046 19 16.5V8.73607C19
        7.97852 18.572 7.286 17.8944 6.94722L10.8944 3.44721C10.3314 3.16569
        9.66863 3.16569 9.10557 3.44721L2.10557 6.94721ZM3.58541
        10.9542C3.21493 10.7689 2.76442 10.9191 2.57918 11.2896C2.39394
        11.6601 2.54411 12.1106 2.91459 12.2958L8.77016 15.2236C9.54436
        15.6107 10.4556 15.6107 11.2298 15.2236L17.0823 12.2974C17.4528
        12.1121 17.6029 11.6616 17.4177 11.2912C17.2325 10.9207 16.7819
        10.7705 16.4115 10.9557L10.559 13.882C10.2071 14.0579 9.79289
        14.0579 9.44098 13.882L3.58541 10.9542Z"
        fill="url(#paint0_linear_2645_678)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_2645_678"
          x1="1"
          y1="10.868"
          x2="19"
          y2="10.868"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.181698" stopColor="#6A2EF0" />
          <stop offset="0.488995" stopColor="#4361EE" />
          <stop offset="0.997383" stopColor="#0BDB6B" />
        </linearGradient>
      </defs>
    </svg>
  );
};

const BellBetaIcon = () => {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.21444 3.72682C4.47824 3.40747 4.43321 2.93474 4.11386
        2.67094C3.79452 2.40714 3.32178 2.45217 3.05798 2.77151C2.1587
        3.86014 1.50992 5.16493 1.20153 6.59703C1.11433 7.00196 1.37191
        7.40091 1.77684 7.48811C2.18177 7.57531 2.58072 7.31774 2.66792
        6.91281C2.92443 5.72161 3.46427 4.63494 4.21444 3.72682Z"
        fill="url(#paint0_linear_2645_757)"
      />
      <path
        d="M16.9417 2.77151C16.6779 2.45217 16.2051 2.40714 15.8858
        2.67094C15.5664 2.93474 15.5214 3.40747 15.7852 3.72682C16.5354
        4.63494 17.0752 5.72161 17.3317 6.91281C17.4189 7.31774 17.8179
        7.57531 18.2228 7.48811C18.6277 7.40091 18.8853 7.00196 18.7981
        6.59703C18.4897 5.16493 17.8409 3.86014 16.9417 2.77151Z"
        fill="url(#paint1_linear_2645_757)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.99997 2.5C6.68626 2.5 3.99997 5.18629 3.99997 8.5C3.99997
        10.3866 3.54624 12.1651 2.7426 13.7343C2.63591 13.9426 2.6326
        14.1888 2.73365 14.4C2.83469 14.6111 3.02851 14.7629 3.25769
        14.8105C4.32537 15.0322 5.41181 15.2023 6.51426 15.318C6.67494
        17.102 8.17421 18.5 10 18.5C11.8258 18.5 13.3251 17.102 13.4857
        15.318C14.5882 15.2023 15.6746 15.0322 16.7422 14.8105C16.9714
        14.7629 17.1652 14.6111 17.2663 14.4C17.3673 14.1888 17.364 13.9426
        17.2573 13.7343C16.4537 12.1651 16 10.3866 16 8.5C16 5.18629 13.3137
        2.5 9.99997 2.5ZM10 17C9.04777 17 8.25097 16.3345 8.0493
        15.4433C8.69477 15.4809 9.34517 15.5 9.99997 15.5C10.6548 15.5
        11.3052 15.4809 11.9507 15.4433C11.749 16.3345 10.9522 17 10 17Z"
        fill="url(#paint2_linear_2645_757)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_2645_757"
          x1="1.18457"
          y1="10.4996"
          x2="18.8151"
          y2="10.4996"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.181698" stopColor="#6A2EF0" />
          <stop offset="0.488995" stopColor="#4361EE" />
          <stop offset="0.997383" stopColor="#0BDB6B" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_2645_757"
          x1="1.18457"
          y1="10.4996"
          x2="18.8151"
          y2="10.4996"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.181698" stopColor="#6A2EF0" />
          <stop offset="0.488995" stopColor="#4361EE" />
          <stop offset="0.997383" stopColor="#0BDB6B" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_2645_757"
          x1="1.18457"
          y1="10.4996"
          x2="18.8151"
          y2="10.4996"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.181698" stopColor="#6A2EF0" />
          <stop offset="0.488995" stopColor="#4361EE" />
          <stop offset="0.997383" stopColor="#0BDB6B" />
        </linearGradient>
      </defs>
    </svg>
  );
};

const CardBetaIcon = () => {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.5 4.5C1.67157 4.5 1 5.17157 1 6V6.5H19V6C19 5.17157 18.3284
        4.5 17.5 4.5H2.5ZM19 9H1V15C1 15.8284 1.67157 16.5 2.5
        16.5H17.5C18.3284 16.5 19 15.8284 19 15V9ZM3 13.75C3 13.3358 3.33579
        13 3.75 13H5.25C5.66421 13 6 13.3358 6 13.75C6 14.1642 5.66421 14.5
        5.25 14.5H3.75C3.33579 14.5 3 14.1642 3 13.75ZM7.75 13C7.33579 13 7
        13.3358 7 13.75C7 14.1642 7.33579 14.5 7.75 14.5H11.25C11.6642 14.5
        12 14.1642 12 13.75C12 13.3358 11.6642 13 11.25 13H7.75Z"
        fill="url(#paint0_linear_2645_639)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_2645_639"
          x1="1"
          y1="10.5"
          x2="19"
          y2="10.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.181698" stopColor="#6A2EF0" />
          <stop offset="0.488995" stopColor="#4361EE" />
          <stop offset="0.997383" stopColor="#0BDB6B" />
        </linearGradient>
      </defs>
    </svg>
  );
};
const TagBetaIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.5 3C4.11929 3 3 4.11929 3 5.5V8.37868C3 9.04172 3.26339
        9.67761 3.73223 10.1464L10.2322 16.6464C11.2085 17.6228 12.7915
        17.6228 13.7678 16.6464L16.6464 13.7678C17.6228 12.7915 17.6228
        11.2085 16.6464 10.2322L10.1464 3.73223C9.67761 3.26339 9.04172 3
        8.37868 3H5.5ZM6 7C6.55228 7 7 6.55228 7 6C7 5.44772 6.55228 5 6
        5C5.44772 5 5 5.44772 5 6C5 6.55228 5.44772 7 6 7Z"
        fill="url(#paint0_linear_5_6866)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_5_6866"
          x1="3"
          y1="10.1893"
          x2="17.3787"
          y2="10.1893"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.181698" stopColor="#6A2EF0" />
          <stop offset="0.488995" stopColor="#4361EE" />
          <stop offset="0.997383" stopColor="#0BDB6B" />
        </linearGradient>
      </defs>
    </svg>
  );
};

type HiMiniStatusDotProps = {
  color?: string;
};

const HiMiniStatusDot = ({ color = "#84DB48" }: HiMiniStatusDotProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <circle cx="10" cy="10" r="3" fill={color} />
    </svg>
  );
};

type HiVennProps = {
  color?: string;
};

const HiVenn = ({ color = "#FFFFFF" }: HiVennProps) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="8"
        cy="12"
        r="6.25"
        stroke={color}
        strokeWidth="1.5"
      />
      <circle
        cx="16"
        cy="12"
        r="6.25"
        stroke={color}
        strokeWidth="1.5"
      />
    </svg>
  );
};

const HiComparator = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="7.75"
        y="7.75"
        width="16.5"
        height="16.5"
        rx="1.25"
        stroke="white"
        strokeWidth="1.5"
      />
      <path
        d="M22 19L18.5 16L22 13"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10 13L13.5 16L10 19"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <line
        x1="16"
        y1="5.75"
        x2="16"
        y2="26.25"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
};

const HiVennAND = ({ color = "#FFFFFF" }: HiVennProps) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="8"
        cy="12"
        r="6.25"
        stroke={color}
        strokeWidth="1.5"
      />
      <circle
        cx="16"
        cy="12"
        r="6.25"
        stroke={color}
        strokeWidth="1.5"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 17.7455C13.8135 16.4806 15 14.3789 15
          12.0002C15 9.62145 13.8135 7.51983 12
          6.25488C10.1865 7.51983 9 9.62145 9 12.0002C9
          14.3789 10.1865 16.4806 12 17.7455Z"
        fill={color}
      />
    </svg>
  );
};

const HiVennOR = ({ color = "#FFFFFF" }: HiVennProps) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="8" cy="12" r="6.25" stroke={color} strokeWidth="1.5"/>
      <circle cx="16" cy="12" r="6.25" stroke={color} strokeWidth="1.5"/>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 17.7453C10.8662 18.5362 9.48725 19 8 19C4.13401
        19 1 15.866 1 12C1 8.13401 4.13401 5 8 5C9.48725 5
        10.8662 5.46381 12 6.25469C13.1338 5.46381 14.5128 5
        16 5C19.866 5 23 8.13401 23 12C23 15.866 19.866 19 16
        19C14.5128 19 13.1338 18.5362 12 17.7453Z"
        fill={color}
      />
    </svg>
  );
};

const HiVennNOT = ({ color = "#FFFFFF" }: HiVennProps) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="8"
        cy="12"
        r="6.25"
        stroke={color}
        strokeWidth="1.5"
      />
      <circle
        cx="16"
        cy="12"
        r="6.25"
        stroke={color}
        strokeWidth="1.5"
      />
    </svg>
  );
};

const HiGreaterThan = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.59998 7.2002L16.8 12.0002L9.59998 16.8002"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

const HiGreaterThanEqual = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.59998 4.2002L16.8 9.0002C16.8
        9.0002 12.4118 11.9257 9.59998 13.8002"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.8 14.3999L9.60005 19.1999"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
};

const HiLessThan = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.4 16.7998L7.20003 11.9998L14.4 7.19981"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

const HiLessThanEqual = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.4 13.7998L7.20003 8.9998L14.4 4.19981"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.4 19.2002L7.20002 14.4002"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
};

const HiEqual = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.8 14.3999L7.20005 14.3999"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M16.8 9.6001L7.20005 9.6001"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
};

const CrossVector = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 15C4.0837 15 6.72621 12.4265 9.23539 9.47469M9.23539
      9.47469C9.59385 9.05301 9.94958 8.62362 10.3064
      8.1929M9.23539 9.47469C9.50857 9.15333 9.78016 8.82749
      10.0519 8.5C10.1367 8.39776 10.2215 8.29537 10.3064
      8.1929M10.3064 8.1929C12.9566 4.99388 15.6671 1.72215 20
      1M0 1C4.0837 1 6.72621 3.57355 9.23539 6.52531M9.23539
      6.52531C9.59385 6.94699 9.94958 7.37638 10.3064
      7.8071M9.23539 6.52531C9.50857 6.84667 9.78016 7.17251
      10.0519 7.5C10.1367 7.60224 10.2215 7.70463 10.3064
      7.8071M10.3064 7.8071C12.9566 11.0061 15.6671 14.2778
      20 15"
      stroke="white"
    />
  </svg>
);

const CrossAboveVector = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.0519 8H0H20H10.0519ZM10.0519 8C7.28451
      11.3356 4.52829 14.5 0 14.5C9 14.5 11 2 20
      0.5C15.5283 1.24528 12.7846 4.7061 10.0519 8Z"
      stroke="white"
    />
  </svg>
);

const CrossUnderVector = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.0519 7.5H0H20H10.0519ZM10.0519 7.5C7.28451
      4.16442 4.52829 1 0 1C9 1 11 13.5 20 15C15.5283 14.2547
      12.7846 10.7939 10.0519 7.5Z"
      stroke="white"
    />
  </svg>
);

export {
  CupAndHandle,
  CupAndHandleRevert,
  DoubleBottom,
  DoubleTop,
  HeadAndShoulders,
  InverseHeadAndShoulders,
  Pennant,
  RoundingBottom,
  RoundingTop,
  TripleBottom,
  TripleTop,
  AppLogo,
  TagGoldIcon,
  MobileAppLogo,
  PoweredBySigmaDark,
  HiBellPlus,
  TelegramIcon,
  MagnifyingGlassChart,
  TooltipTriangleDown,
  TooltipTriangleUp,
  HiMiniStatusDot,
  RocketBetaIcon,
  MailBetaIcon,
  BellBetaIcon,
  CardBetaIcon,
  TagBetaIcon,
  HiVenn,
  HiComparator,
  HiVennAND,
  HiVennOR,
  HiVennNOT,
  HiGreaterThan,
  HiGreaterThanEqual,
  HiLessThan,
  HiLessThanEqual,
  HiEqual,
  CrossVector,
  CrossAboveVector,
  CrossUnderVector,
};
