import { LineData, Time } from "lightweight-charts";
import { transformTsIntervalToTime } from "@components/chart/Chart.helpers";
import type { TAResponseV1 } from "@generated/ta/technical_analysis";
import Long from "long";
import { MacdChartState } from "./state";
import {
  addHueOffsetToColor,
  getChartIndicatorColor,
  hueOffsetFromIndex,
} from "../helpers/colors/utils";
import { BandsIndicator } from "./plugin/band-indicator";

const removeSeries = (state: MacdChartState) => {
  for (const series of state.currentLineSeries) {
    try {
      state.lwChart.removeSeries(series);
    } catch (e) {
      console.warn("Attempted to remove series that doesn't exist");
    }
  }
};

export const updateTechnicals = (
  state: MacdChartState,
  technicals: TAResponseV1 | null
) => {
  if (!technicals) {
    return;
  }

  removeSeries(state);

  const color = getChartIndicatorColor("MACD", state.theme);

  state.currentLineSeries = technicals.results
    .filter(shouldRenderTechnical)
    .map((result, index) => {
      const series = state.lwChart.addLineSeries({
        color: addHueOffsetToColor(color, hueOffsetFromIndex(index)),
        priceLineVisible: false,
        lastValueVisible: false,
        lineWidth: state.theme.chart.patterns.lineWidth,
      });

      const seriesData = getTechnicalSeriesData(technicals, result);
      series.setData(seriesData);

      const macdBand = new BandsIndicator();
      series.attachPrimitive(macdBand);

      return series;
    });
};

const shouldRenderTechnical = (technical: TAResponseV1["results"][number]) => {
  if (technical.macd) {
    return true;
  }
};

const getTechnicalSeriesData = (
  technicals: TAResponseV1,
  technical: TAResponseV1["results"][number]
): LineData<Time>[] => {
  return technical.values
    .map((val, idx) => ({
      time: createLong(technicals.candles[idx].ts_interval),
      value: val,
    }))
    .sort((a, b) => Number(a.time.sub(b.time)))
    .map((datum) => ({
      time: transformTsIntervalToTime(datum.time.toNumber()),
      value: datum.value,
    }));
};

const createLong = (input: Long) => {
  return new Long(input.low, input.high, input.unsigned);
};
