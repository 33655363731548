import { effectStyles } from "@client/src/utils/effectStyles";
import { textStyles } from "@client/src/utils/textStyles";
import { baseStyle } from "@client/src/utils/textStyles";
import styled, { keyframes } from "styled-components";

// Slide-in animation from the right
const slideInRight = keyframes`
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
`;

export const HeaderSection = styled.div`
  display: flex;
  gap: 0.5rem;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;

export const Container = styled.div`
  ${baseStyle};
  z-index: 100;
  display: flex;
  width: 30rem;
  height: 100vh;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  flex-shrink: 0;
  background-color: ${(props) => props.theme.background};
  position: fixed;
  top: 0;
  right: 0;
  animation: ${slideInRight} 0.3s ease-out;
  ${effectStyles.grey.shadow2Xl}
`;

export const Header = styled.div`
  box-sizing: border-box;
  display: flex;
  padding: 1.5rem;
  flex-direction: row;
  width: 100%;
  gap: 1rem;
  align-items: center;
  justify-content: space-between;
`;

export const HeaderTitle = styled.div`
  ${textStyles.textXlBold}
`;

export const HeaderIcon = styled.div`
  ${textStyles.textXlBold}
  box-sizing: border-box;
  height: 1.25rem;
  width: 1.25rem;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

export const Content = styled.div`
  align-items: center;
  justify-content: space-between;
  overflow-y: auto;
  width: 100%;
`;
