import {
  IChartApi,
  ISeriesApi,
  MouseEventParams,
  Time,
} from "lightweight-charts";
import { getCrosshairDataPoint, syncCrosshair } from "./crosshairs";

type ChartSyncHandler = {
  register: (
    chart: IChartApi,
    getMainSeries: SeriesGetter,
  ) => void,
  unregister: (chart: IChartApi) => void,
}

type ChartRegistration = {
  chart: IChartApi,
  getMainSeries: SeriesGetter,
}

type HandlerState = {
  registeredCharts: ChartRegistration[],
}

type SeriesGetter = () => ISeriesApi<"Candlestick" | "Line">;

let chartSyncHandlerInstance: ChartSyncHandler | null = null;

export const getChartSyncHandler = () => {
  if (!chartSyncHandlerInstance) {
    chartSyncHandlerInstance = createGlobalChartHandler();
  }

  return chartSyncHandlerInstance;
};

const createGlobalChartHandler = (): ChartSyncHandler => {
  const state: HandlerState = { registeredCharts: [] as ChartRegistration[] };

  return {

    register: (chart, getMainSeries) => {
      state.registeredCharts.push({ chart, getMainSeries });

      addCrosshairSubscription(chart, state, getMainSeries);
      addTimescaleSubscription(chart, state);
    },

    unregister: (chart) => {
      state.registeredCharts = state.registeredCharts.filter(
        (registration) => registration.chart !== chart,
      );
    },

  };
};

const addCrosshairSubscription = (
  chart: IChartApi,
  state: HandlerState,
  getMainSeries: () => ISeriesApi<"Candlestick" | "Line">,
) => {
  chart.subscribeCrosshairMove((param: MouseEventParams<Time>) => {
    state.registeredCharts.forEach((registration) => {
      if (chart !== registration.chart) {
        const dataPoint = getCrosshairDataPoint(getMainSeries(), param);
        if (!dataPoint) return;
        syncCrosshair(
          registration.chart,
          registration.getMainSeries(),
          dataPoint
        );
      }
    });
  });
};

const addTimescaleSubscription = (chart: IChartApi, state: HandlerState) => {
  chart.timeScale().subscribeVisibleLogicalRangeChange(timeRange => {
    state.registeredCharts.forEach((registration) => {
      if (chart !== registration.chart && timeRange) {
        registration.chart.timeScale().setVisibleLogicalRange(timeRange);
      }
    });
  });
};
