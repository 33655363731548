import { TelegramIcon } from "@assets/Icons";
import { useAuth0 } from "@auth0/auth0-react";
import Button from "@components/button/Button.component";
import { Modal } from "@components/modal/Modal.component";
import Section from "@components/settings/section/Section.component";
// eslint-disable-next-line max-len
import SettingsButton from "@components/settings/settings_button/SettingsButton.component";
// eslint-disable-next-line max-len
import Toggle from "@components/toggle/Toggle.component";
import { getSafeBoolean } from "@utils/getSafe";
import React, { useCallback, useEffect, useState } from "react";
import { HiEnvelope, HiGlobeAlt } from "react-icons/hi2";
import { useTheme } from "styled-components";
import {
  AlertPreferencesData,
  useUserContext,
} from "../../context/user/UserContext";
import { LoadingIndicator } from "./AlertSettings.style";
const AlertsSettings = () => {
  const theme = useTheme();
  const { getAccessTokenSilently } = useAuth0();
  const {
    alertPreferenceData,
    applyAlertPreferencesData,
    applyNetworkAlertPreferences,
  } = useUserContext();

  const [showTelegramModal, setShowTelegramModal] = useState(false);
  const [telegramChatId, setTelegramChatId] = useState("");
  const [inputError, setInputError] = useState("");
  const [webEnabled, setWebEnabled] = useState(
    getSafeBoolean(alertPreferenceData?.webEnabled)
  );
  const [emailEnabled, setEmailEnabled] = useState(
    getSafeBoolean(alertPreferenceData?.emailEnabled)
  );
  const [telegramEnabled, setTelegramEnabled] = useState(
    getSafeBoolean(alertPreferenceData?.telegramEnabled)
  );

  useEffect(() => {
    setWebEnabled(getSafeBoolean(alertPreferenceData?.webEnabled));
    setEmailEnabled(getSafeBoolean(alertPreferenceData?.emailEnabled));
    setTelegramEnabled(getSafeBoolean(alertPreferenceData?.telegramEnabled));
  }, [alertPreferenceData]);

  const toggleWeb = async (newValue: boolean) => {
    const newPreferences: AlertPreferencesData = {
      ...alertPreferenceData,
      webEnabled: newValue,
    } as AlertPreferencesData;
    applyAlertPreferencesData(newPreferences);
    await applyNetworkAlertPreferences("web", newValue ? "enable" : "disable");
  };

  const toggleEmail = async (newValue: boolean) => {
    const newPreferences: AlertPreferencesData = {
      ...alertPreferenceData,
      emailEnabled: newValue,
    } as AlertPreferencesData;
    applyAlertPreferencesData(newPreferences);
    await applyNetworkAlertPreferences(
      "email",
      newValue ? "enable" : "disable"
    );
  };

  const toggleTelegram = async (newValue: boolean) => {
    const newPreferences: AlertPreferencesData = {
      ...alertPreferenceData,
      telegramEnabled: newValue,
    } as AlertPreferencesData;
    applyAlertPreferencesData(newPreferences);
    await applyNetworkAlertPreferences(
      "telegram",
      newValue ? "enable" : "disable"
    );
    setShowTelegramModal(newValue);
  };

  const closeTelegramModal = useCallback(() => {
    setShowTelegramModal(false);
  }, []);

  const handleTelegramLink = useCallback(() => {
    getAccessTokenSilently().then((token: string) => {
      fetch("/api/alerts/telegram-link", {
        method: "POST",
        body: JSON.stringify({ chatId: telegramChatId }),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }).then((res) => {
        if (res.status === 200) {
          closeTelegramModal();
        } else {
          setInputError("Invalid chat ID");
        }
      });
    });
  }, [telegramChatId]);

  return (
    <>
      <Section title="">
        <SettingsButton
          onClick={() => null}
          leftIcon={<HiGlobeAlt />}
          text="Browser"
          rightIcon={
            alertPreferenceData ? (
              <Toggle
                checked={webEnabled}
                checkedColour={theme.chart.candles.upColor}
                onChange={toggleWeb}
              />
            ) : (
              <LoadingIndicator />
            )
          }
        />
        <SettingsButton
          onClick={() => null}
          leftIcon={<HiEnvelope />}
          text="Email"
          rightIcon={
            alertPreferenceData ? (
              <Toggle
                checked={emailEnabled}
                checkedColour={theme.chart.candles.upColor}
                onChange={toggleEmail}
              />
            ) : (
              <LoadingIndicator />
            )
          }
        />
        <SettingsButton
          onClick={() => null}
          leftIcon={<TelegramIcon />}
          text="Telegram"
          rightIcon={
            alertPreferenceData ? (
              <Toggle
                checked={telegramEnabled}
                checkedColour={theme.chart.candles.upColor}
                onChange={toggleTelegram}
              />
            ) : (
              <LoadingIndicator />
            )
          }
        />
      </Section>
      <Modal
        modalImage={<TelegramIcon />}
        modalTitle="Connect to Telegram"
        modalDescription={`
          1. On Telegram, find @runleopard_bot\n
          2. Press start to get your chat ID\n
          3. Paste your unique chat ID here\n
        `}
        placeholderInput="Enter your Telegram Chat ID"
        onInputChange={(e) => setTelegramChatId(e.target.value)}
        inputError={inputError}
        Button1={
          <Button text="Connect" type="primary" onClick={handleTelegramLink} />
        }
        Button2={
          <Button text="Cancel" type="secondary" onClick={closeTelegramModal} />
        }
        isVisible={showTelegramModal}
      />
    </>
  );
};

export default AlertsSettings;
