import styled from "styled-components";

export const InboxSectionContainer = styled.div`
  padding: 1rem;
  padding-bottom: 2rem;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
`;
export const TopRightContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;
export const AlertToggleContainer = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
`;
