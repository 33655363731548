import { createChart } from "lightweight-charts";
import { applyDefaultChartOptions } from "../helpers/options";
import { RsiChartParams } from "./state";

export const getChartOptions = (
  params: RsiChartParams,
): Parameters<typeof createChart>[1] => {
  return applyDefaultChartOptions(params, {
    width: params.elem.clientWidth,
    height: params.elem.clientHeight,
  });
};
