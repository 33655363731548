import { textStyles } from "@utils/textStyles";
import styled from "styled-components";

export const GridContainer = styled.div`
  background: ${(props) => props.theme.planConfig.darkBackground};
  display: grid;
  width: 100%;
  grid-template-columns: repeat(5, 1fr);
  border-radius: 0.5rem;
`;

export const Container = styled.div`
  display: flex;
  gap: 1rem;
  width: 100%;
  align-items: flex-start;
  flex-direction: column;
`;

export const Title = styled.p`
  ${textStyles.textLgBold};
  margin: 0;
`;

export const ComingSoonText = styled.p`
  margin: 0;
  ${textStyles.textSm};
  color: ${(props) => props.theme.planConfig.comingSoonColor};
`;
export const GridColumn = styled.div`
  ${textStyles.textBaseBold};
  padding: 1rem 0;
  justify-self: center;
`;
export const GridItem = styled.div`
  display: flex;
  gap: 0.5rem;
  ${textStyles.textBase};
  padding: 1rem 0.8rem;
  color: ${(props) => props.theme.planConfig.tertiaryText};
`;
