import { effectStyles } from "@client/src/utils/effectStyles";
import { slideInBottom } from "@utils/animations";
import { textStyles } from "@utils/textStyles";
import styled from "styled-components";

const Dialog = styled.div<{ $fullScreen?: boolean }>`
  background-color: ${(props) => props.theme.instrumentDialogBg};
  z-index: 1000;
  min-height: ${(props) => (props.$fullScreen ? "100%" : "initial")};
  height: ${(props) => (props.$fullScreen ? "100%" : "65%")};
  width: ${(props) => (props.$fullScreen ? "100%" : "50%")};
  border-radius: 0.5rem;
  padding: 1rem;
  gap: 1rem;
  display: flex;
  flex-direction: column;
  ${effectStyles.grey.shadowXl};
  @media (max-width: 768px) {
    animation: ${slideInBottom} 0.3s ease-out;
    padding: 1rem;
    border-radius: ${(props) => (
    props.$fullScreen ? "0" : "0.5rem 0.5rem 0 0")};
    bottom: 0;
    height: ${(props) => (props.$fullScreen ? "100%" : "90%")};
    width: 100%;
  }
`;

const TitleContainer = styled.div`
  display: flex;
  gap: 0.5rem;
`;

const CloseButton = styled.div`
  cursor: pointer;
  height: 1rem;
  width: 1rem;
  color: white;
`;

const DialogContainer = styled.div`
  background-color: ${(props) => props.theme.dialogFloatingBg};
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 768px) {
    align-items: flex-end;
  }
`;

const DialogHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const Title = styled.span`
  ${textStyles.textXlBold};
`;

const ItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 1rem;
  padding: 1rem;
  background-color: ${(props) => props.theme.secondaryBackground};
  border-radius: 0.5rem;
`;

const Item = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export {
  DialogContainer,
  TitleContainer,
  Dialog,
  DialogHeader,
  Title,
  CloseButton,
  ItemContainer,
  Item,
};
