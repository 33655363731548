// eslint-disable-next-line max-len
import ConditionBuilder from "@client/src/components/condition_builder/ConditionBuilder.component";
import Footer from "@client/src/components/footer/Footer.component";
import Navbar from "@client/src/components/navbar/Navbar.component";
// eslint-disable-next-line max-len
import { useConditionBuilderContext } from "@client/src/context/ConditionBuilderContext";
import { useScreeningContext } from "@client/src/context/ScreeningContext";
import { getSafeNumber, getSafeString } from "@client/src/utils/getSafe";
import { Portfolio } from "@common/types";
import Button from "@components/button/Button.component";
import {
  CloseButton,
  Dialog,
  DialogContainer,
  DialogHeader,
} from "@components/dialog/Dialog.style";
// eslint-disable-next-line max-len
import {
  AddToPortfolioDialog,
  CreatePortfolioDialog,
  EditPortfolioDialog,
  PortfolioListDialog,
} from "@components/portfolio_dialogs/PortfolioDialogProvider";
// eslint-disable-next-line max-len
import ScreeningFilterContainer from "@components/screening_filter_container/ScreeningFilterContainer.component";
// eslint-disable-next-line max-len
import ScreeningResultTable, {
  getResultsWithPriceAndMidnight,
} from "@components/screening_result_table/ScreeningResultTable.component";
import { Instrument } from "@generated/common/basic-types_pb";
// eslint-disable-next-line max-len
import { getPatternsAndTechnicals } from "@utils/condition_builder_utils/dataHandler";
import React, { useCallback, useEffect, useState } from "react";
import { HiXMark } from "react-icons/hi2";
import { useAppContext } from "../../context/AppContext";
import { usePortfolioContext } from "../../context/PortfolioContext";
import {
  BottomToolContainer,
  Header,
  ScreeningContainer,
  ScreeningPageContainer,
  Title,
  ToolItemContainer,
} from "./Screening.style";

const CreatePortfolio = ({
  show,
  instruments,
  selectedHashcodes,
  setShowCreatePortfolioDialog,
  setSelectedHashcodes,
}: {
  show: boolean;
  instruments: Instrument.AsObject[];
  selectedHashcodes: string[];
  setShowCreatePortfolioDialog: (show: boolean) => void;
  setSelectedHashcodes: (hashcodes: string[]) => void;
}) => {
  if (!show) return;
  return (
    <CreatePortfolioDialog
      instruments={
        selectedHashcodes
          .map((hashcode) => {
            return instruments.find(
              (instrument) => instrument.hashcode === hashcode
            );
          })
          .filter(
            (instrument) => instrument !== undefined
          ) as Instrument.AsObject[]
      }
      onClickCreate={() => {
        setShowCreatePortfolioDialog(false);
        setSelectedHashcodes([]);
      }}
    />
  );
};

const Screening = () => {
  const {
    screeningResults,
    showConditionBuilder,
    setShowConditionBuilder,
    setCondition,
    midnightChange,
    latestCandles,
    instruments,
    setSelectedHashcodes,
    selectedHashcodes,
    selectedPortfolio,
    setSelectedPortfolio,
  } = useScreeningContext();
  const {
    showAddToPortfolioDialog,
    setShowAddToPortfolioDialog,
    showPortfolioListDialog,
    setShowCreatePortfolioDialog,
    showCreatePortfolioDialog,
    showEditPortfolioDialog,
  } = usePortfolioContext();
  const { sigmaScript } = useConditionBuilderContext();
  const [selectedInstruments, setSelectedInstruments] = useState<
    Instrument.AsObject[]
  >([]);
  const { setChartMetadata } = useAppContext();
  const [filteredInstruments, setFilteredInstruments] =
    useState<Instrument.AsObject[]>(instruments);

  useEffect(() => {
    document.title = "Screening";
  }, []);
  useEffect(() => {
    if (instruments.length > 0) {
      setSelectedInstruments(instruments);
    }
  }, [instruments]);

  const handleFilterChange = (filteredInstruments: Instrument.AsObject[]) => {
    return setFilteredInstruments(filteredInstruments);
  };

  useEffect(() => {
    if (!selectedPortfolio) return;
    const updatedInstruments: Instrument.AsObject[] = [];
    instruments.reduce((acc, instrument) => {
      if (selectedPortfolio.hashcodes.includes(instrument.hashcode)) {
        acc.push(instrument);
      }
      return acc;
    }, updatedInstruments);
    setSelectedInstruments(updatedInstruments);
  }, [selectedPortfolio]);

  const handleSelectPortfolio = useCallback(
    (portfolio: Portfolio) => {
      setSelectedPortfolio(portfolio);
    },
    [instruments]
  );

  const renderConditionBuilder = () => {
    if (!showConditionBuilder) return null;
    return (
      <DialogContainer>
        <Dialog $fullScreen={true}>
          <DialogHeader>
            <Title>Condition builder</Title>
            <CloseButton
              onClick={() => {
                setShowConditionBuilder(false);
              }}
            >
              <HiXMark />
            </CloseButton>
          </DialogHeader>
          <ConditionBuilder
            onSave={() => {
              setShowConditionBuilder(false);
              setCondition(getSafeString(sigmaScript));
              setChartMetadata(
                getPatternsAndTechnicals(getSafeString(sigmaScript))
              );
            }}
          />
        </Dialog>
      </DialogContainer>
    );
  };

  const getResults = () => {
    const results = filteredInstruments.map((instrument) => {
      const result = screeningResults.find(
        (result) => result.symbol === instrument.symbol
      );
      return {
        symbol: instrument.symbol,
        name: instrument.name,
        class: getSafeString(instrument.instrumentClass.toString()),
        price: getSafeString(result?.price),
        lastMatch: getSafeString(result?.lastMatch),
        count: getSafeNumber(result?.count),
        hashcode: instrument.hashcode,
      };
    });
    return getResultsWithPriceAndMidnight(
      results,
      latestCandles,
      midnightChange
    );
  };

  const getCreatePortfolioText =
    () => `Add to Portfolio (${selectedHashcodes.length} instruments
    selected
  )`;

  return (
    <ScreeningPageContainer>
      <Navbar />
      <ScreeningContainer>
        <Header>
          <Title>Screening</Title>
        </Header>
        <ScreeningFilterContainer
          instrumentList={selectedInstruments}
          onFilterChange={handleFilterChange}
        />
        <ScreeningResultTable
          results={getResults()}
          onChangeSelected={(hashcodes) => setSelectedHashcodes(hashcodes)}
        />
      </ScreeningContainer>
      {renderConditionBuilder()}
      <CreatePortfolio
        instruments={instruments}
        show={showCreatePortfolioDialog}
        selectedHashcodes={selectedHashcodes}
        setShowCreatePortfolioDialog={setShowCreatePortfolioDialog}
        setSelectedHashcodes={setSelectedHashcodes}
      />
      {showEditPortfolioDialog && (
        <EditPortfolioDialog instruments={instruments} />
      )}
      {showAddToPortfolioDialog && (
        <AddToPortfolioDialog
          onSelect={() => {
            setSelectedHashcodes([]);
          }}
          hashcodes={selectedHashcodes}
          onClickCreate={() => setSelectedHashcodes([])}
        />
      )}
      {showPortfolioListDialog && (
        <PortfolioListDialog
          onSelect={(selectedPortfolio) =>
            handleSelectPortfolio(selectedPortfolio)
          }
        />
      )}
      <Footer />
      <BottomToolContainer $visible={selectedHashcodes.length > 0}>
        <ToolItemContainer></ToolItemContainer>
        <ToolItemContainer>
          <Button
            text={getCreatePortfolioText()}
            type={"primary"}
            onClick={() => setShowAddToPortfolioDialog(true)}
          />
          <Button
            text={"Clear selection"}
            type={"secondary"}
            onClick={() => setSelectedHashcodes([])}
          />
        </ToolItemContainer>
      </BottomToolContainer>
    </ScreeningPageContainer>
  );
};

export default Screening;
