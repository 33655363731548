import { HiArrowPath } from "react-icons/hi2";
import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  gap: 1rem;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  flex-direction: row;
  @media (max-width: 768px) {
    overflow-x: auto;
  }
`;
export const LeftSide = styled.div`
  display: flex;
  flex-direction: row;
`;

export const RightSide = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
  min-width: 300px;
`;

export const LoadingIndicator = styled(HiArrowPath)`
  animation: spin 1s linear infinite;

  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }
`;
