import { Instrument } from "@generated/common/basic-types_pb";
import { usePortfolioContext } from "../../context/PortfolioContext";
// eslint-disable-next-line max-len
import { getPatternsAndTechnicals } from "../../utils/condition_builder_utils/dataHandler";
import { getSafeString } from "@utils/getSafe";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useAppContext } from "../../context/AppContext";
import {
  createFuseInstance,
  getInstrumentsToRender,
} from "../instrument_dialog/InstrumentDialog.helpers";
// eslint-disable-next-line max-len
import { ChangeScreeningFilter } from "../screening_filter/filters/change/ChangeScreeningFilter";
// eslint-disable-next-line max-len
import { PriceScreeningFilter } from "../screening_filter/filters/price/PriceScreeningFilter";
// eslint-disable-next-line max-len
import { TypeScreeningFilter } from "../screening_filter/filters/type/TypeScreeningFilter";
import { Searchbar } from "../searchbar/Searchbar.component";
import {
  Container,
  LeftSide,
  RightSide,
  LoadingIndicator,
} from "./ScreeningFilterContainer.style";
import ConditionSelector, {
  Condition,
} from "../condition_selector/ConditionSelector.component";
import { useScreeningContext } from "@client/src/context/ScreeningContext";
// eslint-disable-next-line max-len
import ScreeningFrequencySelector from "../screening_frequency_selector/ScreeningFrequencySelector.component";
import Button from "../button/Button.component";
import { HiListBullet, HiPlay, HiWrench } from "react-icons/hi2";

type ScreeningFilterContainerProps = {
  instrumentList: Instrument.AsObject[];
  onFilterChange: (filteredInstruments: Instrument.AsObject[]) => void;
};

const renderButtonIcon = (loading: string) => {
  if (loading === "true") {
    return <LoadingIndicator />;
  }
  return <HiPlay />;
};

const ScreeningFilterContainer = ({
  instrumentList,
  onFilterChange,
}: ScreeningFilterContainerProps) => {
  const [filteredList, setFilteredList] =
    useState<Instrument.AsObject[]>(instrumentList);
  const { setChartMetadata } = useAppContext();
  const {
    setCondition,
    setFrequency,
    setInstrumentList,
    runScreening,
    enableRunScreening,
    loading,
    setLoading,
    setShowConditionBuilder,
    selectedPortfolio,
    searchQuery,
    setSearchQuery,
    latestCandles,
    midnightChange,
  } = useScreeningContext();
  const { setShowPortfolioListDialog } = usePortfolioContext();
  useEffect(() => {
    onFilterChange(filteredList);
  }, [filteredList]);
  useEffect(() => {
    const newInstruments = getInstrumentsToRender(
      filteredList,
      searchQuery,
      fuseInstance
    );
    onFilterChange(newInstruments);
    setInstrumentList(newInstruments.map((instrument) => instrument.hashcode));
  }, [searchQuery]);
  const fuseInstance = useMemo(() => {
    return createFuseInstance(instrumentList);
  }, [instrumentList]);

  const getPortfolioFilterText = () => {
    const defaultMessage = "Select Portfolio";
    if (!selectedPortfolio) return defaultMessage;
    return selectedPortfolio.name;
  };

  const handleOnFilterChange = useCallback(
    (filteredInstruments: Instrument.AsObject[]) => {
      const newInstruments = getInstrumentsToRender(
        filteredInstruments,
        searchQuery,
        fuseInstance
      );
      setFilteredList(newInstruments);
      setInstrumentList(
        newInstruments.map((instrument) => instrument.hashcode)
      );
    },
    [searchQuery, fuseInstance]
  );

  const handleConditionChange = useCallback((condition: Condition) => {
    if (condition.id === -1) {
      setShowConditionBuilder(true);
    } else {
      setCondition(getSafeString(condition.sigmaScript));
      if (condition.metadata) {
        setChartMetadata(condition.metadata);
      } else {
        setChartMetadata(
          getPatternsAndTechnicals(getSafeString(condition.sigmaScript))
        );
      }
    }
  }, []);

  return (
    <Container>
      <LeftSide>
        <ConditionSelector onChange={handleConditionChange} />
        <TypeScreeningFilter
          key={"type"}
          instrumentList={instrumentList}
          onFilterChange={handleOnFilterChange}
        />
        <ScreeningFrequencySelector
          onChange={(frequency: number) => {
            setFrequency(frequency);
          }}
        />
        <PriceScreeningFilter
          instrumentList={instrumentList}
          onFilterChange={handleOnFilterChange}
          latestCandles={latestCandles}
        />
        <ChangeScreeningFilter
          instrumentList={instrumentList}
          onFilterChange={handleOnFilterChange}
          change={midnightChange}
        />
        <Button
          onClick={() => setShowPortfolioListDialog(true)}
          text={getPortfolioFilterText()}
          leftIcon={<HiListBullet />}
          type="filter"
          size="sm"
        />
      </LeftSide>
      <RightSide>
        <Searchbar
          placeholder={"Search"}
          onChange={(query) => setSearchQuery(query)}
          initialValue={searchQuery}
        />
        <Button
          onClick={() => {
            setLoading(true);
            runScreening();
          }}
          text={"Run Screening"}
          leftIcon={renderButtonIcon(loading.toString())}
          type="primary"
          size="sm"
          disabled={!enableRunScreening || loading}
        />
        <Button
          onClick={() => setShowConditionBuilder(true)}
          text={"New Condition"}
          leftIcon={<HiWrench />}
          type="secondary"
          size="sm"
        />
      </RightSide>
    </Container>
  );
};

export default ScreeningFilterContainer;
