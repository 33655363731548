import React from "react";
import {
  HiCurrencyDollar,
  HiPlus,
  HiPresentationChartLine,
  HiVariable,
} from "react-icons/hi2";
import {
  Container,
  AddBlockDialogContainer,
  IconContainer,
} from "./ConditionBuilderAdd.style";
// eslint-disable-next-line max-len
import { BaseBlock, ValueBlock, isComparatorBlock, isCrossBlock, isLogicBlock, useConditionBuilderContext } from "@client/src/context/ConditionBuilderContext";
// eslint-disable-next-line max-len
import { ConditionBuilderButton } from "../condition_builder_toolbar/ConditionBuilderToolbar.component";
import {
  CrossVector,
  DoubleBottom,
  HiComparator,
  HiVenn,
} from "@client/src/assets/Icons";
import { v4 } from "uuid";
import { blockReplacer } from "../condition_builder/ConditionBuilder.helper";

const ConditionBuilderAdd = ({ blockId }: { blockId: string }) => {
  const {
    setSelectedTool,
    mutateConditionActionQueue,
    block,
    setBlock,
  } = useConditionBuilderContext();
  const [showAddBlockDialog, setShowAddBlockDialog] = React.useState(false);

  // eslint-disable-next-line complexity
  const findParent = () => {
    const leftNodeMatches = (node: BaseBlock) =>
      node.left && node.left.id === blockId;
    const rightNodeMatches = (node: BaseBlock) =>
      node.right && node.right.id === blockId;

    // eslint-disable-next-line complexity
    const dfs = (node: BaseBlock): BaseBlock | null => {
      if (!node) return null;
      if (
        leftNodeMatches(node) ||
        rightNodeMatches(node)
      ) {
        return node;
      }
      const leftSearch = dfs(node.left);
      if (leftSearch) return leftSearch;
      return dfs(node.right);
    };
    return dfs(block);
  };

  const renderLogicTool = () => {
    const parent = findParent();
    if (parent && !isLogicBlock(parent)) return null;
    return (
      <ConditionBuilderButton
        icon={<HiVenn />}
        text="Logic"
        onClick={() => {
          setSelectedTool("logic");
          mutateConditionActionQueue({
            blockId,
            action: "create",
            blockType: "logic",
          });
          setShowAddBlockDialog(false);
        }}
      />
    );
  };

  const renderComparatorTool = () => {
    const parent = findParent();
    if (parent && !isLogicBlock(parent)) return null;
    return (
      <ConditionBuilderButton
        icon={<HiComparator />}
        text="Comparator"
        onClick={() => {
          setSelectedTool("comparator");
          mutateConditionActionQueue({
            blockId,
            action: "create",
            blockType: "comparator",
          });
          setShowAddBlockDialog(false);
        }}
      />
    );
  };

  const renderTechnicalTool = () => {
    const parent = findParent();
    if (
      !parent ||
      (!isComparatorBlock(parent) && !isCrossBlock(parent))
    ) return null;
    return (
      <ConditionBuilderButton
        icon={<HiPresentationChartLine />}
        text="Technical"
        onClick={() => {
          setSelectedTool("technical");
          mutateConditionActionQueue({
            blockId,
            action: "create",
            blockType: "technical",
          });
          setShowAddBlockDialog(false);
        }}
      />
    );
  };

  const renderPatternTool = () => {
    const parent = findParent();
    if (!parent || !isLogicBlock(parent)) return null;
    return (
      <ConditionBuilderButton
        icon={<DoubleBottom width={24} />}
        text="Pattern"
        onClick={() => {
          setSelectedTool("pattern");
          mutateConditionActionQueue({
            blockId,
            action: "create",
            blockType: "pattern",
          });
          setShowAddBlockDialog(false);
        }}
      />
    );
  };

  const renderFlatValueTool = () => {
    const parent = findParent();
    if (
      !parent ||
      (!isComparatorBlock(parent) && !isCrossBlock(parent))
    ) return null;
    return (
      <ConditionBuilderButton
        icon={<HiVariable />}
        text="Value"
        onClick={() => {
          const newBlock = {
            id: v4(),
            blockType: "value",
            value: 0,
          } as ValueBlock;
          setBlock(blockReplacer(blockId, block, newBlock));
          setShowAddBlockDialog(false);
        }}
      />
    );
  };

  const renderPriceTool = () => {
    const parent = findParent();
    if (
      !parent ||
      (!isComparatorBlock(parent) && !isCrossBlock(parent))
    ) return null;
    return (
      <ConditionBuilderButton
        icon={<HiCurrencyDollar />}
        text="Price"
        onClick={() => {
          setSelectedTool("price");
          mutateConditionActionQueue({
            blockId,
            action: "create",
            blockType: "price",
          });
          setShowAddBlockDialog(false);
        }}
      />
    );
  };

  const renderCrossTool = () => {
    const parent = findParent();
    if (parent && !isLogicBlock(parent)) return null;
    return (
      <ConditionBuilderButton
        icon={<CrossVector />}
        text="Cross"
        onClick={() => {
          setSelectedTool("cross");
          mutateConditionActionQueue({
            blockId,
            action: "create",
            blockType: "cross",
          });
          setShowAddBlockDialog(false);
        }}
      />
    );
  };

  const renderAddBlockDialog = () => {
    if (!showAddBlockDialog) return null;
    return (
      <AddBlockDialogContainer>
        {renderLogicTool()}
        {renderComparatorTool()}
        {renderTechnicalTool()}
        {renderPatternTool()}
        {renderFlatValueTool()}
        {renderPriceTool()}
        {renderCrossTool()}
      </AddBlockDialogContainer>
    );
  };

  return (
    <Container
      onClick={() => {
        setShowAddBlockDialog(!showAddBlockDialog);
      }}
    >
      <IconContainer>
        <HiPlus height={"1rem"} width={"1rem"} />
      </IconContainer>
      {renderAddBlockDialog()}
    </Container>
  );
};

export default ConditionBuilderAdd;
