import { useAlertContext } from "@client/src/context/AlertContext";
import { useAppContext } from "@client/src/context/AppContext";
import { AlertRecord } from "@common/types";
import { useAlerts } from "@hooks/useAlerts";
import { parseAlertInfo } from "@utils/alertParser";
import React, { useState } from "react";
import { HiTrash } from "react-icons/hi2";
import { IconButton } from "../../icon_button/IconButton.component";
import { ActiveLabel } from "../../label_types/active/ActiveLabel.component";
// eslint-disable-next-line max-len
import { InactiveLabel } from "../../label_types/inactive/InactiveLabel.component";
import { openDeleteConfirmation } from "./ManageAlertItem.helpers";
import {
  AlertStatusContainer,
  Divider,
  ItemContainer,
  ItemContent,
  ItemWrapper,
  Message,
  Subtext,
  TextRegular,
} from "./ManageAlertItem.styles";

type ManageAlertItemProps = {
  data: AlertRecord;
};

export const ManageAlertItem = ({ data }: ManageAlertItemProps) => {
  const { markAsDelete } = useAlerts();
  const parsedAlert = parseAlertInfo(data);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const { setFrequency, setHashcode } = useAppContext();
  const { alertStatus } = useAlertContext();
  const handleOpenDeleteConfirmation = () => {
    setShowDeleteModal(true);
  };

  const handleCloseModal = () => {
    setShowDeleteModal(false);
  };

  const handleInstrumentClick = () => {
    const instrumentHashcode = data.alertInfo.instrument;
    const frequency = data.alertInfo.frequency;
    setFrequency(frequency.toString());
    setHashcode(instrumentHashcode);
  };

  const renderAlertStatus = (status: boolean) => {
    return status ? <ActiveLabel /> : <InactiveLabel />;
  };

  const renderPatternAlertInfo = () => {
    if (parsedAlert.alertType === "Condition") return null;
    return (
      <Message>
        <TextRegular>{parsedAlert.pattern}</TextRegular>
        <Subtext>
          {"> "}
          {data.alertInfo.patternConfidence}
          {"%"}
        </Subtext>
        <TextRegular>{" • "}</TextRegular>
        <TextRegular>{parsedAlert.frequency}</TextRegular>
      </Message>
    );
  };

  const renderConditionAlertInfo = () => {
    if (parsedAlert.alertType === "Pattern") return null;
    return (
      <Message>
        {parsedAlert.condition}
        <TextRegular>{" • "}</TextRegular>
        <TextRegular>{parsedAlert.frequency}</TextRegular>
      </Message>
    );
  };

  return (
    <ItemWrapper>
      <ItemContainer>
        <ItemContent>
          <Message>
            <Subtext
              onClick={handleInstrumentClick}
              style={{ cursor: "pointer" }}
            >
              {parsedAlert.instrument}
            </Subtext>
          </Message>
          {renderConditionAlertInfo()}
          {renderPatternAlertInfo()}
          <AlertStatusContainer>
            {renderAlertStatus(alertStatus)}
          </AlertStatusContainer>
        </ItemContent>
        <IconButton
          Icon={HiTrash}
          onClick={handleOpenDeleteConfirmation}
          rounded
          tooltipContent="Delete Alert"
        />
      </ItemContainer>

      <Divider />
      {showDeleteModal &&
        openDeleteConfirmation(handleCloseModal, data, markAsDelete)}
    </ItemWrapper>
  );
};
