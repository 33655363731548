import React from "react";

export const Discord = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.9308 3.46299C15.6561 2.87809 14.2892 2.44716 12.8599
      2.20035C12.8339 2.19558 12.8079 2.20749 12.7945 2.23129C12.6187
      2.54397 12.4239 2.95189 12.2876 3.27251C10.7503 3.04237 9.22099
      3.04237 7.71527 3.27251C7.57887 2.94476 7.37707 2.54397 7.20048
      2.23129C7.18707 2.20828 7.16107 2.19638 7.13504 2.20035C5.70659
      2.44637 4.33963 2.8773 3.06411 3.46299C3.05307 3.46775 3.04361 3.47569
      3.03732 3.486C0.444493 7.35964 -0.265792 11.1381 0.0826501
      14.8696C0.0842267 14.8879 0.0944749 14.9054 0.108665 14.9165C1.81934
      16.1727 3.47642 16.9354 5.10273 17.4409C5.12876 17.4489 5.15634 17.4394
      5.1729 17.4179C5.55761 16.8926 5.90054 16.3386 6.19456 15.7561C6.21192
      15.722 6.19535 15.6815 6.15989 15.668C5.61594 15.4617 5.098 15.2101
      4.59977 14.9244C4.56037 14.9014 4.55721 14.845 4.59347 14.818C4.69831
      14.7395 4.80318 14.6577 4.9033 14.5752C4.92141 14.5601 4.94665 14.5569
      4.96794 14.5665C8.24107 16.0608 11.7846 16.0608 15.0191 14.5665C15.0404
      14.5561 15.0657 14.5593 15.0846 14.5744C15.1847 14.6569 15.2895 14.7395
      15.3952 14.818C15.4314 14.845 15.4291 14.9014 15.3897 14.9244C14.8914
      15.2156 14.3735 15.4617 13.8288 15.6672C13.7933 15.6807 13.7775 15.722
      13.7949 15.7561C14.0952 16.3378 14.4381 16.8917 14.8157 17.4171C14.8315
      17.4394 14.8599 17.4489 14.8859 17.4409C16.5201 16.9354 18.1772 16.1727
      19.8879 14.9165C19.9028 14.9054 19.9123 14.8887 19.9139 14.8704C20.3309
      10.5563 19.2154 6.80887 16.9568 3.48679C16.9513 3.47569 16.9419 3.46775
      16.9308 3.46299ZM6.68335 12.5975C5.69792 12.5975 4.88594 11.6928 4.88594
      10.5817C4.88594 9.47065 5.68217 8.56595 6.68335 8.56595C7.69239 8.56595
      8.49651 9.47859 8.48073 10.5817C8.48073 11.6928 7.68451 12.5975 6.68335
      12.5975ZM13.329 12.5975C12.3435 12.5975 11.5316 11.6928 11.5316
      10.5817C11.5316 9.47065 12.3278 8.56595 13.329 8.56595C14.338 8.56595
      15.1421 9.47859 15.1264 10.5817C15.1264 11.6928 14.338 12.5975 13.329
      12.5975Z"
        fill="#818181"
      />
    </svg>
  );
};

export const Email = () => {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 16.793C9.36016 16.793 8.71562 16.6329 8.16875 16.2024L0
      10.3008V18.6251C0 19.6602 0.839844 20.5001 1.875 20.5001H18.125C19.1602
      20.5001 20 19.6602 20 18.6602V10.3008L11.832 16.2032C11.2852 16.5938
      10.6406 16.793 10 16.793ZM19.2812 6.86726C18.9375 6.59382 18.6094
      6.33991 18.125 5.97272V4.24929C18.125 3.21413 17.2852 2.37429 16.25
      2.37429L13.2227 2.37435C13.1006 2.28646 12.9932 2.20833 12.8662
      2.12044C12.2109 1.63796 10.9102 0.48549 10 0.500138C9.09375 0.48549
      7.78906 1.63796 7.13281 2.12116C7.00586 2.20905 6.89844 2.28718
      6.77637 2.37507H3.75C2.71484 2.37507 1.875 3.21491 1.875
      4.25007V5.97351C1.3918 6.33991 1.06445 6.59382 0.717969
      6.86726C0.263672 7.21882 0 7.76569 0 8.34382V8.75866L3.75
      11.4684V4.25007H16.25V11.4649L20 8.75515V8.34382C20 7.76569 19.7383
       7.21882 19.2812 6.86726ZM6.875 10.4649H13.125C13.4702 10.4649 13.75
        10.1851 13.75 9.87507C13.75 9.52983 13.4702 9.25007 13.125
        9.25007H6.875C6.52984 9.25007 6.25 9.52983 6.25 9.87507C6.25
        10.2188 6.53125 10.4649 6.875 10.4649ZM6.875 7.96491H13.125C13.4702
        7.96491 13.75 7.68507 13.75 7.33991C13.75 6.99468 13.4702 6.75007
        13.125 6.75007H6.875C6.52984 6.75007 6.25 7.02983 6.25 7.33991C6.25
         7.71882 6.53125 7.96491 6.875 7.96491Z"
        fill="#818181"
      />
    </svg>
  );
};

export const Instagram = () => {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1_48836)">
        <path
          d="M10 2.30078C12.6719 2.30078 12.9883 2.3125 14.0391
        2.35937C15.0156 2.40234 15.543 2.56641 15.8945 2.70313C16.3594
        2.88281 16.6953 3.10156 17.043 3.44922C17.3945 3.80078 17.6094
        4.13281 17.7891 4.59766C17.9258 4.94922 18.0898 5.48047 18.1328
        6.45312C18.1797 7.50781 18.1914 7.82422 18.1914 10.4922C18.1914
        13.1641 18.1797 13.4805 18.1328 14.5313C18.0898 15.5078 17.9258
        16.0352 17.7891 16.3867C17.6094 16.8516 17.3906 17.1875 17.043
        17.5352C16.6914 17.8867 16.3594 18.1016 15.8945 18.2813C15.543
        18.418 15.0117 18.582 14.0391 18.625C12.9844 18.6719 12.668 18.6836
        10 18.6836C7.32813 18.6836 7.01172 18.6719 5.96094 18.625C4.98438
        18.582 4.45703 18.418 4.10547 18.2813C3.64063 18.1016 3.30469 17.8828
        2.95703 17.5352C2.60547 17.1836 2.39063 16.8516 2.21094 16.3867C2.07422
         16.0352 1.91016 15.5039 1.86719 14.5313C1.82031 13.4766 1.80859
         13.1602 1.80859 10.4922C1.80859 7.82031 1.82031 7.50391 1.86719
         6.45312C1.91016 5.47656 2.07422 4.94922 2.21094 4.59766C2.39063
         4.13281 2.60938 3.79688 2.95703 3.44922C3.30859 3.09766 3.64063
         2.88281 4.10547 2.70313C4.45703 2.56641 4.98828 2.40234 5.96094
         2.35937C7.01172 2.3125 7.32813 2.30078 10 2.30078ZM10 0.5C7.28516
         0.5 6.94531 0.511719 5.87891 0.558594C4.81641 0.605469 4.08594
         0.777344 3.45313 1.02344C2.79297 1.28125 2.23438 1.62109 1.67969
         2.17969C1.12109 2.73438 0.78125 3.29297 0.523438 3.94922C0.277344
         4.58594 0.105469 5.3125 0.0585938 6.375C0.0117188 7.44531 0 7.78516
         0 10.5C0 13.2148 0.0117188 13.5547 0.0585938 14.6211C0.105469
         15.6836 0.277344 16.4141 0.523438 17.0469C0.78125 17.707 1.12109
         18.2656 1.67969 18.8203C2.23438 19.375 2.79297 19.7188 3.44922
         19.9727C4.08594 20.2188 4.8125 20.3906 5.875 20.4375C6.94141 20.4844
         7.28125 20.4961 9.99609 20.4961C12.7109 20.4961 13.0508 20.4844
         14.1172 20.4375C15.1797 20.3906 15.9102 20.2188 16.543 19.9727C17.1992
         19.7188 17.7578 19.375 18.3125 18.8203C18.8672 18.2656 19.2109 17.707
         19.4648 17.0508C19.7109 16.4141 19.8828 15.6875 19.9297 14.625C19.9766
         13.5586 19.9883 13.2188 19.9883 10.5039C19.9883 7.78906 19.9766
         7.44922 19.9297 6.38281C19.8828 5.32031 19.7109 4.58984 19.4648
         3.95703C19.2188 3.29297 18.8789 2.73438 18.3203 2.17969C17.7656
         1.625 17.207 1.28125 16.5508 1.02734C15.9141 0.78125 15.1875 0.609375
         14.125 0.5625C13.0547 0.511719 12.7148 0.5 10 0.5Z"
          fill="#818181"
        />
        <path
          d="M10 5.36328C7.16406 5.36328 4.86328 7.66406 4.86328
        10.5C4.86328 13.3359 7.16406 15.6367 10 15.6367C12.8359 15.6367
        15.1367 13.3359 15.1367 10.5C15.1367 7.66406 12.8359 5.36328 10
        5.36328ZM10 13.832C8.16016 13.832 6.66797 12.3398 6.66797 10.5C6.66797
        8.66016 8.16016 7.16797 10 7.16797C11.8398 7.16797 13.332 8.66016
        13.332 10.5C13.332 12.3398 11.8398 13.832 10 13.832Z"
          fill="#818181"
        />
        <path
          d="M16.5391 5.16016C16.5391 5.82422 16 6.35938 15.3398
        6.35938C14.6758 6.35938 14.1406 5.82031 14.1406 5.16016C14.1406
        4.49609 14.6797 3.96094 15.3398 3.96094C16 3.96094 16.5391 4.5
        16.5391 5.16016Z"
          fill="#818181"
        />
      </g>
      <defs>
        <clipPath id="clip0_1_48836">
          <rect
            width="20"
            height="20"
            fill="white"
            transform="translate(0 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export const LinkedIn = () => {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1_48842)">
        <path
          d="M18.5195 0.5H1.47656C0.660156 0.5 0 1.14453 0 1.94141V19.0547C0
         19.8516 0.660156 20.5 1.47656 20.5H18.5195C19.3359 20.5 20 19.8516 20
         19.0586V1.94141C20 1.14453 19.3359 0.5 18.5195 0.5ZM5.93359
         17.543H2.96484V7.99609H5.93359V17.543ZM4.44922 6.69531C3.49609
         6.69531 2.72656 5.92578 2.72656 4.97656C2.72656 4.02734 3.49609
         3.25781 4.44922 3.25781C5.39844 3.25781 6.16797 4.02734 6.16797
         4.97656C6.16797 5.92188 5.39844 6.69531 4.44922 6.69531ZM17.043
         17.543H14.0781V12.9023C14.0781 11.7969 14.0586 10.3711 12.5352
         10.3711C10.9922 10.3711 10.7578 11.5781 10.7578
          12.8242V17.543H7.79688V7.99609H10.6406V9.30078H10.6797C11.0742
          8.55078 12.043 7.75781 13.4844 7.75781C16.4883 7.75781 17.043
          9.73438 17.043 12.3047V17.543Z"
          fill="#818181"
        />
      </g>
      <defs>
        <clipPath id="clip0_1_48842">
          <rect
            width="20"
            height="20"
            fill="white"
            transform="translate(0 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export const Twitter = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_3619_1320)">
        <path
          d="M11.6468 8.46864L18.9321 0H17.2057L10.8799 7.3532L5.82741
          0H0L7.6403 11.1193L0 20H1.72649L8.40678 12.2348L13.7425
          20H19.57L11.6464 8.46864H11.6468ZM9.28211 11.2173L8.50799
          10.1101L2.34857 1.29968H5.00037L9.97109 8.40994L10.7452
          9.51718L17.2066 18.7594H14.5548L9.28211 11.2177V11.2173Z"
          fill="#818181"
        />
      </g>
      <defs>
        <clipPath id="clip0_3619_1320">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
