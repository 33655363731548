import { textStyles } from "@client/src/utils/textStyles";
import styled from "styled-components";

const ChartControlsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 0.25rem;
  border-bottom: 1px solid ${(props) => props.theme.dividerColor};
  border-top: 1px solid ${(props) => props.theme.dividerColor};
  @media (max-width: 768px) {
    overflow-x: auto;
    border: 0px;
  }
  background-color: ${(props) => props.theme.secondaryBackground};
  padding: 0.125rem;
`;

const SupportResistanceToggle = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
  padding: 12px 0;
  ${textStyles.textSmBold};
`;

const AIMAButtonContainer = styled.div`
  border-radius: 1rem;
`;

export { ChartControlsContainer, SupportResistanceToggle, AIMAButtonContainer };
