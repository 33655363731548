import { AlertRecord } from "@common/types";
import { Instrument } from "@generated/common/basic-types_pb";
import { useInstrumentContext } from "../context/InstrumentContext";
import { getFrequencyLabel } from "./frequencyUtils";
import { getPatternLabel } from "./pattern_utils";

const getInstrumentLabel = (
  instruments: Instrument.AsObject[],
  alert: AlertRecord
) =>
  instruments.find(
    (instrument) => instrument.hashcode === alert.alertInfo.instrument
  )?.symbol || "Unknown instrument";

const getFiredAtDate = (alert: AlertRecord) =>
  new Date(alert.firedAt ?? alert.createdAt);

export const parseAlertInfo = (alert: AlertRecord) => {
  const { instruments } = useInstrumentContext();
  const frequencyLabel = getFrequencyLabel(alert.alertInfo.frequency);
  const patternLabel = getPatternLabel(alert.alertInfo.patternType);
  const instrumentLabel = getInstrumentLabel(instruments, alert);
  const dateLabel = new Date(alert.createdAt).toUTCString();
  const firedAtDate = getFiredAtDate(alert);
  const dateFiredAt = firedAtDate.toISOString();
  const hour = firedAtDate.getUTCHours();
  const minute = firedAtDate.getUTCMinutes();
  const firedAtTime = `${hour}:${minute < 10 ? `0${minute}` : minute}`;
  return {
    frequency: frequencyLabel,
    pattern: patternLabel,
    instrument: instrumentLabel,
    date: dateLabel,
    firedAt: dateFiredAt,
    firedAtTime,
    read: alert.read,
    alertType: !alert.alertInfo.condition ? "Pattern" : "Condition",
    condition: alert.alertInfo.condition,
  };
};
