import React from "react";
import {
  ConditionBuilderOption,
} from "../condition_builder_selector/ConditionBuilderSelector.component";
import {
  CrossAboveVector,
  CrossUnderVector,
  CupAndHandle,
  CupAndHandleRevert,
  DoubleBottom,
  DoubleTop,
  HeadAndShoulders,
  HiEqual,
  HiGreaterThan,
  HiGreaterThanEqual,
  HiLessThan,
  HiLessThanEqual,
  HiVennAND,
  HiVennNOT,
  HiVennOR,
  InverseHeadAndShoulders,
  RoundingBottom,
  RoundingTop,
  TripleBottom,
  TripleTop,
} from "@client/src/assets/Icons";
import { EMA, MA, MACD, RSI } from "@client/src/assets/TechnicalIcons";
import {
  BlockParameter,
  BlockType,
  ComparatorBlock,
  ComparatorOperation,
  ConditionAction,
  ConditionBuilderBlock,
  CrossBlock,
  CrossOperation,
  LogicBlock,
  LogicOperation,
  PatternBlock,
  PatternOperation,
  PriceBlock,
  PriceOperation,
  TechnicalBlock,
  TechnicalOperation,
} from "@client/src/context/ConditionBuilderContext";
import { v4 } from "uuid";

// eslint-disable-next-line complexity
const handleBlockOperation = (
  blockType: BlockType,
  operation:
    | LogicOperation
    | ComparatorOperation
    | TechnicalOperation
    | PatternOperation
    | PriceOperation
    | CrossOperation,
  queue: ConditionAction[],
  currentBlock: ConditionBuilderBlock,
  mutateQueue: (action: ConditionAction) => void,
  setBlock: (block: ConditionBuilderBlock) => void,
  addToUndoStack: (stack: ConditionBuilderBlock) => void,
  parameters?: BlockParameter[]
) => {
  let newBlock: ConditionBuilderBlock;

  switch (blockType) {
    case "logic":
      newBlock = {
        id: v4(),
        blockType: "logic",
        operation: operation as LogicOperation,
        left: { id: v4(), blockType: "" },
        right: { id: v4(), blockType: "" },
      } as LogicBlock;
      break;
    case "comparator":
      newBlock = {
        id: v4(),
        blockType: "comparator",
        operation: operation as ComparatorOperation,
        left: { id: v4(), blockType: "" },
        right: { id: v4(), blockType: "" },
      } as ComparatorBlock;
      break;
    case "technical":
      newBlock = {
        id: v4(),
        blockType: "technical",
        operation: operation as TechnicalOperation,
        parameters: parameters ?? [],
      } as TechnicalBlock;
      break;
    case "pattern":
      newBlock = {
        id: v4(),
        blockType: "pattern",
        operation: operation as PatternOperation,
      } as PatternBlock;
      break;
    case "price":
      newBlock = {
        id: v4(),
        blockType: "price",
        operation: operation as PriceOperation,
      } as PriceBlock;
      break;
    case "cross":
      newBlock = {
        id: v4(),
        blockType: "cross",
        operation: operation as CrossOperation,
        left: { id: v4(), blockType: "" },
        right: { id: v4(), blockType: "" },
      } as CrossBlock;
      break;
    default:
      throw new Error("Unsupported block type");
  }
  addToUndoStack(currentBlock);
  if (queue.length > 0 && queue[queue.length - 1].action === "create") {
    const previousBlock = currentBlock;
    currentBlock = blockReplacer(
      queue[queue.length - 1].blockId,
      currentBlock,
      newBlock
    );
    mutateQueue({
      action: "edit",
      blockType: blockType,
      blockId: queue[queue.length - 1].blockId,
      newValue: JSON.stringify(currentBlock),
      previousValue: JSON.stringify(previousBlock),
    });
    setBlock(currentBlock);
  } else {
    mutateQueue({
      action: "add",
      blockType: blockType,
      newValue: JSON.stringify(newBlock),
      previousValue: JSON.stringify(currentBlock),
      blockId: newBlock.id,
    });
    setBlock(newBlock);
  }
};

// eslint-disable-next-line complexity
export const blockReplacer = (
  blockId: string,
  block: ConditionBuilderBlock,
  newBlock: ConditionBuilderBlock
): ConditionBuilderBlock => {
  if (block.id === blockId) {
    return newBlock;
  }

  const modifiedBlock = { ...block };

  if (
    "left" in modifiedBlock &&
    "left" in block &&
    modifiedBlock.left &&
    block.left
  ) {
    modifiedBlock.left = blockReplacer(blockId, block.left, newBlock);
  }

  if (
    "right" in modifiedBlock &&
    "right" in block &&
    modifiedBlock.right &&
    block.right
  ) {
    modifiedBlock.right = blockReplacer(blockId, block.right, newBlock);
  }

  return modifiedBlock;
};

export const getLogicOptions: (
  queue: ConditionAction[],
  block: ConditionBuilderBlock,
  mutateQueue: (action: ConditionAction) => void,
  setBlock: (block: ConditionBuilderBlock) => void,
  setSelectedTool: (tool: string | null) => void,
  addToUndoStack: (stack: ConditionBuilderBlock) => void
) => ConditionBuilderOption[] = (
  queue,
  block,
  mutateQueue,
  setBlock,
  setSelectedTool,
  addToUndoStack
) => [
  {
    icon: <HiVennAND />,
    text: "AND",
    onClick: () => {
      handleBlockOperation(
        "logic",
        LogicOperation.AND,
        queue,
        block,
        mutateQueue,
        setBlock,
        addToUndoStack
      );
      setSelectedTool(null);
    },
  },
  {
    icon: <HiVennOR />,
    text: "OR",
    onClick: () => {
      handleBlockOperation(
        "logic",
        LogicOperation.OR,
        queue,
        block,
        mutateQueue,
        setBlock,
        addToUndoStack
      );
      setSelectedTool(null);
    },
  },
  {
    icon: <HiVennNOT />,
    text: "NOT",
    onClick: () => {
      handleBlockOperation(
        "logic",
        LogicOperation.NOT,
        queue,
        block,
        mutateQueue,
        setBlock,
        addToUndoStack
      );
      setSelectedTool(null);
    },
  },
];

export const getComparatorOptions: (
  queue: ConditionAction[],
  block: ConditionBuilderBlock,
  mutateQueue: (action: ConditionAction) => void,
  setBlock: (block: ConditionBuilderBlock) => void,
  setSelectedTool: (tool: string | null) => void,
  addToUndoStack: (stack: ConditionBuilderBlock) => void
) => ConditionBuilderOption[] = (
  queue,
  block,
  mutateQueue,
  setBlock,
  setSelectedTool,
  addToUndoStack
) => [
  {
    icon: <HiGreaterThan />,
    text: "Greater than",
    onClick: () => {
      handleBlockOperation(
        "comparator",
        ComparatorOperation.GT,
        queue,
        block,
        mutateQueue,
        setBlock,
        addToUndoStack
      );
      setSelectedTool(null);
    },
  },
  {
    icon: <HiGreaterThanEqual />,
    text: "Greater than or equal",
    onClick: () => {
      handleBlockOperation(
        "comparator",
        ComparatorOperation.GTE,
        queue,
        block,
        mutateQueue,
        setBlock,
        addToUndoStack
      );
      setSelectedTool(null);
    },
  },
  {
    icon: <HiLessThan />,
    text: "Less than",
    onClick: () => {
      handleBlockOperation(
        "comparator",
        ComparatorOperation.LT,
        queue,
        block,
        mutateQueue,
        setBlock,
        addToUndoStack
      );
      setSelectedTool(null);
    },
  },
  {
    icon: <HiLessThanEqual />,
    text: "Less than or equal",
    onClick: () => {
      handleBlockOperation(
        "comparator",
        ComparatorOperation.LTE,
        queue,
        block,
        mutateQueue,
        setBlock,
        addToUndoStack
      );
      setSelectedTool(null);
    },
  },
  {
    icon: <HiEqual />,
    text: "Equal",
    onClick: () => {
      handleBlockOperation(
        "comparator",
        ComparatorOperation.EQ,
        queue,
        block,
        mutateQueue,
        setBlock,
        addToUndoStack
      );
      setSelectedTool(null);
    },
  },
];

export const getPriceOptions: (
  queue: ConditionAction[],
  block: ConditionBuilderBlock,
  mutateQueue: (action: ConditionAction) => void,
  setBlock: (block: ConditionBuilderBlock) => void,
  setSelectedTool: (tool: string | null) => void,
  addToUndoStack: (stack: ConditionBuilderBlock) => void
) => ConditionBuilderOption[] = (
  queue,
  block,
  mutateQueue,
  setBlock,
  setSelectedTool,
  addToUndoStack,
) => [
  {
    icon: <div></div>,
    text: "Open",
    onClick: () => {
      handleBlockOperation(
        "price",
        PriceOperation.Open,
        queue,
        block,
        mutateQueue,
        setBlock,
        addToUndoStack
      );
      setSelectedTool(null);
    },
  },
  {
    icon: <div></div>,
    text: "High",
    onClick: () => {
      handleBlockOperation(
        "price",
        PriceOperation.High,
        queue,
        block,
        mutateQueue,
        setBlock,
        addToUndoStack
      );
      setSelectedTool(null);
    },
  },
  {
    icon: <div></div>,
    text: "Low",
    onClick: () => {
      handleBlockOperation(
        "price",
        PriceOperation.Low,
        queue,
        block,
        mutateQueue,
        setBlock,
        addToUndoStack
      );
      setSelectedTool(null);
    },
  },
  {
    icon: <div></div>,
    text: "Close",
    onClick: () => {
      handleBlockOperation(
        "price",
        PriceOperation.Close,
        queue,
        block,
        mutateQueue,
        setBlock,
        addToUndoStack
      );
      setSelectedTool(null);
    },
  },
];

export const getTechnicalsOptions: (
  queue: ConditionAction[],
  block: ConditionBuilderBlock,
  mutateQueue: (action: ConditionAction) => void,
  setBlock: (block: ConditionBuilderBlock) => void,
  setSelectedTool: (tool: string | null) => void,
  addToUndoStack: (stack: ConditionBuilderBlock) => void
) => ConditionBuilderOption[] = (
  queue,
  block,
  mutateQueue,
  setBlock,
  setSelectedTool,
  addToUndoStack
) => [
  {
    icon: <MA width={20} height={20} />,
    text: "SMA",
    onClick: () => {
      handleBlockOperation(
        "technical",
        TechnicalOperation.SMA,
        queue,
        block,
        mutateQueue,
        setBlock,
        addToUndoStack,
        [
          {
            value: "10",
            type: "number",
            name: "period",
          },
        ]
      );
      setSelectedTool(null);
    },
  },
  {
    icon: <EMA width={20} height={20} />,
    text: "EMA",
    onClick: () => {
      handleBlockOperation(
        "technical",
        TechnicalOperation.EMA,
        queue,
        block,
        mutateQueue,
        setBlock,
        addToUndoStack,
        [
          {
            value: "10",
            type: "number",
            name: "period",
          },
        ]
      );
      setSelectedTool(null);
    },
  },
  {
    icon: <RSI width={20} height={20} />,
    text: "RSI",
    onClick: () => {
      handleBlockOperation(
        "technical",
        TechnicalOperation.RSI,
        queue,
        block,
        mutateQueue,
        setBlock,
        addToUndoStack,
        [
          {
            value: "14",
            type: "number",
            name: "period",
          },
        ]
      );
      setSelectedTool(null);
    },
  },
  {
    icon: <MACD width={20} height={20} />,
    text: "MACD",
    onClick: () => {
      handleBlockOperation(
        "technical",
        TechnicalOperation.MACD,
        queue,
        block,
        mutateQueue,
        setBlock,
        addToUndoStack,
        [
          {
            value: "Value",
            type: "dropdown",
            name: "macd",
          },
        ]
      );
      setSelectedTool(null);
    },
  },
];

export const getPatternOptions: (
  queue: ConditionAction[],
  block: ConditionBuilderBlock,
  mutateQueue: (action: ConditionAction) => void,
  setBlock: (block: ConditionBuilderBlock) => void,
  setSelectedTool: (tool: string | null) => void,
  addToUndoStack: (stack: ConditionBuilderBlock) => void
) => ConditionBuilderOption[] = (
  queue,
  block,
  mutateQueue,
  setBlock,
  setSelectedTool,
  addToUndoStack
) => [
  {
    icon: <DoubleBottom />,
    text: "Double Bottom",
    onClick: () => {
      handleBlockOperation(
        "pattern",
        PatternOperation.DoubleBottom,
        queue,
        block,
        mutateQueue,
        setBlock,
        addToUndoStack
      );
      setSelectedTool(null);
    },
  },
  {
    icon: <DoubleTop />,
    text: "Double Top",
    onClick: () => {
      handleBlockOperation(
        "pattern",
        PatternOperation.DoubleTop,
        queue,
        block,
        mutateQueue,
        setBlock,
        addToUndoStack
      );
      setSelectedTool(null);
    },
  },
  {
    icon: <TripleBottom />,
    text: "Triple Bottom",
    onClick: () => {
      handleBlockOperation(
        "pattern",
        PatternOperation.TripleBottom,
        queue,
        block,
        mutateQueue,
        setBlock,
        addToUndoStack
      );
      setSelectedTool(null);
    },
  },
  {
    icon: <TripleTop />,
    text: "Triple Top",
    onClick: () => {
      handleBlockOperation(
        "pattern",
        PatternOperation.TripleTop,
        queue,
        block,
        mutateQueue,
        setBlock,
        addToUndoStack
      );
      setSelectedTool(null);
    },
  },
  {
    icon: <RoundingBottom />,
    text: "Rounded Bottom",
    onClick: () => {
      handleBlockOperation(
        "pattern",
        PatternOperation.RoundedBottom,
        queue,
        block,
        mutateQueue,
        setBlock,
        addToUndoStack
      );
      setSelectedTool(null);
    },
  },
  {
    icon: <RoundingTop />,
    text: "Rounded Top",
    onClick: () => {
      handleBlockOperation(
        "pattern",
        PatternOperation.RoundedTop,
        queue,
        block,
        mutateQueue,
        setBlock,
        addToUndoStack
      );
      setSelectedTool(null);
    },
  },
  {
    icon: <CupAndHandle />,
    text: "Cup & Handle",
    onClick: () => {
      handleBlockOperation(
        "pattern",
        PatternOperation.CupAndHandle,
        queue,
        block,
        mutateQueue,
        setBlock,
        addToUndoStack
      );
      setSelectedTool(null);
    },
  },
  {
    icon: <CupAndHandleRevert />,
    text: "Inverse Cup & Handle",
    onClick: () => {
      handleBlockOperation(
        "pattern",
        PatternOperation.InvertedCupAndHandle,
        queue,
        block,
        mutateQueue,
        setBlock,
        addToUndoStack
      );
      setSelectedTool(null);
    },
  },
  {
    icon: <HeadAndShoulders />,
    text: "Head & Shoulders",
    onClick: () => {
      handleBlockOperation(
        "pattern",
        PatternOperation.HeadAndShoulders,
        queue,
        block,
        mutateQueue,
        setBlock,
        addToUndoStack
      );
      setSelectedTool(null);
    },
  },
  {
    icon: <InverseHeadAndShoulders />,
    text: "Inverse Head & Shoulders",
    onClick: () => {
      handleBlockOperation(
        "pattern",
        PatternOperation.InvertedHeadAndShoulders,
        queue,
        block,
        mutateQueue,
        setBlock,
        addToUndoStack
      );
      setSelectedTool(null);
    },
  },
];

export const getCrossOptions: (
  queue: ConditionAction[],
  block: ConditionBuilderBlock,
  mutateQueue: (action: ConditionAction) => void,
  setBlock: (block: ConditionBuilderBlock) => void,
  setSelectedTool: (tool: string | null) => void,
  addToUndoStack: (stack: ConditionBuilderBlock) => void
) => ConditionBuilderOption[] = (
  queue,
  block,
  mutateQueue,
  setBlock,
  setSelectedTool,
  addToUndoStack
) => [
  {
    icon: <CrossAboveVector />,
    text: "Cross Above",
    onClick: () => {
      handleBlockOperation(
        "cross",
        CrossOperation.CrossAbove,
        queue,
        block,
        mutateQueue,
        setBlock,
        addToUndoStack
      );
      setSelectedTool(null);
    },
  },
  {
    icon: <CrossUnderVector />,
    text: "Cross Under",
    onClick: () => {
      handleBlockOperation(
        "cross",
        CrossOperation.CrossUnder,
        queue,
        block,
        mutateQueue,
        setBlock,
        addToUndoStack
      );
      setSelectedTool(null);
    },
  },
];
