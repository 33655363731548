import { Candlestick } from "@generated/common/basic-types_pb";
import { PatternSearchResult } from "@generated/query/pattern_pb";
import { Time } from "lightweight-charts";

export const transformTsIntervalToTime: (tsInterval: number) => Time = (
  tsInterval
) => Math.round(new Date(tsInterval / 1000000).valueOf() / 1000) as Time;

export const getCloseValues = (
  candles: Candlestick.AsObject[],
  intervalList: number[]
) =>
  candles
    .filter(
      (candle) =>
        candle.tsInterval > intervalList[0] &&
        candle.tsInterval < intervalList[intervalList.length - 1]
    )
    .map((candle) => parseFloat(candle.close));

export const getIntervalValues = (
  candles: Candlestick.AsObject[],
  intervalList: number[],
  offset: number
) =>
  candles
    .filter(
      (candle) =>
        candle.tsInterval >= intervalList[0] + offset &&
        candle.tsInterval <= intervalList[intervalList.length - 1] + offset
    )
    .map((candle) => candle.tsInterval);

// Find the indexes of the extrema in the intervalValues
export const findIndexes = (array1: number[], array2: number[]) => {
  return array1.map((value) => BigInt(array2.indexOf(value)));
};

// Scan through the Candles and find the minimum interval difference
export const findIntervalMinDiff = (candles: Candlestick.AsObject[]) => {
  let diff = 86_500_000_000_000; // > 1 day in nanoseconds
  for (let i = 0; i < candles.length - 1; i++) {
    diff = Math.min(diff, candles[i + 1].tsInterval - candles[i].tsInterval);
  }
  return diff;
};

export const getOffset = (
  patternType: number,
  candles: Candlestick.AsObject[]
) => {
  return patternType == 6 || patternType == 7
    ? findIntervalMinDiff(candles)
    : 0;
};

export const getShapeData = (
  candles: Candlestick.AsObject[],
  pattern: PatternSearchResult.AsObject
) => {
  // Get the Close Values of the Candles within the Pattern Extrema
  const closeValues = getCloseValues(candles, pattern.extremaIntervalsList);
  // Is the current pattern a rounded shape?
  const offset = getOffset(pattern.patternType, candles);

  // Get the Interval Values of the Candles within the Pattern Extrema
  const intervalSlice = getIntervalValues(
    candles,
    pattern.extremaIntervalsList,
    offset
  );

  // Determine in the intervalValues the indexes of the extrema
  const extremaIndexes = findIndexes(
    pattern.extremaIntervalsList,
    intervalSlice
  );

  try {
    // Invoke the WASM pattern drawShape function
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return window.drawShape(
      pattern.extremaIntervalsList.map((num) => BigInt(num)),
      pattern.extremaValuesList,
      closeValues,
      extremaIndexes,
      intervalSlice.map((num) => BigInt(num)),
      pattern.patternType
    );
  } catch (error) {
    // Unexpected WASM Pattern Drawing Error - forward to Data Platform Team
    console.error(error);
    return { x: [], y: [] };
  }
};
