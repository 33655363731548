import { Instrument } from "@generated/common/basic-types_pb";
import { ScreeningFilter } from "../../ScreeningFilter";

export const typeScreeningFilter: ScreeningFilter<
  string,
  Instrument.AsObject[]
> = {
  apply: (instrumentList, value) =>
    instrumentList.filter(
      (instrument) => instrument.instrumentClass.toString() === value
    ),
};
