import { textStyles } from "@client/src/utils/textStyles";
import styled from "styled-components";

const Container = styled.footer`
  margin-top: 1rem;
  width: 100%;
  background-color: ${(props) => props.theme.footerBg};
  z-index: 20;
`;

const FooterContainer = styled.div`
  background-color: ${(props) => props.theme.footerBg};
  padding: 24px 32px 0px 32px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  color: ${(props) => props.theme.text};
  flex-wrap: wrap;
  max-width: 1200px;
  margin: 0 auto;
  gap: 32px;
  z-index: 20;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 40px;
    padding: 32px 32px 0px 32px;
  }
`;

const FooterMainContent = styled.div`
  display: flex;

  padding: 0px 0px 64px 0px;
  align-items: center;
  align-self: stretch;
  margin: 0 auto;
  justify-content: space-between;
  row-gap: 56px;
  width: 100%;
  @media (max-width: 768px) {
    flex-direction: column;
    padding: 0px 0px 24px 0px;
    align-items: flex-start;
  }
`;

const FooterSocialContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
`;

const SocialFrame = styled.div`
  display: flex;
  align-items: center;
  gap: 40px;
`;

const LinkContainer = styled.div`
  display: flex;
  align-items: flex-start;

  @media (max-width: 768px) {
    flex-direction: row;
    align-items: flex-start;
    row-gap: 40px;
    align-self: stretch;
    flex-wrap: wrap;
  }
`;

const LinkGroupContainer = styled.div`
  display: flex;
  width: 160px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  @media (max-width: 768px) {
    width: 50%;
  }
`;

const LinkGroupTitle = styled.div`
  ${textStyles.textSmBold}
`;

const CopyrightContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-end;
  gap: 32px;
  align-self: stretch;
`;

const Divider = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  width: 100%;
  border-top: 2px solid #242424;
`;

const CopyrightText = styled.div`
  display: flex;
  flex-direction: column;
  align-self: stretch;

  color: var(--greyscale-500, #818181);
  ${textStyles.textSm}
`;

const PoweredBySigma = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 32px;
  align-self: stretch;
`;

export {
  Container,
  FooterContainer,
  FooterMainContent,
  FooterSocialContent,
  SocialFrame,
  LinkContainer,
  LinkGroupContainer,
  LinkGroupTitle,
  CopyrightContainer,
  Divider,
  CopyrightText,
  PoweredBySigma,
};
