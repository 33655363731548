const configGenerators = {
  rsi: (params: number[]) => ({
    name: "rsi",
    rsi: {
      main_period: params[0] || 0,
      zone: params[1] || 0.4,
      main_kind: 1,
      source: 0,
    },
  }),
  macd: (params: number[]) => ({
    name: "macd",
    macd: {
      fast_period: params[0] || 12,
      slow_period: params[1] || 26,
      line_period: params[2] || 9,
      fast_kind: 1,
      slow_kind: 1,
      line_kind: 1,
      source: 0,
    },
  }),
};

export const generateIndicatorConfig = (
  indicator: string,
  params: number[]
) => {
  const generator =
    configGenerators[indicator.toLowerCase() as keyof typeof configGenerators];
  return generator ? generator(params) : null;
};
