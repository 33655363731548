import { ScreeningMatch } from "@common/types";
import { updateMarkers } from "./markers";
import { updatePatterns } from "./patterns";
import { registerChartWithSeries, updateBaseSeries } from "./candles";
import { MainChartParams, MainChartState, createChartState } from "./state";
import { ChartData } from "@hooks/useFetchChart";
import { updateTechnicals } from "./technicals";
import { makeDisposable } from "../helpers/dispose";
import { updateFrequency } from "../helpers/frequency";

export const createCandlestickChart = (params: MainChartParams) => {
  const state = createChartState(params);
  registerChartWithSeries(state);

  updateBaseSeries(state, params.candles);
  updatePatterns(state, params.patterns);
  updateTechnicals(state, params.technicals);
  updateMarkers(state, params.markers);

  return {
    chart: state.lwChart,
    elemRef: state.elem,
    update: (chartData: ChartData, markers: ScreeningMatch[]) => {
      throwIfDisposed(state);
      const { frequency, candles, patterns, technicals } = chartData;
      updateFrequency(state, frequency);
      updateBaseSeries(state, candles);
      updatePatterns(state, patterns);
      updateTechnicals(state, technicals);
      updateMarkers(state, markers);
    },

    dispose: makeDisposable(state),
  };
};

export type CandlestickChartHandler = ReturnType<typeof createCandlestickChart>;

const throwIfDisposed = (chartInstance: MainChartState) => {
  if (chartInstance.isDisposed) throw new Error("Chart is disposed");
};
