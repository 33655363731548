import styled from "styled-components";
import * as TooltipPrimitive from "@radix-ui/react-tooltip";
import { effectStyles } from "@client/src/utils/effectStyles";

export const TooltipContent = styled(TooltipPrimitive.Content)`
  z-index: 999999999;
  border-radius: 4px;
  padding: 10px 0.5rem;
  font-size: 14px;
  line-height: 1;
  color: ${(props) => props.theme.tooltipText};
  background-color: ${(props) => props.theme.tooltipBg};
  user-select: none;
  animation-duration: 400ms;
  animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
  ${effectStyles.grey.shadowMd};
  will-change: transform, opacity;

  &[data-state="delayed-open"][data-side="top"] {
    animation-name: slideDownAndFade;
  }
  &[data-state="delayed-open"][data-side="right"] {
    animation-name: slideLeftAndFade;
  }
  &[data-state="delayed-open"][data-side="bottom"] {
    animation-name: slideUpAndFade;
  }
  &[data-state="delayed-open"][data-side="left"] {
    animation-name: slideRightAndFade;
  }

  @keyframes slideUpAndFade {
    from {
      opacity: 0;
      transform: translateY(2px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  @keyframes slideRightAndFade {
    from {
      opacity: 0;
      transform: translateX(-2px);
    }
    to {
      opacity: 1;
      transform: translateX(0);
    }
  }

  @keyframes slideDownAndFade {
    from {
      opacity: 0;
      transform: translateY(-2px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  @keyframes slideLeftAndFade {
    from {
      opacity: 0;
      transform: translateX(2px);
    }
    to {
      opacity: 1;
      transform: translateX(0);
    }
  }
`;

export const TooltipArrow = styled(TooltipPrimitive.Arrow)`
  fill: ${(props) => props.theme.tooltipBg};
`;

export const TooltipArrowDark = styled(TooltipPrimitive.Arrow)`
  fill: ${(props) => props.theme.tooltipBgDark};
`;
