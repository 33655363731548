import { ScreeningMatch } from "@common/types";
import { ISeriesApi } from "lightweight-charts";
import { getChartOptions } from "./options";
import type { Candlestick } from "@generated/common/basic-types_pb";
import type { PatternSearchResult } from "@generated/query/pattern_pb";
import type { TAResponseV1 } from "@generated/ta/technical_analysis";
import {
  BaseChartState,
  createBaseChartState,
  PickChartParams,
} from "../helpers/state";

export type MainChartParams = PickChartParams<
  MainChartState,
  "frequency" | "candles" | "patterns" | "chartType" | "technicals" | "markers"
>;

export type MainChartState = BaseChartState & {
  candles: Candlestick.AsObject[];
  baseSeries: ISeriesApi<"Candlestick" | "Line"> | null;
  patterns: PatternSearchResult.AsObject[];
  currentPatternSeries: ISeriesApi<"Line">[];
  chartType: string;
  technicals: TAResponseV1 | null;
  currentTechnicalMaSeries: ISeriesApi<"Line">[];
  currentVolumeSeries: ISeriesApi<"Histogram">[];
  markers: ScreeningMatch[];
};

export const createChartState = (params: MainChartParams): MainChartState => {
  return {
    ...createBaseChartState(params, getChartOptions),
    candles: [],
    baseSeries: null,
    patterns: [],
    currentPatternSeries: [],
    technicals: null,
    currentTechnicalMaSeries: [],
    currentVolumeSeries: [],
    isDisposed: false,
    chartType: params.chartType,
    markers: [],
  };
};
