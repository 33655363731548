import React, { useCallback, useRef } from "react";
import {
  ButtonHolder,
  Checkbox,
  CheckboxHolder,
  CheckboxLabel,
  Container,
  Dialog,
  Header,
  TermsBlock,
  Title,
  Button,
} from "./TermsDialog.style";
import { useAuth0 } from "@auth0/auth0-react";

import {
  CURRENT_TERMS_VERSION,
  TERMS_KEY,
} from "../terms_dialog_wrapper/TermsDialogWrapper.helpers";
import { toast } from "react-toastify";
import Markdown from "react-markdown";
import remarkGfm from "remark-gfm";
import { termsContent } from "./TermsDialog.text";

type TermsDialogProps = {
  onAcceptTerms: () => void;
};

const TermsDialog = ({ onAcceptTerms }: TermsDialogProps) => {
  const divRef = useRef<HTMLDivElement | null>(null);
  const [canAccept, setCanAccept] = React.useState(false);
  const [shouldSubscribe, setShouldSubscribe] = React.useState(false);
  const { getAccessTokenSilently } = useAuth0();

  const onChangeCheckbox = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setCanAccept(event.target.checked);
    },
    []
  );

  const onChangeNewsletterSub = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setShouldSubscribe(event.target.checked);
    },
    []
  );

  return (
    <Container data-testid="terms-dialog">
      <Dialog>
        <Header>
          <Title>Terms of Service</Title>
        </Header>
        <TermsBlock ref={divRef} data-testid="terms-block">
          <Markdown remarkPlugins={[[remarkGfm, { singleTilde: false }]]}>
            {termsContent}
          </Markdown>
        </TermsBlock>
        <ButtonHolder>
          <CheckboxHolder>
            <Checkbox
              type={"checkbox"}
              id="terms-of-service"
              data-testid={"terms-checkbox"}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                onChangeCheckbox(event);
              }}
            />
            <CheckboxLabel htmlFor="terms-of-service">
              I have read and accept the Terms of Service above*
            </CheckboxLabel>
          </CheckboxHolder>
          <CheckboxHolder>
            <Checkbox
              id="newsletter"
              type={"checkbox"}
              data-testid={"newsletter-checkbox"}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                onChangeNewsletterSub(event);
              }}
            />
            <CheckboxLabel htmlFor="newsletter">
              I would like to receive exclusive email updates and offers
            </CheckboxLabel>
          </CheckboxHolder>
          <Button
            disabled={!canAccept}
            onClick={() => {
              getAccessTokenSilently()
                .then((token) => {
                  fetch("/api/terms", {
                    method: "POST",
                    headers: {
                      Accept: "application/json",
                      "Content-Type": "application/json",
                      Authorization: `Bearer ${token}`,
                    },
                    body: JSON.stringify({
                      termsVersion: CURRENT_TERMS_VERSION,
                      subscribeNewsletter: shouldSubscribe,
                    }),
                  })
                    .then((res) => res.json())
                    .then((data) => {
                      if (!data) return;
                      localStorage.setItem(TERMS_KEY, "true");
                      onAcceptTerms();
                    })
                    .catch(() => {
                      // TODO: temporary work around if the user service is down
                      onAcceptTerms();
                      //toast.error("Failed to accept terms");
                    });
                })
                .catch(() =>
                  toast.error("Unauthorized user, please log in again")
                );
            }}
            data-testid="accept-button"
          >
            Accept
          </Button>
        </ButtonHolder>
      </Dialog>
    </Container>
  );
};
export default TermsDialog;
