// eslint-disable-next-line max-len
import { Condition } from "@components/condition_selector/ConditionSelector.component";
import { MovingAverageType } from "@generated/ta/moving_averages";

export const sigmaScriptPresets: {
  scripts: Condition[];
} = {
  scripts: [
    {
      id: 1,
      name: "Golden Cross",
      sigmaScript: "CrossAbove(SMA(50), SMA(200))",
      metadata: {
        patternTypes: [],
        technicals: [
          {
            name: "SMA 50",
            ma: { window_length: 50, kind: MovingAverageType.SMA },
          },
          {
            name: "SMA 200",
            ma: { window_length: 200, kind: MovingAverageType.SMA },
          },
        ],
      },
      category: "Strategies",
    },
    {
      id: 2,
      name: "Death Cross",
      sigmaScript: "CrossUnder(SMA(50), SMA(200))",
      category: "Strategies",
      metadata: {
        patternTypes: [],
        technicals: [
          {
            name: "SMA 50",
            ma: { window_length: 50, kind: MovingAverageType.SMA },
          },
          {
            name: "SMA 200",
            ma: { window_length: 200, kind: MovingAverageType.SMA },
          },
        ],
      },
    },
    {
      id: 3,
      name: "Bullish Turnaround",
      category: "Strategies",
      sigmaScript: "DoubleBottom() && RSI() < 0.45",
      metadata: {
        patternTypes: [0],
        technicals: [
          {
            name: "RSI",

            rsi: { main_kind: 1, zone: 0.45, source: 0, main_period: 14 },
          },
        ],
      },
    },
    {
      id: 4,
      name: "Bearish Turnaround",
      category: "Strategies",
      sigmaScript: "DoubleTop() && RSI() > 0.65",
      metadata: {
        patternTypes: [1],
        technicals: [
          {
            name: "RSI",

            rsi: { main_kind: 1, zone: 0.65, source: 0, main_period: 14 },
          },
        ],
      },
    },
    {
      id: 5,
      name: "Oversold Turnaround",
      category: "Strategies",
      sigmaScript: "StochasticOscillator() < 0.3 && Close() < SMA(100)",
      metadata: {
        patternTypes: [],
        technicals: [
          {
            name: "SMA 100",

            ma: { kind: MovingAverageType.SMA, window_length: 100 },
          },
        ],
      },
    },
    {
      id: 6,
      name: "Overbought Turnaround",
      category: "Strategies",
      sigmaScript: "StochasticOscillator() > 0.7 && Close() > SMA(100)",
      metadata: {
        patternTypes: [],
        technicals: [
          {
            name: "SMA 100",

            ma: { kind: MovingAverageType.SMA, window_length: 100 },
          },
        ],
      },
    },
    {
      id: 7,
      category: "Strategies",
      name: "Upper Bollinger Band exceeds SMA 200",
      sigmaScript: "BollingerBands(20,2.0,UPPER) > SMA(200)",
      metadata: {
        patternTypes: [],
        technicals: [
          {
            name: "SMA 100",

            ma: { kind: MovingAverageType.SMA, window_length: 200 },
          },
        ],
      },
    },
    {
      id: 8,
      category: "Strategies",
      name: "EMA 50 exceeds 200 EMA",
      sigmaScript: "EMA(50) > EMA(200)",
      metadata: {
        patternTypes: [],
        technicals: [
          {
            name: "EMA 50",

            ma: { kind: MovingAverageType.EMA, window_length: 50 },
          },
          {
            name: "EMA 200",

            ma: { kind: MovingAverageType.EMA, window_length: 200 },
          },
        ],
      },
    },
    {
      id: 9,
      category: "Patterns",
      name: "Standard Alert: Double Bottom",
      sigmaScript: "DoubleBottom()",
      metadata: {
        patternTypes: [0],
        technicals: [],
      },
    },
    {
      id: 10,
      name: "Standard Alert: Double Top",
      category: "Patterns",
      sigmaScript: "DoubleTop()",
      metadata: {
        patternTypes: [1],
        technicals: [],
      },
    },
    {
      id: 11,
      name: "Standard Alert: Triple Bottom",
      category: "Patterns",
      sigmaScript: "TripleBottom()",
      metadata: {
        patternTypes: [2],
        technicals: [],
      },
    },
    {
      id: 12,
      name: "Standard Alert: Triple Top",
      category: "Patterns",
      sigmaScript: "TripleTop()",
      metadata: {
        patternTypes: [3],
        technicals: [],
      },
    },
    {
      id: 13,
      name: "Standard Alert: Rounded Bottom",
      category: "Patterns",
      sigmaScript: "RoundedBottom()",
      metadata: {
        patternTypes: [6],
        technicals: [],
      },
    },
    {
      id: 14,
      name: "Standard Alert: Rounded Top",
      category: "Patterns",
      sigmaScript: "RoundedTop()",
      metadata: {
        patternTypes: [7],
        technicals: [],
      },
    },
    {
      id: 15,
      name: "Bullish MACD Convergence",
      category: "Strategies",
      sigmaScript: "MACD(VALUE) > MACD(SIGNAL)",
    },
    {
      id: 16,
      name: "Bearish MACD Divergence",
      category: "Strategies",
      sigmaScript: "MACD(VALUE) < MACD(SIGNAL)",
    },
  ],
};
