import styled from "styled-components";
import { ButtonSize } from "./Button.component";
import { textStyles } from "@utils/textStyles";

type TextProps = {
  theme: {
    infoText: string;
    successText: string;
    warningText: string;
    errorText: string;
  };
};

const sizeStyles: { [key: string]: string } = {
  xs: `
  padding: 0.38rem 0.5rem;
  ${textStyles.textSmBold};
  `,

  sm: `
  padding: 0.5rem;
  ${textStyles.textSmBold};
  `,

  base: `
  padding: 0.75rem;
  ${textStyles.textBaseBold};
  `,

  lg: `
  padding: 0.75rem 1rem;
  ${textStyles.textLgBold};
  `,

  xl: `
  padding: 1rem 1.5rem;
  ${textStyles.textLgBold};
  `,
};

const textColourStyles: { [key: string]: string } = {
  info: `
  color: ${(props: TextProps) => props.theme.infoText};
  `,
  success: `
  color: ${(props: TextProps) => props.theme.successText};
  `,
  warning: `
  color: ${(props: TextProps) => props.theme.warningText};
  `,
  error: `
  color: ${(props: TextProps) => props.theme.errorText};
  `,
};

const Container = styled.div<{
  size: ButtonSize; textColour?: string, disabled: boolean }>`
  cursor: pointer;
  border-radius: 0.5rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  background-color: ${(props) => props.theme.instrumentButtonBg};
  color: ${(props) => props.theme.buttonSecondaryText};
  ${(props) => sizeStyles[props.size]};

  // Disabled styles
  opacity: ${(props) => (props.disabled ? "0.5" : "1")};
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};

  @media (max-width: 768px) {
    align-self: stretch;
  }
`;

const Text = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-wrap: nowrap;
`;

const Primary = styled(Container)<{ textColour?: string }>`
  background-color: ${(props) => props.theme.buttonPrimary};
  color: ${(props) => props.theme.buttonPrimaryText};
  ${(props) => (props.textColour ? textColourStyles[props.textColour] : "")};
`;

const Secondary = styled(Container)<{ textColour?: string }>`
  background-color: ${(props) => props.theme.buttonSecondary};
  color: ${(props) => props.theme.buttonSecondaryText};
  ${(props) => sizeStyles[props.size]};
  ${(props) => (props.textColour ? textColourStyles[props.textColour] : "")};
`;

const Tertiary = styled(Container)<{ textColour?: string }>`
  background-color: ${(props) => props.theme.buttonTertiary};
  color: ${(props) => props.theme.buttonTertiaryText};
  ${(props) => sizeStyles[props.size]};
  ${(props) => (props.textColour ? textColourStyles[props.textColour] : "")};
`;

const Destructive = styled(Container)<{ textColour?: string }>`
  background-color: ${(props) => props.theme.buttonDestructive};
  color: ${(props) => props.theme.buttonDestructiveText};
  ${(props) => sizeStyles[props.size]};
  ${(props) => (props.textColour ? textColourStyles[props.textColour] : "")};
`;

const Gold = styled(Container)<{ textColour?: string }>`
  background: ${(props) => props.theme.label.background.subscription};
  color: ${(props) => props.theme.buttonPrimaryText};
  ${(props) => sizeStyles[props.size]};
  ${(props) => (props.textColour ? textColourStyles[props.textColour] : "")};
`;

const Transparent = styled(Container)<{ textColour?: string }>`
  background-color: transparent;
  color: ${(props) => props.theme.buttonTransparentText};
  ${(props) => sizeStyles[props.size]};
  ${(props) => (props.textColour ? textColourStyles[props.textColour] : "")};
`;

const Filter = styled(Container)<{ textColour?: string }>`
  background-color: ${(props) => props.theme.buttonTertiary};
  color: ${(props) => props.theme.buttonTertiaryText};
  ${(props) => sizeStyles[props.size]};
  ${(props) => (props.textColour ? textColourStyles[props.textColour] : "")};
  border-radius: 0.25rem;
  align-self: stretch;
`;

export {
  Container,
  Text,
  Primary,
  Secondary,
  Tertiary,
  Destructive,
  Transparent,
  Filter,
  Gold,
};
