import React from "react";
import Button from "../button/Button.component";
import { Modal } from "../modal/Modal.component";
import { InfoModalProps } from "./Plans.types";

export const subTabs = [
  {
    title: "Monthly",
    id: "1",
  },
  {
    title: "Annually",
    id: "2",
  },
];

export type PlanItemData = {
  title: string;
  price: string;
  annualPrice?: string;
  suffix: string;
  previousPrice: string;
  priceRecurringUnit: string;
  priceDescription: string;
  includedItems: string[];
  comingSoonItems: string[];
  saving: string;
};

export const planItemsMonthly: PlanItemData[] = [
  {
    title: "Lite",
    price: "$10",
    previousPrice: "$20/month",
    priceRecurringUnit: "/month",
    priceDescription: "$8.33/month if billed annually",
    includedItems: [
      "Crypto",
      "Forex",
      "AI Market Analysis",
      "Unlimited monthly Alerts",
      "Screening",
    ],
    comingSoonItems: ["Backtesting*"],
    suffix: "- Monthly",
    saving: "$10",
  },
  {
    title: "Pro",
    price: "$20",
    previousPrice: "$30/month",
    priceRecurringUnit: "/month",
    priceDescription: "$16.67/month if billed annually",
    includedItems: [
      "Crypto",
      "Forex",
      "AI Market Analysis",
      "Unlimited monthly Alerts",
      "Telegram Alerts",
      "Screening",
    ],
    comingSoonItems: ["UK, EU & Swiss Equities*", "Backtesting*"],
    suffix: "- Monthly",
    saving: "$10",
  },
  {
    title: "Ultimate",
    price: "$35",
    previousPrice: "$45/month",
    priceRecurringUnit: "/month",
    priceDescription: "$29.17/month if billed annually",
    includedItems: [
      "Crypto",
      "Forex",
      "US Equities",
      "AI Market Analysis",
      "Unlimited monthly Alerts",
      "Telegram Alerts",
      "Screening",
    ],
    comingSoonItems: [
      "UK, EU & Swiss Equities*",
      "Backtesting*",
      "Complex Condition Alerts*",
    ],
    suffix: "- Monthly",
    saving: "$10",
  },
];
export const planItemsAnnually: PlanItemData[] = [
  {
    title: "Lite",
    price: "$8.33",
    annualPrice: "$100/year",
    previousPrice: "$200/year",
    priceRecurringUnit: "/month",
    priceDescription: "",
    includedItems: [
      "Crypto",
      "Forex",
      "AI Market Analysis",
      "Unlimited monthly Alerts",
      "Screening",
    ],
    comingSoonItems: ["Backtesting*"],
    suffix: "- Annual",
    saving: "$100",
  },
  {
    title: "Pro",
    price: "$16.67",
    annualPrice: "$200/year",
    previousPrice: "$300/year",
    priceRecurringUnit: "/month",
    priceDescription: "",
    includedItems: [
      "Crypto",
      "Forex",
      "AI Market Analysis",
      "Unlimited monthly Alerts",
      "Telegram Alerts",
      "Screening",
    ],
    comingSoonItems: ["UK, EU & Swiss Equities*", "Backtesting*"],
    suffix: "- Annual",
    saving: "$100",
  },
  {
    title: "Ultimate",
    price: "$29.17",
    annualPrice: "$350/year",
    previousPrice: "$450/year",
    priceRecurringUnit: "/month",
    priceDescription: "",
    includedItems: [
      "Crypto",
      "Forex",
      "US Equities",
      "AI Market Analysis",
      "Unlimited monthly Alerts",
      "Telegram Alerts",
      "Screening",
    ],
    comingSoonItems: [
      "UK, EU & Swiss Equities*",
      "Backtesting*",
      "Complex Condition Alerts*",
    ],
    suffix: "- Annual",
    saving: "$100",
  },
];

export const InfoModal = ({ onClick, isVisible }: InfoModalProps) => {
  return (
    <Modal
      hideCancel={true}
      modalTitle="Try Leopard for free"
      modalDescription={"Leopard AI is currently in beta. Try it now for free!"}
      Button1={<Button text="Close" type="primary" onClick={onClick} />}
      isVisible={isVisible}
    />
  );
};
