import { ScreeningFilter } from "../../ScreeningFilter";
import { CustomField } from "../custom_dialog/CustomFilterDialog.types";
import {
  InstrumentAndPrice,
  PriceFilterValue,
} from "./PriceScreeningFilter.types";

export const priceScreeningFilter: ScreeningFilter<
  PriceFilterValue,
  InstrumentAndPrice[]
> = {
  apply: (instrumentList, value) => value.operation(instrumentList),
};
export const PRICE_CUSTOM_TITLE = "Set Manually";
export const priceScreeningFilterValues: PriceFilterValue[] = [
  {
    title: "Price",
    operation: (instrumentList) => instrumentList,
  },
  {
    title: "100 and above",
    operation: (instrumentList) =>
      instrumentList.filter((instrument) => Number(instrument.close) >= 100),
  },
  {
    title: "10 to 100",
    operation: (instrumentList) =>
      instrumentList.filter(
        (instrument) =>
          Number(instrument.close) >= 10 && Number(instrument.close) <= 100
      ),
  },
  {
    title: "10 and below",
    operation: (instrumentList) =>
      instrumentList.filter(
        (instrument) =>
          instrument.close !== "" && Number(instrument.close) <= 10
      ),
  },
  {
    title: "5 and below",
    operation: (instrumentList) =>
      instrumentList.filter(
        (instrument) => instrument.close !== "" && Number(instrument.close) <= 5
      ),
  },
];

export const PriceCustomFields: CustomField<PriceFilterValue>[] = [
  {
    title: "Change %",
    operation: "Above",
    mainInput: {
      type: "numeric",
      label: "0",
    },
    operationFunction: (value) => {
      return {
        title: `Above ${value}`,
        operation: (instrumentList) =>
          instrumentList.filter(
            (instrument) => Number(instrument.close) > value
          ),
      };
    },
  },
  {
    title: "Change %",
    operation: "Below",
    mainInput: {
      type: "numeric",
      label: "0",
    },
    operationFunction: (value) => {
      return {
        title: `Below ${value}`,
        operation: (instrumentList) =>
          instrumentList.filter(
            (instrument) =>
              instrument.close !== "" && Number(instrument.close) < value
          ),
      };
    },
  },
  {
    title: "Change %",
    operation: "Between",
    mainInput: {
      type: "numeric",
      label: "from",
    },
    secondaryInput: {
      type: "numeric",
      label: "to",
    },
    operationFunction: (value, value2) => {
      return {
        title: `Between ${value} and ${value2}`,
        operation: (instrumentList) =>
          instrumentList.filter(
            (instrument) =>
              Number(instrument.close) >= value &&
              value2 &&
              Number(instrument.close) <= value2
          ),
      };
    },
  },
];
