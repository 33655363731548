// eslint-disable-next-line max-len
import { mapInstrumentClassToName } from "@components/instrument_dialog/instrumentClassMappings";
import { Instrument } from "@generated/common/basic-types_pb";

export const getSelectedInstrumentClass = (
  instruments: Instrument.AsObject[],
  hashcode: string
) => {
  const selectedInstrument =
    instruments.find((instrument) => instrument.hashcode === hashcode) ??
    instruments[0];
  return selectedInstrument ? selectedInstrument.instrumentClass : 0;
};

export const capitalizeFirstLetter = (str: string): string => {
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};

export const getInstrumentLabel = (instrument: number | undefined) => {
  if (instrument === undefined || instrument === null) return "";
  return `${capitalizeFirstLetter(mapInstrumentClassToName(instrument))}`;
};
