import { useAuth0 } from "@auth0/auth0-react";
import {
  CandleResponse,
  ChangeSinceMidnightSimplified,
  Portfolio,
} from "@common/types";
// eslint-disable-next-line max-len
import { ScreeningResult } from "@components/screening_result_table/ScreeningResultTable.component";
import { CandleFrequency, Instrument } from "@generated/common/basic-types_pb";
import {
  InstrumentAlertsV2,
  ScreeningResponseV2,
  ScreeningResultV2,
} from "@generated/job/screening_pb";
import { useScreeningData } from "@hooks/useScreeningData";
import { getSafeString } from "@utils/getSafe";
import { pollUntil } from "@utils/poll";
import React, {
  createContext,
  PropsWithChildren,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Slide, toast, ToastContainer } from "react-toastify";
import { useTheme } from "styled-components";
import { useAppContext } from "./AppContext";

type ScreeningContextType = {
  screeningResults: ScreeningResult[];
  frequency: typeof CandleFrequency | number;
  loading: boolean;
  setLoading: (loading: boolean) => void;
  setInstrumentList: (instrumentList: string[]) => void;
  setFrequency: (frequency: typeof CandleFrequency | number) => void;
  setCondition: (condition: string) => void;
  enableRunScreening: boolean;
  latestCandles: CandleResponse[];
  midnightChange: ChangeSinceMidnightSimplified[];
  runScreening: () => void;
  showConditionBuilder: boolean;
  setShowConditionBuilder: (show: boolean) => void;
  instruments: Instrument.AsObject[];
  setSelectedHashcodes: (hashcodes: string[]) => void;
  selectedHashcodes: string[];
  setSelectedPortfolio: (portfolio: Portfolio) => void;
  selectedPortfolio: Portfolio | null;
  searchQuery: string;
  setSearchQuery: (query: string) => void;
  condition: string;
};

const defaultScreeningContext: ScreeningContextType = {
  screeningResults: [],
  frequency: CandleFrequency.H1,
  loading: false,
  setLoading: () => {
    return;
  },
  setInstrumentList: (instrumentList: string[]) => {
    return instrumentList;
  },
  setFrequency: (frequency: typeof CandleFrequency | number) => {
    return frequency;
  },
  setCondition: (condition: string) => {
    return condition;
  },
  enableRunScreening: false,
  latestCandles: [],
  midnightChange: [],
  runScreening: () => {
    return;
  },
  showConditionBuilder: false,
  setShowConditionBuilder: (show: boolean) => {
    return show;
  },
  instruments: [],
  setSelectedHashcodes: (hashcodes: string[]) => {
    return hashcodes;
  },
  selectedHashcodes: [],
  setSelectedPortfolio: (portfolio: Portfolio) => {
    return portfolio;
  },
  selectedPortfolio: null,
  searchQuery: "",
  setSearchQuery: () => {
    return;
  },
  condition: "",
};

const getLastMatch = (alert: InstrumentAlertsV2.AsObject) => {
  const signals = alert.signalsList;
  if (signals.length === 0) return "";
  const lastSignal = signals[signals.length - 1];
  if (lastSignal.timeList.length === 0) return "";
  const lastMatch = lastSignal.timeList[lastSignal.timeList.length - 1];
  return new Date(lastMatch / 1000000).toString();
};

const getCount = (alert: InstrumentAlertsV2.AsObject) => {
  const signals = alert.signalsList;
  if (signals.length === 0) return 0;
  return signals[0].timeList.length;
};

export const ScreeningContext = createContext(defaultScreeningContext);

const getMatches = (alert: InstrumentAlertsV2.AsObject) => {
  const signals = alert.signalsList;
  if (signals.length === 0) return [];
  return signals[0].timeList.map((time, index) => {
    return { time: time / 1000000000, tag: index.toString() };
  });
};

const ScreeningProvider = (props: PropsWithChildren<object>) => {
  const { getAccessTokenSilently } = useAuth0();
  const { screeningResults, setScreeningResults } = useAppContext();
  const [enableRunScreening, setEnableRunScreening] = React.useState(false);
  const [condition, setCondition] = useState("");

  const memoScreeningResults = useMemo(
    () => screeningResults,
    [screeningResults]
  );
  const [jobId, setJobId] = useState("");
  const [loading, setLoading] = useState(false);
  const [selectedHashcodes, setSelectedHashcodes] = React.useState<string[]>(
    []
  );
  const [selectedPortfolio, setSelectedPortfolio] =
    React.useState<Portfolio | null>(null);
  const [showConditionBuilder, setShowConditionBuilder] =
    useState<boolean>(false);
  const [searchQuery, setSearchQuery] = useState("");
  const theme = useTheme();
  const {
    instruments,
    midnightChange,
    latestCandles,
    frequency,
    setFrequency,
    instrumentList,
    setInstrumentList,
  } = useScreeningData();

  useEffect(() => {
    if (condition !== "" && instrumentList.length > 0)
      setEnableRunScreening(true);
    else {
      setEnableRunScreening(false);
    }
  }, [JSON.stringify(instrumentList), condition]);

  const runScreening = () => {
    if (!enableRunScreening) {
      toast.error("Please select instruments and condition to run screening");
      return;
    }
    getAccessTokenSilently().then((token) => {
      fetch("/api/start-screening", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          hashcodes: instrumentList,
          frequency: frequency,
          condition: condition,
        }),
      })
        .then((res) => res.json())
        .then((data) => {
          setLoading(true);
          setJobId(data.metadata.jobId);
        });
    });
  };

  useEffect(() => {
    if (jobId === "") return;
    pollUntil(
      `/api/poll-screening?jobId=${jobId}`,
      {},
      (data: unknown) => {
        if ((data as ScreeningResponseV2.AsObject).metadata) {
          setJobId(
            (data as ScreeningResponseV2.AsObject).metadata?.jobId || ""
          );
        } else {
          const instrumentAlertsMap = (data as ScreeningResultV2.AsObject)
            .instrumentAlertsMap;
          const alerts: InstrumentAlertsV2.AsObject[] = instrumentAlertsMap.map(
            (instrumentAlertPair) => {
              return instrumentAlertPair[1];
            }
          );
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          const prices: string[] = instrumentAlertsMap.map(
            (instrumentAlert) => {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              return instrumentAlert[2];
            }
          );
          const results = alerts.map((alert, index) => {
            const instrument = instruments.find(
              (instrument) => instrument.hashcode === alert.instrument
            );
            return {
              hashcode: getSafeString(instrument?.hashcode),
              symbol: getSafeString(instrument?.symbol),
              name: getSafeString(instrument?.name),
              class: getSafeString(instrument?.instrumentClass.toString()),
              price: prices[index],
              matches: getMatches(alert),
              lastMatch: getLastMatch(alert),
              count: getCount(alert),
            };
          });
          setScreeningResults(results);
          setLoading(false);
        }
      },
      2000,
      (data: unknown) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        return data.finished !== 0;
      }
    );
  }, [jobId]);

  return (
    <ScreeningContext.Provider
      value={{
        screeningResults: memoScreeningResults,
        frequency,
        loading,
        setLoading,
        setInstrumentList,
        setFrequency,
        setCondition,
        enableRunScreening,
        latestCandles,
        midnightChange,
        runScreening,
        showConditionBuilder,
        setShowConditionBuilder,
        instruments,
        setSelectedHashcodes,
        selectedHashcodes,
        setSelectedPortfolio,
        selectedPortfolio,
        searchQuery,
        setSearchQuery,
        condition,
      }}
    >
      <ToastContainer
        toastStyle={{
          backgroundColor: theme?.toastProperties?.background,
        }}
        style={{
          maxWidth: "30rem",
          width: "100%",
          padding: "0.5rem",
          boxShadow: `0px 1px 2px -1px rgba(0, 0, 0, 0.10),
                  0px 4px 4px 0px rgba(0, 0, 0, 0.10)`,
        }}
        position={"top-left"}
        autoClose={5000}
        hideProgressBar={true}
        newestOnTop={true}
        transition={Slide}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
      />
      {props.children}
    </ScreeningContext.Provider>
  );
};

const useScreeningContext = () => useContext(ScreeningContext);

export { ScreeningProvider, useScreeningContext };
export type { ScreeningContextType };
