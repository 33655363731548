/* eslint-disable max-len */
import styled from "styled-components";
import { textStyles } from "@client/src/utils/textStyles";

export const SearchContainer = styled.div`
  background-color: ${(props) => props.theme.searchbarBg};
  display: flex;
  border: transparent;
  border-radius: 0.375rem;
  position: relative;
  flex-direction: row;
  align-items: center;
  padding: 8px;
  gap: 8px;
`;

export const IconContainer = styled.div`
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StyledInput = styled.input`
  background-color: ${(props) => props.theme.searchbarBg};
  border: transparent;
  display: flex;
  width: 100%;
  border-radius: 0.375rem;
  ${textStyles.textBase}
  color: ${(props) => props.theme.searchbarText};

  &:focus {
    outline: 2px solid transparent;
    outline-offset: 2px;
  }

  &::-webkit-search-cancel-button {
    -webkit-appearance: none;
  }
`;

export const ClearIcon = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  & > {
    height: 1.5rem;
    width: 1.5rem;
  }
`;
