import {
  CustomData,
  IChartApi,
  ISeriesApi,
  MouseEventParams,
  Time,
} from "lightweight-charts";

export const getCrosshairDataPoint = (
  series: ISeriesApi<"Candlestick" | "Line">,
  param: MouseEventParams<Time>,
) => {
  if (!param.time) {
    return null;
  }
  const dataPoint = param.seriesData.get(series);
  return dataPoint || null;
};

export const syncCrosshair = (
  chart: IChartApi,
  series: ISeriesApi<"Candlestick" | "Line">,
  dataPoint: CustomData<Time> | null,
) => {
  if (dataPoint) {
    try {
      chart.setCrosshairPosition(dataPoint.value, dataPoint.time, series);
    } catch (e) {
      console.warn("Attempted to set crosshair position on disposed chart");
    }
    return;
  }
  chart.clearCrosshairPosition();
};
