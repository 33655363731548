import styled from "styled-components";
import { baseStyle, textStyles } from "@utils/textStyles";
import { effectStyles } from "@utils/effectStyles";

export const Overlay = styled.div`
  position: fixed;
  box-sizing: border-box;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${(props) => props.theme.modalOverlayBg};
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1001;
  padding: 1rem;
`;

export const Container = styled.div`
  ${baseStyle};
  z-index: 1001;
  position: relative;
  border-radius: 0.5rem;
  background: ${(props) => props.theme.modalBg};
  display: flex;
  width: 35.5625rem;
  padding: 1.5rem;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
  ${effectStyles.grey.shadowLg};
  @media (max-width: 768px) {
    width: 100%;
    padding: 1rem;
    align-items: center;
  }
`;

export const ContentContainer = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 1rem;
  align-self: stretch;
  align-items: center;
  justify-content: center;

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 0.25rem;
  }
`;

export const TextContainer = styled.div`
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.25rem;
  flex: 1 0 0;

  @media (max-width: 768px) {
    align-items: center;
    width: 100%;
  }
`;

export const ModalIconContainer = styled.div<{ type: "default" | "red" }>`
  display: flex;
  width: 3rem;
  height: 3rem;
  flex-shrink: 0;
  background: ${(props) =>
    props.type === "red"
      ? props.theme.modalIconRedBg
      : props.theme.modalIconBg};
  color: ${(props) =>
    props.type === "red" ? props.theme.modalIconRed : props.theme.modalIcon};
  border-radius: 999rem;
  align-items: center;
  justify-content: center;

  // for the children in the container (i.e IconImage)
  & > * {
    width: 1.5rem;
    height: 1.5rem;
  }
`;

export const Title = styled.div`
  ${textStyles.textLgBold}
`;

export const Body = styled.div`
  ${textStyles.textSm}
  color: ${(props) => props.theme.subtextColor};
  white-space: pre-wrap;
`;

export const ErrorText = styled.div`
  ${textStyles.textSm}
  color: #F00;
`;

export const Input = styled.input`
  ${baseStyle};
  display: flex;
  box-sizing: border-box;
  padding: 0.75rem 1rem;
  align-items: center;
  gap: 0.5rem;
  align-self: stretch;
  border-radius: 0.25rem;
  border: 1px solid ${(props) => props.theme.inputBorderColor || "#000"};
  background: ${(props) => props.theme.inputNumericBg};

  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const InputError = styled.input`
  ${baseStyle};
  display: flex;
  box-sizing: border-box;
  padding: 0.75rem 1rem;
  align-items: center;
  gap: 0.5rem;
  align-self: stretch;
  border-radius: 0.25rem;
  border: 1px solid ${(props) => props.theme.errorBorderColour || "#F00"};
  background: ${(props) => props.theme.inputNumericBg};

  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const TwoButtons = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 1rem;
  align-self: stretch;
  flex-direction: row-reverse;

  @media (max-width: 768px) {
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
`;
