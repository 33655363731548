import React from "react";
import Navbar from "@client/src/components/navbar/Navbar.component";
import Footer from "@client/src/components/footer/Footer.component";
import {
  Container,
  Content,
  ContentWrapper,
  Text,
  Title,
  Message,
  SubMessage,
  ButtonContainer,
} from "./fullPageError.style";
import Button from "@client/src/components/button/Button.component";
import { HiArrowRight } from "react-icons/hi2";

const render404Error = (
) => {
  return (
    <Content>
      <Text>
        <Title>404</Title>
        <Message>Page not found</Message>
        <SubMessage>
          Sorry, we couldn't find the page you're looking for.
        </SubMessage>
      </Text>
      <ButtonContainer>
        <Button
          text="Go back home"
          onClick={() => {
            window.location.href = "/";
          }}
          type="transparent"
          size="sm"
          rightIcon={<HiArrowRight />}
        />
      </ButtonContainer>
    </Content>
  );
};

const renderGenericError = (
) => {
  return (
    <Content>
      <Text>
        <Title>Oops!</Title>
        <Message>Something went wrong</Message>
        <SubMessage>
        Please try again, or come back later
        </SubMessage>
      </Text>
      <ButtonContainer>
        <Button
          text="Go back home"
          onClick={() => {
            window.location.href = "/";
          }}
          type="transparent"
          size="sm"
          rightIcon={<HiArrowRight />}
        />
      </ButtonContainer>
    </Content>
  );
};

const renderTimeoutError = (
) => {
  return (
    <Content>
      <Text>
        <Title>Timeout</Title>
        <Message>Your session has expired due to inactivity</Message>
        <SubMessage>
        Don't worry, log back in and you'll return where you left off
        </SubMessage>
      </Text>
      <ButtonContainer>
        <Button
          text="Log in"
          onClick={() => {
            window.location.href = "/";
          }}
          type="transparent"
          size="sm"
          rightIcon={<HiArrowRight />}
        />
      </ButtonContainer>
    </Content>
  );
};

const renderTechnicalDifficultiesError = (
) => {
  return (
    <Content>
      <Text>
        <Title>Sorry!</Title>
        <Message>We are experiencing technical difficulties</Message>
        <SubMessage>
        Our team is working hard to resolve this issue, and we will be up
        and running soon.
        </SubMessage>
      </Text>
    </Content>
  );
};

const renderErrorPage = (error: number) => {
  type ErrorPageFunctions = {
    [key: number]: () => React.JSX.Element;
  };

  const ERROR_PAGES: ErrorPageFunctions = {
    404: render404Error,
    500: renderGenericError,
    503: renderTechnicalDifficultiesError,
    504: renderTimeoutError,
  };

  const renderFunction = ERROR_PAGES[error];
  if (renderFunction) {
    return renderFunction();
  }

  return renderGenericError();
};

const ErrorPage = ({ errorStatus }: { errorStatus: number }) => {

  const navbarRef = React.useRef<HTMLDivElement>(null);

  return (
    <Container>
      <Navbar ref={navbarRef} />
      <ContentWrapper>
        {renderErrorPage(errorStatus)}
      </ContentWrapper>
      <Footer />
    </Container>
  );
};

export default ErrorPage;
