import { themes } from "@client/src/themes";
import { textStyles } from "@client/src/utils/textStyles";
import { styled } from "styled-components";

const foreColor = themes["dark"]["navBg"];

export const InboxCounterContainer = styled.div`
  position: absolute;
  display: inline-flex;
  box-sizing: border-box;
  padding: 0.125rem 0.375rem;
  ${textStyles.alertElipse};
  justify-content: center;
  align-items: center;
  text-align: center;
  z-index: 100;
  background-color: ${(props) => props.theme.alertBellCounterBg};
  color: ${(props) => props.theme.alertBellCounterText};
  border-radius: 0.75rem;
  border: 1px solid ${foreColor};
  cursor: pointer;
  pointer-events: none;
  min-width: 1.25rem;
  height: 1.25rem;
  bottom: 0.25rem;
  left: 50%;
`;
