// source: pattern/common.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() {
  if (this) { return this; }
  if (typeof window !== 'undefined') { return window; }
  if (typeof global !== 'undefined') { return global; }
  if (typeof self !== 'undefined') { return self; }
  return Function('return this')();
}.call(null));

goog.exportSymbol('proto.ai.sigmafinancial.pattern.common.GPRSmoothingParameters', null, global);
goog.exportSymbol('proto.ai.sigmafinancial.pattern.common.Gaussian1DFilterParameter', null, global);
goog.exportSymbol('proto.ai.sigmafinancial.pattern.common.Gaussian1DFilterSigma', null, global);
goog.exportSymbol('proto.ai.sigmafinancial.pattern.common.PatternType', null, global);
goog.exportSymbol('proto.ai.sigmafinancial.pattern.common.ScoringParameters', null, global);
goog.exportSymbol('proto.ai.sigmafinancial.pattern.common.SmoothingMethod', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ai.sigmafinancial.pattern.common.GPRSmoothingParameters = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ai.sigmafinancial.pattern.common.GPRSmoothingParameters, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ai.sigmafinancial.pattern.common.GPRSmoothingParameters.displayName = 'proto.ai.sigmafinancial.pattern.common.GPRSmoothingParameters';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ai.sigmafinancial.pattern.common.Gaussian1DFilterParameter = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ai.sigmafinancial.pattern.common.Gaussian1DFilterParameter, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ai.sigmafinancial.pattern.common.Gaussian1DFilterParameter.displayName = 'proto.ai.sigmafinancial.pattern.common.Gaussian1DFilterParameter';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ai.sigmafinancial.pattern.common.ScoringParameters = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ai.sigmafinancial.pattern.common.ScoringParameters, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ai.sigmafinancial.pattern.common.ScoringParameters.displayName = 'proto.ai.sigmafinancial.pattern.common.ScoringParameters';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ai.sigmafinancial.pattern.common.GPRSmoothingParameters.prototype.toObject = function(opt_includeInstance) {
  return proto.ai.sigmafinancial.pattern.common.GPRSmoothingParameters.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ai.sigmafinancial.pattern.common.GPRSmoothingParameters} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ai.sigmafinancial.pattern.common.GPRSmoothingParameters.toObject = function(includeInstance, msg) {
  var f, obj = {
    alpha: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    lengthScale: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    lowerBound: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    upperBound: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    normalisation: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    paddingLength: jspb.Message.getFieldWithDefault(msg, 6, 0),
    windowLength: jspb.Message.getFieldWithDefault(msg, 7, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ai.sigmafinancial.pattern.common.GPRSmoothingParameters}
 */
proto.ai.sigmafinancial.pattern.common.GPRSmoothingParameters.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ai.sigmafinancial.pattern.common.GPRSmoothingParameters;
  return proto.ai.sigmafinancial.pattern.common.GPRSmoothingParameters.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ai.sigmafinancial.pattern.common.GPRSmoothingParameters} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ai.sigmafinancial.pattern.common.GPRSmoothingParameters}
 */
proto.ai.sigmafinancial.pattern.common.GPRSmoothingParameters.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setAlpha(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLengthScale(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLowerBound(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setUpperBound(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setNormalisation(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setPaddingLength(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setWindowLength(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ai.sigmafinancial.pattern.common.GPRSmoothingParameters.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ai.sigmafinancial.pattern.common.GPRSmoothingParameters.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ai.sigmafinancial.pattern.common.GPRSmoothingParameters} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ai.sigmafinancial.pattern.common.GPRSmoothingParameters.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAlpha();
  if (f !== 0.0) {
    writer.writeDouble(
      1,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeDouble(
      4,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeBool(
      5,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 6));
  if (f != null) {
    writer.writeUint32(
      6,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 7));
  if (f != null) {
    writer.writeUint32(
      7,
      f
    );
  }
};


/**
 * optional double alpha = 1;
 * @return {number}
 */
proto.ai.sigmafinancial.pattern.common.GPRSmoothingParameters.prototype.getAlpha = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.ai.sigmafinancial.pattern.common.GPRSmoothingParameters} returns this
 */
proto.ai.sigmafinancial.pattern.common.GPRSmoothingParameters.prototype.setAlpha = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional double length_scale = 2;
 * @return {number}
 */
proto.ai.sigmafinancial.pattern.common.GPRSmoothingParameters.prototype.getLengthScale = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.ai.sigmafinancial.pattern.common.GPRSmoothingParameters} returns this
 */
proto.ai.sigmafinancial.pattern.common.GPRSmoothingParameters.prototype.setLengthScale = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.ai.sigmafinancial.pattern.common.GPRSmoothingParameters} returns this
 */
proto.ai.sigmafinancial.pattern.common.GPRSmoothingParameters.prototype.clearLengthScale = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ai.sigmafinancial.pattern.common.GPRSmoothingParameters.prototype.hasLengthScale = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional double lower_bound = 3;
 * @return {number}
 */
proto.ai.sigmafinancial.pattern.common.GPRSmoothingParameters.prototype.getLowerBound = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.ai.sigmafinancial.pattern.common.GPRSmoothingParameters} returns this
 */
proto.ai.sigmafinancial.pattern.common.GPRSmoothingParameters.prototype.setLowerBound = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.ai.sigmafinancial.pattern.common.GPRSmoothingParameters} returns this
 */
proto.ai.sigmafinancial.pattern.common.GPRSmoothingParameters.prototype.clearLowerBound = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ai.sigmafinancial.pattern.common.GPRSmoothingParameters.prototype.hasLowerBound = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional double upper_bound = 4;
 * @return {number}
 */
proto.ai.sigmafinancial.pattern.common.GPRSmoothingParameters.prototype.getUpperBound = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.ai.sigmafinancial.pattern.common.GPRSmoothingParameters} returns this
 */
proto.ai.sigmafinancial.pattern.common.GPRSmoothingParameters.prototype.setUpperBound = function(value) {
  return jspb.Message.setField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.ai.sigmafinancial.pattern.common.GPRSmoothingParameters} returns this
 */
proto.ai.sigmafinancial.pattern.common.GPRSmoothingParameters.prototype.clearUpperBound = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ai.sigmafinancial.pattern.common.GPRSmoothingParameters.prototype.hasUpperBound = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional bool normalisation = 5;
 * @return {boolean}
 */
proto.ai.sigmafinancial.pattern.common.GPRSmoothingParameters.prototype.getNormalisation = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.ai.sigmafinancial.pattern.common.GPRSmoothingParameters} returns this
 */
proto.ai.sigmafinancial.pattern.common.GPRSmoothingParameters.prototype.setNormalisation = function(value) {
  return jspb.Message.setField(this, 5, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.ai.sigmafinancial.pattern.common.GPRSmoothingParameters} returns this
 */
proto.ai.sigmafinancial.pattern.common.GPRSmoothingParameters.prototype.clearNormalisation = function() {
  return jspb.Message.setField(this, 5, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ai.sigmafinancial.pattern.common.GPRSmoothingParameters.prototype.hasNormalisation = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional uint32 padding_length = 6;
 * @return {number}
 */
proto.ai.sigmafinancial.pattern.common.GPRSmoothingParameters.prototype.getPaddingLength = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.ai.sigmafinancial.pattern.common.GPRSmoothingParameters} returns this
 */
proto.ai.sigmafinancial.pattern.common.GPRSmoothingParameters.prototype.setPaddingLength = function(value) {
  return jspb.Message.setField(this, 6, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.ai.sigmafinancial.pattern.common.GPRSmoothingParameters} returns this
 */
proto.ai.sigmafinancial.pattern.common.GPRSmoothingParameters.prototype.clearPaddingLength = function() {
  return jspb.Message.setField(this, 6, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ai.sigmafinancial.pattern.common.GPRSmoothingParameters.prototype.hasPaddingLength = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional uint32 window_length = 7;
 * @return {number}
 */
proto.ai.sigmafinancial.pattern.common.GPRSmoothingParameters.prototype.getWindowLength = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.ai.sigmafinancial.pattern.common.GPRSmoothingParameters} returns this
 */
proto.ai.sigmafinancial.pattern.common.GPRSmoothingParameters.prototype.setWindowLength = function(value) {
  return jspb.Message.setField(this, 7, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.ai.sigmafinancial.pattern.common.GPRSmoothingParameters} returns this
 */
proto.ai.sigmafinancial.pattern.common.GPRSmoothingParameters.prototype.clearWindowLength = function() {
  return jspb.Message.setField(this, 7, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ai.sigmafinancial.pattern.common.GPRSmoothingParameters.prototype.hasWindowLength = function() {
  return jspb.Message.getField(this, 7) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ai.sigmafinancial.pattern.common.Gaussian1DFilterParameter.prototype.toObject = function(opt_includeInstance) {
  return proto.ai.sigmafinancial.pattern.common.Gaussian1DFilterParameter.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ai.sigmafinancial.pattern.common.Gaussian1DFilterParameter} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ai.sigmafinancial.pattern.common.Gaussian1DFilterParameter.toObject = function(includeInstance, msg) {
  var f, obj = {
    sigma: jspb.Message.getFieldWithDefault(msg, 1, 0),
    customSigma: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ai.sigmafinancial.pattern.common.Gaussian1DFilterParameter}
 */
proto.ai.sigmafinancial.pattern.common.Gaussian1DFilterParameter.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ai.sigmafinancial.pattern.common.Gaussian1DFilterParameter;
  return proto.ai.sigmafinancial.pattern.common.Gaussian1DFilterParameter.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ai.sigmafinancial.pattern.common.Gaussian1DFilterParameter} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ai.sigmafinancial.pattern.common.Gaussian1DFilterParameter}
 */
proto.ai.sigmafinancial.pattern.common.Gaussian1DFilterParameter.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.ai.sigmafinancial.pattern.common.Gaussian1DFilterSigma} */ (reader.readEnum());
      msg.setSigma(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setCustomSigma(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ai.sigmafinancial.pattern.common.Gaussian1DFilterParameter.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ai.sigmafinancial.pattern.common.Gaussian1DFilterParameter.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ai.sigmafinancial.pattern.common.Gaussian1DFilterParameter} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ai.sigmafinancial.pattern.common.Gaussian1DFilterParameter.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSigma();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getCustomSigma();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
};


/**
 * optional Gaussian1DFilterSigma sigma = 1;
 * @return {!proto.ai.sigmafinancial.pattern.common.Gaussian1DFilterSigma}
 */
proto.ai.sigmafinancial.pattern.common.Gaussian1DFilterParameter.prototype.getSigma = function() {
  return /** @type {!proto.ai.sigmafinancial.pattern.common.Gaussian1DFilterSigma} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.ai.sigmafinancial.pattern.common.Gaussian1DFilterSigma} value
 * @return {!proto.ai.sigmafinancial.pattern.common.Gaussian1DFilterParameter} returns this
 */
proto.ai.sigmafinancial.pattern.common.Gaussian1DFilterParameter.prototype.setSigma = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional double custom_sigma = 2;
 * @return {number}
 */
proto.ai.sigmafinancial.pattern.common.Gaussian1DFilterParameter.prototype.getCustomSigma = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.ai.sigmafinancial.pattern.common.Gaussian1DFilterParameter} returns this
 */
proto.ai.sigmafinancial.pattern.common.Gaussian1DFilterParameter.prototype.setCustomSigma = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ai.sigmafinancial.pattern.common.ScoringParameters.prototype.toObject = function(opt_includeInstance) {
  return proto.ai.sigmafinancial.pattern.common.ScoringParameters.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ai.sigmafinancial.pattern.common.ScoringParameters} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ai.sigmafinancial.pattern.common.ScoringParameters.toObject = function(includeInstance, msg) {
  var f, obj = {
    maxCombinedCost: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    minNCandles: jspb.Message.getFieldWithDefault(msg, 1, 0),
    longPatternBoost: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    shapeMagnitude: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    temporalDistortion: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    patternLookback: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ai.sigmafinancial.pattern.common.ScoringParameters}
 */
proto.ai.sigmafinancial.pattern.common.ScoringParameters.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ai.sigmafinancial.pattern.common.ScoringParameters;
  return proto.ai.sigmafinancial.pattern.common.ScoringParameters.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ai.sigmafinancial.pattern.common.ScoringParameters} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ai.sigmafinancial.pattern.common.ScoringParameters}
 */
proto.ai.sigmafinancial.pattern.common.ScoringParameters.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 5:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setMaxCombinedCost(value);
      break;
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setMinNCandles(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLongPatternBoost(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setShapeMagnitude(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTemporalDistortion(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setPatternLookback(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ai.sigmafinancial.pattern.common.ScoringParameters.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ai.sigmafinancial.pattern.common.ScoringParameters.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ai.sigmafinancial.pattern.common.ScoringParameters} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ai.sigmafinancial.pattern.common.ScoringParameters.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMaxCombinedCost();
  if (f !== 0.0) {
    writer.writeDouble(
      5,
      f
    );
  }
  f = message.getMinNCandles();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeDouble(
      4,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 6));
  if (f != null) {
    writer.writeDouble(
      6,
      f
    );
  }
};


/**
 * optional double max_combined_cost = 5;
 * @return {number}
 */
proto.ai.sigmafinancial.pattern.common.ScoringParameters.prototype.getMaxCombinedCost = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.ai.sigmafinancial.pattern.common.ScoringParameters} returns this
 */
proto.ai.sigmafinancial.pattern.common.ScoringParameters.prototype.setMaxCombinedCost = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional uint32 min_n_candles = 1;
 * @return {number}
 */
proto.ai.sigmafinancial.pattern.common.ScoringParameters.prototype.getMinNCandles = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.ai.sigmafinancial.pattern.common.ScoringParameters} returns this
 */
proto.ai.sigmafinancial.pattern.common.ScoringParameters.prototype.setMinNCandles = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional double long_pattern_boost = 2;
 * @return {number}
 */
proto.ai.sigmafinancial.pattern.common.ScoringParameters.prototype.getLongPatternBoost = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.ai.sigmafinancial.pattern.common.ScoringParameters} returns this
 */
proto.ai.sigmafinancial.pattern.common.ScoringParameters.prototype.setLongPatternBoost = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.ai.sigmafinancial.pattern.common.ScoringParameters} returns this
 */
proto.ai.sigmafinancial.pattern.common.ScoringParameters.prototype.clearLongPatternBoost = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ai.sigmafinancial.pattern.common.ScoringParameters.prototype.hasLongPatternBoost = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional double shape_magnitude = 3;
 * @return {number}
 */
proto.ai.sigmafinancial.pattern.common.ScoringParameters.prototype.getShapeMagnitude = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.ai.sigmafinancial.pattern.common.ScoringParameters} returns this
 */
proto.ai.sigmafinancial.pattern.common.ScoringParameters.prototype.setShapeMagnitude = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.ai.sigmafinancial.pattern.common.ScoringParameters} returns this
 */
proto.ai.sigmafinancial.pattern.common.ScoringParameters.prototype.clearShapeMagnitude = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ai.sigmafinancial.pattern.common.ScoringParameters.prototype.hasShapeMagnitude = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional double temporal_distortion = 4;
 * @return {number}
 */
proto.ai.sigmafinancial.pattern.common.ScoringParameters.prototype.getTemporalDistortion = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.ai.sigmafinancial.pattern.common.ScoringParameters} returns this
 */
proto.ai.sigmafinancial.pattern.common.ScoringParameters.prototype.setTemporalDistortion = function(value) {
  return jspb.Message.setField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.ai.sigmafinancial.pattern.common.ScoringParameters} returns this
 */
proto.ai.sigmafinancial.pattern.common.ScoringParameters.prototype.clearTemporalDistortion = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ai.sigmafinancial.pattern.common.ScoringParameters.prototype.hasTemporalDistortion = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional double pattern_lookback = 6;
 * @return {number}
 */
proto.ai.sigmafinancial.pattern.common.ScoringParameters.prototype.getPatternLookback = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.ai.sigmafinancial.pattern.common.ScoringParameters} returns this
 */
proto.ai.sigmafinancial.pattern.common.ScoringParameters.prototype.setPatternLookback = function(value) {
  return jspb.Message.setField(this, 6, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.ai.sigmafinancial.pattern.common.ScoringParameters} returns this
 */
proto.ai.sigmafinancial.pattern.common.ScoringParameters.prototype.clearPatternLookback = function() {
  return jspb.Message.setField(this, 6, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ai.sigmafinancial.pattern.common.ScoringParameters.prototype.hasPatternLookback = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * @enum {number}
 */
proto.ai.sigmafinancial.pattern.common.PatternType = {
  DOUBLE_BOTTOM: 0,
  DOUBLE_TOP: 1,
  TRIPLE_BOTTOM: 2,
  TRIPLE_TOP: 3,
  CUP_AND_HANDLE: 4,
  INVERSE_CUP_AND_HANDLE: 5,
  ROUNDING_BOTTOM: 6,
  ROUNDING_TOP: 7,
  HEAD_AND_SHOULDERS: 8,
  INVERSE_HEAD_AND_SHOULDERS: 9,
  DOUBLE_BOTTOM_ASCENDING: 10,
  DOUBLE_BOTTOM_DESCENDING: 11,
  DOUBLE_TOP_ASCENDING: 12,
  DOUBLE_TOP_DESCENDING: 13,
  ALL: 14
};

/**
 * @enum {number}
 */
proto.ai.sigmafinancial.pattern.common.Gaussian1DFilterSigma = {
  VERY_LOW: 0,
  LOW: 1,
  MEDIUM: 2,
  HIGH: 3,
  CUSTOM: 4
};

/**
 * @enum {number}
 */
proto.ai.sigmafinancial.pattern.common.SmoothingMethod = {
  GAUSSIAN_PROCESS_REGRESSION: 0,
  GAUSSIAN_1D_FILTER: 1
};

goog.object.extend(exports, proto.ai.sigmafinancial.pattern.common);
