import { textStyles } from "@client/src/utils/textStyles";
import styled from "styled-components";

const DropdownContainer = styled.div`
  position: relative;
  display: inline-block;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  color: ${(props) => props.theme.text};
`;

const DropdownButton = styled.div`
  display: flex;
  padding: 0.5rem;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  border: none;
  cursor: pointer;
  border-radius: 0.25rem;
  gap: 8px;
  background: ${(props) => props.theme.selectionBg};
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  color: ${(props) => props.theme.text};
  ${textStyles.textSmBold}
  text-decoration: none;
  white-space: nowrap;
`;

const DropdownContent = styled.div`
  display: flex;
  padding: 8px 0;
  flex-direction: column;
  align-items: flex-start;
  cursor: pointer;
  position: absolute;
  z-index: 10; // High to avoid clash with chart
  margin-top: 4px;
  border-radius: 4px;
  background: ${(props) => props.theme.selectionBg};
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  min-width: 100%;
  overflow-y: auto;
`;

const DropdownItem = styled.a`
  padding: 12px 16px;
  ${textStyles.textSm}
  text-decoration: none;
  gap: 8px;
  display: flex;
  align-items: center;
  white-space: nowrap;
`;

export { DropdownContainer, DropdownButton, DropdownContent, DropdownItem };
