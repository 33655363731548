import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: 100vh;

  margin: auto;
  padding: 2rem;
  background-color: ${(props) => props.theme.secondaryBackground};
  border-radius: 0.5rem;
  overflow: auto;
`;

export const BlockContainer = styled(Container)`
  display: flex;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  position: sticky;
  top: 0;
  background-color: ${(props) => props.theme.secondaryBackground};
  padding: 0.5rem;
  gap: 0.5rem;
  z-index: 1;
  justify-content: space-between;
  align-items: center;
`;
export const HeaderSection = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  z-index: 2;
  align-items: center;
`;
