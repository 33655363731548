import React from "react";
import {
  Container,
  OptionContainer,
} from "./ConditionBuilderSelector.style";

export type ConditionBuilderOption = {
  icon: React.ReactNode;
  text: string;
  onClick: () => void;
};

type ConditionBuilderSelectorProps = {
  options: ConditionBuilderOption[];
};

const ConditionBuilderSelector = ({
  options,
}: ConditionBuilderSelectorProps) => {
  return (
    <Container>
      {options.map((option, index) => {
        return (
          <OptionContainer
            key={`condition-builder-option-${index}`}
            onClick={() => option.onClick()}
          >
            {option.icon}
            {option.text}
          </OptionContainer>
        );
      })}
    </Container>
  );
};

export default ConditionBuilderSelector;
