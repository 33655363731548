import { Instrument } from "@generated/common/basic-types_pb";
import { useInstruments } from "@hooks/useInstruments";
import React, {
  createContext,
  PropsWithChildren,
  useContext,
  useEffect,
  useState,
} from "react";
import { useAppContext } from "./AppContext";

type InstrumentContextType = {
  instruments: Instrument.AsObject[];
  availableInstruments: Instrument.AsObject[];
  unavailableInstruments: Instrument.AsObject[];
};

const defaultInstrumentContext: InstrumentContextType = {
  instruments: [],
  availableInstruments: [],
  unavailableInstruments: [],
};

export const InstrumentContext = createContext(defaultInstrumentContext);

const InstrumentProvider = (props: PropsWithChildren<object>) => {
  const { instruments, setInstruments } = useAppContext();
  const [availableInstruments, setAvailableInstruments] = useState<
    Instrument.AsObject[]
  >([]);
  const [unavailableInstruments, setUnavailableInstruments] = useState<
    Instrument.AsObject[]
  >([]);
  const networkInstruments = useInstruments();

  useEffect(() => {
    setInstruments(networkInstruments.instruments);
    setAvailableInstruments(networkInstruments.availableInstruments);
    setUnavailableInstruments(networkInstruments.unavailableInstruments);
  }, [networkInstruments]);

  return (
    <InstrumentContext.Provider
      value={{
        instruments,
        availableInstruments,
        unavailableInstruments,
      }}
    >
      {props.children}
    </InstrumentContext.Provider>
  );
};

const useInstrumentContext = () => useContext(InstrumentContext);

export { InstrumentProvider, useInstrumentContext };
export type { InstrumentContextType };
