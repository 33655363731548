import { BaseChartState } from "./state";
import { addEventListeners } from "./events";
import { getChartSyncHandler } from "@client/src/charts/helpers/sync-handler";

export const makeDisposable = <T extends BaseChartState>(
  state: T,
  onDispose?: () => void
) => {
  const removeEventListeners = addEventListeners(state);
  return () => {
    removeEventListeners();
    getChartSyncHandler().unregister(state.lwChart);
    if (onDispose) onDispose();
    disposeChart(state);
  };
};
export const disposeChart = (state: BaseChartState) => {
  state.lwChart.remove();
  state.isDisposed = true;
};
export const throwIfDisposed = <T extends BaseChartState>(
  chartInstance: T
) => {
  if (chartInstance.isDisposed)
    throw new Error("Chart is disposed");
};
