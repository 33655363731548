import React, { ReactElement } from "react";
import { HiCheck } from "react-icons/hi2";
import { DropdownContent, DropdownItem } from "./SelectionDropdown.styles";
import { CategorizedItems, DropdownBodyProps } from "./SelectionDropdown.types";

export const getDefaultItem = (items: string[]) =>
  items.length > 0 ? items[0] : "";

const renderListItem = (
  key: string,
  item: string,
  handleSelect: (item: string) => void,
  selectedValue: string,
  renderItem?: (item: string) => ReactElement
) => {
  return (
    <DropdownItem
      key={key}
      onClick={() => handleSelect(item)}
      data-testid={`dropdown-item-${key}`}
    >
      {renderItem ? renderItem(item) : item}
      {item === selectedValue && (
        <HiCheck
          style={{ height: "20px", width: "20px", marginLeft: "auto" }}
        />
      )}
    </DropdownItem>
  );
};

const renderListCategory = (
  key: string,
  item: string,
  renderCategory?: (category: string) => ReactElement
) => {
  if (item === "") return null;
  return (
    <DropdownItem key={key} data-testid={`dropdown-item-${key}`}>
      {renderCategory ? renderCategory(item) : item}
    </DropdownItem>
  );
};

const renderItems = (
  items: string[],
  categorizedItems: CategorizedItems[],
  handleSelect: (item: string) => void,
  selectedValue: string,
  renderItem?: (item: string) => ReactElement,
  renderCategory?: (category: string) => ReactElement
) => {
  if (categorizedItems.length > 0) {
    return categorizedItems.map((category, index) => {
      return (
        <div key={index}>
          {renderListCategory(
            index.toString(),
            category.category,
            renderCategory
          )}
          {category.items.map((item, index) =>
            renderListItem(
              index.toString(),
              item,
              handleSelect,
              selectedValue,
              renderItem
            )
          )}
        </div>
      );
    });
  }

  return items.map((item, index) =>
    renderListItem(
      index.toString(),
      item,
      handleSelect,
      selectedValue,
      renderItem
    )
  );
};

export const DropdownBody = ({
  renderCategory,
  renderItem,
  items,
  categorizedItems,
  selectedValue,
  isSelectOpen,
  height,
  handleSelect,
}: DropdownBodyProps) => {
  return (
    <DropdownContent
      style={{
        display: isSelectOpen() ? "block" : "none",
        maxHeight: height,
      }}
      data-testid="dropdown-content"
    >
      {renderItems(
        items,
        categorizedItems,
        handleSelect,
        selectedValue,
        renderItem,
        renderCategory
      )}
    </DropdownContent>
  );
};
