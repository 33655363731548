import React, { useState, useEffect } from "react";
import * as helpers from "./ChartTypeSelector.helpers";
// eslint-disable-next-line max-len
import SelectionDropdown from "@components/selection_dropdown/SelectionDropdown.component";
import { MdCandlestickChart } from "react-icons/md";
import {
  getLocalChartName,
  setChartType,
} from "@client/src/utils/configStorageManager";

type Props = {
  /**
   * Callback function that is called when the value of the select changes
   * @param chartType
   */
  onChange: (chartType: string) => void;
};

const ChartTypeSelector = ({ onChange }: Props) => {
  const [defaultItem, setDefaultItem] = useState("");
  const items = helpers.hardcodedItems.map((item) => item.name);
  const handleSelect = (itemName: string) => {
    const selectedItem = helpers.hardcodedItems.find(
      (item) => item.name === itemName
    );
    if (selectedItem) {
      onChange(selectedItem.chartType);
      setChartType(selectedItem);
    }
  };

  useEffect(() => {
    const chartType = getLocalChartName();
    if (chartType) {
      setDefaultItem(items.includes(chartType) ? chartType : "");
    }
  }, []);

  return (
    <div data-testid="chart-type-select">
      <SelectionDropdown
        dialogTitle={"Chart type"}
        items={items}
        onSelect={handleSelect}
        Icon={<MdCandlestickChart />}
        defaultItem={defaultItem}
      />
    </div>
  );
};

export default ChartTypeSelector;
