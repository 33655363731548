import { useAppContext } from "@client/src/context/AppContext";
import { Candlestick } from "@generated/common/basic-types_pb";
import { PatternSearchResult } from "@generated/job/pattern_pb";
import { getSelectedInstrumentClass } from "@utils/instrumentUtils";
import { useDeferredValue, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { PatternConfigItem } from "@common/types";
import { TAResponseV1 } from "@generated/ta/technical_analysis";
import { TechnicalConfigItem } from "../../../common/types";
import { useInstrumentContext } from "../context/InstrumentContext";
import { useUserContext } from "../context/user/UserContext";
import usePooling from "./usePooling";

export type ChartData = {
  patterns: PatternSearchResult.AsObject[];
  candles: Candlestick.AsObject[];
  technicals: TAResponseV1 | null;
} & Pick<ChartRequestParams, "frequency" | "hashcode">;

type ChartRequestParams = {
  frequency: string;
  hashcode: string;
  enabledPatterns: PatternConfigItem[];
  technicals: TechnicalConfigItem[];
  getAccessToken: () => Promise<string>;
};

const getTechnicals = (
  technicals: TechnicalConfigItem[],
  selectedInstrumentClass: 2 | 4 | 3 | 7 | 8 | 1 | 5 | 6 | 0 | 9
) => {
  if (selectedInstrumentClass === 3) {
    return technicals.filter((technical) => technical.name !== "Volume");
  }
  return technicals;
};

export const useFetchChart = () => {
  const { getAccessTokenSilently } = useAuth0();
  const {
    frequency: ctxfreq,
    hashcode: ctxhashcode,
    getEnabledPatterns,
    technicals,
  } = useAppContext();
  const { instruments } = useInstrumentContext();
  const { setShowAccessDeniedDialog } = useUserContext();
  const selectedInstrumentClass = getSelectedInstrumentClass(
    instruments,
    ctxhashcode
  );

  const frequency = useDeferredValue(ctxfreq);
  const hashcode = useDeferredValue(ctxhashcode);
  const [chartData, setChartData] = useState<ChartData>(
    getInitialChartData(frequency, hashcode)
  );

  usePooling(
    async ({ signal }) => {
      const token = await getAccessTokenSilently();

      const response = await fetch(
        getChartDataUrl({
          frequency: frequency,
          hashcode: hashcode,
        }).href,
        {
          method: "POST",
          body: JSON.stringify({
            patterns: getEnabledPatterns(),
            technicals: getTechnicals(technicals, selectedInstrumentClass),
          }),
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          signal,
        }
      );
      if (!response.ok) {
        if (response.status === 403) {
          setShowAccessDeniedDialog(true);
        }
        return;
      }
      const parsed = JSON.parse(await response.text());
      setChartData({
        ...parsed,
        frequency: frequency,
        hashcode: hashcode,
      });
    },
    { pollIntervalInMs: 10000 },
    [
      frequency,
      hashcode,
      technicals,
      getEnabledPatterns,
      selectedInstrumentClass,
    ]
  );

  return chartData;
};

const getInitialChartData = (frequency: string, hashcode: string) => ({
  frequency,
  hashcode,
  patterns: [],
  candles: [],
  technicals: null,
});

const getChartDataUrl = (
  params: Pick<ChartRequestParams, "frequency" | "hashcode">
) => {
  const { frequency, hashcode } = params;
  const url = new URL("/api/chartData", window.location.origin);
  url.searchParams.set("frequency", frequency);
  url.searchParams.set("hashcode", hashcode);
  url.searchParams.set("count", "500");
  return url;
};
