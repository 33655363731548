import { DefaultTheme } from "styled-components";

export const isUserAuth = (isLoading: boolean, isAuthenticated: boolean) =>
  !isLoading && isAuthenticated;

export const TERMS_KEY = "TERMS_ACCEPTED";
export const CURRENT_TERMS_VERSION = 2;

export const shouldShowDialog = (
  isLoading: boolean,
  isAuthenticated: boolean,
  termsAccepted: boolean | undefined,
  theme: DefaultTheme
) => {
  if (theme.name === "akili") return false;
  return isUserAuth(isLoading, isAuthenticated) && termsAccepted === false;
};
