/* eslint-disable max-len */
import React from "react";

const darkModeQuery = window.matchMedia("(prefers-color-scheme: dark)");

type ColourMapping = {
  [key: string]: string;
};

const COLOUR_MAPPING: ColourMapping = {
  "white": "white", // lightmode: "#131313",
  "#131313": "#131313", // lightmode: "#F8F8F8",
  "#444444": "#444444", // lightmode: "#CACACA",
};

const getColour = (colour: string) => {
  if (!darkModeQuery.matches) {
    return COLOUR_MAPPING[colour.toString()];
  }
  return colour;
};

export const Volume = () => {
  return (
    <svg width="32" height="32" viewBox="0 0 64 64" fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <rect width="64" height="64" rx="4" fill={getColour("#131313")}/>
      <path d="M6 43C6 42.4477 6.44772 42 7 42C7.55228 42 8 42.4477 8
        43V58H6V43Z" fill={getColour("white")}/>
      <path d="M11 35C11 34.4477 11.4477 34 12 34C12.5523 34 13 34.4477 13
        35V58H11V35Z" fill={getColour("white")}/>
      <path d="M16 39C16 38.4477 16.4477 38 17 38C17.5523 38 18 38.4477 18
        39V58H16V39Z" fill={getColour("white")}/>
      <path d="M21 43C21 42.4477 21.4477 42 22 42C22.5523 42 23 42.4477 23
        43V58H21V43Z" fill={getColour("white")}/>
      <path d="M26 40C26 39.4477 26.4477 39 27 39C27.5523 39 28 39.4477 28
        40V58H26V40Z" fill={getColour("white")}/>
      <path d="M31 35C31 34.4477 31.4477 34 32 34C32.5523 34 33 34.4477 33
        35V58H31V35Z" fill={getColour("white")}/>
      <path d="M36 35C36 34.4477 36.4477 34 37 34C37.5523 34 38 34.4477 38
        35V58H36V35Z" fill={getColour("white")}/>
      <path d="M41 40C41 39.4477 41.4477 39 42 39C42.5523 39 43 39.4477 43
        40V58H41V40Z" fill={getColour("white")}/>
      <path d="M46 43C46 42.4477 46.4477 42 47 42C47.5523 42 48 42.4477 48
        43V58H46V43Z" fill={getColour("white")}/>
      <path d="M51 35C51 34.4477 51.4477 34 52 34C52.5523 34 53 34.4477 53
        35V58H51V35Z" fill={getColour("white")}/>
      <path d="M56 40C56 39.4477 56.4477 39 57 39C57.5523 39 58 39.4477 58
        40V58H56V40Z" fill={getColour("white")}/>
    </svg>
  );
};

export const MA = (
  { width = 64, height = 64 }:
  { width?: number, height?: number }
) => {
  return (
    <svg width={width} height={height} viewBox="0 0 64 64" fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_3842_24729)">
        <rect width="64" height="64" rx="4" fill={getColour("#131313")}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M20.4723
          7.00039C20.9001 6.98855 21.288 7.25021 21.4372 7.65127L28.8573
          27.5928L31.7526 24.3356C31.9699 24.0911 32.2932 23.9684 32.618
          24.007C32.9429 24.0456 33.2284 24.2407 33.3823 24.5294L40.7669
          38.3755L48.3146 31.2718C48.5271 31.0718 48.8163 30.9746 49.1065
          31.0057C49.3966 31.0368 49.6587 31.193 49.824 31.4335L66.324
          55.4335L64.6759 56.5665L48.8364 33.5272L41.1853 40.7282C40.9585
          40.9417 40.6452 41.0373 40.3378 40.9868C40.0305 40.9363 39.7642
          40.7454 39.6176 40.4706L32.2934 26.7376L29.2474 30.1644C29.0143
          30.4266 28.6607 30.5475 28.3158 30.4829C27.9709 30.4183 27.6851
          30.1776 27.5628 29.8487L20.5857 11.0978L16.9551 22.7964C16.8398
          23.1676 16.5199 23.4385 16.1348 23.4909C15.7496 23.5433 15.369
          23.3677 15.1588 23.0408L12.0469 18.2L5.98529 53.1708C5.92804 53.5011
          5.7091 53.7807 5.40218 53.9156C5.09527 54.0504 4.74121 54.0225
          4.45923 53.8412L-2.54077 49.3412L-1.45926 47.6588L4.27223
          51.3433L10.5147 15.3292C10.5851 14.9231 10.8975 14.6017 11.3015
          14.5199C11.7055 14.4381 12.1183 14.6125 12.3412 14.9592L15.6809
          20.1544L19.5449 7.7036C19.6718 7.29491 20.0446 7.01222 20.4723
          7.00039Z" fill={getColour("#444444")}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M5.40924
        43.7146C3.78619 43.9962 2.04308 43.7913 0.160271 43.0244C-0.533609
        42.7418 -1.43247 42.1942 -2.43147 41.5079C-3.44761 40.8097 -4.62618
        39.9265 -5.89508 38.9307C-8.43379 36.9384 -11.3679 34.4685 -14.1464
        32.066C-16.9266 29.662 -19.5586 27.3188 -21.4951 25.5771C-22.4636
        24.7061 -23.2585 23.9852 -23.8114 23.4818C-24.0879 23.2301 -24.304
        23.0328 -24.451 22.8983L-24.6756 22.6925C-24.6758 22.6923 -24.6766
        22.6916 -24 21.9552C-23.3234 21.2189 -23.3234 21.2189 -23.3232
        21.219L-23.1011 21.4226C-22.9552 21.5561 -22.7403 21.7523 -22.4651
        22.0028C-21.9147 22.5039 -21.1228 23.2221 -20.1577 24.0901C-18.2272
        25.8264 -15.6055 28.1604 -12.8383 30.5531C-10.0694 32.9473 -7.16268
        35.3936 -4.66035 37.3574C-3.40873 38.3396 -2.26674 39.1945 -1.29892
        39.8594C-0.313972 40.5361 0.43449 40.9766 0.914652 41.1722C2.50292
        41.819 3.86579 41.9525 5.06734 41.744C6.27388 41.5347 7.39206 40.9675
        8.46557 40.0978C10.6495 38.3285 12.5184 35.4222 14.4179 32.1658C14.7477
        31.6004 15.0784 31.0245 15.4113 30.4447C16.9546 27.7568 18.5467 24.984
        20.3295 22.7761C22.5042 20.0828 25.1407 18 28.5831 18C32.0637 18
        34.8552 20.4036 37.4355 23.5183C38.7455 25.0995 40.0563 26.9345
        41.415 28.866C41.5964 29.1239 41.7787 29.3836 41.9621 29.6448C43.1595
        31.3503 44.4041 33.123 45.7474 34.8961C51.9329 43.0606 60.0381 51 74
        51V53C59.0914 53 50.4684 44.4394 44.1533 36.1039C42.7864 34.2997
        41.5194 32.4951 40.3234 30.7914C40.1404 30.5307 39.959 30.2724
        39.7792 30.0168C38.4175 28.0811 37.1485 26.3068 35.8954 24.7942C33.3497
        21.7214 31.0828 20 28.5831 20C26.0452 20 23.9213 21.5114 21.8856
        24.0325C20.2123 26.1048 18.7188 28.7041 17.177 31.3873C16.8363 31.9802
        16.4933 32.5772 16.1454 33.1735C14.2642 36.3986 12.2321 39.6203 9.72458
        41.6518C8.45241 42.6824 7.02733 43.4339 5.40924 43.7146Z"
        fill={getColour("white")}/>
      </g>
      <defs>
        <clipPath id="clip0_3842_24729">
          <rect width="64" height="64" rx="4" fill={getColour("white")}/>
        </clipPath>
      </defs>
    </svg>
  );
};

export const EMA = (
  { width = 64, height = 64 }: { width?: number, height?: number }
) => {
  return (
    <svg width={width} height={height} viewBox="0 0 64 64" fill="none"xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_1_108)">
        <rect width="64" height="64" rx="4" fill="#131313"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M20.4723 7.00039C20.9001 6.98855 21.288 7.25021 21.4372 7.65127L28.8573 27.5928L31.7526 24.3356C31.9699 24.0911 32.2932 23.9684 32.618 24.007C32.9429 24.0456 33.2284 24.2407 33.3823 24.5294L40.7669 38.3755L48.3146 31.2718C48.5271 31.0718 48.8163 30.9746 49.1065 31.0057C49.3966 31.0368 49.6587 31.193 49.824 31.4335L66.324 55.4335L64.6759 56.5665L48.8364 33.5272L41.1853 40.7282C40.9585 40.9417 40.6452 41.0373 40.3378 40.9868C40.0305 40.9363 39.7642 40.7454 39.6176 40.4706L32.2934 26.7376L29.2474 30.1644C29.0143 30.4266 28.6607 30.5475 28.3158 30.4829C27.9709 30.4183 27.6851 30.1776 27.5628 29.8487L20.5857 11.0978L16.9551 22.7964C16.8398 23.1676 16.5199 23.4385 16.1348 23.4909C15.7496 23.5433 15.369 23.3677 15.1588 23.0408L12.0469 18.2L5.98529 53.1708C5.92804 53.5011 5.7091 53.7807 5.40218 53.9156C5.09527 54.0504 4.74121 54.0225 4.45923 53.8412L-2.54077 49.3412L-1.45926 47.6588L4.27223 51.3433L10.5147 15.3292C10.5851 14.9231 10.8975 14.6017 11.3015 14.5199C11.7055 14.4381 12.1183 14.6125 12.3412 14.9592L15.6809 20.1544L19.5449 7.7036C19.6718 7.29491 20.0446 7.01222 20.4723 7.00039Z"
          fill="#444444"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M5.40926 43.7146C3.7862 43.9962 2.0431 43.7913 0.160286 43.0244C-0.533594 42.7418 -1.43246 42.1942 -2.43146 41.5079C-3.44759 40.8097 -4.62617 39.9265 -5.89507 38.9307C-8.43378 36.9384 -11.3679 34.4685 -14.1464 32.066C-16.9265 29.662 -19.5586 27.3188 -21.4951 25.5771C-22.4636 24.7061 -23.2585 23.9852 -23.8114 23.4818C-24.0879 23.2301 -24.304 23.0328 -24.451 22.8983L-24.6756 22.6925C-24.6758 22.6923 -24.6766 22.6916 -24 21.9552C-23.3234 21.2189 -23.3234 21.2189 -23.3232 21.219L-23.101 21.4226C-22.9551 21.5561 -22.7403 21.7523 -22.4651 22.0028C-21.9147 22.5039 -21.1228 23.2221 -20.1577 24.0901C-18.2272 25.8264 -15.6054 28.1604 -12.8382 30.5531C-10.0694 32.9473 -7.16266 35.3936 -4.66033 37.3574C-3.40871 38.3396 -2.26672 39.1945 -1.29891 39.8594C-0.313957 40.5361 0.434505 40.9766 0.914667 41.1722C2.50294 41.819 3.8658 41.9525 5.06736 41.744C6.27389 41.5347 7.39207 40.9675 8.46559 40.0978C10.6495 38.3285 12.5184 35.4222 14.4179 32.1658C14.7477 31.6004 15.0784 31.0245 15.4113 30.4447C16.9546 27.7568 18.5467 24.984 20.3295 22.7761C22.5043 20.0828 25.1407 18 28.5831 18C32.0637 18 34.8552 20.4036 37.4356 23.5183C38.7455 25.0995 40.0563 26.9345 41.415 28.866C41.5964 29.1239 41.7787 29.3836 41.9621 29.6448C43.1595 31.3503 44.4041 33.123 45.7474 34.8961C51.9329 43.0606 60.0381 51 74 51V53C59.0914 53 50.4684 44.4394 44.1533 36.1039C42.7864 34.2997 41.5195 32.4951 40.3234 30.7914C40.1404 30.5307 39.959 30.2724 39.7792 30.0168C38.4175 28.0811 37.1485 26.3068 35.8954 24.7942C33.3498 21.7214 31.0828 20 28.5831 20C26.0452 20 23.9213 21.5114 21.8856 24.0325C20.2123 26.1048 18.7188 28.7041 17.177 31.3873C16.8364 31.9802 16.4933 32.5772 16.1455 33.1735C14.2642 36.3986 12.2321 39.6203 9.72459 41.6518C8.45243 42.6824 7.02734 43.4339 5.40926 43.7146Z"
          fill="white"/>
      </g>
      <defs>
        <clipPath id="clip0_1_108">
          <rect width="64" height="64" rx="4" fill="white"/>
        </clipPath>
      </defs>
    </svg>

  );
};

export const RSI = (
  { width = 64, height = 64 }:
  { width?: number, height?: number }
) => {
  return (
    <svg width={width} height={height} viewBox="0 0 64 64" fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_3842_23825)">
        <rect width="64" height="64" rx="4" fill={getColour("#131313")}/>
        <path d="M-3 32C-3 31.4477 -2.55228 31 -2 31H0C0.552285 31 1 31.4477 1
        32C1 32.5523 0.552285 33 0 33H-2C-2.55228 33 -3 32.5523 -3 32Z"
        fill={getColour("#444444")}/>
        <path d="M3 32C3 31.4477 3.44772 31 4 31H6C6.55228 31 7 31.4477 7 32C7
        32.5523 6.55228 33 6 33H4C3.44772 33 3 32.5523 3 32Z"
        fill={getColour("#444444")}/>
        <path d="M9 32C9 31.4477 9.44772 31 10 31H12C12.5523 31 13 31.4477 13
        32C13 32.5523 12.5523 33 12 33H10C9.44772 33 9 32.5523 9 32Z"
        fill={getColour("#444444")}/>
        <path d="M15 32C15 31.4477 15.4477 31 16 31H18C18.5523 31 19 31.4477 19
        32C19 32.5523 18.5523 33 18 33H16C15.4477 33 15 32.5523 15 32Z"
        fill={getColour("#444444")}/>
        <path d="M21 32C21 31.4477 21.4477 31 22 31H24C24.5523 31 25 31.4477 25
        32C25 32.5523 24.5523 33 24 33H22C21.4477 33 21 32.5523 21 32Z"
        fill={getColour("#444444")}/>
        <path d="M27 32C27 31.4477 27.4477 31 28 31H30C30.5523 31 31 31.4477 31
        32C31 32.5523 30.5523 33 30 33H28C27.4477 33 27 32.5523 27 32Z"
        fill={getColour("#444444")}/>
        <path d="M33 32C33 31.4477 33.4477 31 34 31H36C36.5523 31 37 31.4477 37
        32C37 32.5523 36.5523 33 36 33H34C33.4477 33 33 32.5523 33 32Z"
        fill={getColour("#444444")}/>
        <path d="M39 32C39 31.4477 39.4477 31 40 31H42C42.5523 31 43 31.4477 43
        32C43 32.5523 42.5523 33 42 33H40C39.4477 33 39 32.5523 39 32Z"
        fill={getColour("#444444")}/>
        <path d="M45 32C45 31.4477 45.4477 31 46 31H48C48.5523 31 49 31.4477 49
        32C49 32.5523 48.5523 33 48 33H46C45.4477 33 45 32.5523 45 32Z"
        fill={getColour("#444444")}/>
        <path d="M51 32C51 31.4477 51.4477 31 52 31H54C54.5523 31 55 31.4477 55
        32C55 32.5523 54.5523 33 54 33H52C51.4477 33 51 32.5523 51 32Z"
        fill={getColour("#444444")}/>
        <path d="M57 32C57 31.4477 57.4477 31 58 31H60C60.5523 31 61 31.4477 61
        32C61 32.5523 60.5523 33 60 33H58C57.4477 33 57 32.5523 57 32Z"
        fill={getColour("#444444")}/>
        <path d="M63 32C63 31.4477 63.4477 31 64 31H66C66.5523 31 67 31.4477 67
        32C67 32.5523 66.5523 33 66 33H64C63.4477 33 63 32.5523 63 32Z"
        fill={getColour("#444444")}/>
        <path d="M-3 50C-3 49.4477 -2.55228 49 -2 49H0C0.552285 49 1 49.4477 1
        50C1 50.5523 0.552285 51 0 51H-2C-2.55228 51 -3 50.5523 -3 50Z"
        fill={getColour("#444444")}/>
        <path d="M3 50C3 49.4477 3.44772 49 4 49H6C6.55228 49 7 49.4477 7 50C7
        50.5523 6.55228 51 6 51H4C3.44772 51 3 50.5523 3 50Z"
        fill={getColour("#444444")}/>
        <path d="M9 50C9 49.4477 9.44772 49 10 49H12C12.5523 49 13 49.4477 13
        50C13 50.5523 12.5523 51 12 51H10C9.44772 51 9 50.5523 9 50Z"
        fill={getColour("#444444")}/>
        <path d="M15 50C15 49.4477 15.4477 49 16 49H18C18.5523 49 19 49.4477 19
        50C19 50.5523 18.5523 51 18 51H16C15.4477 51 15 50.5523 15 50Z"
        fill={getColour("#444444")}/>
        <path d="M21 50C21 49.4477 21.4477 49 22 49H24C24.5523 49 25 49.4477 25
        50C25 50.5523 24.5523 51 24 51H22C21.4477 51 21 50.5523 21 50Z"
        fill={getColour("#444444")}/>
        <path d="M27 50C27 49.4477 27.4477 49 28 49H30C30.5523 49 31 49.4477 31
        50C31 50.5523 30.5523 51 30 51H28C27.4477 51 27 50.5523 27 50Z"
        fill={getColour("#444444")}/>
        <path d="M33 50C33 49.4477 33.4477 49 34 49H36C36.5523 49 37 49.4477 37
        50C37 50.5523 36.5523 51 36 51H34C33.4477 51 33 50.5523 33 50Z"
        fill={getColour("#444444")}/>
        <path d="M39 50C39 49.4477 39.4477 49 40 49H42C42.5523 49 43 49.4477 43
        50C43 50.5523 42.5523 51 42 51H40C39.4477 51 39 50.5523 39 50Z"
        fill={getColour("#444444")}/>
        <path d="M45 50C45 49.4477 45.4477 49 46 49H48C48.5523 49 49 49.4477 49
        50C49 50.5523 48.5523 51 48 51H46C45.4477 51 45 50.5523 45 50Z"
        fill={getColour("#444444")}/>
        <path d="M51 50C51 49.4477 51.4477 49 52 49H54C54.5523 49 55 49.4477 55
        50C55 50.5523 54.5523 51 54 51H52C51.4477 51 51 50.5523 51 50Z"
        fill={getColour("#444444")}/>
        <path d="M57 50C57 49.4477 57.4477 49 58 49H60C60.5523 49 61 49.4477 61
        50C61 50.5523 60.5523 51 60 51H58C57.4477 51 57 50.5523 57 50Z"
        fill={getColour("#444444")}/>
        <path d="M63 50C63 49.4477 63.4477 49 64 49H66C66.5523 49 67 49.4477 67
        50C67 50.5523 66.5523 51 66 51H64C63.4477 51 63 50.5523 63 50Z"
        fill={getColour("#444444")}/>
        <path d="M-3 14C-3 13.4477 -2.55228 13 -2 13H0C0.552285 13 1 13.4477 1
        14C1 14.5523 0.552285 15 0 15H-2C-2.55228 15 -3 14.5523 -3 14Z"
        fill={getColour("#444444")}/>
        <path d="M3 14C3 13.4477 3.44772 13 4 13H6C6.55228 13 7 13.4477 7 14C7
        14.5523 6.55228 15 6 15H4C3.44772 15 3 14.5523 3 14Z"
        fill={getColour("#444444")}/>
        <path d="M9 14C9 13.4477 9.44772 13 10 13H12C12.5523 13 13 13.4477 13
        14C13 14.5523 12.5523 15 12 15H10C9.44772 15 9 14.5523 9 14Z"
        fill={getColour("#444444")}/>
        <path d="M15 14C15 13.4477 15.4477 13 16 13H18C18.5523 13 19 13.4477 19
        14C19 14.5523 18.5523 15 18 15H16C15.4477 15 15 14.5523 15 14Z"
        fill={getColour("#444444")}/>
        <path d="M21 14C21 13.4477 21.4477 13 22 13H24C24.5523 13 25 13.4477 25
        14C25 14.5523 24.5523 15 24 15H22C21.4477 15 21 14.5523 21 14Z"
        fill={getColour("#444444")}/>
        <path d="M27 14C27 13.4477 27.4477 13 28 13H30C30.5523 13 31 13.4477 31
        14C31 14.5523 30.5523 15 30 15H28C27.4477 15 27 14.5523 27 14Z"
        fill={getColour("#444444")}/>
        <path d="M33 14C33 13.4477 33.4477 13 34 13H36C36.5523 13 37 13.4477 37
        14C37 14.5523 36.5523 15 36 15H34C33.4477 15 33 14.5523 33 14Z"
        fill={getColour("#444444")}/>
        <path d="M39 14C39 13.4477 39.4477 13 40 13H42C42.5523 13 43 13.4477 43
        14C43 14.5523 42.5523 15 42 15H40C39.4477 15 39 14.5523 39 14Z"
        fill={getColour("#444444")}/>
        <path d="M45 14C45 13.4477 45.4477 13 46 13H48C48.5523 13 49 13.4477 49
        14C49 14.5523 48.5523 15 48 15H46C45.4477 15 45 14.5523 45 14Z"
        fill={getColour("#444444")}/>
        <path d="M51 14C51 13.4477 51.4477 13 52 13H54C54.5523 13 55 13.4477 55
        14C55 14.5523 54.5523 15 54 15H52C51.4477 15 51 14.5523 51 14Z"
        fill={getColour("#444444")}/>
        <path d="M57 14C57 13.4477 57.4477 13 58 13H60C60.5523 13 61 13.4477 61
        14C61 14.5523 60.5523 15 60 15H58C57.4477 15 57 14.5523 57 14Z"
        fill={getColour("#444444")}/>
        <path d="M63 14C63 13.4477 63.4477 13 64 13H66C66.5523 13 67 13.4477 67
        14C67 14.5523 66.5523 15 66 15H64C63.4477 15 63 14.5523 63 14Z"
        fill={getColour("#444444")}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M19.3016
        47.3361C17.7614 47.872 16.1834 47.4269 14.6468 46.1718C14.0729 45.703
        13.358 44.8243 12.5823 43.7555C11.7857 42.658 10.8617 41.2693 9.86633
        39.7027C7.87456 36.5678 5.57166 32.6801 3.38974 28.8964C1.2067 25.1107
        -0.860225 21.4205 -2.38107 18.6772C-3.14159 17.3054 -3.7658 16.17
        -4.19998 15.3774C-4.41708 14.981 -4.58668 14.6704 -4.70207
        14.4587L-4.87872 14.1341C-4.87885 14.1338 -4.87892 14.1337 -4.00001
        13.6567C-3.12109 13.1797 -3.12104 13.1798 -3.12094 13.18L-2.94599
        13.5015C-2.83121 13.7121 -2.66228 14.0215 -2.44589 14.4166C-2.0131
        15.2067 -1.39055 16.3391 -0.631893 17.7075C0.885624 20.4447 2.94672
        24.1245 5.12231 27.8973C7.29901 31.672 9.58536 35.531 11.5544
        38.6301C12.5394 40.1805 13.4387 41.5305 14.201 42.5808C14.9841 43.6599
        15.5635 44.3382 15.912 44.6228C17.1027 45.5954 17.9651 45.6835 18.6443
        45.4472C19.4219 45.1766 20.2665 44.3834 21.1516 42.9452C22.9105 40.0874
        24.4096 35.3985 25.904 30.2608C26.1667 29.3574 26.429 28.4414 26.6922
        27.5225C27.9045 23.2889 29.1349 18.9921 30.514 15.5666C31.3532 13.4822
        32.2769 11.6423 33.334 10.3083C34.385 8.98209 35.6971 8 37.3153
        8C38.9863 8 40.3306 9.15294 41.4229 10.6432C42.5389 12.1657 43.576
        14.2886 44.59 16.7432C45.7342 19.5133 46.9042 22.8536 48.1452
        26.3966C49.0863 29.0835 50.0683 31.887 51.1107 34.6466C53.5608
        41.1322 56.3908 47.5186 59.9746 52.2731C63.5519 57.0191 67.7691 60 73
        60V62C66.8889 62 62.1492 58.4809 58.3774 53.4769C54.612 48.4814 51.7007
        41.8678 49.2398 35.3534C48.1527 32.4757 47.1616 29.6429 46.2224
        26.9585C45.0069 23.4846 43.8784 20.2591 42.7415 17.5068C41.7417 15.0864
        40.7783 13.1468 39.8098 11.8256C38.8178 10.4721 37.9937 10 37.3153
        10C36.5841 10 35.7864 10.4338 34.9015 11.5505C34.0226 12.6595 33.1842
        14.2896 32.3693 16.3136C31.033 19.6326 29.8391 23.8004 28.6271
        28.0315C28.3609 28.9605 28.0939 29.8926 27.8244 30.8194C26.3481 35.8948
        24.7821 40.8622 22.8549 43.9935C21.897 45.5499 20.7434 46.8344 19.3016
        47.3361Z" fill={getColour("white")}/>
      </g>
      <defs>
        <clipPath id="clip0_3842_23825">
          <rect width="64" height="64" rx="4" fill={getColour("white")}/>
        </clipPath>
      </defs>
    </svg>
  );
};

export const MACD = (
  { width = 64, height = 64 }:
  { width?: number, height?: number }
) => {
  return (
    <svg width={width} height={height} viewBox="0 0 64 64" fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_3842_24878)">
        <rect width="64" height="64" rx="4" fill={getColour("#131313")}/>
        <path d="M16 33C16 32.4477 16.4477 32 17 32C17.5523 32 18 32.4477 18
        33V38H16V33Z" fill={getColour("#444444")}/>
        <path d="M21 26C21 25.4477 21.4477 25 22 25C22.5523 25 23 25.4477 23
        26V38H21V26Z" fill={getColour("#444444")}/>
        <path d="M26 23C26 22.4477 26.4477 22 27 22C27.5523 22 28 22.4477 28
        23V38H26V23Z" fill={getColour("#444444")}/>
        <path d="M31 29C31 28.4477 31.4477 28 32 28C32.5523 28 33 28.4477 33
        29V38H31V29Z" fill={getColour("#444444")}/>
        <path d="M36 34C36 33.4477 36.4477 33 37 33C37.5523 33 38 33.4477 38
        34V38H36V34Z" fill={getColour("#444444")}/>
        <path d="M63 50C63 50.5523 62.5523 51 62 51C61.4477 51 61 50.5523 61
        50L61 38L63 38L63 50Z" fill={getColour("#444444")}/>
        <path d="M58 48C58 48.5523 57.5523 49 57 49C56.4477 49 56 48.5523 56
        48L56 38L58 38L58 48Z" fill={getColour("#444444")}/>
        <path d="M53 48C53 48.5523 52.5523 49 52 49C51.4477 49 51 48.5523 51
        48L51 38L53 38L53 48Z" fill={getColour("#444444")}/>
        <path d="M48 49C48 49.5523 47.5523 50 47 50C46.4477 50 46 49.5523 46
        49L46 38L48 38L48 49Z" fill={getColour("#444444")}/>
        <path d="M43 42C43 42.5523 42.5523 43 42 43C41.4477 43 41 42.5523 41
        42L41 38L43 38L43 42Z" fill={getColour("#444444")}/>
        <path d="M13 41C13 41.5523 12.5523 42 12 42C11.4477 42 11 41.5523 11
        41L11 38L13 38L13 41Z" fill={getColour("#444444")}/>
        <path d="M8 48C8 48.5523 7.55228 49 7 49C6.44772 49 6 48.5523 6 48L6
        38L8 38L8 48Z" fill={getColour("#444444")}/>
        <path d="M3 50C3 50.5523 2.55228 51 2 51C1.44772 51 1 50.5523 1 50L1
        38L3 38L3 50Z" fill={getColour("#444444")}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M9.52275
        36.0672C8.08975 36.3706 6.56858 36.1429 4.96102 35.3437C4.3577 35.0438
        3.59469 34.4738 2.76547 33.7784C1.91695 33.0668 0.934429 32.1679
        -0.121673 31.1562C-2.23491 29.1318 -4.67636 26.6231 -6.98739
        24.1838C-9.3 21.7429 -11.4893 19.3637 -13.1 17.5954C-13.9055 16.711
        -14.5667 15.979 -15.0267 15.4679C-15.2566 15.2124 -15.4364 15.012
        -15.5587 14.8754L-15.7455 14.6664C-15.7457 14.6663 -15.7464 14.6655
        -15 14C-14.2536 13.3345 -14.2535 13.3346 -14.2534 13.3347L-14.0688
        13.5412C-13.9475 13.6766 -13.7688 13.8758 -13.54 14.1301C-13.0823
        14.6386 -12.4239 15.3676 -11.6214 16.2486C-10.0162 18.011 -7.83626
        20.3799 -5.53552 22.8083C-3.23319 25.2384 -0.817201 27.7203 1.26186
        29.712C2.30191 30.7083 3.24924 31.5739 4.05065 32.246C4.87134 32.9343
        5.4783 33.3674 5.85135 33.5528C7.13045 34.1887 8.19231 34.3046 9.10835
        34.1106C10.039 33.9135 10.933 33.3722 11.8198 32.4952C13.6272 30.7078
        15.1824 27.7589 16.7657 24.4456C17.0403 23.8709 17.3155 23.2859 17.5925
        22.697C18.8758 19.9687 20.1977 17.1585 21.6789 14.9193C23.4601 12.2267
        25.7005 10 28.7299 10C30.3307 10 31.7439 10.8083 32.9971 11.9917C34.2503
        13.1751 35.442 14.825 36.617 16.7238C37.7965 18.6299 38.9937 20.8444
        40.2418 23.1858C40.4083 23.4981 40.5757 23.8128 40.7442 24.1295C41.8441
        26.1964 42.9903 28.3503 44.2187 30.5046C49.9386 40.5355 57.1416 50
        68.5173 50V52C55.8834 52 48.1661 41.4645 42.4813 31.4954C41.2372 29.3136
        40.0761 27.1315 38.9771 25.0662C38.809 24.7501 38.6423 24.4368 38.4769
        24.1267C37.2263 21.7806 36.0573 19.6201 34.9163 17.7762C33.7707 15.925
        32.6873 14.4499 31.624 13.4458C30.5606 12.4417 29.6157 12 28.7299
        12C26.7858 12 25.0674 13.4219 23.347 16.0227C21.954 18.1287 20.7098
        20.7719 19.4274 23.4961C19.1442 24.0978 18.8591 24.7033 18.5702
        25.308C17.0093 28.5743 15.3203 31.8462 13.2261 33.9172C12.1623 34.9694
        10.9412 35.7667 9.52275 36.0672Z" fill={getColour("white")}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M18.04 36.0672C16.607
        36.3706 15.0858 36.1429 13.4782 35.3437C12.8749 35.0438 12.1119 34.4738
        11.2827 33.7784C10.4342 33.0668 9.45164 32.1679 8.39554 31.1562C6.28231
        29.1318 3.84085 26.6231 1.52982 24.1838C-0.782789 21.7429 -2.97211
        19.3637 -4.5828 17.5954C-5.38831 16.711 -6.04948 15.979 -6.50944
        15.4679C-6.73943 15.2124 -6.91914 15.012 -7.04146 14.8754L-7.22833
        14.6664C-7.22848 14.6663 -7.22917 14.6655 -6.48277 14C-5.73636 13.3345
        -5.73632 13.3346 -5.73622 13.3347L-5.55155 13.5412C-5.43025 13.6766
        -5.25164 13.8758 -5.02281 14.1301C-4.56513 14.6386 -3.90667 15.3676
        -3.10422 16.2486C-1.49899 18.011 0.680949 20.3799 2.98169
        22.8083C5.28402 25.2384 7.70001 27.7203 9.77907 29.712C10.8191 30.7083
        11.7665 31.5739 12.5679 32.246C13.3886 32.9343 13.9955 33.3674 14.3686
        33.5528C15.6477 34.1887 16.7095 34.3046 17.6256 34.1106C18.5562 33.9135
        19.4502 33.3722 20.337 32.4952C22.1444 30.7078 23.6996 27.7589 25.2829
        24.4456C25.5575 23.8709 25.8327 23.2859 26.1097 22.697C27.3931 19.9687
        28.715 17.1585 30.1961 14.9193C31.9773 12.2267 34.2177 10 37.2471
        10C38.7941 10 40.16 10.6571 41.3799 11.6701C42.5898 12.6748 43.7106
        14.0734 44.7961 15.6728C45.8852 17.2776 46.9749 19.1398 48.1047
        21.1003C48.2556 21.3621 48.4073 21.6258 48.5599 21.8911C49.5556 23.6224
        50.5909 25.4223 51.7083 27.2227C56.8825 35.5594 63.5786 43.5 75.0172
        43.5V45.5C62.4462 45.5 55.2307 36.6906 50.0089 28.2773C48.8725 26.4462
        47.8191 24.6147 46.8244 22.8852C46.6722 22.6206 46.5214 22.3584 46.3719
        22.0989C45.2393 20.1336 44.1835 18.3318 43.1412 16.7959C42.0952
        15.2548 41.0986 14.0362 40.1022 13.2088C39.1159 12.3897 38.1868 12
        37.2471 12C35.303 12 33.5846 13.4219 31.8642 16.0227C30.4712 18.1287
        29.227 20.7719 27.9446 23.4961C27.6614 24.0978 27.3764 24.7033 27.0874
        25.308C25.5265 28.5743 23.8375 31.8462 21.7434 33.9172C20.6795 34.9694
        19.4584 35.7667 18.04 36.0672Z" fill={getColour("white")}/>
      </g>
      <defs>
        <clipPath id="clip0_3842_24878">
          <rect width="64" height="64" rx="4" fill={getColour("white")}/>
        </clipPath>
      </defs>
    </svg>
  );
};
