import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  background-color: ${(props) => props.theme.secondaryBackground};
  border-radius: 0.5rem;
  border-color: ${(props) => props.theme.dashedBorder};
  border-style: solid;
  padding-right: 1rem;
`;

export const LogicContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  background-color: ${(props) => props.theme.secondaryBackground};
  padding: 0.5rem;
`;

export const Handle = styled.div<{ type: "and" | "or" | "not" }>`
  max-width: 1rem;
  min-width: 1rem;
  min-height: 100%;
  border-radius: 0.4rem 0 0 0.4rem;
  background-color: ${({ type, theme }) => {
    return theme.conditions[type].condition;
  }};
`;

export const LogicSelector = styled.div<{ type: "and" | "or" | "not" }>`
  display: flex;
  border-radius: 0.5rem;
  justify-content: center;
  align-items: center;
  background-color: ${({ type, theme }) => {
    return theme.conditions[type].logicSelector;
  }};
`;
