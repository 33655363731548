import React from "react";
import {
  SettingsButtonContainer,
  LeftIconContainer,
  ButtonText,
  RightIconContainer,
  ButtonDescriptionText,
  ButtonTextContainer,
} from "./SettingsButton.style";

type SettingsButtonProps = {
  onClick: () => void;
  leftIcon?: React.ReactNode;
  text: string;
  description?: string;
  rightIcon?: React.ReactNode;
};

const getButtonTextContainer = (
  text: string,
  description: string | undefined
) => (
  <ButtonTextContainer>
    <ButtonText>{text}</ButtonText>
    {description && (
      <ButtonDescriptionText>{description}</ButtonDescriptionText>
    )}
  </ButtonTextContainer>
);

const SettingsButton = ({
  onClick,
  leftIcon,
  text,
  rightIcon,
  description,
}: SettingsButtonProps) => {
  return (
    <SettingsButtonContainer onClick={onClick}>
      {leftIcon && <LeftIconContainer>{leftIcon}</LeftIconContainer>}
      {getButtonTextContainer(text, description)}
      {rightIcon && <RightIconContainer>{rightIcon}</RightIconContainer>}
    </SettingsButtonContainer>
  );
};

export default SettingsButton;
