import React, { useCallback } from "react";
import {
  TopRightContainer,
} from "../inbox_sheet/InboxSheet.styles";
import { Slideover } from "../slideover/Slideover.component";
import { Tabbar } from "../tabbar/Tabbar.component";
import { renderSelectedTabComponent } from "./InboxSlideover.helper";
import { InboxSectionContainer } from "./InboxSlideover.styles";
import { useAlertContext } from "@client/src/context/AlertContext";
import PauseAlertToggle from "../pause_alert_toggle/PauseAlertToggle.component";
import Button from "../button/Button.component";
import { HiTrash } from "react-icons/hi2";
import { useAuth0 } from "@auth0/auth0-react";

type InboxSlideoverProps = {
  onClose: () => void;
  isOpen: boolean;
};

const TAB_OPTIONS = [
  { title: "Inbox", id: "inbox" as const },
  { title: "Manage", id: "manage" as const },
];

const InboxSlideover = ({ onClose, isOpen }: InboxSlideoverProps) => {
  const { selectedTab, setSelectedTab } = useAlertContext();
  const { getAccessTokenSilently } = useAuth0();

  const renderTabContent = useCallback(
    () => renderSelectedTabComponent(selectedTab),
    [selectedTab]
  );

  const handleClearAll = () => {
    getAccessTokenSilently().then((token: string) => {
      fetch("/api/inbox/clear-all", {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
    });
  };

  const renderClearAllButton = () => {
    if (selectedTab !== "inbox") return null;
    return (
      <Button
        onClick={handleClearAll}
        text="Clear all alerts"
        size="xs"
        type="primary"
        leftIcon={<HiTrash />}
      />
    );
  };

  return (
    <Slideover
      isOpen={isOpen}
      title="Alerts"
      onClose={onClose}
      topRightComponent={
        <TopRightContainer>
          <PauseAlertToggle />
          {renderClearAllButton()}
        </TopRightContainer>
      }
    >
      <InboxSectionContainer>
        <Tabbar
          data={TAB_OPTIONS}
          selectedTab={selectedTab}
          onTabSelectedChange={setSelectedTab}
        />
        {renderTabContent()}
      </InboxSectionContainer>
    </Slideover>
  );
};

export default InboxSlideover;
