import React from "react";
// eslint-disable-next-line max-len
import {
  Container,
  Content,
  Header,
  HeaderIcon,
  HeaderSection,
  HeaderTitle,
} from "./Slideover.styles";
import { HiXMark } from "react-icons/hi2";
import { Tooltip } from "../tooltip/Tooltip.component";

export type SlideoverProps = {
  title: string;
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactNode;
  topRightComponent?: React.ReactNode;
};

export const Slideover = ({
  title,
  isOpen,
  onClose,
  children,
  topRightComponent,
}: SlideoverProps) => {
  if (!isOpen) {
    return null;
  }
  return (
    <Container data-testid="slideover">
      <Header>
        <HeaderTitle data-testid="slideover-title">
          {title}
        </HeaderTitle>
        <HeaderSection>
          {topRightComponent}
          <Tooltip>
            <Tooltip.Item>
              <HeaderIcon
                onClick={onClose}
                data-testid="slideover-close"
              >
                <HiXMark />
              </HeaderIcon>
            </Tooltip.Item>
            <Tooltip.Content text="Close" />
          </Tooltip>
        </HeaderSection>
      </Header>
      <Content data-testid="slideover-content">
        {children}
      </Content>
    </Container>
  );
};
