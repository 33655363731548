import { useOnClickOutside } from "@hooks/useOnClickOutside";
import React, { useRef } from "react";
import { HiXMark } from "react-icons/hi2";
import {
  CloseButton,
  Dialog,
  DialogContainer,
  DialogHeader,
  Item,
  ItemContainer,
  Title,
} from "../dialog/Dialog.style";
import { Text } from "@client/src/sigma-ui/atoms/typography";
import { Tooltip } from "../tooltip/Tooltip.component";

type Props = {
  title: string;
  items: string[];
  onClickOutside: () => void;
  visible: boolean;
  onSelectItem: (selectedItem: string) => void;
};
const SelectionDialog = ({
  title,
  onClickOutside,
  items,
  visible,
  onSelectItem,
}: Props) => {
  const containerRef = useRef(null);
  useOnClickOutside(containerRef, onClickOutside);
  if (!visible) return null;
  return (
    <DialogContainer>
      <Dialog ref={containerRef}>
        <DialogHeader>
          <Title>{title}</Title>
          <Tooltip>
            <Tooltip.Item>
              <CloseButton onClick={onClickOutside}>
                <HiXMark />
              </CloseButton>
            </Tooltip.Item>
            <Tooltip.Content text="Close" />
          </Tooltip>
        </DialogHeader>
        <ItemContainer>
          {items.map((item, index) => {
            return (
              <Item
                key={index}
                onClick={() => onSelectItem(item)}
              >
                <Text>{item}</Text>
              </Item>
            );
          })}
        </ItemContainer>
      </Dialog>
    </DialogContainer>
  );
};

export default SelectionDialog;
