import { useAuth0 } from "@auth0/auth0-react";
import { SubscriptionDetails } from "@common/types";
import { useLocation, useNavigate } from "react-router-dom";
import { v4 } from "uuid";

export const useSubscriptions = () => {
  const { isAuthenticated, getAccessTokenSilently } = useAuth0();
  const navigate = useNavigate();
  const location = useLocation();
  const shouldShowFailedBanner = async () => {
    if (isAuthenticated) {
      const token = await getAccessTokenSilently();
      const response = await fetch("/api/failed-subscriptions", {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response.json();
      return data.showBanner;
    }
  };

  const handleResult = (result: Response) => {
    if (result.ok) {
      if (location.pathname === "/profile") {
        window.location.reload();
      } else {
        navigate("/profile");
      }
    }
  };

  const cancelSubscription = async (
    subscriptionDetails: SubscriptionDetails | undefined
  ) => {
    if (!subscriptionDetails) return;
    if (isAuthenticated) {
      const requestId = v4();
      const token = await getAccessTokenSilently();
      const result = await fetch("/api/orders/cancel", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          requestId: requestId,
          cancellationReason: "I dont want this anymore",
          orderId: subscriptionDetails.orderId,
        }),
      });
      handleResult(result);
    }
  };

  return {
    cancelSubscription,
    shouldShowFailedBanner,
  };
};
