import { textStyles } from "@client/src/utils/textStyles";
import styled from "styled-components";

export const ScreeningPageContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: ${(props) => props.theme.secondaryBackground};
`;

export const ScreeningContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 7.5rem 0 7.5rem;
  background-color: ${(props) => props.theme.secondaryBackground};
  min-height: 100%;
  flex-grow: 1;

  @media (max-width: 768px) {
    padding: 0;
  }
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-bottom: 0.5rem;
  padding-top: 1rem;

  @media (max-width: 768px) {
    padding-left: 1rem;
  }
`;

export const Title = styled.div`
  ${textStyles.text2xlBold};
`;

export const BottomToolContainer = styled.div<{ $visible?: boolean }>`
  display: flex;
    z-index: 100;
  justify-content: space-between;
  position: fixed;
  bottom: 0;
  left: 0;
  padding: 1rem;
  width: 100%;
  visibility: ${(props) => (props.$visible ? "visible" : "hidden")};
  background-color: ${(props) => props.theme.background};
  }
`;
export const ToolItemContainer = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
`;
