import { textStyles } from "@utils/textStyles";
import styled from "styled-components";

export const SettingsButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  padding: 1rem;
  margin: 0;
  align-items: center;
  cursor: pointer;
`;

export const LeftIconContainer = styled.div`
  margin-top: 0.05rem;
`;

export const ButtonTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
`;
export const ButtonDescriptionText = styled.p`
  ${textStyles.textSm};
  color: ${(props) => props.theme.subtextColor};
  margin: 0;
`;
export const ButtonText = styled.p`
  ${textStyles.textSmBold};
  margin: 0;
`;

export const RightIconContainer = styled.div`
  margin-left: auto;
  margin-top: 0.1rem;
`;
