import { textStyles } from "@client/src/utils/textStyles";
import styled from "styled-components";

export const AnalysisContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
`;

export const AnalysisContent = styled.span`
  background-color: ${(props) => props.theme.secondaryBackground};
  ${textStyles.textBase};
  display: inline-block;
  white-space: break-spaces;
  height: 20rem;
  padding: 1rem 0.25rem 1rem 1rem;
  align-items: flex-start;
  gap: 0.75rem;
  border-radius: 0.5rem;
  align-self: stretch;
  overflow-y: scroll;
  @media (max-width: 768px) {
    height: auto;
    max-height: 50vh;
  }

  ::-webkit-scrollbar-track {
    background: ${(props) => props.theme.scrollbarBg};
    border-radius: 0.5rem;
  }
  ::-webkit-scrollbar-thumb {
    background: ${(props) => props.theme.scrollbarBg};
    border-radius: 0.5rem;
  }

  /* For Firefox */
  * {
    scrollbar-width: thin;
    scrollbar-color: ${(props) => props.theme.scrollbarBg};
  }
`;

export const AnalysisHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.5rem;
  align-self: stretch;
`;

export const TickerSymbol = styled.span`
  ${textStyles.textBaseBold};
  color: ${(props) => props.theme.text};
`;

export const TickerName = styled.span`
  ${textStyles.textBase};
  color: ${(props) => props.theme.subtext};
`;

export const DateRange = styled.span`
  ${textStyles.textSm};
  color: ${(props) => props.theme.subtext};
`;
