import { MA, MACD, RSI, Volume } from "@client/src/assets/TechnicalIcons";
import { useAppContext } from "@client/src/context/AppContext";
import { isMobile } from "@client/src/utils/device";
import { TechnicalConfigItem } from "@common/types";
import { useOnClickOutside } from "@hooks/useOnClickOutside";
import { getSelectedInstrumentClass } from "@utils/instrumentUtils";
import React, { useEffect, useRef, useState } from "react";
import { HiXMark } from "react-icons/hi2";
import { useInstrumentContext } from "../../context/InstrumentContext";
import Button from "../button/Button.component";
import { Sheet } from "../sheet/Sheet.component";
import {
  CloseButton,
  Container,
  Dialog,
  Divider,
  Error,
  FieldOptions,
  FieldTitle,
  Header,
  Name,
  SheetWrapper,
  SideMenu,
  TechnicalContainer,
  TechnicalIcon,
  TechnicalOptionContainer,
  TechnicalsWrapper,
  ThumbMenuItem,
  Title,
} from "./TechnicalsDialog.style";
import {
  Input,
  InputContainer,
} from "@client/src/components/input/Input.style";
import {
  TechnicalOption,
  technicals,
  transformTechnicalToConfig,
} from "./TechnicalsDialogFormSchema";

type TechnicalsDialogProps = {
  visible: boolean;
  onClickOutside: () => void;
};

const TECHNICAL_ICONS = {
  volume: Volume,
  macd: MACD,
  rsi: RSI,
  ma: MA,
};

const renderTechnicalIcon = (name: string) => {
  const IconComponent = TECHNICAL_ICONS[name];
  return IconComponent ? <IconComponent /> : null;
};

const isValueValid = (
  value: number,
  validator:
    | (((value: number) => boolean) | ((value: string) => boolean))
    | undefined
) => isNaN(value) || (validator && validator(value));

const getButtonTitle = (technicalToEdit: TechnicalConfigItem | undefined) =>
  technicalToEdit ? "Edit" : "Add";

export const getNumericValue = (value: number | string): number => {
  if (typeof value === "string") return parseInt(value);
  return value;
};

const isValidForm = (selectedTechnical: TechnicalOption): boolean => {
  let isValid = true;
  selectedTechnical.fields.forEach((field) => {
    const value = field.value;
    if (field.type !== "number") return;
    if (!isValueValid(getNumericValue(value), field.validator)) isValid = false;
  });
  return isValid;
};

const handleAddTechnical = (
  selectedTechnical: TechnicalOption | undefined,
  setError: (error: string) => void,
  addTechnical: (technicalConfig: TechnicalConfigItem) => void,
  onClickOutside: () => void,
  technicals: TechnicalConfigItem[]
) => {
  if (!selectedTechnical) return;
  else if (!isValidForm(selectedTechnical)) {
    setError("Ensure all fields are in range");
    return;
  }
  const technicalConfig = transformTechnicalToConfig(selectedTechnical);
  const isTechnicalAlreadyAdded = technicals.some(
    (technical) => JSON.stringify(technical) === JSON.stringify(technicalConfig)
  );
  if (isTechnicalAlreadyAdded) {
    setError("Technical already added");
    return;
  }
  addTechnical(technicalConfig);
  setError("");
  onClickOutside();
};

const handleSelectTechnical = (
  setSelectedTechnical: (technical: TechnicalOption | undefined) => void,
  technical: TechnicalOption,
  setError: (value: ((prevState: string) => string) | string) => void
) => {
  setSelectedTechnical(technical);
  setError("");
};

const TechnicalsDialog = ({
  visible,
  onClickOutside,
}: TechnicalsDialogProps) => {
  const containerRef = useRef(null);
  useOnClickOutside(containerRef, onClickOutside);
  const [error, setError] = useState<string>("");
  const {
    addTechnical,
    selectedTechnical,
    setTechnicalOptionSelected: setSelectedTechnical,
    setTechnicalToEdit,
    technicals: existingTechnicals,
    technicalToEdit,
  } = useAppContext();
  const { instruments } = useInstrumentContext();
  const { hashcode } = useAppContext();
  const selectedInstrumentClass = getSelectedInstrumentClass(
    instruments,
    hashcode
  );
  const renderError = () => {
    if (error === "") return null;
    return <Error>{error}</Error>;
  };

  const clearSelected = () => {
    if (setSelectedTechnical && setTechnicalToEdit) {
      setSelectedTechnical(undefined);
      setTechnicalToEdit(undefined);
    }
  };

  useEffect(() => {
    if (!visible) {
      clearSelected();
    }
  }, [visible]);
  useEffect(() => {
    if (selectedInstrumentClass === 3) {
      setSelectedTechnical(undefined);
    }
  }, [selectedInstrumentClass]);

  if (!visible) return null;

  return (
    <Container>
      <Dialog ref={containerRef} data-testid="technicals-dialog">
        <Header>
          <Title>Technical Indicators</Title>
          <CloseButton onClick={onClickOutside}>
            <HiXMark />
          </CloseButton>
        </Header>
        <TechnicalsWrapper>
          <SideMenu data-testid="technicals-menu">
            <ThumbMenuItem $isselected={true}>
              <Name>Technicals</Name>
            </ThumbMenuItem>
          </SideMenu>
          <div>
            <Divider />
          </div>
          <SideMenu data-testid="technicals-list">
            {technicals.map((technical, index) => {
              if (selectedInstrumentClass === 3 && technical.name === "Volume")
                return null;
              return (
                <ThumbMenuItem
                  key={`technical-option-${index}`}
                  onClick={() =>
                    handleSelectTechnical(
                      setSelectedTechnical,
                      technical,
                      setError
                    )
                  }
                  $isselected={selectedTechnical?.name === technical.name}
                  data-testid={`technical-option-${index}`}
                >
                  <TechnicalIcon>
                    {renderTechnicalIcon(technical.key)}
                  </TechnicalIcon>
                  <Name>{technical.name}</Name>
                </ThumbMenuItem>
              );
            })}
          </SideMenu>
          <div>
            <Divider />
          </div>
          {selectedTechnical && (
            <TechnicalContainer data-testid="technicals-option-data">
              <FieldTitle>{selectedTechnical.name}</FieldTitle>
              {selectedTechnical.fields.map((field, index) => {
                if (field.hidden) return null;
                return (
                  <FieldOptions
                    data-testid="field-option"
                    key={`field-${index}-${selectedTechnical.name}`}
                  >
                    <label>{field.label}</label>
                    <InputContainer>
                      <Input
                        type={field.type}
                        min={field.min}
                        max={field.max}
                        defaultValue={field.value}
                        onChange={(e) => {
                          if (field.type === "number") {
                            const val = parseInt(e.target.value);
                            selectedTechnical.fields[index].value = val;
                            if (!isValueValid(val, field.validator)) {
                              const { min, max } = field;
                              setError(
                                `Ensure number is in range ${min} - ${max}`
                              );
                              return;
                            }
                            setError("");
                          }
                        }}
                      />
                    </InputContainer>
                  </FieldOptions>
                );
              })}
              {renderError()}
            </TechnicalContainer>
          )}
        </TechnicalsWrapper>
        <Button
          text={getButtonTitle(technicalToEdit)}
          onClick={() =>
            handleAddTechnical(
              selectedTechnical,
              setError,
              addTechnical,
              onClickOutside,
              existingTechnicals
            )
          }
          type="primary"
        />
      </Dialog>
    </Container>
  );
};

const TechnicalsSheet = ({
  visible,
  onClickOutside,
}: TechnicalsDialogProps) => {
  const containerRef = useRef(null);
  useOnClickOutside(containerRef, onClickOutside);
  const [error, setError] = useState<string>("");
  const {
    addTechnical,
    selectedTechnical,
    setTechnicalOptionSelected: setSelectedTechnical,
    setTechnicalToEdit,
    technicalToEdit,
    technicals: existingTechnicals,
  } = useAppContext();

  const renderError = () => {
    if (error === "") return null;
    return <Error>{error}</Error>;
  };

  useEffect(() => {
    if (!visible) {
      setSelectedTechnical(undefined);
      setTechnicalToEdit(undefined);
    }
  }, [visible]);

  if (!visible) return null;

  return (
    <Sheet
      title="Technical Indicators"
      isOpen={visible}
      onClose={onClickOutside}
      topRightComponent={
        <CloseButton onClick={onClickOutside}>
          <HiXMark />
        </CloseButton>
      }
    >
      <SheetWrapper>
        {!selectedTechnical ? (
          <TechnicalsWrapper>
            <SideMenu>
              {technicals.map((technical, index) => (
                <ThumbMenuItem
                  key={`technical-option-${index}`}
                  onClick={() => setSelectedTechnical(technical)}
                  $isselected={selectedTechnical?.name === technical.name}
                  data-testid={`technical-option-${index}`}
                >
                  <TechnicalIcon>
                    {renderTechnicalIcon(technical.key)}
                  </TechnicalIcon>
                  <Name>{technical.name}</Name>
                </ThumbMenuItem>
              ))}
            </SideMenu>
          </TechnicalsWrapper>
        ) : (
          <TechnicalOptionContainer>
            <TechnicalsWrapper>
              <TechnicalContainer>
                <FieldTitle>{selectedTechnical.name}</FieldTitle>
                {selectedTechnical.fields.map((field, index) => {
                  if (field.hidden) return null;
                  return (
                    <FieldOptions
                      key={`field-${index}-${selectedTechnical.name}`}
                    >
                      <label>{field.label}</label>
                      <InputContainer>
                        <Input
                          type={field.type}
                          min={field.min}
                          max={field.max}
                          defaultValue={field.value}
                          onChange={(e) => {
                            if (field.type === "number") {
                              const val = parseInt(e.target.value);
                              selectedTechnical.fields[index].value = val;
                              if (!isValueValid(val, field.validator)) {
                                const { min, max } = field;
                                setError(
                                  `Ensure number is in range ${min} - ${max}`
                                );
                                return;
                              }
                              setError("");
                            }
                          }}
                        />
                      </InputContainer>
                    </FieldOptions>
                  );
                })}
                {renderError()}
              </TechnicalContainer>
            </TechnicalsWrapper>
            <Button
              text={getButtonTitle(technicalToEdit)}
              size={"base"}
              onClick={() =>
                handleAddTechnical(
                  selectedTechnical,
                  setError,
                  addTechnical,
                  onClickOutside,
                  existingTechnicals
                )
              }
              type="primary"
            />
          </TechnicalOptionContainer>
        )}
      </SheetWrapper>
    </Sheet>
  );
};

const TechnicalsPopup = ({
  visible,
  onClickOutside,
}: TechnicalsDialogProps) => {
  if (isMobile()) {
    return (
      <TechnicalsSheet visible={visible} onClickOutside={onClickOutside} />
    );
  }
  return <TechnicalsDialog visible={visible} onClickOutside={onClickOutside} />;
};

export default TechnicalsPopup;
