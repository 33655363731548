import React from "react";
import Label from "../label/Label.component";
type Props = {
  subscriptionTier: string;
};
const SubscriptionTag = ({ subscriptionTier }: Props) => {
  return (
    <div data-testid={"subscription-tag"}>
      <Label text={subscriptionTier} variant="subscription" />
    </div>
  );
};
export default SubscriptionTag;
