import { effectStyles } from "@client/src/utils/effectStyles";
import { baseStyle, textStyles } from "@client/src/utils/textStyles";
import { slideInBottom } from "@utils/animations";
import styled from "styled-components";

export const HeaderSection = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

export const SheetContainer = styled.div`
  background-color: ${(props) => props.theme.dialogFloatingBg};
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  height: 100vh;
  width: 100%;
`;
export const SheetContent = styled.div`
  ${baseStyle};
  z-index: 20;
  display: flex;
  width: 100%;
  height: 90%;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  flex-shrink: 0;
  background-color: ${(props) => props.theme.background};
  position: fixed;
  bottom: 0;
  left: 0;
  animation: ${slideInBottom} 0.3s ease-out;
  border-radius: 1rem 1rem 0 0;
  ${effectStyles.grey.shadowLg}
`;

export const Header = styled.div`
  box-sizing: border-box;
  display: flex;
  padding: 2.5rem 1rem 1rem 1rem;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: 0.5rem;
  justify-content: space-between;
  position: relative;
  cursor: grab;
  user-select: none;
`;

export const HeaderTitle = styled.div`
  ${textStyles.textXlBold}
  justify-content: start;
  width: 100%;
`;

export const Content = styled.div`
  align-items: center;
  justify-content: space-between;
  overflow-y: auto;
  width: 100%;
`;

export const Handle = styled.div`
  width: 6.5rem;
  height: 0.25rem;
  background-color: ${(props) => props.theme.handleColour};
  border-radius: 0.5rem;

  position: absolute;
  top: 0.5rem;
  left: 50%;
  transform: translateX(-50%);
`;
