import { ProductItem } from "@common/types";
import { useEffect, useState } from "react";
import { useUserContext } from "../context/user/UserContext";

export const useSubscriptionProducts = () => {
  const [subscriptionProducts, setSubscriptionProducts] = useState<
    ProductItem[]
  >([]);
  const { getAccessTokenSilently } = useUserContext();
  useEffect(() => {
    getSubscriptionProducts();
  }, []);
  const getSubscriptionProducts = async () => {
    const token = await getAccessTokenSilently();
    const response = await fetch("/api/subscriptions-products", {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const products = await response.json();
    if (JSON.stringify(products) === JSON.stringify({})) return;
    setSubscriptionProducts(products);
  };
  return { subscriptionProducts };
};
