import styled from "styled-components";
import { textStyles } from "@utils/textStyles";
import { effectStyles } from "@client/src/utils/effectStyles";

const Container = styled.div`
  background-color: ${(props) => props.theme.dialogFloatingBg};
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-x: auto;
  @media (max-width: 768px) {
    align-items: flex-end;
  }
`;

const SheetWrapper = styled.div`
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const Title = styled.span`
  ${textStyles.textXlBold};
`;

const Dialog = styled.div`
  background-color: ${(props) => props.theme.instrumentDialogBg};
  z-index: 1000;
  width: 50%;
  border-radius: 0.5rem;
  padding: 1rem;
  gap: 1rem;
  display: flex;
  flex-direction: column;
  ${effectStyles.grey.shadowXl};
  @media (max-width: 768px) {
    padding: 1rem;
    border-radius: 0.5rem 0.5rem 0 0;

    bottom: 0;
    height: 90%;
    width: 100%;
  }
`;

const CloseButton = styled.div`
  cursor: pointer;
  height: 1rem;
  width: 1rem;
  color: white;
`;

const TechnicalsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  background-color: ${(props) => props.theme.secondaryBackground};
  border-radius: 0.5rem;
  position: relative;
`;

const Divider = styled.div`
  height: 100%;
  width: 1px;
  background-color: ${(props) => props.theme.dividerColor};
`;

const SideMenu = styled.div`
  display: flex;
  padding: 0.5rem;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.5rem;
  overflow-y: auto;
  border-radius: 0.25rem;
`;

const ThumbMenuItem = styled.div<{ $isselected: boolean }>`
  display: flex;
  width: 12.5rem;
  padding: 0.75rem 1rem;
  flex-direction: row;
  gap: 0.5rem;
  align-items: center;
  cursor: pointer;
  border-radius: 0.25rem;
  background-color: ${(props) => (props.$isselected ? "white" : "transparent")};
  color: ${(props) => (props.$isselected ? "black" : "inherit")};

  &:hover {
    background-color: rgba(255, 255, 255, 0.1);
    color: white;
  }
`;

const FieldOptions = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 1rem;
`;

const FieldTitle = styled.span`
  padding: 0 1rem;
  ${textStyles.textBaseBold};
`;

const TechnicalContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  cursor: pointer;
  width: 22.375rem;
  padding: 1rem;
`;

const Name = styled.p`
  ${textStyles.textBaseBold};
  color: inherit;
  margin: 0;
`;

const TechnicalIcon = styled.div`
  width: 1.5rem;
  height: 1.5rem;
  & > * {
    width: 1.5rem;
    height: 1.5rem;
  }
`;

const Error = styled.div`
  ${textStyles.textSmBold};
  color: ${(props) => props.theme.chart.candles.downColor};
`;

const TechnicalOptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 100%;
  justify-content: space-between;
`;

export {
  SheetWrapper,
  CloseButton,
  Container,
  Dialog,
  Error,
  Header,
  Name,
  TechnicalIcon,
  TechnicalContainer,
  TechnicalsWrapper,
  Title,
  SideMenu,
  ThumbMenuItem,
  FieldOptions,
  FieldTitle,
  Divider,
  TechnicalOptionContainer,
};
