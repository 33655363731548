import styled from "styled-components";

const Container = styled.div`
  flex: 1;
  width: 100%;
  box-sizing: border-box;
  height: 100%;
  overscroll-behavior: none;
  overflow-y: auto;

  display: flex;
  padding: 1rem;
  flex-direction: column;
  z-index: 1;
  gap: 1rem;

  @media (max-width: 768px) {
    max-height: none;
    overscroll-behavior: initial;
    overflow-y: auto;
    overflow: visible;
  }
`;

export default Container;
