import { useAppContext } from "@client/src/context/AppContext";
import { useInstrumentContext } from "@client/src/context/InstrumentContext";
import { useOnClickOutside } from "@client/src/hooks/useOnClickOutside";
import { formatTimeUTC } from "@utils/dateFormatter";
import { format } from "date-fns";
import React, { useRef } from "react";
import { HiXMark } from "react-icons/hi2";
import { BetaTag } from "../label_types/beta_tag/BetaTag.component";
import Button from "../button/Button.component";
import {
  CloseButton,
  Dialog,
  DialogContainer,
  DialogHeader,
  Title,
  TitleContainer,
} from "../dialog/Dialog.style";
import {
  AnalysisContainer,
  AnalysisContent,
  AnalysisHeader,
  DateRange,
  TickerName,
  TickerSymbol,
} from "./AimaDialog.style";

export type MarketAnalysisDates = {
  start: string;
  end: string;
};

type AimaDialogProps = {
  onClickOutside: () => void;
  content: string;
  visible: boolean;
  dates: MarketAnalysisDates;
};

const AimaDialog = ({
  onClickOutside,
  content,
  visible,
  dates,
}: AimaDialogProps) => {
  const containerRef = useRef(null);
  useOnClickOutside(containerRef, onClickOutside);
  const { instruments } = useInstrumentContext();
  const { hashcode } = useAppContext();

  const instrument = instruments.find(
    (instrument) => hashcode === instrument.hashcode
  );

  const startDate = new Date(dates?.start);
  const endDate = new Date(dates?.end);

  if (!visible) return null;

  return (
    <DialogContainer>
      <Dialog ref={containerRef}>
        <DialogHeader>
          <TitleContainer>
            <Title>AI Market Analysis</Title>
            <BetaTag />
          </TitleContainer>
          <CloseButton onClick={onClickOutside}>
            <HiXMark />
          </CloseButton>
        </DialogHeader>
        <AnalysisContainer>
          <AnalysisHeader>
            <span>
              <TickerSymbol>{instrument?.symbol}</TickerSymbol>{" "}
              <TickerName>{instrument?.name}</TickerName>
            </span>
            <DateRange>
              {format(startDate, "MMMM dd, yyyy ") + formatTimeUTC(startDate)}
              {" to "}
              {format(endDate, "MMMM dd, yyyy ") + formatTimeUTC(endDate)}
            </DateRange>
          </AnalysisHeader>
          <AnalysisContent>{content}</AnalysisContent>
        </AnalysisContainer>
        <div style={{ display: "flex", justifyContent: "end" }}>
          <Button text="OK" onClick={onClickOutside} type="primary" />
        </div>
      </Dialog>
    </DialogContainer>
  );
};

export default AimaDialog;
