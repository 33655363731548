import { baseStyle, textStyles } from "@utils/textStyles";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  gap: 1rem;
  flex-direction: column;
  background-color: ${(props) => props.theme.toastProperties.background};
`;

const Message = styled.div`
  ${baseStyle};
  display: flex;
  align-items: flex-start;
  align-content: flex-start;
  gap: 0 0.25rem;
  align-self: stretch;
  flex-wrap: wrap;
`;

const TextRegular = styled.span`
  ${textStyles.textBase};
`;

const TextBold = styled.span`
  ${textStyles.textBaseBold};
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
`;

export { Container, Message, TextBold, TextRegular, ButtonContainer };
