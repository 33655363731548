import { textStyles } from "@utils/textStyles";
import styled from "styled-components";

export const Container = styled.div`
  padding: 1.5rem;
  background: ${(props) => props.theme.planConfig.darkBackground};
  border-radius: 0.5rem;
  flex: 1;
`;

export const Title = styled.p`
  margin: 0;
  flex: 1;
  ${textStyles.textLgBold};
`;

export const TitleContainer = styled.div`
  display: flex;
  gap: 1rem;
  text-align: start;
`;
export const PreviousPrice = styled.p`
  margin: 0;
  text-decoration: line-through;
  ${textStyles.textBase};
`;

export const AnnualPrice = styled.p`
  margin: 0;
  ${textStyles.textBase};
`;
export const PreviousPriceContainer = styled.div`
  display: flex;
  gap: 0.25rem;
`;
export const Price = styled.p`
  margin: 0;
  ${textStyles.text4xlBold};
`;

export const PriceRecurringUnit = styled.p`
  margin: 0;
  ${textStyles.textBase};
  color: ${(props) => props.theme.planConfig.secondaryText};
`;

export const PriceDescription = styled.p`
  margin: 0;
  ${textStyles.textSm};
  min-height: 1rem;
  color: ${(props) => props.theme.planConfig.secondaryText};
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`;

export const PriceContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 0.3rem;
`;

export const PriceInfoContainer = styled.div`
  display: flex;
  gap: 0.3rem;
  align-items: first baseline;
`;

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  background: ${(props) => props.theme.planConfig.divider};
`;

export const IncludedText = styled.p`
  ${textStyles.textBaseBold};
  margin: 0;
`;
export const ComingSoonText = styled.p`
  ${textStyles.textBaseBold};
  margin: 0;
  color: ${(props) => props.theme.planConfig.comingSoonColor};
`;

export const BodyItemContainer = styled.div`
  align-items: center;
  display: flex;
  gap: 0.5rem;
`;

export const BodyItemList = styled.div`
  display: flex;
  gap: 1rem;
  flex-direction: column;
  align-items: flex-start;
`;
