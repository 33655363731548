import { LineStyle, LineType, LineWidth } from "lightweight-charts";
import { SigmaUIDarkTheme } from "./sigma-ui/theme/dark";
import { SigmaUILightTheme } from "./sigma-ui/theme/light";
import { LeopardDarkTheme, LeopardLightTheme } from "./theme/leopard";

export const colours = {
  "sigma-gray": {
    50: "#676767",
    100: "#F2F2F2",
    150: "#ECECEC",
    200: "#E5E7EB",
    300: "#CACACA",
    400: "#ABABAB",
    500: "#818181",
    600: "#676767",
    700: "#444444",
    800: "#242424",
    850: "#1B1B1B",
    900: "#131313",
  },
  "sigma-neon": {
    darkGreen: "rgba(139, 239, 118, 0.20)",
    lightGreen: "rgba(37, 255, 188, 0.2)",
    darkPurple: "#6A2EF0",
  },
  transparentBlack: "rgba(19, 19, 19, 0.7)",
  white: "#FFFFFF",
  tertiary: {
    green: "#84DB48",
    yellow: "#F9D849",
    red: "#FF6625",
    blue: "#443A84",
    transparentRed: "rgba(255, 102, 37, 0.2)",
    transparentYellow: "rgba(249, 216, 73, 0.2)",
    transparentGreen: "rgba(132, 219, 72, 0.2)",
  },
  candlesticks: {
    darkGreen: "#406328",
    darkRed: "#71341A",
    lightGreen: "#B5E991",
    lightRed: "#FFA47C",
  },
  opacity20: {
    white: "rgba(255, 255, 255, 0.2)",
    black: "rgba(19, 19, 19, 0.2)",
  },
  patterns: [
    "#7209B7",
    "#6A2EF0",
    "#4361EE",
    "#4CC9F0",
    "#0BDB6B",
    "#84DB48",
    "#F9D849",
    "#FB9700",
    "#FF6625",
    "#F72585",
  ],
};

interface Theme {
  [key: string]: ThemeProperties;
}

interface CandleThemeProperties {
  upColor: string;
  borderUpColor: string;
  wickUpColor: string;
  downColor: string;
  borderDownColor: string;
  wickDownColor: string;
}

interface ValueThemeProperties {
  color: string;
  lineStyle: LineStyle;
  lineWidth: LineWidth;
  lineType: LineType;
}

interface ChartThemeProperties {
  background: string;
  label: string;
  line: string;
  text: string;
  grid: string;
  candles: CandleThemeProperties;
  values: ValueThemeProperties;
  patterns: {
    colours: string[];
    lineWidth: LineWidth;
  };
}
interface PlanConfigProperties {
  background: string;
  darkBackground: string;
  text: string;
  secondaryText: string;
  tertiaryText: string;
  goldButtonBackground: string;
  divider: string;
  betaButtonBackground: string;
  comingSoonColor: string;
}

interface PatternConfigProperties {
  colours: string[];
}

interface ToastProperties {
  background: string;
  text: string;
}

interface PortfolioItemProperties {
  background: string;
  backgroundHover: string;
  primaryText: string;
  secondaryText: string;
}

interface LabelConfigProperties {
  background: LabelBackgroundConfigProperties;
  text: LabelTextConfigProperties;
}

interface LabelBackgroundConfigProperties {
  default: string;
  green: string;
  yellow: string;
  red: string;
  tertiary: string;
  beta: string;
  subscription: string;
}

interface LabelTextConfigProperties {
  default: string;
  green: string;
  yellow: string;
  red: string;
  tertiary: string;
  beta: string;
  subscription: string;
}

interface ConditionColours {
  condition: string;
  logicSelector: string;
}

interface ConditionProperties {
  and: ConditionColours;
  or: ConditionColours;
  not: ConditionColours;
}

export interface ThemeProperties {
  name: string;
  scrollbarBg: string;
  secondaryBackground: string;
  searchbarBg: string;
  alertTokenBg: string;
  roundedIconBg: string;
  searchbarText: string;
  background: string;
  instrumentButtonBg: string;
  text: string;
  subtext: string;
  navBg: string;
  footerBg: string;
  selectBg: string;
  selectText: string;
  link: string;
  instrumentDialogBg: string;
  instrumentItemHoverBg: string;
  dialogFloatingBg: string;
  dialogBg: string;
  titleFontSize: string;
  buttonPrimary: string;
  buttonPrimaryText: string;
  buttonSecondary: string;
  buttonSecondaryText: string;
  buttonTertiary: string;
  buttonDestructive: string;
  buttonDestructiveText: string;
  errorTextColor: string;
  buttonTertiaryText: string;
  buttonTransparentText: string;
  termsTextColour: string;
  selectionBg: string;
  uncheckedInput: string;
  patternConfigBg: string;
  patternConfigText: string;
  inputNumericBg: string;
  inputNumericText: string;
  dividerColor: string;
  InboxBackground: string;
  TabbarBackground: string;
  TabbarSelected: string;
  TabbarSelectedGold: string;
  InboxItemBackground: string;
  ManageAlertBackground: string;
  ManageAlertItemBackground: string;
  creditsLabel: string;
  subtextColor: string;
  modalBg: string;
  handleColour: string;
  modalOverlayBg: string;
  modalIconBg: string;
  modalIconRedBg: string;
  modalIcon: string;
  modalIconRed: string;
  emptyStateIcon: string;
  iconButton: string;
  iconButtonHover: string;
  iconButtonClicked: string;
  tooltipBg: string;
  tooltipBgDark: string;
  tooltipText: string;
  inputUnitColor: string;
  chart: ChartThemeProperties;
  planConfig: PlanConfigProperties;
  patternConfig: PatternConfigProperties;
  toastProperties: ToastProperties;
  portfolioItem: PortfolioItemProperties;
  appLogo: string;
  roundedIconColor: string;
  label: LabelConfigProperties;
  alertBellCounterBg: string;
  alertBellCounterText: string;
  dashedBorder: string;
  conditions: ConditionProperties;
}

const defaultLightTheme: ThemeProperties = {
  name: "light",
  ...SigmaUILightTheme,
  ...LeopardLightTheme,
  scrollbarBg: colours.opacity20.black,
  roundedIconBg: colours["sigma-gray"][700],
  searchbarBg: colours["sigma-gray"][900],
  alertTokenBg: colours["sigma-gray"][50],
  alertBellCounterBg: colours["sigma-neon"].darkPurple,
  alertBellCounterText: colours.white,
  searchbarText: colours.white,
  background: colours["sigma-gray"][850],
  instrumentButtonBg: colours["sigma-gray"][800],
  text: colours.white,
  subtext: colours["sigma-gray"][300],
  navBg: colours["sigma-gray"][900],
  footerBg: colours["sigma-gray"][900],
  selectBg: colours["sigma-gray"][900],
  selectText: colours["sigma-gray"][100],
  link: colours.white,
  instrumentDialogBg: colours["sigma-gray"][850],
  instrumentItemHoverBg: colours["sigma-gray"][700],
  dialogFloatingBg: colours.transparentBlack,
  dialogBg: colours["sigma-gray"][900],
  titleFontSize: "18px",
  buttonPrimary: colours.white,
  buttonPrimaryText: colours["sigma-gray"][900],
  buttonSecondary: colours["sigma-gray"][700],
  buttonSecondaryText: colours.white,
  buttonTertiary: colours["sigma-gray"][900],
  buttonTertiaryText: colours.white,
  buttonDestructive: colours.tertiary.transparentRed,
  buttonDestructiveText: colours.tertiary.red,
  errorTextColor: colours.tertiary.red,
  buttonTransparentText: colours["sigma-gray"][900],
  termsTextColour: colours.white,
  selectionBg: colours["sigma-gray"][800],
  uncheckedInput: colours["sigma-gray"][800],
  patternConfigBg: colours["sigma-gray"][900],
  patternConfigText: colours.white,
  inputNumericBg: colours["sigma-gray"][800],
  inputNumericText: colours.white,
  inputUnitColor: colours["sigma-gray"][400],
  dividerColor: colours["sigma-gray"][800],
  InboxBackground: colours["sigma-gray"][850],
  TabbarBackground: colours["sigma-gray"][900],
  TabbarSelected: colours["sigma-gray"][150],
  TabbarSelectedGold:
    "linear-gradient(90deg, #FF6625 18.17%, #FB9700 48.9%, #F9D849 99.74%)",
  InboxItemBackground: colours["sigma-gray"][900],
  ManageAlertBackground: colours["sigma-gray"][900],
  ManageAlertItemBackground: colours["sigma-gray"][900],
  creditsLabel: colours["sigma-gray"][500],
  subtextColor: colours["sigma-gray"][400],
  modalBg: colours["sigma-gray"][900],
  handleColour: colours["sigma-gray"][700],
  modalOverlayBg: colours.transparentBlack,
  modalIconBg: colours["sigma-gray"][700],
  modalIconRedBg: colours.tertiary.transparentRed,
  modalIcon: colours.white,
  modalIconRed: colours.tertiary.red,
  emptyStateIcon: colours["sigma-gray"][700],
  iconButton: colours["sigma-gray"][900],
  iconButtonHover: colours["sigma-gray"][100],
  iconButtonClicked: colours["sigma-gray"][200],
  tooltipBg: colours["sigma-gray"][900],
  tooltipBgDark: colours["sigma-gray"][800],
  tooltipText: colours.white,
  patternConfig: {
    colours: colours.patterns,
  },
  toastProperties: {
    background: colours["sigma-gray"][900],
    text: colours.white,
  },
  label: {
    background: {
      default: colours["sigma-gray"][800],
      green: colours["sigma-neon"].darkGreen,
      yellow: colours.tertiary.transparentYellow,
      red: colours.tertiary.transparentRed,
      tertiary: colours["sigma-gray"][200],
      // eslint-disable-next-line max-len
      beta: "linear-gradient(90deg, #6A2EF0 18.17%, #4361EE 48.9%, #0BDB6B 99.74%)",
      subscription:
        "linear-gradient(90deg, #FF6625 18.17%, #FB9700 48.9%, #F9D849 99.74%)",
    },
    text: {
      default: colours.white,
      green: colours.tertiary.green,
      yellow: colours.tertiary.yellow,
      red: colours.tertiary.red,
      tertiary: colours["sigma-gray"][600],
      beta: colours.white,
      subscription: colours.white,
    },
  },
  portfolioItem: {
    background: colours["sigma-gray"][900],
    backgroundHover: colours["sigma-gray"][800],
    primaryText: colours.white,
    secondaryText: colours["sigma-gray"][400],
  },
  planConfig: {
    background: colours["sigma-gray"][850],
    text: colours.white,
    darkBackground: colours["sigma-gray"][900],
    secondaryText: colours["sigma-gray"][400],
    tertiaryText: colours["sigma-gray"][200],
    goldButtonBackground: "linear-gradient(#1B1B1B, #1B1B1B)",
    betaButtonBackground:
      "linear-gradient( #6A2EF0 18.17%, #4361EE 48.9%, #0BDB6B 99.74%)",
    divider: colours["sigma-gray"][800],
    comingSoonColor: colours["sigma-gray"][500],
  },
  chart: {
    label: colours["sigma-gray"][900],
    background: colours["sigma-gray"][900],
    line: colours["sigma-gray"][500],
    text: colours["sigma-gray"][50],
    grid: colours["sigma-gray"][850],
    candles: {
      upColor: colours.candlesticks.darkGreen,
      borderUpColor: colours.candlesticks.darkGreen,
      wickUpColor: colours.candlesticks.darkGreen,
      downColor: colours.candlesticks.darkRed,
      borderDownColor: colours.candlesticks.darkRed,
      wickDownColor: colours.candlesticks.darkRed,
    },
    patterns: {
      colours: colours.patterns,
      lineWidth: 2,
    },
    values: {
      color: colours.white,
      lineStyle: LineStyle.Solid,
      lineWidth: 2,
      lineType: LineType.Simple,
    },
  },
  appLogo: "",
  roundedIconColor: colours.white,
};

const defaultDarkTheme: ThemeProperties = {
  name: "dark",
  ...SigmaUIDarkTheme,
  ...LeopardDarkTheme,
  scrollbarBg: colours.opacity20.white,
  roundedIconBg: colours["sigma-gray"][700],
  secondaryBackground: colours["sigma-gray"][900],
  alertTokenBg: colours["sigma-gray"][800],
  alertBellCounterBg: colours["sigma-neon"].darkPurple,
  alertBellCounterText: colours.white,
  searchbarText: colours.white,
  searchbarBg: colours["sigma-gray"][900],
  background: colours["sigma-gray"][850],
  instrumentButtonBg: colours["sigma-gray"][800],
  text: colours.white,
  subtext: colours["sigma-gray"][300],
  navBg: colours["sigma-gray"][900],
  footerBg: colours["sigma-gray"][900],
  termsTextColour: colours.white,
  selectBg: colours["sigma-gray"][900],
  selectText: colours["sigma-gray"][100],
  link: colours.white,
  instrumentDialogBg: colours["sigma-gray"][850],
  instrumentItemHoverBg: colours["sigma-gray"][700],
  dialogFloatingBg: colours.transparentBlack,
  dialogBg: colours["sigma-gray"][900],
  titleFontSize: "18px",
  buttonPrimary: colours.white,
  portfolioItem: {
    background: colours["sigma-gray"][900],
    backgroundHover: colours["sigma-gray"][800],
    primaryText: colours.white,
    secondaryText: colours["sigma-gray"][400],
  },
  buttonPrimaryText: colours["sigma-gray"][800],
  buttonSecondary: colours["sigma-gray"][700],
  buttonSecondaryText: colours.white,
  buttonTertiary: colours["sigma-gray"][900],
  buttonTertiaryText: colours.white,
  buttonDestructive: colours.tertiary.transparentRed,
  buttonDestructiveText: colours.tertiary.red,
  errorTextColor: colours.tertiary.red,
  buttonTransparentText: colours.white,
  selectionBg: colours["sigma-gray"][900],
  uncheckedInput: colours["sigma-gray"][800],
  patternConfigBg: colours["sigma-gray"][900],
  patternConfigText: colours.white,
  inputNumericBg: colours["sigma-gray"][800],
  inputNumericText: colours["sigma-gray"][100],
  inputUnitColor: colours["sigma-gray"][400],
  handleColour: colours["sigma-gray"][700],
  dividerColor: colours["sigma-gray"][800],
  InboxBackground: colours["sigma-gray"][850],
  TabbarBackground: colours["sigma-gray"][900],
  TabbarSelected: colours["sigma-gray"][150],
  TabbarSelectedGold:
    "linear-gradient(90deg, #FF6625 18.17%, #FB9700 48.9%, #F9D849 99.74%)",
  InboxItemBackground: colours["sigma-gray"][900],
  ManageAlertBackground: colours["sigma-gray"][900],
  ManageAlertItemBackground: colours["sigma-gray"][900],
  creditsLabel: colours["sigma-gray"][500],
  subtextColor: colours["sigma-gray"][400],
  modalBg: colours["sigma-gray"][900],
  modalOverlayBg: colours.transparentBlack,
  modalIconBg: colours["sigma-gray"][700],
  modalIconRedBg: colours.tertiary.transparentRed,
  modalIcon: colours.white,
  modalIconRed: colours.tertiary.red,
  emptyStateIcon: colours["sigma-gray"][700],
  iconButton: colours.white,
  iconButtonHover: colours["sigma-gray"][800],
  iconButtonClicked: colours["sigma-gray"][700],
  tooltipBg: colours.white,
  tooltipBgDark: colours["sigma-gray"][800],
  tooltipText: colours["sigma-gray"][900],
  patternConfig: {
    colours: colours.patterns,
  },
  toastProperties: {
    background: colours["sigma-gray"][900],
    text: colours.white,
  },
  label: {
    background: {
      default: colours["sigma-gray"][200],
      green: colours["sigma-neon"].darkGreen,
      yellow: colours.tertiary.transparentYellow,
      red: colours.tertiary.transparentRed,
      tertiary: colours["sigma-gray"][800],
      // eslint-disable-next-line max-len
      beta: "linear-gradient(90deg, #6A2EF0 18.17%, #4361EE 48.9%, #0BDB6B 99.74%)",
      subscription:
        "linear-gradient(90deg, #FF6625 18.17%, #FB9700 48.9%, #F9D849 99.74%)",
    },
    text: {
      default: colours["sigma-gray"][900],
      green: colours.tertiary.green,
      yellow: colours.tertiary.yellow,
      red: colours.tertiary.red,
      tertiary: colours["sigma-gray"][400],
      beta: colours.white,
      subscription: colours.white,
    },
  },
  planConfig: {
    background: colours["sigma-gray"][850],
    text: colours.white,
    darkBackground: colours["sigma-gray"][900],
    secondaryText: colours["sigma-gray"][400],
    tertiaryText: colours["sigma-gray"][200],
    goldButtonBackground: "linear-gradient(#1B1B1B, #1B1B1B)",
    betaButtonBackground:
      "linear-gradient(90deg, #6A2EF0 18.17%, #4361EE 48.9%, #0BDB6B 99.74%)",
    divider: colours["sigma-gray"][800],
    comingSoonColor: colours["sigma-gray"][500],
  },
  chart: {
    label: colours.white,
    background: colours["sigma-gray"][900],
    line: colours["sigma-gray"][500],
    text: colours["sigma-gray"][50],
    grid: colours["sigma-gray"][850],
    candles: {
      upColor: colours.candlesticks.darkGreen,
      borderUpColor: colours.candlesticks.darkGreen,
      wickUpColor: colours.candlesticks.darkGreen,
      downColor: colours.candlesticks.darkRed,
      borderDownColor: colours.candlesticks.darkRed,
      wickDownColor: colours.candlesticks.darkRed,
    },
    values: {
      color: colours.white,
      lineStyle: LineStyle.Solid,
      lineWidth: 2,
      lineType: LineType.Simple,
    },
    patterns: {
      colours: colours.patterns,
      lineWidth: 2,
    },
  },
  appLogo: "",
  roundedIconColor: colours.white,
  dashedBorder: colours["sigma-gray"][500],
  conditions: {
    and: {
      condition: colours.tertiary.green,
      logicSelector: colours.tertiary.transparentGreen,
    },
    or: {
      condition: colours.tertiary.yellow,
      logicSelector: colours.tertiary.transparentYellow,
    },
    not: {
      condition: colours.tertiary.red,
      logicSelector: colours.tertiary.transparentRed,
    },
  },
};

export const themes: Theme = {
  // -- Light mode: TBD -- \\
  light: {
    ...defaultLightTheme,
  },
  dark: {
    ...defaultDarkTheme,
  },
  tt: {
    ...defaultLightTheme,
    // name: "tt",
  },
  akili: {
    ...defaultDarkTheme,
    name: "akili",
    appLogo: "https://images.prd.sigmafinancial.ai/akilli-logo-dark.svg",
  },
};
