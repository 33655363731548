import { getInstrumentLabel } from "@client/src/utils/instrumentUtils";
import { ScreeningResult } from "./ScreeningResultTable.component";

export const initialResultSort = (results: ScreeningResult[]) => {
  return [...results].sort((a, b) => {
    const valueA = new Date(a.lastMatch || 0).getTime();
    const valueB = new Date(b.lastMatch || 0).getTime();
    return valueB - valueA;
  });
};

export const createSortByMethods = (sortedList: ScreeningResult[]) => {
  return {
    0: () => {
      // Symbol sorting
      let sorted = [...sortedList].sort((a, b) =>
        a.symbol.localeCompare(b.symbol)
      );
      if (JSON.stringify(sorted) === JSON.stringify(sortedList)) {
        sorted = [...sortedList].sort((a, b) =>
          b.symbol.localeCompare(a.symbol)
        );
      }
      return sorted;
    },
    1: () => {
      // Class/Type sorting
      let sorted = [...sortedList].sort((a, b) =>
        getInstrumentLabel(Number(a.class)).localeCompare(
          getInstrumentLabel(Number(b.class))
        )
      );
      if (JSON.stringify(sorted) === JSON.stringify(sortedList)) {
        sorted = [...sortedList].sort((a, b) =>
          getInstrumentLabel(Number(b.class)).localeCompare(
            getInstrumentLabel(Number(a.class))
          )
        );
      }
      return sorted;
    },
    2: () => {
      // Price sorting
      const notEmptyPrice = sortedList.filter((item) => item.price !== "");
      const emptyPriceList = sortedList.filter(
        (item) => !item.price || item.price === ""
      );
      let sorted = [...notEmptyPrice].sort((a, b) => {
        const priceA = parseFloat((a.price || "").replace(/,/g, ""));
        const priceB = parseFloat((b.price || "").replace(/,/g, ""));
        return priceB - priceA;
      });
      if (JSON.stringify(sorted) === JSON.stringify(notEmptyPrice)) {
        sorted = [...notEmptyPrice].sort((a, b) => {
          const priceA = parseFloat((a.price || "").replace(/,/g, ""));
          const priceB = parseFloat((b.price || "").replace(/,/g, ""));
          return priceA - priceB;
        });
      }
      sorted = [...sorted, ...emptyPriceList];
      return sorted;
    },

    3: () => {
      // Volume sorting
      const notEmptyVolumeList = sortedList.filter(
        (item) => item.volume && item.volume !== ""
      );
      const emptyVolumeList = sortedList.filter(
        (item) => !item.volume || item.volume === ""
      );
      let sorted = [...notEmptyVolumeList].sort((a, b) => {
        const volumeA = parseFloat(a.volume || "0");
        const volumeB = parseFloat(b.volume || "0");
        return volumeA - volumeB;
      });
      if (JSON.stringify(sorted) === JSON.stringify(notEmptyVolumeList)) {
        sorted = [...notEmptyVolumeList].sort((a, b) => {
          const volumeA = parseFloat(a.volume || "0");
          const volumeB = parseFloat(b.volume || "0");
          return volumeB - volumeA;
        });
      }
      sorted = [...sorted, ...emptyVolumeList];
      return sorted;
    },
    4: () => {
      // Change sorting
      const notEmptyChangeList = sortedList.filter(
        (item) => item.change && item.change.toString() !== ""
      );
      const emptyChangeList = sortedList.filter(
        (item) => !item.change || item.change.toString() === ""
      );
      let sorted = [...notEmptyChangeList].sort((a, b) => {
        const changeA = parseFloat(a.change?.toString().split(", ")[1] || "0");
        const changeB = parseFloat(b.change?.toString().split(", ")[1] || "0");
        return changeB - changeA;
      });
      if (JSON.stringify(sorted) === JSON.stringify(notEmptyChangeList)) {
        sorted = [...notEmptyChangeList].sort((a, b) => {
          const changeA = parseFloat(
            a.change?.toString().split(", ")[1] || "0");
          const changeB = parseFloat(
            b.change?.toString().split(", ")[1] || "0");
          return changeA - changeB;
        });
      }
      sorted = [...sorted, ...emptyChangeList];
      return sorted;
    },
    5: () => {
      // Last match sort
      const notEmptyLastMatch = sortedList.filter(
        (item) => item.lastMatch !== ""
      );
      const emptyLastMatchList = sortedList.filter(
        (item) => !item.lastMatch || item.lastMatch === ""
      );
      let sorted = [...notEmptyLastMatch].sort((a, b) => {
        const lastMatchA = a.lastMatchTimestamp || 0;
        const lastMatchB = b.lastMatchTimestamp || 0;
        return lastMatchB - lastMatchA;
      });
      if (JSON.stringify(sorted) === JSON.stringify(notEmptyLastMatch)) {
        sorted = [...notEmptyLastMatch].sort((a, b) => {
          const lastMatchA = a.lastMatchTimestamp || 0;
          const lastMatchB = b.lastMatchTimestamp || 0;
          return lastMatchA - lastMatchB;
        });
      }
      sorted = [...sorted, ...emptyLastMatchList];
      return sorted;
    },
    6: () => {
      let sorted = [...sortedList].sort((a, b) => {
        const aCount = a.count || 0;
        const bCount = b.count || 0;
        return aCount - bCount;
      });
      if (JSON.stringify(sorted) === JSON.stringify(sortedList)) {
        sorted = [...sortedList].sort((a, b) => {
          const aCount = a.count || 0;
          const bCount = b.count || 0;
          return bCount - aCount;
        });
      }
      return sorted;
    },
  };
};
