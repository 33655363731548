import { Instrument } from "@generated/common/basic-types_pb";
import React from "react";
import { HiTrash } from "react-icons/hi2";
import { IconButton } from "../icon_button/IconButton.component";
// eslint-disable-next-line max-len
import {
  LeftAlignText,
  NameAndSymbol,
  SubText,
  TextBold,
} from "../screening_result_table/ScreeningResultTable.style";
import {
  InstrumentItemContainer,
  PortfolioButtonContainer,
} from "./PortfolioDialogProvider.style";

export const renderCreateInstrumentItem = (
  instrument: Instrument.AsObject,
  index: number
) => {
  return (
    <InstrumentItemContainer>
      <NameAndSymbol key={index}>
        <LeftAlignText>
          <TextBold>{instrument.symbol}</TextBold>
        </LeftAlignText>
        <LeftAlignText>
          <SubText>{instrument.name}</SubText>
        </LeftAlignText>
      </NameAndSymbol>
    </InstrumentItemContainer>
  );
};

export const renderEditInstrumentItem = (
  instrument: Instrument.AsObject,
  index: number,
  onRemove: () => void
) => {
  return (
    <InstrumentItemContainer>
      <NameAndSymbol key={index}>
        <LeftAlignText>
          <TextBold>{instrument.symbol}</TextBold>
        </LeftAlignText>
        <LeftAlignText>
          <SubText>{instrument.name}</SubText>
        </LeftAlignText>
      </NameAndSymbol>
      <PortfolioButtonContainer>
        <IconButton
          onClick={onRemove}
          Icon={HiTrash}
          tooltipContent="Remove instrument"
          rounded
          showHover={false}
        />
      </PortfolioButtonContainer>
    </InstrumentItemContainer>
  );
};
