import { useOnClickOutside } from "@hooks/useOnClickOutside";
import { isMobile } from "@utils/device";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import SelectionDialog from "../selection_dialog/SelectionDialog.component";
import { DropdownBody, getDefaultItem } from "./SelectionDropdown.helper";
import { DropdownButton, DropdownContainer } from "./SelectionDropdown.styles";
import { DropdownProps } from "./SelectionDropdown.types";

const SelectionDropdown = ({
  items,
  onSelect,
  Icon,
  defaultItem,
  dialogTitle,
  customHeight,
  renderItem,
  buttonValue,
  onButtonValuePressed,
  value,
  categorizedItems,
  renderCategory,
}: DropdownProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const mobile = isMobile();
  const isSelectOpen = useCallback(() => isOpen && !mobile, [isOpen]);
  const isDialogOpen = useCallback(() => isOpen && mobile, [isOpen]);
  const [selectedItem, setSelectedItem] = useState(getDefaultItem(items));
  const selectedValue = useMemo(
    () => value || selectedItem,
    [value, selectedItem]
  );
  const itemsToRender = useMemo(() => {
    return buttonValue ? [...items, buttonValue] : items;
  }, [items, buttonValue]);

  const categorizedItemsToRender = useMemo(() => {
    if (!categorizedItems) return [];
    return buttonValue
      ? [...categorizedItems, { category: "", items: [buttonValue] }]
      : categorizedItems;
  }, [categorizedItems, buttonValue]);

  useEffect(() => {
    if (defaultItem) {
      setSelectedItem(defaultItem);
    }
  }, [defaultItem]);

  const handleToggle = useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen]);

  const handleSelect = useCallback(
    (item: string) => {
      if (item !== buttonValue) setSelectedItem(item);
      if (item === buttonValue && onButtonValuePressed) onButtonValuePressed();
      onSelect(item);
      setIsOpen(false);
    },
    [onSelect]
  );

  const containerRef = useRef(null);
  useOnClickOutside(containerRef, () => setIsOpen(false));

  return (
    <DropdownContainer ref={containerRef}>
      <DropdownButton onClick={handleToggle} data-testid="dropdown-button">
        {Icon}
        {selectedValue}
      </DropdownButton>
      <SelectionDialog
        title={dialogTitle ?? ""}
        items={itemsToRender}
        onClickOutside={() => setIsOpen(false)}
        visible={isDialogOpen()}
        onSelectItem={(selectedItem) => handleSelect(selectedItem)}
      />
      <DropdownBody
        handleSelect={handleSelect}
        selectedValue={selectedValue}
        isSelectOpen={isSelectOpen}
        items={itemsToRender}
        renderItem={renderItem}
        categorizedItems={categorizedItemsToRender}
        height={customHeight || ""}
        renderCategory={renderCategory}
      />
    </DropdownContainer>
  );
};

export default SelectionDropdown;
