import { effectStyles } from "@client/src/utils/effectStyles";
import { textStyles } from "@utils/textStyles";
import styled from "styled-components";

const Container = styled.div`
  background-color: ${(props) => props.theme.dialogFloatingBg};
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 768px) {
    align-items: flex-end;
  }
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const Title = styled.span`
  ${textStyles.textXlBold};
`;

const Dialog = styled.div`
  background-color: ${(props) => props.theme.instrumentDialogBg};
  z-index: 1000;
  height: 60%;
  width: 60%;
  border-radius: 0.5rem;
  padding: 1rem;
  gap: 1rem;
  display: flex;
  flex-direction: column;
  ${effectStyles.grey.shadowXl};
  @media (max-width: 768px) {
    padding: 1rem;
    border-radius: 0.5rem 0.5rem 0 0;
    bottom: 0;
    height: 90%;
    width: 100%;
  }
`;

const CloseButton = styled.div`
  cursor: pointer;
  height: 1rem;
  width: 1rem;
  color: white;
`;

export { Container, Dialog, Header, Title, CloseButton };
