import Link from "@components/link/Link.component";
import {
  Container,
  FooterContainer,
  FooterMainContent,
  FooterSocialContent,
  SocialFrame,
  LinkContainer,
  LinkGroupContainer,
  LinkGroupTitle,
  CopyrightContainer,
  Divider,
  CopyrightText,
  PoweredBySigma,
} from "./Footer.styles";
import { AppLogo, PoweredBySigmaDark } from "@assets/Icons";
import {
  Discord,
  Email,
  Instagram,
  LinkedIn,
  Twitter,
} from "@assets/SocialImages";
import React from "react";

const Footer = () => {
  return (
    <Container>
      <FooterContainer>
        <FooterMainContent>
          <FooterSocialContent>
            <AppLogo />
            {"Enhance your trading performance"}
            <SocialFrame>
              <a href="mailto:hello@runleopard.ai">
                <Email />
              </a>
              <a
                href="https://www.instagram.com/runleopard_ai/"
                target="_blank"
              >
                <Instagram />
              </a>
              <a href="https://twitter.com/runleopard_ai" target="_blank">
                <Twitter />
              </a>
              <a href="https://www.linkedin.com/company/runleopard-ai">
                <LinkedIn />
              </a>
              <a href="https://discord.gg/ztmE2DjbQD" target="_blank">
                <Discord />
              </a>
            </SocialFrame>
          </FooterSocialContent>
          <LinkContainer>
            <LinkGroupContainer>
              <LinkGroupTitle>Support</LinkGroupTitle>
              <Link
                href="https://support.runleopard.ai"
                $variant="underline"
                target="_blank"
              >
                Help & FAQs
              </Link>
              <Link
                href="https://support.runleopard.ai/hc/en-gb/requests/new"
                $variant="underline"
                target="_blank"
              >
                Report an issue
              </Link>
            </LinkGroupContainer>
            <LinkGroupContainer>
              <LinkGroupTitle>Company</LinkGroupTitle>
              <Link
                href="https://www.runleopard.ai/"
                $variant="underline"
                target="_blank"
              >
                About
              </Link>
            </LinkGroupContainer>
            <LinkGroupContainer>
              <LinkGroupTitle>Legal</LinkGroupTitle>
              <Link
                href="https://www.runleopard.ai/privacy"
                $variant="underline"
                target="_blank"
              >
                Privacy Policy
              </Link>
              <Link
                href="https://www.runleopard.ai/terms-of-use"
                $variant="underline"
                target="_blank"
              >
                Terms of Use
              </Link>
            </LinkGroupContainer>
          </LinkContainer>
        </FooterMainContent>
        <CopyrightContainer>
          <Divider />
          <CopyrightText>
            © 2023 Leopard AI LTD. All rights reserved
          </CopyrightText>
          <PoweredBySigma>
            <PoweredBySigmaDark />
          </PoweredBySigma>
        </CopyrightContainer>
      </FooterContainer>
    </Container>
  );
};

export default Footer;
