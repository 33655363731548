import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { ChartMetadata } from "@utils/condition_builder_utils/dataHandler";
// eslint-disable-next-line max-len
import SelectionDropdown from "../selection_dropdown/SelectionDropdown.component";
import { sigmaScriptPresets } from "@client/src/utils/sigmaScriptPresets";
import { HiClipboardDocumentList, HiPencil, HiTrash } from "react-icons/hi2";
import {
  ConditionBuilderBlock,
  convertBlockToSigmaScript,
  useConditionBuilderContext,
} from "@client/src/context/ConditionBuilderContext";
import { useScreeningContext } from "@client/src/context/ScreeningContext";
import { useAuth0 } from "@auth0/auth0-react";
import { toast } from "react-toastify";
// eslint-disable-next-line max-len
import { CategorizedItems } from "../selection_dropdown/SelectionDropdown.types";
import { CategoryContainer } from "./ConditionSelector.style";

export type ConditionCategory = "Strategies" | "Patterns";

export type Condition = {
  id: number;
  name: string;
  sigmaScript?: string;
  block?: ConditionBuilderBlock;
  version?: number;
  owner?: string;
  metadata?: ChartMetadata;
  category?: ConditionCategory;
};

type Props = {
  onChange: (condition: Condition) => void;
};

const ConditionSelector = ({ onChange }: Props) => {
  const { conditionResults, setBlock, setConditionName, setEditBlockId } =
    useConditionBuilderContext();
  const { setCondition, setShowConditionBuilder } = useScreeningContext();
  const [items, setItems] = useState<CategorizedItems[]>([]);
  const [selectedItem, setSelectedItem] = useState<string>("");
  const conditionResultsRef = useRef(conditionResults);
  const { getAccessTokenSilently } = useAuth0();
  const strategies = useMemo(() => {
    return {
      category: "Strategies",
      items: sigmaScriptPresets.scripts
        .filter((item) => item.category === "Strategies")
        .map((item) => item.name)
        .concat(
          conditionResults.conditions.map((item: Condition) => item.name)
        ),
    };
  }, [conditionResults]);

  const patterns = useMemo(() => {
    return {
      category: "Patterns",
      items: sigmaScriptPresets.scripts
        .filter((item) => item.category === "Patterns")
        .map((item) => item.name),
    };
  }, []);

  const emptyState = { category: "", items: ["Screening Condition"] };

  useEffect(() => {
    conditionResultsRef.current = conditionResults;
  }, [conditionResults]);

  useEffect(() => {
    const hardcodedItems = [emptyState, strategies, patterns];

    setItems(hardcodedItems);
  }, [JSON.stringify(conditionResults), strategies, patterns]);

  const handleSelect = useCallback(
    (itemName: string) => {
      setSelectedItem(itemName); // Update selected item
      const selectedSigmaScriptItem = sigmaScriptPresets.scripts.find(
        (item: Condition) => item.name === itemName
      );
      const selectedConditionItem = conditionResultsRef.current.conditions.find(
        (item: Condition) => item.name === itemName
      );
      if (selectedSigmaScriptItem) {
        onChange(selectedSigmaScriptItem);
      } else if (selectedConditionItem) {
        if (!selectedConditionItem.block) return;
        onChange({
          id: sigmaScriptPresets.scripts.length + 1,
          name: selectedConditionItem.name,
          sigmaScript: convertBlockToSigmaScript(
            selectedConditionItem.block
          )?.toString(),
        });
      }
    },
    [onChange]
  );

  const handleDelete = (itemName: string, e: React.MouseEvent) => {
    e.stopPropagation();

    const itemToDelete = conditionResultsRef.current.conditions.find(
      (item) => item.name === itemName
    );

    if (itemToDelete && itemToDelete.id !== undefined) {
      getAccessTokenSilently().then((token) => {
        fetch("/api/conditions", {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            id: itemToDelete.id,
          }),
        })
          .then((response) => {
            if (response.ok) {
              toast.success(`Successfully deleted ${itemName}`);
              setItems((prevItems) => {
                const newItems = [...prevItems];
                newItems.forEach((item) => {
                  item.items.filter((item) => item !== itemName);
                });
                return newItems;
              });
              if (selectedItem === itemName) {
                setCondition("");
                setSelectedItem("");
                onChange({} as Condition);
              }
            }
          })
          .catch(() => {
            toast.error(`Failed to delete ${itemName}`);
          });
      });
    }
  };

  const handleEdit = (itemName: string, e: React.MouseEvent) => {
    e.stopPropagation();
    const conditionToEdit = conditionResultsRef.current.conditions.find(
      (item: Condition) => item.name === itemName
    );
    const blockId = conditionToEdit?.id;

    if (!conditionToEdit || !conditionToEdit.block) {
      toast.error("No block data available for editing");
      return;
    }

    setBlock(conditionToEdit.block);
    if (blockId !== undefined) {
      setEditBlockId(blockId.toString());
    }
    setConditionName(itemName);
    setShowConditionBuilder(true);
  };

  return (
    <div data-testid="condition-select">
      <SelectionDropdown
        dialogTitle={"Condition Selector"}
        items={[]}
        categorizedItems={items}
        onSelect={handleSelect}
        Icon={<HiClipboardDocumentList />}
        defaultItem={emptyState.items[0]}
        customHeight="50vh"
        renderCategory={(category) => {
          return <CategoryContainer>{category}</CategoryContainer>;
        }}
        renderItem={(item) => {
          if (
            conditionResultsRef.current.conditions.some(
              (cond) => cond.name === item
            )
          ) {
            return (
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  gap: "1rem",
                  width: "100%",
                }}
              >
                {item}
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                    gap: "1rem",
                    width: "fit-content",
                  }}
                >
                  <HiPencil
                    style={{ cursor: "pointer" }}
                    onClick={(e) => handleEdit(item, e)}
                  />
                  <HiTrash
                    style={{ cursor: "pointer" }}
                    onClick={(e) => handleDelete(item, e)}
                  />
                </div>
              </div>
            );
          }
          return <span>{item}</span>;
        }}
      />
    </div>
  );
};

export default ConditionSelector;
