/* eslint-disable max-len */
import styled from "styled-components";

export const ShowRemoveButton = styled.div<{ $isvisible: string }>`
  opacity: ${({ $isvisible }) => $isvisible === "true" ? 1 : 0};
  visibility: ${({ $isvisible }) => $isvisible === "true" ? "visible" : "hidden"};
  transition: opacity 0.3s ease, visibility 0.3s ease;
  cursor: pointer;
`;

export const BlockContainer = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  justify-content: space-between;
  padding: 0.25rem;
`;

export const RenderContainer = styled.div`
  margin: auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: fit-content;
`;
