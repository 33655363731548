import { TechnicalConfigItem } from "@common/types";
import { MovingAverageType } from "@generated/ta/moving_averages_pb";

export const KNOWN_TECHNICALS = [
  "SMA",
  "RSI",
  "EMA",
  "MACD",
  "Volume",
] as const;
export type TTechnical = (typeof KNOWN_TECHNICALS)[number];

export type TechnicalOptionField = {
  value: string | number;
  name: string;
  label?: string;
  type?: string;
  hidden?: boolean;
  validator?: ((value: string) => boolean) | ((value: number) => boolean);
  min?: number;
  max?: number;
};

export type TechnicalOption = {
  name: TTechnical;
  key: string;
  fields: TechnicalOptionField[];
};
export const standardRangeValidator = (value: number) =>
  value >= 2 && value <= 254;

export const rsiRangeValidator = (value: number) =>
  value >= 3 && value <= 255;

export const technicals: TechnicalOption[] = [
  {
    name: "Volume",
    key: "volume",
    fields: [],
  },
  {
    name: "SMA",
    key: "ma",
    fields: [
      {
        value: 20,
        label: "Period",
        name: "window_length",
        type: "number",
        validator: standardRangeValidator,
        min: 2,
        max: 254,
      },
      {
        value: MovingAverageType.SMA,
        hidden: true,
        name: "kind",
      },
    ],
  },
  {
    name: "EMA",
    key: "ma",
    fields: [
      {
        value: 20,
        label: "Period",
        name: "window_length",
        type: "number",
        validator: standardRangeValidator,
        min: 2,
        max: 254,
      },
      {
        value: MovingAverageType.EMA,
        hidden: true,
        name: "kind",
      },
    ],
  },
  {
    name: "RSI",
    key: "rsi",
    fields: [
      {
        value: 14,
        label: "Period",
        name: "main_period",
        type: "number",
        validator: rsiRangeValidator,
        min: 3,
        max: 255,
      },
      {
        value: 0.4,
        label: "Zone",
        name: "zone",
        type: "number",
        validator: (value: number) => value >= 0 && value <= 0.5,
        min: 0,
        max: 0.5,
      },
      {
        value: MovingAverageType.SMA,
        hidden: true,
        name: "main_kind",
      },
      {
        value: 0.4,
        hidden: true,
        name: "zone",
      },
      {
        value: 0,
        hidden: true,
        name: "source",
      },
    ],
  },
  {
    name: "MACD",
    key: "macd",
    fields: [
      {
        value: 12,
        label: "Fast Period",
        name: "fast_period",
        type: "number",
        validator: standardRangeValidator,
        min: 2,
        max: 254,
      },
      {
        value: 26,
        label: "Slow Period",
        name: "slow_period",
        type: "number",
        validator: standardRangeValidator,
        min: 2,
        max: 254,
      },
      {
        value: 9,
        label: "Signal Period",
        name: "line_period",
        type: "number",
        validator: standardRangeValidator,
        min: 2,
        max: 254,
      },
      {
        value: MovingAverageType.SMA,
        hidden: true,
        name: "fast_kind",
      },
      {
        value: MovingAverageType.SMA,
        hidden: true,
        name: "slow_kind",
      },
      {
        value: MovingAverageType.SMA,
        hidden: true,
        name: "line_kind",
      },
      {
        value: 0,
        hidden: true,
        name: "source",
      },
    ],
  },
];
export const transformTechnicalToConfig = (
  technical: TechnicalOption
): TechnicalConfigItem => {
  const config = technical.fields.reduce((acc, field) => {
    acc[field.name] = field.value;
    return acc;
  }, {} as TechnicalConfigItem);

  return {
    name: technical.name,
    [technical.key]: config,
  };
};

export const transformConfigToTechnical = (
  config: TechnicalConfigItem
): TechnicalOption | undefined => {
  const option = [...technicals].find(
    (technical) => technical.name === config.name
  );
  if (!option) return undefined;
  option.fields.forEach((field) => {
    field.value = config[option.key][field.name];
  });
  return option;
};
