import { useAuth0 } from "@auth0/auth0-react";
import { toast } from "react-toastify";
import { toastStyles } from "@utils/toastStyles";
import { usePreferenceDispatch } from "../context/app/preference";
import { AlertPreference } from "../context/app/preference/context";

const useProfile = () => {
  const { getAccessTokenSilently } = useAuth0();

  const preferenceDispatch = usePreferenceDispatch();

  const fetchUserPreferences = () => {
    return getAccessTokenSilently().then((token: string) => {
      return fetch("/api/alerts/preferences", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
        .then((res) => res.json())
        .then((data) => {
          const enabledChannels = data.alertChannels;

          return {
            enabledChannels: enabledChannels as AlertPreference[],
            webEnabled: enabledChannels.includes("web") as boolean,
            emailEnabled: enabledChannels.includes("email") as boolean,
            telegramEnabled: enabledChannels.includes("telegram") as boolean,
            notificationsEnabled: data.notificationsEnabled as boolean,
          };
        })
        .catch(() => {
          toast.error("Error fetching alerts preferences", {
            style: toastStyles("error"),
          });
          return null;
        });
    });
  };

  const pauseOrResumeAlerts = async (pause: boolean) => {
    const requestUrl = `/api/alerts/${pause ? "pause" : "resume"}`;
    return getAccessTokenSilently().then((token: string) => {
      fetch(requestUrl, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
        .then((res) => res.status)
        .then((status) => {
          if (status === 200) {
            toast.success(`Alerts  have been ${pause ? "paused" : "resumed"}`, {
              style: toastStyles("success"),
            });
          }
        })
        .catch(() => {
          toast.error("Error pausing/resuming, please try again.", {
            style: toastStyles("error"),
          });
        });
    });
  };

  const updateAlertPreferences = async (
    alertType: "email" | "web" | "telegram",
    requestType: "enable" | "disable"
  ) => {
    const requestUrl = `/api/alerts/${alertType}/${requestType}`;
    return getAccessTokenSilently().then((token: string) => {
      fetch(requestUrl, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
        .then((res) => res.status)
        .then((status) => {
          preferenceDispatch({
            type: "apply-alert-preference",
            data: {
              preference: alertType,
              action: requestType === "enable" ? "add" : "remove",
            },
          });
          if (status === 200) {
            toast.success(`Alerts for ${alertType} have been ${requestType}d`, {
              style: toastStyles("success"),
            });
          }
        })
        .catch(() => {
          toast.error(
            `Error modifying alerts for ${alertType}, please try again.`,
            {
              style: toastStyles("error"),
            }
          );
        });
    });
  };

  return {
    fetchUserPreferences,
    updateAlertPreferences,
    pauseOrResumeAlerts,
  };
};

export { useProfile };
